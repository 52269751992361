import { IProperty } from '@pulppo/pulppo-models';
import { useEffect, useState } from 'react';
import axios from '../../utils/axios';
import { api_url } from '../../helpers/fetcher';
import Button from '../Elements/Button';
import { Modal } from 'antd';
import { useTranslate } from '@tolgee/react';
interface Props {
    property?: Partial<IProperty>;
}

export const ELEMENTS_TO_CLICK = {
    'verify-button-section': '.verify-commercial-agreement-section',
    'verify-generate-button': '.verify-generate-agreement-button'
};

const VerifyRegenerateContract = ({ property }: Props) => {
    const [propertyContract, setPropertyContract] = useState<{
        comission: string | number;
        durationMonths: number;
    }>(null);
    const { t } = useTranslate('common');

    useEffect(() => {
        if (property?.contract?.pulppoId) {
            axios.get(`${api_url}/property/contract/${property.contract.pulppoId}/property`).then((response) => {
                if (response?.data?.data?.property?.contract) {
                    console.log('contract', response.data.data.property);
                    setPropertyContract(response.data.data.property.contract);
                }
            });
        }
    }, [property?.contract?.pulppoId]);

    const onClickRegenerateContract = () => {
        Modal.confirm({
            title: '¿Estás seguro/a de regenerar el contrato?',
            content:
                'Al regenerar el contrato, se enviará un nuevo contrato al propietario y se pasara la captacion al estado "documentación"',
            onOk: () => {
                const buttonSection = document.querySelector(
                    ELEMENTS_TO_CLICK['verify-button-section']
                ) as HTMLButtonElement;
                if (buttonSection?.click) {
                    buttonSection.click();
                    const generateButton = document.querySelector(
                        ELEMENTS_TO_CLICK['verify-generate-button']
                    ) as HTMLButtonElement;
                    if (generateButton?.click) {
                        generateButton.click();
                    } else {
                        Modal.error({
                            title: 'Error al regenerar el contrato [3]',
                            content: 'No se pudo regenerar el contrato, por favor intenta de nuevo'
                        });
                    }
                } else {
                    Modal.error({
                        title: 'Error al regenerar el contrato [1]',
                        content: 'No se pudo regenerar el contrato, por favor intenta de nuevo'
                    });
                }
            }
        });
    };
    if (
        propertyContract &&
        property?.status?.last === 'published' &&
        (`${propertyContract?.comission}` !== `${property?.contract?.comission}` ||
            `${propertyContract?.durationMonths}` !== `${property?.contract?.durationMonths}`)
    ) {
        return (
            <div className="flex flex-col items-center justify-between gap-x-4 bg-status-light-error px-4 py-2 md:flex-row">
                <p className="font-medium text-status-dark-error md:text-base">
                    Detectamos que hubo un cambio en el  {t('acuerdo comercial').toLowerCase()}.{' '}
                    {`${propertyContract?.comission}` !== `${property?.contract?.comission}`
                        ? `Comisión: ${propertyContract?.comission} > ${property?.contract?.comission}`
                        : ''}{' '}
                    {`${propertyContract?.durationMonths}` !== `${property?.contract?.durationMonths}`
                        ? `Duración: ${propertyContract?.durationMonths} > ${property?.contract?.durationMonths}`
                        : ''}
                </p>
                <Button className="mt-2 w-full md:mt-0 md:w-auto" type="secondary" onClick={onClickRegenerateContract}>
                    Regenerar contrato
                </Button>
            </div>
        );
    }
    return null;
};

export default VerifyRegenerateContract;
