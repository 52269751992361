import { Modal, Popover } from 'antd';
import axios from '../../utils/axios';
import { api_url, fetcher } from '../../helpers/fetcher';
import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { IClose, IOperation, ISimpleUser } from '@pulppo/pulppo-models';
import { closeGenerateID } from '@pulppo/pulppo-models/build/utils/close';
import { useRouter } from 'next/router';
import RowList, { RowListProps } from './RowList';
import { parsePrice } from '../../utils/parser';
import { DEFAULT_CURRENCY } from '../../utils/constants';
import { ColorBadge, getOperationStatusColor } from '../../utils/color';
import { useEffect, useState } from 'react';
import { closeOperation } from '../../services/operationService';
import dayjs from 'dayjs';
import useUser from '../../hooks/useUser';
import { LoadingButton } from '../Elements/ButtonLoading';
import { NotesStep } from './NotesStep';
import useSWR from 'swr';
import { PulppoLoader } from '../Elements/PulppoLoader';
import { PulppoSelect } from '../Elements/PulppoSelect';
import { IResponse } from '../../types';
import useChat from '../../hooks/useChat';
import { useTranslate } from '@tolgee/react';
import Button from '../Elements/Button';
import { Share } from '@blueprintjs/icons';
import { ShareIcon, TrashIcon } from '@heroicons/react/outline';
import AvatarUser from '../Elements/AvatarUser';
import { AgentSelect } from '../Elements/AgentSelect';

export interface DrawerOperationProps {
    show: boolean;
    onClose: () => void;
    operation?: IOperation;
    closeId?: string;
    onClickAssignClose?: () => Promise<void>;
}

const DrawerOperation = ({
    show,
    onClose,
    operation: originalOperation,
    closeId,
    onClickAssignClose
}: DrawerOperationProps) => {
    const [operation, setOperation] = useState(originalOperation);
    const user = useUser((state) => state.user);
    const setChatContact = useChat((store) => store.setChatContact);
    const router = useRouter();
    const [actualCloseId, setActualCloseId] = useState();
    const [showModalSelectClose, setShowModalSelectClose] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslate('common');
    useEffect(() => {
        setOperation(originalOperation);
    }, [originalOperation]);

    const userFeatureBillingEdit = user?.features?.billing;

    const showAssignClose =
        (operation?.status.last === 'contract' ||
            operation?.status.last === 'offer' ||
            operation?.status.last === 'offer_blocked') &&
        user?.permissions?.billing?.edit &&
        !!onClickAssignClose;

    useEffect(() => {
        if (showAssignClose && !actualCloseId) {
            axios.get<IClose>(`${api_url}/close/current?companyId=${operation?.company?._id}`).then((res) => {
                if (res.data) {
                    let id = res.data.id;
                    if (res.data.status !== 'pending') {
                        id = closeGenerateID(dayjs(new Date()).tz().add(1, 'months').toDate());
                    }
                    setActualCloseId(id);
                }
            });
        }
    }, [showAssignClose]);

    const getDirectors = async (user) => {
        return await fetcher(`${api_url}/company/${user?.company?._id}/directors?asContacts=true`);
    };
    const getOwner = () => {
        const contact = {
            ...operation?.seller?.contact
        };
        if (operation?.side === 'buyer') {
            delete contact?.phone;
            return contact;
        }
        return contact;
    };

    const rows: Array<RowListProps> = [
        {
            label: 'Operación',
            value: t(operation?.property?.listing?.operation),
            type: 'title',
            className: {
                value: 'uppercase font-medium'
            }
        },
        {
            label: 'ID',
            value: operation?.id
        },
        {
            label: 'Lado',
            type: 'badge',
            value: {
                text: t(operation?.side),
                color: ColorBadge.Gray
            }
        },
        {
            label: 'Propiedad',
            type: 'property',
            value: {
                property: operation?.property,
                style: {
                    disableTruncate: true
                }
            }
        },
        {
            label: 'Asesor Productor',
            type: 'user',
            value: {
                user: operation?.seller?.producer,
                style: {
                    disableTruncate: true
                }
            }
        },
        ...(`${operation?.seller?.producer?.uid}` === `${user?.uid}`
            ? [
                  {
                      label: 'Propietario',
                      type: 'user',
                      value: {
                          user: getOwner(),
                          style: {
                              disableTruncate: true
                          }
                      }
                  } as any
              ]
            : []),
        {
            label: 'Asesor Vendedor',
            type: 'user',
            value: {
                user: operation?.seller?.broker,
                style: {
                    disableTruncate: true
                }
            }
        },
        {
            label: 'Asesor Comprador',
            type: 'user',
            value: {
                user: operation?.buyer?.broker,
                style: {
                    disableTruncate: true
                }
            }
        },
        {
            label: 'Comprador o inquilino',
            type: 'user',
            value: {
                user: operation?.buyer?.contact,
                style: {
                    disableTruncate: true
                }
            }
        },
        {
            label: 'Estado',
            type: 'badge',
            value: {
                text: t(operation?.status.last),
                color: getOperationStatusColor(operation?.status.last)
            }
        },
        operation?.status.last === 'cancelled'
            ? {
                  label: 'Motivo de cancelación',
                  value: `${operation?.cancelReason?.id} - ${operation?.cancelReason?.description}`
              }
            : null,
        {
            label: 'Fecha de creación',
            value: <span className="capitalize">{dayjs(operation?.createdAt).format('DD/MM/YYYY')}</span>
        },
        {
            label: 'Cierre',
            type: 'title'
        },
        {
            label: 'Mes de cierre',
            value: <span className="capitalize">{closeId || '-'}</span>
        },
        {
            label: 'Fecha de cierre',
            value:
                operation?.status.last === 'closed' || operation?.status.last === 'paying' ? (
                    <span className="capitalize">{closeGenerateID(operation?.closedAt)}</span>
                ) : undefined
        },
        {
            label: 'Fecha estimada de cierre',
            value:
                operation?.status.last === 'offer' ||
                operation?.status.last === 'offer_blocked' ||
                operation?.status.last === 'contract' ? (
                    <span className="capitalize">{closeGenerateID(operation?.expectedClosedAt)}</span>
                ) : undefined
        },
        {
            label: 'Dinero de reserva',
            value: parsePrice({
                price: operation?.reserveValue?.price,
                startStr: '$',
                endStr: operation?.reserveValue?.currency || DEFAULT_CURRENCY
            })
        },
        {
            label: t('Valor de promoción'),
            value: parsePrice({
                price: operation?.promotionValue?.price,
                startStr: '$',
                endStr: operation?.promotionValue?.currency || DEFAULT_CURRENCY
            })
        },
        {
            label: 'Valor de cierre',
            value: parsePrice({
                price: operation?.closeValue?.price,
                startStr: '$',
                endStr: operation?.closeValue?.currency || DEFAULT_CURRENCY
            })
        },
        {
            label: 'Comisión',
            type: 'title'
        },
        {
            label: 'Comisión total cobrada',
            value: parsePrice({
                price: operation?.comission?.price,
                startStr: '$',
                endStr: operation?.comission?.currency || DEFAULT_CURRENCY
            })
        },
        {
            label: 'Comisión inmobiliaria',
            value: parsePrice({
                price: operation?.comission?.price - operation?.pulppoComission?.price,
                startStr: '$',
                endStr: operation?.comission?.currency || DEFAULT_CURRENCY
            })
        },
        user?.features?.billing
            ? {
                  label: 'Comisión pulppo',
                  value: parsePrice({
                      price: operation?.pulppoComission?.price,
                      startStr: '$',
                      endStr: operation?.pulppoComission?.currency || DEFAULT_CURRENCY
                  })
              }
            : null
    ].filter(Boolean);

    const closeOperationServer = async (closeIdForce?: string) => {
        setIsLoading(true);
        setShowModalSelectClose(false);
        await closeOperation(operation?._id, closeIdForce);
        await onClickAssignClose();
        setIsLoading(false);
    };

    const [open, setOpen] = useState(false);
    const [selectedAgent, setSelectedAgent] = useState<ISimpleUser | null>(null);

    const showEdit =
        operation?.status.last === 'offer' ||
        operation?.status.last === 'offer_blocked' ||
        operation?.status.last === 'contract';

    return (
        <PulppoDrawer
            title={
                <div className="flex items-center justify-between">
                    <h3>Detalle de operación</h3>
                    <Button type="ghost" onClick={() => setOpen(true)}>
                        <ShareIcon className="h-4 w-4" />
                    </Button>
                </div>
            }
            visible={show}
            onClose={onClose}
            bodyStyle={{ padding: 0 }}
        >
            <PulppoDrawer title="Agregar colaboradores" onClose={() => setOpen(false)} visible={open}>
                <div className="flex w-full flex-col gap-2">
                    <div className="flex gap-2">
                        <AgentSelect
                            className="min-w-[200px] flex-1"
                            allowAll={false}
                            user={user}
                            defaultToUserId={false}
                            onChange={(val) => {
                                setSelectedAgent(val);
                            }}
                            placeholder="Seleccionar broker..."
                        />
                        <LoadingButton
                            onClick={async () => {
                                setOperation((o) => ({ ...o, editors: [...(o.editors || []), selectedAgent] }));
                                await axios.post(`${api_url}/operation/${operation?._id}/editor`, {
                                    agentId: selectedAgent?.uid
                                });
                            }}
                            className="h-auto py-2"
                            type="ghost"
                        >
                            Agregar
                        </LoadingButton>
                    </div>
                    <div className="mt-4 flex flex-col gap-2">
                        {operation?.editors?.map((editor) => (
                            <div key={editor?.uid} className="flex items-center justify-between gap-2">
                                <AvatarUser user={editor} />
                                <LoadingButton
                                    type="ghost"
                                    onClick={async () => {
                                        setOperation((o) => ({
                                            ...o,
                                            editors: o.editors?.filter((e) => e?.uid !== editor?.uid)
                                        }));
                                        await axios.delete(
                                            `${api_url}/operation/${operation?._id}/editor/${editor?.uid}`
                                        );
                                    }}
                                >
                                    <TrashIcon className="h-4 w-4" />
                                </LoadingButton>
                            </div>
                        ))}
                    </div>
                </div>
            </PulppoDrawer>
            <PulppoLoader loading={isLoading}>
                <div className="flex h-full flex-col gap-2">
                    <div className="w-full flex-1 overflow-y-auto">
                        {rows.map((row) => (
                            <RowList key={`row-list-${row.label.trim().toLowerCase()}`} {...row} />
                        ))}
                        {operation && (
                            <div className="p-8">
                                <NotesStep operation={operation} setOperation={setOperation} />
                            </div>
                        )}
                    </div>
                    {(showAssignClose || showEdit) && (
                        <div className="flex items-center gap-2 px-7 py-4">
                            {showEdit ? (
                                <LoadingButton
                                    onClick={async () => {
                                        if (!user?.permissions?.billing?.edit) {
                                            const directors = await getDirectors(user);
                                            Modal.confirm({
                                                title: 'Acción no permitida',
                                                content:
                                                    'No tienes permiso para editar operaciones. Contacta al masterbroker de tu inmobiliaria para que la edite por ti',
                                                okText: 'Contactar',
                                                cancelText: 'Cancelar',
                                                onOk: () => {
                                                    setChatContact({
                                                        ...directors?.[0],
                                                        message: `Hola, te escribo por la operación ${operation?.id}. Necesito editarla y no tengo permiso, podrias apoyarme?`
                                                    });
                                                    onClose();
                                                }
                                            });
                                            return;
                                        }
                                        if (router) await router.push(`/operations/${operation?._id}`);
                                        else
                                            window.open(
                                                `${window?.location?.origin}/operations/${operation?._id}`,
                                                '_blank'
                                            );
                                    }}
                                    className="h-auto flex-1 py-2"
                                >
                                    Editar
                                </LoadingButton>
                            ) : null}
                            {showAssignClose ? (
                                <LoadingButton
                                    className="h-auto truncate py-2"
                                    disabled={!operation?.comission?.price}
                                    onClick={async () => {
                                        if (userFeatureBillingEdit) {
                                            setShowModalSelectClose(true);
                                        } else {
                                            await closeOperationServer();
                                        }
                                    }}
                                    type="primary"
                                >
                                    Concretar y asignar {userFeatureBillingEdit ? '' : `a ${actualCloseId}`}
                                </LoadingButton>
                            ) : null}
                        </div>
                    )}
                </div>
                <ModalSelectClose
                    operation={operation}
                    show={showModalSelectClose}
                    onClose={() => {
                        setShowModalSelectClose(false);
                    }}
                    onOk={closeOperationServer}
                />
            </PulppoLoader>
        </PulppoDrawer>
    );
};

const ModalSelectClose = ({
    show,
    onClose,
    onOk,
    operation
}: {
    show: boolean;
    onClose: () => void;
    operation: IOperation;
    onOk: (selectedClose: string) => Promise<void>;
}) => {
    const { data, isLoading: isLoadingServer } = useSWR<
        IResponse<{
            closes: Array<IClose>;
        }>
    >(operation?.company?._id ? `${api_url}/close?companyId=${operation?.company?._id}` : '');
    const [selectedClose, setSelectedClose] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(isLoadingServer);
    }, [isLoadingServer]);

    useEffect(() => {
        if (!show) {
            setSelectedClose('');
        }
    }, [show]);

    return (
        <Modal
            title="Seleccionar cierre"
            open={show}
            onCancel={onClose}
            onOk={async () => {
                if (!selectedClose) return;
                setIsLoading(true);
                await onOk(selectedClose);
                onClose();
            }}
        >
            <PulppoLoader loading={isLoading}>
                <p>Seleccione un cierre para la operación</p>
                <PulppoSelect
                    placeholder="Seleccionar cierre"
                    options={data?.data?.closes.map((close) => ({
                        key: close.id,
                        label: close.id,
                        value: close.id
                    }))}
                    value={selectedClose}
                    onChange={(value) => {
                        setSelectedClose(value);
                    }}
                />
            </PulppoLoader>
        </Modal>
    );
};

export default DrawerOperation;
