import { PlusSquareOutlined } from '@ant-design/icons';
import { useTranslate } from '@tolgee/react';
import { Button, Collapse, Typography } from 'antd';
import { FC, ReactElement, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

const { Panel } = Collapse;

export interface ITaskViewProps {
    title?: string | FC | Element | ReactElement;
    action?: any;
    actionIcon?: FC;
    items?: any[];
    content?: FC | any;
    children?: FC | any;
    className?: string;
    classNameTitle?: string;
    onAction?: any;
    isBlack?: boolean;
    showFooter?: boolean;
    id?: string;
}

export const TaskView = ({
    title,
    action,
    actionIcon,
    items,
    content,
    children,
    className,
    classNameTitle,
    onAction,
    showFooter = false,
    isBlack = false,
    id
}: ITaskViewProps) => {
    const { t } = useTranslate('common');
    return (
        <div
            className={twMerge(
                'relative mx-0 my-10px border-solid border-primary-black p-0 pt-4 @container md:p-4 md:shadow-none',
                isBlack ? 'border-t border-primary-black' : 'border-t-4 border-primary-color',
                className
            )}
            id={id}
        >
            <div className="flex justify-between">
                {title ? (
                    <h4 className={twMerge('mb-2 text-lg font-semibold text-primary-black', classNameTitle)}>
                        {t(title as string) as ReactNode}
                    </h4>
                ) : null}
                {onAction && (
                    <Button type="link" className="flex items-center" onClick={onAction}>
                        {action} {actionIcon || <PlusSquareOutlined className="text-secondary-color" />}
                    </Button>
                )}
                {action}
            </div>
            <div className="mb-10px">
                {items && (
                    <Collapse accordion bordered={false} expandIconPosition="end" className="mb-20px bg-transparent">
                        {items.map((item) => (
                            <Panel
                                className="border-none bg-transparent"
                                header={
                                    <Typography.Title
                                        level={5}
                                        className="font-regular text-pulppo-status-dark-disabled"
                                    >
                                        {item.title}
                                    </Typography.Title>
                                }
                                key={item.id}
                            >
                                {item.content}
                            </Panel>
                        ))}
                    </Collapse>
                )}
                {content || children}
            </div>
            {showFooter && (
                <Button className="absolute bottom-2 left-0" type="link">
                    Ver todo
                </Button>
            )}
        </div>
    );
};
