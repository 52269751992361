import { IPropertyHistory } from '@pulppo/pulppo-models';
import { Avatar, Input, List } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { parsePrice } from '../../utils/parser';
import { advancedFields } from '../Property/Steps/step-1';
import { useTranslate } from '@tolgee/react';

const defaultTranslate = (text: string) => {
    const map = {
        age: 'Antigüedad',
        condition: 'Condición',
        expenses: 'Mantenimiento',
        rooms: 'Ambientes',
        suites: 'Recámaras',
        toilettes: 'Medio Baños',
        bathrooms: 'Baños',
        parkings: 'Estacionamientos',
        zonification: 'Zonificación',
        roofedSurface: 'Superficie construida',
        semiroofedSurface: 'Superficie semicubierta',
        totalSurface: 'Superficie total',
        displayOnWeb: 'Difundir en portales',
        unroofedSurface: 'Superficie descubierta',
        orientation: 'Orientación',
        disposition: 'Disposición',
        edition: 'Borrador',
        valuation: 'Valuación',
        documentation: 'Documentación',
        published: 'Publicada',
        cancelled: 'Cancelada',
        completed: 'Vendida'
    };
    if (!map[text]) {
        const field = advancedFields.find((af) => af.id === text);
        if (field) return field.label;
    }
    return map[text] || text;
};

const getHistoryMessage = (item, t: any) => {
    if (item.operation === 'create' && item.changes.label == 'V') return 'Creó la propiedad';

    if (item.changes.label === 'Services Name')
        if (item.changes.ops !== 'Deleted') {
            return `Agregó el servicio ${item.changes.to} a la propiedad`;
        } else return `Eliminó el servicio ${item.changes.to} a la propiedad`;
    if (item.changes.label.includes('Attributes')) {
        if (item.changes.ops === 'Created')
            return `Agregó el campo ${t(item.changes.path.split('.')?.[1])}: ${item.changes.to} a la propiedad`;
        else if (item.changes.ops === 'Edited')
            return `Modificó el campo ${t(
                item.changes.path.split('.')?.[1]
            )} de ${item.changes.from} a ${item.changes.to}`;
        else if (item.changes.ops === 'Deleted') {
            return `Eliminó el campo ${t(item.changes.path.split('.')?.[1])}`;
        }
    }
    if (item.changes.label === 'Listing Price Price') {
        if (item.changes.ops === 'Created')
            return `Agregó el precio de la propiedad ${parsePrice({
                price: item.changes.to,
                startStr: '$',
                withMathRound: true
            })}`;
        else if (item.changes.ops === 'Edited') {
            return `Modificó el precio de la propiedad  de${parsePrice({
                price: item.changes.from,
                startStr: '$',
                withMathRound: true
            })} a ${parsePrice({
                price: item.changes.to,
                startStr: '$',
                withMathRound: true
            })}`;
        }
    }
    if (item.changes.label === 'Pictures Url') {
        if (item.changes.ops !== 'Deleted')
            return (
                <p>
                    Agregó una{' '}
                    <a className="text-blue-400 underline" href={item.changes.to} target="_blank" rel="noreferrer">
                        imagen
                    </a>{' '}
                    a la propiedad
                </p>
            );
        else return 'Eliminó una imagen de la propiedad';
    }
    if (item.changes.label.includes('Additional Fields')) {
        if (item.changes.ops === 'Created')
            return `Agregó el campo ${t(item.changes.path.split('.')?.[1])}: ${item.changes.to} a la propiedad`;
        else if (item.changes.ops === 'Edited') {
            return `Modificó el campo ${t(
                item.changes.path.split('.')?.[1]
            )} de ${item.changes.from} a ${item.changes.to}`;
        } else if (item.changes.ops === 'Deleted') {
            return `Eliminó el campo ${t(item.changes.path.split('.')?.[1])}`;
        }
    }

    if (item.changes.label === 'Contract Comission') {
        if (item.changes.ops === 'Created') return `Agregó la comisión  de la propiedad:  ${item.changes.to}`;
        else if (item.changes.ops === 'Edited') {
            return `Modificó la comisión  de la propiedad de ${item.changes.from} a ${item.changes.to}`;
        }
    }

    if (item.changes.label === 'Portals Inmuebles Publish') {
        return `Modificó Difundir en portales a: ${item.changes.to ? 'Publicar' : 'No publicar'}`;
    }
    if (item.changes.label === 'Listing Description') {
        return `Modificó la descripción a la propiedad`;
    }
    if (item.changes.label === 'Listing Title') {
        return `Modificd el título a la propiedad`;
    }
    if (item.changes.label === 'Listing Operation') {
        return `Modificó el tipo de operacion de la propiedad:  ${item.changes.to === 'sale' ? 'Venta' : 'Renta'}`;
    }
    if (item.changes.label === 'Status Last') {
        return `Modificó el estado de ${t(item.changes.from)} a ${t(item.changes.to)}`;
    }
    if (item.changes.label === 'Agent Seller Personal Email') {
        if (item.changes.ops !== 'Deleted') return `Modificó el vendedor a ${item.changes.to}`;
        else return `Eliminó al vendedor`;
    }
    if (item.changes.label === 'Agent Personal Email') {
        if (item.changes.ops !== 'Deleted') return `Modificó el productor a ${item.changes.to}`;
        else return `Eliminó al productor`;
    }
    if (item.changes.label === 'Contact First Name') {
        if (item.changes.ops === 'Created') return `Agregó el nombre del propietario: ${item.changes.to}`;
        else if (item.changes.ops === 'Edited')
            return `Modificó el nombre del propietario de ${item.changes.from} a ${item.changes.to}`;
    }
    if (item.changes.label === 'Contact Last Name') {
        if (item.changes.ops === 'Created') return `Agregó el apellido del propietario: ${item.changes.to}`;
        else if (item.changes.ops === 'Edited')
            return `Modificó el apellido del propietario de ${item.changes.from} a ${item.changes.to}`;
    }
    if (item.changes.label === 'Videos Url') {
        if (item.changes.ops !== 'Deleted')
            return (
                <p>
                    Agregó un{' '}
                    <a className="text-blue-400 underline" href={item.changes.to} target="_blank" rel="noreferrer">
                        video
                    </a>{' '}
                    a la propiedad
                </p>
            );
        else return 'Eliminó un video de la propiedad';
    }
};

export const PropertyHistory = ({ history }: { history: IPropertyHistory[] }) => {
    const [search, setSearch] = useState('');
    const { t: translate } = useTranslate('common');
    const t = (text: string) => translate(text, defaultTranslate(text));
    return (
        <div className="h-full overflow-y-hidden">
            <div className="pb-4">
                <Input placeholder="Buscar actividad" onChange={(ev) => setSearch(ev.currentTarget.value)} />
            </div>
            <div className="h-full overflow-y-auto pb-10">
                <List
                    dataSource={history
                        .map((hst) => ({
                            user: hst.user,
                            createdAt: (hst as any).createdAt,
                            message: getHistoryMessage(hst, t),
                            searchable:
                                typeof getHistoryMessage(hst, t) === 'string'
                                    ? getHistoryMessage(hst, t)
                                    : (hst.changes as any).label
                        }))
                        .filter((hst) => !!hst.message && (!search || hst.searchable?.toLowerCase().includes(search)))}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                title={<p>{item.message}</p>}
                                description={dayjs((item as any).createdAt)
                                    .tz()
                                    .fromNow()}
                                avatar={
                                    <Avatar
                                        src={
                                            item.user?.profilePicture ||
                                            `https://ui-avatars.com/api/?name=${item.user?.firstName}%20${item?.user?.lastName}`
                                        }
                                    />
                                }
                            />
                        </List.Item>
                    )}
                ></List>
            </div>
        </div>
    );
};
