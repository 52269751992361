import Button from '../Elements/Button';
import { twMerge } from 'tailwind-merge';
import { ReactNode } from 'react';

interface Props {
    title: string;
    description: string;
    button: {
        text: ReactNode;
        onClick: () => void;
        type?: 'primary' | 'secondary';
        disabled?: boolean;
    };
    img: {
        src: string;
        className: string;
    };
    container?: {
        className?: string;
    };
}

const CaptureDataOption = ({ title, description, button, img, container }: Props) => {
    return (
        <div
            className={twMerge(
                'flex flex-col justify-between border border-solid border-secondary-gray-disabled px-3 py-5',
                container?.className
            )}
        >
            <div className="flex items-center px-1">
                <img className={img.className} src={img.src} alt={title} />
                <div className="text-primary-black">
                    <p className="font-semibold">{title}</p>
                    <p className="font-light">{description}</p>
                </div>
            </div>
            <Button
                disabled={button?.disabled}
                className="mt-4 w-full"
                onClick={button.onClick}
                type={button?.type || 'secondary'}
            >
                {button.text}
            </Button>
        </div>
    );
};

export default CaptureDataOption;
