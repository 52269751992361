import { DeleteOutlined, EyeOutlined, CloseOutlined, LinkOutlined } from '@ant-design/icons';
import { IVideo } from '@pulppo/pulppo-models';
import { Button, Col, Input, Popover, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { urlToId } from '../../helpers/youtube';
import { useDebounce } from '../../hooks/debounce';

export const VideoRow = ({
    editable = true,
    onChange,
    videos,
    video = {
        url: ''
    } as IVideo & { _id: string },
    index = 0,
    isNew = false
}) => {
    const [edit, setEdit] = useState(false);
    const [input, setInput] = useState(video.url);
    const [openPreview, setOpenPreview] = useState(false);
    const [invalidUrl, setInvalidUrl] = useState(false);
    const videosRef = useRef(videos);

    useEffect(() => {
        videosRef.current = videos;
    }, [videos]);

    useDebounce(
        () => {
            if (edit && input) {
                if (urlToId(input)) {
                    setInvalidUrl(false);
                    const clone = [...(videosRef.current || [])];
                    clone[index] = {
                        url: input,
                        provider: 'youtube',
                        type: 'youtube',
                        title: '',
                        description: '',
                        id: urlToId(input)
                    };
                    onChange(clone);
                    if (isNew) setInput('');
                } else {
                    setInvalidUrl(true);
                }
            }
        },
        1000,
        [input]
    );

    return (
        <>
            <Popover
                trigger="click"
                content={
                    <>
                        <Row justify="end">
                            <Button
                                type="link"
                                onClick={() => {
                                    setOpenPreview(false);
                                }}
                                icon={<CloseOutlined />}
                            />
                        </Row>

                        <iframe
                            width="560"
                            height="315"
                            src={`https://youtube.com/embed/${urlToId(input)}`}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </>
                }
                open={openPreview}
                placement="bottom"
            >
                <Row justify="space-between" key={video._id}>
                    <Col span={22}>
                        <Input
                            placeholder="Inserte la URL del video de YouTube"
                            disabled={!editable}
                            className="mb-10px rounded-none"
                            suffix={<LinkOutlined className="text-lg text-secondary-gray-disabled" />}
                            defaultValue={video.url}
                            value={input}
                            onChange={(ev) => {
                                setEdit(true);
                                setInput(ev.currentTarget.value);
                            }}
                        />
                    </Col>
                    <Col>
                        <Button
                            type="link"
                            onClick={() => {
                                setOpenPreview(true);
                            }}
                            icon={<EyeOutlined className="text-primary-gray" />}
                        />
                    </Col>
                    {!isNew && (
                        <Col>
                            <Button
                                type="link"
                                onClick={() => {
                                    const aux = [...(videos || [])];
                                    aux.splice(index, 1);
                                    onChange(aux);
                                }}
                                icon={<DeleteOutlined />}
                            ></Button>
                        </Col>
                    )}
                </Row>
                {invalidUrl && <p className="text-red-600">Url inválida</p>}
            </Popover>
        </>
    );
};
