import { useEffect, useState } from 'react';
import CourseService from '../services/CourseService';
import { ICourse } from '@pulppo/pulppo-models';
import { CourseType } from '@pulppo/pulppo-models/build/enums/CourseType';
import { setCourseToAgentOngoing } from '../utils/courses';
import { ICourseWithStatus } from '../types';

interface Props {
    initData?: Array<ICourse>;
    agentUid?: string;
    includeSuspension?: boolean;
}

const ORDER_TYPES = [
    CourseType.FirstSteps,
    CourseType.ManageProperties,
    CourseType.ManageClients,
    CourseType.Administration,
    CourseType.Benefits,
    CourseType.Exclusive,
    CourseType.Marketing,
    CourseType.Coaching,
    CourseType.Coffee,
    CourseType.Suspension
];

const useCourse = ({ initData = [], agentUid, includeSuspension }: Props) => {
    const [courses, _setCourses] = useState<Array<ICourse>>(initData);
    const [agentCourses, setAgentCourses] = useState<Array<ICourseWithStatus>>([]);

    const setCourses = (courses: Array<ICourse>) => {
        _setCourses(includeSuspension ? courses : courses.filter((course) => course.type !== 'suspension'));
    };

    useEffect(() => {
        if (!initData.length) {
            CourseService.getAll({ isActive: true, classesActive: true }).then((res) => {
                res.success && setCourses(res.data);
            });
        } else {
            setCourses(initData);
        }
    }, []);

    useEffect(() => {
        (async () => {
            if (!courses?.length) return;
            const actualAgentCourses = await CourseService.getAgentCourses(agentUid);
            const normalizeAgentCourses = setCourseToAgentOngoing(actualAgentCourses, courses);
            setAgentCourses(normalizeAgentCourses);
        })();
    }, [courses, agentUid]);

    const coursesOngoing: Array<ICourseWithStatus> = agentCourses.filter(
        (course) => course.progress.ongoing && course.progress.status !== 'completed'
    );
    const coursesCompleted: Array<ICourseWithStatus> = agentCourses.filter(
        (course) => course.progress.status === 'completed'
    );
    const coursesPending: Array<ICourseWithStatus> = agentCourses.filter(
        (course) => course.progress.status !== 'completed'
    );
    const coursesIsNewOrder = agentCourses
        .filter((course) => !!course.isNewOrder && course.progress.status !== 'completed')
        .sort((courseA, courseB) => courseA.isNewOrder - courseB.isNewOrder);
    const firstIsNewOrder = coursesIsNewOrder[0];

    const sort = (courses: Array<ICourseWithStatus>) => {
        return courses.sort((courseA, courseB) => {
            return (
                ORDER_TYPES.indexOf(courseA.type) - ORDER_TYPES.indexOf(courseB.type) || courseA.order - courseB.order
            );
        });
    };
    const orderedCoursesByTypeAndOrder = sort(agentCourses);
    const types = [...new Set(courses.map((course) => course.type))].sort((typeA, typeB) => {
        return ORDER_TYPES.indexOf(typeA) - ORDER_TYPES.indexOf(typeB);
    });

    return {
        state: {
            courses: {
                total: courses,
                ongoing: coursesOngoing,
                completed: coursesCompleted,
                suspension: courses.filter((course) => course.type === 'suspension'),
                orderedByTypeAndOrder: orderedCoursesByTypeAndOrder,
                agent: agentCourses,
                pending: coursesPending,
                new: coursesIsNewOrder,
                firstNew: firstIsNewOrder
            },
            types
        },
        sort
    };
};

export default useCourse;
