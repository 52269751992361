import { Typography } from "antd";
import { LinkProps } from "antd/lib/typography/Link";
import Link from "next/link";

export const NextLink = (props: LinkProps) => {
    return (
        <Link href={props.href} passHref>
            <Typography.Link {...props}></Typography.Link>
        </Link>
    );
};
