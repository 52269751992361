import { IAgent } from '@pulppo/pulppo-models';
import useSWR from 'swr';
import { api_url, fetcher } from '../../helpers/fetcher';
import { useEffect, useState } from 'react';
import MetricService, { ResponseMetric } from '../../services/metricService';
import { IResponse } from '../../types';
import { MetricType } from '@pulppo/pulppo-models/build/enums/MetricType';
import useUser from '../useUser';

interface Props {
    select: Array<MetricType>;
    currentWeek: boolean;
}

const useMetricAgents = ({ select = [], currentWeek }: Props) => {
    const user = useUser((u) => u.user);
    const statsView = user.showAll;
    const { data: agents = [] } = useSWR<Array<IAgent>>(
        statsView && `${api_url}/company/${user?.company?._id}/agents?filterBy=status`,
        fetcher
    );
    const [agentsWithMetrics, setAgentsWithMetrics] = useState<
        Array<
            IAgent & {
                metrics: ResponseMetric | undefined;
            }
        >
    >([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (agents.length) {
            (async () => {
                if (!select?.length) {
                    return;
                }
                const promises: Array<Promise<IResponse<Array<ResponseMetric>>>> = [];
                for (const agent of agents) {
                    promises.push(
                        MetricService.get({
                            type: 'agent',
                            id: `${agent._id}`,
                            limit: 1,
                            currentWeek,
                            select: select
                        })
                    );
                }
                const result = await Promise.all(promises);
                setAgentsWithMetrics(
                    agents
                        .map((agent, index) => {
                            return {
                                ...agent,
                                metrics: result[index].data[0]
                            };
                        })
                        .filter((agent) => agent.metrics)
                );
                setIsLoading(false);
            })();
        }
    }, [agents]);

    return {
        state: {
            isLoading,
            agents: agentsWithMetrics
        }
    };
};

export default useMetricAgents;
