import { useEffect, useRef } from 'react';
import axios from '../utils/axios';
import { api_url } from '../helpers/fetcher';
import { toPlain } from '../utils/toPlain';
import { useDebounceValue } from './debounceValue';
import useCaptureProperty from './useCaptureProperty';
import { useRouter } from 'next/router';

interface Props {
    disabled?: boolean;
}

const useCapturePropertyUpdate = ({ disabled }: Props) => {
    const router = useRouter();
    const { property, setProperty, stackedChanges, cleanStackedChanges } = useCaptureProperty();
    const debouncedChange = useDebounceValue(property, 500);
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (!disabled) {
            if (!debouncedChange) return;

            if (!property?._id) {
                axios
                    .post(`${api_url}/property`, {
                        ...toPlain(debouncedChange, ''),
                        editedAt: new Date()
                    })
                    .then((p) => {
                        setProperty(p.data, 'useCapturePropertyUpdate-create');
                        router.push(`/captaciones/${p.data._id}`);
                    });
            } else {
                if (isFirstRender.current) {
                    isFirstRender.current = false;
                    return;
                }

                (async () => {
                    if (Array.isArray(stackedChanges?.notes)) {
                        if (stackedChanges?.notes.filter((note) => !note._id).length) {
                            await axios.patch(`${api_url}/property/${property._id}/notes`, {
                                notes: stackedChanges.notes
                            });
                            setProperty(
                                (state) => ({
                                    notes: [
                                        ...(state?.notes?.length
                                            ? state.notes.map((note) => ({
                                                  ...note,
                                                  _id: note?._id || Math.random().toString()
                                              }))
                                            : stackedChanges?.notes || [])
                                    ]
                                }),
                                'useCapturePropertyUpdate-notes'
                            );
                        }
                        delete stackedChanges.notes;
                    }
                    if (stackedChanges && Object.keys(stackedChanges).length === 0) return;
                    axios
                        .patch(`${api_url}/property/${property._id}`, {
                            ...toPlain(stackedChanges, ''),
                            editedAt: new Date()
                        })
                        .finally(() => {
                            cleanStackedChanges();
                        });
                })();
            }
        }
    }, [debouncedChange, disabled]);
};

export default useCapturePropertyUpdate;
