import { NextImage } from '../Elements/NextImage';
import { parsePrice } from '../../utils/parser';
import { PulppoLoader } from '../Elements/PulppoLoader';
import ValuationBarChart from './ValuationBarChart';
import dayjs from 'dayjs';
import { api_url } from '../../helpers/fetcher';
import { ShowContactList } from '../Contact/SimpleContactList';
import { showComparableDrawer } from './ComparablesDrawer';
import { useEffect, useState } from 'react';
import { IAgent, IProperty } from '@pulppo/pulppo-models';
import axios from '../../utils/axios';
import { twMerge } from 'tailwind-merge';
import Button from '../Elements/Button';
import { LoadingButton } from '../Elements/ButtonLoading';
import { shareValuation } from '../PropertyDetail/PropertyDetail';

export interface ValuationProps {
    property: IProperty;
    setProperty: (property: IProperty) => void;
    user: IAgent;
    disabled?: boolean;
    hidden?: {
        propertyDetails?: boolean;
        actionShare?: boolean;
        conclusion?: boolean;
        description?: boolean;
    };
    askingM2Price?: number;
    showDrawerComparables?: boolean;
}

const Valuation = ({ property, setProperty, user, hidden, disabled, showDrawerComparables }: ValuationProps) => {
    const [selectedColumn, setSelectedColumn] = useState<number>(-1);
    const [comparables, setComparables] = useState<Array<IProperty>>([]);
    const [valuation, setValuation] = useState<typeof property.valuation>(null);
    const validComparables = comparables.filter((cmp) => cmp.status?.last !== 'cancelled');
    const [loading, setLoading] = useState(false);

    const getComparables = () => {
        setLoading(true);
        return axios
            .get(`${api_url}/property/${property?._id}/comparables`)
            .then((res) => setComparables(res.data))
            .catch(() => setComparables([]))
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (property?.valuation?.columns?.length) {
            setValuation(property.valuation);
            getComparables();
        }
    }, [property]);

    useEffect(() => {
        if (showDrawerComparables) {
            openDrawer();
        }
    }, [showDrawerComparables]);

    const updateValuation = async (property: IProperty) => {
        await getComparables();
        setValuation(property.valuation);
        setProperty(property);
    };

    const openDrawer = () => {
        showComparableDrawer({
            comparables,
            property,
            updateValuation,
            disabled
        });
    };

    return (
        <div className="-mx-4 flex h-full flex-col items-center justify-start bg-white">
            <div className="flex-0 w-full shrink-0">
                {!hidden?.propertyDetails ? (
                    <div className="flex w-full items-center gap-4">
                        <div className="flex-0 h-fit shrink-0">
                            <NextImage
                                className="h-20 w-20"
                                src={property?.pictures?.[0]?.url}
                                alt="thumb"
                                showPreview={false}
                                width={250}
                            />
                        </div>
                        <div className="shrink-1 flex w-4/5 flex-1 flex-col justify-between">
                            <p className="w-full truncate text-lg font-medium">{property?.address?.street}</p>
                            <p className="truncate text-base text-pulppo-status-dark-disabled">
                                {property?.address?.neighborhood?.name || property?.address?.city?.name}
                            </p>
                            <p className="text-base font-medium">
                                {parsePrice({
                                    price: property.listing?.price?.price,
                                    startStr: `${property.listing?.price?.currency} `
                                })}
                            </p>
                        </div>
                    </div>
                ) : null}
                <div
                    className={`my-3 flex w-full items-center justify-center rounded-md px-1 py-1 font-medium ${
                        valuation?.result === 'high'
                            ? 'bg-notification-red text-strong-red'
                            : valuation?.result === 'low'
                              ? 'bg-notification-green text-strong-green'
                              : 'bg-notification-yellow text-strong-yellow'
                    }`}
                >
                    <p className="text-center">
                        El precio de la propiedad{' '}
                        {valuation?.result === 'high'
                            ? 'está fuera de mercado'
                            : valuation?.result === 'low'
                              ? 'es óptimo'
                              : 'es poco competitivo'}
                    </p>
                </div>
            </div>
            <div className="shrink-1 w-full flex-1 overflow-x-hidden">
                <div className="h-full w-full overflow-y-auto overflow-x-hidden px-4 py-2">
                    <PulppoLoader loading={loading} className="shrink-1">
                        <div className="flex flex-1 flex-col">
                            {!hidden?.description ? (
                                <>
                                    <p className="text-lg font-medium">Comparables</p>
                                    <p className="mt-1 text-base">
                                        Haz clic en las columnas para ver más detalles de valuación
                                    </p>
                                </>
                            ) : null}
                            <div className="mt-2 h-fit w-full">
                                <ValuationBarChart
                                    onSelect={(column) => {
                                        if (column === selectedColumn) setSelectedColumn(-1);
                                        else setSelectedColumn(column);
                                    }}
                                    columns={valuation?.columns as Array<any>}
                                    marks={[
                                        valuation?.value && typeof valuation?.column === 'number'
                                            ? {
                                                  value: valuation?.value,
                                                  column: valuation?.column
                                              }
                                            : null
                                    ].filter(Boolean)}
                                    selectedColumn={selectedColumn}
                                />
                            </div>
                            {selectedColumn >= 0 && (
                                <div className="mt-4">
                                    <div className="flex w-full justify-between border-b border-solid border-gray-200 pb-2">
                                        <p className="font-medium">Rango por m2</p>
                                        <p className="text-pulppo-status-dark-disabled">{`${parsePrice({
                                            price: Math.round(valuation?.columns?.[selectedColumn]?.min / 100) * 100,
                                            startStr: ' '
                                        })} a ${parsePrice({
                                            price: Math.round(valuation?.columns?.[selectedColumn]?.max / 100) * 100,
                                            startStr: ' '
                                        })} ${process.env.NEXT_PUBLIC_PRIMARY_CURRENCY}`}</p>
                                    </div>
                                    <div className="flex w-full justify-between pt-2">
                                        <p className="font-medium">Rango de precio</p>
                                        <p className="text-pulppo-status-dark-disabled">{`${parsePrice({
                                            price:
                                                Math.round(
                                                    (property?.attributes?.roofedSurface *
                                                        valuation?.columns?.[selectedColumn]?.min) /
                                                        100
                                                ) * 100,
                                            startStr: ' '
                                        })} a ${parsePrice({
                                            price:
                                                Math.round(
                                                    (property?.attributes?.roofedSurface *
                                                        valuation?.columns?.[selectedColumn]?.max) /
                                                        100
                                                ) * 100,
                                            startStr: ' '
                                        })} ${process.env.NEXT_PUBLIC_PRIMARY_CURRENCY}`}</p>
                                    </div>
                                </div>
                            )}
                            {!hidden?.conclusion ? (
                                <>
                                    <p className="mt-5 text-lg font-medium">Conclusiones</p>
                                    <div className="mt-1 flex flex-col gap-1 text-base">
                                        <p>&bull; Se encontraron {validComparables?.length} propiedades similares</p>
                                        <p>
                                            &bull; Hay{' '}
                                            {
                                                validComparables?.filter(
                                                    (elem) => elem.valuation.value < valuation?.value
                                                )?.length
                                            }{' '}
                                            propiedades con un precio más bajo
                                        </p>
                                        {validComparables?.filter(
                                            (cmp) =>
                                                cmp?.listing?.value < property?.listing?.value &&
                                                cmp?.attributes?.roofedSurface > property?.attributes?.roofedSurface
                                        )?.length > 0 && (
                                            <p>
                                                &bull; Hay{' '}
                                                {
                                                    validComparables?.filter(
                                                        (cmp) =>
                                                            cmp?.listing?.value < property?.listing?.value &&
                                                            cmp?.attributes?.roofedSurface >
                                                                property?.attributes?.roofedSurface
                                                    )?.length
                                                }{' '}
                                                propiedades con un precio más bajo y mayor superficie
                                            </p>
                                        )}
                                        {valuation?.result === 'low' &&
                                            validComparables?.some(
                                                (cmp) =>
                                                    cmp?.valuation?.result === 'low' &&
                                                    dayjs().tz().diff(dayjs(cmp.publishedAt).tz(), 'days')
                                            ) && (
                                                <p>
                                                    &bull; Las propiedades con precio optimo tienen en promedio{' '}
                                                    {Math.round(
                                                        validComparables
                                                            ?.filter(
                                                                (cmp) =>
                                                                    cmp?.valuation?.result === 'low' &&
                                                                    dayjs()
                                                                        .tz()
                                                                        .diff(dayjs(cmp.publishedAt).tz(), 'days')
                                                            )
                                                            ?.reduce(
                                                                (avg, cmp, _, arr) =>
                                                                    avg +
                                                                    dayjs()
                                                                        .tz()
                                                                        .diff(dayjs(cmp.publishedAt).tz(), 'days') /
                                                                        arr.length,
                                                                0
                                                            )
                                                    )}{' '}
                                                    días publicadas
                                                </p>
                                            )}
                                        {valuation?.result === 'high' &&
                                            validComparables?.some(
                                                (cmp) =>
                                                    cmp?.valuation?.result === 'high' &&
                                                    dayjs().tz().diff(dayjs(cmp.publishedAt).tz(), 'days') > 30
                                            ) && (
                                                <p>
                                                    &bull; Las propiedades con precio fuera de mercado tienen en
                                                    promedio{' '}
                                                    {Math.round(
                                                        validComparables
                                                            ?.filter(
                                                                (cmp) =>
                                                                    cmp?.valuation?.result === 'high' &&
                                                                    dayjs()
                                                                        .tz()
                                                                        .diff(dayjs(cmp.publishedAt).tz(), 'days') > 30
                                                            )
                                                            ?.reduce(
                                                                (avg, cmp, _, arr) =>
                                                                    avg +
                                                                    dayjs()
                                                                        .tz()
                                                                        .diff(dayjs(cmp.publishedAt).tz(), 'days') /
                                                                        arr.length,
                                                                0
                                                            )
                                                    )}{' '}
                                                    días publicadas
                                                </p>
                                            )}
                                        {valuation?.result === 'medium' &&
                                            validComparables?.some(
                                                (cmp) =>
                                                    cmp?.valuation?.result === 'medium' &&
                                                    dayjs().tz().diff(dayjs(cmp.publishedAt).tz(), 'days') > 30
                                            ) && (
                                                <p>
                                                    &bull; Las propiedades con precio no competitivo tienen en promedio{' '}
                                                    {Math.round(
                                                        validComparables
                                                            ?.filter(
                                                                (cmp) =>
                                                                    cmp?.valuation?.result === 'medium' &&
                                                                    dayjs()
                                                                        .tz()
                                                                        .diff(dayjs(cmp.publishedAt).tz(), 'days') > 30
                                                            )
                                                            ?.reduce(
                                                                (avg, cmp, index, arr) =>
                                                                    avg +
                                                                    dayjs()
                                                                        .tz()
                                                                        .diff(dayjs(cmp.publishedAt).tz(), 'days') /
                                                                        arr.length,
                                                                0
                                                            )
                                                    )}{' '}
                                                    días publicadas
                                                </p>
                                            )}
                                        {valuation?.result !== 'low' && (
                                            <p>
                                                &bull; Recomendamos bajar el precio de la propiedad y fijarlo entre{' '}
                                                <br />
                                                {parsePrice({
                                                    price:
                                                        Math.round(
                                                            (valuation?.columns?.[1]?.min *
                                                                property?.attributes?.roofedSurface) /
                                                                100
                                                        ) * 100,
                                                    startStr: `${process.env.NEXT_PUBLIC_PRIMARY_CURRENCY} `
                                                })}{' '}
                                                y{' '}
                                                {parsePrice({
                                                    price:
                                                        Math.round(
                                                            (valuation?.columns?.[1]?.max *
                                                                property?.attributes?.roofedSurface) /
                                                                100
                                                        ) * 100,
                                                    startStr: `${process.env.NEXT_PUBLIC_PRIMARY_CURRENCY} `
                                                })}
                                            </p>
                                        )}
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </PulppoLoader>
                </div>
            </div>

            <div className="flex w-full shrink-0 items-center justify-center gap-3 py-2">
                {!hidden?.actionShare ? (
                    <LoadingButton
                        className="h-10 w-1/3 font-medium"
                        type="default"
                        onClick={async () => {
                            const { destroy } = ShowContactList({
                                onSelect: (contact) =>
                                    shareValuation({
                                        property,
                                        contact,
                                        user,
                                        onSend: () => {
                                            destroy({
                                                triggerCancel: true
                                            });
                                        }
                                    }),
                                onAdd: (contact) =>
                                    shareValuation({
                                        property,
                                        contact,
                                        user,
                                        onSend: () => {
                                            destroy({
                                                triggerCancel: true
                                            });
                                        }
                                    })
                            });
                        }}
                    >
                        Compartir
                    </LoadingButton>
                ) : null}
                <Button
                    className={twMerge('h-10 w-2/3 font-medium', hidden?.actionShare ? 'mx-4 w-full' : '')}
                    type={!hidden?.actionShare ? 'primary' : 'secondary'}
                    onClick={openDrawer}
                >
                    Ver{disabled ? '' : ' o editar comparables'} ({validComparables?.length})
                </Button>
            </div>
        </div>
    );
};

export default Valuation;
