import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { renderToStaticMarkup } from 'react-dom/server';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { cloneElement, ReactElement, useEffect, useRef, useState } from 'react';
import { initialCoordinates } from '../../pages/acm/[id]/step-1';
import { notification } from 'antd';
import mapboxglSupported from '@mapbox/mapbox-gl-supported';
import { PulppoFallbackMap } from './PulppoFallbackMap';
import { fetcher } from '../../helpers/fetcher';

const toElement = (el) => {
    const output = document.createElement('div');
    const staticElement = renderToStaticMarkup(el);
    output.innerHTML = `${staticElement}`;
    return output;
};
mapboxgl.accessToken =
    process.env.NEXT_PUBLIC_MAPBPX_API_KEY ||
    'pk.eyJ1IjoibmFodWVsb2liZXJtYW4iLCJhIjoiY2t5Z2Q2Z2o1MDZxazMxcGN4ZnpseW9ieCJ9.AQts33Bb77UHxOaTqort4w';
const supported = mapboxglSupported.supported() !== false;

export const PulppoLayer = ({
    map = null as mapboxgl.Map,
    id = '',
    sourceId = '',
    sourceLayer = '',
    source = null,
    filter = [],
    sourceType = 'geojson',
    type = '' as any,
    paint = {} as any
}) => {
    const loaded = useRef<boolean>(null);
    useEffect(() => {
        if (!map) return;
        const layer = {
            id,
            type: type,
            source: sourceId,
            layout: {},
            'source-layer': sourceLayer,
            filter,
            // minzoom: minZoom,
            paint: paint
        };
        if (!sourceLayer) {
            delete layer['filter'];
            delete layer['source-layer'];
        }
        const addLayer = () => {
            if (source) {
                try {
                    map.addSource(sourceId, {
                        type: sourceType as any,
                        data: source
                    });
                } catch (err) {}
            }
            map.addLayer(layer);
        };
        map.on('load', () => {
            loaded.current = true;
            addLayer();
        });
        if (loaded.current) {
            addLayer();
        }
        return () => {
            try {
                map?.removeLayer(id);
                map?.removeSource(sourceId);
            } catch (e) {}
        };

        // map.addControl(controls.current);
    }, [map, source]);

    return <></>;
};

export const PulppoMarker = ({
    map = null as mapboxgl.Map,
    coordinates,
    onClick = null,
    children = null,
    setCoordinates = null,
    draggable = true
}) => {
    const marker = useRef<mapboxgl.Marker>(null);

    useEffect(() => {
        if (marker.current) {
            marker.current.remove();
        }
        if (!map) return;
        const el = toElement(children);
        el.onclick = onClick;
        if (!coordinates?.[0]) return;
        const markerInstance = new mapboxgl.Marker(children ? el : null, {
            color: '#fce02a',
            draggable: draggable
        })
            .setLngLat(coordinates)
            .addTo(map);
        markerInstance.on('click', onClick);
        if (setCoordinates)
            markerInstance.on('dragend', () => {
                const lngLat = markerInstance.getLngLat();
                setCoordinates([lngLat.lng, lngLat.lat]);
            });
        marker.current = markerInstance;
        return () => {
            markerInstance.remove();
        };
    }, [map, children, coordinates]);

    useEffect(() => {
        if (!coordinates?.[0]) return;
        marker.current?.setLngLat?.(coordinates);
    }, [marker, coordinates]);

    return <></>;
};

export const PulppoZoom = ({ map = null }) => {
    const controls = useRef(null);
    useEffect(() => {
        if (controls.current) return;
        if (!map) return;
        controls.current = new mapboxgl.NavigationControl();
        map.addControl(controls.current);
    }, [map]);
    return <></>;
};

export const PulppoDraw = ({ map = null, setPolygon }) => {
    const draw = useRef<MapboxDraw>(null);
    useEffect(() => {
        if (draw.current) return;
        if (!map) return;
        const drawInstance = new MapboxDraw({
            displayControlsDefault: false,
            controls: { polygon: true, trash: true }
        });
        map.on('draw.create', function (e) {
            const allFeatures = drawInstance.getAll();
            if (allFeatures.features.length > 1) {
                drawInstance.delete(allFeatures.features?.[0].id as string);
            }
            setPolygon(e.features?.[0]?.geometry.coordinates);
        });
        map.on('draw.delete', function () {
            setPolygon(null);
        });
        map.addControl(drawInstance);
        draw.current = drawInstance;
    }, [map]);
    return <></>;
};

export const PulppoMap = ({
    center: initialCenter = null as mapboxgl.LngLatLike,
    pitch = null,
    zoom: initialZoom = 15,
    bearing = null,
    containerStyle = {},
    className = '',
    children = [] as any,
    bounds = null,
    refreshMap = 0,
    route = null,
    setExternalMap = (map) => {}
}) => {
    const mapContainer = useRef(null);
    const [error, setError] = useState('');
    const [map, setMap] = useState<mapboxgl.Map>(null);
    const [loaded, setLoaded] = useState(false);
    const getRoute = async (points: Array<any>) => {
        // make a directions request using cycling profile
        // an arbitrary start will always be the same
        // only the end or destination will change
        return fetcher(
            `https://api.mapbox.com/directions/v5/mapbox/walking/${points
                .map((coordinates) => coordinates.join(','))
                .join(';')}?geometries=geojson&access_token=${mapboxgl.accessToken}`
        );
    };
    useEffect(() => {
        if (!initialCenter?.[0]) return;
        if (!map) return;
        map.flyTo({ center: initialCenter || initialCoordinates });
    }, [initialCenter, map]);

    useEffect(() => {
        if (!map || !bounds) return;
        if (!bounds?.flat()?.every((el) => Number.isFinite(el))) return;
        map.fitBounds(bounds, {
            padding: { left: 100, right: 100, top: 100, bottom: 100 }
        });
    }, [refreshMap, map, bounds]);

    useEffect(() => {
        if (map && loaded) {
            if (route) {
                getRoute(route).then((data) => {
                    const route = data?.routes?.[0]?.geometry?.coordinates;
                    const geojson = {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: 'LineString',
                            coordinates: route
                        }
                    };
                    if (!route) {
                        return notification.error({
                            message: `Hubo un error: La ruta no tiene coordenadas`
                        });
                    }
                    if (map.getSource('route')) {
                        (map.getSource('route') as any).setData(geojson);
                    } else {
                        map.addLayer({
                            id: 'route',
                            type: 'line',
                            source: {
                                type: 'geojson',
                                data: geojson
                            } as any,
                            layout: {
                                'line-join': 'round',
                                'line-cap': 'round'
                            },
                            paint: {
                                'line-color': '#000',
                                'line-width': 5,
                                'line-opacity': 0.75
                            }
                        });
                    }
                });

                // const prevRoute = map.getSource("route");
                // if (prevRoute) {
                //   map.removeLayer("route");
                //   map.removeSource("route");
                // }
                // map.addSource("route", {
                //   type: "geojson",
                //   data: {
                //     type: "Feature",
                //     properties: {},
                //     geometry: {
                //       type: "LineString",
                //       coordinates: route,
                //     },
                //   },
                // });
                // map.addLayer({
                //   id: "route",
                //   type: "line",
                //   source: "route",
                //   layout: {
                //     "line-join": "round",
                //     "line-cap": "round",
                //   },
                //   paint: {
                //     "line-color": "#2A98FA",
                //     "line-width": 8,
                //   },
                // });
            }
        }
    }, [map, route, loaded]);
    useEffect(() => {
        if (map) return; // initialize map only once
        if (!supported) {
            setError(mapboxglSupported.notSupportedReason());
            return;
        }
        const mapInstance = new mapboxgl.Map({
            cooperativeGestures: true,
            pitch,
            preserveDrawingBuffer: true,
            bearing,
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: (initialCenter?.[0] && initialCenter) || initialCoordinates,
            zoom: initialZoom
        } as any);
        mapInstance.on('load', () => {
            setLoaded(true);
        });
        // if (route) {
        //   map.on("load", () => {
        //     map.addSource("route", {
        //       type: "geojson",
        //       data: {
        //         type: "Feature",
        //         properties: {},
        //         geometry: {
        //           type: "LineString",
        //           coordinates: route,
        //         },
        //       },
        //     });
        //     map.addLayer({
        //       id: "route",
        //       type: "line",
        //       source: "route",
        //       layout: {
        //         "line-join": "round",
        //         "line-cap": "round",
        //       },
        //       paint: {
        //         "line-color": "#888",
        //         "line-width": 8,
        //       },
        //     });
        //   });
        // }
        setExternalMap(mapInstance);
        // mapInstance.on("moveend", () => {
        //   setBounds(mapInstance?.getBounds());
        // });

        // mapInstance.on("move", () => {
        //   setCenter(mapInstance.getCenter());
        // });
        setMap(mapInstance);
    }, []);
    //   const childrenWithProps = Children.map(children, (child) => {
    //     // Checking isValidElement is the safe way and avoids a typescript
    //     // error too.
    //     if (isValidElement(child)) {
    //       return cloneElement(child, { map: map?.current } as any);
    //     }
    //     return child;
    //   });
    if (!supported) {
        return (
            <PulppoFallbackMap
                center={initialCenter && { lng: initialCenter[0], lat: initialCenter[1] }}
                zoom={initialZoom}
                className={className}
                markers={
                    Array.isArray(children)
                        ? children
                              ?.flat()
                              .filter((child) => (child as ReactElement).props?.coordinates)
                              .map((child) => ({ lng: child.props.coordinates[0], lat: child.props.coordinates[1] }))
                        : children.props.coordinates
                          ? [{ lng: children.props.coordinates[0], lat: children.props.coordinates[1] }]
                          : []
                }
            />
        );
    }
    return (
        <div className={`${className}`} ref={mapContainer} style={containerStyle}>
            {error ? (
                <div className="flex h-full w-full items-center justify-center rounded border border-solid border-pulppo-status-dark-error bg-pulppo-status-light-error text-pulppo-status-dark-error">
                    Tu navegador no puede cargar mapas
                </div>
            ) : Array.isArray(children) ? (
                children
                    .flat()
                    .filter(Boolean)
                    .map((child, i) => cloneElement(child as any, { map: map, key: `map-${child?.key || i}` } as any))
            ) : (
                cloneElement(children as any, { map: map } as any)
            )}
        </div>
    );
};
