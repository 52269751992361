import { FormInstance } from 'antd';
import { IProperty } from '@pulppo/pulppo-models';
import { PromotionForm } from '../Property/Steps/step-2';
import PropertyListingDetails from '../PropertyDetail/PropertyListingDetails';

interface Props {
    property: Partial<IProperty>;
    form: FormInstance;
    isDisabled?: boolean;
}

const CapturePropertyPromotion = ({ form, property, isDisabled }: Props) => {
    return (
        <>
            <PropertyListingDetails property={property} isDisabled={isDisabled} />
            <div className="mt-6">
                <PromotionForm
                    className={{
                        title: 'text-sm font-semibold text-primary-black'
                    }}
                    form={form}
                    property={property}
                    isDisabled={isDisabled}
                />
            </div>
        </>
    );
};

export default CapturePropertyPromotion;
