import { DeleteOutlined, LoadingOutlined, PaperClipOutlined, SendOutlined } from '@ant-design/icons';
import { useTranslate } from '@tolgee/react';
import { Typography, Upload, notification, Select } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { useState, useEffect, createRef } from 'react';
import useSWRInfinite from 'swr/infinite';
import { twMerge } from 'tailwind-merge';
import { fetcher, api_url } from '../../helpers/fetcher';
import useOnScreen from '../../hooks/useOnScreen';
import { ImageUploaderService } from '../../services/ImageUploaderService';
import { Post, PostMedia } from '../Elements/Post';
import useUser from '../../hooks/useUser';
import { MapUserToSimpleUser } from '../../utils/MapContactToSimpleUser';
import Button from '../Elements/Button';
import TextArea from 'antd/lib/input/TextArea';
import axios from '../../utils/axios';

function chunkArray(arr, n) {
    const result = [];
    for (let i = 0; i < arr.length; i += n) {
        const chunk = arr.slice(i, i + n);
        result.push(chunk);
    }
    return result;
}

export const Community = () => {
    const user = useUser((u) => u.user);
    const [loading, setLoading] = useState(false);
    const [post, setPost] = useState({
        user: MapUserToSimpleUser(user),
        content: '',
        createdAt: new Date(),
        media: [],
        showTo: 'company'
    });
    const [error, setError] = useState('');
    const LIMIT = 5;
    const {
        data: postsArray,
        setSize,
        isValidating,
        isLoading,
        size,
        mutate: setPostsArray
    } = useSWRInfinite((index) => `${api_url}/post?limit=${LIMIT}&skip=${index * LIMIT}`, fetcher, {
        fallbackData: []
    });
    const { t } = useTranslate('common');

    useEffect(() => {
        localStorage.setItem('postsSince', new Date().toISOString());
    }, []);

    const posts = postsArray.flat();

    const setPosts = (newPosts, { revalidate = true } = {}) => {
        const chunkedArray = chunkArray(newPosts, LIMIT);
        setPostsArray(chunkedArray, { revalidate });
    };

    const onSend = async () => {
        if (!post.content && !post.media?.length) {
            return setError('Necesita agregar contenido');
        }
        const regex =
            /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*))/gi;
        post.content = post.content.replaceAll(regex, '[$1]($1)');
        await axios.post(`${api_url}/post`, post).then((r) => r.data);
        setPosts([post, ...posts], {
            revalidate: true
        });
        setPost({} as any);
    };
    const lastRef = createRef<HTMLDivElement>();

    const visible = useOnScreen(lastRef);
    const isLoadingMore = isLoading || (size > 0 && postsArray && typeof postsArray[size - 1] === 'undefined');
    const isEmpty = postsArray?.[0]?.length === 0;
    const isReachingEnd = isEmpty || (postsArray && postsArray[postsArray.length - 1]?.length < LIMIT);

    useEffect(() => {
        if (visible && !isLoadingMore && !isReachingEnd && !isValidating) setSize((size) => size + 1);
    }, [visible, isLoadingMore, isReachingEnd, isValidating, setSize]);

    return (
        <div className="flex h-full flex-col gap-4">
            <div className="p-2">
                <Typography.Title level={4}>{t('pulppo_community', 'Comunidad Pulppo')}</Typography.Title>
                <div className="flex flex-col items-stretch gap-2">
                    <TextArea
                        rows={3}
                        value={post.content}
                        onChange={(val) => {
                            setError('');
                            setPost((note) => ({
                                ...note,
                                createdAt: new Date(),
                                creator: MapUserToSimpleUser(user),
                                content: val.target?.value
                            }));
                        }}
                        onPressEnter={(ev) => {
                            if (ev.shiftKey) {
                                return;
                            }
                            onSend();
                        }}
                        className={twMerge(
                            'flex-1 rounded-none border border-solid border-pulppo-secondary-gray-disabled py-2 transition-all',
                            error && 'border-red-500'
                        )}
                        placeholder="Escribe aquí lo que quieras postear..."
                    />
                    <div className="flex items-center gap-2">
                        <Select
                            className="h-auto"
                            options={[
                                { label: 'Pulppo', value: 'pulppo', key: 'pulppo' },
                                { label: 'Inmobiliaria', value: 'company', key: 'company' }
                            ]}
                            onChange={(value) => {
                                setPost((prevPost) => ({
                                    ...prevPost,
                                    showTo: value
                                }));
                            }}
                            defaultValue="company"
                        />
                    </div>
                </div>
                {error && <p className="text-sm text-red-500">{error}</p>}
                <div className="mt-1 flex flex-col gap-1">
                    {post.media?.map((media) => (
                        <div key={media.url} className="relative">
                            <PostMedia media={media} />
                            <div
                                className="absolute right-4 top-4 z-10 cursor-pointer"
                                onClick={() => {
                                    setPost((post) => ({
                                        ...post,
                                        media: post.media.filter((m) => m.url !== media.url)
                                    }));
                                }}
                            >
                                <DeleteOutlined className="text-red-500 shadow-md" />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="mt-1 flex items-stretch justify-between gap-2">
                    <Upload
                        accept="image/*,video/*"
                        className="p-0"
                        multiple={false}
                        showUploadList={false}
                        customRequest={async ({ file, ...events }) => {
                            if ((file as RcFile).size > 24e6) {
                                return events.onError({
                                    name: 'file_too_large',
                                    message: 'El archivo es demasiado grande'
                                });
                            }

                            ImageUploaderService.upload(
                                file as RcFile,
                                `property/post/${user?.uid}/${(file as RcFile).name.replace(/\s/gi, '_')}`,
                                events,
                                true
                            ).catch((err) => console.error(`No se pudieron subir los archivos ${err}`));
                        }}
                        onChange={(info) => {
                            const { status } = info.file;

                            if (status === 'uploading') {
                                setLoading(true);
                            }

                            if (status === 'done') {
                                const newFileName = info.file.response;
                                const type = info.file.type.split('/')[0] || 'image';
                                setLoading(false);
                                setError('');
                                return setPost((n) => ({
                                    ...n,
                                    createdAt: new Date(),
                                    user: MapUserToSimpleUser(user),
                                    media: [
                                        ...(n.media || []),
                                        {
                                            url: newFileName,
                                            fileName: info.file.name,
                                            type,
                                            videoUrl: type === 'video' ? newFileName : null
                                        }
                                    ]
                                }));
                            } else if (status === 'error') {
                                setLoading(false);
                                notification.error({
                                    message: info.file.error?.message
                                });
                            }
                        }}
                    >
                        <Button
                            type="secondary"
                            className="h-auto py-2"
                            icon={loading ? <LoadingOutlined /> : <PaperClipOutlined />}
                            iconPosition="left"
                        >
                            Adjuntar
                        </Button>
                    </Upload>
                    <Button type="primary" onClick={onSend} icon={<SendOutlined className="ml-2" />} className="h-auto">
                        Enviar
                    </Button>
                </div>
            </div>
            <div className="flex flex-1 flex-col gap-4 overflow-y-scroll">
                {posts.map((post) => (
                    <Post
                        key={post._id}
                        post={post}
                        onPostsUpdate={(func) => {
                            const newPosts = func(posts);
                            setPosts(newPosts, { revalidate: false });
                        }}
                    />
                ))}
                {!!posts?.length && <div ref={lastRef} className="h-px w-full shrink-0 bg-white" />}
            </div>
        </div>
    );
};
