import { CloseCircleFilled, CloseCircleOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { ILocation, IProperty } from '@pulppo/pulppo-models';
import { Input } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { searchLocation, searchProperty } from '../../utils/Typesense';
import { useDebounce } from '../../hooks/debounce';
import { LocationOption, PropertyOption } from './TextSearchMobile ';
import { isPropertyInternalId } from '@pulppo/pulppo-models/build/utils/property';

export const TextSearch = ({
    selectedLocations,
    onSelectLocation,
    onSelectProperty,
    onEnter
}: {
    selectedLocations: ILocation[];
    onSelectLocation: (location: ILocation) => void;
    onSelectProperty: (property: IProperty) => void;
    onEnter: (value: string) => void;
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [open, setOpen] = useState(false);
    const [locations, setLocations] = useState<ILocation[]>([]);
    const ref = useRef(null);
    const container = useRef(null);
    const [width, setWidth] = useState(0);
    const [recent, setRecent] = useState<Array<ILocation>>([]);
    const [properties, setProperties] = useState<IProperty[]>([]);

    const handleClick = (e) => {
        if (container.current && !container.current.contains(e.target)) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    };

    const execOnEnter = (value: string) => {
        let search = value;
        if (isPropertyInternalId(value)) {
            search = search.toUpperCase();
        }
        onEnter(search);
        setOpen(false);
    };

    useDebounce(
        () => {
            if (searchTerm) {
                searchLocation(searchTerm).then((res) => setLocations(res.hits.map((hit) => hit.document) as any[]));
                searchProperty(searchTerm).then((res) => setProperties(res.hits.map((hit) => hit.document) as any[]));
            } else {
                setLocations([]);
                setProperties([]);
                onEnter('');
            }
        },
        500,
        [searchTerm]
    );

    useEffect(() => {
        if (ref.current) {
            setWidth(ref.current?.clientWidth);
        }
    }, [ref, open]);

    useEffect(() => {
        setRecent(
            JSON.parse(localStorage?.getItem('recent_locations') || '[]')
                ?.filter((l) => l.type && l.name && l.id && !selectedLocations?.some((elem) => elem.id === l.id))
                .slice(0, 2)
        );
    }, [open, selectedLocations]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    return (
        <div ref={container}>
            <div ref={ref}>
                <Input
                    onPressEnter={() => {
                        execOnEnter(searchTerm);
                    }}
                    value={searchTerm}
                    className="h-10 w-full rounded-none border border-solid border-gray-300 px-4 text-black"
                    placeholder="Buscar por dirección, id, código interno o palabra clave"
                    onChange={(ev) => setSearchTerm(ev.target.value)}
                    suffix={
                        !searchTerm ? (
                            <SearchOutlined className="text-xl text-black" />
                        ) : (
                            <CloseCircleFilled
                                className="cursor-pointer text-lg text-black"
                                onClick={() => {
                                    setSearchTerm('');
                                }}
                            />
                        )
                    }
                />
            </div>
            {open && (
                <div
                    className="absolute z-50 mt-1 flex h-auto flex-col rounded-md bg-white px-7 py-4 shadow-md"
                    style={{ width: `${width}px` }}
                >
                    {searchTerm?.replace(/\s/g, '')?.length > 0 && (
                        <p
                            onClick={() => {
                                execOnEnter(searchTerm);
                            }}
                            className="cursor-pointer px-2 py-1 hover:bg-pulppo-status-light-disabled"
                        >
                            Buscar &quot;{searchTerm}&quot;
                        </p>
                    )}
                    <div className="my-3 h-px w-full bg-gray-200"></div>
                    {selectedLocations?.length ? (
                        <>
                            <div className="flex flex-wrap items-center gap-2">
                                {selectedLocations.map((loc) => (
                                    <div
                                        key={loc.id}
                                        onClick={() => onSelectLocation(loc)}
                                        className="flex h-6 cursor-pointer items-center justify-center gap-2 bg-black px-2 text-sm text-white"
                                    >
                                        <p>{loc.name}</p>
                                        <CloseCircleOutlined className="text-base" />
                                    </div>
                                ))}
                            </div>
                            <div className="my-3 h-px w-full bg-gray-200"></div>
                        </>
                    ) : null}
                    {recent?.length ? (
                        <>
                            <div className="flex gap-4">
                                <p className="text-pulppo-status-dark-disabled">Recientes</p>
                                <div className="flex flex-wrap gap-2">
                                    {recent.map((loc) => (
                                        <div
                                            key={loc.id}
                                            onClick={() => {
                                                onSelectLocation(loc);
                                                setOpen(false);
                                            }}
                                            className="flex h-6 cursor-pointer items-center justify-center gap-2 border border-solid border-gray-300 bg-white px-2 text-sm text-pulppo-status-dark-disabled"
                                        >
                                            <p>{loc.name}</p>
                                            <PlusCircleOutlined className="text-base" />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="my-3 h-px w-full bg-gray-200"></div>
                        </>
                    ) : null}
                    {locations?.length ? (
                        <>
                            {' '}
                            <p className="mb-2 text-pulppo-status-dark-disabled">Ubicaciones</p>
                            <div
                                className="flex-0 flex shrink-0 flex-col gap-1 overflow-y-auto"
                                style={{
                                    maxHeight: '95px'
                                }}
                            >
                                {locations?.map((location) => (
                                    <LocationOption
                                        selected={selectedLocations?.some((elem) => elem.id === location.id)}
                                        onClick={() => {
                                            onSelectLocation(location);
                                            setSearchTerm('');
                                            setOpen(false);
                                        }}
                                        location={location}
                                        key={location.id}
                                    />
                                ))}
                            </div>{' '}
                        </>
                    ) : (
                        <p className="text-pulppo-status-dark-disabled">Ubicaciones | Sin resultados</p>
                    )}
                    <div className="my-3 h-px w-full bg-gray-200"></div>
                    {properties?.length ? (
                        <>
                            <p className="mb-2 text-pulppo-status-dark-disabled">Propiedades</p>
                            <div
                                className="flex h-auto flex-1 shrink-0 flex-col gap-1 overflow-y-auto overflow-x-hidden"
                                style={{
                                    maxHeight: '260px'
                                }}
                            >
                                {properties?.map((property) => (
                                    <PropertyOption
                                        onClick={() => onSelectProperty(property)}
                                        property={property}
                                        key={property._id.toString()}
                                    />
                                ))}
                            </div>
                        </>
                    ) : (
                        <p className="text-pulppo-status-dark-disabled">Propiedades | Sin resultados</p>
                    )}
                </div>
            )}
        </div>
    );
};
