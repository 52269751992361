import { create } from 'zustand';
import { IOperation } from '@pulppo/pulppo-models';

interface UseOperationStore {
    initialOperation?: IOperation;
    operation: IOperation;
    temporaryId?: string;
    invoiceUrl?: string;
    totalCommissionRecommendedPrice?: number;
    setOperation: (operation: IOperation | Partial<IOperation> | ((operation: IOperation) => IOperation)) => void;
    setInitialOperation: (operation: IOperation) => void;
    setTemporaryId: (temporaryId: string) => void;
    setInvoiceUrl: (invoiceUrl: string) => void;
    setTotalCommissionRecommendedPrice: (totalCommissionRecommendedPrice: number) => void;
    cleanOperation: () => void;
}

export const useOperation = create<UseOperationStore>((set, get) => ({
    initialOperation: {} as any,
    operation: {} as any,
    setOperation: async (operationUpdate) => {
        const { operation: oldOperation } = get();
        if (!operationUpdate) {
            return set({ operation: {} as any });
        }
        const newOperation =
            typeof operationUpdate === 'function'
                ? operationUpdate(oldOperation)
                : { ...oldOperation, ...operationUpdate };
        set({ operation: newOperation });
        return;
    },
    setInitialOperation: (operation) => {
        set({ initialOperation: operation });
    },
    setTemporaryId: (temporaryId) => set({ temporaryId }),
    setInvoiceUrl: (invoiceUrl) => set({ invoiceUrl }),
    setTotalCommissionRecommendedPrice: (totalCommissionRecommendedPrice) => set({ totalCommissionRecommendedPrice }),
    cleanOperation: () => {
        set({
            operation: {} as any,
            initialOperation: {} as any,
            temporaryId: null,
            invoiceUrl: null,
            totalCommissionRecommendedPrice: null
        });
    }
}));

export default useOperation;
