import dayjs from 'dayjs';

export const isValidDate = (d: any) => {
    if (!d) return false;
    return (
        (typeof d === 'number' && !!d) ||
        (typeof d === 'object' &&
            ((d instanceof dayjs && (d as dayjs.Dayjs).isValid()) || (d instanceof Date && !isNaN(d.getTime()))))
    );
};

export function formatDateNatural(date: Date | string) {
    const inputDate = dayjs(date).tz();

    const calendarFormat = {
        sameDay: '[Hoy a las] h:mm A',
        nextDay: '[Mañana a las] h:mm A',
        lastDay: '[Ayer a las] h:mm A',
        sameElse: 'DD/MM/YYYY [a las] h:mm A',
        lastWeek: 'DD/MM/YYYY [a las] h:mm A',
        nextWeek: 'dddd [a las] h:mm A'
    };

    return inputDate.calendar(null, calendarFormat);
}
