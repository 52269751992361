import { useEffect, useRef } from 'react';

export const useDebounce = (cb: Function, millis: number, listening: Array<any>, firstUpdateInit = true) => {
    const firstUpdate = useRef(firstUpdateInit);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        const timer = setTimeout(cb, millis);
        return () => clearTimeout(timer);
    }, listening);
};
