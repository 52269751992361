import Image from "next/image";
import { Tag } from 'antd';
import { ISimpleProperty } from "@pulppo/pulppo-models";
import { useState } from "react";
import PropertyDetail from "../PropertyDetail/PropertyDetail";
interface PropertyNewProps {
    link?: string;
    address?: string;
    price?: string;
    operationType?: string;
    image?: string;
    currency?: string;
    state?: string;
    neighborhood?: string;
    property?: ISimpleProperty;
}

export const PropertyNew: React.FC<PropertyNewProps> = ({
  address,
  price,
  operationType,
  image,
  currency,
  state,
  neighborhood,
  property: simpleProperty
}) => {

    const [showPropertyModal, setShowPropertyModal] = useState<boolean>(false);

  return (
    <div className="w-full max-w-3xl border-b border-gray-300 pb-3">
      <div className="px-4 pt-2">
        <div className="flex justify-center items-end"> 
          <Tag color="success" className="mb-4 text-lg">Propiedad disponible</Tag>
        </div>

        <div className="flex gap-4 items-start cursor-pointer border border-solid border-gray-300 rounded-md p-2" onClick={() => {
          setShowPropertyModal(true);
        }}>
          <div className="relative w-16 h-16 flex-shrink-0 rounded-md overflow-hidden">
            <Image
              src={image || "/placeholder.svg"}
              alt={address}
              layout="fill"
              objectFit="cover"
            />
          </div>

          <div className="flex-1">
            <div className="text-sm font-medium text-black">{address}</div>
            <div className="text-xs text-gray-600">
              {state ? `${state}, ` : ""}
              {neighborhood ? `${neighborhood}` : ""}
            </div>
          </div>

          <div className="text-right flex flex-col items-end">
            <div className="text-md font-semibold text-black">{currency} {price}</div>
            <div className="bg-gray-900 text-white text-sm rounded-sm px-4 py-1 mt-2">
              {operationType}
            </div>
          </div>
        </div>
      </div>
      { simpleProperty && <PropertyDetail property={simpleProperty} visible={showPropertyModal} onClose={() => setShowPropertyModal(false)} />}
    </div>
  );
};

