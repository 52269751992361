export const FilterTag = ({ text }: { text: string }) => {
    return (
        <div
            className="flex h-6 items-center justify-center px-2 text-sm text-nowrap whitespace-nowrap"
            style={{
                backgroundColor: '#EAEDF2'
            }}
        >
            {text}
        </div>
    );
};
