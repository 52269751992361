import { BasicColor } from '../../components/Home/AgentsList';

export interface UseMetricDrawerData {
    id: string;
    value: {
        y: number;
        x: string | number;
    };
    verticalTextY?: string;
    status: BasicColor;
}

interface UseMetricDrawerProps {
    data: Array<UseMetricDrawerData>;
    mostRecentData?: UseMetricDrawerData;
}

const calcDifferenceBeforeLastWeek = (array: Array<UseMetricDrawerData>) => {
    const lastWeek = array[array.length - 1];
    const beforeLastWeek = array[array.length - 2];
    return (lastWeek?.value.y || 0) - (beforeLastWeek?.value.y || 0);
};

const useMetricDrawer = ({ data = [], mostRecentData }: UseMetricDrawerProps) => {
    let dataWithMostRecentData = [...(data || []).reverse(), ...(mostRecentData ? [mostRecentData] : [])];
    const differenceBeforeLastWeek = calcDifferenceBeforeLastWeek(dataWithMostRecentData);
    const lastWeekValue = dataWithMostRecentData[dataWithMostRecentData.length - 1]?.value.y;
    const beforeLastWeekValue = dataWithMostRecentData[dataWithMostRecentData.length - 2]?.value.y;
    const lastWeekDate = dataWithMostRecentData[dataWithMostRecentData.length - 1]?.value.x;
    const lastWeekVerticalTextY = dataWithMostRecentData[dataWithMostRecentData.length - 1]?.verticalTextY;
    const beforeLastWeekVerticalTextY = dataWithMostRecentData[dataWithMostRecentData.length - 2]?.verticalTextY;

    return {
        state: {
            metrics: dataWithMostRecentData,
            differenceBeforeLastWeek,
            lastWeekValue,
            lastWeekDate,
            beforeLastWeekValue,
            lastWeekVerticalTextY,
            beforeLastWeekVerticalTextY
        }
    };
};

export default useMetricDrawer;
