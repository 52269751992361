import useMetric from './useMetric';
import { MetricType } from '@pulppo/pulppo-models/build/enums/MetricType';
import {
    currentWeekMetricFromSelect,
    getColorVerifyIsMetricIsGood,
    interactionsToQuality
} from '../../utils/ConstantsMetrics';
import MetricChart from '../../components/Home/MetricChart';

interface Props {
    type: MetricType;
    agentId?: string;
}

const useMetricView = ({ type, agentId }: Props) => {
    const select = type ? [type] : [];
    const currentWeek = currentWeekMetricFromSelect(select);
    const {
        state: { data: metrics, isLoading: isLoadingInitMetrics }
    } = useMetric({
        currentWeek,
        limit: 2,
        select,
        simple: false,
        agentId
    });
    const {
        state: { data: allMetrics, isLoading: isLoadingAllMetrics }
    } = useMetric({
        currentWeek: false,
        limit: 11,
        select,
        simple: false,
        agentId
    });
    let PrimaryContent = <></>;
    let actualValue = undefined;

    if (type === MetricType.UnansweredLeads) {
        const unansweredLeads = metrics?.[0]?.data?.unansweredLeads || 0;
        const textColor = getColorVerifyIsMetricIsGood(type, unansweredLeads);

        PrimaryContent = (
            <p>
                Hoy tienes <br />
                <span
                    style={{
                        fontSize: '44px'
                    }}
                    className={`font-medium ${textColor}`}
                >
                    {unansweredLeads}
                </span>
                <br /> consultas sin responder.
            </p>
        );
        if (currentWeek) {
            actualValue = unansweredLeads;
        }
    } else if (type === MetricType.FirstResponseTime) {
        const firstResponseTimeNow = Math.trunc(metrics?.[0]?.data.firstResponseTime / 60) || 0;
        const firstResponseTimePast = Math.trunc(metrics?.[1]?.data.firstResponseTime / 60) || 0;
        const firstResponseTimeDifference = firstResponseTimePast - firstResponseTimeNow;
        const isPositive = firstResponseTimeDifference > 0;
        const initFirstResponseTime = isPositive ? 'Reducción' : 'Aumento';
        const firstResponseTimeDifferenceAbs = Math.abs(firstResponseTimeDifference);
        const textColor = isPositive ? 'text-pulppo-status-dark-success' : 'text-pulppo-status-dark-error';
        const textColorNow = getColorVerifyIsMetricIsGood(type, firstResponseTimeNow);
        const textColorPast = getColorVerifyIsMetricIsGood(type, firstResponseTimePast);

        PrimaryContent = (
            <p>
                La semana pasada tu tiempo de primera respuesta fue de <br />
                <span
                    style={{
                        fontSize: '44px'
                    }}
                    className={`font-medium ${textColorNow}`}
                >
                    {firstResponseTimeNow}
                </span>
                <br /> minutos.
                <br />
                <span className="mx-auto max-w-56">
                    <span className={`font-semibold ${textColor}`}>
                        {initFirstResponseTime} de {firstResponseTimeDifferenceAbs}
                    </span>{' '}
                    minutos respecto a la semana anterior que fue de{' '}
                    <span className={`font-semibold ${textColorPast}`}>{firstResponseTimePast}</span> minutos.
                </span>
            </p>
        );
        if (currentWeek) {
            actualValue = firstResponseTimeNow;
        }
    } else if (type === MetricType.TasksPendingClient || type === MetricType.TasksExpiredClient) {
        const tasks = metrics?.[0]?.data?.tasksPendingClient || 0;
        const tasksExpired = metrics?.[0]?.data?.tasksExpiredClient || 0;
        const tasksTotal = tasks + tasksExpired;
        const textColor = getColorVerifyIsMetricIsGood(type, tasksTotal);

        PrimaryContent = (
            <p>
                Hoy tienes <br />
                <span
                    style={{
                        fontSize: '44px'
                    }}
                    className={`font-medium ${textColor}`}
                >
                    {tasksTotal}
                </span>
                <br /> tareas pendientes de clientes.
                <br />
                <span className="mx-auto max-w-56">
                    De las <span className={`font-semibold ${textColor}`}>{tasksTotal}</span> tareas,{' '}
                    <span className="font-semibold text-pulppo-status-dark-error">{tasksExpired}</span> están caducadas
                    porque se venció el tiempo esperado de realización.
                </span>
            </p>
        );
        if (currentWeek) {
            actualValue = tasksTotal;
        }
    } else if (type === MetricType.AvgResponseTime) {
        const avgResponseTimeNow = Math.trunc(metrics?.[0]?.data.avgResponseTime / 60) || 0;
        const avgResponseTimePast = Math.trunc(metrics?.[1]?.data.avgResponseTime / 60) || 0;
        const avgResponseTimeDifference = avgResponseTimePast - avgResponseTimeNow;
        const isPositive = avgResponseTimeDifference > 0;
        const initAvgResponseTime = isPositive ? 'Reducción' : 'Aumento';
        const avgResponseTimeDifferenceAbs = Math.abs(avgResponseTimeDifference);
        const textColor = isPositive ? 'text-pulppo-status-dark-success' : 'text-pulppo-status-dark-error';
        const textColorNow = getColorVerifyIsMetricIsGood(type, avgResponseTimeNow);
        const textColorPast = getColorVerifyIsMetricIsGood(type, avgResponseTimePast);

        PrimaryContent = (
            <p>
                La semana pasada tu tiempo medio de respuesta fue de <br />
                <span
                    style={{
                        fontSize: '44px'
                    }}
                    className={`font-medium ${textColorNow}`}
                >
                    {avgResponseTimeNow}
                </span>
                <br /> minutos.
                <br />
                <span className="mx-auto max-w-56">
                    <span className={`font-semibold ${textColor}`}>
                        {initAvgResponseTime} de {avgResponseTimeDifferenceAbs}
                    </span>{' '}
                    minutos respecto a la semana anterior que fue de{' '}
                    <span className={`font-semibold ${textColorPast}`}>{avgResponseTimePast}</span> minutos.
                </span>
            </p>
        );
        if (currentWeek) {
            actualValue = avgResponseTimeNow;
        }
    } else if (type === MetricType.Interactions) {
        const interactions = metrics?.[0]?.data?.interactions || 0;
        const interactionsQuality = interactionsToQuality(interactions);
        const interactionsPast = metrics?.[1]?.data?.interactions || 0;
        const interactionsQualityPast = interactionsToQuality(interactionsPast);
        const interactionsDifference = interactions - interactionsPast;
        const isPositive = interactionsDifference > 0;
        const initInteractions =
            interactionsQuality === interactionsQualityPast ? 'Esta igual' : isPositive ? 'Mejoró' : 'Empeoró';
        const textColorNow = getColorVerifyIsMetricIsGood(type, interactions);
        const textColorPast = getColorVerifyIsMetricIsGood(type, interactionsPast);
        const initInteractionsTextColor =
            initInteractions === 'Esta igual'
                ? ''
                : isPositive
                  ? 'text-pulppo-status-dark-success'
                  : 'text-pulppo-status-dark-error';

        PrimaryContent = (
            <p>
                La semana pasada tu calidad de seguimiento fue <br />
                <span
                    style={{
                        fontSize: '44px'
                    }}
                    className={`font-medium ${textColorNow}`}
                >
                    {interactionsQuality}
                </span>
                <br />
                <span className="mx-auto max-w-56">
                    <span className={initInteractionsTextColor}>{initInteractions}</span> respecto a la semana anterior
                    que fue <span className={`font-semibold ${textColorPast}`}>{interactionsQualityPast}</span>.
                </span>
            </p>
        );
        if (currentWeek) {
            actualValue = interactionsQuality;
        }
    } else if (type === MetricType.AvgSuggestedProperties) {
        const avgSuggestedProperties = Math.round(metrics?.[0]?.data?.avgSuggestedProperties || 0);
        const avgSuggestedPropertiesPast = Math.round(metrics?.[1]?.data?.avgSuggestedProperties || 0);
        const avgSuggestedPropertiesDifference = avgSuggestedProperties - avgSuggestedPropertiesPast;
        const isPositive = avgSuggestedPropertiesDifference > 0;
        const initAvgSuggestedProperties = isPositive ? 'más' : 'menos';
        const avgSuggestedPropertiesDifferenceAbs = Math.abs(avgSuggestedPropertiesDifference);
        const textColor = isPositive ? 'text-pulppo-status-dark-success' : 'text-pulppo-status-dark-error';
        const isPlural = avgSuggestedPropertiesDifferenceAbs > 1 ? 'es' : '';
        const textColorNow = getColorVerifyIsMetricIsGood(type, avgSuggestedProperties);
        const textColorPast = getColorVerifyIsMetricIsGood(type, avgSuggestedPropertiesPast);

        PrimaryContent = (
            <p>
                La semana pasada sugeriste en promedio <br />
                <span
                    style={{
                        fontSize: '44px'
                    }}
                    className={`font-medium ${textColorNow}`}
                >
                    {avgSuggestedProperties}
                </span>
                <br /> propiedades por cada búsqueda creada en los últimos 30 días.
                <br />
                <span className="mx-auto max-w-56">
                    <span className={`font-semibold ${textColor}`}>
                        {avgSuggestedPropertiesDifferenceAbs} propiedad
                        {isPlural} {initAvgSuggestedProperties}
                    </span>{' '}
                    respecto a la semana anterior que fue de{' '}
                    <span className={`font-semibold ${textColorPast}`}>{avgSuggestedPropertiesPast}</span>
                </span>
            </p>
        );
        if (currentWeek) {
            actualValue = avgSuggestedProperties;
        }
    } else if (type === MetricType.VisitRate) {
        const visitRateNow = Math.round(metrics?.[0]?.data?.visitRate || 0);
        const visitRatePast = Math.round(metrics?.[1]?.data?.visitRate || 0);
        const visitsTotal = metrics?.[0]?.data?.visitsTotal || 0;
        const visitRateDifference = visitRateNow - visitRatePast;
        const isPositive = visitRateDifference > 0;
        const initVisitRate = isPositive ? 'Aumento' : 'Reducción';
        const visitRateDifferenceAbs = Math.abs(visitRateDifference);
        const textColor = isPositive ? 'text-pulppo-status-dark-success' : 'text-pulppo-status-dark-error';
        const textColorNow = getColorVerifyIsMetricIsGood(type, visitRateNow);
        const textColorPast = getColorVerifyIsMetricIsGood(type, visitRatePast);

        PrimaryContent = (
            <p>
                La semana pasada tu tasa de visita de los últimos 30 días fue del <br />
                <span
                    style={{
                        fontSize: '44px'
                    }}
                    className={`font-medium ${textColorNow}`}
                >
                    {visitRateNow}%
                </span>
                .
                <br />
                <span className="mx-auto max-w-56">
                    <span className={`font-semibold ${textColor}`}>
                        {initVisitRate} de {visitRateDifferenceAbs}%
                    </span>{' '}
                    respecto a la semana anterior que fue de{' '}
                    <span className={`font-semibold ${textColorPast}`}>{visitRatePast}%</span>.
                </span>
                <br />
                <span className="mx-auto max-w-56">
                    La semana pasada realizaste{' '}
                    <span
                        className={`font-semibold ${visitsTotal > 0 ? 'text-pulppo-status-dark-success' : 'text-pulppo-status-dark-error'}`}
                    >
                        {visitsTotal}
                    </span>{' '}
                    visitas.
                </span>
            </p>
        );
        if (currentWeek) {
            actualValue = visitRateNow;
        }
    } else if (type === MetricType.TasksPendingProperty || type === MetricType.TasksExpiredProperty) {
        const tasks = metrics?.[0]?.data?.tasksPendingProperty || 0;
        const tasksExpired = metrics?.[0]?.data?.tasksExpiredProperty || 0;
        const tasksTotal = tasks + tasksExpired;
        const textColor = getColorVerifyIsMetricIsGood(type, tasksTotal);

        PrimaryContent = (
            <p>
                Hoy tienes <br />
                <span
                    style={{
                        fontSize: '44px'
                    }}
                    className={`font-medium ${textColor}`}
                >
                    {tasksTotal}
                </span>
                <br /> tareas pendientes de propiedades.
                <br />
                <span className="mx-auto max-w-56">
                    De las <span className={`font-semibold ${textColor}`}>{tasksTotal}</span> tareas,{' '}
                    <span className="font-semibold text-pulppo-status-dark-error">{tasksExpired}</span> están caducadas
                    porque se venció el tiempo esperado de realización.
                </span>
            </p>
        );
        if (currentWeek) {
            actualValue = tasksTotal;
        }
    } else if (type === MetricType.PropertiesSale) {
        const propertiesSale = metrics?.[0]?.data?.propertiesSale || 0;
        const propertiesTotal = metrics?.[0]?.data?.properties || 0;
        const propertiesSalePercentage = Math.round((propertiesSale / propertiesTotal) * 100) || 0;
        const textColor = getColorVerifyIsMetricIsGood(type, propertiesSalePercentage);

        PrimaryContent = (
            <p>
                Hoy tienes el <br />
                <span
                    style={{
                        fontSize: '44px'
                    }}
                    className={`font-medium ${textColor}`}
                >
                    {propertiesSalePercentage}%
                </span>
                <br /> de tu inventario en venta.
            </p>
        );
        if (currentWeek) {
            actualValue = propertiesSalePercentage;
        }
    } else if (type === MetricType.Properties) {
        const propertiesTotal = metrics?.[0]?.data?.properties || 0;
        const textColor = getColorVerifyIsMetricIsGood(type, propertiesTotal);

        PrimaryContent = (
            <p>
                Hoy tienes <br />
                <span
                    style={{
                        fontSize: '44px'
                    }}
                    className={`font-medium ${textColor}`}
                >
                    {propertiesTotal}
                </span>
                <br /> propiedades activas.
            </p>
        );
        if (currentWeek) {
            actualValue = propertiesTotal;
        }
    } else if (type === MetricType.OfferRate) {
        const offerRateNow = Math.round(metrics?.[0]?.data?.offerRate || 0);
        const offerRatePast = Math.round(metrics?.[1]?.data?.offerRate || 0);
        const offerRateDifference = offerRateNow - offerRatePast;
        const isPositive = offerRateDifference > 0;
        const initOfferRate = isPositive ? 'Crecimiento' : 'Reducción';
        const offerRateDifferenceAbs = Math.abs(offerRateDifference);
        const textColor = isPositive ? 'text-pulppo-status-dark-success' : 'text-pulppo-status-dark-error';

        PrimaryContent = (
            <p>
                La semana pasada tu tasa de oferta de los últimos 30 días fue del <br />
                <span
                    style={{
                        fontSize: '44px'
                    }}
                    className={`font-medium ${textColor}`}
                >
                    {offerRateNow}%
                </span>
                .
                <br />
                <span className="mx-auto max-w-56">
                    <span className={`font-semibold ${textColor}`}>
                        {initOfferRate} de {offerRateDifferenceAbs}%
                    </span>{' '}
                    respecto a la semana anterior que fue de{' '}
                    <span
                        className={`font-semibold ${!isPositive ? 'text-pulppo-status-dark-success' : 'text-pulppo-status-dark-error'}`}
                    >
                        {offerRatePast}%
                    </span>
                    .
                </span>
            </p>
        );
        if (currentWeek) {
            actualValue = offerRateNow;
        }
    }
    const ChartContent = MetricChart({
        data: allMetrics,
        type,
        simple: true
    }).Chart;

    return {
        state: {
            actualValue,
            isLoading: {
                initMetrics: isLoadingInitMetrics,
                allMetrics: isLoadingAllMetrics
            },
            ContentHow: {
                Primary: PrimaryContent,
                Chart: ChartContent
            }
        }
    };
};

export default useMetricView;
