import { IProperty } from '@pulppo/pulppo-models';
import { PropertyStep4 } from '../Property/Steps/step-4';
import useUser from '../../hooks/useUser';

export const PropertyLegal = ({ property }: { property: IProperty }) => {
    const user = useUser((u) => u.user);

    return (
        <PropertyStep4
            property={property as IProperty & { contract: IProperty['contract'] }}
            legalAdmin={user?.features?.legal}
        />
    );
};
