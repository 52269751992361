import React from 'react';
import { PulppoMap, PulppoMarker, PulppoZoom } from '../Elements/PulppoMap';

export const MapsGallery = ({ coordinates }) => {
    return (
        <PulppoMap
            center={[coordinates[0], coordinates[1]]}
            containerStyle={{
                height: '400px',
                width: '100%'
            }}
        >
            <PulppoMarker draggable={false} coordinates={[coordinates[0], coordinates[1]]} />
            <PulppoZoom />
        </PulppoMap>
    );
};
