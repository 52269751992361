import { ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons';
import Button from '../Elements/Button';
import { PulppoDrawer } from '../Elements/PulppoDrawer';
import useSWR from 'swr';
import useHomeUser from '../../hooks/useHomeUser';
import { IClose } from '@pulppo/pulppo-models';
import { api_url, fetcher } from '../../helpers/fetcher';
import { IResponse } from '../../types';
import { PulppoLoader } from '../Elements/PulppoLoader';
import Link from 'next/link';
import { useTranslate } from '@tolgee/react';

const formatNumber = (number) => {
    return `${(Math.round(number / 1000) || 0).toLocaleString()} K`;
};

export const Operation = ({ show, onClose }) => {
    const uid = useHomeUser((u) => u.user?.uid);
    const cid = useHomeUser((u) => u.user?.company?._id);
    const { data: close, isLoading: isLoadingClose } = useSWR<IClose>(
        uid && cid && `${api_url}/close/current?companyId=${cid}`,
        fetcher
    );
    const { t } = useTranslate('common');
    const { data: operations, isLoading: isLoadingOperations } = useSWR<
        IResponse<{
            offer: number;
            contract: number;
            closed: number;
            offerNextMonth: number;
            offerExpired: number;
        }>
    >(uid && cid && `${api_url}/metrics/operations/${cid}`, fetcher);
    const potential = operations?.data?.offer + operations?.data?.contract + operations?.data?.closed;
    if (close && !close?.objective) {
        close.objective = 1;
    }

    return (
        <PulppoDrawer visible={show} onClose={onClose} title="Alcance de objetivo">
            <PulppoLoader loading={isLoadingClose || isLoadingOperations} />
            <div className="flex h-full flex-col justify-between">
                <div className="flex-1">
                    <div className="mb-6 flex cursor-pointer items-center gap-2 bg-pulppo-status-light-warning p-3 text-pulppo-status-dark-warning">
                        <ExclamationCircleFilled className="text-xl" />
                        <div className="font-light">
                            Llevas alcanzado el{' '}
                            <b className="font-medium">
                                {Math.floor((100 * (close?.comission || 0)) / (close?.objective || 1)) || 0}%
                            </b>{' '}
                            del objetivo
                        </div>
                    </div>
                    <div className="mt-2 flex flex-col gap-3">
                        <p>
                            Mes actual: <span className="font-bold uppercase">{close?.id}</span>
                        </p>
                        <div className="mt-2 flex flex-col gap-2">
                            <div className="relative flex w-full items-center justify-between bg-pulppo-status-light-disabled px-6 py-2">
                                <span>{t('Offers', 'Ofertas')}</span>
                                <span>{formatNumber(operations?.data?.offer || 0)}</span>
                                <div className="translate absolute right-6 top-full flex h-5 w-5 -translate-y-1/2 transform items-center justify-center rounded-full bg-white">
                                    <PlusOutlined className="text-xs" />
                                </div>
                            </div>
                            <div className="relative flex w-full items-center justify-between bg-pulppo-status-light-disabled px-6 py-2">
                                <span>{t('Contracts', 'Contratos')}</span>
                                <span>{formatNumber(operations?.data?.contract || 0)}</span>
                                <div className="translate absolute right-6 top-full flex h-5 w-5 -translate-y-1/2 transform items-center justify-center rounded-full bg-white">
                                    <PlusOutlined className="text-xs" />
                                </div>
                            </div>
                            <div className="relative flex w-full items-center justify-between bg-pulppo-status-light-disabled px-6 py-2">
                                <span>Cierres</span>
                                <span>{formatNumber(operations?.data?.closed || 0)}</span>
                                <div className="translate absolute right-6 top-full flex h-5 w-5 -translate-y-1/3 transform items-center justify-center rounded-full bg-white font-bold">
                                    =
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full flex-col items-center justify-between gap-2 bg-pulppo-status-light-disabled px-6 py-2">
                            <div className="flex w-full items-center justify-between">
                                <span>Potencial</span>
                                <span>{formatNumber(potential || 0)}</span>
                            </div>
                            <div className="flex w-full items-center justify-between">
                                <span>Objetivo</span>
                                <span>{formatNumber(close?.objective || 0)}</span>
                            </div>
                            <div className="flex w-full items-center justify-between">
                                <span>Alcance potencial</span>
                                <span>{Math.floor((100 * potential) / close?.objective) || 0}%</span>
                            </div>
                        </div>
                        <div className="flex w-full flex-col items-center justify-between gap-2 bg-pulppo-status-light-disabled px-6 py-2">
                            <div className="flex w-full items-center justify-between">
                                <span>Cierres (confirmados)</span>
                                <span>{formatNumber(operations?.data?.closed || 0)}</span>
                            </div>
                            <div className="flex w-full items-center justify-between">
                                <span>Objetivo</span>
                                <span>{formatNumber(close?.objective || 0)}</span>
                            </div>
                            <div className="flex w-full items-center justify-between">
                                <span>Alcance confirmado</span>
                                <span>
                                    {Math.floor((100 * operations?.data?.closed || 0) / close?.objective) || 0}%
                                </span>
                            </div>
                        </div>
                        <p>Otras</p>
                        <div className="flex flex-col gap-2">
                            <div className="flex w-full items-center justify-between bg-pulppo-status-light-disabled px-6 py-2">
                                <span>Próximos meses</span>
                                <span>{formatNumber(operations?.data?.offerNextMonth || 0)}</span>
                            </div>
                            <div className="flex w-full items-center justify-between bg-pulppo-status-light-error px-6 py-2 text-pulppo-status-dark-error">
                                <span>Operaciones vencidas</span>
                                <span>{formatNumber(operations?.data?.offerExpired || 0)}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <Link href={'/operations'}>
                    <Button type="primary">Ir a operaciones</Button>
                </Link>
            </div>
        </PulppoDrawer>
    );
};
