import { useEffect, useState } from 'react';
import { BasicInformation } from './BasicInformation';
import { useRouter } from 'next/router';
import { IAgent, IContact } from '@pulppo/pulppo-models';

export const CreateContact = ({
    setCurrentStep,
    onCancel,
    initialValue,
    user = undefined,
    onFinish = undefined,
    useLocalContact = false,
    migrate
}: {
    onCancel: () => void;
    initialValue?: IContact;
    user: IAgent;
    setCurrentStep: (step: number) => void;
    onFinish: (contact: IContact) => void;
    useLocalContact?: boolean;
    migrate?: {
        type: 'phone' | 'email';
        fromId: string;
    };
}) => {
    const [contact, setContact] = useState(initialValue);
    const router = useRouter();

    useEffect(() => {
        setContact(initialValue);
        setCurrentStep(0);
    }, [initialValue]);

    return (
        <>
            <BasicInformation
                user={user}
                onFinish={
                    onFinish ||
                    ((contact) => {
                        router.push(`/contact/${contact._id}`);
                    })
                }
                onCancel={onCancel}
                contact={contact}
                useLocalContact={useLocalContact}
                migrate={migrate}
            />
            <div className="mt-20px" />
        </>
    );
};
