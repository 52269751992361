
export const CustomListingIcon = (props: React.SVGProps<SVGSVGElement>) => {
    
    return ( <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.367 5.46061H1.63357C1.26392 5.46061 0.964592 5.74789 0.964592 6.10347V15.4249C0.964592 15.7805 1.26392 16.0678 1.63357 16.0678H16.365C16.7347 16.0678 17.034 15.7805 17.034 15.4249V6.10347C17.036 5.74789 16.7367 5.46061 16.367 5.46061ZM5.94674 14.6213H2.41102V11.407H5.94674V14.6213ZM5.94674 10.1213H2.41102V6.90704H5.94674V10.1213ZM10.7682 14.6213H7.23245V11.407H10.7682V14.6213ZM10.7682 10.1213H7.23245V6.90704H10.7682V10.1213ZM15.5896 14.6213H12.0539V11.407H15.5896V14.6213ZM15.5896 10.1213H12.0539V6.90704H15.5896V10.1213ZM16.8753 1.9249H1.12531C1.03691 1.9249 0.964592 1.99722 0.964592 2.08561V3.69275C0.964592 3.78115 1.03691 3.85347 1.12531 3.85347H16.8753C16.9637 3.85347 17.036 3.78115 17.036 3.69275V2.08561C17.036 1.99722 16.9637 1.9249 16.8753 1.9249Z"
            fill="black"
            fillOpacity="0.85"
        />
    </svg>
    );
}