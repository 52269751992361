import { IAgent, ICompany } from '@pulppo/pulppo-models';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { api_url, fetcher } from '../../helpers/fetcher';
import { NextImage } from './NextImage';
import { PulppoMultiselect } from './PulppoMultiselect';
import { PulppoSelect } from './PulppoSelect';
import { CompanySelect } from './CompanySelect';
import { twMerge } from 'tailwind-merge';
import { TolgeeNextProvider } from '../../contexts/TolgeeProvider';

export const AgentSelect = ({
    user = null,
    company: initialCompany = null,
    value = null,
    status = null,
    multi = false,
    defaultToUserId = true,
    onChange = null,
    disableSuspended = false,
    icon = null,
    className = '',
    disabled = false,
    placeholder = '',
    selectClassName = '',
    renderButton = null,
    allowAll = true,
    onChangeCompany = null,
    showAllAgents = false
}) => {
    const [selectedAgent, setSelectedAgent] = useState(value || (defaultToUserId && user));
    const allCompanies = user?.features?.viewAll || (user?.features?.callCenter && showAllAgents);
    const [selectedAgents, setSelectedAgents] = useState([]);
    const [company, setCompany] = useState<ICompany>(initialCompany || user?.company);
    const { data: companies } = useSWR<{
        result: Array<ICompany>;
    }>(allCompanies && `${api_url}/company`, fetcher);
    const { data: agents, isLoading: isLoadingAgents } = useSWR<IAgent[]>(
        company?._id && `${api_url}/company/${company?._id}/agents`,
        fetcher
    );

    useEffect(() => {
        setSelectedAgent(value);
        setSelectedAgents(value || []);
    }, [value]);

    useEffect(() => {
        setCompany(initialCompany || user?.company);
    }, [initialCompany || user?.company]);

    useEffect(() => {
        onChangeCompany?.(company);
    }, [company]);

    return !isLoadingAgents ? (
        <TolgeeNextProvider>
            <div className={twMerge('w-full md:w-auto', className)}>
                {multi ? (
                    <PulppoMultiselect
                        disabled={disabled}
                        showButtons={true}
                        options={[
                            ...(agents || []).map((ag) => ({
                                value: ag.uid,
                                icon: (
                                    <NextImage
                                        key={ag.uid}
                                        showPreview={false}
                                        src={
                                            ag?.profilePicture ||
                                            `https://ui-avatars.com/api/?name=${ag?.firstName}%20${ag?.lastName}`
                                        }
                                        alt={ag.profilePicture}
                                        width={64}
                                        height={64}
                                        className="h-6 w-6 rounded-full"
                                    />
                                ),
                                label: `${ag.firstName} ${ag.lastName}`,
                                key: ag.uid
                            }))
                        ]}
                        renderButton={renderButton}
                        placeholder="Seleccione las opciones deseadas"
                        onClear={() => {
                            setSelectedAgents([]);
                            onChange?.([]);
                        }}
                        onApply={(val) => {
                            const newAgents = val.map((v) => agents.find((a) => a.uid == v));
                            setSelectedAgents(newAgents);
                            onChange?.(newAgents);
                        }}
                        onChange={(val) => {
                            const newAgents = val.map((v) => agents.find((a) => a.uid == v));
                            setSelectedAgents(newAgents);
                            onChange?.(newAgents);
                        }}
                        value={selectedAgents?.map?.((a) => a?.uid)}
                    />
                ) : (
                    <PulppoSelect
                        filter={companies && <CompanySelect onChange={(val) => setCompany(val)} value={company} />}
                        status={status}
                        value={selectedAgent?.uid}
                        disabled={disabled}
                        placeholder={placeholder}
                        onChange={(val) => {
                            if (val === 'all') {
                                const agent = {
                                    uid: 'all',
                                    firstName: 'Todos',
                                    lastName: 'los asesores',
                                    company: company,
                                    profilePicture: company?.logo?.default
                                };

                                setSelectedAgent(agent);
                                onChange?.(agent);
                                return;
                            }
                            const newAgents = agents.find((a) => a.uid == val);
                            setSelectedAgent(newAgents);
                            onChange?.(newAgents);
                        }}
                        options={[
                            ...(allowAll
                                ? [
                                      {
                                          value: 'all',
                                          label: 'Todos los asesores',
                                          key: 'all'
                                      }
                                  ]
                                : []),
                            ...(agents || []).map((ag) => {
                                return {
                                    value: ag.uid,
                                    disabled: disableSuspended && ag?.suspensions?.length > 0,
                                    icon: (
                                        <NextImage
                                            showPreview={false}
                                            src={
                                                ag?.profilePicture ||
                                                `https://ui-avatars.com/api/?name=${ag?.firstName}%20${ag?.lastName}`
                                            }
                                            alt={ag.profilePicture}
                                            width={64}
                                            height={64}
                                            className="h-6 w-6 rounded-full"
                                        />
                                    ),
                                    label: `${ag.firstName} ${ag.lastName}`,
                                    key: ag.uid
                                };
                            })
                        ].filter(Boolean)}
                        renderButton={renderButton}
                        icon={icon}
                        className={twMerge('w-full py-2', selectClassName)}
                    />
                )}
            </div>
        </TolgeeNextProvider>
    ) : (
        <div />
    );
};
