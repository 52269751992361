import { IProperty } from '@pulppo/pulppo-models';
import { isValidDate } from './date';

export const isExclusive = (property: Partial<IProperty>) => {
    return (
        !!property?.contract?.exclusive &&
        !!property?.contract?.exclusive?.start &&
        (isValidDate(property?.contract?.exclusive?.start) || typeof property?.contract?.exclusive?.start === 'string')
    );
};
