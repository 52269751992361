import { Col, Form, Input, InputProps, InputRef, Row, Typography } from 'antd';
import { RefAttributes, useContext, useEffect, useState } from 'react';
import { PulppoSelect } from '../Elements/PulppoSelect';
import { formEventEmitter } from '../../utils/FormEvents';
import { PropertyFormContext } from '../../contexts/PropertyFormContext';
import { useTranslate } from '@tolgee/react';
import { twMerge } from 'tailwind-merge';
import PropertyListingDetails from './PropertyListingDetails';
import { IProperty } from '@pulppo/pulppo-models';
import Button from '../Elements/Button';
import { DollarOutlined } from '@ant-design/icons';
import { DEFAULT_COMMISSION_RECOMMENDED_BROKER } from '@pulppo/pulppo-models/build/utils/operation';
import { useCurrencyConverter } from '../../hooks/useCurrencyConverter';
import { DEFAULT_CURRENCY } from '../../utils/constants';

const TEMPORARY_RENT_TAG = 'Alquiler temporario';
const SALE_AND_RENT_TAG = 'En venta y renta';
function getDecimalSeparator() {
    const num = 1.1;
    return num.toLocaleString(process.env.NEXT_PUBLIC_LANGUAGE_CODE || 'es-MX').substring(1, 2);
}

export const PriceInput = ({
    prefix,
    className,
    withAddonAfter,
    ...props
}: Omit<InputProps, 'onChange'> & RefAttributes<InputRef> & { onChange?: Function; withAddonAfter?: boolean }) => {
    const [renderedValue, setRenderedValue] = useState(
        props.value ? (+props.value)?.toLocaleString(process.env.NEXT_PUBLIC_LANGUAGE_CODE || 'es-MX') : ''
    );
    let moreClassName = '';

    useEffect(() => {
        setRenderedValue(
            props.value ? (+props.value)?.toLocaleString(process.env.NEXT_PUBLIC_LANGUAGE_CODE || 'es-MX') : ''
        );
    }, [props.value]);

    if (withAddonAfter) {
        moreClassName = 'pr-10';
    } else if (props && !withAddonAfter && typeof prefix === 'undefined') {
        prefix = '$';
    }

    const input = (
        <Input
            inputMode="numeric"
            prefix={prefix}
            {...props}
            value={renderedValue}
            onChange={(event) => {
                const separator = getDecimalSeparator();
                if (event.target.value.endsWith(separator)) {
                    return setRenderedValue(event.target.value);
                }
                const regex = new RegExp(`[^0-9${separator}-]`, 'gi');
                const formattedValue = event.target.value.replace(regex, '');

                props.onChange?.(parseFloat(formattedValue.replace(separator, '.')));
            }}
            className={twMerge('w-full rounded-none text-base', moreClassName, className)}
        />
    );

    if (withAddonAfter) {
        return (
            <div className={twMerge('relative', className)}>
                {input}
                <div className="absolute inset-y-0 right-0 flex items-center bg-secondary-gray-disabled">
                    <DollarOutlined className="bg-secondary-gray-disabled px-2 text-lg" />
                </div>
            </div>
        );
    }
    return input;
};

export const PercentInput = (
    props: Omit<InputProps, 'onChange'> & RefAttributes<InputRef> & { onChange?: Function }
) => {
    const [renderedValue, setRenderedValue] = useState(
        props.value ? (+props.value * 100)?.toLocaleString(process.env.NEXT_PUBLIC_LANGUAGE_CODE || 'es-MX') : ''
    );

    useEffect(() => {
        setRenderedValue(
            props.value ? (+props.value * 100)?.toLocaleString(process.env.NEXT_PUBLIC_LANGUAGE_CODE || 'es-MX') : ''
        );
    }, [props.value]);

    return (
        <Input
            inputMode="numeric"
            suffix="%"
            {...props}
            value={renderedValue}
            onChange={(event) => {
                const separator = getDecimalSeparator();
                if (event.target.value.endsWith(separator)) {
                    return setRenderedValue(event.target.value);
                }
                const regex = new RegExp(`[^0-9${separator}]`, 'gi');
                const formattedValue = event.target.value.replace(regex, '');

                props.onChange?.(parseFloat(formattedValue.replace(separator, '.')) / 100);
            }}
            className={twMerge('h-10 w-full items-center text-base', props.className)}
        />
    );
};

export const PropertyListing = ({
    property,
    disableDetails,
    calcValue,
    isDisabled
}: {
    property?: Partial<IProperty>;
    disableDetails?: boolean;
    calcValue?: boolean;
    isDisabled?: boolean;
}) => {
    const { t } = useTranslate('common');
    const form = Form.useFormInstance();
    const propertyForm = useContext(PropertyFormContext);
    const { currencyConverter } = useCurrencyConverter();

    const { formData } = property
        ? {
              formData: property
          }
        : propertyForm || {};

    useEffect(() => {
        if (calcValue && typeof formData?.listing?.price?.price === 'number') {
            formEventEmitter.emit('changes', {
                listing: {
                    ...formData?.listing,
                    value: currencyConverter(
                        formData?.listing?.price?.currency || DEFAULT_CURRENCY,
                        DEFAULT_CURRENCY,
                        formData.listing.price.price
                    )
                }
            });
        }
    }, [calcValue, formData?.listing?.price?.price, formData?.listing?.price?.currency]);

    return (
        <>
            <Row>
                <Col flex="auto">
                    <Form.Item
                        className="form-item-one-column"
                        name={['listing', 'operation']}
                        label="Tipo de operación"
                        rules={[
                            {
                                required: true,
                                message: 'Seleccione tipo de operación'
                            }
                        ]}
                    >
                        <TypeOperationSelect disabled={isDisabled} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Row className="mx-0" align="bottom" justify="space-between" gutter={15}>
                        <Col className="pl-0 [&>div]:mb-0" span={16}>
                            <Form.Item label="Precio" className="form-item-one-column" required>
                                <Form.Item
                                    name={['listing', 'price', 'price']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    noStyle
                                >
                                    <PriceInput
                                        disabled={isDisabled}
                                        onChange={(val) => {
                                            formEventEmitter.emit('changes', {
                                                listing: {
                                                    ...formData?.listing,
                                                    price: {
                                                        currency: form.getFieldValue(['listing', 'price', 'currency']),
                                                        price: val
                                                    }
                                                }
                                            });
                                        }}
                                        withAddonAfter
                                    />
                                </Form.Item>
                            </Form.Item>
                        </Col>
                        <Col className="pr-0" span={8}>
                            <Form.Item
                                name={['listing', 'price', 'currency']}
                                validateTrigger={['onChange', 'onBlur']}
                                noStyle
                                rules={[
                                    {
                                        required: true,
                                        message: 'Tiene que ingresar un precio'
                                    }
                                ]}
                                required
                            >
                                <PulppoSelect
                                    disabled={isDisabled}
                                    allowSearch={false}
                                    className="h-9 w-full overflow-y-hidden py-2"
                                    options={process.env.NEXT_PUBLIC_CURRENCIES.split(',').map((currency) => ({
                                        value: currency,
                                        label: currency,
                                        key: currency
                                    }))}
                                    placeholder={process.env.NEXT_PUBLIC_PRIMARY_CURRENCY}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Typography.Paragraph className="my-2 font-medium">
                {t('expenses', 'Costo de mantenimiento')}
            </Typography.Paragraph>
            <Form.Item name={['attributes', 'expenses']}>
                <PriceInput
                    disabled={isDisabled}
                    inputMode="numeric"
                    className="flex w-full items-center text-base"
                    withAddonAfter
                />
            </Form.Item>
            <Form.Item name={['attributes', 'expensesIncluded']}>
                <ExpensesInput disabled={isDisabled} />
            </Form.Item>
            <p className="text-md mt-4 font-semibold">Otras características</p>
            <p className="text-md mb-4 text-gray-400">
                Estos ítems no generan una copia de la propiedad, solo funcionan como etiquetas internas para la
                búsqueda
            </p>
            <label className="mb-4 flex gap-x-2 text-xs">
                <input
                    type="checkbox"
                    checked={property?.tags?.some((t) => t?.label === TEMPORARY_RENT_TAG)}
                    onChange={(e) => {
                        let aux = property?.tags || [];
                        const index = aux.findIndex((el) => el.label === TEMPORARY_RENT_TAG);
                        if (index === -1) aux.push({ label: TEMPORARY_RENT_TAG, group: '' } as any);
                        else aux = aux.filter((el) => el.label !== TEMPORARY_RENT_TAG);
                        console.log(aux);
                        formEventEmitter.emit('changes', { tags: aux });
                    }}
                    disabled={isDisabled}
                />
                {`En ${t('rent')} temporario`}
            </label>
            <label className="flex gap-x-2 text-xs">
                <input
                    type="checkbox"
                    checked={property?.tags?.some((t) => t?.label === SALE_AND_RENT_TAG)}
                    onChange={(e) => {
                        let aux = property?.tags || [];
                        const index = aux.findIndex((el) => el.label === SALE_AND_RENT_TAG);
                        if (index === -1) aux.push({ label: SALE_AND_RENT_TAG, group: '' } as any);
                        else aux = aux.filter((el) => el.label !== SALE_AND_RENT_TAG);
                        formEventEmitter.emit('changes', { tags: aux });
                    }}
                    disabled={isDisabled}
                />
                {`En ${t('sale')} y ${t('rent')}`}
            </label>
            {!disableDetails ? <PropertyListingDetails /> : null}
        </>
    );
};

export const TypeOperationSelect = ({
    value,
    onChange,
    disabled
}: {
    value?: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
}) => {
    const form = Form.useFormInstance();
    const { t } = useTranslate('common');

    const onSelect = (type: 'sale' | 'rent') => {
        if (form) {
            formEventEmitter.emit('changes', {
                'contract.comission':
                    type === 'sale'
                        ? DEFAULT_COMMISSION_RECOMMENDED_BROKER.sale
                        : DEFAULT_COMMISSION_RECOMMENDED_BROKER.rent
            });
            form.setFieldsValue({
                contract: {
                    comission:
                        type === 'sale'
                            ? DEFAULT_COMMISSION_RECOMMENDED_BROKER.sale
                            : DEFAULT_COMMISSION_RECOMMENDED_BROKER.rent
                }
            });
        }
        onChange?.(type);
    };

    return (
        <>
            <Button
                onClick={() => {
                    onSelect('sale');
                }}
                className="w-1/2"
                type={value === 'sale' ? 'black' : 'secondary'}
                disabled={disabled}
            >
                {t('sale')}
            </Button>
            <Button
                onClick={() => {
                    onSelect('rent');
                }}
                className="w-1/2"
                type={value === 'rent' ? 'black' : 'secondary'}
                disabled={disabled}
            >
                {t('rent')}
            </Button>
        </>
    );
};

const ExpensesInput = (props) => {
    return (
        <label className="flex gap-x-2 text-xs">
            <input
                type="checkbox"
                checked={props.value}
                onChange={(e) => {
                    props.onChange(e.target.checked);
                }}
                disabled={props?.disabled}
            />
            Incluído
        </label>
    );
};
