import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, ButtonProps, Form, Input, notification, Typography } from 'antd';
import nookies from 'nookies';
import axios from '../../../utils/axios';
import { useContext, useEffect, useRef, useState } from 'react';
import { api_url, fetcher } from '../../../helpers/fetcher';
import { useRouter } from 'next/router';
import { PulppoOptions } from '../../../components/Elements/PulppoOptions';
import { PropertyStep1 } from '../../../components/Property/Steps/step-1';
import { useHasPermissions } from '../../../hooks/useHasPermissions';
import { PropertyStep2 } from '../../../components/Property/Steps/step-2';
import { PropertyStep3 } from '../../../components/Property/Steps/step-3';
import { getPropertyError, PropertyStep4 } from '../../../components/Property/Steps/step-4';
import { useDebounceValue } from '../../../hooks/debounceValue';
import { toComplex, toPlain } from '../../../utils/toPlain';
import dayjs from 'dayjs';
import { useForm } from 'antd/lib/form/Form';
import { formEventEmitter } from '../../../utils/FormEvents';
import TaskHeader from '../../../components/Tasks/TaskHeader';
import { PropertyFormContext, PropertyFormContextProvider } from '../../../contexts/PropertyFormContext';
import { initialCoordinates } from '../../acm/[id]/step-1';
import Alert from '../../../components/Closes/Alert';
import { twMerge } from 'tailwind-merge';
import { useTranslate } from '@tolgee/react';
import YellowLightBulb from '../../../components/Icon/YellowLightBulb';

export const LoadingButton = (props: ButtonProps) => {
    const [loading, setLoading] = useState(false);
    return (
        <Button
            {...props}
            className={twMerge('rounded-none', props?.className)}
            loading={loading}
            disabled={loading}
            onClick={(ev) => {
                if (loading) return;
                setLoading(true);

                (props.onClick?.(ev) as any)
                    ?.catch?.((err) => {
                        console.error(err?.response);
                        notification.error({
                            message: `Error: ${err?.response?.data?.error || err?.response?.data?.message || err?.message || err}`
                        });
                    })
                    ?.finally?.(() => setLoading(false));
            }}
        />
    );
};

const initializeProperty = (property) => {
    return {
        ...property,
        type: property?.type,
        services: property?.services || [],
        additionalFields: {
            ...(property as any)?.additionalFields,
            terrainType: property?.additionalFields?.terrainType || null,
            remodelationDate: (property as any)?.additionalFields?.remodelationDate
                ? dayjs((property as any)?.additionalFields?.remodelationDate).tz()
                : dayjs().tz()
        },
        pictures: property?.pictures || [],
        address: {
            ...(property?.address || {}),
            location: property?.address?.location || {
                type: 'Point',
                coordinates: initialCoordinates
            }
        },

        development: property?.development || null,
        contract: {
            ...(property?.contract || {}),
            exclusive: property?.contract?.exclusive
                ? {
                      durationMonths: property?.contract?.exclusive.durationMonths,
                      start: dayjs(property?.contract?.exclusive?.start).tz().toDate() as any
                  }
                : null
        },
        portals: property?.portals
            ? property.portals
            : {
                  inmuebles24: {},
                  lamudi: {},
                  pulppo: {},
                  easybroker: {},
                  trovit: {},
                  facebook: {},
                  google: {}
              },
        listing: {
            ...(property?.listing || {}),
            publishOnPortals:
                property?.listing && 'publishOnPortals' in property?.listing ? property.listing.publishOnPortals : true,
            price: property?.listing?.price?.price
                ? property?.listing.price
                : {
                      price: '',
                      currency: process.env.NEXT_PUBLIC_PRIMARY_CURRENCY
                  }
        },
        contact: property?.contact
    };
};

let stackedChanges = {};

const EditProperty = ({ property: initialProperty }) => {
    const router = useRouter();
    const [form] = useForm();
    const { formData, setFormData } = useContext(PropertyFormContext);
    const [property, setProperty] = useState(initialProperty);
    const [page, setFinalPage] = useState('step-1');
    const [changedValues, setChangedValues] = useState(null);
    const [error, setError] = useState(null);
    const [missingFields, setMissingFields] = useState('');
    const [loading, setLoading] = useState(false);
    const [task, setTask] = useState(null);
    const permissions = useHasPermissions(property, 'property', false);
    const scroller = useRef<HTMLDivElement>(null);
    const debouncedChange = useDebounceValue(changedValues, 300);
    const { t } = useTranslate('common');
    useEffect(() => {
        setProperty(initialProperty);
    }, [initialProperty]);

    useEffect(() => {
        const initializedProperty = initializeProperty(initialProperty);

        form.resetFields();
        form.setFieldsValue(initializedProperty);
        setFormData(initializedProperty);
        setProperty(initialProperty);
    }, [initialProperty]);

    useEffect(() => {
        formEventEmitter.on('changes', (values) => {
            setFormData((formData) => toComplex({ ...formData, ...values }));

            stackedChanges = {
                ...stackedChanges,
                ...toPlain(values, '')
            };
            setChangedValues(values);
        });
    }, []);

    useEffect(() => {
        console.log('form data changed: ', formData);
        if (formData) {
            const errors = getPropertyError(formData, t);
            if (errors) setMissingFields(errors);
            else setMissingFields('');
        }
    }, [formData]);

    useEffect(() => {
        if (!debouncedChange) return;
        setLoading(true);

        if (!property?._id) {
            const plain = toPlain(debouncedChange, '');
            if (!Object.keys(plain).some((key) => (Array.isArray(plain[key]) ? plain[key].length : !!plain[key])))
                return;
            axios
                .post(`${api_url}/property`, {
                    ...toPlain(debouncedChange, ''),
                    editedAt: new Date()
                })
                .then((p) => {
                    setError(null);
                    setProperty(p.data);
                    form.setFieldValue('_id', p.data._id);
                    router.push(`/listing/${p.data._id}/step-1`);
                })
                .catch((e) => {
                    setError(e?.response?.data?.error || e?.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            axios
                .patch(`${api_url}/property/${property._id}`, {
                    ...stackedChanges,
                    editedAt: new Date()
                })
                .then(() => {
                    setError(null);
                })
                .catch((e) => {
                    setError(e?.response?.data?.error || e?.message);
                })
                .finally(() => setLoading(false));
            stackedChanges = {};
        }
    }, [debouncedChange]);

    useEffect(() => {
        if (!permissions && !router.asPath.includes('new-property')) {
            notification.error({
                message: 'No tienes permiso para acceder a esa propiedad'
            });
            router.push('/');
        }
    }, [permissions, router]);

    useEffect(() => {
        if (router.query.step) {
            setFinalPage(router.query.step as string);
            scroller?.current?.scrollTo?.(0, 0);
        }
    }, [router.query]);

    useEffect(() => {
        if (property?._id) {
            fetcher(`${api_url}/task/one?type=property&id=${property._id}`).then((data) => {
                if (data?._id) setTask(data);
            });
        }
    }, [property]);

    const address = formData?.address;
    const type = formData?.type;
    const internalId = formData?.internalId;

    const generateInternalCode = () => {
        axios
            .post(`${api_url}/property/internal`, {
                type,
                address
            })
            .then((r) => {
                formEventEmitter.emit('changes', { internalId: r.data.id });
            });
    };

    useEffect(() => {
        if (!internalId && type && address) generateInternalCode(); // TODO: generar cuando cambien los campos correspondientes
    }, [internalId, address, type]);

    const steps = [
        {
            label: 'Características',
            render: (props) => <PropertyStep1 {...props} />,
            key: 'step-1'
        },
        {
            label: 'Operación',
            render: (props) => <PropertyStep2 {...props} />,
            key: 'step-2'
        },
        {
            label: 'Galería',
            render: (props) => <PropertyStep3 {...props} />,

            key: 'step-3'
        },
        {
            label: 'Legales',
            render: (props) => <PropertyStep4 {...props} />,
            key: 'step-4'
        }
    ];

    const pageIndex = steps.findIndex((step) => step.key == page);

    const setPage = (page) => {
        router.query.step = page;
        router.push(router);
    };

    return (
        <div className="flex h-full flex-col">
            <TaskHeader task={task} beforeCheck={async () => {}} />
            <div className="flex flex-1 flex-col bg-white px-4 py-2 lg:bg-light-background">
                <div className="items-center justify-between pt-2 lg:flex lg:pb-4">
                    <div className="flex w-full items-center gap-4">
                        <Typography.Title
                            level={4}
                            className="mb-0 w-full shrink-0 truncate text-lg font-normal lg:w-auto"
                        >
                            {' '}
                            {address?.street || 'Nueva propiedad'} {internalId ? `(${internalId})` : ''}
                        </Typography.Title>
                    </div>
                </div>
                {!missingFields?.length && property?.status?.last === 'edition' &&
                    <div className="flex items-center justify-center gap-2 rounded-md bg-yellow-500/10 p-4 mb-4 flex-col lg:flex-row">
                        <div className='flex items-center gap-2'>
                            <YellowLightBulb className='w-40 h-40 lg:w-12 lg:h-12' />
                            <p className='px-2 items-right'>
                                La propiedad está en estado Borrador y lista para publicarse. Aún no has completado “Documentación y Acuerdo”, pero <b>puedes publicarla de todos modos
                                haciendo clic en “Publicar propiedad”.</b>
                            </p>
                        </div>
                        <LoadingButton
                            type="primary"
                            className="h-10 !w-50 font-medium w-full lg:w-auto"
                            disabled={!!missingFields?.length}
                            onClick={async () => {
                                await axios
                                    .post(`${api_url}/property/${property?._id}/publish`)
                                    .then(() => {
                                        notification.success({ message: 'Propiedad publicada con éxtio' });
                                        window.location.reload();
                                    })
                                    .catch(() => {
                                        notification.error({ message: 'Ocurrió un error al publicar la propiedad' });
                                    });
                            }}
                        >
                            Publicar propiedad
                        </LoadingButton>
                    </div>}

                {error && (
                    <div className="mb-3 mt-3 flex w-full flex-col gap-2">
                        <Alert
                            type="danger"
                            content={[
                                {
                                    text: 'Hubo un error guardando la propiedad: '
                                },
                                { text: error, emphasize: true }
                            ]}
                        />
                    </div>
                )}
                <div className="flex w-full flex-1 flex-col items-center rounded-md bg-white py-2 lg:px-4">
                    <div className="relative hidden lg:block">
                        <div
                            className="absolute bg-gray-200"
                            style={{
                                width: `${8 * (steps.length - 1)}rem`,
                                height: '2px',
                                top: '0.7rem',
                                left: '3rem'
                            }}
                        />
                        <div className="relative z-10 flex items-center gap-8">
                            {steps.map((step, index) => (
                                <div
                                    onClick={() => {
                                        setPage(step.key);
                                    }}
                                    key={step.key}
                                    className="flex w-24 cursor-pointer flex-col items-center justify-center"
                                >
                                    <div
                                        className={`flex h-6 w-6 shrink-0 items-center justify-center rounded-full transition-all ${
                                            pageIndex > index
                                                ? 'bg-black'
                                                : pageIndex == index
                                                  ? 'border-4 border-solid border-black bg-white'
                                                  : 'bg-gray-200'
                                        }`}
                                    />
                                    <div className={`${pageIndex >= index ? 'text-black' : 'text-gray-200'}`}>
                                        {step.label}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex w-full flex-col lg:hidden">
                        <p className="font-medium">
                            Paso {pageIndex + 1} de {steps.length}
                        </p>
                        <PulppoOptions
                            value={{ step: page }}
                            options={steps.map((step, index) => ({
                                label: step.label,
                                value: `step-${index + 1}`,
                                description: step.label,
                                key: `step-${index + 1}`,
                                group: 'step'
                            }))}
                            onChange={(opt) => setPage(opt.key)}
                        >
                            <Input
                                value={steps.find((s) => s.key == page)?.label}
                                className="flex h-10 items-center"
                                suffix={<DownOutlined className="text-gray-300" />}
                            />
                        </PulppoOptions>
                    </div>
                    <div className="relative mt-2 w-full flex-1 overflow-x-hidden" ref={scroller}>
                        <Form
                            preserve={true}
                            form={form}
                            layout="vertical"
                            scrollToFirstError={{ behavior: 'smooth' }}
                            name="property"
                            id="property"
                            autoComplete="off"
                            onValuesChange={(changedValues, values) => {
                                stackedChanges = {
                                    ...stackedChanges,
                                    ...toPlain(changedValues, '')
                                };
                                setFormData((formData) => toComplex({ ...formData, ...values }));
                                setChangedValues(changedValues);
                            }}
                            initialValues={initializeProperty(property)}
                        >
                            {steps.map((step, index) => (
                                <div
                                    className="absolute h-full w-full shrink-0 transition-all"
                                    style={{
                                        left: `${(index - pageIndex) * 100}%`
                                    }}
                                    key={step.label}
                                >
                                    {step.render({ property })}
                                </div>
                            ))}
                        </Form>
                    </div>
                    <div className="flex w-full items-center justify-end gap-4 pt-2">
                        <Button
                            onClick={() => {
                                if (pageIndex == 0) {
                                    return router.push(`/search?tab=user`);
                                }

                                setPage(steps[pageIndex - 1]?.key);
                            }}
                            className="w-72"
                        >
                            Atrás
                        </Button>
                        <Button
                            onClick={() => {
                                if (pageIndex == steps.length - 1) {
                                    router.push(`/search?tab=user&selected=${property?._id}`);
                                    return;
                                }
                                setPage(steps[pageIndex + 1].key);
                            }}
                            className="flex w-72 items-center justify-center gap-4"
                            type="primary"
                        >
                            {loading ? (
                                <>
                                    <LoadingOutlined /> Guardando...
                                </>
                            ) : pageIndex == steps.length - 1 ? (
                                'Guardar propiedad'
                            ) : (
                                'Continuar'
                            )}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const EditPropertyWithContext = ({ property }) => {
    return (
        <PropertyFormContextProvider>
            <EditProperty property={property} />
        </PropertyFormContextProvider>
    );
};

export const getServerSideProps = async (ctx) => {
    const { params } = ctx;
    const cookies = nookies.get(ctx);

    const property =
        params.id == 'new-property'
            ? {}
            : await axios
                  .get(`${api_url}/property/${params.id}`, {
                      headers: cookies['new-token']
                          ? {
                                authorization: `Bearer ${cookies['new-token']}`,
                                'SKIP-IP-CHECK': 'true'
                            }
                          : { 'pulppo-access-token': cookies.token }
                  })
                  .then((r) => r.data);

    return {
        props: {
            property
        }
    };
};
export default EditPropertyWithContext;
