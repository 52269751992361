import { CheckOutlined, DownOutlined } from '@ant-design/icons';
import { ReactNode, useEffect, useState } from 'react';
import { PulppoDrawer } from './PulppoDrawer';
import { NewPropertyTypes } from '@pulppo/pulppo-models/build/enums/PropertyTypes';
import { twMerge } from 'tailwind-merge';
import Button from './Button';
import { useTranslate } from '@tolgee/react';

const residentialTypes = ['Casa', 'Casa en condominio', 'Departamento'];
const landTypes = ['Terreno residencial', 'Terreno comercial', 'Terreno industrial'];

interface Props {
    value?: string[];
    apply?: (value: string[]) => void;
    render?: (value: string[]) => ReactNode;
    className?: {
        container?: string;
        button?: string;
        icon?: string;
    };
}

export const PropertyTypeSelector = ({
    value = [],
    apply = (_: Array<string>) => {},
    render = null,
    className
}: Props) => {
    const [showDrawer, setShowDrawer] = useState(false);
    const [selected, setSelected] = useState<string[]>([]);
    const { t } = useTranslate('common');
    useEffect(() => {
        if (showDrawer) {
            setSelected(value);
        } else {
            setSelected([]);
        }
    }, [showDrawer]);

    const onCheck = (value: string) => {
        if (selected.includes(value)) setSelected((selected) => selected.filter((val) => val !== value));
        else setSelected((selected) => [...selected, value]);
    };

    return (
        <>
            <div
                className={twMerge('cursor-pointer', className?.container)}
                onClick={() => {
                    setShowDrawer(true);
                }}
            >
                {render ? (
                    render(value)
                ) : (
                    <Button
                        type="secondary"
                        className={className?.button}
                        icon={<DownOutlined className={twMerge('ml-2 text-sm text-black', className?.icon)} />}
                    >
                        Tipo de propiedad
                    </Button>
                )}
            </div>
            <PulppoDrawer
                bodyStyle={{ paddingTop: '0px' }}
                title="Tipo de propiedad"
                visible={showDrawer}
                onClose={() => {
                    setShowDrawer(false);
                    apply(selected);
                }}
            >
                <div className="flex h-full w-full flex-col justify-between pt-4">
                    <div className="flex flex-1 flex-col gap-5">
                        <div>
                            <p className="border-b border-solid border-secondary-gray-disabled py-2 text-primary-gray">
                                Residencial
                            </p>
                            <div className="mt-3 flex flex-col gap-2">
                                {residentialTypes.map((type) => (
                                    <div
                                        className={'cursor-pointer hover:underline'}
                                        key={type}
                                        onClick={() => {
                                            onCheck(type);
                                        }}
                                    >
                                        <div className="flex items-center gap-2">
                                            <div
                                                className={`flex h-5 w-5 items-center justify-center rounded-none border-2 border-solid bg-white ${
                                                    selected.includes(type) ? 'border-black' : 'border-gray-200'
                                                }`}
                                            >
                                                {selected.includes(type) && (
                                                    <CheckOutlined className="p-1 text-xs font-bold text-black" />
                                                )}
                                            </div>
                                            <p>{t(type)}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <p className="border-b border-solid border-secondary-gray-disabled py-2 text-primary-gray">
                                Terrenos
                            </p>
                            <div className="mt-3 flex flex-col gap-2">
                                {landTypes.map((type) => (
                                    <div
                                        className={'cursor-pointer hover:underline'}
                                        key={type}
                                        onClick={() => {
                                            onCheck(type);
                                        }}
                                    >
                                        <div className="flex items-center gap-2">
                                            <div
                                                className={`flex h-5 w-5 items-center justify-center rounded-none border-2 border-solid bg-white ${
                                                    selected.includes(type) ? 'border-black' : 'border-gray-200'
                                                }`}
                                            >
                                                {selected.includes(type) && (
                                                    <CheckOutlined className="p-1 text-xs text-black" />
                                                )}
                                            </div>
                                            <p>{t(type)}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <p className="border-b border-solid border-secondary-gray-disabled py-2 text-primary-gray">
                                Comercial e industrial
                            </p>
                            <div className="mt-3 flex flex-col gap-2">
                                {NewPropertyTypes.filter(
                                    (type) => !residentialTypes.includes(type) && !landTypes.includes(type)
                                ).map((type) => (
                                    <div
                                        className={'cursor-pointer hover:underline'}
                                        key={type}
                                        onClick={() => {
                                            onCheck(type);
                                        }}
                                    >
                                        <div className="flex items-center gap-2">
                                            <div
                                                className={`flex h-5 w-5 items-center justify-center rounded-none border-2 border-solid bg-white ${
                                                    selected.includes(type) ? 'border-black' : 'border-gray-200'
                                                }`}
                                            >
                                                {selected.includes(type) && (
                                                    <CheckOutlined className="p-1 text-xs text-black" />
                                                )}
                                            </div>
                                            <p>{t(type)}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full gap-5">
                        <Button
                            type="secondary"
                            className="flex-1"
                            disabled={selected.length === 0}
                            onClick={() => {
                                apply([]);
                                setShowDrawer(false);
                            }}
                        >
                            Borrar filtros
                        </Button>
                        <Button
                            className="flex-1"
                            type="primary"
                            onClick={() => {
                                apply(selected);
                                setShowDrawer(false);
                            }}
                        >
                            Filtrar
                        </Button>
                    </div>
                </div>
            </PulppoDrawer>
        </>
    );
};
