/* eslint-disable react/no-deprecated */
import { IProperty } from '@pulppo/pulppo-models/build/@types/pulppo-models/schemas/Property';
import { PulppoDrawer } from '../Elements/PulppoDrawer';
import * as ReactDOM from 'react-dom';
import { useState } from 'react';
import ComparablesSection from './ComparablesSection';
import ComparableCard from './ComparableCard';
import { Button } from 'antd';
import ComparableSectionHeader from './ComparableSectionHeader';
import axios from '../../utils/axios';
import { api_url } from '../../helpers/fetcher';
import { TolgeeNextProvider } from '../../contexts/TolgeeProvider';
export interface ComparablesDrawerProps {
    visible: boolean;
    onClose: () => void;
    comparables: IProperty[];
    property: IProperty;
    updateValuation: (property) => Promise<any>;
    disabled?: boolean;
}

const ComparablesDrawer = ({
    property,
    visible,
    onClose,
    comparables: InitialComparables,
    updateValuation,
    disabled
}: ComparablesDrawerProps) => {
    const [tab, setTab] = useState<'low' | 'high' | 'medium' | null>('low');
    const [changes, setChanges] = useState<{ action: 'restore' | 'remove'; id: string }[]>([]);
    const [comparables, setComparables] = useState<IProperty[]>(InitialComparables);
    const [loading, setLoading] = useState(false);
    const removeComparable = (id: string) => {
        const aux = [...comparables];
        const index = aux.findIndex((cmp) => cmp._id?.toString() === id);
        aux[index].status.last = 'cancelled';
        addChange(id, 'remove');
        setComparables(aux);
    };
    const restoreComparable = (id: string) => {
        const aux = [...comparables];
        const index = aux.findIndex((cmp) => cmp._id?.toString() === id);
        aux[index].status.last = 'published';
        addChange(id, 'restore');
        setComparables(aux);
    };
    const addChange = (id: string, action: 'restore' | 'remove') => {
        const aux = [...changes];
        const index = aux.findIndex((chng) => chng.id === id);
        if (index === -1) {
            aux.push({
                id,
                action
            });
            setChanges(aux);
            return;
        }
        if (aux[index].action === action) return;
        aux.splice(index, 1);
        setChanges(aux);
    };

    const update = async () => {
        setLoading(true);
        const data = await axios
            .patch(`${api_url}/property/${property?._id}/valuate`, {
                changes
            })
            .then((res) => res.data);
        await updateValuation(data);
        setLoading(false);
        onClose();
    };
    return (
        <PulppoDrawer
            visible={visible}
            onClose={() => {
                if (changes.length) update();
                else onClose();
            }}
            title="Detalle de comparables"
        >
            <div className="-mx-4 flex h-full flex-1 flex-col items-center justify-start md:mx-0">
                <div className="flex-0 w-full shrink-0">
                    <ComparableCard property={property} showActions={false} className="bg-light-background" />
                </div>
                <div className="shrink-1 flex w-full flex-1 flex-col overflow-x-hidden">
                    <div>
                        <ComparableSectionHeader
                            tab="low"
                            comparables={comparables}
                            active={tab === 'low'}
                            onSelect={(val) => {
                                if (val === tab) setTab(null);
                                else setTab(val);
                            }}
                        />
                    </div>
                    <div className={`flex-0 w-full overflow-x-hidden ${tab === 'low' ? 'shrink-1' : 'shrink-0'}`}>
                        <ComparablesSection
                            tab="low"
                            selectedTab={tab}
                            comparables={comparables}
                            restoreComparable={restoreComparable}
                            removeComparable={removeComparable}
                            disabled={disabled}
                        />
                    </div>
                    <div>
                        <ComparableSectionHeader
                            tab="medium"
                            comparables={comparables}
                            active={tab === 'medium'}
                            onSelect={(val) => {
                                if (val === tab) setTab(null);
                                else setTab(val);
                            }}
                        />
                    </div>
                    <div className={`flex-0 w-full overflow-x-hidden ${tab === 'medium' ? 'shrink-1' : 'shrink-0'}`}>
                        <ComparablesSection
                            tab="medium"
                            selectedTab={tab}
                            comparables={comparables}
                            restoreComparable={restoreComparable}
                            removeComparable={removeComparable}
                            disabled={disabled}
                        />
                    </div>
                    <div>
                        <ComparableSectionHeader
                            tab="high"
                            comparables={comparables}
                            active={tab === 'high'}
                            onSelect={(val) => {
                                if (val === tab) setTab(null);
                                else setTab(val);
                            }}
                        />
                    </div>
                    <div className={`flex-0 w-full overflow-x-hidden ${tab === 'high' ? 'shrink-1' : 'shrink-0'}`}>
                        <ComparablesSection
                            tab="high"
                            selectedTab={tab}
                            comparables={comparables}
                            restoreComparable={restoreComparable}
                            removeComparable={removeComparable}
                            disabled={disabled}
                        />
                    </div>
                </div>

                {changes?.length > 0 && (
                    <div className="w-full shrink-0">
                        <Button
                            loading={loading}
                            type="primary"
                            className="h-10 w-full rounded-md font-medium"
                            onClick={async () => {
                                await update();
                            }}
                        >
                            {loading ? 'Actualizando valuacion' : 'Actualizar valuación'}
                        </Button>
                    </div>
                )}
            </div>
        </PulppoDrawer>
    );
};

export const showComparableDrawer = (config: Omit<ComparablesDrawerProps, 'visible' | 'onClose'>) => {
    const container = document.createDocumentFragment();
    let currentConfig = {
        ...config,
        onClose,
        visible: true
    } as any;

    function destroy(...args: any[]) {
        ReactDOM.unmountComponentAtNode(container);
    }

    function render(props) {
        setTimeout(() => {
            ReactDOM.render(
                <TolgeeNextProvider>
                    <ComparablesDrawer {...props} />
                </TolgeeNextProvider>,
                container
            );
        });
    }

    function onClose(...args: any[]) {
        currentConfig = {
            ...currentConfig,
            visible: false,
            afterClose: () => {
                destroy.apply(this, args);
            }
        };
        render(currentConfig);
    }

    function update(configUpdate) {
        if (typeof configUpdate === 'function') {
            currentConfig = configUpdate(currentConfig);
        } else {
            currentConfig = {
                ...currentConfig,
                ...configUpdate
            };
        }
        render(currentConfig);
    }

    render(currentConfig);

    return {
        destroy: close,
        update
    };
};
export default ComparablesDrawer;
