const formatNumber = (num: number) => {
    if (num >= 1e6) return (num / 1e6).toPrecision(3).concat('M');
    else if (num > 1e3) return (num / 1e3).toPrecision(3).concat('K');
    return `${num}`;
};
declare global {
    interface Number {
        toRad(): number;
    }
}
/** Converts numeric degrees to radians */
if (typeof Number.prototype.toRad === 'undefined') {
    Number.prototype.toRad = function () {
        return (this * Math.PI) / 180;
    };
}
export function formatCurrency(
    number: number,
    options?: {
        disableRound?: boolean;
    }
) {
    const { disableRound } = options || {};
    const suffixes = ['', 'K', 'M', 'B', 'T'];
    let suffixNum = 0;
    while (Math.round(number) >= 1000 && suffixNum < suffixes.length - 1) {
        suffixNum++;
        number /= 1000;
    }
    const roundedNum = disableRound ? Math.floor(number) : parseFloat(Math.round(number).toFixed(0));
    return roundedNum + suffixes[suffixNum];
}

export default formatNumber;
