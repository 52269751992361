import { useCallback, useEffect, useState } from "react";
import {
  collectionGroup,
  getDocs,
  limit,
  orderBy,
  query,
  where
} from 'firebase/firestore';
import { db } from '../services/authentication';


export function useInteractions(search) {
  const [isLoading, setIsLoading] = useState(false);
  const [searchInternal, setSearch] = useState(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (search?._id) {
      setSearch(search);
    }
  }, [search])

  const fetchInteractions = useCallback(() => {
    if (searchInternal) {
      setIsLoading(true);
      const contact = searchInternal?.contact;
      const agent = searchInternal?.agent;

      if (!contact?._id) return;
      if (!agent?.uid) return;

      const filters = [
          collectionGroup(db, 'messages'),
          where('contact._id', '==', contact?._id),
          where('type', 'in', ['email', 'whatsapp']),
          where('agent.uid', '==', agent?.uid),
          orderBy('createdAt', 'desc'),
          limit(20)
      ].filter(Boolean);
      const q = query.apply(this, filters);
      getDocs(q).then((docs) => {
          const aux = [];
          docs.forEach((doc) => aux.push(doc.data()));
          setMessages(aux);
          setIsLoading(false);
      });
    }
  }, [searchInternal]);

  useEffect(() => {
    fetchInteractions();
  }, [searchInternal, fetchInteractions]);


  const reloadMessages = () => {
    fetchInteractions()
  }

  return { messages, reloadMessages, isLoading }
}