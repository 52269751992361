import { notification } from 'antd';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { hasPermissions } from '../utils/checkPermissions';
import useUser from './useUser';

export const useHasPermissions = (instance, entity: 'property' | 'contact' | 'agent' | 'company', redirect = true) => {
    const user = useUser((store) => store.user);
    const [hasPermission, setHasPermission] = useState(true);
    const router = useRouter();

    useEffect(() => {
        if (!redirect) return;
        if (!hasPermission) {
            notification.error({
                message: 'No tienes permiso para acceder a esta página'
            });
            router.push('/');
        }
    }, [hasPermission]);

    useEffect(() => {
        if (!user) {
            return;
        }
        if (entity == 'property') {
            return setHasPermission(hasPermissions(user, instance));
        }

        if (entity == 'agent') {
            return setHasPermission(
                user?.features?.userCreation ||
                    (user?.permissions?.users?.edit && user?.company?._id == instance?.company?._id) ||
                    user?.uid == instance.uid
            );
        }
        if (entity == 'company') {
            if (!instance) return;
            return setHasPermission(
                user?.features?.userCreation || (user?.permissions?.users?.edit && user?.company?._id == instance?._id)
            );
        }
    }, [user, instance, entity]);

    return hasPermission;
};
