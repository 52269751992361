import { useEffect, useState } from 'react';
import { PulppoDrawer } from './PulppoDrawer';
import { ISearch } from '@pulppo/pulppo-models';
import { fetcher, api_url } from '../../helpers/fetcher';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { EmptyState } from './EmptyState';

export const SearchSelect = ({ onSelect, render }) => {
    const [searches, setSearches] = useState<ISearch[]>([]);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        fetcher(`${api_url}/search/active`).then((res) => setSearches(res));
    }, []);
    return (
        <div>
            <div className="cursor-pointer" onClick={() => setOpen(true)}>
                {render()}
            </div>
            <PulppoDrawer visible={open} onClose={() => setOpen(false)} title={'Selecciona tu cliente'}>
                <div className="flex flex-col gap-2 p-2">
                    {searches && searches.length == 0 && <EmptyState description="No tienes búsquedas activas" />}
                    {searches?.map((search) => (
                        <div
                            onClick={() => {
                                onSelect(search);
                                setOpen(false);
                            }}
                            className="flex cursor-pointer items-center gap-2 border-b border-solid border-gray-300 p-2 hover:underline"
                            key={search?._id?.toString()}
                        >
                            <Avatar src={search.contact?.profilePicture} icon={<UserOutlined />} />
                            <div>
                                <p>{`${search.contact?.firstName} ${search.contact?.lastName}`}</p>
                                <p className="text-sm text-pulppo-status-dark-disabled">{`${
                                    search.contact?.phone || search.contact?.email
                                }`}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </PulppoDrawer>
        </div>
    );
};
