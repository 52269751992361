import { CheckCircleFilled, CloseCircleOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { ILocation, IProperty } from '@pulppo/pulppo-models';
import { Input, Button } from 'antd';
import { ReactElement, useEffect, useState } from 'react';
import { NextImage } from './NextImage';
import { parsePrice } from '../../utils/parser';
import { searchLocation, searchProperty } from '../../utils/Typesense';
import { useDebounce } from '../../hooks/debounce';
import { PulppoDrawer } from './PulppoDrawer';
import { useTranslate } from '@tolgee/react';

export const TextSearchMobile = ({
    selectedLocations,
    onSelectLocation,
    onSelectProperty,
    onSearch,
    render
}: {
    selectedLocations: ILocation[];
    onSelectLocation: (location: ILocation) => void;
    onSelectProperty: (property: IProperty) => void;
    onSearch: (value: string) => void;
    render: () => ReactElement;
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [open, setOpen] = useState(false);
    const [locations, setLocations] = useState<ILocation[]>([]);
    const [recent, setRecent] = useState<Array<ILocation>>([]);
    const [properties, setProperties] = useState<IProperty[]>([]);

    useDebounce(
        () => {
            if (searchTerm) {
                searchLocation(searchTerm).then((res) => setLocations(res.hits.map((hit) => hit.document) as any[]));
                searchProperty(searchTerm).then((res) => setProperties(res.hits.map((hit) => hit.document) as any[]));
            }
        },
        500,
        [searchTerm]
    );

    useEffect(() => {
        setRecent(
            JSON.parse(localStorage?.getItem('recent_locations') || '[]')
                ?.filter((l) => l.type && l.name && l.id)
                .slice(0, 2)
        );
    }, [open, selectedLocations]);

    return (
        <div>
            <div
                onClick={() => {
                    setOpen(true);
                }}
            >
                {render()}
            </div>

            <PulppoDrawer visible={open} onClose={() => setOpen(false)} title="Buscador">
                <div className="-mx-2 -my-4 flex h-full flex-col gap-3">
                    <p className="flex-0 shrink-0 text-pulppo-status-dark-disabled">
                        Utiliza el buscador para realizar búsquedas según la ubicación, dirección, ID o palabra clave
                    </p>
                    <div className="flex-0 shrink-0">
                        <Input
                            allowClear={true}
                            value={searchTerm}
                            className="h-11 w-full rounded border border-solid border-gray-300 px-4 text-black"
                            placeholder="Buscar"
                            onChange={(ev) => setSearchTerm(ev.target.value)}
                            suffix={<SearchOutlined className="text-xl text-black" />}
                        />
                    </div>

                    {selectedLocations?.length ? (
                        <div>
                            <div className="flex-0 flex shrink-0 flex-wrap items-center gap-2">
                                {selectedLocations.map((loc) => (
                                    <div
                                        key={loc.id}
                                        onClick={() => onSelectLocation(loc)}
                                        className="flex h-6 cursor-pointer items-center justify-center gap-2 bg-black px-2 text-sm text-white"
                                    >
                                        <p>{loc.name}</p>
                                        <CloseCircleOutlined className="text-base" />
                                    </div>
                                ))}
                            </div>
                            <div className="mt-3 h-px w-full bg-gray-200"></div>
                        </div>
                    ) : (
                        <></>
                    )}

                    {recent?.length ? (
                        <div className="flex-0 shrink-0">
                            <div className="flex gap-4">
                                <p className="text-pulppo-status-dark-disabled">Recientes</p>
                                <div className="flex flex-wrap gap-2">
                                    {recent.map((loc) => (
                                        <div
                                            key={loc.id}
                                            onClick={() => onSelectLocation(loc)}
                                            className="flex h-auto cursor-pointer items-center justify-center gap-2 border border-solid border-gray-300 bg-white px-2 text-sm text-pulppo-status-dark-disabled"
                                        >
                                            <p>{loc.name}</p>
                                            <PlusCircleOutlined className="text-base" />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="mt-3 h-px w-full bg-gray-200"></div>
                        </div>
                    ) : (
                        <></>
                    )}
                    {locations?.length ? (
                        <div>
                            {' '}
                            <p className="mb-2 text-pulppo-status-dark-disabled">Ubicaciones</p>
                            <div
                                className="flex-0 flex shrink-0 flex-col gap-2 overflow-y-auto"
                                style={{ maxHeight: '110px' }}
                            >
                                {locations?.map((location) => (
                                    <LocationOption
                                        selected={selectedLocations?.some((elem) => elem.id === location.id)}
                                        onClick={() => onSelectLocation(location)}
                                        location={location}
                                        key={location.id}
                                    />
                                ))}
                            </div>{' '}
                        </div>
                    ) : (
                        <p className="text-pulppo-status-dark-disabled">Ubicaciones | Sin resultados</p>
                    )}
                    <div className="h-px w-full bg-gray-200"></div>
                    {properties?.length ? (
                        <>
                            <p className="text-pulppo-status-dark-disabled">Propiedades</p>
                            <div className="flex h-auto flex-1 shrink-0 flex-col gap-1 overflow-y-auto overflow-x-hidden">
                                {properties?.map((property) => (
                                    <PropertyOption
                                        onClick={() => onSelectProperty(property)}
                                        property={property}
                                        key={property._id.toString()}
                                    />
                                ))}
                            </div>
                        </>
                    ) : (
                        <p className="flex-1 text-pulppo-status-dark-disabled">Propiedades | Sin resultados</p>
                    )}
                    <div>
                        <div className="mb-3 h-px w-full bg-gray-200"></div>
                        <p>O presiona buscar por título, dirección, etc</p>
                    </div>

                    <div className="flex w-full gap-3">
                        <Button className="h-10 flex-1 rounded font-medium" disabled={true}>
                            Ver mapa
                        </Button>
                        <Button
                            className="h-10 flex-1 rounded font-medium"
                            type="primary"
                            onClick={() => {
                                setOpen(false);
                                onSearch(searchTerm);
                            }}
                        >
                            Buscar
                        </Button>
                    </div>
                </div>
            </PulppoDrawer>
        </div>

        // </AutoComplete>
    );
};

export const LocationOption = ({
    location,
    onClick,
    selected
}: {
    location: ILocation;
    onClick: () => void;
    selected: boolean;
}) => {
    return (
        <div onClick={onClick} className="flex cursor-pointer gap-2 hover:bg-light-background">
            {selected ? (
                <CheckCircleFilled className="text-xl text-black" />
            ) : (
                <PlusCircleOutlined className="text-xl text-pulppo-status-dark-disabled" />
            )}
            <p>
                <span className="font-medium">{location.fullName?.split(',').slice(0, 2).join(', ')}</span>
            </p>
        </div>
    );
};

export const PropertyOption = ({ property, onClick }: { property: IProperty; onClick: () => void }) => {
    const { t } = useTranslate('common');
    return (
        <div
            onClick={onClick}
            className="flex w-full cursor-pointer items-center justify-between hover:bg-light-background"
        >
            <div className="shrink-1 flex w-4/5 flex-1 items-center gap-2">
                <div className="shrink-0">
                    <NextImage
                        alt="picture"
                        width={500}
                        height={500}
                        className="h-16 w-16 rounded-md"
                        src={property?.pictures?.[0]?.url}
                    />
                </div>

                <div className="shrink-1 w-4/5">
                    <p className="truncate font-medium">
                        {property?.address?.street || property?.listing?.title || ''}
                    </p>
                    <p className="truncate text-pulppo-status-dark-disabled">
                        {property?.address?.neighborhood?.name || property?.address?.city?.name
                            ? `${
                                  property?.address?.neighborhood?.name
                                      ? property?.address?.neighborhood?.name + ','
                                      : ''
                              } ${property?.address?.city?.name}`
                            : 'Sin colonia'}
                    </p>
                    <p className="font-medium">{`${
                        property?.listing?.operation === 'sale' ? 'Venta' : t('rent', 'Renta')
                    } | ${parsePrice({
                        price: property?.listing?.price?.price,
                        startStr: `${property?.listing?.price?.currency || process.env.NEXT_PUBLIC_PRIMARY_CURRENCY} `
                    })}`}</p>
                </div>
            </div>
            <p className="shrink-0 px-4 uppercase">{property.internalId}</p>
        </div>
    );
};
