import axios from '../utils/axios';
import { api_url } from '../helpers/fetcher';
import { IContact } from '@pulppo/pulppo-models';

const addViewer = async ({ contactId, agentUid, firstName, lastName }) => {
    return axios
        .post(`${api_url}/contact/${contactId}/viewers`, {
            uid: agentUid,
            contactAlias: {
                firstName,
                lastName
            }
        })
        .then((res) => res.data)
        .catch(console.error);
};

const searchExistByPhone = async (phone: string): Promise<null | IContact> => {
    const plain = phone.replace(/\D/gi, '');
    if (!plain) return;
    if (plain.startsWith('54') && !plain.startsWith('549')) plain.replace('54', '549');
    if (plain.startsWith('52') && !plain.startsWith('521')) plain.replace('52', '521');
    const contact = await axios.get(`${api_url}/contact/phones/${plain}`).then((res) => res.data);
    if (contact) {
        return contact;
    }
    return null;
};

const getAlias = (contactId: string) => {
    return axios.get(`${api_url}/contact/${contactId}/alias`).then((res) => res.data);
};

export const contactService = {
    addViewer,
    searchExistByPhone,
    getAlias
};
