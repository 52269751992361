import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import { useEffect, useState } from 'react';

export const Carousel = ({ items, renderItem, showControls = true, minHeight = '200px', hoverable = false }) => {
    const [selected, setSelected] = useState(0);

    useEffect(() => {
        setSelected(0);
    }, [items]);

    return (
        <div
            className="relative w-full"
            style={{
                overflow: 'hidden',
                minHeight: minHeight
            }}
        >
            <div
                className="flex h-full w-full items-center transition-all duration-500"
                style={{
                    transform: `translateX(-${selected * 100}%)`
                }}
            >
                {items?.map((item, i) => (
                    <div
                        style={{ minWidth: '100%' }}
                        className="flex h-full items-center justify-center bg-gray-100"
                        key={item.url}
                    >
                        {renderItem(item, i)}
                    </div>
                ))}
            </div>
            {showControls && items?.length > 1 && (
                <LeftCircleFilled
                    onClick={(e) => {
                        e.stopPropagation();
                        setSelected((selected) => (selected - 1 < 0 ? items?.length - 1 : selected - 1));
                    }}
                    className={`absolute bottom-1/2 flex translate-y-1/2 transform cursor-pointer ${
                        hoverable
                            ? 'left-5 text-4xl text-black opacity-50'
                            : 'left-5 text-4xl text-black md:left-10 md:text-4xl'
                    } `}
                />
            )}
            {showControls && items?.length > 1 && (
                <RightCircleFilled
                    onClick={(e) => {
                        e.stopPropagation();

                        setSelected((selected) => (selected + 1 >= items?.length ? 0 : selected + 1));
                    }}
                    className={`absolute bottom-1/2 flex translate-y-1/2 transform cursor-pointer ${
                        hoverable
                            ? 'right-5 text-4xl text-black opacity-50'
                            : 'right-5 text-4xl text-black md:right-10 md:text-4xl'
                    } `}
                />
            )}
        </div>
    );
};
