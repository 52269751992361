import useMetric from '../../hooks/useMetric/useMetric';
import { MetricType } from '@pulppo/pulppo-models/build/enums/MetricType';
import useSWR from 'swr';
import { IResponse } from '../../types';
import { api_url, fetcher } from '../../helpers/fetcher';
import { IAgent, ICourse } from '@pulppo/pulppo-models';
import { useState } from 'react';
import useUser from '../../hooks/useUser';
import { setCourseToAgentOngoing } from '../../utils/courses';
import useCourse from '../../hooks/useCourse';
import SimpleCardStat from './SimpleCardStat';
import {
    getMetricTitle,
    interactionsToQuality,
    isLoadingMetricByCurrentWeek,
    MetricStatus,
    selectMetricCurrentWeek,
    verifyMetricStatus
} from '../../utils/ConstantsMetrics';
import DrawerMetricView from './DrawerMetricView';
import { METRICS_DESCRIPTION } from '../../utils/constants';
import DrawerMetricCourse from './DrawerMetricCourse';
import DrawerWhatsapp from './DrawerWhatsapp';
import { useRouter } from 'next/router';
import DrawerSuspension from './DrawerSuspension';

interface Props {
    agentId?: string;
}

const getAgentsNoCompletedCourses = (agents: IAgent[], courses: Array<ICourse>) => {
    return agents?.filter((agent) => {
        const coursesClean = setCourseToAgentOngoing(agent?.courses as Array<any>, courses);
        return coursesClean.filter(
            (course) => course.progress.status === 'notStarted' || course.progress.status === 'ongoing'
        ).length;
    }).length;
};

const NewSectionMetrics = ({ agentId }: Props) => {
    const router = useRouter();
    const setShowMoreMetric = (metric: '' | MetricType) => {
        router.query.metric = metric;
        router.push(router);
    };
    const [showDrawerCourse, setShowDrawerCourse] = useState(false);
    const [showDrawerWhatsapp, setShowDrawerWhatsapp] = useState(false);
    const user = useUser((u) => u.user);
    const company = user?.company;
    const statsView = user?.permissions?.stats?.view;

    const {
        state: { data: metrics, isLoading: metricsIsLoading }
    } = useMetric({
        agentId,
        currentWeek: true,
        limit: 1,
        select: selectMetricCurrentWeek({
            currentWeek: true
        })
    });
    const {
        state: { data: metricsNoRealTime, isLoading: metricsNoRealTimeIsLoading }
    } = useMetric({
        agentId,
        currentWeek: false,
        limit: 1,
        select: selectMetricCurrentWeek({
            currentWeek: false
        })
    });
    const { data: agentsCount, isLoading: isLoadingAgents } = useSWR<
        IResponse<{
            total: number;
            whatsapp: number;
            suspension: number;
            warning: number;
        }>
    >(user && `${api_url}/metrics/agents`, fetcher);
    const { data: agents = [] } = useSWR<Array<IAgent>>(
        `${api_url}/company/${company?._id}/agents?filterBy=status`,
        fetcher
    );
    const metric = {
        ...metrics[0],
        data: {
            ...metrics[0]?.data,
            firstResponseTime: metricsNoRealTime[0]?.data?.firstResponseTime,
            avgResponseTime: metricsNoRealTime[0]?.data?.avgResponseTime,
            interactions: metricsNoRealTime[0]?.data?.interactions,
            visitRate: Math.round(metricsNoRealTime[0]?.data?.visitRate),
            offerRate: parseFloat((metricsNoRealTime[0]?.data?.offerRate || 0).toFixed(2)),
            avgSuggestedProperties: Math.round(metricsNoRealTime[0]?.data?.avgSuggestedProperties)
        }
    };
    const {
        state: { courses }
    } = useCourse({});
    const avgResponseRate = Math.trunc(metric?.data?.avgResponseTime / 60);
    const firstResponseRate = Math.trunc(metric?.data?.firstResponseTime / 60) || 0;
    const propertySalePercentage = Math.round((metric?.data?.propertiesSale * 100) / metric?.data?.properties) || 0;
    const cantAgentsNoCompletedCourses = getAgentsNoCompletedCourses(agents, courses.total);

    return (
        <div className="grid gap-6 sm:grid-cols-2">
            {user?.permissions?.billing?.view && !agentId ? (
                <>
                    <SimpleCardStat
                        isLoading={isLoadingAgents}
                        name={METRICS_DESCRIPTION.suspension.title}
                        onClick={() => {
                            router.query.metric = 'suspension';
                            router.push(router);
                        }}
                        value={`${agentsCount?.data?.suspension}/${agentsCount?.data?.total}`}
                        color={agentsCount?.data?.suspension ? 'red' : 'gray'}
                    />
                    <SimpleCardStat
                        isLoading={isLoadingAgents}
                        name={METRICS_DESCRIPTION.warning.title}
                        onClick={() => {
                            router.query.metric = 'suspension';
                            router.push(router);
                        }}
                        value={`${agentsCount?.data?.warning}/${agentsCount?.data?.total}`}
                        color={agentsCount?.data?.warning > agentsCount?.data?.total * 0.2 ? 'red' : 'gray'}
                    />
                    <SimpleCardStat
                        isLoading={isLoadingAgents}
                        name={METRICS_DESCRIPTION.whatsapp.title}
                        onClick={() => setShowDrawerWhatsapp(true)}
                        value={`${agentsCount?.data?.whatsapp}/${agentsCount?.data?.total}`}
                        color={agentsCount?.data?.whatsapp === agentsCount?.data?.total ? 'gray' : 'red'}
                    />
                    <DrawerWhatsapp
                        visible={showDrawerWhatsapp}
                        onClose={() => setShowDrawerWhatsapp(false)}
                        companyId={company?._id as string}
                    />
                    <DrawerSuspension
                        visible={router.query.metric === 'suspension'}
                        onClose={() => {
                            router.query.metric = '';
                            router.push(router);
                        }}
                        companyId={company?._id as string}
                    />
                </>
            ) : null}
            {statsView && !agentId ? (
                <>
                    <SimpleCardStat
                        isLoading={metricsIsLoading}
                        name={METRICS_DESCRIPTION.brokersCertificate.title}
                        onClick={() => setShowDrawerCourse(true)}
                        value={`${agents.length - cantAgentsNoCompletedCourses}/${agents.length}`}
                        color={cantAgentsNoCompletedCourses === 0 ? 'gray' : 'red'}
                    />
                    <DrawerMetricCourse
                        show={showDrawerCourse}
                        onClose={() => setShowDrawerCourse(false)}
                        companyId={company?._id as string}
                    />
                </>
            ) : null}
            <SimpleCardStat
                name={getMetricTitle(MetricType.UnansweredLeads, 'short')}
                isLoading={isLoadingMetricByCurrentWeek({
                    type: MetricType.UnansweredLeads,
                    isLoadingNoCurrentWeek: metricsNoRealTimeIsLoading,
                    isLoadingCurrentWeek: metricsIsLoading
                })}
                value={metric?.data?.unansweredLeads}
                color={
                    verifyMetricStatus(MetricType.UnansweredLeads, metric?.data?.unansweredLeads) === MetricStatus.Bad
                        ? 'red'
                        : 'gray'
                }
                onClick={() => setShowMoreMetric(MetricType.UnansweredLeads)}
            />
            <SimpleCardStat
                name={getMetricTitle(MetricType.FirstResponseTime, 'short')}
                isLoading={isLoadingMetricByCurrentWeek({
                    type: MetricType.FirstResponseTime,
                    isLoadingNoCurrentWeek: metricsNoRealTimeIsLoading,
                    isLoadingCurrentWeek: metricsIsLoading
                })}
                value={`${firstResponseRate} minutos`}
                color={
                    verifyMetricStatus(MetricType.FirstResponseTime, firstResponseRate) === MetricStatus.Bad
                        ? 'red'
                        : 'gray'
                }
                onClick={() => setShowMoreMetric(MetricType.FirstResponseTime)}
            />
            <SimpleCardStat
                name={getMetricTitle(MetricType.TasksPendingClient, 'short')}
                isLoading={isLoadingMetricByCurrentWeek({
                    type: MetricType.TasksPendingClient,
                    isLoadingNoCurrentWeek: metricsNoRealTimeIsLoading,
                    isLoadingCurrentWeek: metricsIsLoading
                })}
                value={metric?.data?.tasksPendingClient + metric?.data?.tasksExpiredClient}
                color={
                    verifyMetricStatus(
                        MetricType.TasksPendingClient,
                        metric?.data?.tasksPendingClient + metric?.data?.tasksExpiredClient
                    ) === MetricStatus.Bad
                        ? 'red'
                        : 'gray'
                }
                onClick={() => setShowMoreMetric(MetricType.TasksPendingClient)}
            />
            <SimpleCardStat
                name={getMetricTitle(MetricType.AvgResponseTime, 'short')}
                isLoading={isLoadingMetricByCurrentWeek({
                    type: MetricType.AvgResponseTime,
                    isLoadingNoCurrentWeek: metricsNoRealTimeIsLoading,
                    isLoadingCurrentWeek: metricsIsLoading
                })}
                value={`${avgResponseRate || 0} minutos`}
                color={
                    verifyMetricStatus(MetricType.AvgResponseTime, avgResponseRate) === MetricStatus.Bad
                        ? 'red'
                        : 'gray'
                }
                onClick={() => setShowMoreMetric(MetricType.AvgResponseTime)}
            />
            <SimpleCardStat
                name={getMetricTitle(MetricType.Interactions, 'short')}
                isLoading={isLoadingMetricByCurrentWeek({
                    type: MetricType.Interactions,
                    isLoadingNoCurrentWeek: metricsNoRealTimeIsLoading,
                    isLoadingCurrentWeek: metricsIsLoading
                })}
                value={interactionsToQuality(metric?.data?.interactions)}
                color={
                    verifyMetricStatus(MetricType.Interactions, metric?.data?.interactions) === MetricStatus.Bad
                        ? 'red'
                        : 'gray'
                }
                onClick={() => setShowMoreMetric(MetricType.Interactions)}
            />
            <SimpleCardStat
                name={getMetricTitle(MetricType.AvgSuggestedProperties, 'short')}
                isLoading={isLoadingMetricByCurrentWeek({
                    type: MetricType.AvgSuggestedProperties,
                    isLoadingNoCurrentWeek: metricsNoRealTimeIsLoading,
                    isLoadingCurrentWeek: metricsIsLoading
                })}
                value={metric?.data?.avgSuggestedProperties}
                color={
                    verifyMetricStatus(MetricType.AvgSuggestedProperties, metric?.data?.avgSuggestedProperties) ===
                    MetricStatus.Bad
                        ? 'red'
                        : 'gray'
                }
                onClick={() => setShowMoreMetric(MetricType.AvgSuggestedProperties)}
            />
            <SimpleCardStat
                name={getMetricTitle(MetricType.VisitRate, 'short')}
                isLoading={isLoadingMetricByCurrentWeek({
                    type: MetricType.VisitRate,
                    isLoadingNoCurrentWeek: metricsNoRealTimeIsLoading,
                    isLoadingCurrentWeek: metricsIsLoading
                })}
                value={`${metric?.data?.visitRate || 0}%`}
                color={
                    verifyMetricStatus(MetricType.VisitRate, metric?.data?.visitRate) === MetricStatus.Bad
                        ? 'red'
                        : 'gray'
                }
                onClick={() => setShowMoreMetric(MetricType.VisitRate)}
            />
            <SimpleCardStat
                name={getMetricTitle(MetricType.OfferRate, 'short')}
                isLoading={isLoadingMetricByCurrentWeek({
                    type: MetricType.OfferRate,
                    isLoadingNoCurrentWeek: metricsNoRealTimeIsLoading,
                    isLoadingCurrentWeek: metricsIsLoading
                })}
                value={`${metric?.data?.offerRate}%`}
                color={
                    verifyMetricStatus(MetricType.OfferRate, metric?.data?.offerRate) === MetricStatus.Bad
                        ? 'red'
                        : 'gray'
                }
                onClick={() => setShowMoreMetric(MetricType.OfferRate)}
            />
            <SimpleCardStat
                name={getMetricTitle(MetricType.TasksPendingProperty, 'short')}
                isLoading={isLoadingMetricByCurrentWeek({
                    type: MetricType.TasksPendingProperty,
                    isLoadingNoCurrentWeek: metricsNoRealTimeIsLoading,
                    isLoadingCurrentWeek: metricsIsLoading
                })}
                value={metric?.data?.tasksPendingProperty + metric?.data?.tasksExpiredProperty}
                color={
                    verifyMetricStatus(
                        MetricType.TasksPendingProperty,
                        metric?.data?.tasksPendingProperty + metric?.data?.tasksExpiredProperty
                    ) === MetricStatus.Bad
                        ? 'red'
                        : 'gray'
                }
                onClick={() => setShowMoreMetric(MetricType.TasksPendingProperty)}
            />
            <SimpleCardStat
                name={getMetricTitle(MetricType.PropertiesSale, 'short')}
                isLoading={isLoadingMetricByCurrentWeek({
                    type: MetricType.PropertiesSale,
                    isLoadingNoCurrentWeek: metricsNoRealTimeIsLoading,
                    isLoadingCurrentWeek: metricsIsLoading
                })}
                value={`${propertySalePercentage}%`}
                color={
                    verifyMetricStatus(MetricType.PropertiesSale, propertySalePercentage) === MetricStatus.Bad
                        ? 'red'
                        : 'gray'
                }
                onClick={() => setShowMoreMetric(MetricType.PropertiesSale)}
            />
            <SimpleCardStat
                name={getMetricTitle(MetricType.Properties, 'short')}
                isLoading={isLoadingMetricByCurrentWeek({
                    type: MetricType.Properties,
                    isLoadingNoCurrentWeek: metricsNoRealTimeIsLoading,
                    isLoadingCurrentWeek: metricsIsLoading
                })}
                value={metric?.data?.properties}
                color={
                    verifyMetricStatus(MetricType.Properties, metric?.data?.properties) === MetricStatus.Bad
                        ? 'red'
                        : 'gray'
                }
                onClick={() => setShowMoreMetric(MetricType.Properties)}
            />
            <DrawerMetricView
                metricType={router.query.metric as MetricType}
                show={!!router.query.metric}
                onClose={() => setShowMoreMetric('')}
                agentId={agentId}
            />
        </div>
    );
};

export default NewSectionMetrics;
