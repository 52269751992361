const ExclusiveTag = ({ isPulppoExclusive }: { isPulppoExclusive: boolean }) => {
    return (
        <div className="flex h-7 items-center gap-1 bg-black px-2 py-1 uppercase text-white">
            <img
                src={process.env.NEXT_PUBLIC_LOGO_BLANCO_SOLO || '/pulppo-icon-white.png'}
                alt="Pulppo"
                className="h-6 w-6"
            />
            <p>EXCLUSIVA{isPulppoExclusive ? ' 1-5-10' : null}</p>
        </div>
    );
};

export default ExclusiveTag;
