import { Bar, BarChart, Cell, ReferenceLine, ResponsiveContainer, XAxis, Text } from 'recharts';
import useIsMobile from '../../hooks/isMobile';
import { ReactNode } from 'react';
import { BasicColor } from './AgentsList';
import dayjs from 'dayjs';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const renderCustomBarLabel = ({ x, y, width, height, value, suffix, verticalTextY }) => {
    return (
        <Text
            className="text-xs"
            angle={verticalTextY ? -90 : 0}
            x={x + width / 2}
            y={verticalTextY ? y + height / 2 : y}
            textAnchor={verticalTextY && height < 50 ? 'start' : 'middle'}
            dy={verticalTextY ? 0 : -6}
            dx={verticalTextY ? 3 : 0}
        >
            {`${verticalTextY || value}${suffix || ''}`}
        </Text>
    );
};

function getLastSaturday(date: string) {
    let givenDate = dayjs(date);
    let dayOfWeek = givenDate.day();
    let daysToLastSaturday = dayOfWeek === 6 ? 0 : dayOfWeek + 1;
    return givenDate.subtract(daysToLastSaturday, 'day');
}

const CustomXAxisTick = ({
    x,
    y,
    payload
}: {
    x: number;
    y: number;
    payload: {
        coordinate: number;
        index: number;
        offset: number;
        value: string;
    };
}) => {
    let initDate = dayjs(payload.value).tz();
    let date: dayjs.Dayjs;
    let endDate: string;
    const offsetInMinutes = initDate.utcOffset();
    const offsetInHours = offsetInMinutes / 60;
    const isSum = offsetInHours <= 0;

    if (isSum) {
        initDate = initDate.add(Math.abs(offsetInHours), 'hours');
    } else {
        initDate = initDate.subtract(offsetInHours, 'hours');
    }

    if (initDate.isSame(dayjs().tz(), 'day')) {
        date = getLastSaturday(payload.value);
        endDate = initDate.format('DD/MM');
    } else {
        date = initDate.add(2, 'days');
        endDate = date.add(1, 'weeks').subtract(1, 'days').format('DD/MM');
    }

    return (
        <g transform={`translate(${x - 15},${y})`}>
            <text className="fill-pulppo-primary-gray text-[10px] md:text-xs" x={0} y={0} dy={8}>
                {date.format('DD/MM')}
            </text>
            <text className="fill-pulppo-primary-gray text-[10px] md:text-xs" x={0} y={12} dy={8}>
                {endDate}
            </text>
        </g>
    );
};

export type MetricBarChartData = Array<{
    id: string;
    value: {
        x: number | string;
        y: number;
    };
    status?: BasicColor;
    verticalTextY?: string;
}>;

interface Props {
    data: MetricBarChartData;
    extra?: {
        label: ReactNode | string;
        referenceLineY?: number;
        suffix?: ReactNode | string;
        tooltip?: string;
    };
}

const MetricBarChart = ({ data, extra }: Props) => {
    const isMobile = useIsMobile();

    return (
        <div>
            {data.length ? (
                <div className="mb-4 flex items-center justify-between gap-x-4">
                    {extra?.label ? <p className="text-xs text-pulppo-primary-gray">{extra.label}</p> : null}
                    {extra?.tooltip ? (
                        <Tooltip title={extra.tooltip} placement="left">
                            <QuestionCircleOutlined className="text-pulppo-primary-gray" />
                        </Tooltip>
                    ) : null}
                </div>
            ) : null}
            <ResponsiveContainer width="100%" height={180}>
                {data.length ? (
                    <BarChart
                        margin={{
                            top: 20
                        }}
                        data={data}
                    >
                        <Bar
                            dataKey="value.y"
                            label={(props) => {
                                const verticalTextY = data.find((d) => d?.id === props?.id)?.verticalTextY;
                                return renderCustomBarLabel({
                                    ...props,
                                    verticalTextY,
                                    suffix: extra.suffix
                                });
                            }}
                            isAnimationActive={false}
                        >
                            {data.map((entry) => {
                                return (
                                    <Cell
                                        key={`cell-${entry.id}`}
                                        className={
                                            entry.status === BasicColor.Good
                                                ? 'fill-pulppo-status-light-success'
                                                : entry.status === BasicColor.Neutral
                                                  ? 'fill-pulppo-status-light-warning'
                                                  : 'fill-pulppo-status-light-error'
                                        }
                                    />
                                );
                            })}
                        </Bar>
                        <XAxis
                            dataKey="value.x"
                            // @ts-ignore
                            tick={<CustomXAxisTick />}
                            interval={isMobile ? 1 : 0}
                            axisLine={false}
                            tickLine={false}
                        />
                        {extra?.referenceLineY ? <ReferenceLine y={extra.referenceLineY} stroke="#c9f7f4" /> : null}
                    </BarChart>
                ) : (
                    <div className="flex h-full items-center justify-center">
                        <p className="text-pulppo-primary-gray">No hay datos</p>
                    </div>
                )}
            </ResponsiveContainer>
        </div>
    );
};

export default MetricBarChart;
