import { IProperty } from '@pulppo/pulppo-models';
import { Button, Checkbox, Form, Input, Modal, notification, Tabs } from 'antd';
import axios from '../../../utils/axios';
import { ReactElement, ReactNode, SVGProps, useContext, useEffect, useRef, useState } from 'react';
import { api_legal_url, api_url, fetcher, legal_url } from '../../../helpers/fetcher';
import {
    CheckCircleFilled,
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleFilled,
    CopyOutlined,
    ExclamationCircleFilled,
    FilePdfOutlined,
    MailOutlined,
    ReloadOutlined,
    WarningOutlined
} from '@ant-design/icons';
import { FileUpload } from '../../Elements/FileUpload';
import { PulppoDrawer } from '../../Elements/PulppoDrawer';
import { T, useTranslate } from '@tolgee/react';
import { ContractNotes } from '../ContractNotes';
import TabPane from 'antd/lib/tabs/TabPane';
import { LoadingButton } from '../../Elements/ButtonLoading';
import dayjs from 'dayjs';
import { ContactsIcon, PropertiesIcon } from '../../Sidebar/Icons';
import { io, Socket } from 'socket.io-client';
import useSWR from 'swr';
import { useForm } from 'antd/lib/form/Form';
import { PhoneInputPlain } from '../../Elements/PhoneInputPlain';
import { AttributeMapper } from '../../../utils/AttributeMapper';
import TextArea from 'antd/lib/input/TextArea';
import { MapUserToSimpleUser } from '../../../utils/MapContactToSimpleUser';
import { ObjectId } from '../../../utils/ObjectId';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../../services/authentication';
import { PulppoLoader } from '../../Elements/PulppoLoader';
import { openUrl } from '../../../utils/openUrl';
import { showShareDrawer } from '../../Contact/ShareDrawer';
import { ELEMENTS_TO_CLICK } from '../../Captures/VerifyRegenerateContract';
import useUser from '../../../hooks/useUser';
import { useShallow } from 'zustand/react/shallow';
import { openPropertyStatusDrawer } from '../PropertyStatusDrawer';
import { PropertyFormContext } from '../../../contexts/PropertyFormContext';
import { useRouter } from 'next/router';

const Mapper = {
    owner: 'Propietario',
    legalRepresentative: 'Inmobiliaria',
    broker: 'Asesor',
    pulppo: 'Equipo de pulppo'
};

export const ContractIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg {...props} width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.31033 0.723633H10.6673C11.057 0.723633 11.3981 1.07178 11.3981 1.47801V5.88853V13.0845C11.3981 13.4907 11.057 13.8389 10.6673 13.8389H10.2775H1.11787C0.728105 13.8389 0.387056 13.4907 0.387056 13.0845V4.32161"
            fill="#F4F7FD"
        />
        <path
            d="M3.31033 0.723633H10.6673C11.057 0.723633 11.3981 1.07178 11.3981 1.47801V5.88853V13.0845C11.3981 13.4907 11.057 13.8389 10.6673 13.8389H10.2775H1.11787C0.728105 13.8389 0.387056 13.4907 0.387056 13.0845V4.32161"
            stroke="#212322"
            stroke-width="0.59615"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M4.64026 6.31641H7.74023"
            stroke="#212322"
            stroke-width="0.59615"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M6.19031 7.80688V4.7666"
            stroke="#212322"
            stroke-width="0.59615"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M4.64026 10.1914H7.74023"
            stroke="#212322"
            stroke-width="0.59615"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M3.3103 0.723633V3.39311C3.3103 3.85737 3.01798 4.26359 2.62821 4.26359H0.435749L3.3103 0.723633Z"
            stroke="#212322"
            stroke-width="0.59615"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export const getPropertyError = (property: IProperty, t) => {
    if (!property?.address?.street) return 'Tienes que agregar una dirección';
    if (!property?.contact) return 'Tienes que agregar un propietario';
    if (!property?.type) return 'Tienes que agregar un tipo de propiedad';
    if (!property?.address?.neighborhood?.name) return 'Tienes que agregar una colonia';
    if (!property?.address?.city?.name) return 'Tienes que agregar una ciudad o delegación';
    if (!property?.address?.state?.name) return 'Tienes que agregar un estado';
    if (!property?.listing?.operation) return 'Tienes que agregar un tipo de operación';
    if (!property?.listing?.price?.price) return 'Tienes que agregar un precio';
    if (!property?.contract?.durationMonths) return 'Tienes que agregar una duración del acuerdo';
    if (!property?.contract?.comission) return 'Tienes que agregar una comisión';
    if (!property?.listing?.title) return 'Tienes que agregar un título';
    if (!property?.listing?.description) return 'Tienes que agregar una descripción';
    for (const key in AttributeMapper) {
        if (
            AttributeMapper[key][property.type] === 'required' &&
            [null, undefined, ''].includes(property.attributes?.[key]) &&
            [null, undefined, ''].includes(property.additionalFields?.[key])
        )
            return `Tienes que agregar ${t(key)}`;
    }
    if (!property.pictures?.length) return 'Tienes que agregar al menos una imagen';
    return null;
};

const LegalStep = ({
    action,
    metadata,
    title,
    description
}: {
    metadata?: ReactElement;
    action?: ReactNode;
    title: string;
    description: ReactNode;
}) => {
    return (
        <div
            className={`border border-solid ${status !== 'not-started' ? 'opacity-100' : 'opacity-30'} border-pulppo-secondary-gray-disabled bg-pulppo-secondary-bg p-4`}
        >
            <div className="flex items-center justify-between gap-4">
                <div className="flex flex-1 items-start gap-4">
                    <div>
                        <h3 className="text-black">{title}</h3>
                        <p className="text-pulppo-primary-gray">{description}</p>
                    </div>
                </div>
                {action}
            </div>
            {metadata}
        </div>
    );
};

const ErrorMessages = {
    'invalid-document-type': 'Documento no válido',
    'no-owner-found': 'Nombre del propietario no encontrado',
    'no-company-or-broker-found': 'No se encontró la inmobiliaria',
    unreadable: 'Documento ilegible',
    expired: 'Documento caducado',
    tampered: 'Documento alterado digitalmente',
    'no-signature': 'Documento no firmado',
    'invalid-address': 'Dirección no válida',
    'invalid-property-data': 'Información de la propiedad inválida',
    other: 'Error no identificado',
    'address-mismatch': 'Dirección no coincide',
    'property-data-mismatch': 'Información de la propiedad no coincide',
    'owner-mismatch': 'Documento no coincide con el propietario',
    'address-not-found': 'Dirección no encontrada',
    'no-name-found': 'No se encontró el nombre del propietario'
};

const getErrorMessage = (m: string) => {
    const key = m.split(':');
    let text = key[1] || key[2] || key[0] || '';
    text = text.endsWith('.') ? text.slice(0, -1) : text;
    return (
        <div>
            <p className="font-bold">{ErrorMessages[key[0]] || 'Error no identificado'}</p>
            <p className="text-sm">{text}</p>
            {process.env.NEXT_PUBLIC_DISABLE_LEGAL_AI !== 'true' && (
                <p className="text-sm">
                    A veces, la AI puede fallar. Intenta cargar otro documento o intentar de nuevo
                </p>
            )}
        </div>
    );
};

type IContract = Exclude<IProperty['contract'], undefined>;

export const PropertyStep4 = ({
    property,
    legalAdmin = false,
    hiddenExtra = false
}: {
    property: IProperty & { contract: IProperty['contract'] };
    legalAdmin?: boolean;
    hiddenExtra?: boolean;
}) => {
    const user = useUser(useShallow((state) => state.user));
    const [loading, setLoading] = useState('');
    const [accepted, setAccepted] = useState(false);
    const [appeal, setAppeal] = useState<{ message: string; title: string } | null>(null);
    const [error, setError] = useState(property?.contract?.message || '');
    const { t } = useTranslate(['common']);
    const [showNotes, setShowNotes] = useState(false);
    const propertyForm = useContext(PropertyFormContext);
    const { formData } = property ? { formData: property } : propertyForm || {};

    const { data } = useSWR<{ contract: (typeof property)['contract']; files: IProperty['files'] }>(
        `${api_url}/legals/${property._id}`,
        fetcher,
        {
            fallbackData: { contract: property.contract, files: property.files },
            refreshInterval: 1e3
        }
    );
    const contract = data?.contract || ({} as IProperty['contract']);

    const originalFiles = data?.files;
    const status = contract?.status || 'pending';
    const shouldPreventPublish = legalAdmin ? '' : getPropertyError(property, t);

    const userType: 'broker' | 'masterbroker' | 'owner' | 'legal' = user?.uid
        ? legalAdmin
            ? 'legal'
            : user?.uid === property.agent?.uid
              ? 'broker'
              : 'masterbroker'
        : 'owner';

    const userHasSigned = !!contract?.signers
        ?.filter((s) => s.email === user?.email || (user?.features?.legal && s.type === 'pulppo'))
        ?.every((el) => el.signedAt);
    const InitDocumentation = (
        <Button
            disabled={!!shouldPreventPublish}
            onClick={() => setFiles(originalFiles || [])}
            type="default"
            className="h-auto px-6 py-2"
        >
            Iniciar
        </Button>
    );
    const ViewDocumentation = (
        <Button
            disabled={!!shouldPreventPublish}
            onClick={() => setFiles(originalFiles || [])}
            type="default"
            className="h-auto px-6 py-2"
        >
            Ver
        </Button>
    );
    const InitContract = (
        <Button
            disabled={!!shouldPreventPublish}
            onClick={() => setContractFiles(originalFiles || [])}
            type="default"
            className="h-auto px-6 py-2"
        >
            Iniciar
        </Button>
    );
    const ViewContract = (
        <Button
            disabled={!!shouldPreventPublish}
            onClick={() => setContractFiles(originalFiles || [])}
            type="default"
            className="h-auto px-6 py-2"
        >
            Ver
        </Button>
    );
    const SendContract = (
        <Button
            onClick={() =>
                setSendContract(
                    contract.signers?.length
                        ? contract.signers?.filter((signer) => signer.type === 'owner')
                        : [
                              {
                                  ...MapUserToSimpleUser(property?.contact),
                                  type: 'owner',
                                  role: 'signer'
                              }
                          ]
                )
            }
            type="default"
            className="h-auto px-6 py-2"
        >
            Enviar acuerdo
        </Button>
    );
    const ApprovedDocumentation = (
        <div className="w-min bg-pulppo-status-light-success px-2 py-1 text-pulppo-status-dark-success">Aprobado</div>
    );
    const ViewUnsignedContract = contract?.unsignedUrl && (
        <LoadingButton
            type="default"
            onClick={async () => {
                await openUrl(contract?.unsignedUrl);
            }}
        >
            Ver
        </LoadingButton>
    );
    const SignContract = (
        <LoadingButton
            className="px-1"
            onClick={async () => {
                window.open(
                    contract?.signers?.find(
                        (s) =>
                            !s.signedAt && (s.email === user?.email || (user?.features?.legal && s.type === 'pulppo'))
                    )?.signingUrl,
                    '_blank'
                );
            }}
        >
            Firmar
        </LoadingButton>
    );
    const ViewSignedContract = (
        <LoadingButton
            className="px-1"
            onClick={async () => {
                await openUrl(contract?.url);
            }}
        >
            Ver
        </LoadingButton>
    );
    const RegenerateContract = contract.id && (
        <LoadingButton
            className={`px-1 ${ELEMENTS_TO_CLICK['verify-generate-button']}`}
            onClick={async () => {
                setSendContract(
                    contract.signers?.length
                        ? contract.signers?.filter((signer) => signer.type === 'owner')
                        : [
                              {
                                  ...MapUserToSimpleUser(property?.contact),
                                  type: 'owner',
                                  role: 'signer'
                              }
                          ]
                );
            }}
        >
            Regenerar
        </LoadingButton>
    );
    const DeleteContract = contract.id && contract.signers?.every((signer) => !signer.signedAt) && (
        <LoadingButton
            danger
            className="px-1"
            onClick={async () => {
                await axios.delete(`${legal_url}/api/contracts/${contract.id}`);
            }}
        >
            Eliminar
        </LoadingButton>
    );

    const ViewExternal = contract.id && (
        <LoadingButton
            className="px-1"
            onClick={async () => {
                const url =
                    contract?.provider === 'documenso'
                        ? `https://legal.pulppo.com/t/pulppo/documents/${contract.id}`
                        : `https://apps.docusign.com/send/documents/details/${contract.id}`;
                window.open(url, '_blank');
            }}
        >
            Detalle
        </LoadingButton>
    );
    const actionMapper: {
        [key in typeof userType]: {
            [key in IContract['flow']]: {
                [key in IContract['status']]: {
                    [key in 'step_1' | 'step_2' | 'step_3']?: React.ReactNode;
                };
            };
        };
    } = {
        broker: {
            complete: {
                pending: {
                    step_1: InitDocumentation,
                    step_2: <></>,
                    step_3: <></>
                },
                revision: {
                    step_1: ViewDocumentation,
                    step_2: (
                        <div className="shrink-0 bg-pulppo-status-light-warning px-2 py-1 text-pulppo-status-dark-warning">
                            En revisión
                        </div>
                    ),
                    step_3: <></>
                },
                approved: {
                    step_1: ViewDocumentation,
                    step_2: ApprovedDocumentation,
                    step_3: (
                        <div className="flex flex-col gap-2">
                            {contract?.processing === 'automatic' && SendContract}
                            {RegenerateContract}
                        </div>
                    )
                },
                rejected: {
                    step_1: ViewDocumentation,
                    step_2: (
                        <div className="flex gap-2">
                            <div className="w-min bg-pulppo-status-light-error px-2 py-1 text-pulppo-status-dark-error">
                                Rechazado
                            </div>
                            {contract.processing == 'automatic' && (
                                <LoadingButton
                                    type="link"
                                    onClick={async () => {
                                        setAppeal({ message: '', title: 'Apelación' });
                                    }}
                                    className="h-auto border-b border-l-0 border-r-0 border-t-0 border-solid border-pulppo-status-dark-error px-0 py-0 pb-0 text-pulppo-status-dark-error underline hover:border-black hover:text-pulppo-primary-black"
                                >
                                    Apelar
                                </LoadingButton>
                            )}
                        </div>
                    ),
                    step_3: ''
                },
                contract_sent: {
                    step_1: ViewDocumentation,
                    step_2: ApprovedDocumentation,
                    step_3: (
                        <div className="flex flex-col gap-2">
                            {userHasSigned ? ViewUnsignedContract : SignContract}
                            {RegenerateContract}
                        </div>
                    )
                },
                completed: {
                    step_1: ViewDocumentation,
                    step_2: ApprovedDocumentation,
                    step_3: (
                        <div className="flex flex-col gap-2">
                            {userHasSigned ? (contract.url ? ViewSignedContract : ViewUnsignedContract) : SignContract}
                            {RegenerateContract}
                        </div>
                    )
                }
            },
            'only-contract': {
                pending: {
                    step_1: InitContract,
                    step_2: <></>
                },
                revision: {
                    step_1: ViewContract,
                    step_2: (
                        <div className="shrink-0 bg-pulppo-status-light-warning px-2 py-1 text-pulppo-status-dark-warning">
                            En revisión
                        </div>
                    )
                },
                approved: {
                    step_1: ViewContract,
                    step_2: ApprovedDocumentation
                },
                rejected: {
                    step_1: ViewContract,
                    step_2: (
                        <div className="flex gap-2">
                            <div className="w-min bg-pulppo-status-light-error px-2 py-1 text-pulppo-status-dark-error">
                                Rechazado
                            </div>
                            {contract.processing == 'automatic' && (
                                <LoadingButton
                                    type="link"
                                    onClick={async () => {
                                        setAppeal({ message: '', title: 'Apelación' });
                                    }}
                                    className="h-auto border-b border-l-0 border-r-0 border-t-0 border-solid border-pulppo-status-dark-error px-0 py-0 pb-0 text-pulppo-status-dark-error underline hover:border-black hover:text-pulppo-primary-black"
                                >
                                    Apelar
                                </LoadingButton>
                            )}
                        </div>
                    )
                },
                contract_sent: {
                    step_1: ViewContract,
                    step_2: ApprovedDocumentation
                },
                completed: {
                    step_1: ViewContract,
                    step_2: ApprovedDocumentation
                }
            }
        },
        masterbroker: {
            complete: {
                pending: {
                    step_1: InitDocumentation,
                    step_2: <></>,
                    step_3: <></>
                },
                revision: {
                    step_1: ViewDocumentation,
                    step_2: (
                        <div className="shrink-0 bg-pulppo-status-light-warning px-2 py-1 text-pulppo-status-dark-warning">
                            En revisión
                        </div>
                    ),
                    step_3: <></>
                },
                approved: {
                    step_1: ViewDocumentation,
                    step_2: ApprovedDocumentation,
                    step_3: (
                        <div className="flex flex-col gap-2">
                            {contract?.processing === 'automatic' && SendContract}
                            {RegenerateContract}
                        </div>
                    )
                },
                rejected: {
                    step_1: ViewDocumentation,
                    step_2: (
                        <div className="flex gap-2">
                            <div className="w-min bg-pulppo-status-light-error px-2 py-1 text-pulppo-status-dark-error">
                                Rechazado
                            </div>
                            {contract.processing == 'automatic' && (
                                <LoadingButton
                                    type="link"
                                    onClick={async () => {
                                        setAppeal({ message: '', title: 'Apelación' });
                                    }}
                                    className="h-auto border-b border-l-0 border-r-0 border-t-0 border-solid border-pulppo-status-dark-error px-0 py-0 pb-0 text-pulppo-status-dark-error underline hover:border-black hover:text-pulppo-primary-black"
                                >
                                    Apelar
                                </LoadingButton>
                            )}
                        </div>
                    ),
                    step_3: ''
                },
                contract_sent: {
                    step_1: ViewDocumentation,
                    step_2: ApprovedDocumentation,
                    step_3: (
                        <div className="flex flex-col gap-2">
                            {userHasSigned ? ViewUnsignedContract : SignContract}
                            {RegenerateContract}
                        </div>
                    )
                },
                completed: {
                    step_1: ViewDocumentation,
                    step_2: ApprovedDocumentation,
                    step_3: (
                        <div className="flex flex-col gap-2">
                            {userHasSigned ? (contract.url ? ViewSignedContract : ViewUnsignedContract) : SignContract}
                            {RegenerateContract}
                        </div>
                    )
                }
            },
            'only-contract': {
                pending: {
                    step_1: InitContract,
                    step_2: <></>
                },
                revision: {
                    step_1: ViewContract,
                    step_2: (
                        <div className="shrink-0 bg-pulppo-status-light-warning px-2 py-1 text-pulppo-status-dark-warning">
                            En revisión
                        </div>
                    )
                },
                approved: {
                    step_1: ViewContract,
                    step_2: ApprovedDocumentation
                },
                rejected: {
                    step_1: ViewContract,
                    step_2: (
                        <div className="flex gap-2">
                            <div className="w-min bg-pulppo-status-light-error px-2 py-1 text-pulppo-status-dark-error">
                                Rechazado
                            </div>
                            {contract.processing == 'automatic' && (
                                <LoadingButton
                                    type="link"
                                    onClick={async () => {
                                        setAppeal({ message: '', title: 'Apelación' });
                                    }}
                                    className="h-auto border-b border-l-0 border-r-0 border-t-0 border-solid border-pulppo-status-dark-error px-0 py-0 pb-0 text-pulppo-status-dark-error underline hover:border-black hover:text-pulppo-primary-black"
                                >
                                    Apelar
                                </LoadingButton>
                            )}
                        </div>
                    )
                },
                contract_sent: {
                    step_1: ViewContract,
                    step_2: ApprovedDocumentation
                },
                completed: {
                    step_1: ViewContract,
                    step_2: ApprovedDocumentation
                }
            }
        },
        owner: {
            complete: {
                pending: {
                    step_1: InitDocumentation,
                    step_2: <></>,
                    step_3: <></>
                },
                revision: {
                    step_1: ViewDocumentation,
                    step_2: (
                        <div className="shrink-0 bg-pulppo-status-light-warning px-2 py-1 text-pulppo-status-dark-warning">
                            En revisión
                        </div>
                    ),
                    step_3: <></>
                },
                approved: {
                    step_1: ViewDocumentation,
                    step_2: ApprovedDocumentation,
                    step_3: <></>
                },
                rejected: {
                    step_1: ViewDocumentation,
                    step_2: (
                        <div className="flex gap-2">
                            <div className="w-min bg-pulppo-status-light-error px-2 py-1 text-pulppo-status-dark-error">
                                Rechazado
                            </div>
                        </div>
                    ),
                    step_3: ''
                },
                contract_sent: {
                    step_1: ViewDocumentation,
                    step_2: ApprovedDocumentation,
                    step_3: userHasSigned ? ViewUnsignedContract : SignContract
                },
                completed: {
                    step_1: ViewDocumentation,
                    step_2: ApprovedDocumentation,
                    step_3: userHasSigned ? (contract.url ? ViewSignedContract : ViewUnsignedContract) : SignContract
                }
            },
            'only-contract': {
                pending: {
                    step_1: InitContract,
                    step_2: <></>
                },
                revision: {
                    step_1: ViewContract,
                    step_2: (
                        <div className="shrink-0 bg-pulppo-status-light-warning px-2 py-1 text-pulppo-status-dark-warning">
                            En revisión
                        </div>
                    )
                },
                approved: {
                    step_1: ViewContract,
                    step_2: ApprovedDocumentation
                },
                rejected: {
                    step_1: ViewContract,
                    step_2: (
                        <div className="flex gap-2">
                            <div className="w-min bg-pulppo-status-light-error px-2 py-1 text-pulppo-status-dark-error">
                                Rechazado
                            </div>
                        </div>
                    )
                },
                contract_sent: {
                    step_1: ViewContract,
                    step_2: ApprovedDocumentation
                },
                completed: {
                    step_1: ViewContract,
                    step_2: ApprovedDocumentation
                }
            }
        },
        legal: {
            complete: {
                pending: {
                    step_1: InitDocumentation,
                    step_2: <></>,
                    step_3: <></>
                },
                revision: {
                    step_1: ViewDocumentation,
                    step_2: (
                        <LoadingButton
                            disabled={!!shouldPreventPublish}
                            onClick={() => setFiles(originalFiles || [])}
                            type="default"
                            className="h-auto px-6 py-2"
                        >
                            Revisar
                        </LoadingButton>
                    ),
                    step_3: <></>
                },
                approved: {
                    step_1: ViewDocumentation,
                    step_2: ApprovedDocumentation,
                    step_3: (
                        <div className="flex flex-col gap-2">
                            {SendContract}
                            {RegenerateContract}
                        </div>
                    )
                },
                rejected: {
                    step_1: ViewDocumentation,
                    step_2: (
                        <div className="flex items-center gap-2">
                            <div className="w-min bg-pulppo-status-light-error px-2 py-1 text-pulppo-status-dark-error">
                                Rechazado
                            </div>
                            <LoadingButton
                                disabled={!!shouldPreventPublish}
                                onClick={() => setFiles(originalFiles || [])}
                                type="default"
                                className="h-auto px-6 py-2"
                            >
                                Revisar
                            </LoadingButton>
                        </div>
                    ),
                    step_3: ''
                },
                contract_sent: {
                    step_1: ViewDocumentation,
                    step_2: ApprovedDocumentation,
                    step_3: (
                        <div className="flex flex-col gap-2">
                            {userHasSigned ? ViewUnsignedContract : SignContract}
                            {contract.id ? RegenerateContract : SendContract}
                            {DeleteContract}
                            {ViewExternal}
                        </div>
                    )
                },
                completed: {
                    step_1: ViewDocumentation,
                    step_2: ApprovedDocumentation,
                    step_3: (
                        <div className="flex flex-col gap-2">
                            {userHasSigned ? (contract.url ? ViewSignedContract : ViewUnsignedContract) : SignContract}
                            {contract.id ? RegenerateContract : SendContract}
                            {DeleteContract}
                            {ViewExternal}
                        </div>
                    )
                }
            },
            'only-contract': {
                pending: {
                    step_1: InitContract,
                    step_2: <></>
                },
                revision: {
                    step_1: ViewContract,
                    step_2: (
                        <div className="shrink-0 bg-pulppo-status-light-warning px-2 py-1 text-pulppo-status-dark-warning">
                            En revisión
                        </div>
                    )
                },
                approved: {
                    step_1: ViewContract,
                    step_2: ApprovedDocumentation
                },
                rejected: {
                    step_1: ViewContract,
                    step_2: (
                        <div className="flex gap-2">
                            <div className="w-min bg-pulppo-status-light-error px-2 py-1 text-pulppo-status-dark-error">
                                Rechazado
                            </div>
                        </div>
                    )
                },
                contract_sent: {
                    step_1: ViewContract,
                    step_2: ApprovedDocumentation
                },
                completed: {
                    step_1: ViewContract,
                    step_2: ApprovedDocumentation
                }
            }
        }
    };

    const steps: {
        title: string;
        description: React.ReactNode;
        action?: React.ReactNode;
        metadata?: React.ReactElement;
    }[] = [
        {
            title: 'Solicitud de documentación',
            description: 'Solicita el documento de identidad del propietario y título de la propiedad',
            action:
                contract?.flow === 'complete'
                    ? actionMapper?.[userType]?.['complete']?.[contract?.status]?.['step_1']
                    : actionMapper?.[userType]?.['complete']?.['pending']?.['step_1']
        },
        {
            title: 'Revisión de documentación',
            description: 'Estamos trabajando en el armado del acuerdo y revisando toda la información',
            action:
                contract?.flow === 'complete' ? (
                    actionMapper?.[userType]?.['complete']?.[contract?.status]?.['step_2']
                ) : (
                    <></>
                )
        },
        {
            title: t('Acuerdo comercial'),
            description: (
                <div>
                    <div className="flex items-center justify-between">
                        <p>Se firman las condiciones de acuerdo</p>
                    </div>
                </div>
            ),
            action:
                contract.flow === 'complete' ? (
                    actionMapper?.[userType]?.['complete']?.[contract?.status]?.['step_3']
                ) : (
                    <></>
                ),
            metadata:
                contract?.flow === 'complete' && (status === 'contract_sent' || status === 'completed') ? (
                    <div className="mt-4 flex flex-col gap-2">
                        {legalAdmin && (contract.id || contract.pulppoId) && (
                            <LoadingButton
                                type="text"
                                className="text-left"
                                onClick={async () => {
                                    await axios.get(
                                        `${legal_url}/api/contract/url/${contract.pulppoId || contract.id}/update`
                                    );
                                }}
                            >
                                Actualizar <ReloadOutlined />
                            </LoadingButton>
                        )}
                        {contract.signers?.map((signer) => (
                            <div key={`${signer._id}`} className="flex list-disc items-center gap-2">
                                {signer.signedAt ? (
                                    <CheckCircleOutlined />
                                ) : signer.sentAt ? (
                                    <MailOutlined />
                                ) : (
                                    <ClockCircleOutlined />
                                )}
                                {signer.firstName} {signer.lastName} {signer.type && <>({Mapper[signer.type]})</>}
                                <div
                                    className={`${signer.signedAt ? 'bg-pulppo-status-light-success text-pulppo-status-dark-success' : 'bg-pulppo-status-light-warning text-pulppo-status-dark-warning'} rounded px-2`}
                                >
                                    {signer.signedAt ? 'Firmado' : signer.sentAt ? 'Enviado' : 'Pendiente'}
                                </div>
                                {signer.signedAt && (
                                    <p className="text-sm font-thin text-gray-400">
                                        {dayjs(signer.signedAt).fromNow()}
                                    </p>
                                )}
                                {!signer.signedAt && signer.sentAt && (contract.pulppoId || contract.id) && (
                                    <>
                                        <LoadingButton
                                            // type="link"
                                            className="px-1"
                                            onClick={async () => {
                                                await axios.post(
                                                    `${legal_url}/api/contract/url/${contract.pulppoId || contract.id}/send`,
                                                    { email: signer.email }
                                                );
                                                notification.success({ message: 'Reenviado con éxito' });
                                                // navigator.clipboard.writeText(signer.signingUrl);
                                            }}
                                        >
                                            Reenviar
                                        </LoadingButton>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                ) : (
                    <></>
                )
        }
    ];

    const contractSteps: {
        title: string;
        description: string;
        action?: React.ReactNode;
    }[] = [
        {
            title: `Subir ${t('acuerdo comercial').toLowerCase()}`,
            description: 'Adjunta el acuerdo firmado por el propietario',
            action:
                contract.flow === 'only-contract'
                    ? actionMapper[userType]['only-contract'][contract.status]['step_1']
                    : actionMapper[userType]['only-contract']['pending']['step_1']
        },
        {
            title: `Revisión de ${t('acuerdo comercial').toLowerCase()}`,
            description: 'Estamos revisando toda la información',
            action:
                contract.flow === 'only-contract' ? (
                    actionMapper[userType]['only-contract'][contract.status]['step_2']
                ) : (
                    <></>
                )
        }
    ];
    const [files, setFiles] = useState<IProperty['files'] | null>(null);
    const [contractFiles, setContractFiles] = useState<IProperty['files'] | null>(null);
    const [rejecting, setRejecting] = useState(false);
    const [showReject, setShowReject] = useState(false);
    const [appealing, setAppealing] = useState(false);

    const contracts = contractFiles?.filter((f) => f.type === 'contract');

    useTranslate(['property_edit']);

    const [sendContract, setSendContract] = useState<IProperty['contract']['signers'] | null>(null);
    const [contractToSend, setContractToSend] = useState<{
        url: string;
        _id: string;
    }>(null);

    const propertyFiles = files?.filter((file) => file.type === 'title') || [];
    const ownerFiles = files?.filter((file) => file.type === 'identification') || [];

    const socketRef = useRef<Socket>();
    const regenerate = useRef<boolean>(false);
    const appealingRef = useRef<{
        firstName: string;
        lastName: string;
        email: string;
        message: string;
        phone: string;
    } | null>(null);
    const [form] = useForm();
    const [appealForm] = useForm();
    const flowRef = useRef(contract?.flow);
    const owners = contract?.signers?.filter((signer) => signer?.type === 'owner') || [];
    const router = useRouter();
    const isListing = router.pathname.includes('listing');

    useEffect(() => {
        if (!user?.uid) return;
        if (socketRef.current) return;
        socketRef.current = io(`${api_url}`, { transports: ['websocket', 'polling'], auth: { uid: user?.uid } });
        socketRef.current.connect();
        socketRef.current.on('process-update', async (data) => {
            setLoading(data);
        });
        socketRef.current.on('process-error', async (data) => {
            setLoading('');
            setError(`${data.error}: ${data.description}`);
        });
        socketRef.current.on('process-complete', async (data) => {
            setLoading('');
            setFiles(null);
            setContractFiles(null);
            setError('');

            setSendContract({
                firstName: data.owner.firstNames?.join(' ') || '',
                lastName: data.owner.lastNames?.join(' ') || ''
            } as any);
        });
        return () => {
            socketRef.current?.disconnect();
            socketRef.current = null;
        };
    }, [user?.uid]);

    return (
        <div>
            <div className="bg-white pt-4">
                {shouldPreventPublish && isListing && (
                    <div className="mb-2 flex justify-center">
                        <div className="mx-auto flex w-full max-w-3xl flex-col items-center justify-between gap-4 bg-pulppo-status-light-error px-4 py-3 lg:flex-row">
                            <div className="flex items-center gap-2">
                                <WarningOutlined className="text-3xl text-pulppo-status-dark-error" />
                                <div className="flex flex-col items-center gap-2">
                                    <h2 className="text-md w-full text-left font-bold text-pulppo-status-dark-error">
                                        Faltan datos para publicar la propiedad
                                    </h2>
                                    <p className="text-pulppo-status-dark-error">
                                        La propiedad sigue en estado Borrador. Para publicarla, asegúrate de completar
                                        todos los
                                        <br />
                                        campos obligatorios.
                                    </p>
                                </div>
                            </div>
                            <Button
                                type="primary"
                                className="!w-50 h-10 w-full font-medium lg:w-auto"
                                onClick={() => {
                                    openPropertyStatusDrawer({ property: formData });
                                }}
                                danger
                            >
                                Ver Más
                            </Button>
                        </div>
                    </div>
                )}
                <>
                    {!legalAdmin && (
                        <>
                            {contract?.processing === 'manual' &&
                                process.env.NEXT_PUBLIC_DISABLE_LEGAL_AI !== 'true' &&
                                contract?.status !== 'completed' && (
                                    <div className="mb-2 flex justify-center">
                                        <div className="mx-auto flex w-full max-w-3xl items-center gap-4 bg-pulppo-status-light-error px-4 py-3">
                                            <ExclamationCircleFilled className="text-xl text-pulppo-status-dark-error" />
                                            <p className="text-pulppo-status-dark-error">
                                                Esta propiedad está en proceso de apelación y su revisión se realiza de
                                                manera manual. Queda al pendiente de las notificaciones de las novedades
                                            </p>
                                        </div>
                                    </div>
                                )}
                        </>
                    )}
                    {!user?.features?.capture10 &&
                        contract?.status !== 'completed' &&
                        property?.status?.last !== 'published' &&
                        process.env.NEXT_PUBLIC_DOCUMENTATION_MANDATORY && (
                            <div className="mb-2 flex justify-center">
                                <div className="mx-auto flex w-full max-w-3xl items-center gap-4 bg-pulppo-status-light-warning px-4 py-3">
                                    <ExclamationCircleFilled className="text-xl text-pulppo-status-dark-warning" />
                                    <p className="text-pulppo-status-dark-warning">
                                        La propiedad no será publicada hasta no haber completado este proceso. Para más
                                        información has clic{' '}
                                        <a
                                            className="underline hover:text-pulppo-primary-black"
                                            href={
                                                'https://ayuda.pulppo.com/es/articles/9670829-nuevo-flujo-en-la-carga-de-documentos'
                                            }
                                            target="_blank"
                                        >
                                            aquí
                                        </a>
                                    </p>
                                </div>
                            </div>
                        )}
                    {status === 'completed' && (
                        <div className="mb-2 flex justify-center">
                            <div className="mx-auto flex w-full max-w-3xl items-center gap-4 bg-pulppo-status-light-success px-4 py-3">
                                <CheckCircleFilled className="text-xl text-pulppo-status-dark-success" />
                                <p className="text-pulppo-status-dark-success">
                                    La propiedad ha sido publicada y tiene la documentación completa. Si quieres puedes
                                    regenerar un nuevo acuerdo abajo.
                                </p>
                            </div>
                        </div>
                    )}
                    <div className="mx-auto max-w-3xl">
                        {hiddenExtra ? null : <h1 className="mt-4 text-lg">Documentación y acuerdo</h1>}
                        <Tabs 
                            defaultActiveKey={contract?.flow || 'complete'}
                            className="[&_.ant-tabs-tab.ant-tabs-tab-active_.ant-tabs-tab-btn]:text-gray-900"
                        >
                            <TabPane
                                tab="Generar acuerdo"
                                key="complete"
                                disabled={Boolean(user?.features?.capture10 && shouldPreventPublish)}
                            >
                                <div className="space-y-4">
                                    {steps.map((step) => (
                                        <LegalStep key={step.title} {...step} />
                                    ))}
                                </div>
                            </TabPane>
                            {(process.env.NEXT_PUBLIC_PREVENT_ONLY_CONTRACT !== 'true' ||
                                legalAdmin ||
                                contract.flow === 'complete') && (
                                <TabPane
                                    tab="Subir acuerdo existente"
                                    key="only-contract"
                                    disabled={Boolean(user?.features?.capture10 && shouldPreventPublish)}
                                >
                                    <div className="space-y-4">
                                        {contractSteps.map((step) => (
                                            <LegalStep key={step.title} {...step} />
                                        ))}
                                    </div>
                                </TabPane>
                            )}
                        </Tabs>
                        <ContractNotes
                            property={property}
                            disabled={Boolean(user?.features?.capture10 && shouldPreventPublish)}
                        />
                    </div>
                </>
            </div>

            <PulppoDrawer
                visible={showNotes}
                onClose={() => setShowNotes(false)}
                title="Notas"
                bodyStyle={{ height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}
            >
                <div className="mb-4 flex items-center gap-4 bg-pulppo-status-light-info px-4 py-3">
                    <ExclamationCircleFilled className="text-xl text-pulppo-status-dark-info" />
                    <p className="text-pulppo-status-dark-info">
                        Tu solicitud de apelación fue enviada a nuestro equipo de legales
                    </p>
                </div>
                <ContractNotes className="" showTitle={false} property={property} />
            </PulppoDrawer>

            <PulppoDrawer
                visible={!!files}
                onClose={() => setFiles(null)}
                title="Solicitud de documentación"
                bodyStyle={{ height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}
            >
                {loading ? (
                    <>
                        <div className="absolute left-0 top-0 z-10 h-full w-full bg-white opacity-80" />
                        <div className="absolute left-0 top-0 z-20 flex h-full w-full flex-col items-center justify-center">
                            <div className="relative flex items-center justify-center pt-20">
                                <img
                                    src={process.env.NEXT_PUBLIC_LOGO_NEGRO_SOLO || '/pulppo.png'}
                                    alt="pulppo-loader"
                                    style={{
                                        objectFit: 'cover',
                                        width: '60px',
                                        height: '60px'
                                    }}
                                />
                                <div className="loader absolute" />
                            </div>
                            <p className="mx-auto mt-6 max-w-[200px] text-center">{loading}</p>
                        </div>
                    </>
                ) : null}

                <div className="flex-1 overflow-y-scroll">
                    {error ? (
                        <div className="mb-4 flex items-center gap-4 bg-pulppo-status-light-error px-4 py-3">
                            <CloseCircleFilled className="text-xl text-pulppo-status-dark-error" />
                            <div className="text-pulppo-status-dark-error">
                                {getErrorMessage(error)}
                                {contract?.processing !== 'manual' && (
                                    <LoadingButton
                                        type="link"
                                        onClick={async () => {
                                            setAppeal({ message: '', title: 'Apelación' });
                                        }}
                                        className="h-auto border-b border-l-0 border-r-0 border-t-0 border-solid border-pulppo-status-dark-error px-0 py-0 pb-0 text-pulppo-status-dark-error underline hover:border-black hover:text-pulppo-primary-black"
                                    >
                                        Apelar
                                    </LoadingButton>
                                )}
                            </div>
                        </div>
                    ) : null}
                    <p className="text-pulppo-primary-gray">
                        Con el objetivo de avanzar en el proceso de publicación de la propiedad, los documentos serán
                        enviados al departamento legal para su revisión
                    </p>
                    <FileUpload
                        accept=".pdf,image/*"
                        isPrivate
                        multiple
                        showList={false}
                        className="border-solid bg-white"
                        disableShowListPictures
                        setNameFile={(file) => {
                            return `/property/${property?._id}/files/${dayjs().tz().format('YYYY_MM_DD')}/${file.name.replace(/\s/gi, '_')}`;
                        }}
                        onUploaded={(newFileName) => {
                            setFiles((f) => [
                                ...(f || []),
                                {
                                    type: 'identification',
                                    data: newFileName,
                                    name: newFileName,
                                    creator: user,
                                    _id: ObjectId()
                                }
                            ]);
                        }}
                    >
                        <div className="w-full px-4 py-0 text-left">
                            <div className="mb-5 flex items-center gap-4">
                                <ContactsIcon active={false} />

                                <h3 className="text-lg font-medium">Propietario</h3>
                            </div>
                            {ownerFiles?.length ? (
                                <div className="grid grid-cols-4 gap-2">
                                    {ownerFiles.map((file) => (
                                        <DisplayFile
                                            key={file.data}
                                            file={file}
                                            onDelete={() => {
                                                setFiles((f) => f.filter((f) => f.data !== file.data));
                                            }}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <T
                                    ns="common"
                                    keyName="ownerFiles"
                                    params={{
                                        ul: <ul className="ml-4 flex list-disc flex-col gap-1" />,
                                        p: <p />,
                                        li: <li />,
                                        ol: <ol className="list-decimal" />
                                    }}
                                    defaultValue={`<ul>
                                    <li>En caso de persona física:</li>
                                    <p>INE/ Pasaporte y/o Licencia de conducir</p>
                                    <li>En caso de ser Persona Moral: </li>
                                    <ol>
                                        <li>Acta Constitutiva</li>
                                        <li> Poder de representante legal</li>
                                        <li> INE / Pasaporte del representante legal</li>
                                    </ol>
                                </ul>`}
                                />
                            )}
                            <p className="btn btn-secondary mt-8 text-center text-sm">Cargar o arrastrar archivos</p>
                        </div>
                    </FileUpload>
                    <FileUpload
                        accept=".pdf,image/*"
                        multiple
                        isPrivate
                        showList={false}
                        className="border-solid bg-white"
                        disableShowListPictures
                        setNameFile={(file) => {
                            return `/property/${property?._id}/files/${dayjs().tz().format('YYYY_MM_DD')}/${file.name.replace(/\s/gi, '_')}`;
                        }}
                        onUploaded={(newFileName) => {
                            setFiles((f) => [
                                ...(f || []),
                                { type: 'title', data: newFileName, name: newFileName, _id: ObjectId(), creator: user }
                            ]);
                        }}
                    >
                        <div className="w-full px-4 py-0 text-left">
                            <div className="mb-5 flex items-center gap-4">
                                <PropertiesIcon active={false} />

                                <h3 className="text-lg font-medium">Propiedad</h3>
                            </div>
                            {propertyFiles?.length ? (
                                <div className="grid grid-cols-4 gap-2">
                                    {propertyFiles.map((file) => (
                                        <DisplayFile
                                            key={file.data}
                                            file={file}
                                            onDelete={() => {
                                                setFiles((f) => f.filter((f) => f.data !== file.data));
                                            }}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <T
                                    ns="common"
                                    keyName="propertyFiles"
                                    params={{
                                        ul: <ul className="ml-4 flex list-disc flex-col gap-1" />,
                                        li: <li />
                                    }}
                                    defaultValue={`<ul>
                                    <li>Escritura</li>
                                    <li>Boleta de agua</li>
                                    <li>Predial de la propiedad</li>
                                    <li>Comprobante de domicilio (Telmex, TotalPlay, CFE, Gas, etc)</li>
                                </ul>`}
                                />
                            )}
                            <p className="btn btn-secondary mt-8 text-center text-sm">Cargar o arrastrar archivos</p>
                        </div>
                    </FileUpload>
                </div>
                {(status === 'pending' || status === 'rejected' || status === 'revision') && legalAdmin && (
                    <>
                        {process.env.NEXT_PUBLIC_DISABLE_LEGAL_AI !== 'true' && (
                            <div className="pt-2">
                                <LoadingButton
                                    disabled={!ownerFiles?.length || !propertyFiles?.length}
                                    onClick={async () => {
                                        setLoading('Enviando...');
                                        setError('');
                                        flowRef.current = 'complete';
                                        socketRef.current.emit('analyze-documents', {
                                            files,
                                            propertyId: property?._id
                                        });
                                    }}
                                    type="default"
                                    className="h-auto w-full flex-1 py-2"
                                >
                                    Procesar con AI
                                </LoadingButton>
                            </div>
                        )}
                        <div className="flex gap-2 pt-2">
                            <LoadingButton
                                danger
                                onClick={async () => {
                                    setShowReject(true);
                                }}
                                type="default"
                                className="h-auto flex-1 py-2"
                            >
                                Rechazar
                            </LoadingButton>
                            <LoadingButton
                                onClick={async () => {
                                    try {
                                        await axios.patch(`${api_url}/legals/${property?._id}/approve`);
                                        notification.success({
                                            message: 'Se aprobaron los documentos'
                                        });
                                    } catch (err) {
                                        notification.error({
                                            message: `Hubo un error`,
                                            description: `${
                                                err?.response?.data?.message || err?.response?.data?.error || err
                                            }`
                                        });
                                    }
                                }}
                                type="primary"
                                className="h-auto flex-1 py-2"
                            >
                                Aprobar
                            </LoadingButton>
                        </div>
                    </>
                )}
                {(status === 'pending' || status === 'rejected' || status === 'revision') && !legalAdmin ? (
                    <div className="flex gap-2 pt-2">
                        <LoadingButton
                            onClick={async () => {
                                const contact = property.contact;
                                if (!contact?.phone) {
                                    throw new Error('El contacto no tiene cargado un whatsapp');
                                }
                                showShareDrawer({
                                    contact: contact,
                                    whatsapp: {
                                        content: `Hola ${contact.firstName || ''},
Para avanzar con tu operación inmobiliaria, necesitamos que nos proveas los documentos de identificación del propietario y el título de la propiedad.
Puedes cargarlos pinchando en la liga al final de este mensaje👇.
Si tienes alguna duda, no dudes en contactarme.
Recuerda que almacenamos tu información de forma segura y que necesitamos estos datos para poder avanzar con la operación.

https://broker.pulppo.com/radar/${contact?._id}/legal?listing=${property._id}
`
                                    },
                                    mail: {
                                        subject: 'Solicitud de documentación',
                                        content: `Hola ${contact.firstName || ''},
                                        Para avanzar con tu operación inmobiliaria, necesitamos que nos proveas los documentos de identificación del propietario y el título de la propiedad.
                                        Puedes cargarlos pinchando en la liga al final de este mensaje👇.
                                        Si tienes alguna duda, no dudes en contactarme.
                                        Recuerda que almacenamos tu información de forma segura y que necesitamos estos datos para poder avanzar con la operación.
                                        
                                        https://broker.pulppo.com/radar/${contact?._id}/legal?listing=${property._id}`,
                                        url: `${api_url}/gmail/send/${user?.uid}`
                                    },
                                    onSend: () => {}
                                });
                            }}
                            type="default"
                            className="h-auto flex-1 py-2"
                        >
                            Solicitar al propietario
                        </LoadingButton>
                        <LoadingButton
                            disabled={!ownerFiles?.length || !propertyFiles?.length}
                            onClick={async () => {
                                if (
                                    contract?.processing === 'manual' ||
                                    process.env.NEXT_PUBLIC_DISABLE_LEGAL_AI === 'true'
                                ) {
                                    try {
                                        await axios.post(`${api_url}/legals/${property._id}/documents`, {
                                            files,
                                            flow: 'complete',
                                            manual: true
                                        });
                                        if (process.env.NEXT_PUBLIC_DISABLE_LEGAL_AI === 'true') {
                                            const values = {
                                                signers: [
                                                    {
                                                        ...MapUserToSimpleUser(property.contact),
                                                        type: 'owner',
                                                        role: 'signer'
                                                    }
                                                ]
                                            };
                                            await axios.post(`${api_url}/legals/${property._id}/signer`, values);
                                            await axios.post(`${api_url}/legals/${property?._id}/appeal`, values);
                                            setAppeal({ message: '', title: 'Confirmar datos' });
                                        }
                                    } catch (err) {
                                        setError(err?.response?.data?.message || err?.response?.data?.error || err);
                                    }
                                    return;
                                }
                                setLoading('Enviando...');
                                setError('');
                                flowRef.current = 'complete';
                                socketRef.current.emit('analyze-documents', {
                                    files,
                                    propertyId: property?._id
                                });
                            }}
                            type="primary"
                            className="h-auto flex-1 py-2"
                        >
                            Subir documentación
                        </LoadingButton>
                    </div>
                ) : null}
            </PulppoDrawer>
            <PulppoDrawer
                visible={!!contractFiles}
                onClose={() => setContractFiles(null)}
                title="Solicitud de documentación"
                bodyStyle={{ height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}
            >
                {loading ? (
                    <>
                        <div className="absolute left-0 top-0 z-10 h-full w-full bg-white opacity-80"></div>
                        <div className="absolute left-0 top-0 z-20 flex h-full w-full flex-col items-center justify-center">
                            <div className="relative flex items-center justify-center pt-20">
                                <img
                                    src={process.env.NEXT_PUBLIC_LOGO_NEGRO_SOLO || '/pulppo.png'}
                                    alt="pulppo-loader"
                                    style={{
                                        objectFit: 'cover',
                                        width: '60px',
                                        height: '60px'
                                    }}
                                />
                                <div className="loader absolute" />
                            </div>
                            <p className="mx-auto mt-6 max-w-[200px] text-center">{loading}</p>
                        </div>
                    </>
                ) : null}

                <div className="flex-1 overflow-y-scroll">
                    {error ? (
                        <div className="mb-4 flex items-center gap-4 bg-pulppo-status-light-error px-4 py-3">
                            <CloseCircleFilled className="text-xl text-pulppo-status-dark-error" />
                            <div className="text-pulppo-status-dark-error">
                                {getErrorMessage(error)}
                                {contract?.processing !== 'manual' && (
                                    <LoadingButton
                                        type="link"
                                        onClick={async () => {
                                            setAppeal({ message: '', title: 'Apelación' });
                                        }}
                                        className="h-auto border-b border-l-0 border-r-0 border-t-0 border-solid border-pulppo-status-dark-error px-0 py-0 pb-0 text-pulppo-status-dark-error underline hover:border-black hover:text-pulppo-primary-black"
                                    >
                                        Apelar
                                    </LoadingButton>
                                )}
                            </div>
                        </div>
                    ) : null}
                    <p className="text-pulppo-primary-gray">
                        Con el objetivo de avanzar en el proceso de publicación de la propiedad, los documentos serán
                        enviados al departamento legal para su revisión
                    </p>
                    <FileUpload
                        accept=".pdf"
                        multiple
                        isPrivate
                        showList={false}
                        className="border-solid bg-white"
                        disableShowListPictures
                        setNameFile={(file) => {
                            return `/property/${property?._id}/files/${dayjs().tz().format('YYYY_MM_DD')}/${file.name.replace(/\s/gi, '_')}`;
                        }}
                        onUploaded={(newFileName) => {
                            setContractFiles((f) => [
                                ...(f || []),
                                {
                                    type: 'contract',
                                    data: newFileName,
                                    name: newFileName,
                                    creator: user,
                                    _id: ObjectId()
                                }
                            ]);
                        }}
                    >
                        <div className="w-full px-4 py-0 text-left">
                            <div className="mb-5 flex items-center gap-4">
                                <ContractIcon />
                                <h3 className="text-lg font-medium">Acuerdo comercial</h3>
                            </div>
                            {contracts?.length ? (
                                <div className="grid grid-cols-4 gap-2">
                                    {contracts.map((file) => (
                                        <DisplayFile
                                            key={file.data}
                                            file={file}
                                            onDelete={() => {
                                                setContractFiles((f) => f.filter((f) => f.data !== file.data));
                                            }}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <ul className="ml-4 flex list-disc flex-col gap-1">
                                    <li>Acuerdo firmado por el propietario</li>
                                </ul>
                            )}
                            <p className="btn btn-secondary mt-8 text-center text-sm">Cargar o arrastrar archivos</p>
                        </div>
                    </FileUpload>
                </div>
                {status !== 'completed' && !legalAdmin && (
                    <div className="flex gap-2 pt-2">
                        <LoadingButton
                            disabled={!contracts?.length}
                            onClick={async () => {
                                if (
                                    contract?.processing === 'manual' ||
                                    process.env.NEXT_PUBLIC_DISABLE_LEGAL_AI === 'true'
                                ) {
                                    await axios.post(`${api_url}/legals/${property._id}/documents`, {
                                        flow: 'only-contract',
                                        files: contractFiles.map((f) => ({
                                            ...f,
                                            creator: MapUserToSimpleUser(f.creator),
                                            _id: undefined
                                        }))
                                    });
                                    if (process.env.NEXT_PUBLIC_DISABLE_LEGAL_AI === 'true') {
                                        setAppeal({ message: '', title: 'Confirmar datos' });
                                    }
                                    setContractFiles(null);
                                    return;
                                }
                                setLoading('Enviando...');
                                setError('');
                                flowRef.current = 'only-contract';

                                socketRef.current.emit('analyze-contract', {
                                    files: contractFiles,
                                    propertyId: property?._id
                                });
                            }}
                            type="primary"
                            className="h-auto flex-1 py-2"
                        >
                            Subir acuerdo
                        </LoadingButton>
                    </div>
                )}
                {status !== 'completed' && legalAdmin && (
                    <>
                        {process.env.NEXT_PUBLIC_DISABLE_LEGAL_AI !== 'true' && (
                            <div className="pt-2">
                                <LoadingButton
                                    disabled={!contractFiles?.length}
                                    onClick={async () => {
                                        setLoading('Enviando...');
                                        setError('');
                                        flowRef.current = 'only-contract';
                                        socketRef.current?.emit('analyze-contract', {
                                            files: contractFiles,
                                            propertyId: property?._id
                                        });
                                    }}
                                    type="default"
                                    className="h-auto w-full flex-1 py-2"
                                >
                                    Procesar con AI
                                </LoadingButton>
                            </div>
                        )}
                        <div className="flex gap-2 pt-2">
                            <LoadingButton
                                danger
                                onClick={async () => {
                                    setShowReject(true);
                                }}
                                type="default"
                                className="h-auto flex-1 py-2"
                            >
                                Rechazar
                            </LoadingButton>
                            <LoadingButton
                                disabled={!contractFiles?.length}
                                onClick={async () => {
                                    await axios.post(`${api_url}/legals/${property._id}/documents`, {
                                        files: contractFiles,
                                        flow: 'only-contract'
                                    });
                                    await axios.patch(`${api_url}/legals/${property?._id}/complete`, {
                                        url: contracts?.[0]?.data
                                    });
                                    notification.success({
                                        message: 'Se aprobaron los documentos'
                                    });
                                }}
                                type="primary"
                                className="h-auto flex-1 py-2"
                            >
                                Aprobar
                            </LoadingButton>
                        </div>
                    </>
                )}
            </PulppoDrawer>
            <PulppoDrawer
                visible={!!sendContract}
                onClose={() => setSendContract(null)}
                title="Datos del firmante"
                bodyStyle={{ height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}
            >
                {error ? (
                    <div className="mb-4 flex items-center gap-4 bg-pulppo-status-light-error px-4 py-3">
                        <CloseCircleFilled className="text-xl text-pulppo-status-dark-error" />
                        <div className="text-pulppo-status-dark-error">
                            {getErrorMessage(error)}
                            {contract?.processing !== 'manual' && (
                                <LoadingButton
                                    type="link"
                                    onClick={async () => {
                                        setAppeal({ message: '', title: 'Apelación' });
                                    }}
                                    className="h-auto border-b border-l-0 border-r-0 border-t-0 border-solid border-pulppo-status-dark-error px-0 py-0 pb-0 text-pulppo-status-dark-error underline hover:border-black hover:text-pulppo-primary-black"
                                >
                                    Apelar
                                </LoadingButton>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="mb-4 flex items-center gap-4 bg-pulppo-status-light-warning px-4 py-3">
                        <CloseCircleFilled className="text-xl text-pulppo-status-dark-warning" />
                        <p className="text-pulppo-status-dark-warning">
                            ¡Atención! <br /> Detectamos estos propietarios en la escritura. Por favor completa los
                            campos necesarios para avanzar con el acuerdo de comisión.
                            {contract?.processing !== 'manual' && (
                                <LoadingButton
                                    type="link"
                                    onClick={async () => {
                                        setAppeal({ message: '', title: 'Apelación' });
                                    }}
                                    className="h-auto border-b border-l-0 border-r-0 border-t-0 border-solid border-pulppo-status-dark-warning px-0 py-0 pb-0 text-pulppo-status-dark-warning underline hover:border-black hover:text-pulppo-primary-black"
                                >
                                    Apelar
                                </LoadingButton>
                            )}
                        </p>
                    </div>
                )}
                {loading ? (
                    <>
                        <div className="absolute left-0 top-0 z-10 h-full w-full bg-white opacity-90"></div>
                        <div className="absolute left-0 top-0 z-20 flex h-full w-full flex-col items-center justify-center">
                            <div className="relative flex items-center justify-center pt-20">
                                <img
                                    src={process.env.NEXT_PUBLIC_LOGO_NEGRO_SOLO || '/pulppo.png'}
                                    alt="pulppo-loader"
                                    style={{
                                        objectFit: 'cover',
                                        width: '60px',
                                        height: '60px'
                                    }}
                                />
                                <div className="loader absolute" />
                            </div>
                            <p className="mx-auto mt-6 max-w-[200px] text-center">{loading}</p>
                        </div>
                    </>
                ) : null}
                <Form
                    form={form}
                    initialValues={{
                        signers: sendContract?.length
                            ? sendContract
                            : owners.length
                              ? owners
                              : [{ ...MapUserToSimpleUser(property.contact), type: 'owner', role: 'signer' }]
                    }}
                    onFinish={async (values) => {
                        try {
                            setError('');
                            setLoading('Generando acuerdo. Esta operación puede demorar hasta un minuto.');
                            await axios.post(`${api_url}/legals/${property._id}/signer`, values);

                            // TODO: voidear el contrato
                            const contract = await axios.post(
                                `${legal_url}/api/contract/company/${property.company?._id}`,
                                {
                                    owner: values.signers[0],
                                    signers: values.signers?.map((signer) => ({
                                        ...signer,
                                        email: signer.email.toLowerCase()
                                    })),
                                    property: {
                                        ...property,
                                        contract: {
                                            ...property.contract,
                                            comission: property.contract.comission
                                        }
                                    },
                                    broker: property.agent,
                                    userId: user?.uid
                                }
                            );
                            setContractToSend({ url: contract?.data?.url, _id: contract?.data?._id });

                            setLoading('');
                        } catch (err) {
                            setError(`${err?.response?.data?.error || err?.response?.data?.message}`);
                        } finally {
                            setLoading('');
                        }
                    }}
                    name="signer"
                    layout="vertical"
                    className="flex flex-1 flex-col justify-between"
                >
                    <div>
                        <Form.List name="signers">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <div key={field.key}>
                                            <Form.Item
                                                className="flex-1"
                                                name={[field.name, 'firstName']}
                                                label="Nombre"
                                            >
                                                <Input className="h-auto rounded-none py-2" />
                                            </Form.Item>
                                            <Form.Item
                                                className="flex-1"
                                                name={[field.name, 'lastName']}
                                                label="Apellido"
                                            >
                                                <Input className="h-auto rounded-none py-2" />
                                            </Form.Item>
                                            <div className="flex items-end gap-2">
                                                <Form.Item
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: `Tienes que ingresar un teléfono`
                                                        }
                                                    ]}
                                                    className="flex-1"
                                                    name={[field.name, 'phone']}
                                                    label="Teléfono "
                                                >
                                                    <PhoneInputPlain className="h-auto rounded-none py-2" />
                                                </Form.Item>
                                                <Button
                                                    type="text"
                                                    className="mb-2"
                                                    onClick={() => {
                                                        form.setFieldValue(
                                                            ['signers', field.name, 'phone'],
                                                            property.contact.phone
                                                        );
                                                    }}
                                                >
                                                    <CopyOutlined />
                                                </Button>
                                            </div>

                                            <div className="flex items-end gap-2">
                                                <Form.Item
                                                    className="flex-1"
                                                    label="Email "
                                                    name={[field.name, 'email']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: `Tienes que ingresar un email`
                                                        }
                                                    ]}
                                                >
                                                    <Input
                                                        className="h-auto rounded-none py-2"
                                                        type="email"
                                                        placeholder="jonsnow@gmail.com"
                                                    />
                                                </Form.Item>
                                                <Button
                                                    type="text"
                                                    className="mb-2"
                                                    onClick={() => {
                                                        form.setFieldValue(
                                                            ['signers', field.name, 'email'],
                                                            property.contact.email
                                                        );
                                                    }}
                                                >
                                                    <CopyOutlined />
                                                </Button>
                                            </div>
                                            {index !== 0 && (
                                                <Button
                                                    className=""
                                                    type="text"
                                                    danger
                                                    onClick={() => remove(field.name)}
                                                >
                                                    Quitar copropietario
                                                </Button>
                                            )}
                                            <div className="my-4 h-px w-full bg-gray-200" />
                                        </div>
                                    ))}
                                    <Button
                                        className="h-auto w-full py-2"
                                        onClick={() => add({ type: 'owner', role: 'signer' })}
                                    >
                                        Agregar otro propietario
                                    </Button>
                                </>
                            )}
                        </Form.List>
                    </div>
                </Form>

                <div className="flex flex-col gap-2 pt-2">
                    <div onClick={() => setAccepted((a) => !a)} className="flex cursor-pointer items-start gap-2">
                        <Checkbox checked={accepted} className="h-5 w-5" />
                        <p className="text-sm font-light">
                            Confirmo que los datos proporcionados son correctos y verdaderos, y que corresponden al
                            propietario informado. También reconozco que el acuerdo será enviado a la dirección de email
                            y al número de teléfono proporcionados para su firma.
                        </p>
                    </div>
                    <LoadingButton
                        disabled={!accepted}
                        onClick={async () => {
                            regenerate.current = false;
                            form.submit();
                        }}
                        type="primary"
                        className="h-auto flex-1 py-2"
                    >
                        Confirmar y avanzar
                    </LoadingButton>
                </div>
            </PulppoDrawer>
            <PulppoDrawer
                visible={!!contractToSend?.url}
                onClose={() => setContractToSend(null)}
                title="Acuerdo"
                width="21cm"
                bodyStyle={{ padding: 0, display: 'flex', flexDirection: 'column', height: '100%' }}
            >
                {legalAdmin ? (
                    <iframe className="w-full flex-1" src={`${legal_url}/${contractToSend?._id}`} />
                ) : (
                    <iframe className="w-full flex-1" src={contractToSend?.url} />
                )}
                <div className="flex w-full gap-2 p-2">
                    <LoadingButton
                        className="h-auto w-full py-2"
                        onClick={async () => {
                            await axios.post(`${legal_url}/api/contract/send`, {
                                contractId: contractToSend._id,
                                userId: user?.uid || user?._id
                            });
                            setContractToSend(null);
                            setSendContract(null);
                            notification.success({
                                message: 'Acuerdo enviado'
                            });
                        }}
                        type="primary"
                    >
                        Enviar acuerdo
                    </LoadingButton>
                </div>
            </PulppoDrawer>
            <PulppoDrawer
                visible={!!appeal}
                onClose={() => setAppeal(null)}
                title={appeal?.title || 'Apelación'}
                bodyStyle={{ display: 'flex', flexDirection: 'column', height: '100%' }}
            >
                {error ? (
                    <div className="mb-4 flex items-center gap-4 bg-pulppo-status-light-error px-4 py-3">
                        <CloseCircleFilled className="text-xl text-pulppo-status-dark-error" />
                        <div className="text-pulppo-status-dark-error">{getErrorMessage(error)}</div>
                    </div>
                ) : null}
                <Form
                    form={appealForm}
                    initialValues={{
                        signers: owners?.length
                            ? owners
                            : [{ ...MapUserToSimpleUser(property.contact), type: 'owner', role: 'signer' }]
                    }}
                    onFinish={async (values) => {
                        try {
                            setAppealing(true);
                            values.type = 'owner';
                            await axios.post(`${api_url}/legals/${property._id}/signer`, values);
                            appealingRef.current = values;
                            setLoading('');
                            notification.success({ message: `Se ha apelado tu solicitud` });
                            await axios.post(`${api_url}/legals/${property?._id}/appeal`, appealingRef.current);
                            setAppeal(null);
                            setSendContract(null);
                            setFiles(null);
                            setContractFiles(null);
                        } catch (err) {
                            setError(err?.response?.data?.message || err?.response?.data?.error || err);
                        } finally {
                            setAppealing(false);
                        }
                    }}
                    name="appeal"
                    layout="vertical"
                    className="flex flex-1 flex-col justify-between"
                >
                    <div>
                        <Form.List name="signers">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <div key={field.key}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: `Tienes que ingresar un nombre`
                                                    }
                                                ]}
                                                className="flex-1"
                                                name={[field.name, 'firstName']}
                                                label="Nombre"
                                            >
                                                <Input className="h-auto rounded-none py-2" />
                                            </Form.Item>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: `Tienes que ingresar un apellido`
                                                    }
                                                ]}
                                                className="flex-1"
                                                name={[field.name, 'lastName']}
                                                label="Apellido"
                                            >
                                                <Input className="h-auto rounded-none py-2" />
                                            </Form.Item>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: `Tienes que ingresar un teléfono`
                                                    }
                                                ]}
                                                className="flex-1"
                                                name={[field.name, 'phone']}
                                                label="Teléfono "
                                            >
                                                <PhoneInputPlain className="h-auto rounded-none py-2" />
                                            </Form.Item>

                                            <Form.Item
                                                className="flex-1"
                                                name={[field.name, 'email']}
                                                label="Email "
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: `Tienes que ingresar un email`
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    className="h-auto rounded-none py-2"
                                                    type="email"
                                                    placeholder="jonsnow@gmail.com"
                                                />
                                            </Form.Item>
                                            {index !== 0 && (
                                                <Button
                                                    className=""
                                                    type="text"
                                                    danger
                                                    onClick={() => remove(field.name)}
                                                >
                                                    Quitar copropietario
                                                </Button>
                                            )}
                                            <div className="my-4 h-px w-full bg-gray-200" />
                                        </div>
                                    ))}
                                    <Button
                                        className="mb-4 h-auto w-full py-2"
                                        onClick={() => add({ type: 'owner', role: 'signer' })}
                                    >
                                        Agregar copropietario
                                    </Button>
                                </>
                            )}
                        </Form.List>
                        {process.env.NEXT_PUBLIC_DISABLE_LEGAL_AI === 'true' ? (
                            <p className="mb-2 mt-4">Dejar un mensaje para nuestro equipo legal</p>
                        ) : (
                            <div className="mb-4 flex justify-center">
                                <div className="flex items-center gap-4 bg-pulppo-status-light-warning px-4 py-3">
                                    <ExclamationCircleFilled className="text-xl text-pulppo-status-dark-warning" />
                                    <p className="text-pulppo-status-dark-warning">
                                        Tienes que dejarnos un mensaje con el motivo por el cual quieres apelar para
                                        poder hacerlo
                                    </p>
                                </div>
                            </div>
                        )}
                        <Form.Item
                            className="flex-1"
                            name={'message'}
                            rules={
                                process.env.NEXT_PUBLIC_DISABLE_LEGAL_AI === 'true'
                                    ? []
                                    : [
                                          {
                                              required: true,
                                              message: `Tienes que ingresar un mensaje de apelación`
                                          }
                                      ]
                            }
                        >
                            <TextArea placeholder="Escribe un mensaje de apelación..." rows={5} />
                        </Form.Item>
                    </div>
                </Form>
                <div className="flex-1" />
                <div className="flex w-full gap-2 p-2">
                    <Button
                        loading={appealing}
                        disabled={appealing}
                        className="h-auto w-full py-2"
                        onClick={() => {
                            appealForm.submit();
                        }}
                        type="primary"
                    >
                        {process.env.NEXT_PUBLIC_DISABLE_LEGAL_AI === 'true' ? 'Confirmar información' : 'Apelar'}
                    </Button>
                </div>
            </PulppoDrawer>
            <Modal footer={null} open={showReject} onCancel={() => setShowReject(false)}>
                <div>
                    <Form
                        layout="vertical"
                        name="reason"
                        onFinish={async (values) => {
                            try {
                                setRejecting(true);
                                await axios.patch(`${api_url}/legals/${property?._id}/reject`, {
                                    message: values.message
                                });
                                notification.success({
                                    message: 'Se rechazaron los documentos'
                                });
                            } catch (err) {
                                notification.error({
                                    message: `Hubo un error`,
                                    description: `${err?.response?.data?.message || err?.response?.data?.error || err}`
                                });
                            } finally {
                                setShowReject(false);
                                setRejecting(false);
                            }
                        }}
                    >
                        <Form.Item label="Motivo de rechazo" name="message">
                            <Input.TextArea
                                rows={5}
                                className="rounded-md"
                                placeholder="Inserte motivo de rechazo..."
                            />
                        </Form.Item>
                        <div className="flex justify-end gap-4">
                            <Button onClick={() => setShowReject(false)} className="rounded-md">
                                Cancelar
                            </Button>
                            <Button
                                loading={rejecting}
                                disabled={rejecting}
                                className="rounded-md"
                                htmlType="submit"
                                form="reason"
                                type="primary"
                            >
                                Rechazar
                            </Button>
                        </div>
                    </Form>
                </div>
            </Modal>
            {legalAdmin ? (
                <pre className="mt-48 rounded-sm bg-gray-100 p-2 text-xs font-light">
                    {JSON.stringify(contract, null, 2)}
                </pre>
            ) : null}
        </div>
    );
};

const ImageOrPDF = ({ url }: { url: string }) => {
    const [type, setType] = useState('image');
    if (type == 'image') {
        return (
            <img
                src={url}
                className="mb-2 flex aspect-[1/1.414] w-[100px] items-center justify-center bg-gray-100 object-cover"
                onError={() => setType('pdf')}
                alt="file"
            />
        );
    }
    return (
        <div className="mb-2 flex aspect-[1/1.414] w-[100px] items-center justify-center bg-gray-100">
            <FilePdfOutlined className="text-4xl text-red-700" />
        </div>
    );
};

export const DisplayFile = ({ file, onDelete }) => {
    const [loading, setLoading] = useState(false);
    return (
        <PulppoLoader loading={loading}>
            <div
                className="relative"
                onClick={async (e) => {
                    setLoading(true);
                    try {
                        e.stopPropagation();
                        const pathname = file.data && new URL(file.data).pathname;
                        if (file.data.startsWith('private://')) {
                            const newUrl = await getDownloadURL(ref(storage, file.data.replace('private://', '')));
                            window.open(newUrl, '_blank');
                        } else if ('id' in file && file.id) {
                            window.open(`${api_legal_url}/legal/document/${file?.id}`, '_blank');
                        } else if (
                            file.data?.includes('https://firebasestorage.googleapis.com/') ||
                            file?.data?.startsWith('private://')
                        ) {
                            const url = await getDownloadURL(ref(storage, file.data));
                            window.open(url, '_blank');
                        } else if (pathname.startsWith('/private')) {
                            const url = await getDownloadURL(ref(storage, pathname));
                            window.open(url, '_blank');
                        } else {
                            window.open(file.data, '_blank');
                        }
                    } catch (e) {
                        console.error(e);
                    } finally {
                        setLoading(false);
                    }
                }}
            >
                <CloseCircleFilled
                    className="absolute right-0 top-0 -translate-y-1/4 translate-x-1/4 transform cursor-pointer"
                    onClick={(e) => {
                        e.stopPropagation();
                        onDelete();
                    }}
                />
                <ImageOrPDF url={file.data} />

                <p className="truncate">{file.data.split('/').slice(-1)?.[0]}</p>
            </div>
        </PulppoLoader>
    );
};
