import { CloseOutlined, ShareAltOutlined, RightOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { useRouter } from 'next/router';
import { useEffect, useState, createRef } from 'react';
import { ShowContactList } from '../Contact/SimpleContactList';
import { LoyaltyProgress } from '../Loyalty/LoyaltyProgress';
import useIsMobile from '../../hooks/isMobile';
import useSWR from 'swr';
import { api_url, fetcher } from '../../helpers/fetcher';
import axios from '../../utils/axios';
import useUser from '../../hooks/useUser';
import { twMerge } from 'tailwind-merge';
import { getLevelTresholds } from '../Loyalty/LoyaltyDrawer';
import { UserAvatar } from './UserAvatar';
import { LoadingButton } from '../Elements/ButtonLoading';
import { showShareDrawer } from '../Contact/ShareDrawer';
import { IContact, ISimpleUser } from '@pulppo/pulppo-models';
import { ButtonAvailability } from './DrawerAvailability';

export const WelcomeCardLoyalty = ({ selectedAgent }) => {
    const router = useRouter();
    const isMobile = useIsMobile();
    const user = useUser((u) => u.user);

    const [showStory, setShowStory] = useState(false);

    const { data: stories, mutate: setStories } = useSWR(`${api_url}/story`, fetcher, {
        fallbackData: []
    });
    const [story, setStory] = useState(null);

    const [percent, setPercent] = useState(0);
    const videoRef = createRef<HTMLVideoElement>();

    const level = selectedAgent?.level;
    const comission = selectedAgent?.comission;

    useEffect(() => {
        if (!story) return;
        const video = videoRef.current;
        if (story.type === 'image') {
            let start = Date.now();
            setPercent(0);
            let timeout = setInterval(() => {
                const total = 10 * 1000;
                const percent = Math.min(100, Math.round(((Date.now() - start) / total) * 100));
                setPercent(percent);
                if (percent >= 100) {
                    clearInterval(timeout);
                    if (story?.index + 1 < stories.length) {
                        setStory({
                            ...stories[story?.index + 1],
                            index: story?.index + 1
                        });
                    } else {
                        setPercent(0);
                        setShowStory(false);
                        setStory(null);
                    }
                }
            }, 100);
            return () => {
                return clearInterval(timeout);
            };
        } else if (story.type === 'video' && video) {
            setPercent(0);
            const onUpdate = () => {
                const progress = video?.currentTime / video?.duration;
                setPercent(Math.round(progress * 100));
            };
            video.addEventListener('timeupdate', onUpdate);
            return () => {
                video.removeEventListener('timeupdate', onUpdate);
            };
        }
    }, [story?._id, videoRef?.current]);

    const withStories = stories.length;
    const withStoriesNotSeen = stories?.some((s) => !s.views);
    const share = (contact: IContact | ISimpleUser, onSend: () => void) => {
        showShareDrawer({
            contact,
            whatsapp: {
                content: `*Este es mi resumen del año en pulppo*
¡Feliz 2024!`,
                attachment:
                    story.type === 'video'
                        ? {
                              video: {
                                  url: story?.url,
                                  mimetype: 'video/mp4'
                              }
                          }
                        : {
                              image: {
                                  url: story?.url,
                                  mimetype: 'image/jpeg'
                              }
                          }
            },
            mail: {
                url: `${api_url}/marketing/email`,
                subject: `Mi resumen del año en pulppo`,
                params: { custom_url: story?.url },
                content: `*Este es mi resumen del año en pulppo*
                ¡Feliz 2024!`
            },
            onSend
        });
    };
    useEffect(() => {
        if (!story) return;
        axios.post(`${api_url}/story/${story._id}/view`);

        setStories(stories.map((s) => (s._id === story._id ? { ...s, views: s.views + 1 } : s)));
    }, [story?._id]);

    return (
        <>
            <div
                className={twMerge(
                    'visible fixed left-0 top-0 z-[999] h-screen w-screen bg-zinc-900 opacity-100 transition-all duration-300',
                    !showStory && 'invisible h-0 w-0 opacity-0'
                )}
                onClick={(ev) => {
                    setPercent(0);
                    if (ev.clientX > window.innerWidth / 2) {
                        if (story?.index + 1 < stories.length) {
                            setStory({
                                ...stories[story?.index + 1],
                                index: story?.index + 1
                            });
                        } else {
                            setShowStory(false);
                            setStory(null);
                        }
                    } else {
                        if (story?.index - 1 >= 0) {
                            setStory({
                                ...stories[story?.index - 1],
                                index: story?.index - 1
                            });
                        } else {
                            setShowStory(false);
                            setStory(null);
                        }
                    }
                }}
            >
                <div className="fixed left-0 top-0 flex w-full gap-2 p-4">
                    {stories.map((s, index) => (
                        <div
                            key={s._id}
                            className={`h-2 flex-1 rounded-full ${
                                index > story?.index
                                    ? 'bg-zinc-700'
                                    : index === story?.index
                                      ? 'relative bg-zinc-700'
                                      : 'bg-zinc-100'
                            }`}
                        >
                            {index === story?.index && (
                                <div
                                    className="absolute left-0 top-0 h-full rounded-full bg-zinc-100 transition-all"
                                    style={{
                                        width: `${percent}%`
                                    }}
                                />
                            )}
                        </div>
                    ))}
                </div>
                <div
                    className="fixed right-6 top-6 z-10 cursor-pointer"
                    onClick={() => {
                        setShowStory(false);
                        setStory(null);
                    }}
                >
                    <CloseOutlined className="text-4xl text-white" />
                </div>
                {story && story?.type === 'video' ? (
                    <video
                        playsInline
                        ref={videoRef}
                        onEnded={() => {
                            if (story?.index + 1 < stories.length) {
                                setStory({
                                    ...stories[story?.index + 1],
                                    index: story?.index + 1
                                });
                                setPercent(0);
                            } else {
                                setShowStory(false);
                                setStory(null);
                            }
                        }}
                        autoPlay
                        className="h-full w-full object-contain"
                        src={story?.url}
                    />
                ) : story?.type === 'image' ? (
                    <img className="h-full w-full object-contain" src={story?.url} alt="" />
                ) : null}
                <div className="fixed bottom-6 z-10 flex w-full items-center justify-center">
                    <LoadingButton
                        onClick={async (ev) => {
                            ev.stopPropagation();
                            axios.post(`${api_url}/story/${story._id}/share`);
                            setStory((s) => ({ ...s, shares: s.shares + 1 }));
                            try {
                                if (isMobile) {
                                    let response = await fetch(story?.url);
                                    let data = await response.blob();
                                    let metadata = {
                                        type: story.type === 'video' ? 'video/mp4' : 'image/jpeg'
                                    };
                                    let file = new File(
                                        [data],
                                        `Pulppo_Wrapped_2023_${user.firstName}.${
                                            story.type === 'video' ? 'mp4' : 'jpeg'
                                        }`,
                                        metadata
                                    );
                                    await navigator
                                        .share?.({
                                            files: [file]
                                        })
                                        .catch((err) => console.error(err));
                                } else {
                                    const { destroy } = ShowContactList({
                                        onSelect: (contact) => {
                                            share(contact, () => {
                                                destroy({
                                                    triggerCancel: true
                                                });
                                            });
                                        },
                                        onAdd: (contact) => {
                                            share(contact, () => {
                                                destroy({
                                                    triggerCancel: true
                                                });
                                            });
                                        }
                                    });
                                }
                            } catch (err) {
                                notification.error({
                                    message: 'No se pudo compartir',
                                    description: `${err}`
                                });
                            }
                        }}
                        className="flex h-auto items-center justify-center rounded-full bg-white px-4 py-2 text-lg text-black"
                    >
                        Compartir <ShareAltOutlined />
                    </LoadingButton>
                </div>
            </div>
            <div
                className="mb-2 flex items-center justify-between px-4 py-2 lg:mb-4"
                style={{
                    background: !level ? '#FAFAFA' : '#212322',
                    color: !level ? '#212322' : 'white'
                }}
            >
                <div
                    className="flex cursor-pointer items-center justify-between gap-4 lg:flex-row lg:rounded-full lg:py-1 lg:pl-1"
                    onClick={() => {
                        router.query.loyalty = 'true';
                        router.push(router);
                    }}
                >
                    <div className="mr-6 flex items-center gap-4 lg:pt-0">
                        <UserAvatar
                            onClick={
                                stories?.length
                                    ? (e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setShowStory(true);
                                          setStory({ ...stories[0], index: 0 });
                                      }
                                    : () => {
                                          router.query.loyalty = 'true';
                                          router.push(router);
                                      }
                            }
                            agent={selectedAgent}
                            withStories={withStories}
                            withStoriesNotSeen={withStoriesNotSeen}
                        />
                        <div className="flex flex-col items-start justify-center gap-0 md:gap-2">
                            <h1
                                className={twMerge(
                                    'mb-0 font-mark text-lg font-medium leading-none text-pulppo-primary-black md:leading-tight',
                                    level && 'text-white'
                                )}
                            >
                                ¡Hola {selectedAgent?.firstName || ''}!
                                <br className="block md:hidden" />
                            </h1>
                            <div className="lg:font-regular mb-0 flex items-center gap-2 text-sm font-thin lg:text-base">
                                <p className="mb-0">
                                    Asesor{' '}
                                    {level === 'professional'
                                        ? 'Profesional'
                                        : level === 'elite'
                                          ? 'Elite'
                                          : level === 'standard'
                                            ? 'Estándar'
                                            : 'No afiliado'}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="hidden h-[30px] w-px bg-pulppo-status-dark-disabled lg:block"></div>
                    <div className="hidden w-full items-center gap-2 pb-4 lg:flex lg:w-auto lg:pb-0">
                        {level ? (
                            <div className="w-full px-8 lg:w-[340px] lg:px-0">
                                <LoyaltyProgress
                                    max={getLevelTresholds(level).max}
                                    level={level}
                                    comission={comission}
                                    className="h-5 text-sm lg:h-6 lg:text-base"
                                />
                            </div>
                        ) : (
                            <p className="max-w-[300px] text-pulppo-status-dark-error">
                                Únete a Pulppo Plus y comienza a disfrutar los beneficios
                            </p>
                        )}
                    </div>
                    <RightOutlined className="hidden text-pulppo-primary-gray lg:block" />
                </div>

                {/* <img className="h-full object-contain" src="/gifs/christmas_gif.gif" alt="pulppo gif" /> */}
                <ButtonAvailability />
            </div>
        </>
    );
};
