import { Wrapper } from '@googlemaps/react-wrapper';
import { useEffect, useRef } from 'react';

function MapInside({
    center,
    className
}: {
    center: google.maps.LatLng | google.maps.LatLngLiteral;
    className?: string;
}) {
    const streetRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        new google.maps.StreetViewPanorama(streetRef?.current as HTMLDivElement, {
            position: center,
            pov: {
                heading: 34,
                pitch: 10
            }
        });
    }, []);

    return (
        <>
            <div className={className || 'aspect-video w-full'} ref={streetRef}></div>
        </>
    );
}

export const StreetView = ({
    center,
    className = ''
}: {
    center: google.maps.LatLng | google.maps.LatLngLiteral;
    className?: string;
}) => {
    return (
        <Wrapper apiKey="AIzaSyDCMPUOVzmP2XTOs-rlqx9Hscbx3Irj0ig">
            <MapInside className={className} center={center} />
        </Wrapper>
    );
};
