import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../services/authentication';

export const openUrl = async (url: string = '') => {
    const pathname = url && new URL(url).pathname;
    if (url.startsWith('private://')) {
        const newUrl = await getDownloadURL(ref(storage, url.replace('private://', '')));
        window.open(newUrl, '_blank');
    } else if (url.includes('https://firebasestorage.googleapis.com/') || url?.startsWith('private://')) {
        const newUrl = await getDownloadURL(ref(storage, url));
        window.open(newUrl, '_blank');
    } else if (pathname.startsWith('/private')) {
        const newUrl = await getDownloadURL(ref(storage, decodeURIComponent(pathname)));
        window.open(newUrl, '_blank');
    } else {
        window.open(url, '_blank');
    }
};
