/* eslint-disable react/no-deprecated */
import { PulppoDrawer } from '../Elements/PulppoDrawer';
import * as ReactDOM from 'react-dom';
import Valuation, { ValuationProps } from './Valuation';

export interface ValuationDrawerProps extends ValuationProps {
    visible: boolean;
    onClose: () => void;
}

const ValuationDrawer = ({ visible, onClose, ...valuationProps }: ValuationDrawerProps) => {
    return (
        <PulppoDrawer visible={visible} onClose={onClose} title="Valuación">
            <Valuation {...valuationProps} />
        </PulppoDrawer>
    );
};

export default ValuationDrawer;

export const showValuationDrawer = (config: Omit<ValuationDrawerProps, 'visible' | 'onClose'>) => {
    const container = document.createDocumentFragment();
    let currentConfig = {
        ...config,
        onClose,
        visible: true
    } as any;

    function destroy(...args: any[]) {
        ReactDOM.unmountComponentAtNode(container);
        const triggerCancel = args.some((param) => param && param.triggerCancel);
    }

    function render(props) {
        setTimeout(() => {
            ReactDOM.render(<ValuationDrawer {...props} />, container);
        });
    }

    function onClose(...args: any[]) {
        currentConfig = {
            ...currentConfig,
            visible: false,
            afterClose: () => {
                destroy.apply(this, args);
            }
        };
        render(currentConfig);
    }

    function update(configUpdate) {
        if (typeof configUpdate === 'function') {
            currentConfig = configUpdate(currentConfig);
        } else {
            currentConfig = {
                ...currentConfig,
                ...configUpdate
            };
        }
        render(currentConfig);
    }

    render(currentConfig);

    return {
        destroy: close,
        update
    };
};
