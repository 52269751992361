interface ParsePriceProps {
    price: number | string;
    withMathRound?: boolean;
    startStr?: string;
    endStr?: string;
}

export const parsePrice = ({ price, withMathRound, startStr = '', endStr = '' }: ParsePriceProps) => {
    if (typeof price === 'string') price = price.replace(/\D/gm, '');
    const value = withMathRound
        ? Math.round(parseInt(`${price}`))
        : typeof price === 'string'
          ? parseInt(price)
          : price;
    if (!value && value !== 0) return '';
    return `${startStr}${value.toLocaleString(process.env.NEXT_PUBLIC_LANGUAGE_CODE || 'es-MX')}${
        endStr ? ` ${endStr}` : ''
    }`;
};

export const secondsToMinutes = (seconds: number) => {
    return Math.floor(seconds / 60);
};
