import { DownOutlined } from '@ant-design/icons';
import { ISimpleUser } from '@pulppo/pulppo-models';
import { ShowContactList } from '../Contact/SimpleContactList';

export const ContactSelect = ({ value = null, user, onSelect = (contact: ISimpleUser) => {} }) => {
    return (
        <div
            className="flex w-full cursor-pointer items-center justify-between border border-solid border-gray-300 px-3 py-2 md:w-auto"
            onClick={() => {
                const { destroy } = ShowContactList({
                    onAdd: (contact) => {
                        onSelect(contact);
                        destroy();
                    },
                    onSelect: (contact) => {
                        onSelect(contact);
                        destroy();
                    }
                });
            }}
        >
            <p>{!value ? 'Seleccionar contacto' : `${value.firstName} ${value.lastName}`}</p>
            <DownOutlined className="text-pulppo-status-dark-disabled" />
        </div>
    );
};
