import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import HelpVideo from '../components/HelpVideo';
import Button from '../components/Elements/Button';
import { Drawer, Modal } from 'antd';
import Table, { TypeColumn } from '../components/Elements/Table';
import useSWR from 'swr';
import { fetcher, setNewToken } from '../helpers/fetcher';
import { ConfirmationStep, CreateServiceRequest, IDToName } from './Elements/CreateServiceRequest';
import { ProviderLogin } from './Elements/ProviderLogin';
import axios from '../utils/axios';
import { EyeOutlined } from '@ant-design/icons';
import useUser from '../hooks/useUser';
import { LoadingButton } from './Elements/ButtonLoading';
import { ColorBadge } from '../utils/color';
import { PulppoDrawer } from './Elements/PulppoDrawer';
import { useTranslate } from '@tolgee/react';

export const ServiceRequest = ({ open: createServiceRequest, onClose, agent: initialAgent = null }) => {
    useTranslate('property_detail');
    const agent = useUser((u) => u.user);
    return (
        <Drawer
            destroyOnClose
            open={!!createServiceRequest}
            onClose={() => onClose()}
            title={null}
            height={'100%'}
            width={'100%'}
            bodyStyle={{ padding: 0 }}
            placement="bottom"
            footer={null}
            zIndex={900}
        >
            <CreateServiceRequest
                agent={initialAgent || agent}
                onClose={() => {
                    onClose();
                }}
            />
        </Drawer>
    );
};
export const provider_url = process.env.NEXT_PUBLIC_PROVIDER_URL || process.env.NEXT_PUBLIC_API_URL;
const Providers = ({ agent = null }) => {
    const { user } = useContext(UserContext);
    const [provider, setProvider] = useState(null);
    const [createServiceRequest, setCreateServiceRequest] = useState(false);
    const { data: requests, mutate: refresh } = useSWR(
        `${provider_url}/providers/${(agent || user)?._id}/requests`,
        fetcher,
        { fallbackData: [] }
    );

    useEffect(() => {
        if (!provider) return;
        localStorage.setItem('provider', JSON.stringify(provider));
        setNewToken(provider.accessToken);
        refresh();
    }, [provider]);

    useEffect(() => {
        const provider = localStorage.getItem('provider') && JSON.parse(localStorage.getItem('provider'));
        if (provider) {
            setNewToken(provider.accessToken);
            setProvider(provider);
        }
    }, []);

    const [showConfirmation, setShowConfirmation] = useState(null);

    if (!user && !provider) {
        return <ProviderLogin setProvider={setProvider} />;
    }

    return (
        <div className="relative h-full flex-1 p-4 pb-48 lg:pb-0">
            <div className="flex items-center justify-between border-b-0 border-solid border-gray-300 pb-2">
                <div className="flex flex-row items-center">
                    <p className="text-lg">Solicitudes de servicios</p>
                    <HelpVideo
                        video="services"
                        className={{
                            button: {
                                text: 'sr-only md:not-sr-only'
                            }
                        }}
                    />
                </div>
                <div className="absolute inset-x-0 bottom-0 z-20 flex flex-col-reverse items-center gap-4 bg-white p-4 md:static md:flex-row md:bg-transparent md:p-0">
                    <Button
                        onClick={() => {
                            setCreateServiceRequest(true);
                        }}
                        type="primary"
                        className="flex h-10 w-full items-center justify-center whitespace-nowrap px-5 text-base md:w-auto"
                    >
                        Nueva solicitud
                    </Button>
                </div>
            </div>
            <div className="mt-2 flex items-center gap-2"></div>
            <div className="mt-5">
                <Table
                    columns={[
                        {
                            key: 'client',
                            name: 'Cliente'
                        },
                        {
                            key: 'property',
                            name: 'Propiedad'
                        },
                        {
                            key: 'provider',
                            name: 'Proveedor'
                        },
                        {
                            key: 'type',
                            name: 'Tipo de solicitud'
                        },
                        {
                            key: 'status',
                            name: 'Estado'
                        },
                        {
                            key: 'action',
                            name: 'Acción'
                        }
                    ]}
                    dataSource={requests.map((element) => ({
                        client: {
                            type: TypeColumn.Avatar,
                            avatarType: 'user',
                            data: {
                                user: element.client
                            }
                        },
                        property: {
                            type: TypeColumn.Avatar,
                            avatarType: 'property',
                            data: {
                                property: element.property
                            }
                        },
                        provider: {
                            type: TypeColumn.Avatar,
                            avatarType: 'user',
                            data: {
                                user: {
                                    firstName: element.provider.name,
                                    lastName: '',
                                    profilePicture: element.provider.logo
                                }
                            }
                        },
                        type: {
                            type: TypeColumn.Default,
                            value: IDToName[element.type]?.name
                        },
                        status: {
                            type: TypeColumn.Badge,
                            value: element.status.name,
                            color: MapColors[element.status.last]
                        },
                        action: {
                            type: TypeColumn.Default,
                            value: (
                                <LoadingButton
                                    onClick={async () => {
                                        if (element.provider?.login) {
                                            const url = await axios
                                                .post(
                                                    `${provider_url}/providers/${element.provider._id}/requests/${element._id}`
                                                )
                                                .then((res) => res.data?.url);
                                            if (url) {
                                                return window.open(url, '_blank');
                                            }
                                            throw new Error('No se pudo abrir la solicitud');
                                        } else {
                                            setShowConfirmation(element);
                                        }
                                    }}
                                >
                                    <EyeOutlined />
                                </LoadingButton>
                            )
                        }
                    }))}
                />
                <ServiceRequest
                    agent={agent}
                    open={createServiceRequest}
                    onClose={() => {
                        setCreateServiceRequest(false);
                        refresh?.();
                    }}
                />
                <Modal
                    className="w-2xl"
                    open={showConfirmation}
                    footer={null}
                    onCancel={() => setShowConfirmation(false)}
                >
                    <ConfirmationStep onPrevious={undefined} onNext={undefined} request={showConfirmation} />
                </Modal>
            </div>
        </div>
    );
};

const MapColors = {
    pending: ColorBadge.Gray,
    accepted: ColorBadge.Blue,
    rejected: ColorBadge.Red,
    'requires-action': ColorBadge.Yellow,
    'requires-action-broker': ColorBadge.Yellow,
    'requires-action-client': ColorBadge.Yellow,
    'requires-action-owner': ColorBadge.Yellow,
    completed: ColorBadge.Green,
    cancelled: ColorBadge.Gray
};

export default Providers;
