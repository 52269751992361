import { DownOutlined } from '@ant-design/icons';
import { IProperty } from '@pulppo/pulppo-models';
import { useState } from 'react';
import { parsePrice } from '../../utils/parser';
import { NextImage } from './NextImage';
import { PulppoDrawer } from './PulppoDrawer';

const PropertySelect = ({
    value = null,
    onSelect = () => {},
    properties = []
}: {
    value?: IProperty;
    onSelect?: (property: IProperty) => void;
    properties?: IProperty[];
}) => {
    const [show, setShow] = useState(false);
    return (
        <>
            <PulppoDrawer
                visible={show}
                onClose={() => {
                    setShow(false);
                }}
            >
                {properties.map((property) => {
                    const name = property?.address?.street || property?.listing?.title || 'Sin dirección';
                    return (
                        <>
                            <div
                                onClick={() => {
                                    onSelect(property);
                                    setShow(false);
                                }}
                                className="items-top mt-6 flex w-full cursor-pointer justify-between pr-2 hover:bg-gray-100"
                                key={property?._id as string}
                            >
                                <div className="flex w-full items-center gap-2">
                                    <div className="shrink-0">
                                        <NextImage
                                            alt={property?._id as string}
                                            showPreview={false}
                                            width={50}
                                            height={50}
                                            className="h-24 w-24 rounded-md"
                                            src={property?.pictures?.[0]?.url}
                                        />
                                    </div>

                                    <div className="shrink-1  flex h-full flex-col justify-around">
                                        <p className="font-bold">
                                            {`${name.slice(0, 30)}${name?.length > 30 ? '...' : ''}`}
                                        </p>
                                        <p className="text-ellipsis font-medium text-pulppo-status-dark-disabled">{`${
                                            property?.address?.neighborhood?.name || ''
                                        }, ${property?.address?.city?.name || ''}`}</p>
                                        <p className="text-lg font-bold">
                                            {parsePrice({
                                                price: property?.listing?.price?.price,
                                                startStr: `${property?.listing?.price?.currency} `
                                            })}
                                        </p>
                                    </div>
                                </div>
                                <div className="ml-2 mt-2">
                                    {value?._id === property?._id ? (
                                        <div className="flex h-7 w-7 rounded-full border-2 border-solid border-black bg-white">
                                            <div className="mx-1 my-1 h-4 w-4 rounded-full bg-confirmationGreen"></div>
                                        </div>
                                    ) : (
                                        <div className="h-7 w-7 rounded-full border-2 border-solid border-black bg-white">
                                            {' '}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    );
                })}
            </PulppoDrawer>
            <div
                className={`flex w-full cursor-pointer items-center justify-between border border-solid border-gray-300 px-3 py-2 md:w-auto`}
                onClick={() => setShow(true)}
            >
                <p className="truncate">
                    {!value
                        ? 'Seleccionar propiedad'
                        : `${value.address?.street || value.address?.neighborhood?.name || value.address?.name}`}
                </p>
                <DownOutlined className="text-pulppo-status-dark-disabled" />
            </div>
        </>
    );
};

export default PropertySelect;
