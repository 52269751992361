import { AlertFilled, CheckCircleOutlined, CloseOutlined, ForwardOutlined, WarningFilled } from '@ant-design/icons';
import { ITask } from '@pulppo/pulppo-models';
import { Button, Modal } from 'antd';
import axios from '../../utils/axios';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { api_url, fetcher } from '../../helpers/fetcher';

const TaskHeader = ({
    task: initialTask,
    beforeCheck = async () => {}
}: {
    task: ITask;
    beforeCheck?: () => Promise<void>;
}) => {
    const router = useRouter();
    const [taskQueue, setTaskQueue] = useState<{
        current: number;
        tasks: ITask[];
    }>(null);
    const [currentTask, setCurrentTask] = useState(initialTask);
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const ref = useRef(null);
    useEffect(() => {
        if (!router.query?.['queueId'] && initialTask?._id) {
            setCurrentTask(initialTask);
        } else {
            setTaskQueue(JSON.parse(window.sessionStorage.getItem(`queue_${router.query?.['queueId']}`)));
        }
    }, [router, initialTask]);
    useEffect(() => {
        if (!taskQueue?.tasks[taskQueue?.current]?._id) return;
        if (!currentTask?._id || currentTask?._id?.toString() !== taskQueue?.tasks[taskQueue?.current]?._id?.toString())
            fetcher(`${api_url}/task/${taskQueue?.tasks[taskQueue?.current]._id}`)
                .then((task) => setCurrentTask(task))
                .catch(() => setCurrentTask(null));
    }, [taskQueue]);
    useEffect(() => {
        if (ref?.current) {
            ref.current.scrollIntoView();
        }
    }, [ref]);

    const goToNextTask = () => {
        if (!taskQueue) return;
        if (taskQueue?.current >= taskQueue?.tasks?.length - 1) return;
        if (!router.query['queueId']) return;
        const aux = { ...taskQueue };
        aux.current++;
        sessionStorage.setItem(`queue_${router.query['queueId']}`, JSON.stringify(aux));
        setShowAlert(false);
        setCurrentTask(null);
        const url = taskQueue?.tasks[aux.current].reddirectTo;
        router.push(url + '?queueId=' + router.query['queueId']);
    };

    const close = () => {
        setCurrentTask(null);
        setShowAlert(false);
        setTaskQueue(null);
        if (router.query['queueId']) window.sessionStorage.removeItem(`queue_${router.query['queueId']}`);
        if (taskQueue) router.push('/');
    };

    const markAsDone = () => {
        axios
            .patch(`${api_url}/task/${currentTask?._id}/markAsDone`)
            .then((res) => {
                if (res.data?.check) {
                    if (taskQueue && taskQueue?.current < taskQueue?.tasks?.length - 1) {
                        goToNextTask();
                    } else {
                        if (router.query['queueId']) {
                            sessionStorage.removeItem(`queue_${router.query['queueId']}`);
                        }
                        if (taskQueue) router.push('/');
                        else setCurrentTask(null);
                    }
                    setShowAlert(false);
                } else {
                    setShowAlert(true);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                Modal.error({
                    title: 'Error al marcar tarea',
                    content:
                        'Hubo un problema al intentar marcar esta tarea como realizada. Continúa realizando las demás tareas y vuelve a intentarlo mas tarde'
                });
            });
    };

    return (
        <div>
            {currentTask?._id && (
                <div key={currentTask?._id as string} ref={ref}>
                    <div className="striped-gold hidden w-full items-center bg-black lg:flex">
                        <div
                            className="w-full bg-black px-4 py-4 pr-14"
                            style={{
                                clipPath: 'polygon(0 0, 100% 0, 88% 100%, 0% 100%)'
                            }}
                        >
                            <div className="flex items-center">
                                <div className="flex items-center text-white">
                                    <AlertFilled className="mr-4 text-4xl" />
                                    <p>
                                        {currentTask?.type === 'search'
                                            ? `${currentTask?.name}: ${currentTask?.message}`
                                            : currentTask?.message}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex h-full w-full items-center justify-end px-8 py-4">
                            {taskQueue && (
                                <p className="text-lg font-medium text-white">
                                    Tarea {`${taskQueue?.current + 1}/${taskQueue?.tasks?.length}`}
                                </p>
                            )}
                            {taskQueue && taskQueue?.current < taskQueue?.tasks?.length - 1 && (
                                <Button
                                    className="ml-2 bg-white font-bold"
                                    onClick={() => {
                                        goToNextTask();
                                    }}
                                >
                                    Saltar <ForwardOutlined />
                                </Button>
                            )}
                            <Button
                                loading={loading}
                                className="ml-2 bg-white font-bold"
                                disabled={currentTask?.status !== 'pending'}
                                onClick={() => {
                                    setLoading(true);
                                    beforeCheck().then(() => {
                                        markAsDone();
                                    });
                                }}
                            >
                                Realizada <CheckCircleOutlined />
                            </Button>
                            <Button
                                className="ml-2 bg-white font-bold"
                                onClick={() => {
                                    close();
                                }}
                            >
                                <CloseOutlined className="text-2xl" />
                            </Button>
                        </div>
                    </div>
                    <div className="w-full bg-black px-4 py-4 lg:hidden">
                        <div className="flex justify-between">
                            <div className="flex items-center justify-end text-white">
                                <AlertFilled className="mr-4 text-4xl" />
                                <p>
                                    {currentTask?.type === 'search'
                                        ? `${currentTask?.name}: ${currentTask?.message}`
                                        : currentTask?.message}
                                </p>
                            </div>
                            <CloseOutlined
                                className="ml-2 text-2xl text-white"
                                onClick={() => {
                                    close();
                                }}
                            />
                        </div>
                    </div>
                    <div className="bg-black lg:hidden">
                        <div className="striped-gold-mobile flex w-full items-center justify-end px-4 py-4">
                            {taskQueue && (
                                <p className="text-lg font-medium text-white">
                                    {' '}
                                    Tarea {`${taskQueue?.current + 1}/${taskQueue?.tasks?.length}`}
                                </p>
                            )}
                            {taskQueue && taskQueue?.current <= taskQueue?.tasks?.length - 1 && (
                                <Button
                                    className="ml-2 bg-white text-base font-bold"
                                    onClick={() => {
                                        goToNextTask();
                                    }}
                                >
                                    Saltar <ForwardOutlined />
                                </Button>
                            )}
                            <Button
                                className="ml-2 bg-white text-base font-bold"
                                loading={loading}
                                disabled={currentTask?.status !== 'pending'}
                                onClick={() => {
                                    setLoading(true);
                                    beforeCheck().then(() => {
                                        markAsDone();
                                    });
                                }}
                            >
                                Realizada <CheckCircleOutlined />
                            </Button>
                        </div>
                    </div>
                    {showAlert && (
                        <div className="flex w-full bg-notification-red px-4 py-4 text-strong-red lg:items-center">
                            <WarningFilled className="text-4xl" />
                            <p className="ml-4 lg:font-medium">
                                Detectamos que la tarea que estás marcando como realizada no ha sido completada. Por
                                favor, completa la tarea para poder avanzar
                            </p>
                        </div>
                    )}
                    {currentTask?.status === 'done' && (
                        <div className="bg-confirmation-green flex w-full px-4 py-4 text-strong-green lg:items-center">
                            <CheckCircleOutlined className="text-4xl" />
                            <p className="ml-4 lg:font-medium">
                                Esta tarea ya ha sido realizada. Presiona &quot;Saltar&quot; para continuar
                            </p>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default TaskHeader;
