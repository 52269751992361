import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { PulppoDrawer } from './PulppoDrawer';
import { Button } from 'antd';
import { CloseCircleOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { twMerge } from 'tailwind-merge';

export interface PulppoRadioProps {
    value: string;
    onApply: (value: string) => void;
    onClear: () => void;
    options: { value: string; label: string }[];
    defaultOptionValue?: string;
    render: (active: { label: string; value: string }) => ReactElement;
    showButtons?: boolean;
    title?: string | ReactNode;
    isSort?: boolean;
}

export const PulppoRadio = ({
    value,
    onApply,
    onClear,
    options,
    defaultOptionValue,
    render,
    title = 'Selecciona una opción',
    showButtons = true,
    isSort
}: PulppoRadioProps) => {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(value || defaultOptionValue);
    const clear = () => {
        setOpen(false);
        onClear();
    };
    const apply = (value) => {
        setOpen(false);
        onApply(value);
    };

    useEffect(() => {
        setSelected(value);
    }, [value]);

    return (
        <div>
            <div onClick={() => setOpen(true)}>{render(options.find((opt) => opt.value === selected))}</div>
            <PulppoDrawer
                visible={open}
                onClose={() => {
                    setOpen(false);
                }}
                title={title}
            >
                <div className="flex h-full flex-col justify-between">
                    <div className="flex flex-1 flex-col gap-5 pb-6">
                        {options.map((option) => (
                            <div
                                key={`opt_${option?.label}`}
                                className="flex cursor-pointer items-center gap-2 border-b border-solid border-gray-100 py-2 hover:underline"
                                onClick={() => {
                                    setSelected(option.value);
                                    if (!showButtons) apply(option.value);
                                }}
                            >
                                <Radio active={option.value === selected} />
                                <p>{option?.label}</p>
                            </div>
                        ))}
                    </div>
                    {showButtons ? (
                        <div className="flex w-full gap-4">
                            <Button className="h-10 flex-1 rounded-none font-medium" onClick={clear}>
                                {isSort ? 'Limpiar' : 'Borrar filtros'}
                            </Button>
                            <Button
                                onClick={() => {
                                    apply(selected);
                                }}
                                className="h-10 flex-1 rounded-none font-medium"
                                type="primary"
                            >
                                {isSort ? 'Ordenar' : 'Filtrar'}
                            </Button>
                        </div>
                    ) : null}
                </div>
            </PulppoDrawer>
        </div>
    );
};

export const PulppoRadioForm = ({
    options,
    value,
    onChange,
    showButtons = true
}: {
    options: { value: string; label: string }[];
    value?: any;
    onChange?: (value: any) => void;
    showButtons?: boolean;
}) => {
    return (
        <PulppoRadio
            options={options}
            onApply={(value) => {
                onChange(value);
            }}
            onClear={() => onChange(null)}
            value={value}
            showButtons={showButtons}
            render={(active) => (
                <div className="flex h-10 cursor-pointer items-center justify-between border border-solid border-gray-300 px-2">
                    {active?.value ? (
                        <div className="flex h-6 items-center justify-center gap-2 bg-black px-2 text-sm text-white">
                            <p>{active.label}</p>
                            <CloseCircleOutlined className="text-base" />
                        </div>
                    ) : (
                        <p className="flex-1 text-pulppo-status-dark-disabled">Seleccione la opción deseada</p>
                    )}
                    {!active?.value ? (
                        <RightOutlined className="text-xl text-gray-300" />
                    ) : (
                        <SearchOutlined className="text-xl text-gray-300" />
                    )}
                </div>
            )}
        />
    );
};

export const Radio = ({ active }) => {
    return (
        <div
            className={twMerge(
                'flex h-5 w-5 items-center justify-center rounded-full border-2 border-solid bg-white',
                active ? 'border-black' : 'border-gray-200'
            )}
        >
            {active ? <div className="h-3 w-3 rounded-full bg-black" /> : null}
        </div>
    );
};
