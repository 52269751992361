import axios from '../utils/axios';

const DEFAULT_URL = process.env.NEXT_PUBLIC_API_URL || 'https://api.pulppo.com';

export const scrapUrl = async (url: string) => {
    return axios
        .get(`${DEFAULT_URL}/scrapper/scrapUrl?url=${url}`)
        .then((res) => res.data)
        .catch(() => null);
};
