/* eslint-disable react/no-deprecated */
import ReactDOM from 'react-dom';
import PropertyDetail, { PropertyDetailProps } from '../components/PropertyDetail/PropertyDetail';
import { withRouter } from 'next/router';
import { TolgeeNextProvider } from '../contexts/TolgeeProvider';

export const showProperty = (config: Omit<PropertyDetailProps, 'visible' | 'onClose'>) => {
    const container = document.createDocumentFragment();
    let currentConfig = {
        ...config,
        close,
        show: true
    } as any;

    function destroy(...args: any[]) {
        ReactDOM.unmountComponentAtNode(container);
        const triggerCancel = args.some((param) => param && param.triggerCancel);
    }

    function render(props) {
        /**
         * https://github.com/ant-design/ant-design/issues/23623
         *
         * Sync render blocks React event. Let's make this async.
         */
        /**
         *
         * TODO: remove user context provider and pass user as prop
         */
        const PDrawer = () => {
            return (
                <TolgeeNextProvider>
                    <PropertyDetail
                        onClose={() => {
                            close && close({ triggerCancel: true });
                        }}
                        visible={props.show}
                        propertyDetailActions={() => <></>}
                        {...props}
                    />
                </TolgeeNextProvider>
            );
        };
        const RoutedRouter = withRouter(PDrawer);
        setTimeout(() => {
            ReactDOM.render(<RoutedRouter />, container);
        });
    }

    function close(...args: any[]) {
        currentConfig = {
            ...currentConfig,
            show: false,
            afterClose: () => {
                destroy.apply(this, args);
            }
        };
        render(currentConfig);
    }

    function update(configUpdate) {
        if (typeof configUpdate === 'function') {
            currentConfig = configUpdate(currentConfig);
        } else {
            currentConfig = {
                ...currentConfig,
                ...configUpdate
            };
        }
        render(currentConfig);
    }

    render(currentConfig);

    return {
        destroy: close,
        update
    };
};
