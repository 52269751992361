import { CopyOutlined } from '@ant-design/icons';
import axios from '../utils/axios';
import { api_url } from '../helpers/fetcher';
import { IProperty } from '@pulppo/pulppo-models';
import useUser from './useUser';
import { useShallow } from 'zustand/react/shallow';
import { useHasPermissions } from './useHasPermissions';

interface IUseDuplicatePropertyOption {
    property: Partial<IProperty>;
}

const useDuplicatePropertyOption = ({ property }: IUseDuplicatePropertyOption) => {
    const permission = useHasPermissions(property, 'property', false);
    const user = useUser(useShallow((state) => state.user));

    return {
        value: 'clone',
        label: 'Duplicar propiedad',
        disabled: property?.status?.last === 'valuation' || !permission || user?.suspensions?.length > 0,
        icon: <CopyOutlined className="bg-gray-100 p-2" />,
        onClick: async () => {
            const newProperty = await axios.post(`${api_url}/property/${property?._id}/duplicate`).then((r) => r.data);
            if (user?.features?.capture10) {
                window.open(`/captaciones/${newProperty?._id}`, '_blank');
            } else {
                window.open(`/listing/${newProperty?._id}/step-1`, '_blank');
            }
        },
        description: 'Crea otra propiedad con las mismas características'
    };
};

export default useDuplicatePropertyOption;
