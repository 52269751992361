import { api_url } from '../helpers/fetcher';
import axios from './axios';

export const openBenefits = async (uid) => {
    const token = await axios.get(`${api_url}/agent/${uid}/maslow`).then((r) => r.data.token);
    const url = `${process.env.NEXT_PUBLIC_MASLOW_URL}?token=${token}&redirect=/marketplace`;
    const popupOrRedirect = (url) => {
        const tryPopup = (url) => {
            try {
                const newWindow = window.open(url, '_blank');
                if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
                    return null;
                }

                (newWindow as Window).window.focus();
                newWindow.addEventListener('load', function () {
                    // console.info("Please allow popups for this website");
                    // window.location.href = url;
                });
                return newWindow;
            } catch (e) {
                return null;
            }
        };
        if (!tryPopup(url)) {
            window.location.href = url;
        }
    };
    popupOrRedirect(url);
};
