import { Skeleton } from 'antd';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
    name: string | ReactNode;
    value: string | number;
    color: 'red' | 'gray' | 'trasparent' | 'green' | 'yellow' | 'border';
    className?: {
        container?: string;
        text?: string;
        description?: string;
    };
    isLoading?: boolean;
    onClick?: () => void;
}

const SimpleCardStat = ({ name, value, color, className, isLoading, onClick }: Props) => {
    let colorClass = '';
    switch (color) {
        case 'red':
            colorClass = 'bg-pulppo-status-light-error text-pulppo-status-dark-error';
            break;
        case 'gray':
            colorClass = 'bg-pulppo-status-light-disabled text-black';
            break;
        case 'trasparent':
            colorClass = 'bg-transparent text-black';
            break;
        case 'green':
            colorClass = 'bg-pulppo-status-light-success text-pulppo-status-dark-success';
            break;
        case 'yellow':
            colorClass = 'bg-pulppo-status-light-warning text-pulppo-status-dark-warning';
            break;
        case 'border':
            colorClass = 'border border-solid border-pulppo-secondary-gray-line';
            break;
    }

    const element = (
        <div className={twMerge('p-6', colorClass, className?.container)}>
            {isLoading ? (
                <Skeleton
                    active
                    title={{
                        width: '40%',
                        className: 'h-8'
                    }}
                    paragraph={false}
                />
            ) : (
                <p className={twMerge('', className?.text)}>{value || 0}</p>
            )}
            <p className={twMerge('whitespace-nowrap text-sm', className?.description)}>{name}</p>
        </div>
    );
    if (onClick) {
        return (
            <button
                className="relative h-full w-full text-left transition-transform hover:scale-105"
                type="button"
                onClick={onClick}
            >
                {element}
            </button>
        );
    }

    return element;
};

export default SimpleCardStat;
