const IconSuccess = () => {
    return (
        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1706_10735)">
                <path
                    d="M43.7783 8.29684C38.9619 3.48052 32.5585 0.828125 25.7471 0.828125C18.9357 0.828125 12.5322 3.48052 7.71598 8.29684C2.89947 13.1132 0.24707 19.5168 0.24707 26.3281C0.24707 33.1394 2.89947 39.543 7.71598 44.3594C12.5322 49.1757 18.9357 51.8281 25.7471 51.8281C32.5585 51.8281 38.9619 49.1757 43.7783 44.3594C48.5947 39.543 51.2471 33.1394 51.2471 26.3281C51.2471 19.5168 48.5947 13.1132 43.7783 8.29684ZM25.7471 49.7963C12.8067 49.7963 2.2789 39.2686 2.2789 26.3281C2.2789 13.3877 12.8067 2.85996 25.7471 2.85996C38.6874 2.85996 49.2152 13.3878 49.2152 26.3281C49.2152 39.2685 38.6874 49.7963 25.7471 49.7963Z"
                    fill="black"
                />
                <path
                    d="M40.2499 15.3816C38.8635 13.9952 36.6076 13.9952 35.2213 15.3816L20.2614 30.3413L15.3594 25.4394C13.973 24.0531 11.7172 24.0531 10.3308 25.4394C8.94442 26.8258 8.94442 29.0816 10.3308 30.4681L17.7472 37.8844C18.4189 38.556 19.3118 38.9257 20.2614 38.9257C21.2111 38.9257 22.104 38.5559 22.7758 37.8843L40.2499 20.4102C41.6361 19.0238 41.6361 16.7678 40.2499 15.3816ZM38.8131 18.9734L21.3391 36.4475C21.0512 36.7352 20.6685 36.8939 20.2615 36.8939C19.8545 36.8939 19.4718 36.7353 19.1841 36.4475L11.7678 29.0311C11.1735 28.4369 11.1735 27.4702 11.7677 26.876C12.0647 26.579 12.455 26.4304 12.8452 26.4304C13.2354 26.4304 13.6258 26.5791 13.9227 26.8761L19.5431 32.4965C19.94 32.8932 20.5831 32.8932 20.9799 32.4965L36.6579 16.8182C37.2522 16.2241 38.219 16.2241 38.8132 16.8182C39.4073 17.4124 39.4073 18.3791 38.8131 18.9734Z"
                    fill="black"
                />
                <path
                    d="M46.2799 30.5259C45.7357 30.3872 45.1831 30.7158 45.0448 31.2596C42.7981 40.0806 34.8628 46.2413 25.7474 46.2413C25.1864 46.2413 24.7314 46.6962 24.7314 47.2573C24.7314 47.8184 25.1864 48.2732 25.7474 48.2732C35.7927 48.2732 44.5377 41.4832 47.0138 31.761C47.1522 31.2175 46.8238 30.6643 46.2799 30.5259Z"
                    fill="black"
                />
                <path
                    d="M46.6753 25.3125C46.1143 25.3125 45.6593 25.7673 45.6593 26.3284C45.6593 26.6189 45.6531 26.9116 45.6406 27.1989C45.6163 27.7594 46.0511 28.2335 46.6117 28.2577C46.6267 28.2583 46.6415 28.2586 46.6563 28.2586C47.1972 28.2586 47.647 27.8321 47.6706 27.2866C47.6843 26.9703 47.6912 26.648 47.6912 26.3284C47.6912 25.7673 47.2363 25.3125 46.6753 25.3125Z"
                    fill="black"
                />
            </g>
            <defs>
                <clipPath id="clip0_1706_10735">
                    <rect width="51" height="51" fill="white" transform="translate(0.24707 0.828125)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default IconSuccess;
