import { PauseOutlined } from '@ant-design/icons';
import { IAgent, ITask } from '@pulppo/pulppo-models';
import { api_url, fetcher } from '../../helpers/fetcher';
import { LoadingButton } from '../Elements/ButtonLoading';
import { PulppoDrawer } from '../Elements/PulppoDrawer';
import useUser from '../../hooks/useUser';
import useSWR from 'swr';
import { useRouter } from 'next/router';
import { generateTaskQueueId } from '../../utils/task';
import Link from 'next/link';
import { notification } from 'antd';
import useChat from '../../hooks/useChat';
import { twMerge } from 'tailwind-merge';

const SuspensionMetricCard = ({
    reason,
    isSuspension = true
}: {
    reason: IAgent['suspensions'][number] & { metric?: number; course?: string };
    isSuspension?: boolean;
}) => {
    const router = useRouter();
    const suspensionUser = router.query.suspension;
    const userId = useUser((u) => u.user.uid);
    const { data: metric } = useSWR(
        suspensionUser && `${api_url}/metrics/${suspensionUser}/type/${reason.reason}`,
        fetcher
    );
    if (reason.reason === 'client-tasks')
        return (
            <div className="mt-6" key={reason.reason}>
                <div className="flex flex-1 items-center justify-between rounded border border-solid border-pulppo-secondary-gray-disabled p-4">
                    <div>
                        <h2 className="mb-2 text-lg">{metric?.metric}</h2>
                        <h3 className="font-medium">Tareas de cliente caducadas</h3>
                    </div>

                    {userId === suspensionUser &&
                        (metric?.metric === 0 && isSuspension ? (
                            <LoadingButton
                                className="h-10"
                                onClick={async () => {
                                    await router.push(`/academia/${metric?.course}`);
                                }}
                            >
                                Ver curso
                            </LoadingButton>
                        ) : (
                            <LoadingButton
                                className="h-10"
                                onClick={() =>
                                    fetcher(
                                        `${api_url}/task?status=pending&priority=all&agent=${suspensionUser}&type=client`
                                    ).then((res: { tasks: Array<ITask> }) => {
                                        if (!res?.tasks?.length) return;

                                        const id = generateTaskQueueId({
                                            currentIndex: 0,
                                            tasks: res.tasks.map((tsk) => ({
                                                _id: tsk._id,
                                                reddirectTo: tsk.reddirectTo
                                            }))
                                        });
                                        const url = res.tasks[0]?.reddirectTo;
                                        router.push(url + '?queueId=' + id);
                                    })
                                }
                            >
                                Realizar tareas
                            </LoadingButton>
                        ))}
                </div>
            </div>
        );
    else if (reason.reason === 'visit-rate')
        return (
            <div className="mt-6" key={reason.reason}>
                <div className="flex flex-1 items-center justify-between rounded border border-solid border-pulppo-secondary-gray-disabled p-4">
                    <div>
                        <h2 className="mb-2 text-lg">{metric?.metric}%</h2>
                        <h3 className="font-medium">Tasa de visita</h3>
                    </div>
                    {userId === suspensionUser &&
                        (isSuspension ? (
                            <LoadingButton
                                className="h-10"
                                onClick={async () => {
                                    await router.push(`/academia/${metric?.course}`);
                                }}
                            >
                                Ver curso
                            </LoadingButton>
                        ) : (
                            <LoadingButton
                                className="h-10"
                                onClick={async () => {
                                    router.query.metrics = 'true';
                                    router.query.metric = 'visitRate';
                                    await router.push(router);
                                }}
                            >
                                Ver métricas
                            </LoadingButton>
                        ))}
                </div>
            </div>
        );
    else if (reason.reason === 'company-debt')
        return (
            <div className="mt-6" key={reason.reason}>
                <div className="flex flex-1 items-center justify-between rounded border border-solid border-pulppo-secondary-gray-disabled p-4">
                    <div>
                        <h2 className="mb-2 text-lg">Deuda vencida</h2>
                        <h3 className="font-medium">No se realizó el pago correspondiente a Pulppo</h3>
                    </div>
                    {suspensionUser !== userId && (
                        <Link href={'/operations'}>
                            <LoadingButton>Ir a operaciones</LoadingButton>
                        </Link>
                    )}
                </div>
            </div>
        );
    return <></>;
};

export const SuspensionDrawer = () => {
    const user = useUser((u) => u.user);
    const setChatContact = useChat((c) => c.setChatContact);
    const router = useRouter();
    const { data: suspensionUser } = useSWR<IAgent>(
        router.query.suspension && `${api_url}/agent/${router.query.suspension}`,
        fetcher
    );
    // const suspensionUser = {
    //     ...user,
    //     suspensions: [{ reason: 'visit-rate' }, { reason: 'client-tasks' }]
    // };
    const suspended = !!suspensionUser?.suspensions?.length;
    const sameUser = user?.uid === suspensionUser?.uid;
    console.log('suspended user', suspensionUser);
    return (
        <PulppoDrawer
            title={suspensionUser?.suspensions?.[0] ? 'Pausa de consultas' : 'Advertencia'}
            visible={!!router.query.suspension}
            onClose={() => {
                delete router.query.suspension;
                router.push(router);
            }}
            bodyStyle={{ padding: 0, height: '100%' }}
        >
            <div className="flex h-full flex-1 flex-col justify-between p-4">
                <div className="flex-1">
                    <div
                        className={`mb-6 flex cursor-pointer items-center gap-4 px-4 py-2 font-semibold ${suspensionUser?.suspensions?.[0] ? 'bg-pulppo-status-light-error text-pulppo-status-dark-error' : 'bg-pulppo-status-light-warning text-pulppo-status-dark-warning'}`}
                    >
                        <PauseOutlined
                            className={twMerge(
                                'rounded-full p-1 text-white',
                                suspended ? 'bg-status-dark-error' : 'bg-status-dark-warning'
                            )}
                        />
                        <div>
                            <p>
                                {sameUser ? 'Tu ' : 'Esta '} cuenta se encuentra en estado{' '}
                                {suspended ? 'Pausa de consultas' : 'Advertencia'} por <br />{' '}
                                {suspensionUser?.suspensions
                                    ?.map((s) =>
                                        s.reason === 'visit-rate' ? 'Tasa de visitas' : 'Tareas caducadas y pendientes'
                                    )
                                    .join(' y ')}{' '}
                            </p>
                        </div>
                    </div>
                    {suspended ? (
                        <div>
                            <p> ¿Cómo recuperar mi estado a disponible? </p>
                            {suspensionUser?.suspensions?.some((s) => s.reason === 'client-tasks') && (
                                <div className="px-1">
                                    <p>
                                        &bull;
                                        <span className="ml-1">Debes completar tus tareas pendientes y atrasadas.</span>
                                    </p>
                                    <p>
                                        &bull;<span className="ml-1">Completa el curso “Tareas pendientes”.</span>
                                    </p>
                                </div>
                            )}
                            {suspensionUser?.suspensions?.some((s) => s.reason === 'visit-rate') && (
                                <div className="px-1">
                                    <p>
                                        &bull;<span className="ml-1">Completa el curso “Tasa de visitas”.</span>
                                    </p>
                                </div>
                            )}
                            <p className="mt-4">¿Cómo regularizar mi métrica?</p>
                            {suspensionUser?.suspensions?.some((s) => s.reason === 'client-tasks') && (
                                <div className="px-1">
                                    <p>
                                        &bull;
                                        <span className="ml-1">
                                            Mantente al día con tus tareas pendientes dedicando unos minutos diarios
                                            para completarlas y evitar que se acumulen.
                                        </span>
                                    </p>
                                </div>
                            )}
                            {suspensionUser?.suspensions?.some((s) => s.reason === 'visit-rate') && (
                                <div className="px-1">
                                    <p>
                                        &bull;
                                        <span className="ml-1">
                                            Asegúrate de concretar visitas con tus clientes en los próximos días.
                                        </span>
                                    </p>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            <p className="mt-4">¿Cómo regularizar mi métrica?</p>
                            {suspensionUser?.warnings?.some((s) => s.reason === 'client-tasks') && (
                                <div className="px-1">
                                    <p>
                                        &bull;
                                        <span className="ml-1">
                                            Mantente al día con tus tareas pendientes dedicando unos minutos diarios
                                            para completarlas y evitar que se acumulen.
                                        </span>
                                    </p>
                                </div>
                            )}
                            {suspensionUser?.warnings?.some((s) => s.reason === 'visit-rate') && (
                                <div className="px-1">
                                    <p>
                                        &bull;
                                        <span className="ml-1">
                                            Asegúrate de concretar visitas con tus clientes en los próximos días.
                                        </span>
                                    </p>
                                </div>
                            )}
                        </div>
                    )}
                    {suspensionUser?.suspensions?.length > 0 && (
                        <>
                            {suspensionUser?.suspensions?.map((reason: IAgent['suspensions'][number]) => (
                                <SuspensionMetricCard isSuspension={true} reason={reason} key={reason.reason} />
                            ))}
                        </>
                    )}
                    {suspensionUser?.warnings?.length > 0 && (
                        <>
                            {suspensionUser?.warnings?.map((reason: IAgent['suspensions'][number]) => (
                                <SuspensionMetricCard isSuspension={false} reason={reason} key={reason.reason} />
                            ))}
                        </>
                    )}
                </div>
                {suspensionUser?.uid !== user?.uid && (
                    <div className="w-full">
                        <LoadingButton
                            className="h-11 w-full"
                            type="primary"
                            onClick={async () => {
                                const contact = await fetcher(`${api_url}/contact/phones/${suspensionUser?.phone}`);
                                if (!contact) return notification.error({ message: 'No se encontró el contacto' });
                                setChatContact({
                                    ...contact,
                                    message: `Hola ${suspensionUser?.firstName}, ¿cómo te encuentras? Espero que muy bien.

Pulppo me está informando que tu cuenta se encuentra en pausa de consultas por acumular pendientes en la plataforma.

Puedes seguir entrando a la plataforma para realizar tus pendientes, pero no recibiras consultas de tus propiedades. Las consultas que recibas serán reasignadas a otros asesores de tu equipo.

Por favor, tómate el tiempo de regularizar tu situación, una vez realizados tus pendientes dentro de las 48hs se reactivará tu cuenta.`
                                });
                            }}
                        >
                            Hablar con {suspensionUser?.firstName} {suspensionUser?.lastName}
                        </LoadingButton>
                    </div>
                )}
            </div>
        </PulppoDrawer>
    );
};
