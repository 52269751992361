import SimpleCardStat from './SimpleCardStat';
import { FallOutlined } from '@ant-design/icons';
import { topFunnelScales } from '../../utils/constants';
import { Tooltip } from 'antd';
import { twMerge } from 'tailwind-merge';

export type DaysFunnel = 7 | 15 | 30 | 90;

interface Props {
    id: string;
    name: string;
    total: number;
    values: {
        advanced: number;
        inProgress: number;
        lost: number;
        total?: number;
        difference?: number;
    };
    days: DaysFunnel;
}

const getColorChevron = (id: string, total: number, days: DaysFunnel) => {
    return total >= topFunnelScales[days][id]?.high
        ? {
              bg: 'bg-pulppo-status-light-success',
              text: 'text-pulppo-status-dark-success'
          }
        : total >= topFunnelScales[days][id]?.medium
          ? {
                bg: 'bg-pulppo-status-light-warning',
                text: 'text-pulppo-status-dark-warning'
            }
          : {
                bg: 'bg-pulppo-status-light-error',
                text: 'text-pulppo-status-dark-error'
            };
};

const CHEVRON_WIDTH = 'w-14';

const Chevron = ({
    id,
    valuesTotal,
    total,
    days
}: {
    id: string;
    valuesTotal?: number;
    total: number;
    days: DaysFunnel;
}) => {
    if (valuesTotal === undefined) {
        return <div className={CHEVRON_WIDTH} />;
    }
    const totalPercent = Math.round((valuesTotal / total) * 100);
    const color = getColorChevron(id, totalPercent, days);
    return (
        <Tooltip
            color="black"
            title={
                <p>
                    Excelente: {topFunnelScales[days][id].high}%
                    <br />
                    Promedio: {topFunnelScales[days][id].medium}%
                </p>
            }
        >
            <div className={twMerge('relative flex h-12 items-center justify-center', CHEVRON_WIDTH)}>
                <p
                    style={{
                        clipPath: 'polygon(100% 0, 100% 75%, 50% 100%, 0 75%, 0 0)'
                    }}
                    className={twMerge(
                        'relative z-10 flex h-[98%] w-[98%] items-center justify-center text-sm font-semibold text-black',
                        color.bg
                    )}
                >
                    <span className={twMerge('-mt-2', color.text)}>{totalPercent || 0}%</span>
                </p>
            </div>
        </Tooltip>
    );
};

const BarProgressStat = ({ id, name, total, values, days }: Props) => {
    return (
        <div className="flex items-center gap-x-2 lg:gap-x-4">
            <div className="flex w-20 items-center justify-end">
                <SimpleCardStat
                    name={name}
                    value={values.lost + values.inProgress + values.advanced}
                    color="trasparent"
                    className={{
                        text: 'text-right text-lg font-normal',
                        container: 'p-0'
                    }}
                />
            </div>
            <div className="flex flex-grow items-center">
                <div className="relative flex h-12 w-full border-[0.5px] border-solid border-pulppo-secondary-gray-disabled">
                    <Tooltip color="black" title={`Avanzó ${values.advanced}`}>
                        <div
                            className="h-full bg-[#A3F3EE]"
                            style={{
                                width: `${(values.advanced / total) * 100 || 0}%`
                            }}
                        />
                    </Tooltip>
                    <Tooltip color="black" title={`En proceso ${values.inProgress}`}>
                        <div
                            className="h-full bg-[#FFEABB]"
                            style={{
                                width: `${(values.inProgress / total) * 100 || 0}%`
                            }}
                        />
                    </Tooltip>
                    <Tooltip color="black" title={`Perdidas ${values.lost}`}>
                        <div
                            className="h-full bg-[#FFCDC9]"
                            style={{
                                width: `${(values.lost / total) * 100 || 0}%`
                            }}
                        />
                    </Tooltip>
                    {values.difference > 0 ? (
                        <div className="absolute inset-y-0 right-2 flex items-center">
                            <p className="bg-pulppo-status-light-error px-2 py-0.5 text-sm font-semibold text-pulppo-status-dark-error ring-[0.5px] ring-pulppo-status-dark-error">
                                <FallOutlined className="mr-2" />
                                {values.difference}
                            </p>
                        </div>
                    ) : null}
                </div>
            </div>

            <Chevron id={id} total={total} valuesTotal={values.total} days={days} />
        </div>
    );
};

export default BarProgressStat;
