import { AimOutlined, CheckOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { useState } from 'react';
import { PulppoDatePicker } from '../Elements/PulppoDatePicker';
import dayjs from 'dayjs';
import { Image } from './IntelliHome';
import axios from '../../utils/axios';
import { api_url } from '../../helpers/fetcher';
import { ISearchProperty } from '@pulppo/pulppo-models';
import { Button } from 'antd';
import { twMerge } from 'tailwind-merge';
import { useRouter } from 'next/router';
import { LoadingButton } from '../Elements/ButtonLoading';

export const VisitDetected = ({ show, onClose, search, audit, refresh }) => {
    const [properties, setProperties] = useState([]);
    const [date, setDate] = useState(dayjs());
    const router = useRouter();
    return (
        <PulppoDrawer visible={show} onClose={onClose} title="Registro de visita">
            <div className="flex h-full flex-col justify-between overflow-y-hidden">
                <div className="flex w-full flex-1 flex-col gap-4 overflow-y-auto">
                    <div className="flex cursor-pointer items-center gap-3 bg-pulppo-status-light-warning p-4 text-pulppo-status-dark-warning">
                        <ExclamationCircleFilled className="text-3xl" />
                        <div>
                            <p className="font-medium">
                                Detectamos una posible visita con {search.contact.firstName}{' '}
                                {search.contact.lastName || ''}.
                            </p>
                            <p className="font-light">
                                Completa el formulario para registrar la visita en la plataforma
                            </p>
                        </div>
                    </div>
                    <div className="w-full">
                        <p className="mb-2 font-medium">Fecha de visita</p>
                        <PulppoDatePicker
                            value={date}
                            onChange={(value) => {
                                setDate(value);
                            }}
                        />
                    </div>
                    <div className="w-full">
                        <p className="mb-2 font-medium">Seleccione las propiedades visitadas</p>
                        <div className="flex flex-col gap-4">
                            {search?.properties
                                ?.filter((prop: ISearchProperty) => prop.status.last !== 'deleted')
                                ?.map((prop) => {
                                    const active = properties.includes(prop._id);
                                    return (
                                        <div
                                            key={prop?._id}
                                            onClick={() => {
                                                setProperties((properties) => {
                                                    if (properties.includes(prop._id)) {
                                                        return properties.filter((p) => p !== prop._id);
                                                    }
                                                    return [...properties, prop._id];
                                                });
                                            }}
                                            className="flex w-full cursor-pointer items-center justify-between gap-2 pr-2 hover:bg-gray-100"
                                        >
                                            <div
                                                className={`flex h-5 w-5 shrink-0 items-center justify-center border-2 border-solid ${active ? 'border-black' : 'border-pulppo-status-dark-disabled'}`}
                                            >
                                                {active && (
                                                    <CheckOutlined
                                                        className={`${active ? 'text-black' : 'text-pulppo-status-dark-disabled'}`}
                                                    />
                                                )}
                                            </div>

                                            <div className="flex w-full items-center gap-2">
                                                <div className="shrink-0">
                                                    <Image
                                                        alt={prop?._id as string}
                                                        width={50}
                                                        height={50}
                                                        className="h-24 w-24"
                                                        src={prop?.pictures?.[0]?.url}
                                                    />
                                                </div>

                                                <div className="shrink-1 flex h-full flex-col justify-around">
                                                    <p className="">
                                                        {prop?.address?.street
                                                            ? `${prop?.address?.street?.slice(0, 30)}${
                                                                  prop?.address?.street?.length > 30 ? '...' : ''
                                                              }`
                                                            : 'Sin dirección'}
                                                    </p>
                                                    <p className="text-ellipsis font-light text-pulppo-status-dark-disabled">{`${
                                                        prop?.address?.neighborhood?.name || ''
                                                    }, ${prop?.address?.city?.name || ''}`}</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
                <div className="flex gap-2">
                    <Button
                        onClick={() => {
                            router.push(`/new-searches/${search?._id}`);
                        }}
                        className={twMerge('h-auto w-auto w-min min-w-[1px] py-2')}
                    >
                        <AimOutlined />
                    </Button>
                    <LoadingButton
                        onClick={async () => {
                            await axios.patch(`${api_url}/audit/visit/${audit._id}`, {
                                properties: properties,
                                date: date.toISOString()
                            });
                            onClose();
                            refresh?.();
                        }}
                        type="primary"
                        className="h-auto flex-1 py-2 font-light"
                    >
                        {properties.length ? `Registrar (${properties.length} propiedades)` : `No hubo visita`}
                    </LoadingButton>
                </div>
            </div>
        </PulppoDrawer>
    );
};
