import axios from '../utils/axios';
import { IProperty, ISimpleUser } from '@pulppo/pulppo-models';
import { api_url } from '../helpers/fetcher';
import { ShowPulppoDrawer } from '../components/Elements/showPulppoDrawer';
import { shareACM } from '../components/PropertyDetail/PropertyDetail';
import IconSuccess from '../components/Icon/IconSuccess';
import IconError from '../components/Icon/IconError';
import Button from '../components/Elements/Button';

export const propertyCreateMLS = async (newProperty: Partial<IProperty>) => {
    return axios.post<IProperty>(`${api_url}/property/mls`, newProperty);
};

export const propertyCreateAcmSimple = async ({
    propertyId,
    property,
    user,
    error
}: {
    propertyId: string;
    property?: Partial<IProperty>;
    user?: ISimpleUser;
    error?: {
        description: string;
    };
}) => {
    try {
        const response = await axios.post(`${api_url}/property/${propertyId}/valuate`, { acm: true });

        const { destroy } = ShowPulppoDrawer({
            title: 'ACM creado con éxito',
            children: (
                <div className="flex h-full w-full flex-col items-center justify-between">
                    <div className="mt-16 flex w-3/4 flex-col items-center gap-4">
                        <IconSuccess />
                        <div className="flex flex-col items-center gap-1">
                            <p className="text-sm font-semibold text-pulppo-primary-black">
                                Hemos generado con éxito tu ACM
                            </p>
                            <p className="text-center text-sm font-light leading-normal text-pulppo-primary-black">
                                Ahora puedes enviárselo al propietario para que lo revise y gestione los próximos pasos.
                            </p>
                        </div>
                    </div>

                    <div className="flex w-full items-center gap-x-2">
                        <Button className="mb-2 h-10 w-full p-2 font-light" type="secondary" onClick={() => destroy()}>
                            Volver
                        </Button>
                        <Button
                            className="mb-2 h-10 w-full p-2 font-light"
                            type="primary"
                            onClick={async () => {
                                await shareACM({
                                    property: property as IProperty,
                                    user,
                                    contact: property?.contact,
                                    onSend: () => {
                                        destroy();
                                    }
                                });
                            }}
                        >
                            Compartir
                        </Button>
                    </div>
                </div>
            ),
            onCancel: () => destroy()
        });

        return response;
    } catch (err) {
        const { destroy } = ShowPulppoDrawer({
            title: 'No pudimos realizar el ACM simplificado',
            children: (
                <div className="flex h-full w-full flex-col items-center justify-between">
                    <div className="mt-16 flex w-3/4 flex-col items-center gap-4">
                        <IconError />
                        <div className="flex flex-col items-center gap-1">
                            <p className="text-sm font-semibold text-pulppo-primary-black">
                                No se encontraron comparables
                            </p>
                            <p className="text-center text-sm font-light leading-normal text-pulppo-primary-black">
                                No hay propiedades que se ajusten a los filtros seleccionados, puedes probar ajustando
                                los datos de la propiedad y volver a intentarlo.
                            </p>
                        </div>
                    </div>

                    <Button className="mb-2 h-10 w-full p-2 font-light" type="primary" onClick={() => destroy()}>
                        Ajustar datos de la propiedad
                    </Button>
                </div>
            ),
            onCancel: () => destroy()
        });

        return null;
    }
};
