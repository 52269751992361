import { InfoCircleFilled } from '@ant-design/icons';
import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { Button, Input } from 'antd';
import { useState } from 'react';
import { LoadingButton } from '../Elements/ButtonLoading';
import { api_url, instance } from '../../helpers/fetcher';

export const CancelDrawer = ({
    visitId,
    visible,
    onConfirm,
    onClose
}: {
    visible: boolean;
    visitId: string;
    onConfirm: () => void;
    onClose: () => void;
}) => {
    const [textInput, setTextInput] = useState('');
    const cancelVisit = async (visitId: string) => {
        await instance.delete(`${api_url}/visit/${visitId}`, {
            data: { reasonToFinish: textInput || '' }
        });
        onConfirm();
    };
    return (
        <PulppoDrawer visible={visible} onClose={() => onClose()} title="Cancelar recorrido">
            <div className="flex h-full flex-col">
                <div className="flex-1">
                    <div className="flex w-full items-center gap-3 bg-pulppo-status-light-warning p-3 text-sm text-pulppo-status-dark-warning">
                        <InfoCircleFilled className="text-3xl" />
                        <p>
                            Recuerda que puedes modificar la fecha y hora de la visita, evitando cancelarla o creando
                            otra a futuro.
                        </p>
                    </div>
                    <p className="mb-1 mt-4 text-sm font-semibold">Motivo de cancelación</p>
                    <Input.TextArea
                        value={textInput}
                        className="rounded-none"
                        onInput={(ev) => {
                            setTextInput(ev.currentTarget.value);
                        }}
                        placeholder="Indique un motivo de cancelación de la visita"
                        rows={6}
                    />
                </div>
                <div className="flex w-full items-center justify-center gap-3">
                    <Button className="h-10 flex-1" onClick={() => onClose()}>
                        Editar recorrido
                    </Button>
                    <LoadingButton type="primary" className="h-10 flex-1" onClick={() => cancelVisit(visitId)}>
                        Cancelar recorrido
                    </LoadingButton>
                </div>
            </div>
        </PulppoDrawer>
    );
};
