import Title from './Title';
import { AimOutlined, RightOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import BarProgressStat, { DaysFunnel } from './BarPorgressStat';
import useSWR from 'swr';
import { api_url, fetcher } from '../../helpers/fetcher';
import { useState } from 'react';
import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { PulppoSelect } from '../Elements/PulppoSelect';
import Button from '../Elements/Button';
import { useTranslate } from '@tolgee/react';
import { AgentSelect } from '../Elements/AgentSelect';
import useUser from '../../hooks/useUser';
import { IAgent } from '@pulppo/pulppo-models';

export const FunnelInner = ({ typeOperation = 'all', timeVisualization = 90 as DaysFunnel, agentId }) => {
    const { data, isLoading } = useSWR<{
        pending: {
            cancelled: number;
            next: number;
            keep: number;
            total: number;
        };
        searching: {
            cancelled: number;
            next: number;
            keep: number;
            total: number;
        };
        visiting: {
            cancelled: number;
            next: number;
            keep: number;
            total: number;
        };
        offer_done: {
            cancelled: number;
            next: number;
            keep: number;
            total: number;
        };
        closing: {
            cancelled: number;
            next: number;
            keep: number;
            total: number;
        };
        completed: number;
        cancelled: number;
        total: number;
    }>(agentId && `${api_url}/agent/${agentId}/funnel?operation=${typeOperation}&time=${timeVisualization}`, fetcher);
    const { t } = useTranslate(['home', 'common']);
    console.log({ data });

    return (
        <>
            <div className="mt-8 flex flex-grow flex-col justify-between space-y-2 lg:mt-4">
                {isLoading || !data ? (
                    <>
                        {[...Array(6).keys()].map((value) => {
                            return (
                                <Skeleton
                                    key={`loader-bar-progress-stat-${value}`}
                                    paragraph={false}
                                    title={{
                                        width: '100%',
                                        className: 'h-[4.25rem]'
                                    }}
                                    active
                                />
                            );
                        })}
                    </>
                ) : (
                    <>
                        <BarProgressStat
                            id="pending"
                            name={t('funnel_pending', 'Consultas')}
                            total={data.pending.total}
                            values={{
                                total: data.pending.total,
                                inProgress: data.pending.keep,
                                advanced: data.pending.next,
                                lost: data.pending.cancelled
                            }}
                            days={timeVisualization}
                        />
                        <BarProgressStat
                            id="searching"
                            name={t('funnel_searching', 'Buscando')}
                            total={data.pending.total}
                            values={{
                                total: data.visiting.total,
                                advanced: data.searching.next,
                                inProgress: data.searching.keep,
                                lost: data.searching.cancelled,
                                difference: data.pending.total - data.searching.total
                            }}
                            days={timeVisualization}
                        />
                        <BarProgressStat
                            id="visiting"
                            name={t('funnel_visiting', 'Visitando')}
                            total={data.pending.total}
                            values={{
                                total: data.offer_done.total,
                                inProgress: data.visiting.keep,
                                advanced: data.visiting.next,
                                lost: data.visiting.cancelled,
                                difference: data.searching.total - data.visiting.total
                            }}
                            days={timeVisualization}
                        />
                        <BarProgressStat
                            id="offer_done"
                            name={t('funnel_offering', 'Ofertando')}
                            total={data.pending.total}
                            values={{
                                total: data.closing.total,
                                inProgress: data.offer_done.keep,
                                advanced: data.offer_done.next,
                                lost: data.offer_done.cancelled,
                                difference: data.visiting.total - data.offer_done.total
                            }}
                            days={timeVisualization}
                        />
                        <BarProgressStat
                            id="closing"
                            name={t('funnel_closing', 'Cerrando')}
                            total={data.pending.total}
                            values={{
                                total: data.completed,
                                inProgress: data.closing.keep,
                                advanced: data.closing.next,
                                lost: data.closing.cancelled,
                                difference: data.offer_done.total - data.closing.total
                            }}
                            days={timeVisualization}
                        />
                        <BarProgressStat
                            id="completed"
                            name={t('funnel_completed', 'Completas')}
                            total={data.pending.total}
                            values={{
                                inProgress: data.closing.next,
                                advanced: 0,
                                lost: 0,
                                difference: data.closing.total - data.completed
                            }}
                            days={timeVisualization}
                        />
                    </>
                )}
            </div>
            <div className="mt-10 flex justify-center space-x-4">
                <div className="flex items-center">
                    <div className="h-2 w-2 rounded-full bg-[#A3F3EE]" />
                    <p className="ml-2 text-xs text-pulppo-status-dark-disabled">
                        {t('funnel_legend_green', 'AVANZÓ')}
                    </p>
                </div>
                <div className="flex items-center">
                    <div className="h-2 w-2 rounded-full bg-[#FFEABB]" />
                    <p className="ml-2 text-xs text-pulppo-status-dark-disabled">
                        {t('funnel_legend_yellow', 'EN PROCESO')}
                    </p>
                </div>
                <div className="flex items-center">
                    <div className="h-2 w-2 rounded-full bg-[#FFCDC9]" />
                    <p className="ml-2 text-xs text-pulppo-status-dark-disabled">
                        {t('funnel_legend_red', 'PERDIDAS')}
                    </p>
                </div>
            </div>
        </>
    );
};

export const FunnelFilters = ({ show, onClose, filters, setFilters }) => {
    const { t } = useTranslate(['home', 'common']);
    const user = useUser((u) => u.user);
    return (
        <PulppoDrawer
            visible={show}
            onClose={onClose}
            title={t('multiselect_title', 'Seleccione las opciones deseadas', { ns: 'common' })}
        >
            <div>
                <div>
                    <p className="mb-2 font-medium text-black">
                        {t('operation_type', 'Tipo de operación', { ns: 'common' })}
                    </p>
                    <PulppoSelect
                        className="h-10"
                        value={filters.typeOperation}
                        title={t('select_title', 'Seleccione una opción', { ns: 'common' })}
                        allowSearch={false}
                        onSelect={({ value }) => setFilters((f) => ({ ...f, typeOperation: value }))}
                        options={[
                            {
                                label: t('all', 'Todos', { ns: 'common' }),
                                value: 'all',
                                key: 'all'
                            },
                            {
                                label: t('sale', 'Venta', { ns: 'common' }),
                                value: 'sale',
                                key: 'sale'
                            },
                            {
                                label: t('rent', 'Renta', { ns: 'common' }),
                                value: 'rent',
                                key: 'rent'
                            }
                        ]}
                    />
                </div>
                <div className="mt-6">
                    <p className="mb-2 font-medium text-black">
                        {t('funnel_filters_time', 'Tiempo de visualización')}{' '}
                    </p>
                    <PulppoSelect
                        className="h-10"
                        value={`${filters.timeVisualization}`}
                        title={t('select_title', 'Seleccione una opción', { ns: 'common' })}
                        allowSearch={false}
                        onSelect={({ value }) =>
                            setFilters((f) => ({ ...f, timeVisualization: parseInt(value) as DaysFunnel }))
                        }
                        options={[
                            {
                                label: t('last_n_days', 'Últimos {days} días', { ns: 'common', days: 7 }),
                                value: '7',
                                key: '7'
                            },
                            {
                                label: t('last_n_days', 'Últimos {days} días', { ns: 'common', days: 15 }),

                                value: '15',
                                key: '15'
                            },
                            {
                                label: t('last_n_days', 'Últimos {days} días', { ns: 'common', days: 30 }),

                                value: '30',
                                key: '30'
                            },
                            {
                                label: t('last_n_days', 'Últimos {days} días', { ns: 'common', days: 90 }),

                                value: '90',
                                key: '90'
                            }
                        ]}
                    />
                </div>
                {(user?.features?.viewAll || user?.permissions?.searches?.view) && (
                    <div className="mt-6">
                        <p>Asesor</p>
                        <AgentSelect
                            className=""
                            onChange={(agent: IAgent) => {
                                setFilters((f) => ({ ...f, agent }) as any);
                            }}
                            allowAll={true}
                            user={user}
                            value={filters?.agent}
                            renderButton={() => {
                                return (
                                    <div className="flex cursor-pointer items-center justify-between border border-solid border-gray-300 p-2">
                                        {filters?.agent?.firstName} {filters?.agent?.lastName}
                                        <RightOutlined className="text-gray-300" />
                                    </div>
                                );
                            }}
                        />
                    </div>
                )}
            </div>
        </PulppoDrawer>
    );
};

const Funnel = () => {
    const user = useUser((u) => u.user);
    const [filters, setFilters] = useState({ typeOperation: 'all', timeVisualization: 90 as DaysFunnel, agent: user });
    const [showDrawerSearchFilter, setShowDrawerSearchFilter] = useState(false);
    const { t } = useTranslate(['home', 'common']);

    return (
        <section className="flex flex-col border border-solid border-gray-100 bg-white p-4 lg:col-span-6 xl:col-span-7">
            <Title
                icon={
                    <div className="flex h-11 w-11 items-center justify-center p-2.5">
                        <AimOutlined className="text-2xl" />
                    </div>
                }
                level={2}
                description={t('last_n_days', '', { days: filters?.timeVisualization, ns: 'common' })}
                rightElement={
                    <Button type="secondary" className="font-normal" onClick={() => setShowDrawerSearchFilter(true)}>
                        {t('filters', 'Filtros', { ns: 'common' })}
                    </Button>
                }
            >
                <span className="font-book">{t('funnel_title', 'Búsquedas')}</span>
            </Title>
            <FunnelFilters
                show={showDrawerSearchFilter}
                onClose={() => setShowDrawerSearchFilter(false)}
                filters={filters}
                setFilters={setFilters}
            />
            <FunnelInner
                timeVisualization={filters?.timeVisualization}
                typeOperation={filters?.typeOperation}
                agentId={filters?.agent?.uid}
            />
        </section>
    );
};

export default Funnel;
