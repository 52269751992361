import nookies from 'nookies';
import useSWR from 'swr';
import { api_url, fetcher } from '../../helpers/fetcher';
import dayjs from 'dayjs';
import { IContact, IFilters, IProperty, ISearch, ISimpleProperty } from '@pulppo/pulppo-models';
import { Button, Drawer, List, Modal, Tabs, notification } from 'antd';
import {
    CalendarOutlined,
    CaretRightFilled,
    CloseOutlined,
    CommentOutlined,
    DollarOutlined,
    EditOutlined,
    FileDoneOutlined,
    FileSearchOutlined,
    FormOutlined,
    HourglassOutlined,
    MailOutlined,
    MoreOutlined,
    PhoneOutlined,
    PlusCircleOutlined,
    ShareAltOutlined,
    WhatsAppOutlined
} from '@ant-design/icons';
import { NextImage } from '../../components/Elements/NextImage';
import { parsePrice } from '../../utils/parser';
import { SearchDrawer, shareSearch } from '../../components/Elements/SearchDrawer';
import { useContext, useEffect, useState } from 'react';
import { ShowTaskDrawer } from '../../components/Elements/TaskDrawer';
import { Filters } from '../../components/Property/Filters';
import { UserContext } from '../../contexts/UserContext';
import { ShowNoteDrawer } from '../../components/Elements/NoteDrawer';
import useIsMobile from '../../hooks/isMobile';
import { PulppoDrawer } from '../../components/Elements/PulppoDrawer';
import { PropertyList } from '../../components/Property/PropertyList';
import axios from '../../utils/axios';
import TaskHeader from '../../components/Tasks/TaskHeader';
import { useRouter } from 'next/router';
import { showProperty } from '../../utils/showProperty';
import { PulppoLoader } from '../../components/Elements/PulppoLoader';
import { ShowTourDrawer } from '../../components/Elements/TourDrawer';
import useChat from '../../hooks/useChat';
import { SearchPropertyCard } from '../../components/Elements/SearchPropertyCard';
import { showVisitReview } from '../../components/Elements/VisitReviewDrawer';
import AvatarUser from '../../components/Elements/AvatarUser';
import { PulppoSelect } from '../../components/Elements/PulppoSelect';
import { twMerge } from 'tailwind-merge';
import { portals } from '../../components/PropertyDetail/PropertyDetailInternal';
import Icon, { IconType } from '../../components/Icon/Icon';
import { GetServerSideProps } from 'next';
import { LoadingButton } from '../../components/Elements/ButtonLoading';
import { ShowPortal } from '../../utils/ShowPortal';
import { shareProperties } from '../../components/PropertyDetail/PropertyDetail';
import { createSearchInitTask } from '../../utils/task';
import { useTranslate } from '@tolgee/react';

export const sourceIcons = {
    pulppo: '/icons/apple-touch-icon.png',
    'pulppo.com': '/icons/apple-touch-icon.png',
    'inmuebles 24': '/inmuebles.png',
    facebook: '/facebook-logo.png',
    Lamudi: '/lamudi.png',
    easybroker:
        'https://cdn.easybroker.com/assets/product/logos/icon-3c95cdabf18f7dcfdde6cf8ceccb9614206a5e9bfda8d4251bb8e88e359dff82.svg',
    ...portals.reduce((result, portal) => ({ ...result, [portal.path]: portal.logo }))
};

const tabs = [
    {
        key: 'shared',
        label: 'Compartidas',
        filter: (property) => !!property?.added && property?.status?.last !== 'deleted'
    },
    {
        key: 'suggested',
        label: 'Sugeridas',
        filter: (property) => !property?.added
    },
    {
        key: 'deleted',
        label: 'Descartadas',
        filter: (property) => property?.status?.last === 'deleted'
    }
];

export const Search = ({ id }: { id: string }) => {
    const { user } = useContext(UserContext);
    const router = useRouter();
    const setChatContact = useChat((store) => store.setChatContact);
    const [showOptions, setShowOptions] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [showPropertyList, setShowPropertyList] = useState(false);
    const [showAddDrawer, setShowAddDrawer] = useState(false);
    const [suggestedPropertiesSelected, setSuggestedPropertiesSelected] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState<IFilters>({});
    const [tab, setTab] = useState('shared');
    const [search, setSearch] = useState<ISearch>(null);
    const [task, setTask] = useState(null);
    const [showDelegate, setShowDelegate] = useState(false);
    const isMobile = useIsMobile();
    const { data: visits, mutate: refreshVisits } = useSWR<any[]>(id && `${api_url}/visit/search/${id}`, fetcher);
    const {
        data: properties,
        mutate: refreshProperties,
        isLoading
    } = useSWR<Array<any>>(id && `${api_url}/search/${id}/properties?deleted=true`, fetcher);
    const { data: contact } = useSWR<IContact>(
        search?.contact?._id && id && `${api_url}/contact/${search.contact?._id}/simple`,
        fetcher
    );

    const { t } = useTranslate('common');

    const { data: suggestedProperties, mutate: refreshSuggestedProperties } = useSWR<Array<ISimpleProperty>>(
        `${api_url}/search/${id}/suggested`,
        fetcher
    );
    const refreshSearch = async () => {
        setLoading(true);
        await refreshProperties();
        fetcher(`${api_url}/search/${id}?source=agent`)
            .then((res) => setSearch(res))
            .catch(() => setSearch(null))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (router.query.chat && search?.contact?._id) {
            setChatContact({
                ...search?.contact,
                search,
                searchId: id
            });
        }
    }, [router.query.chat, search?.contact?._id]);

    useEffect(() => {
        if (router.query.review) {
            fetcher(`${api_url}/visit/${router.query.review}`)
                .then((res) => {
                    if (res?.survey?.status === 'answered') {
                        const { destroy } = showVisitReview({
                            onClose: () => {
                                destroy();
                            },
                            visit: res
                        });
                    }
                })
                .catch((err) => console.log(err));
        }
        if (router.query.list === 'true') {
            setShowPropertyList(true);
        }
    }, [router.query]);

    useEffect(() => {
        refreshSearch();
    }, [id]);

    useEffect(() => {
        setShowDelegate(
            user?.features?.callCenter ||
                user?.callCenterOperator ||
                (!search?.agent && search?.company?._id === user?.company?._id && user?.permissions?.searches?.edit) ||
                search?.agent?.uid === user?.uid ||
                (search?.company?._id === user?.company?._id && user?.permissions?.searches?.edit)
        );
    }, [user, search?.agent]);

    useEffect(() => {
        if (search?.filters) {
            setFilters(search.filters);
        }
    }, [search?.filters]);

    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            axios
                .patch(`${api_url}/search/${id}`, {
                    $set: {
                        filters
                    }
                })
                .then(() => {
                    refreshSuggestedProperties();
                });
        }
    }, [filters]);

    useEffect(() => {
        if (search) {
            fetcher(`${api_url}/task/one?type=search,client&id=${search?._id}&dueDate=expired`).then((data) => {
                if (data?._id) setTask(data);
            });
        }
    }, [search?._id]);

    useEffect(() => {
        if (router?.query?.suggestedPropertiesFrom) {
            setTab('suggested');
        }
    }, [router.query]);

    const openTasks = () => {
        const { destroy } = ShowTaskDrawer({
            user,
            task: createSearchInitTask({
                search,
                user
            }),
            type: 'search',
            metadata: {
                agent: user,
                properties: search?.properties,
                id: `${search?._id}`
            },
            onClose: () => {
                destroy();
            }
        });
    };

    const openNotes = () => {
        const { destroy } = ShowNoteDrawer({
            search: search,
            user,
            onClose: () => {
                destroy();
            }
        });
    };

    const openVisits = (property = null) => {
        const { destroy } = ShowPortal({
            type: 'visit-drawer',
            config: {
                contact,
                searchId: id,
                refreshSearch,
                onClose: () => {
                    destroy();
                },
                property
            }
        });
    };

    const onRemove = async (property) => {
        await axios
            .patch(`${api_url}/search/${search?._id}/properties/delete`, {
                user: user?.uid,
                properties: [property],
                reasonToFinish: `${user.firstName} ${user.lastName}`
            })
            .then(() => refreshSearch())
            .catch(() =>
                Modal.error({
                    title: 'Hubo un error',
                    content:
                        'Hubo un error al intentar eliminar la propiedad. Por favor intentelo nuevamente mas tarde.'
                })
            );
    };

    const onAddFromPropertyList = async (selectedProperties: Array<IProperty>, needRefresh: boolean = true) => {
        await axios.patch(`${api_url}/search/${search?._id}/properties/add`, {
            properties: selectedProperties,
            user: user?.uid,
            source: search?.agent
        });
        selectedProperties.forEach((listing) => {
            axios
                .post(`${api_url}/property/${listing._id}/addStats`, {
                    source: 'search',
                    field: 'share'
                })
                .catch(() => {});
        });
        refreshSearch?.();
        if (needRefresh) {
            setShowPropertyList(false);
        }
    };

    const onRestore = async (property: IProperty) => {
        Modal.confirm({
            title: 'Restaurar publicación',
            content: 'Seguro que quiere volver a agregar esta publicación de su búsqueda?',
            okText: 'Agregar',

            cancelText: 'Cancelar',
            closable: true,
            onOk: async () => {
                await axios
                    .patch(`${api_url}/search/${search?._id}/properties/add`, {
                        properties: [property],
                        user: user?.uid,
                        source: search?.agent
                    })
                    .then(() => {
                        setTab('shared');
                        refreshSearch();
                    })
                    .catch(() =>
                        Modal.error({
                            title: 'Hubo un error',
                            content:
                                'Hubo un error al intentar agregar la propiedad. Por favor intentelo nuevamente mas tarde.'
                        })
                    );
            }
        });
    };

    return (
        <PulppoLoader loading={!search || isLoading || loading}>
            <TaskHeader task={task} beforeCheck={async () => {}} />
            <div className="overflow-x-hidden px-4 py-4">
                <Filters
                    visible={showFilters}
                    setVisible={setShowFilters}
                    appliedFilters={filters}
                    setAppliedFilters={setFilters}
                    hide={{
                        sort: true,
                        radius: true,
                        internal: true,
                        search: true
                    }}
                />
                <div className="fixed bottom-footer-height z-50 -mx-4 w-full bg-white px-6 py-3 lg:hidden">
                    <div className="flex w-full justify-center gap-2">
                        <Button
                            className="flex h-12 w-1/4 items-center justify-center text-2xl font-medium"
                            onClick={() => {
                                setChatContact({
                                    ...search?.contact,
                                    search,
                                    searchId: id
                                });
                            }}
                        >
                            <CommentOutlined />
                        </Button>
                        <Button
                            className="flex h-12 w-1/4 items-center justify-center text-2xl font-medium"
                            onClick={() => {
                                openTasks();
                            }}
                        >
                            <FileDoneOutlined />
                        </Button>
                        <Button
                            className="flex h-12 w-1/4 items-center justify-center text-2xl font-medium"
                            onClick={() => openNotes()}
                        >
                            <FormOutlined />
                        </Button>
                        <Button
                            disabled={user?.uid !== search?.agent?.uid}
                            className="flex h-12 w-1/4 items-center justify-center text-2xl font-medium"
                            onClick={() => setShowOptions(true)}
                        >
                            <MoreOutlined />
                        </Button>
                    </div>
                    <Button
                        type="primary"
                        disabled={user?.uid !== search?.agent?.uid}
                        className="mt-2 h-10 w-full font-medium"
                        onClick={() => setShowPropertyList(true)}
                    >
                        Buscar propiedades
                    </Button>
                </div>
                <div className="mb-2 hidden lg:block">
                    <div className="flex items-center justify-between">
                        <p className="text-lg">
                            <span>{t(search?.status.last)}</span>
                            {` desde ${search ? dayjs(search?.createdAt).tz().fromNow() : '-'}`}
                        </p>
                        <div className="flex gap-5">
                            <Button
                                className="flex h-10 items-center font-medium"
                                onClick={() => {
                                    setChatContact({
                                        ...search.contact,
                                        search,
                                        searchId: id,
                                        asAgent: search.agent
                                    });
                                }}
                            >
                                Conversación <CommentOutlined className="text-xl" />
                            </Button>
                            <Button className="flex h-10 items-center font-medium" onClick={() => openTasks()}>
                                Tareas <FileDoneOutlined className="text-xl" />
                            </Button>
                            <Button
                                className="flex-items-center h-10 font-medium"
                                onClick={() => {
                                    openNotes();
                                }}
                            >
                                Notas <FormOutlined className="text-xl" />
                            </Button>
                            <Button
                                className="flex-items-center h-10 font-medium"
                                onClick={() => setShowOptions(true)}
                                disabled={user?.uid !== search?.agent?.uid}
                            >
                                <MoreOutlined className="text-xl" />
                            </Button>
                            <Button
                                type="primary"
                                className="h-10 font-medium"
                                onClick={() => setShowPropertyList(true)}
                                disabled={user?.uid !== search?.agent?.uid}
                            >
                                Buscar propiedades
                            </Button>
                            {showDelegate ? (
                                <LoadingButton
                                    className="h-10 font-medium"
                                    onClick={async () => {
                                        if (
                                            !search?.properties?.find(
                                                (p) =>
                                                    p?.status?.last !== 'deleted' && p?.status?.last !== 'not_available'
                                            )
                                        )
                                            return notification.error({
                                                message:
                                                    'Tienes que tener una propiedad disponible en la búsqueda para derivarla'
                                            });
                                        await axios.post(`${api_url}/search/${search?._id}/delegate`, {});
                                    }}
                                >
                                    Derivar
                                </LoadingButton>
                            ) : null}
                        </div>
                    </div>
                </div>

                <div className="mt-0 flex gap-5 lg:mt-2">
                    <div className="flex-1 border border-solid border-gray-200 bg-white px-4 py-5 lg:px-8">
                        <div className="flex h-full flex-col items-center gap-5">
                            <div className="flex w-full items-center justify-start gap-4">
                                <AvatarUser
                                    size="large"
                                    user={contact}
                                    titleClassName="font-medium"
                                    description={`Prioridad: ${contact?.score > 1 ? 'Alta' : 'Normal'}`}
                                    label={
                                        contact?.isBroker
                                            ? { text: 'BROKER', textColor: 'white', bgColor: 'black' }
                                            : null
                                    }
                                />
                            </div>
                            <div className="flex w-full flex-col">
                                {contact?.phone ? (
                                    <div className="flex w-full items-center justify-between">
                                        <div className="mr-4">
                                            <p className="text-base">Teléfono</p>
                                            <p className="truncate text-pulppo-primary-gray">+{contact?.phone}</p>
                                        </div>
                                        <div className="flex gap-2">
                                            <Button
                                                className="flex h-10 w-10 items-center justify-center p-2 text-xl"
                                                onClick={() => {
                                                    setChatContact({
                                                        ...contact,
                                                        search,
                                                        searchId: id
                                                    });
                                                }}
                                            >
                                                <WhatsAppOutlined />
                                            </Button>
                                            <Button
                                                className="flex h-10 w-10 items-center justify-center p-2 text-xl font-medium"
                                                onClick={() => {
                                                    window.open(`tel:+${contact?.phone}`);
                                                }}
                                            >
                                                <PhoneOutlined />
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {contact?.email ? (
                                    <div className="flex w-full items-center justify-between">
                                        <div className="mr-4">
                                            <p className="text-base">Email</p>
                                            <p className="truncate text-pulppo-primary-gray">{contact?.email}</p>
                                        </div>
                                        <Button
                                            className="flex h-10 w-10 items-center justify-center p-2 text-xl"
                                            onClick={() => {
                                                window.open(`mailto:${contact?.email}`);
                                            }}
                                        >
                                            <MailOutlined />
                                        </Button>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <div className="flex w-full justify-start gap-4">
                                <div className="w-12 border border-solid border-gray-300 p-1">
                                    <img
                                        className="h-10 w-10 object-contain"
                                        src={
                                            sourceIcons[search?.source] ||
                                            process.env.NEXT_PUBLIC_LOGO_NEGRO_SOLO ||
                                            '/icons/apple-touch-icon.png'
                                        }
                                        alt="touch"
                                    />
                                </div>
                                <div>
                                    <p className="font-medium">
                                        {search?.source || process.env.NEXT_PUBLIC_APP_NAME || 'Pulppo'}
                                    </p>
                                    <p className="text-pulppo-primary-gray">
                                        {dayjs(search?.createdAt).tz().format('DD/MM/YYYY')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="relative hidden w-1/3 flex-col justify-stretch self-stretch overflow-y-auto border border-solid border-gray-200 bg-white py-5 lg:flex">
                        <div className="absolute flex w-full flex-1 flex-col justify-stretch px-8">
                            <div className="flex w-full items-center justify-between">
                                <p className="text-lg font-medium">{`Visitas (${visits?.length})`}</p>
                                <Button
                                    className="flex h-10 items-center justify-center px-4 font-medium"
                                    onClick={() => {
                                        openVisits();
                                    }}
                                >
                                    Ir a visitas <CalendarOutlined className="text-xl" />
                                </Button>
                            </div>

                            <List
                                className="flex-1"
                                dataSource={[...(visits || [])].reverse()}
                                renderItem={(item) => (
                                    <>
                                        <div className="mb-4 mt-4 flex w-full cursor-pointer items-center justify-between">
                                            <div
                                                className="shrink-1 flex flex-1 gap-2"
                                                onClick={() =>
                                                    showProperty({
                                                        property: item.steps?.[0]?.property
                                                    })
                                                }
                                            >
                                                <div className="w-12 shrink-0">
                                                    <NextImage
                                                        alt={item._id as string}
                                                        width={50}
                                                        showPreview={false}
                                                        height={50}
                                                        className="h-12 w-12 rounded-md"
                                                        src={item.steps?.[0]?.property?.pictures?.[0]?.url}
                                                    />
                                                </div>

                                                <div className="shrink-1 flex-1">
                                                    <p className="truncate font-medium">
                                                        {item.steps?.[0]?.property?.address?.street?.length > 20
                                                            ? item.steps?.[0]?.property?.address?.street?.slice(0, 20) +
                                                              '...'
                                                            : item.steps?.[0]?.property?.address?.street}
                                                    </p>
                                                    <p className="text-pulppo-primary-gray">
                                                        {dayjs(item?.startTime).tz().format('DD/MM/YYYY')}
                                                    </p>
                                                </div>
                                            </div>
                                            <Button
                                                onClick={() => {
                                                    const { destroy } = ShowTourDrawer({
                                                        onClose: () => {
                                                            destroy();
                                                        },
                                                        visit: item,
                                                        onSave: async (shouldClose) => {
                                                            if (shouldClose) destroy();
                                                            refreshVisits();
                                                        }
                                                    });
                                                }}
                                                className={twMerge(
                                                    'border border-solid px-4 py-1 text-sm font-medium hover:shadow-md',
                                                    item?.status?.last === 'pending'
                                                        ? 'border-strong-red text-strong-red'
                                                        : dayjs(item.endTime).tz().isAfter(dayjs().tz())
                                                          ? 'border-strong-green text-strong-green'
                                                          : 'border-gray-400 text-pulppo-primary-gray'
                                                )}
                                            >
                                                {item?.status?.last === 'pending'
                                                    ? 'PENDIENTE'
                                                    : dayjs(item.endTime).tz().isAfter(dayjs().tz())
                                                      ? 'CONFIRMADA'
                                                      : 'VISITADA'}
                                            </Button>
                                        </div>
                                    </>
                                )}
                            ></List>
                        </div>
                    </div>
                    <div className="hidden w-1/3 flex-col justify-between border border-solid border-gray-200 bg-white px-8 py-5 lg:flex">
                        <div className="flex flex-col items-start gap-4">
                            <div className="flex w-full items-center justify-between">
                                <p className="text-lg font-medium">Filtros</p>
                                <Button
                                    className="flex h-10 items-center justify-center px-4 font-medium"
                                    onClick={() => setShowFilters(true)}
                                >
                                    Editar <EditOutlined className="text-xl" />
                                </Button>
                            </div>

                            <div className="flex w-full">
                                <div className="flex flex-1 flex-col gap-2">
                                    <div className="flex items-center gap-2">
                                        <FileSearchOutlined className="text-pulppo-primary-gray" />
                                        {`${
                                            filters?.types?.length > 1
                                                ? filters?.types?.reduce((str, type, index) => {
                                                      if (index === filters?.types.length - 1)
                                                          return str + ` o ${type}`;
                                                      if (index === 0) return str + type;
                                                      return str + `, ${type}`;
                                                  }, '')
                                                : filters?.types?.[0] || 'Propiedad'
                                        } en ${filters?.operation === 'sale' ? 'Venta' : t('rent')}`}
                                    </div>
                                    {filters?.price?.min && (
                                        <div className="flex items-center gap-2">
                                            <DollarOutlined className="text-pulppo-primary-gray" />
                                            {parsePrice({
                                                price: filters?.price.min,
                                                startStr: 'Desde $'
                                            })}
                                        </div>
                                    )}
                                    {filters?.price?.max > 0 && (
                                        <div className="flex items-center gap-2">
                                            <DollarOutlined className="text-pulppo-primary-gray" />
                                            {parsePrice({
                                                price: filters?.price.max,
                                                startStr: 'Hasta $'
                                            })}
                                        </div>
                                    )}
                                    {filters?.addresses?.filter?.(Boolean).length > 0 && (
                                        <div className="flex items-center gap-2">
                                            <svg
                                                width="14"
                                                height="14"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M13.6407 0.0211058C12.913 0.154699 12.6563 0.235559 12.2133 0.471106C11.0145 1.10743 10.227 2.33087 10.1391 3.69142C10.0969 4.31017 10.2516 4.89728 10.6911 5.78322C11.2114 6.84493 12.1571 8.19493 13.3278 9.56603C13.8059 10.125 13.9817 10.2164 14.2946 10.0688C14.5864 9.92814 16.1544 7.93478 16.861 6.80626C17.7926 5.31212 18.109 4.32071 17.9508 3.36798C17.6766 1.69454 16.4848 0.435949 14.8536 0.0879021C14.5829 0.0281363 13.82 -0.0105352 13.6407 0.0211058ZM14.8536 1.24806C15.859 1.54337 16.6008 2.36251 16.8188 3.4172C16.8505 3.56486 16.8751 3.77931 16.8751 3.89181C16.8751 4.59493 16.2141 5.87814 15.0188 7.48829C14.6391 8.00157 14.0907 8.68361 14.0626 8.68361C13.9993 8.68361 12.8989 7.23868 12.4559 6.57423C11.8969 5.73751 11.4434 4.85509 11.3169 4.35939C11.1376 3.66329 11.3801 2.77384 11.918 2.134C12.6352 1.2797 13.7848 0.931652 14.8536 1.24806Z"
                                                    fill=" rgb(156 163 175)"
                                                />
                                                <path
                                                    d="M13.6864 2.30273C12.8496 2.48555 12.2696 3.34336 12.4067 4.19414C12.5543 5.10469 13.4192 5.7375 14.3192 5.59336C15.2297 5.4457 15.8625 4.58086 15.7184 3.68086C15.6621 3.32578 15.5004 3.0164 15.2438 2.75976C14.9871 2.50312 14.7446 2.36953 14.4246 2.30273C14.1364 2.23945 13.9711 2.23945 13.6864 2.30273ZM14.2875 3.42422C14.3508 3.45234 14.4457 3.52617 14.4985 3.59297C14.6813 3.83555 14.6637 4.10976 14.4493 4.32422C14.2172 4.55625 13.9184 4.55976 13.6793 4.33125C13.2293 3.90234 13.711 3.18515 14.2875 3.42422Z"
                                                    fill=" rgb(156 163 175)"
                                                />
                                                <path
                                                    d="M3.1957 5.65313C1.6207 6.28594 0.284766 6.83086 0.232031 6.86602C0.179297 6.90117 0.105469 6.99609 0.0667969 7.07695C0.00351563 7.22109 0 7.39688 0 12.3926V17.5605L0.0808594 17.7012C0.165234 17.8559 0.404297 18 0.569531 18C0.622266 18 1.90898 17.5043 3.42773 16.8961L6.1875 15.7922L8.94727 16.8961C10.466 17.5043 11.7527 18 11.8125 18C11.9426 18 17.6238 15.7359 17.7785 15.6234C18.0141 15.4477 18.0035 15.6199 17.993 11.7352C17.9824 8.2582 17.9824 8.23008 17.9086 8.13516C17.7715 7.94883 17.659 7.89258 17.4375 7.89258C17.216 7.89258 17.1035 7.94883 16.9664 8.13516C16.8926 8.23008 16.8926 8.26875 16.875 11.5242L16.8574 14.8148L14.6777 15.6867C13.4789 16.1684 12.4699 16.5691 12.4383 16.5797C12.3785 16.5973 12.375 16.4215 12.368 13.5387C12.3574 10.5117 12.3574 10.4801 12.2836 10.3852C12.1465 10.1988 12.034 10.1426 11.8125 10.1426C11.591 10.1426 11.4785 10.1988 11.3414 10.3852C11.2676 10.4801 11.2676 10.5117 11.257 13.5387C11.25 16.4215 11.2465 16.5973 11.1867 16.5797C11.1551 16.5691 10.1461 16.1684 8.94727 15.6867L6.76758 14.8148L6.75703 10.3605C6.75352 7.91016 6.75703 5.90625 6.76758 5.90625C6.77813 5.90625 7.38281 6.14531 8.11055 6.43711C9.33047 6.92578 9.45352 6.96797 9.61875 6.95391C9.71719 6.94336 9.83672 6.9082 9.88945 6.86953C10.009 6.78516 10.125 6.55313 10.125 6.40195C10.125 6.25781 10.0125 6.03984 9.9 5.96602C9.72422 5.85 6.29297 4.5 6.17695 4.5C6.11367 4.50352 4.82344 4.99922 3.1957 5.65313ZM5.61797 10.3605L5.60742 14.8148L3.42773 15.6867C2.22891 16.1684 1.21992 16.5691 1.18477 16.5797C1.12852 16.5973 1.125 16.3758 1.13203 12.143L1.14258 7.68516L3.35742 6.7957C4.57734 6.31055 5.58281 5.90977 5.60039 5.90625C5.61445 5.90625 5.62148 7.91016 5.61797 10.3605Z"
                                                    fill=" rgb(156 163 175)"
                                                />
                                            </svg>
                                            <p>
                                                {filters?.addresses
                                                    ?.filter?.(Boolean)
                                                    .map((address) => address.name?.split(',')?.[0])
                                                    .join(', ')}
                                            </p>
                                        </div>
                                    )}
                                    <div className="flex items-center gap-2">
                                        <HourglassOutlined className="text-pulppo-primary-gray" />
                                        Buscando hace {dayjs().tz().diff(dayjs(search?.createdAt).tz(), 'days')} días
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2">
                                    {filters?.suites?.length > 0 && (
                                        <div className="flex items-center gap-2">
                                            <img src="/icons/bed-gray.png" alt="Cama gris" />
                                            {filters?.suites?.sort((a, b) => a - b)?.join(', ')}{' '}
                                            {t('suites', 'Recámaras')}
                                        </div>
                                    )}
                                    {filters?.bathrooms?.length > 0 && (
                                        <div className="flex items-center gap-2">
                                            <img src="/icons/bath-gray.png" alt="Baño gris" />
                                            {filters?.bathrooms?.sort((a, b) => a - b)?.join(', ')}{' '}
                                            {t('bathrooms', 'Baños')}
                                        </div>
                                    )}
                                    {filters?.parkings?.length > 0 && (
                                        <div className="flex items-center gap-2">
                                            <img src="/icons/parking-gray.png" alt="Estacionamiento" />
                                            {filters?.parkings?.sort((a, b) => a - b)?.join(', ')}{' '}
                                            {t('parkings', 'Estacionamientos')}
                                        </div>
                                    )}
                                    {filters?.roofedSurface && (
                                        <div className="flex items-center gap-2">
                                            <Icon
                                                name={IconType.Mts}
                                                width={14}
                                                height={14}
                                                className="text-gray-300"
                                            />
                                            {`${filters?.roofedSurface?.min ? `desde ${filters?.roofedSurface?.min}` : ''} ${filters?.roofedSurface?.max ? `hasta ${filters?.roofedSurface?.max}` : ''} m2 construidos`}
                                        </div>
                                    )}
                                    {filters?.totalSurface && (
                                        <div className="flex items-center gap-2">
                                            <Icon
                                                name={IconType.Mts}
                                                width={14}
                                                height={14}
                                                className="text-gray-300"
                                            />
                                            {`${filters?.totalSurface?.min ? `desde ${filters?.totalSurface?.min}` : ''} ${filters?.totalSurface?.max ? `hasta ${filters?.totalSurface?.max}` : ''} m2 de terreno`}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="mt-4 flex h-14 w-full items-center justify-between border border-solid border-gray-300 px-2 lg:hidden"
                    onClick={() => {
                        openVisits();
                    }}
                >
                    <p className="text-lg font-medium">{`Visitas (${visits?.length || 0})`}</p>
                    <CaretRightFilled className="text-xl" />
                </div>
                <div
                    className="mt-4 flex h-14 w-full items-center justify-between border border-solid border-gray-300 bg-white px-2 lg:hidden"
                    onClick={() => {
                        setShowFilters(true);
                    }}
                >
                    <p className="text-lg font-medium">Filtros de búsqueda</p>
                    <CaretRightFilled className="text-xl" />
                </div>
                <PulppoSelect
                    value={tab}
                    options={tabs?.map((t) => ({
                        key: t.key,
                        value: t.key,
                        label: `${t.label} (${[...(properties || []), ...(suggestedProperties || [])]?.filter(t.filter)?.length})`
                    }))}
                    renderButton={(item) => (
                        <div className="mt-4 flex h-14 w-full items-center justify-between border border-solid border-gray-300 bg-white px-2 lg:hidden">
                            <p className="text-lg font-medium">{item.label}</p>
                            <CaretRightFilled className="text-xl" />
                        </div>
                    )}
                    onSelect={(item) => setTab(item.value)}
                />

                <Tabs
                    activeKey={tab}
                    className="no-padding mt-5"
                    tabBarStyle={{ marginBottom: 0 }}
                    tabBarGutter={15}
                    renderTabBar={() => {
                        return (
                            <div className="flex w-full items-center justify-between border-b border-solid border-gray-200">
                                <div className="hidden w-full items-center justify-between lg:flex">
                                    <div className="flex-1">
                                        <div className="mx-0 flex w-full gap-8">
                                            {tabs.map((t) => (
                                                <div
                                                    key={`tab-pulppo-${t.key}`}
                                                    className="cursor-pointer pl-0"
                                                    onClick={() => {
                                                        setTab(t.key);
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            paddingBottom: '4px'
                                                        }}
                                                        className={
                                                            tab === t.key
                                                                ? 'border-b-4 border-solid border-black font-semibold text-black'
                                                                : 'text-pulppo-primary-gray'
                                                        }
                                                    >
                                                        {`${t.label} (${[...(properties || []), ...(suggestedProperties || [])]?.filter(t.filter)?.length})`}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }}
                />
                <div className="mt-2 flex flex-wrap items-stretch pb-32 md:pb-0">
                    {tab === 'shared' && !properties?.length ? (
                        <div className="flex w-full flex-col items-center gap-2 px-10 py-20 text-center">
                            <NextImage
                                alt="visits_empty"
                                src="/icons/houseSearch.svg"
                                showPreview={false}
                                width={100}
                                height={100}
                            />
                            <p className="text-lg font-medium">No tienes propiedades compartidas</p>
                            <p className="px-10">
                                Para añadir propiedades haz click en <br />
                                &quot;Buscar propiedades&quot;
                            </p>
                        </div>
                    ) : (
                        <>
                            {[...(properties || []), ...(suggestedProperties || [])]
                                ?.filter(tabs.find((t) => t.key === tab)?.filter)
                                .sort((a, b) => score(b) - score(a))
                                .map((prop) => (
                                    <div key={prop._id} className="mb-4 w-full p-2 lg:w-1/4">
                                        <SearchPropertyCard
                                            property={prop}
                                            search={search}
                                            onShare={() => {
                                                shareProperties({
                                                    properties: [prop],
                                                    contact: contact,
                                                    user,
                                                    t,
                                                    onSend: () => {}
                                                });
                                            }}
                                            onRemove={() => onRemove(prop)}
                                            onVisit={() => openVisits(prop)}
                                            onRestore={() => onRestore(prop)}
                                            onAdd={() => {
                                                setSuggestedPropertiesSelected([prop]);
                                                setShowAddDrawer(true);
                                            }}
                                        />
                                    </div>
                                ))}
                            {tab === 'shared' && user?.uid == search?.agent?.uid && (
                                <div className="mb-4 hidden min-h-96 w-full p-2 lg:block lg:w-1/4">
                                    <div className="flex h-full flex-col items-center border border-solid border-gray-200 p-4 hover:shadow-md">
                                        <div className="mt-20">
                                            <NextImage
                                                alt="visits_empty"
                                                src="/icons/houseSearch.svg"
                                                showPreview={false}
                                                width={120}
                                                height={120}
                                            />
                                        </div>
                                        <p className="mt-2 text-center text-lg font-medium">Comparte más propiedades</p>
                                        <p className="mt-2 flex-1 px-5 text-center">
                                            Ofrece mas propiedades a tu cliente utilizando el buscador de propiedades o
                                            la pestaña de &quot;sugeridas&quot;
                                        </p>
                                        <Button
                                            type="primary"
                                            className="mb-2 mt-2 h-10 w-full font-medium"
                                            onClick={() => setShowPropertyList(true)}
                                        >
                                            Buscar propiedades
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
                <SearchDrawer
                    show={showOptions}
                    setShow={setShowOptions}
                    search={search}
                    refreshSearch={refreshSearch}
                />
                <Drawer
                    destroyOnClose
                    closable={false}
                    title={
                        <div className="flex w-full justify-end">
                            <CloseOutlined onClick={() => setShowPropertyList(false)} className="text-2xl" />
                        </div>
                    }
                    bodyStyle={{
                        padding: '0px'
                    }}
                    push={{ distance: 1 }}
                    height={'100%'}
                    placement="bottom"
                    onClose={() => setShowPropertyList(false)}
                    open={showPropertyList}
                >
                    <PropertyList
                        showTab={{
                            exclusive: true,
                            pulppo: true,
                            mls: !contact?.isBroker
                        }}
                        showAddButton={false}
                        isPublic={false}
                        filters={{ tab: 'pulppo', ...filters }}
                        setFilters={setFilters}
                        onShareCollection={() => {
                            shareSearch({
                                onSend: () => {},
                                search
                            });
                        }}
                        inCollection={true}
                        propertyDetailActions={({ property }) => (
                            <Button
                                type="primary"
                                className="mt-2 w-full"
                                onClick={() => {
                                    Modal.confirm({
                                        title: 'Agregar publicación',
                                        content: 'Seguro que quiere agregar esta publicación de su búsqueda?',
                                        okText: 'Agregar',

                                        cancelText: 'Cancelar',
                                        closable: true,
                                        onOk: async () => {
                                            await axios
                                                .patch(`${api_url}/search/${search?._id}/properties/add`, {
                                                    properties: [property],
                                                    user: user?.uid,
                                                    source: search?.agent
                                                })
                                                .then(() => {
                                                    setTab('shared');
                                                    refreshSearch();
                                                })
                                                .catch(() =>
                                                    Modal.error({
                                                        title: 'Hubo un error',
                                                        content:
                                                            'Hubo un error al intentar agregar la propiedad. Por favor intentelo nuevamente mas tarde.'
                                                    })
                                                );
                                        }
                                    });
                                }}
                            >
                                Agregar a la búsqueda
                            </Button>
                        )}
                        onAdd={onAddFromPropertyList}
                        showFilters={true}
                    />
                </Drawer>

                <PulppoDrawer
                    title="Agregar a búsqueda"
                    visible={showAddDrawer}
                    onClose={() => setShowAddDrawer(false)}
                >
                    <List>
                        {user && (
                            <List.Item
                                onClick={() => {
                                    setShowAddDrawer(false);
                                    onAddFromPropertyList(suggestedPropertiesSelected).then(() => {
                                        shareSearch({
                                            search,
                                            onSend: () => {}
                                        });
                                        setSuggestedPropertiesSelected([]);
                                    });
                                }}
                                className="cursor-pointer hover:underline"
                            >
                                <List.Item.Meta
                                    title={
                                        <p className="text-lg">
                                            <ShareAltOutlined className="mr-2 text-xl" />
                                            Agregar y compartir
                                        </p>
                                    }
                                    description="Agrega las propiedades a la búsqueda y compartela con tu cliente"
                                />
                            </List.Item>
                        )}
                        <List.Item
                            className="cursor-pointer hover:underline"
                            onClick={() => {
                                setShowAddDrawer(false);
                                onAddFromPropertyList(suggestedPropertiesSelected).then(() => {
                                    setSuggestedPropertiesSelected([]);

                                    notification.success({
                                        message: 'Propiedades agregadas con éxito',
                                        description: `Se agregaron correctamente las propiedades a la búsqueda`,
                                        duration: isMobile ? 2 : 4,
                                        placement: isMobile ? 'bottomRight' : 'topRight'
                                    });
                                });
                            }}
                        >
                            <List.Item.Meta
                                title={
                                    <p className="text-lg">
                                        <PlusCircleOutlined className="mr-2 text-xl" />
                                        Solo agregar
                                    </p>
                                }
                                description="Agrega las propiedades a la búsqueda. Podrás compartirla mas adelante desde la pantalla de búsqueda"
                            />
                        </List.Item>
                    </List>
                </PulppoDrawer>
            </div>
        </PulppoLoader>
    );
};

const score = (property) => {
    if (property?.status?.last === 'to_visit') return 3;
    if (!property?.status?.last) return 1;
    if (property?.status?.last === 'deleted') return 0;
    if (property?.status?.last === 'not_available') return 2;
};

export default Search;

export const getServerSideProps: GetServerSideProps = async (ctx) => {
    if (ctx.params.id === 'undefined') {
        return {
            redirect: {
                permanent: false,
                destination: '/404'
            }
        };
    }
    const cookies = nookies.get(ctx);
    if (!cookies.currentUser) {
        return {
            redirect: {
                permanent: false,
                destination: '/login'
            }
        };
    }
    return {
        props: {
            id: ctx?.params.id
        }
    };
};
