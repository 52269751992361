import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { EditOutlined } from '@ant-design/icons';
import { CalendarDefault, disabledDateBefore } from './Calendar';
import Button from './Button';
import { twMerge } from 'tailwind-merge';

interface Props {
    value?: Dayjs;
    onChange?: (value: Dayjs) => void;
    iconPosition?: 'left' | 'right';
    placeholder?: string;
    placeholderValue?: string;
    className?: {
        calendar?: string;
        container?: string;
    };
    disabled?: boolean;
    showPrevDays?: boolean;
}

const CalendarInput = ({
    value,
    onChange,
    iconPosition,
    placeholder,
    placeholderValue,
    className,
    disabled,
    showPrevDays
}: Props) => {
    const [showOverlay, setShowOverlay] = useState(false);

    return (
        <div className={twMerge('relative hidden lg:block', className?.container)}>
            <Button
                disabled={disabled}
                type="input"
                className={twMerge('text-left text-primary-gray', value && 'text-pulppo-primary-black')}
                icon={
                    <EditOutlined
                        className={twMerge(
                            '-mr-2 h-full bg-pulppo-secondary-gray-disabled px-3 text-lg text-pulppo-primary-black',
                            iconPosition === 'left' && '-ml-2 mr-0'
                        )}
                    />
                }
                onClick={() => setShowOverlay(!showOverlay)}
                iconPosition={iconPosition || 'right'}
            >
                {!value
                    ? placeholder || 'Seleccionar'
                    : placeholderValue
                      ? `${placeholderValue} ${value.format('DD/MM/YYYY')}`
                      : value.format('DD/MM/YYYY')}
            </Button>
            {showOverlay && (
                <CalendarDefault
                    disabledDate={
                        showPrevDays
                            ? () => false
                            : (current) => disabledDateBefore(current, dayjs.tz().subtract(1, 'day'))
                    }
                    value={value}
                    onSelect={(date) => {
                        if (value?.date() !== date.date()) {
                            setShowOverlay(false);
                        }
                        onChange?.(date);
                    }}
                    className={twMerge('absolute inset-x-0 top-full z-10 min-h-96 shadow-lg', className?.calendar)}
                />
            )}
        </div>
    );
};

export default CalendarInput;
