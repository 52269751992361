import { PropertyStatus } from "@pulppo/pulppo-models";

export const PropertyStatusMapper: {
  [key: PropertyStatus[number]]: { label: string; color: string };
} = {
  edition: { label: "Borrador", color: "#6bb9cf" },
  valuation: { label: "ACM", color: "#6bb9cf" },
  documentation: { label: "Documentación", color: "#fce02a" },
  published: { label: "Disponible", color: "#44be6d" },
  // pending: { label: "No iniciada", color: "#6bb9cf" },
  // capturing: { label: "Captando", color: "#6bb9cf" },
  completed: { label: "Vendida/Rentada", color: "#44be6d" },
  cancelled: { label: "No disponible", color: "#6b7280" },
  // duplicated: { label: "Duplicada", color: "#6bb9cf" },
};
