import { CloseCircleOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { ILocation } from '@pulppo/pulppo-models';
import { Input, Button } from 'antd';
import { ReactElement, useEffect, useState } from 'react';
import { searchLocation } from '../../utils/Typesense';
import { useDebounce } from '../../hooks/debounce';
import { PulppoDrawer } from './PulppoDrawer';
import { LocationOption } from './TextSearchMobile ';
import { useTranslate } from '@tolgee/react';

export const LocationDrawer = ({
    value = [],
    onChange = (location) => {},
    render
}: {
    value?: Array<ILocation>;
    onChange?: (value: Array<ILocation>) => void;
    render: (value: Array<ILocation>) => ReactElement;
}) => {
    const [selectedLocations, setSelectedLocations] = useState<ILocation[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [open, setOpen] = useState(false);
    const [locations, setLocations] = useState<ILocation[]>([]);
    const [recent, setRecent] = useState<Array<ILocation>>([]);
    const { t } = useTranslate('common');
    const onSelectLocation = (location) => {
        setSelectedLocations((selectedLocations = []) => {
            if (selectedLocations.some((loc) => loc.id === location.id))
                return selectedLocations.filter((loc) => loc.id !== location.id);
            else return [...selectedLocations, location];
        });
    };
    useDebounce(
        () => {
            if (searchTerm) {
                searchLocation(searchTerm).then((res) => setLocations(res.hits.map((hit) => hit.document) as any[]));
            }
        },
        500,
        [searchTerm]
    );

    useEffect(() => {
        setRecent(
            JSON.parse(localStorage?.getItem('recent_locations') || '[]')
                ?.filter((l) => l.type && l.name && l.id)
                .slice(0, 2)
        );
    }, [open, selectedLocations]);
    useEffect(() => {
        setSelectedLocations(
            value?.map((loc) => ({
                ...loc,
                id: loc.neighborhood?.id || loc?.city?.id || loc?.state?.id
            })) || []
        );
    }, [value]);

    return (
        <div>
            <div
                onClick={() => {
                    setOpen(true);
                }}
            >
                {render(selectedLocations)}
            </div>

            <PulppoDrawer visible={open} onClose={() => setOpen(false)} title={'Buscador'}>
                <div className="-mx-2 -my-4 flex h-full flex-col gap-3">
                    <p className="flex-0 shrink-0 text-pulppo-status-dark-disabled">
                        {`Busca por ${t('neighborhood', 'colonia')}, ${t('city', 'ciudad')} o ${t('state', 'estado')}`}
                    </p>
                    <div className="flex-0 shrink-0">
                        <Input
                            allowClear={true}
                            value={searchTerm}
                            className="h-11 w-full rounded-none border border-solid border-gray-300 px-4 text-black"
                            placeholder="Buscar"
                            onChange={(ev) => setSearchTerm(ev.target.value)}
                            suffix={<SearchOutlined className="text-xl text-black" />}
                        />
                    </div>

                    {selectedLocations?.length ? (
                        <div>
                            <div className="flex-0 flex shrink-0 flex-wrap items-center gap-2">
                                {selectedLocations.map((loc) => (
                                    <div
                                        key={loc.id}
                                        onClick={() => onSelectLocation(loc)}
                                        className="flex h-6 cursor-pointer items-center justify-center gap-2 bg-black px-2 text-sm text-white"
                                    >
                                        <p>{loc.name?.split(',')?.[0]}</p>
                                        <CloseCircleOutlined className="text-base" />
                                    </div>
                                ))}
                            </div>
                            <div className="mt-3 h-px w-full bg-gray-200"></div>
                        </div>
                    ) : (
                        <></>
                    )}

                    {recent?.length ? (
                        <div className="flex-0 shrink-0">
                            <div className="flex gap-4">
                                <p className="text-pulppo-status-dark-disabled">Recientes</p>
                                <div className="flex flex-wrap gap-2">
                                    {recent.map((loc) => (
                                        <div
                                            key={loc.id}
                                            onClick={() => onSelectLocation(loc)}
                                            className="flex h-6 cursor-pointer items-center justify-center gap-2 border border-solid border-gray-300 bg-white px-2 text-sm text-pulppo-status-dark-disabled"
                                        >
                                            <p>{loc.name}</p>
                                            <PlusCircleOutlined className="text-base" />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="mt-3 h-px w-full bg-gray-200"></div>
                        </div>
                    ) : (
                        <></>
                    )}

                    <div className="flex-1">
                        {' '}
                        <p className="mb-2 text-pulppo-status-dark-disabled">
                            Ubicaciones {locations?.length === 0 && <span> | Sin resultados</span>}
                        </p>
                        <div className="flex shrink-0 flex-col gap-2 overflow-y-auto">
                            {locations?.map((location) => (
                                <LocationOption
                                    selected={selectedLocations?.some((elem) => elem.id === location.id)}
                                    onClick={() => onSelectLocation(location)}
                                    location={location}
                                    key={location.id}
                                />
                            ))}
                        </div>{' '}
                    </div>

                    <div className="flex w-full gap-3">
                        <Button
                            className="h-10 flex-1 font-medium"
                            disabled={selectedLocations?.length === 0}
                            onClick={() => setSelectedLocations([])}
                        >
                            Borrar
                        </Button>
                        <Button
                            className="h-10 flex-1 font-medium"
                            type="primary"
                            onClick={() => {
                                onChange(selectedLocations);
                                setOpen(false);
                            }}
                        >
                            Buscar
                        </Button>
                    </div>
                </div>
            </PulppoDrawer>
        </div>
    );
};
