import GenericMetricView from './GenericMetricView';
import { MetricType } from '@pulppo/pulppo-models/build/enums/MetricType';
import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { getMetricTitle } from '../../utils/ConstantsMetrics';
import DrawerMetricChartTeam from './DrawerMetricChartTeam';

interface Props {
    show: boolean;
    onClose: () => void;
    metricType: MetricType;
    agentId?: string;
}

const DrawerMetricView = ({ show, onClose, metricType, agentId }: Props) => {
    if (!agentId) {
        return <DrawerMetricChartTeam type={metricType} show={show} onClose={onClose} />;
    }
    return (
        <PulppoDrawer title={getMetricTitle(metricType, 'long')} visible={show} onClose={onClose}>
            <GenericMetricView type={metricType} agentId={agentId} />
        </PulppoDrawer>
    );
};

export default DrawerMetricView;
