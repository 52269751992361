import { useState } from 'react';
import formatNumber from '../../utils/numberFormatter';
import { Button } from 'antd';
import { IProperty } from '@pulppo/pulppo-models';
import { PulppoMap, PulppoMarker } from '../Elements/PulppoMap';
import { SearchOutlined } from '@ant-design/icons';
import { parsePrice } from '../../utils/parser';
import mapboxgl from 'mapbox-gl';

const PropertyMap = ({
    properties = [] as Array<IProperty>,
    count,
    center = undefined as mapboxgl.LngLatLike | undefined,
    selectedProperties = [],
    hoveredProperty = null,
    onClick = (prop) => {},
    searchArea = (coordinates: Array<Array<number>>) => {},
    refreshMap
}) => {
    const [map, setMap] = useState(null);

    return (
        <>
            {properties && (
                <PulppoMap
                    refreshMap={refreshMap}
                    setExternalMap={setMap}
                    center={center}
                    bounds={
                        properties?.length &&
                        (['min', 'max'].map((operation) =>
                            [0, 1].map((i) =>
                                Math[operation](
                                    ...properties
                                        .filter((prop) => prop.address?.location?.coordinates?.[0])
                                        .map((prop) => prop.address?.location.coordinates[i])
                                )
                            )
                        ) as [[number, number], [number, number]])
                    }
                    containerStyle={{
                        height: 'calc(100vh - 175px)',
                        width: '100%'
                    }}
                >
                    <div className="absolute left-6 top-6 z-10 flex w-full gap-4">
                        <Button
                            onClick={() => {
                                const coordinates = map?.getBounds();
                                searchArea([
                                    coordinates.getNorthWest().toArray(),
                                    coordinates.getNorthEast().toArray(),
                                    coordinates.getSouthEast().toArray(),
                                    coordinates.getSouthWest().toArray()
                                ]);
                            }}
                            className="flex h-12 items-center justify-center gap-2 rounded-md bg-light-background font-medium shadow-md shadow-gray-300"
                        >
                            <p>Buscar en este área</p>
                            <SearchOutlined />
                        </Button>
                        <div className="flex cursor-default items-center justify-center rounded-md bg-notification-green px-4 text-center text-base font-bold text-strong-green shadow-md shadow-gray-300">
                            <p>
                                {count > 0
                                    ? `${parsePrice({
                                          price: properties?.length,
                                          startStr: ' '
                                      })} de ${parsePrice({
                                          price: count,
                                          startStr: ' '
                                      })} propiedades`
                                    : 'No hay propiedades en esta zona'}
                            </p>
                        </div>
                    </div>
                    {properties.map((property) => (
                        <PulppoMarker
                            onClick={() => {
                                onClick(property);
                            }}
                            draggable={false}
                            coordinates={property.address?.location?.coordinates}
                            key={property._id as string}
                        >
                            <div
                                className={`marker text-small cursor-pointer ${
                                    hoveredProperty && hoveredProperty === property._id
                                        ? 'marker-black z-50'
                                        : `${
                                              selectedProperties.find((prop) => prop._id === property._id)
                                                  ? `marker-${
                                                        property.listing?.operation === 'sale' ? 'green' : 'green'
                                                    }-selected`
                                                  : `marker-${
                                                        property.listing?.operation === 'sale' ? 'green' : 'blue'
                                                    }`
                                          }`
                                }`}
                            >
                                {!!property.listing?.price
                                    ? `${property.listing?.price?.currency} ${formatNumber(
                                          property.listing?.price?.price
                                      )}`
                                    : ''}
                            </div>
                        </PulppoMarker>
                    ))}
                </PulppoMap>
            )}
        </>
    );
};

export default PropertyMap;
