import { createContext, useState } from 'react';

export const PropertyFormContext = createContext<{
    formData: any;
    setFormData: any;
}>(null);

export const PropertyFormContextProvider = ({ children }) => {
    const [formData, setFormData] = useState({});

    return (
        <PropertyFormContext.Provider
            value={{
                formData,
                setFormData
            }}
        >
            {children}
        </PropertyFormContext.Provider>
    );
};
