import { CheckCircleFilled, CloseCircleFilled, InfoCircleFilled, LinkOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Divider, Form, Input, Modal, Row, Select, Table, Typography } from 'antd';
import axios from '../../utils/axios';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';
import es from 'react-phone-number-input/locale/es.json';
import { CountryCode } from 'libphonenumber-js/types';
import { api_url, fetcher } from '../../helpers/fetcher';
import { IProperty } from '@pulppo/pulppo-models';
import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { PulppoLoader } from '../Elements/PulppoLoader';
import { scrapUrl } from '../../services/mls-scrapper';
import { useTranslate } from '@tolgee/react';
import useSWR from 'swr';
import useMLSInfo from '../../hooks/useMLSInfo';

const Phone = ({ onChange }) => {
    const [selectedCountry, setSelectedCountry] = useState(process.env.NEXT_PUBLIC_COUNTRY_CODE || 'MX');
    const [value, setValue] = useState(null);
    const [searchTerm, setSearchTerm] = useState(undefined);
    return (
        <div className="h-11">
            <div className="flex w-full items-center">
                <Select
                    // showSearch
                    placeholder="Elija un país"
                    optionFilterProp="children"
                    searchValue={searchTerm}
                    dropdownRender={(menu) => (
                        <div className="px-5">
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <Input
                                autoComplete="off"
                                // className="px-1"
                                placeholder="Buscar pais"
                                onChange={(ev) => {
                                    setSearchTerm(ev.currentTarget.value);
                                }}
                            />
                        </div>
                    )}
                    filterOption={(input, option) => {
                        // return true;
                        return (
                            option.title
                                ?.toLowerCase()
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                                .indexOf(
                                    searchTerm
                                        .toLowerCase()
                                        .normalize('NFD')
                                        .replace(/[\u0300-\u036f]/g, '')
                                ) >= 0
                        );
                    }}
                    dropdownMatchSelectWidth={200}
                    className="phone rounded"
                    style={{ width: '60px' }}
                    value={selectedCountry}
                    onSelect={(e) => {
                        setSelectedCountry(e);
                        setSearchTerm('');
                    }}
                >
                    {getCountries().map((country) => (
                        <Select.Option value={country} key={country} title={es[country]}>
                            <img
                                width={20}
                                alt={country}
                                style={{ minWidth: '20px' }}
                                src={`https://catamphetamine.gitlab.io/country-flag-icons/3x2/${country}.svg`}
                            />
                            {country != selectedCountry && <div className="ml-10px">{es[country]}</div>}
                        </Select.Option>
                    ))}
                </Select>
                <div>
                    <Input
                        className={value === '' ? 'ml-10px w-full border-red-500' : 'ml-10px w-full'}
                        value={value}
                        onChange={(ev) => {
                            const value = `+${getCountryCallingCode(
                                selectedCountry as CountryCode
                            )}${ev.currentTarget.value}`;
                            setValue(ev.currentTarget.value);
                            onChange({
                                ...ev,
                                currentTarget: { value: value }
                            });
                        }}
                        prefix={
                            <span className="text-secondary-color">
                                +{getCountryCallingCode(selectedCountry as CountryCode)}
                            </span>
                        }
                    />
                </div>
            </div>
            {value === '' && <span className="text-sm text-red-500">Este campo es requerido</span>}
        </div>
    );
};

const CustomInput = ({ onChange = (_) => {} }) => {
    const [value, setValue] = useState(null as any);
    return (
        <div className="h-11">
            {' '}
            <Input
                className={value === '' ? 'border-red-500' : ''}
                value={value}
                onChange={(ev) => {
                    setValue(ev.currentTarget.value);
                    onChange(ev);
                }}
            />
            {value === '' && <span className="text-sm text-red-500"> Este campo es requerido</span>}
        </div>
    );
};

interface Props {
    show: boolean;
    multi?: boolean;
    setShow: (state: boolean) => void;
    onFinished?: (properties?: Array<IProperty>) => void | Promise<void>;
    noOportunity?: boolean;
    sendRawData?: boolean;
}

const ImportProperty = ({ show, setShow, onFinished = () => {}, multi = true }: Props) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [urls, setUrls] = useState<string[]>(['']);
    const { t } = useTranslate('common');
    const resetComponent = () => {
        setLoading(false);
        setErrors([]);
        setUrls(['']);
        refresh();
    };
    const {
        isLoading,
        refresh,
        state: { health }
    } = useMLSInfo();
    const getErrorMessage = (errors: string[]): ReactElement => {
        if (errors.length === 3) {
            return (
                <p>
                    Actualmente, no es posible importar propiedades desde los portales.{' '}
                    <b>Vuelve a intentarlo más tarde.</b>
                </p>
            );
        } else if (errors.includes('import_failed')) {
            return (
                <p>
                    No hemos podido importar la propiedad del portal.
                    <b>Vuelve a intentarlo más tarde.</b>
                </p>
            );
        } else {
            return (
                <p>
                    {`Actualmente, no es posible importar propiedades desde ${errors.map((el) => el[0].toUpperCase() + el.substring(1)).join(', ')}, estamos trabajando en
                    solucionarlo`}
                    . <b>Puedes continuar con otros portales.</b>
                </p>
            );
        }
    };
    useEffect(() => {
        const aux: string[] = [];
        for (const source of ['mercadolibre', 'easybroker', 'inmuebles24']) {
            if (health?.[source] === 'unhealthy') aux.push(source);
            if (aux.length) setErrors(aux);
        }
    }, [health]);

    const handleSubmit = async () => {
        setLoading(true);
        const validUrls = urls.map((url) => url.trim()).filter((url) => url !== '');

        if (!validUrls.length) {
            setLoading(false);
            return;
        }
        try {
            const responses = await Promise.all(validUrls.map((url) => scrapUrl(url)));
            const properties = responses.flat();
            if (properties?.length) {
                Modal.success({
                    title: 'Propiedades importadas correctamente'
                });
                onFinished(properties);
                resetComponent();
            }
        } catch (error) {
            setErrors(['import_fail']);
        } finally {
            setLoading(false);
        }
    };
    return (
        <PulppoDrawer
            visible={show}
            title="Importar anuncios"
            closable={true}
            onClose={() => {
                resetComponent();
                setShow(false);
            }}
        >
            <div className="h-full">
                <PulppoLoader loading={loading} className="h-full">
                    <div className="flex h-full flex-col">
                        {!!errors?.length && (
                            <div className="mb-4 flex w-full items-center gap-4 bg-status-light-error p-4 text-status-dark-error">
                                <CloseCircleFilled className="text-xl" />
                                <>{getErrorMessage(errors)}</>
                            </div>
                        )}
                        <Typography.Text className="">
                            {t(
                                'property_import_placeholder',
                                'Pega aquí las ligas de tus anuncios de Inmuebles 24, EasyBroker o Mercadolibre'
                            )}
                        </Typography.Text>
                        <Form className="mt-4 flex flex-1 flex-col justify-between" onFinish={handleSubmit}>
                            <div>
                                {urls.map((url, index) => (
                                    <Form.Item
                                        key={`url-${index}`}
                                        className="flex-1"
                                        name={`url-${index}`}
                                        rules={[
                                            {
                                                message: t(
                                                    'property_import_error_message',
                                                    'Inserte una liga válida de I24, VA, EB, EA o Meli'
                                                ),
                                                validator: (rule, value) => {
                                                    if (
                                                        !value ||
                                                        value.includes('inmuebles24.com') ||
                                                        value.includes('easybroker.com') ||
                                                        value.includes('easyaviso.com') ||
                                                        value.includes('vivanuncios.com') ||
                                                        value.includes('mercadolibre') ||
                                                        value.includes('zonaprop.com')
                                                    ) {
                                                        return Promise.resolve();
                                                    } else {
                                                        return Promise.reject();
                                                    }
                                                },

                                                validateTrigger: 'onChange',
                                                required: false
                                            }
                                        ]}
                                    >
                                        <Input
                                            className="h-10 rounded-none"
                                            placeholder={t(
                                                'property_import_placeholder',
                                                'Pega aquí las ligas de tus anuncions de Inmuebles 24, Mercado Libre o EasyBroker'
                                            )}
                                            value={url}
                                            onChange={(e) => {
                                                setUrls((prevUrls) => {
                                                    const newUrls = [...prevUrls];
                                                    newUrls[index] = e.target.value;
                                                    return newUrls;
                                                });
                                            }}
                                        />
                                    </Form.Item>
                                ))}

                                {urls.length < 4 && (
                                    <Button
                                        type="primary"
                                        className="h-10 w-full"
                                        onClick={() => setUrls([...urls, ''])}
                                    >
                                        {t('Agregar liga')}
                                    </Button>
                                )}
                            </div>

                            <div className="w-full">
                                <Button type="primary" className="h-10 w-full" htmlType="submit">
                                    Continuar
                                </Button>
                            </div>
                        </Form>
                    </div>
                </PulppoLoader>
            </div>
        </PulppoDrawer>
    );
};

export default ImportProperty;
