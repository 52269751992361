import { LoadingOutlined, PaperClipOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Typography, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { ImageUploaderService } from '../../services/ImageUploaderService';
import Note from '../Elements/Note';
import { useEffect, useState } from 'react';
import { INote, IProperty } from '@pulppo/pulppo-models';
import useUser from '../../hooks/useUser';
import { MapUserToSimpleUser } from '../../utils/MapContactToSimpleUser';
import axios from '../../utils/axios';
import { api_url, fetcher } from '../../helpers/fetcher';
import { LoadingButton } from '../Elements/ButtonLoading';
import useSWR from 'swr';

export const ContractNotes = ({
    property,
    className = '',
    refresh,
    showTitle = true,
    disabled = false
}: {
    property: IProperty;
    className?: string;
    refresh?: number;
    showTitle?: boolean;
    disabled?: boolean;
}) => {
    const user = useUser((u) => u.user);
    const [loading, setLoading] = useState(false);
    const { data: notes, mutate: setNotes } = useSWR<INote[]>(
        property?._id && `${api_url}/legals/${property?._id}/notes`,
        fetcher,
        {
            fallbackData: property?.contract?.notes?.sort(
                (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            )
        }
    );
    const [note, setNote] = useState<INote>({
        content: '',
        user: user,
        createdAt: new Date(),
        files: []
    } as any);
    useEffect(() => {
        if (refresh) setNotes();
    }, [refresh]);
    const createNote = async () => {
        await axios.post(`${api_url}/property/${property._id}/contract/notes`, note);
        setNotes([note, ...notes]);
        setNote({} as any);
    };
    return (
        <div className={className}>
            <div className="mt-4 flex h-full w-full flex-col justify-between">
                <div className="mx-auto w-full">
                    {showTitle && (
                        <>
                            <Typography.Title level={4} className="mb-0">
                                Notas
                            </Typography.Title>
                        </>
                    )}
                    <p className="font-light text-pulppo-primary-gray">
                        Puedes dejar comentarios para nuestro equipo legal y adjuntar archivos.
                    </p>
                    <div className="mt-4 flex items-stretch gap-2">
                        <Input
                            value={note.content}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    createNote();
                                }
                            }}
                            onChange={(val) => {
                                setNote((note) => ({
                                    ...note,
                                    createdAt: new Date(),
                                    creator: MapUserToSimpleUser(user),
                                    content: val.target?.value
                                }));
                            }}
                            className="flex-1 rounded-none py-2"
                            placeholder="Escribe el una nota aquí..."
                            disabled={disabled}
                        />
                        <Upload
                            className="p-0"
                            multiple={false}
                            showUploadList={false}
                            customRequest={async ({ file, ...events }) => {
                                ImageUploaderService.upload(
                                    file as RcFile,
                                    `property/${property._id}/legal/${(file as RcFile).name.replace(/\s/gi, '_')}`,
                                    events,
                                    true
                                ).catch((err) => console.error(`No se pudieron subir los archivos ${err}`));
                            }}
                            onChange={(info) => {
                                const { status } = info.file;
                                if (status === 'uploading') {
                                    setLoading(true);
                                }

                                if (status === 'done') {
                                    const newFileName = info.file.response;
                                    setNote((n) => ({
                                        ...n,
                                        createdAt: new Date(),
                                        creator: MapUserToSimpleUser(user),
                                        files: [...(n.files || []), newFileName]
                                    }));

                                    setLoading(false);
                                } else if (status === 'error') {
                                    Modal.error({
                                        title: `${info.file.name} file upload failed.`
                                    });
                                }
                            }}
                            disabled={disabled}
                        >
                            <Button className="h-auto rounded-none py-2" disabled={disabled}>
                                {loading ? <LoadingOutlined /> : <PaperClipOutlined />}
                            </Button>
                        </Upload>
                        <LoadingButton onClick={createNote} className="h-auto rounded-none" disabled={disabled}>
                            <SendOutlined />
                        </LoadingButton>
                    </div>
                    {note.files?.length ? (
                        <div className="mt-4 flex flex-wrap items-center gap-2">
                            {note.files.map((file) => (
                                <div
                                    key={file}
                                    onClick={() => {
                                        const a = document.createElement('a');
                                        a.href = file;
                                        a.download = decodeURIComponent(file).split('/').pop();
                                        document.body.appendChild(a);
                                        a.target = '_blank';
                                        a.click();
                                        document.body.removeChild(a);
                                    }}
                                    className="shrink-0 cursor-pointer rounded-full bg-gray-200 px-4 py-1"
                                >
                                    <PaperClipOutlined className="mr-2" />
                                    {decodeURIComponent(file).split('/').pop()}
                                </div>
                            ))}
                        </div>
                    ) : null}
                    <div className="mt-2 flex flex-col gap-2">
                        {notes?.map((note) => <Note key={`note-${note?._id || note?.content?.trim()}`} {...note} />)}
                    </div>
                </div>
            </div>
        </div>
    );
};
