import { twMerge } from 'tailwind-merge';
import { formatCurrency } from '../../utils/numberFormatter';

export const LoyaltyProgress = ({ className = '', comission, max, level, points = 15 }) => {
    return (
        <div className="px-5">
            <p className="text mb-1">
                ${formatCurrency(comission)} alcanzado <span className="muted text-xs">(últimos 6 meses)</span>
            </p>
            <div className="relative">
                <div className={twMerge('h-6 w-full bg-pulppo-primary-gray opacity-30', className)}></div>
                <div
                    className="absolute left-0 top-0 z-10 h-full "
                    style={{
                        width: `${Math.min(100, (100 * comission) / max)}%`,
                        background: level === 'elite' ? '#CDB27B' : level === 'professional' ? '#7D7D7D' : '#B6703D'
                    }}
                />
                <div className="absolute left-0 top-0 flex h-full w-full items-center justify-between px-5 text-pulppo-primary-gray ">
                    {new Array(points).fill(1).map((el, i) => (
                        <div
                            key={`progress_${i}`}
                            className="h-2 w-2 shrink-0 rounded-full bg-pulppo-primary-gray opacity-40"
                        />
                    ))}
                    <p className="text-xs">${formatCurrency(max)}</p>
                </div>
            </div>
        </div>
    );
};
