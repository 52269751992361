import { CheckOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { IProperty, ISimpleProperty } from '@pulppo/pulppo-models';
import { Button, Card } from 'antd';
import { ReactElement, useEffect, useState } from 'react';
import { NextImage } from './NextImage';
import { Carousel } from './Carousel';
import { PropertyMainMetrics } from '../PropertyDetail/PropertyDetailMain';
import { parsePrice } from '../../utils/parser';
import useChat from '../../hooks/useChat';
import { api_url, instance } from '../../helpers/fetcher';
import dayjs from 'dayjs';
import { twMerge } from 'tailwind-merge';
import { useTranslate } from '@tolgee/react';
import useUser from '../../hooks/useUser';

export const PropertyCard = ({
    property,
    loading,
    onClick,
    actions = null,
    badges = [],
    style = {},
    showControls = false,
    checkbox = false,
    onCheck = () => {},
    checked = false,
    onHover = () => {},
    showValuationTag = false,
    tag = <></>,
    note = null,
    showContactInfo = false,
    banner = null
}: {
    property: IProperty | ISimpleProperty;
    loading?: boolean;
    onClick?: any;
    style?: any;
    actions?: any;
    showControls?: boolean;
    badges?: { label: string; color: string }[];
    checkbox?: boolean;
    onCheck?: (checked: boolean) => void;
    checked?: boolean;
    onHover?: (hovered: boolean) => void;
    showValuationTag?: any;
    tag?: ReactElement;
    note?: ReactElement;
    showContactInfo?: boolean;
    banner?: {
        text: string;
        className: string;
    };
}) => {
    const [hover, setHover] = useState(false);
    useEffect(() => {
        if (onHover) {
            onHover(hover);
        }
    }, [hover]);
    const setChatContact = useChat((store) => store.setChatContact);
    const { t } = useTranslate('common');

    const [gettingPhone, setGettingPhone] = useState(false);
    const { user } = useUser();
    if (!property) return <></>;
    const showEmpty = ['acm', 'edition'].includes((property as any)?.status?.last);
    const agent = (property as IProperty)?.agentSeller || property?.agent;
    const company = property.company || (property as IProperty).import?.announcers?.[0];
    const street =
        user?.company?._id?.toString() === company?._id?.toString() && !!property?.address?.street
            ? `${property.address.street}${
                  property.address.floor || property.address.apartment
                      ? ` (${[property.address.floor, property.address.apartment].filter(Boolean).join(' ')})`
                      : ''
              }`
            : property?.address?.street;
    return (
        <Card
            hoverable
            loading={loading}
            className="h-full w-full"
            bodyStyle={{
                padding: 0,
                borderColor: checked ? '#e0e4e5de' : 'white',
                backgroundColor: checked ? '#e0e4e5de' : 'white'
            }}
            style={{
                ...style
            }}
            cover={
                <div
                    onClick={onClick}
                    className="relative h-auto"
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    <Carousel
                        minHeight="100px"
                        items={property.pictures?.length ? property.pictures : [{ url: '/empty_bg.png' }]}
                        hoverable={true}
                        showControls={hover || showControls}
                        renderItem={(image) => (
                            <NextImage
                                wrapperStyle={{
                                    width: '100%',
                                    height: '100%'
                                }}
                                loading={'lazy'}
                                showPreview={false}
                                width={500}
                                style={{
                                    objectFit: 'cover',
                                    width: '100%',
                                    height: '100%'
                                }}
                                className={`aspect-3/2 w-full ${checked ? 'opacity-30' : ''}`}
                                alt={property?.listing?.title}
                                src={image?.url}
                            />
                        )}
                    />
                    <div className="absolute right-3 top-3 h-auto w-auto">{tag}</div>
                    {banner && (
                        <div
                            className={twMerge(
                                'absolute bottom-[30px] z-30 flex h-[35px] w-full items-center justify-center bg-opacity-80 px-1 font-medium',
                                banner?.className
                            )}
                        >
                            <p>{banner?.text}</p>
                        </div>
                    )}
                    <div className="absolute left-0 top-0 z-20 flex flex-col gap-2 p-3">
                        <div className="flex items-center gap-2">
                            {checkbox && (
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onCheck(!checked);
                                    }}
                                >
                                    <div
                                        className={`flex h-7 w-7 items-center justify-center ${
                                            checked ? 'bg-black' : 'border border-solid border-gray-200 bg-white'
                                        }`}
                                    >
                                        {checked && <CheckOutlined className="p-1 text-lg text-white" />}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="absolute bottom-10 left-2 flex flex-col-reverse gap-2 text-sm uppercase">
                        {badges.filter(Boolean).map((badge) => (
                            <div className="w-fit rounded-sm bg-gray-100 px-2" key={badge.label}>
                                {badge.label}
                            </div>
                        ))}
                    </div>
                    <p className="text-md h-[30px] truncate bg-black p-1 text-center font-medium uppercase text-white">
                        {t(property.listing?.operation)}
                        {property.type ? ` DE ${t(property.type)}` : ''}
                    </p>
                </div>
            }
        >
            <div onClick={onClick} className="cursor-pointer px-3 pb-1">
                <p className="truncate text-base font-medium capitalize">
                    {(street || property.listing?.title || 'Sin dirección')?.toLowerCase()}{' '}
                </p>
                <p className="h-6 truncate text-sm text-pulppo-status-dark-disabled">
                    {property?.address?.neighborhood?.name ||
                    property?.address?.city?.name ||
                    property?.address?.subdivision?.name ||
                    property?.address?.subsubdivision?.name
                        ? `${property?.address?.subsubdivision?.name ? property?.address?.subsubdivision?.name + ',' : ''} ${property?.address?.subdivision?.name ? property?.address?.subdivision?.name + ',' : ''} ${
                              property?.address?.neighborhood?.name ? property?.address?.neighborhood?.name + ',' : ''
                          } ${property?.address?.city?.name}`
                        : 'Sin colonia'}
                </p>
                <p className="h-6 truncate text-sm capitalize text-pulppo-status-dark-disabled">{`${
                    (property as any)?.development?.name?.toLowerCase() || (showEmpty ? 'Sin nombre de desarollo' : '')
                }`}</p>
                {note ? (
                    note
                ) : (
                    <>
                        {' '}
                        <div className="my-1 flex w-full items-center gap-3">
                            <p className="text-base font-medium">
                                {property?.listing?.price?.price
                                    ? parsePrice({
                                          price: property.listing.price.price,
                                          startStr: `${
                                              property.listing.price.currency ||
                                              process.env.NEXT_PUBLIC_PRIMARY_CURRENCY
                                          } `
                                      })
                                    : 'Sin precio'}
                            </p>
                            {showValuationTag && (
                                <div
                                    className={twMerge(
                                        'flex items-center justify-center rounded-sm border border-solid px-2 py-1 text-xs',
                                        (property as any)?.valuation?.result === 'low'
                                            ? 'border-strong-green text-strong-green'
                                            : (property as any)?.valuation?.result === 'medium'
                                              ? 'border-strong-yellow text-strong-yellow'
                                              : 'border-strong-red text-strong-red'
                                    )}
                                >
                                    {`${
                                        (property as any)?.valuation?.value
                                            ? parsePrice({
                                                  price: Math.round((property as any)?.valuation?.value),
                                                  startStr: ' '
                                              })
                                            : 'Sin precio'
                                    } ${process.env.NEXT_PUBLIC_PRIMARY_CURRENCY} / m2`}
                                </div>
                            )}
                        </div>
                        <div className="mb-1 h-px bg-pulppo-secondary-gray-disabled opacity-40"></div>
                        <div className="flex h-7 w-full items-center justify-center px-5">
                            <PropertyMainMetrics
                                showEmpty={showEmpty}
                                property={property as IProperty}
                                className="w-full justify-evenly"
                                size="small"
                            />
                        </div>
                        <div className="my-1 h-px bg-pulppo-secondary-gray-disabled opacity-40"></div>
                        {(property as any)?.mls || (property as any).source === 'mls' ? (
                            <div className="flex w-full items-center justify-between">
                                <div className="shrink-1 flex-1">
                                    <p className="font-medium capitalize">{`${
                                        property?.company?.name?.toLowerCase() || 'Sin inmobiliaria'
                                    }`}</p>
                                    <p className="text-sm text-pulppo-status-dark-disabled">
                                        {`Actualizada el ${dayjs(property?.updatedAt).tz().format('DD/MM/YYYY')}`}
                                    </p>
                                </div>

                                {showContactInfo && (
                                    <div className="flex shrink-0 items-center gap-1">
                                        {(property as any)?.import?.source === 'inmuebles 24' &&
                                        (property as IProperty)?.import?.url ? (
                                            <Button
                                                href={`${(property as any)?.import?.url}`}
                                                target="_blank"
                                                className="flex h-10 w-10 items-center justify-center p-0 hover:shadow-md"
                                            >
                                                <img src="/inmuebles.png" alt="inmuebles" />
                                            </Button>
                                        ) : (
                                            <></>
                                        )}
                                        {(property as IProperty)?.import?.source === 'easybroker' &&
                                        (property as IProperty)?.import?.url ? (
                                            <Button
                                                href={`${(property as IProperty).import?.url}`}
                                                target="_blank"
                                                className="flex h-10 w-10 items-center justify-center border-none p-0 hover:shadow-md"
                                            >
                                                <img
                                                    src="/easybroker.svg"
                                                    className="h-10 w-10 object-cover"
                                                    alt="easybroker"
                                                />
                                            </Button>
                                        ) : (
                                            <></>
                                        )}
                                        {(property as IProperty)?.import?.source === 'mercadolibre' &&
                                        (property as IProperty)?.import?.url ? (
                                            <Button
                                                href={`${(property as IProperty).import?.url}`}
                                                target="_blank"
                                                className="flex h-10 w-10 items-center justify-center rounded-none border-none p-0 hover:shadow-md"
                                            >
                                                <img
                                                    src="/mercadolibre_logo.png"
                                                    className="h-10 w-10 object-contain"
                                                    alt="easybroker"
                                                />
                                            </Button>
                                        ) : (
                                            <></>
                                        )}
                                        <Button
                                            loading={gettingPhone}
                                            disabled={!company?.phone}
                                            onClick={async (ev) => {
                                                ev.stopPropagation();
                                                setGettingPhone(true);
                                                const contact = await instance
                                                    .post(`${api_url}/contact`, {
                                                        firstName: company.name,
                                                        lastName: '',
                                                        emails: [
                                                            {
                                                                email: company.email
                                                            }
                                                        ],
                                                        phones: [
                                                            {
                                                                phone: company.phone,
                                                                plain: company.phone.replace(/\D/gi, '')
                                                            }
                                                        ]
                                                    })
                                                    .then((r) => r.data)
                                                    .catch((err) => console.error(err))
                                                    .finally(() => {
                                                        setGettingPhone(false);
                                                    });
                                                setChatContact({
                                                    ...contact,
                                                    message: `Hola, te escribo para consultarte por esta propiedad:  ${
                                                        (property as any)?.import?.url || property?.address?.street
                                                    }`
                                                });
                                            }}
                                            target="_blank"
                                            className="flex h-10 w-10 items-center justify-center p-0 text-lg"
                                        >
                                            {!gettingPhone && <WhatsAppOutlined />}
                                        </Button>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="flex w-full flex-1 items-center justify-between">
                                <div className="w-3/4">
                                    <p className="truncate font-semibold capitalize">{`${agent?.firstName?.toLowerCase() || ''} ${agent?.lastName?.toLowerCase() || ''}`}</p>
                                    <div className="flex items-center">
                                        <p className="truncate text-xs text-pulppo-status-dark-disabled">
                                            {property?.company?.name}
                                        </p>
                                        <p className="ml-1 text-sm font-medium">/</p>
                                        <img
                                            src={
                                                `${process.env.NEXT_PUBLIC_LOGO_NEGRO}?reso=256x128&fit=inside` ||
                                                '/pulppo-iso.svg'
                                            }
                                            alt="Pulppo"
                                            className="ml-1 h-4"
                                        />
                                    </div>
                                </div>
                                {showContactInfo ? (
                                    <div className="flex items-center gap-3">
                                        <Button
                                            loading={gettingPhone}
                                            disabled={!agent?.phone}
                                            onClick={async (ev) => {
                                                ev.stopPropagation();
                                                setGettingPhone(true);
                                                const contact = await instance
                                                    .post(`${api_url}/contact`, {
                                                        firstName: agent?.firstName,
                                                        lastName: agent?.lastName,
                                                        emails: [
                                                            {
                                                                email: agent?.email
                                                            }
                                                        ],
                                                        phones: [
                                                            {
                                                                phone: agent?.phone,
                                                                plain: agent?.phone.replace(/\D/gi, '')
                                                            }
                                                        ]
                                                    })
                                                    .then((r) => r.data)
                                                    .catch((err) => console.error(err))
                                                    .finally(() => {
                                                        setGettingPhone(false);
                                                    });
                                                setChatContact({
                                                    ...contact,
                                                    firstName: agent?.firstName,
                                                    lastName: agent?.lastName,
                                                    message: `Hola, te escribo para consultarte por esta propiedad:  ${
                                                        (property as any)?.import?.url || property?.address?.street
                                                    }`
                                                });
                                            }}
                                            target="_blank"
                                            className="flex h-10 w-10 items-center justify-center p-0 text-lg"
                                        >
                                            {!gettingPhone && <WhatsAppOutlined />}
                                        </Button>
                                    </div>
                                ) : (
                                    <p className="shrink-0 text-xs uppercase">{(property as any)?.internalId}</p>
                                )}
                            </div>
                        )}
                        <div className="my-1 h-px bg-pulppo-secondary-gray-disabled opacity-40"></div>
                        {actions}
                    </>
                )}
            </div>
        </Card>
    );
};
