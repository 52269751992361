import { AgentCourseStatus } from '@pulppo/pulppo-models/build/@types/pulppo-models/enums/enums';
import { ICourse, IProperty, ISimpleUser, IVisit } from '@pulppo/pulppo-models';

declare global {
    interface Array<T> {
        reverseIfTrue(isReverse: boolean): T[];
    }
}

export interface Property {
    img: string;
    direction: string;
    price: string;
    status: string;
    stickers: Array<string>;
    type: string;
}

export interface IResponse<T> {
    success: boolean;
    message: string;
    data?: T;
    error?: {
        details?: any;
    };
}

export interface ICourseAgent {
    _id: string;
    idLastClass?: string;
    status: AgentCourseStatus;
}

export const STATUS_NOT_STARTED = 'notStarted';

export interface ICourseWithStatus extends ICourse {
    progress: {
        ongoing: boolean;
        status: AgentCourseStatus | typeof STATUS_NOT_STARTED;
        idLastClass?: string;
    };
}

export type SectionConsultation = 'all' | 'pending';

export type ID = IProperty['_id'];

export interface IExtendedVisit extends Omit<IVisit, 'agent'> {
    agent?: ISimpleUser;
}

export interface StepOperationProps {
    onPrevious?: () => void;
    onFinish?: () => void;
}

export type TaskType = 'search' | 'capture';
