import { NewPropertyTypes } from '@pulppo/pulppo-models/build/enums/PropertyTypes';
import { showSpaces } from './config';

export const AttributeMapper: {
    [key: string]: { [key in (typeof NewPropertyTypes)[number]]: boolean | string };
} = {
    suites: {
        PH: 'required',
        Departamento: 'required',
        Casa: 'required',
        'Casa en condominio': 'required',
        'Terreno residencial': false,
        Finca: 'required',
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': false,
        'Local en centro comercial': false,
        'Nave industrial': false,
        'Terreno comercial': false,
        'Terreno industrial': false
    },
    spaces: {
        PH: true,
        Departamento: showSpaces ? 'required' : false,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': false,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': false,
        'Local en centro comercial': false,
        'Nave industrial': false,
        'Terreno comercial': false,
        'Terreno industrial': false
    },
    referenceCode: {
        PH: true,
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': true,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': true,
        'Terreno industrial': true
    },
    bathrooms: {
        PH: 'required',
        Departamento: 'required',
        Casa: 'required',
        'Casa en condominio': 'required',
        'Terreno residencial': false,
        Finca: 'required',
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false
    },
    toilettes: {
        PH: true,
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': false,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false
    },
    parkings: {
        PH: 'required',
        Departamento: 'required',
        Casa: 'required',
        'Casa en condominio': 'required',
        'Terreno residencial': true,
        Finca: true,
        Edificio: true,
        Oficina: 'required',
        Local: 'required',
        'Casa uso de suelo': 'required',
        'Bodega comercial': true,
        'Local en centro comercial': 'required',
        'Nave industrial': true,
        'Terreno comercial': true,
        'Terreno industrial': true
    },
    totalSurface: {
        PH: 'required',
        Departamento: 'required',
        Casa: 'required',
        'Casa en condominio': 'required',
        'Terreno residencial': 'required',
        Finca: 'required',
        Edificio: true,
        Oficina: 'required',
        Local: 'required',
        'Casa uso de suelo': 'required',
        'Bodega comercial': 'required',
        'Local en centro comercial': 'required',
        'Nave industrial': 'required',
        'Terreno comercial': 'required',
        'Terreno industrial': 'required'
    },
    roofedSurface: {
        PH: 'required',
        Departamento: 'required',
        Casa: 'required',
        'Casa en condominio': 'required',
        'Terreno residencial': false,
        Finca: 'required',
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false
    },
    unroofedSurface: {
        PH: true,
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': true,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': true,
        'Terreno industrial': true
    },
    yearBuild: {
        PH: 'required',
        Departamento: 'required',
        Casa: 'required',
        'Casa en condominio': 'required',
        'Terreno residencial': true,
        Finca: 'required',
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': true,
        'Terreno industrial': true
    },
    month: {
        PH: true,
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': true,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': true,
        'Terreno industrial': true
    },
    year: {
        PH: true,
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': true,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': true,
        'Terreno industrial': true
    },
    disposition: {
        PH: true,
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': true,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': true,
        'Terreno industrial': true
    },
    orientation: {
        PH: true,
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': true,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': true,
        'Terreno industrial': true
    },
    condition: {
        PH: true,
        Departamento: 'required',
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': true,
        Finca: true,
        Edificio: true,
        Oficina: 'required',
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': true,
        'Terreno industrial': true
    },
    terrainType: {
        PH: true,
        Departamento: false,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': 'required',
        Finca: true,
        Edificio: true,
        Oficina: false,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': 'required',
        'Terreno industrial': 'required'
    },
    frontMeters: {
        PH: true,
        Departamento: false,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': true,
        Finca: true,
        Edificio: true,
        Oficina: false,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': true,
        'Terreno industrial': true
    },
    bottomMeters: {
        PH: true,
        Departamento: false,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': true,
        Finca: true,
        Edificio: true,
        Oficina: false,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': true,
        'Terreno industrial': true
    },
    catastral: {
        PH: true,
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': true,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': true,
        'Terreno industrial': true
    },
    ampliation: {
        PH: true,
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': true,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': true,
        'Terreno industrial': true
    },
    kitchen: {
        PH: true,
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': false,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false
    },
    sprinkler: {
        PH: false,
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false
    },
    floorUsage: {
        PH: true,
        Departamento: false,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': true,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': true,
        'Terreno industrial': true
    },
    buildingClasification: {
        PH: false,
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false
    },
    elevatorAmount: {
        PH: false,
        Departamento: true,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false
    },
    floorType: {
        PH: true,
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': false,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false
    },
    remodelationDate: {
        PH: true,
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': false,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false
    },
    floorsConstructed: {
        PH: true,
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': false,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false
    },
    CasaAmount: {
        PH: true,
        Departamento: false,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': false,
        Finca: true,
        Edificio: false,
        Oficina: false,
        Local: false,
        'Casa uso de suelo': false,
        'Bodega comercial': false,
        'Local en centro comercial': false,
        'Nave industrial': false,
        'Terreno comercial': false,
        'Terreno industrial': false
    },
    constructionQuality: {
        PH: true,
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': false,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false
    },
    OficinaArea: {
        Departamento: true,
        PH: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': false,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false
    },
    OficinasAmount: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: false
    },
    ocupationStatus: {
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': true,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': true,
        'Terreno industrial': true,
        PH: true
    },
    productivePropertyType: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: false
    },
    howToArrive: {
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': true,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': true,
        'Terreno industrial': true,
        PH: true
    },
    constructionStyle: {
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': false,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: true
    },
    constructionStatus: {
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': false,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: true
    },
    rentConstructionArea: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': true,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': true,
        'Terreno industrial': true,
        PH: false
    },
    surfacePerFloor: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: false
    },
    constructionYear: {
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': false,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: true
    },
    remodelationYear: {
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': false,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: true
    },
    rentAmount: {
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': false,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: true
    },
    class: {
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': true,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': true,
        'Terreno industrial': true,
        PH: true
    },
    alturaLosaALosa: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: false
    },
    columnWidth: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: false
    },
    columnDepth: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: false
    },
    widthBetweenColumns: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: false
    },
    depthBetweenColumns: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: false
    },
    finishings: {
        Departamento: false,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': false,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: true
    },
    windows: {
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': false,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: true
    },
    doorsAndClosets: {
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': false,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: true
    },
    buildingType: {
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': false,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: true
    },
    minHeight: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: false
    },
    maxHeight: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: false
    },
    patioSurface: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: false,
        Oficina: false,
        Local: false,
        'Casa uso de suelo': false,
        'Bodega comercial': true,
        'Local en centro comercial': false,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: false
    },
    privateSurface: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: false,
        Oficina: false,
        Local: false,
        'Casa uso de suelo': false,
        'Bodega comercial': true,
        'Local en centro comercial': false,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: false
    },
    OficinasSurface: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: false
    },
    manouverSurface: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: false,
        Oficina: false,
        Local: false,
        'Casa uso de suelo': false,
        'Bodega comercial': true,
        'Local en centro comercial': false,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: false
    },
    metersPerFloor: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: false
    },
    commonBathrooms: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: false
    },
    industrialParkName: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': true,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': true,
        'Terreno industrial': true,
        PH: false
    },
    wallType: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: false,
        Oficina: false,
        Local: false,
        'Casa uso de suelo': false,
        'Bodega comercial': true,
        'Local en centro comercial': false,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: false
    },
    structureType: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: false
    },
    coverType: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: false,
        Oficina: false,
        Local: false,
        'Casa uso de suelo': false,
        'Bodega comercial': true,
        'Local en centro comercial': false,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: false
    },
    cover: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: false,
        Oficina: false,
        Local: false,
        'Casa uso de suelo': false,
        'Bodega comercial': true,
        'Local en centro comercial': false,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: false
    },
    firme: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: false,
        Oficina: false,
        Local: false,
        'Casa uso de suelo': false,
        'Bodega comercial': true,
        'Local en centro comercial': false,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: false
    },
    lightningType: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: false
    },
    energyType: {
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': true,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': true,
        'Terreno industrial': true,
        PH: true
    },
    transformer: {
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': true,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': true,
        'Terreno industrial': true,
        PH: true
    },
    electricSubstation: {
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': true,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': true,
        'Terreno industrial': true,
        PH: true
    },
    bardaPermietral: {
        Departamento: false,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': true,
        Finca: true,
        Edificio: true,
        Oficina: false,
        Local: false,
        'Casa uso de suelo': false,
        'Bodega comercial': true,
        'Local en centro comercial': false,
        'Nave industrial': true,
        'Terreno comercial': true,
        'Terreno industrial': true,
        PH: true
    },
    commonParkingLots: {
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': true,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': true,
        'Terreno industrial': true,
        PH: true
    },
    privateParkingLots: {
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': true,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': true,
        'Terreno industrial': true,
        PH: true
    },
    trailerParkingLots: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: false
    },
    emergencyDoors: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: false
    },
    floorDepth: {
        Departamento: false,
        Casa: false,
        'Casa en condominio': false,
        'Terreno residencial': false,
        Finca: false,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': false,
        'Terreno industrial': false,
        PH: false
    },
    restrictions: {
        Departamento: true,
        Casa: true,
        'Casa en condominio': true,
        'Terreno residencial': true,
        Finca: true,
        Edificio: true,
        Oficina: true,
        Local: true,
        'Casa uso de suelo': true,
        'Bodega comercial': true,
        'Local en centro comercial': true,
        'Nave industrial': true,
        'Terreno comercial': true,
        'Terreno industrial': true,
        PH: true
    }
};
