import {
    CloseCircleOutlined,
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    MailOutlined,
    WhatsAppOutlined
} from '@ant-design/icons';
import { ISearchProperty, ISimpleProperty, ISimpleUser, IVisit } from '@pulppo/pulppo-models';
import { Button, Col, Dropdown, Row, Input, notification } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import * as ReactDOM from 'react-dom';
import { api_url, fetcher, wapp_url } from '../../helpers/fetcher';
import axios from '../../utils/axios';
import { parsePrice } from '../../utils/parser';
import { NextImage } from './NextImage';
import { PulppoDrawer } from './PulppoDrawer';
import { PulppoMap, PulppoMarker } from './PulppoMap';
import useSWR from 'swr';
import { PulppoDatePicker } from './PulppoDatePicker';
import { PulppoLoader } from './PulppoLoader';
import AddStep from './VisitDrawer/AddStep';
import { DEFAULT_COORDINATES } from '../../utils/coordinates';
import { LoadingButton } from './ButtonLoading';
import DrawerFormVisit, { IDrawerFormVisit } from '../Visit/FormVisit';
import { INote } from '@pulppo/pulppo-models/build/@types/pulppo-models/schemas/Notes';
import { IExtendedVisit } from '../../types';

const RemovePropertyDrawer = ({ show, setShow, onConfirm, isLast }) => {
    return (
        <PulppoDrawer
            title="Eliminar propiedad a visitar"
            visible={show}
            onClose={() => {
                setShow(false);
            }}
        >
            <div className="flex h-full flex-col justify-between">
                <div className="mt-8 flex flex-1 flex-col items-center gap-4">
                    <div className="flex h-20 w-20 items-center justify-center bg-notification-red text-5xl text-strong-red">
                        <CloseCircleOutlined />
                    </div>
                    <p className="text-center">
                        ¿Estás seguro que quieres eliminar esta propiedad del recorrido agendado? <br></br>
                        {isLast
                            ? 'Esta es la última propiedad del recorrido, si la eliminas se cancelara la visita completa.'
                            : 'Si la eliminas del recorrido, no podrás volver a añadirla, pero puedes agendar una visita nueva.'}
                    </p>
                </div>
                <Button
                    type="primary"
                    className="h-12 font-medium"
                    onClick={() => {
                        setShow(false);
                        onConfirm();
                    }}
                >
                    Quitar propiedad del recorrido
                </Button>
            </div>
        </PulppoDrawer>
    );
};

const EditTimeDrawer = ({ show, setShow, currentTime, onConfirm, onRemove }) => {
    const [time, setTime] = useState<Dayjs>(currentTime);

    useEffect(() => {
        setTime(currentTime);
    }, [currentTime]);

    return (
        <PulppoDrawer
            title="Editar horario de visita"
            visible={show}
            onClose={() => {
                setShow(false);
            }}
        >
            <div className="flex h-full flex-col justify-between px-4">
                <div className="flex-1">
                    <p className="mb-6 text-pulppo-status-dark-disabled">
                        La visita forma parte de un recorrido, puedes sólo editar el horario a la realizar la visita o
                        quitarla del recorrido
                    </p>
                    <p className="mb-2 font-medium">Horario</p>
                    <Row gutter={10}>
                        {new Array(16).fill(7).map((offset, index) => {
                            const isSame =
                                time.isSame(
                                    dayjs(time)
                                        .tz()
                                        .startOf('day')
                                        .add(offset + index, 'hour'),
                                    'hour'
                                ) || false;
                            return (
                                <Col key={index} span={6} className="mt-3">
                                    <Dropdown
                                        trigger={['click']}
                                        menu={{
                                            className: '',
                                            items: new Array(4).fill(1).map((_, subindex) => ({
                                                label: (
                                                    <div>
                                                        <p
                                                            className={`text-md text-center text-black ${
                                                                time.isSame(
                                                                    dayjs(time || dayjs().tz())
                                                                        .tz()
                                                                        .startOf('day')
                                                                        .add(offset + index, 'hour')
                                                                        .add(subindex * 15, 'minutes'),
                                                                    'minutes'
                                                                )
                                                                    ? 'font-medium'
                                                                    : ''
                                                            }`}
                                                        >
                                                            {dayjs(time || dayjs().tz())
                                                                .tz()
                                                                .startOf('day')
                                                                .add(offset + index, 'hour')
                                                                .add(subindex * 15, 'minutes')
                                                                .format('h:mm')}
                                                            {' Hs'}
                                                        </p>
                                                        {subindex < 3 && (
                                                            <div className="mt-2 h-0.5 w-full bg-gray-200"></div>
                                                        )}
                                                    </div>
                                                ),
                                                key: subindex
                                            })),
                                            onClick: (item) => {
                                                setTime((time) =>
                                                    dayjs(time)
                                                        .tz()
                                                        .startOf('day')
                                                        .add(offset + index, 'hour')
                                                        .add(+item.key * 15, 'minutes')
                                                );
                                            }
                                        }}
                                    >
                                        <div
                                            onClick={() => {
                                                !isSame &&
                                                    setTime((time) =>
                                                        dayjs(time)
                                                            .tz()
                                                            .startOf('day')
                                                            .add(offset + index, 'hours')
                                                    );
                                            }}
                                            className={`flex cursor-pointer flex-col items-center justify-center py-2 font-bold ${
                                                isSame ? 'bg-black text-white' : 'border border-solid border-gray-300'
                                            }`}
                                        >
                                            <p className="text-lg">
                                                {isSame
                                                    ? dayjs(time || dayjs().tz())
                                                          .tz()
                                                          .format('h:mm')
                                                    : dayjs(time || dayjs().tz())
                                                          .tz()
                                                          .startOf('day')
                                                          .add(offset + index, 'hours')
                                                          .format('h:mm')}
                                            </p>
                                            <p className="text-base capitalize">
                                                {dayjs(time)
                                                    .tz()
                                                    .startOf('day')
                                                    .add(offset + index, 'hours')
                                                    .format('a')}
                                            </p>
                                        </div>
                                    </Dropdown>
                                </Col>
                            );
                        })}
                    </Row>
                </div>
                <div className="flex w-full justify-between gap-4">
                    <Button
                        type="default"
                        className="h-12 flex-1 font-medium"
                        onClick={() => {
                            onRemove();
                        }}
                    >
                        Quitar propiedad
                    </Button>

                    <Button
                        type="primary"
                        className="h-12 flex-1 font-medium"
                        onClick={() => {
                            setShow(false);
                            onConfirm(time);
                        }}
                    >
                        Guardar cambios
                    </Button>
                </div>
            </div>
        </PulppoDrawer>
    );
};

export interface ITourDrawer {
    visit: IExtendedVisit | IVisit;
    onClose?: () => void;
    onCancel?: (visit: IVisit) => void;
    onSave?: (shouldClose: boolean) => void;
    setVisit?: (visit: IVisit) => void;
}

const TourDrawer = ({ visit, setVisit, show, onClose, onSave, onCancel }: ITourDrawer & { show: boolean }) => {
    const [refreshMap, setRefreshMap] = useState(1);
    const [deleteStepIndex, setDeleteStepIndex] = useState(-1);
    const [editTimeIndex, setEditTimeIndex] = useState(-1);
    const printRef = useRef(null);
    const [showShareDrawer, setShowShareDrawer] = useState(false);
    const [savingDate, setSavingDate] = useState(false);
    const [showAddProperty, setShowAddProperty] = useState(false);
    useEffect(() => {
        setRefreshMap((refreshMap) => refreshMap + 1);
    }, [visit]);

    return (
        <>
            <AddPropertyDrawer
                show={showAddProperty}
                onClose={() => setShowAddProperty(false)}
                visit={visit as IVisit}
                properties={[]}
                onSave={(step) => {
                    const aux = [...visit.steps, step];
                    axios
                        .patch(`${api_url}/visit/${visit._id}`, {
                            steps: aux
                        })
                        .then((res) => {
                            setVisit(res.data);
                            onSave(res.data);
                        });
                }}
            />
            <ShareTourDrawer show={showShareDrawer} onClose={() => setShowShareDrawer(false)} visit={visit} />
            <RemovePropertyDrawer
                show={deleteStepIndex !== -1}
                setShow={(value: boolean) => {
                    if (!value) setDeleteStepIndex(-1);
                }}
                isLast={visit.steps.length === 1}
                onConfirm={() => {
                    if (visit?.steps?.length === 1) {
                        axios.delete(`${api_url}/visit/${visit?._id}`).then((res) => onCancel(res.data));
                    } else {
                        const aux = [...visit.steps];
                        aux.splice(deleteStepIndex, 1);
                        axios
                            .patch(`${api_url}/visit/${visit._id}`, {
                                steps: aux
                            })
                            .then((res) => {
                                setVisit(res.data);
                                onSave(res.data);
                            });
                    }
                }}
            />
            <EditTimeDrawer
                show={editTimeIndex !== -1}
                setShow={(value: boolean) => {
                    if (!value) setEditTimeIndex(-1);
                }}
                onRemove={() => {
                    setDeleteStepIndex(editTimeIndex);
                    setEditTimeIndex(-1);
                }}
                currentTime={dayjs(visit.steps[editTimeIndex]?.date).tz() || dayjs().tz()}
                onConfirm={(time: Date) => {
                    const aux = [...visit.steps];
                    aux[editTimeIndex].date = time;
                    axios
                        .patch(`${api_url}/visit/${visit._id}`, {
                            steps: aux
                        })
                        .then((res) => {
                            setVisit(res.data);
                            onSave(res.data);
                        });
                }}
            />
            <PulppoDrawer visible={show} onClose={onClose} title="Recorrido">
                <div className="-mx-4 -mt-4 flex h-full flex-col items-center justify-between px-4">
                    <div className="shrink-1 w-full overflow-x-hidden">
                        <PulppoLoader loading={savingDate}>
                            <div className="flex flex-col" ref={printRef}>
                                <p className="mb-2 text-base text-pulppo-status-dark-disabled">
                                    {visit?.status?.last === 'confirmed'
                                        ? 'Puedes eliminar una visita del recorrido, editar la fecha o el horario.'
                                        : 'Confirma el recorrido para realizar las visitas agendadas. Puedes eliminar una visita del recorrido, editar la fecha o el horario.'}
                                </p>
                                <div className="mb-4">
                                    <PulppoDatePicker
                                        format={(value) => `Fecha de visita: ${value.format('DD/MM/YYYY')}`}
                                        disableOlderDates={true}
                                        onChange={(date) => {
                                            setSavingDate(true);
                                            axios
                                                .patch(`${api_url}/visit/${visit._id}`, {
                                                    steps: visit.steps.map((step) => ({
                                                        property: step.property,
                                                        date: dayjs(step.date)
                                                            .tz()
                                                            .set('year', date.year())
                                                            .set('month', date.month())
                                                            .set('date', date.date())
                                                            .toDate()
                                                    }))
                                                })
                                                .then((res) => {
                                                    setSavingDate(false);
                                                    setVisit(res.data);
                                                    onSave(res.data);
                                                });
                                        }}
                                        value={dayjs(visit?.startTime).tz()}
                                    />
                                </div>

                                {visit.steps.map((step, index) => (
                                    <div key={`step_${index}`} className="mb-4">
                                        <div className="mt-2 flex w-full items-start gap-2">
                                            <div className="flex h-6 w-6 items-center justify-center bg-black font-medium text-white">
                                                {index + 1}
                                            </div>
                                            <p className="flex-1 text-lg font-medium">
                                                {`Horario de visita: ${dayjs(step.date).tz().format('HH:mm a')}`}
                                            </p>
                                        </div>
                                        <div className="mt-2 flex w-full items-center justify-between">
                                            <div className="flex w-3/4 flex-1 gap-2">
                                                <div className="shrink-0">
                                                    <NextImage
                                                        alt={step.property._id as string}
                                                        src={step.property.pictures?.[0]?.url}
                                                        width={100}
                                                        height={100}
                                                        className="h-32 w-32"
                                                        showPreview={false}
                                                    />
                                                </div>
                                                <div className="flex w-3/4 flex-col justify-between py-2">
                                                    <p className="truncate text-lg font-medium">
                                                        {step.property.address?.street}
                                                    </p>
                                                    <p className="text-base font-medium text-pulppo-status-dark-disabled">
                                                        {`${step.property.address?.neighborhood?.name}, ${step.property.address?.city?.name}`}
                                                    </p>
                                                    <p className="text-lg font-bold">
                                                        {parsePrice({
                                                            price: step.property.listing.price?.price,
                                                            startStr: '$ '
                                                        })}
                                                    </p>
                                                    <div className="flex h-8 w-24 items-center justify-center bg-gray-200 px-4 text-sm font-medium">
                                                        {step.property.listing.operation === 'sale' ? 'VENTA' : 'RENTA'}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex shrink-0 gap-2">
                                                <Button
                                                    onClick={() => {
                                                        setDeleteStepIndex(index);
                                                    }}
                                                    type="default"
                                                    className="flex h-8 w-8 items-center justify-center font-medium"
                                                >
                                                    <DeleteOutlined />
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        setEditTimeIndex(index);
                                                    }}
                                                    type="default"
                                                    className="flex h-8 w-8 items-center justify-center font-medium"
                                                >
                                                    <EditOutlined />
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="mt-4 h-px w-full bg-gray-200"></div>
                                    </div>
                                ))}

                                <p className="mb-4 text-lg font-medium">Mapa de recorrido</p>
                                <div>
                                    <PulppoMap
                                        refreshMap={refreshMap}
                                        bounds={
                                            visit?.steps?.filter(
                                                (step) => step.property?.address?.location?.coordinates?.[1]
                                            )?.length > 1
                                                ? (['min', 'max'].map((operation) =>
                                                      [0, 1].map((i) =>
                                                          Math[operation](
                                                              ...visit.steps
                                                                  .filter(
                                                                      (step) =>
                                                                          step.property.address?.location
                                                                              ?.coordinates?.[1]
                                                                  )
                                                                  .map(
                                                                      (step) =>
                                                                          step.property.address?.location.coordinates[i]
                                                                  )
                                                          )
                                                      )
                                                  ) as [[number, number], [number, number]])
                                                : null
                                        }
                                        center={
                                            (visit.steps.filter(
                                                (step) => step.property?.address?.location?.coordinates?.[1]
                                            )?.[0]?.property?.address?.location?.coordinates as any) || [
                                                DEFAULT_COORDINATES.lng,
                                                DEFAULT_COORDINATES.lat
                                            ]
                                        }
                                        className="h-96 w-full"
                                        route={
                                            visit.steps.length > 1
                                                ? visit.steps
                                                      .filter(
                                                          (step) => step.property?.address?.location?.coordinates?.[1]
                                                      )
                                                      .map((step) => step.property.address.location.coordinates)
                                                : null
                                        }
                                    >
                                        {visit.steps
                                            .filter((step) => step.property?.address?.location?.coordinates?.[1])
                                            .map((step, index) => (
                                                <PulppoMarker
                                                    key={step.property._id as string}
                                                    coordinates={step.property.address.location.coordinates}
                                                >
                                                    <div className="flex h-6 w-6 items-center justify-center bg-black font-bold text-white">
                                                        {index + 1}
                                                    </div>
                                                </PulppoMarker>
                                            ))}
                                    </PulppoMap>
                                </div>
                            </div>
                        </PulppoLoader>
                    </div>

                    {visit.status.last === 'pending' ? (
                        <div className="flex w-full shrink-0 gap-3 px-4 py-2">
                            <Button
                                type="default"
                                className="h-10 flex-1 font-medium"
                                onClick={() => {
                                    axios.delete(`${api_url}/visit/${visit._id}`).then((res) => onCancel(res.data));
                                }}
                            >
                                Cancelar recorrido
                            </Button>
                            <Button
                                type="primary"
                                className="h-10 flex-1 font-medium"
                                onClick={() => {
                                    axios.patch(`${api_url}/visit/${visit._id}/confirmed`).then((res) => {
                                        onSave(res.data);
                                        onClose();
                                    });
                                }}
                            >
                                Confirmar recorrido
                            </Button>
                        </div>
                    ) : (
                        <div className="-mb-8 flex w-full shrink-0 gap-3 px-4 py-4">
                            <LoadingButton
                                icon={<DownloadOutlined />}
                                type="default"
                                className="flex-0 flex h-10 w-12 items-center justify-center font-medium"
                                onClick={async () => {
                                    const url = await fetcher(`${api_url}/visit/${visit._id}/url`);
                                    if (url) {
                                        const link = document.createElement('a');
                                        link.href = url?.url;
                                        link.target = '_blank';
                                        link.rel = 'noreferrer';
                                        link.download = 'tour.png';
                                        link.click();
                                    }
                                }}
                            ></LoadingButton>
                            <Button
                                onClick={() => setShowAddProperty(true)}
                                className="h-10 flex-1 font-medium"
                                type="default"
                            >
                                Añadir propiedad
                            </Button>
                            <Button
                                type="primary"
                                className="h-10 flex-1 font-medium"
                                onClick={() => {
                                    setShowShareDrawer(true);
                                }}
                            >
                                Compartir recorrido
                            </Button>
                        </div>
                    )}
                </div>
            </PulppoDrawer>
        </>
    );
};
export interface IVisitStep {
    date: Dayjs;
    notes: INote[];
    property: ISimpleProperty;
}

const AddPropertyDrawer = ({
    show,
    onClose,
    visit,
    onSave
}: {
    show: boolean;
    onClose: () => void;
    properties: ISearchProperty[];
    visit: IVisit;
    onSave: (step: IVisitStep) => void;
}) => {
    const [step, setStep] = useState<IVisitStep>({
        date: dayjs(visit.endTime).tz().add(30, 'minute'),
        notes: [],
        property: null
    });
    const { data: properties, isLoading } = useSWR<any[]>(
        visit?.search && `${api_url}/search/${visit.search}/properties`,
        fetcher
    );
    return (
        <PulppoDrawer visible={show} onClose={onClose} title="Agregar una propiedad">
            <div className="-mx-4 flex h-full flex-col items-center justify-between">
                <div className="shrink-1 w-full overflow-x-hidden">
                    <PulppoLoader loading={isLoading}>
                        <AddStep
                            properties={properties}
                            visit={visit.steps.map((step) => ({
                                date: dayjs(step.date).tz(),
                                property: step.property as ISearchProperty,
                                notes: step.notes
                            }))}
                            step={step}
                            setStep={setStep}
                            user={visit.agent}
                        />
                    </PulppoLoader>
                </div>

                <div className="flex w-full shrink-0 items-center justify-center gap-3 px-2 py-2 lg:px-4">
                    <Button className="h-10 flex-1 font-medium" onClick={onClose}>
                        Cancelar
                    </Button>
                    <LoadingButton
                        type="primary"
                        className="h-10 flex-1 font-medium"
                        onClick={async () => {
                            await onSave(step);
                            onClose();
                        }}
                    >
                        Confirmar
                    </LoadingButton>
                </div>
            </div>
        </PulppoDrawer>
    );
};

const generateMessage = (visit: IVisit) => `Hola ${visit.contact?.firstName}
Te envío este mensaje para reconfirmar que tenemos una visita agendada el día ${dayjs(visit.startTime)
    .tz()
    .format('DD/MM')} a las ${dayjs(visit.startTime).tz().format('hh:mm a')} Hs.
En la misma visitaremos ${visit.steps.length === 1 ? 'una propiedad' : `${visit.steps.length} propiedades`}:
  ${visit.steps.reduce((msg: string, step, index: number) => {
      return (
          msg +
          `${index + 1} - ${
              step.property.address?.street || `"${step.property?.listing?.title}"`
          } a las ${dayjs(step.date).tz().format('hh:mm a')} Hs
`
      );
  }, '')}
  Te envío adjunto una imagen con el recorrido a realizar. 
  En caso de haber algún inconveniente, por favor, házmelo saber para poder reagendar la visita. 
  Muchas gracias!
  `;
const ShareTourDrawer = ({ show, onClose, visit }) => {
    const [share, setShare] = useState('phone');
    const [loading, setLoading] = useState(false);
    const { data: contact } = useSWR<ISimpleUser>(`${api_url}/contact/${visit?.contact?._id}/simple`, fetcher);

    const [message, setMessage] = useState(generateMessage(visit));

    useEffect(() => {
        setMessage(generateMessage(visit));
    }, [visit]);

    return (
        <PulppoDrawer visible={show} onClose={onClose} title="Compartir recorrido">
            <div className="flex h-full flex-col justify-between">
                <div className="flex-1">
                    <div className="flex cursor-pointer flex-col gap-5">
                        {contact?.phone && (
                            <div
                                className="flex w-full items-center justify-between"
                                onClick={() => {
                                    setShare('phone');
                                }}
                            >
                                <div className="flex items-center gap-2">
                                    <WhatsAppOutlined className="flex h-10 w-10 items-center justify-center bg-gray-100 text-xl font-medium" />{' '}
                                    <p className="text-lg font-medium">{contact.phone}</p>
                                </div>
                                <div className="">
                                    {share === 'phone' ? (
                                        <div className="flex h-7 w-7 rounded-full border-2 border-solid border-black bg-white">
                                            <div className="mx-1 my-1 h-4 w-4 rounded-full bg-confirmationGreen"></div>
                                        </div>
                                    ) : (
                                        <div className="h-7 w-7 rounded-full border-2 border-solid border-black bg-white">
                                            {' '}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {contact?.email && (
                            <div
                                className="flex w-full cursor-pointer items-center justify-between"
                                onClick={() => {
                                    setShare('email');
                                }}
                            >
                                <div className="flex items-center gap-2">
                                    <MailOutlined className="flex h-10 w-10 items-center justify-center bg-gray-100 text-xl font-medium" />{' '}
                                    <p className="text-lg font-medium">{contact.email}</p>
                                </div>
                                <div className="">
                                    {share === 'email' ? (
                                        <div className="flex h-7 w-7 rounded-full border-2 border-solid border-black bg-white">
                                            <div className="mx-1 my-1 h-4 w-4 rounded-full bg-confirmationGreen"></div>
                                        </div>
                                    ) : (
                                        <div className="h-7 w-7 rounded-full border-2 border-solid border-black bg-white">
                                            {' '}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <p className="mb-2 mt-4 text-lg font-medium">Mensaje</p>
                    <Input.TextArea
                        rows={20}
                        value={message}
                        onInput={(ev) => setMessage(ev.currentTarget.value)}
                    ></Input.TextArea>
                </div>
                <div className="flex w-full gap-10">
                    <Button className="h-12 flex-1 font-medium" onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button
                        className="h-12 flex-1 font-medium"
                        type="primary"
                        onClick={async () => {
                            setLoading(true);
                            const url = await fetcher(`${api_url}/visit/${visit._id}/url`).catch(() => null);
                            if (share === 'phone') {
                                url
                                    ? axios
                                          .post(`${wapp_url}/message-attachment/${visit.agent.uid}`, {
                                              to: contact.phone?.replace?.(/\D/gi, ''),
                                              content: message,
                                              attachment: {
                                                  image: url
                                              }
                                          })

                                          .then(() => {
                                              setLoading(false);
                                              onClose();
                                              notification.success({
                                                  message: 'Se compartió el recorrido correctamente'
                                              });
                                          })
                                          .catch(() => {
                                              setLoading(false);
                                              notification.error({
                                                  message: 'Hubo un problema al comprtir el recorrido'
                                              });
                                          })
                                    : axios
                                          .post(`${wapp_url}/message/${visit.agent.uid}`, {
                                              to: contact.phone?.replace?.(/\D/gi, ''),
                                              content: message
                                          })

                                          .then(() => {
                                              setLoading(false);
                                              onClose();
                                              notification.success({
                                                  message: 'Se compartió el recorrido correctamente'
                                              });
                                          })
                                          .catch(() => {
                                              setLoading(false);
                                              notification.error({
                                                  message: 'Hubo un problema al comprtir el recorrido'
                                              });
                                          });
                            } else if (share === 'email') {
                                axios
                                    .post(`https://api.pulppo.com/gmail/send/${visit.agent?.uid}`, {
                                        html: true,
                                        to: contact.email,
                                        subject: `Recordatorio visita ${dayjs(visit.startTime).tz().format('DD/MM')}`,
                                        message: `
                      <img src=${url.url} alt="Recorrido" />
                      <p> 
                      ${message}
                      <p> 
                      `
                                    })
                                    .then(() => {
                                        setLoading(false);
                                        onClose();
                                        notification.success({
                                            message: 'Se compartió el recorrido correctamente'
                                        });
                                    })
                                    .catch(() => {
                                        setLoading(false);
                                        notification.error({
                                            message: 'Hubo un problema al comprtir el recorrido'
                                        });
                                    });
                            }
                        }}
                        loading={loading}
                    >
                        Compartir
                    </Button>
                </div>
            </div>
        </PulppoDrawer>
    );
};
export default TourDrawer;

export const ShowOldTourDrawer = (config: ITourDrawer) => {
    const container = document.createDocumentFragment();
    let currentConfig = {
        ...config,
        close,
        show: true
    } as any;

    function destroy(...args: any[]) {
        ReactDOM.unmountComponentAtNode(container);
        args.some((param) => param && param.triggerCancel);
    }

    function render(props: JSX.IntrinsicAttributes & ITourDrawer & { show: boolean }) {
        setTimeout(() => {
            ReactDOM.render(<TourDrawer {...props} />, container);
        });
    }

    function close(...args: any[]) {
        currentConfig = {
            ...currentConfig,
            show: false,
            afterClose: () => {
                destroy.apply(this, args);
            }
        };
        render(currentConfig);
    }

    function update(configUpdate) {
        if (typeof configUpdate === 'function') {
            currentConfig = configUpdate(currentConfig);
        } else {
            currentConfig = {
                ...currentConfig,
                ...configUpdate
            };
        }
        render(currentConfig);
    }

    render(currentConfig);

    return {
        destroy: close,
        update
    };
};

export const ShowTourDrawer = (config: Omit<IDrawerFormVisit, 'show'>) => {
    const container = document.createDocumentFragment();
    let currentConfig = {
        ...config,
        close,
        show: true
    } as any;

    function destroy(...args: any[]) {
        ReactDOM.unmountComponentAtNode(container);
        args.some((param) => param && param.triggerCancel);
    }

    function render(props: JSX.IntrinsicAttributes & IDrawerFormVisit & { show: boolean }) {
        setTimeout(() => {
            ReactDOM.render(<DrawerFormVisit {...props} />, container);
        });
    }

    function close(...args: any[]) {
        currentConfig = {
            ...currentConfig,
            show: false,
            afterClose: () => {
                destroy.apply(this, args);
            }
        };
        render(currentConfig);
    }

    function update(configUpdate) {
        if (typeof configUpdate === 'function') {
            currentConfig = configUpdate(currentConfig);
        } else {
            currentConfig = {
                ...currentConfig,
                ...configUpdate
            };
        }
        render(currentConfig);
    }

    render(currentConfig);

    return {
        destroy: close,
        update
    };
};
