import { CheckOutlined, MailOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { IContact, ISimpleUser } from '@pulppo/pulppo-models';
import { Input, notification, Tooltip } from 'antd';
import axios from '../../utils/axios';
import { useEffect, useState } from 'react';
import { api_url, fetcher, wapp_url } from '../../helpers/fetcher';
import { sendMessageVCard } from '../../services/messageService';
import { ShowPulppoDrawer } from '../Elements/showPulppoDrawer';
import useUser from '../../hooks/useUser';
import { LoadingButton } from '../Elements/ButtonLoading';
import useSWR from 'swr';

interface IShareDrawerProps {
    contact: IContact | ISimpleUser;
    onSend: () => void;
    mail: {
        url: string;
        subject: string;
        content: string;
        params?: any;
    };
    whatsapp: {
        content: string;
        attachment?: any;
    };
}

export const showShareDrawer = (params: IShareDrawerProps) => {
    const { destroy } = ShowPulppoDrawer({
        title: '¿Cómo enviar?',
        children: (
            <ShareDrawer
                {...params}
                onSend={() => {
                    destroy();
                    params?.onSend?.();
                }}
            />
        ),
        onCancel: () => destroy()
    });
};

const ShareDrawer = ({ contact: initialContact, onSend, mail, whatsapp }: IShareDrawerProps) => {
    const { user } = useUser();
    const [medium, setMedium] = useState([]);
    const [selectedPhones, setSelectedPhones] = useState<string[]>([]);
    const [textInput, setTextInput] = useState(whatsapp?.content || '');
    const { data: contact } = useSWR(
        initialContact?._id && `${api_url}/contact/${initialContact._id}/all-channels`,
        fetcher
    );

    useEffect(() => {
        if (contact) {
            const phones = contact.phones || [];

            setMedium([...(phones.length > 0 ? ['phone'] : []), ...(contact.email ? ['email'] : [])]);
            setSelectedPhones(phones);
        }
    }, [contact]);

    useEffect(() => {
        if (selectedPhones.length > 0) {
            setMedium((prevMedium) => [...prevMedium, 'phone']);
        } else {
            setMedium((prevMedium) => prevMedium.filter((el) => el !== 'phone'));
        }
    }, [selectedPhones]);

    const sendWhatsapp = async (phone: string, message) => {
        try {
            await axios.post(
                whatsapp.attachment
                    ? `${wapp_url}/message-attachment/${user?.uid}`
                    : `${wapp_url}/message/${user?.uid}`,
                {
                    to: phone?.replace(/\D/gi, ''),
                    content: message,
                    attachment: whatsapp.attachment
                }
            );
            const content = whatsapp.content;
            const urlRegex = /(https?:\/\/[^\s]+)/g;
            const containsUrl = content.match(urlRegex);
            if (containsUrl) {
                axios
                    .get(`${api_url}/contact/${contact._id}/has-chat`)
                    .then(async (r) => {
                        if (!r.data.exists) {
                            await sendMessageVCard({
                                onPreSend: async () => {
                                    await axios
                                        .post(`${wapp_url}/message/${user?.uid}`, {
                                            to: phone?.replace(/\D/gi, ''),
                                            content:
                                                'Aprovecho para compartirte mi contacto, en algunos Whatsapp tienes que agregarme para poder hacer clic en la liga.'
                                        })
                                        .catch((err) => {
                                            console.error(`No se pudo enviar el vcard: ${err}`);
                                        });
                                },
                                user,
                                toPhone: phone
                            });
                        }
                    })
                    .catch();
            }
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };

    const sendEmail = async (contact: IContact | ISimpleUser, message) => {
        return await axios
            .post(mail.url, {
                ...mail,
                ...mail.params,
                to: contact?.email,
                agent: { ...user },
                contact,
                message: message
            })
            .then(() => true)
            .catch((err) => {
                console.log(err);
                return false;
            });
    };
    const share = async (medium, message) => {
        let success = false;
        if (medium.includes('phone') && selectedPhones.length > 0) {
            const results = await Promise.all(selectedPhones.map((phone) => sendWhatsapp(phone, message)));

            success = results.every((res) => res);
        }
        if (medium.includes('email')) {
            success = await sendEmail(contact, message);
        }
        if (success) {
            notification.success({
                message: 'Compartido correctamente',
                description: 'Se ha compartido correctamente la información con tu contacto.'
            });
        } else {
            notification.error({
                message: 'Hubo un problema',
                description: 'Hemos tenido un problema compartiendo la información con tu contacto.'
            });
        }
        onSend();
    };
    return (
        <div className="flex h-full flex-col justify-between">
            <div className="flex-1">
                <p className="mb-1 mt-4 text-sm font-semibold">Mensaje</p>
                <Input.TextArea
                    value={textInput}
                    className="rounded-none"
                    onInput={(ev) => {
                        setTextInput(ev.currentTarget.value);
                    }}
                    placeholder="Escriba un mensaje aqui..."
                    rows={14}
                />
                {contact?.phones?.map((phone) => (
                    <div
                        key={`contact-phone-${phone}`}
                        className="mt-4 flex w-full cursor-pointer items-center gap-4 border-b border-solid border-pulppo-secondary-gray-line py-3"
                        onClick={() =>
                            setSelectedPhones((prevPhones) => {
                                if (prevPhones.includes(phone)) {
                                    return prevPhones.filter((p) => p !== phone);
                                } else {
                                    return [...prevPhones, phone];
                                }
                            })
                        }
                    >
                        <div className="flex items-center justify-center bg-pulppo-status-light-disabled p-1.5">
                            <WhatsAppOutlined className="text-black" style={{ fontSize: '15px' }} />
                        </div>
                        <div className="flex-1">Whatsapp al {phone}</div>
                        <div
                            className={`flex h-5 w-5 items-center justify-center rounded-none border border-solid border-black bg-white`}
                        >
                            {selectedPhones.includes(phone) && (
                                <CheckOutlined className="p-1 text-xs font-bold text-black" />
                            )}
                        </div>
                    </div>
                ))}
                {contact?.email && (
                    <div
                        className="mt-4 flex w-full cursor-pointer items-center gap-4 border-b border-solid border-pulppo-secondary-gray-line py-3"
                        onClick={() =>
                            setMedium((medium) => {
                                if (medium.includes('email')) return medium.filter((el) => el !== 'email');
                                else return medium.concat('email');
                            })
                        }
                    >
                        <div className="flex shrink-0 items-center justify-center bg-pulppo-status-light-disabled p-1.5">
                            <MailOutlined className="text-black" style={{ fontSize: '15px' }} />
                        </div>
                        <div className="flex-1">Email a {contact?.email}</div>
                        <div className="flex h-5 w-5 items-center justify-center rounded-none border border-solid border-black bg-white">
                            {medium.includes('email') && <CheckOutlined className="p-1 text-xs font-bold text-black" />}
                        </div>
                    </div>
                )}
            </div>
            <div className="w-full py-4">
                <Tooltip title={medium.length === 0 ? 'Selecciona al menos una opción' : ''}>
                    <span>
                        <LoadingButton
                            type="primary"
                            className="h-11 w-full"
                            onClick={async () => {
                                await share(medium, textInput);
                            }}
                            disabled={medium.length === 0}
                        >
                            Compartir
                        </LoadingButton>
                    </span>
                </Tooltip>
            </div>
        </div>
    );
};
