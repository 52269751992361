import { IProperty } from '@pulppo/pulppo-models';
import { Avatar, Button, Col, Form, InputNumber, Modal, notification, Row, Typography } from 'antd';
import axios from '../../../utils/axios';
import nookies from 'nookies';
import { useRouter } from 'next/router';
import { useContext, useEffect, useRef, useState } from 'react';
import { Header } from '../../../components/ACM/Header';
import { FileUpload } from '../../../components/Elements/FileUpload';
import { TaskView } from '../../../components/Elements/TaskView';
import { Services } from '@pulppo/pulppo-models/build/enums/Services';
import { api_url } from '../../../helpers/fetcher';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { i18n } from 'next-i18next';
import { NextImage } from '../../../components/Elements/NextImage';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ShowContactList } from '../../../components/Contact/SimpleContactList';
import { PropertyLocation } from '../../../components/PropertyDetail/PropertyLocation';
import { useForm } from 'antd/lib/form/Form';
import { useHasPermissions } from '../../../hooks/useHasPermissions';
import { toComplex, toPlain } from '../../../utils/toPlain';
import { PropertyFormContext, PropertyFormContextProvider } from '../../../contexts/PropertyFormContext';
import { formEventEmitter } from '../../../utils/FormEvents';
import { PulppoSelect } from '../../../components/Elements/PulppoSelect';
import dayjs from 'dayjs';
import { PulppoMultiselect } from '../../../components/Elements/PulppoMultiselect';
import SimpleACMLoader from '../../../components/ACM/SImpleACMLoader';
import { isEqual, cloneDeep } from 'lodash';
import { DEFAULT_COORDINATES } from '../../../utils/coordinates';
import { useTranslate } from '@tolgee/react';
import { propertyCreateAcmSimple } from '../../../services/propertyService';

export const initialCoordinates = (typeof localStorage !== 'undefined' &&
    localStorage.getItem('coordinates') &&
    JSON.parse(localStorage.getItem('coordinates'))) || [DEFAULT_COORDINATES.lng, DEFAULT_COORDINATES.lat];

const DEFAULT_IMAGE = 'https://images.pulppo.com/property/default_acm.jpg';

const ACM = ({ property }: { property: IProperty }) => {
    const router = useRouter();
    const [contact, setContact] = useState(property.contact);
    const [image, setImage] = useState(property.pictures?.[0]?.url);
    const permission = useHasPermissions(property, 'property', false);
    const { setFormData } = useContext(PropertyFormContext);
    const [form] = useForm();
    const [loading, setLoading] = useState(false);
    const [initProperty, setInitProperty] = useState<IProperty>();

    const { t } = useTranslate(['common']);

    Form.useWatch('address', form);

    useEffect(() => {
        formEventEmitter.on('changes', (changes) => {
            setFormData((formData) => toComplex({ ...formData, ...changes }));
            for (const key in changes) {
                form.setFieldValue(key.split('.'), changes[key]);
            }
        });
    }, []);

    useEffect(() => {
        if (property) {
            setInitProperty(cloneDeep(property));
        }
    }, [property]);

    useEffect(() => {
        setFormData({
            ...property,
            address: {
                ...property.address,
                location: {
                    type: 'Point',
                    coordinates: [
                        property?.address?.location?.coordinates?.[0] || initialCoordinates?.[0] || 0,
                        property?.address?.location?.coordinates?.[1] || initialCoordinates?.[1] || 0
                    ]
                }
            }
        });
    }, []);

    useEffect(() => {
        if (!permission && !router.asPath.includes('new-acm')) {
            notification.error({
                message: 'No tienes permiso para acceder a esa propiedad'
            });
            router.push('/');
        }
    }, [permission, router]);

    useEffect(() => {
        if (router.query.contact) {
            axios.get(`${api_url}/contact/${router.query.contact}`).then((r) => setContact(r.data));
        }
    }, [router.query.contact]);

    const chooseOpportunity = () => {
        const { destroy } = ShowContactList({
            onSelect: async (contact) => {
                setContact(contact);
                destroy();
            },
            onAdd: (contact) => {
                setContact(contact);
                destroy();
            }
        });
    };
    const simple = useRef(false);

    return (
        <div>
            <Header
                property={property.status?.last === 'published' && property}
                step={0}
                previous={{
                    text: 'Cancelar',
                    onClick: () => {
                        router.push('/search?tab=user');
                    },
                    disabled: false
                }}
                additional={{
                    text: 'ACM Simplificado',

                    onClick: async () => {
                        simple.current = true;
                        form.submit();
                    },
                    disabled: false
                }}
                next={{
                    text: 'Siguiente',
                    additionalProps: {
                        htmlType: 'submit',
                        form: 'step1',
                        loading
                    },
                    onClick: async () => {},
                    disabled: false
                }}
                capture={{
                    onClick: async () => {
                        await router.push(`/captaciones/${property._id}`);
                    }
                }}
                title={{
                    text: property?.address?.street,
                    onChange: null
                }}
            />
            <div>
                <div className="mt-0 bg-white px-6 py-2 md:bg-transparent">
                    <Form
                        initialValues={{
                            ...property,
                            services: property?.services?.length ? property.services.map((svc) => svc?.id) : [],
                            address: {
                                ...property.address,
                                location: {
                                    type: 'Point',
                                    coordinates: [
                                        property?.address?.location?.coordinates?.[0] || initialCoordinates?.[0] || 0,
                                        property?.address?.location?.coordinates?.[1] || initialCoordinates?.[1] || 0
                                    ]
                                }
                            }
                        }}
                        scrollToFirstError={{ behavior: 'smooth' }}
                        form={form}
                        onFinish={async (values) => {
                            if (!contact) {
                                return Modal.error({
                                    title: 'No seleccionaste un contacto',
                                    content: 'Es necesario elegir un contacto al cual realizarle el ACM'
                                });
                            }
                            setLoading(true);

                            values.listing = {
                                operation: 'sale'
                            };
                            if (values.services) {
                                values.services = values.services.map((svc) =>
                                    Services.find((elem) => elem.id === svc)
                                );
                            }
                            try {
                                let id = property?._id;

                                const postUpdate = async () => {
                                    if (simple.current) {
                                        const result = await propertyCreateAcmSimple({
                                            propertyId: `${id}`
                                        });
                                        if (result) {
                                            await router.push(`/acm/${id}/step-4?simple=true`);
                                        }
                                        return;
                                    }

                                    await router.push(`/acm/${id}/step-2`);
                                };
                                if (router.query.id == 'new-acm') {
                                    const property = await axios
                                        .post(`${api_url}/property`, {
                                            ...values,
                                            contact,
                                            pictures: [
                                                {
                                                    url: image || DEFAULT_IMAGE,
                                                    description: 'pulppo_acm'
                                                }
                                            ],
                                            acm: {
                                                comparables: []
                                            },
                                            editedAt: new Date(),
                                            status: 'valuation'
                                        })
                                        .then((r) => r.data);
                                    id = property._id;
                                } else {
                                    values.pictures = property?.pictures?.length ? property.pictures : [];
                                    values.pictures[0] = {
                                        url: image || DEFAULT_IMAGE,
                                        description: 'pulppo_acm'
                                    };
                                    const updateProperty = async (extraQuery?: any) => {
                                        return axios.patch(`${api_url}/property/${property._id}`, {
                                            ...toPlain(JSON.parse(JSON.stringify(values)), ''),
                                            editedAt: new Date(),
                                            contact,
                                            ...extraQuery
                                        });
                                    };
                                    if (
                                        !isEqual(initProperty?.address, property?.address) ||
                                        !isEqual(initProperty?.attributes, property?.attributes) ||
                                        initProperty?.listing?.operation !== property?.listing?.operation ||
                                        initProperty?.type !== property?.type
                                    ) {
                                        Modal.warn({
                                            title: 'Advertencia',
                                            content:
                                                'Algunos datos han cambiado, quiere que se recalculen los comparables previamente seleccionados?',
                                            okCancel: true,
                                            onOk: async () => {
                                                await updateProperty({
                                                    'acm.comparables': []
                                                });
                                                await postUpdate();
                                            },
                                            onCancel: async () => {
                                                await updateProperty();
                                                await postUpdate();
                                            }
                                        });
                                    } else {
                                        await updateProperty();
                                    }
                                }
                                await postUpdate();
                            } catch (err) {
                                Modal.error({
                                    title: 'Hubo un error',
                                    content: `${err}`
                                });
                            } finally {
                                setLoading(false);
                            }
                        }}
                        onValuesChange={(changedValues) => {
                            formEventEmitter.emit('changes', toPlain(changedValues, ''));
                        }}
                        name="step1"
                        id="step1"
                        layout="vertical"
                    >
                        <Row gutter={12}>
                            <Col xs={24} md={12}>
                                <TaskView title="Ubicación de la propiedad">
                                    <PropertyLocation showAdvanced={false} />
                                </TaskView>
                            </Col>
                            <Col xs={24} lg={12}>
                                <TaskView title="Propietario">
                                    {!contact && (
                                        <>
                                            <Typography.Paragraph className="text-pulppo-status-dark-disabled">
                                                Busca o agrega un contacto para asignarlo como propietario
                                            </Typography.Paragraph>
                                            <Button type="primary" className="font-medium" onClick={chooseOpportunity}>
                                                Seleccionar contacto
                                            </Button>
                                        </>
                                    )}
                                    {contact && (
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center">
                                                <Typography.Link href={`/contact/${contact._id}`}>
                                                    <Avatar
                                                        src={
                                                            <NextImage
                                                                showPreview={false}
                                                                alt={contact.profilePicture}
                                                                src={
                                                                    contact.profilePicture ||
                                                                    `https://ui-avatars.com/api/?name=${contact?.firstName}%20${contact?.lastName}`
                                                                }
                                                                width={64}
                                                                height={64}
                                                            />
                                                        }
                                                    />
                                                </Typography.Link>
                                                <div className="ml-4">
                                                    <Typography.Paragraph className="mb-0 font-medium">
                                                        {contact.firstName} {contact.lastName}
                                                    </Typography.Paragraph>
                                                    <Typography.Paragraph className="mb-0 text-pulppo-status-dark-disabled">
                                                        {contact.phone} - {contact.email}
                                                    </Typography.Paragraph>
                                                </div>
                                            </div>
                                            <Typography.Link onClick={chooseOpportunity}>
                                                Editar <EditOutlined />
                                            </Typography.Link>
                                        </div>
                                    )}
                                </TaskView>
                                <TaskView title="Características">
                                    <Row gutter={12}>
                                        <Col xs={24} lg={12}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Selecciona el tipo de propiedad'
                                                    }
                                                ]}
                                                name={['type']}
                                                label="Tipo de Propiedad"
                                            >
                                                <PulppoSelect
                                                    className="py-2"
                                                    allowSearch={false}
                                                    options={['Casa', 'Departamento', 'Casa en condominio'].map(
                                                        (key) => ({
                                                            key: key,
                                                            value: key,
                                                            label: t(key, key, { ns: 'common' })
                                                        })
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={12} xs={24}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Ingresa año de construcción'
                                                    }
                                                ]}
                                                name={['attributes', 'yearBuild']}
                                                label="Año de construcción"
                                            >
                                                <PulppoSelect
                                                    options={[
                                                        {
                                                            label: 'En construcción',
                                                            key: '-1',
                                                            value: `${-1}`
                                                        },
                                                        {
                                                            label: 'A estrenar',
                                                            key: '0',
                                                            value: `${0}`
                                                        },
                                                        {
                                                            label: 'Menos de 5 años',
                                                            key: '5',
                                                            value: `${dayjs().tz().year() - 5}`
                                                        },
                                                        {
                                                            label: 'Menos de 10 años',
                                                            key: '10',
                                                            value: `${dayjs().tz().year() - 10}`
                                                        },
                                                        {
                                                            label: 'Menos de 20 años',
                                                            key: '20',
                                                            value: `${dayjs().tz().year() - 20}`
                                                        },
                                                        {
                                                            label: 'Menos de 50 años',
                                                            key: '50',
                                                            value: `${dayjs().tz().year() - 50}`
                                                        },
                                                        {
                                                            label: 'Más de 50 años',
                                                            key: '51',
                                                            value: `${dayjs().tz().year() - 51}`
                                                        }
                                                    ]}
                                                    allowSearch={false}
                                                    className="py-2"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={12}>
                                        <Col xs={12} lg={4}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: `Ingresa cantidad de ${t('suites', 'recámaras')?.toLocaleLowerCase()}`
                                                    }
                                                ]}
                                                name={['attributes', 'suites']}
                                                label={t('suites', 'Recámaras')}
                                            >
                                                <InputNumber className="w-full" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} lg={4}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Ingresa cantidad de baños'
                                                    }
                                                ]}
                                                name={['attributes', 'bathrooms']}
                                                label={t('bathrooms', 'Baños')}
                                            >
                                                <InputNumber className="w-full" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} lg={5}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Ingresa cantidad de baños'
                                                    }
                                                ]}
                                                name={['attributes', 'parkings']}
                                                label={t('parkings', 'Estacionamientos')}
                                            >
                                                <InputNumber className="w-full" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} lg={5}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Ingresa m2 construídos'
                                                    }
                                                ]}
                                                name={['attributes', 'roofedSurface']}
                                                label={t('roofedSurface', 'm2 construidos')}
                                            >
                                                <InputNumber className="w-full" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Ingresa m2 totales'
                                                    }
                                                ]}
                                                name={['attributes', 'totalSurface']}
                                                label={t('totalSurface', 'm2 de terreno')}
                                            >
                                                <InputNumber className="w-full" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item name="services" label="Amenidades y Servicios">
                                                <PulppoMultiselect
                                                    options={Services.filter((svc) =>
                                                        [
                                                            'Aire Acondicionado',
                                                            'Alberca',
                                                            'Amueblado',
                                                            'Cocina Integral',
                                                            'Cuarto de Lavado',
                                                            'Estudio/Oficina',
                                                            'Gimnasio',
                                                            'Jacuzzi',
                                                            'Jardín',
                                                            'Seguridad privada'
                                                        ].includes(svc.name)
                                                    ).map((svc) => ({
                                                        key: svc.name,
                                                        value: svc.id,
                                                        label: t(svc.name, svc.name)
                                                    }))}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </TaskView>
                                <TaskView title="Imagen de portada">
                                    {image ? (
                                        <div className="relative">
                                            <img
                                                src={image}
                                                alt="acm image"
                                                className="w-full object-cover"
                                                style={{ height: '200px' }}
                                            />
                                            <div className="justofy-center absolute right-6 top-6 z-50 flex h-8 w-8 items-center rounded-full bg-black opacity-50">
                                                <DeleteOutlined
                                                    className="mx-auto text-white"
                                                    onClick={() => setImage(null)}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <FileUpload
                                            prefix={`property/${property?._id}`}
                                            multiple={false}
                                            showList={false}
                                            setPictures={(fileList) => {
                                                setImage(fileList?.[0]?.url);
                                            }}
                                            style={{ height: '200px' }}
                                            type="image"
                                            pictures={[]}
                                            description={
                                                <>
                                                    La imagen deben estar a 72 DPI, un tamaño no menor a 1640x1080
                                                    pixels y en formato JPG o PNG.
                                                </>
                                            }
                                        />
                                    )}
                                </TaskView>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
            <SimpleACMLoader loading={loading && simple.current} />
        </div>
    );
};

export default function ACMStep1({ property }) {
    return (
        <PropertyFormContextProvider>
            <ACM property={property}></ACM>
        </PropertyFormContextProvider>
    );
}
export const getServerSideProps = async (ctx) => {
    const { locale, params, query } = ctx;
    const cookies = nookies.get(ctx);
    const property =
        params.id == 'new-acm'
            ? {}
            : await axios
                  .get(`${api_url}/property/${params.id}`, {
                      headers: cookies['new-token']
                          ? { authorization: `Bearer ${cookies['new-token']}`, 'SKIP-IP-CHECK': 'true' }
                          : { 'pulppo-access-token': cookies.token }
                  })
                  .then((res) => res.data);
    if (query.contact) {
        property.contact = await axios
            .get(`${api_url}/contact/${query.contact}/simple`, {
                headers: cookies['new-token']
                    ? { authorization: `Bearer ${cookies['new-token']}`, 'SKIP-IP-CHECK': 'true' }
                    : { 'pulppo-access-token': cookies.token }
            })
            .then((res) => res.data);
    }
    if (process.env.NODE_ENV === 'development') {
        await i18n?.reloadResources();
    }
    return {
        props: {
            property,
            ...(await serverSideTranslations(locale, ['common', 'routes']))
        }
    };
};
