import { IProperty } from '@pulppo/pulppo-models';
import { NextImage } from '../Elements/NextImage';
import { parsePrice } from '../../utils/parser';
import dayjs from 'dayjs';
import { CalendarFilled, DeleteOutlined, EyeOutlined, ReloadOutlined } from '@ant-design/icons';
import Icon from '../Icon/Icon';
import { IconType } from '../../utils/constants';
import { Button } from 'antd';
import { showProperty } from '../../utils/showProperty';
import { useTranslate } from '@tolgee/react';

const ComparableCard = ({
    property,
    showActions,
    className,
    remove,
    restore,
    disabled
}: {
    property: IProperty;
    className?: string;
    showActions?: boolean;
    remove?: () => void;
    restore?: () => void;
    disabled?: boolean;
}) => {
    const { t } = useTranslate('common');
    return (
        <div
            className={`w-full border border-solid border-gray-200 p-2 ${
                property?.status?.last === 'cancelled' ? 'bg-light-background' : ''
            } ${className}`}
        >
            {property?.status?.last === 'cancelled' && (
                <div className="mb-3 mt-2 w-full rounded-md border border-solid border-strong-red bg-notification-red text-center text-strong-red">
                    Propiedad deshabilitada, reactivala para valuar
                </div>
            )}
            <div className="flex w-full items-center gap-4 border-b border-solid border-gray-200 pb-2">
                <div className="flex-0 h-fit shrink-0">
                    <NextImage
                        className="h-20 w-20"
                        src={property?.pictures?.[0]?.url}
                        alt="thumb"
                        showPreview={false}
                        width={250}
                    />
                </div>
                <div className="shrink-1 flex w-1/5 flex-1 flex-col justify-between">
                    <p className="w-full truncate text-lg font-medium">
                        {property?.address?.street || property?.listing?.title || '-'}
                    </p>
                    <p className="truncate text-base text-pulppo-status-dark-disabled">
                        {`${property?.address?.neighborhood?.name || property?.address?.city?.name}`}
                    </p>
                    <p className="text-base font-medium">
                        {`${parsePrice({
                            price: Math.round(property?.valuation?.value),
                            startStr: `${process.env.NEXT_PUBLIC_PRIMARY_CURRENCY} `
                        })} / m2`}
                    </p>
                </div>
                {showActions && (
                    <div className="hidden shrink-0 justify-end gap-1 md:flex">
                        {property?.status?.last === 'published' && (
                            <button
                                type="button"
                                key="trash"
                                className="flex h-10 w-10 items-center justify-center border border-solid border-gray-200 text-xl hover:bg-gray-100 disabled:cursor-default disabled:opacity-50"
                                onClick={() => remove()}
                                disabled={disabled}
                            >
                                <DeleteOutlined />
                            </button>
                        )}
                        {property?.status?.last === 'cancelled' && (
                            <button
                                type="button"
                                key="restore"
                                className="flex h-10 w-10 items-center justify-center border border-solid border-gray-200 text-xl hover:bg-gray-100 disabled:cursor-default disabled:opacity-50"
                                onClick={() => restore()}
                                disabled={disabled}
                            >
                                <ReloadOutlined />
                            </button>
                        )}

                        <div
                            key="watch"
                            className="flex h-10 w-10 cursor-pointer items-center justify-center border border-solid border-gray-200 text-xl hover:bg-gray-100"
                            onClick={() => {
                                showProperty({
                                    property,
                                    check: false,
                                    isMLS: true
                                });
                            }}
                        >
                            <EyeOutlined />
                        </div>
                    </div>
                )}
            </div>
            <div className="mt-2 flex w-full items-end justify-around">
                <p className="font-semibold">
                    {parsePrice({
                        price: property?.listing?.price?.price,
                        startStr: `${property?.listing?.price?.currency} `
                    })}
                </p>
                <div className="h-6 w-px bg-gray-200" />
                <div className="flex items-end justify-center gap-1">
                    <p className="font-semibold">
                        {dayjs()
                            .tz()
                            .diff(dayjs(property?.publishedAt || property?.createdAt).tz(), 'days')}
                    </p>
                    <p className="hidden text-sm md:block">días</p>
                    <CalendarFilled className="text-lg md:hidden" />
                </div>
                <div className="h-6 w-px bg-gray-200" />
                <div className="flex items-end justify-center gap-1">
                    <p className="font-semibold">{Math.round(property?.attributes?.roofedSurface)}</p>
                    <p className="hidden text-sm md:block">m2</p>
                    <Icon className="md:hidden" width={18} height={18} name={IconType.Mts} />
                </div>
                <div className="h-6 w-px bg-gray-200" />
                <div className="flex items-end justify-center gap-1">
                    <p className="font-semibold">{property?.attributes?.suites}</p>
                    <Icon className="md:hidden" width={18} height={18} name={IconType.Bed} />
                    <p className="hidden text-sm md:block">{t('suites', 'Recámaras')}</p>
                </div>
                <div className="h-6 w-px bg-gray-200" />
                <div className="flex items-end justify-center gap-1">
                    <p className="font-semibold">
                        {property?.attributes?.yearBuild > 0 ? property.attributes.yearBuild : new Date().getFullYear()}
                    </p>
                </div>
            </div>
            {showActions && (
                <div className="mt-3 flex w-full justify-between gap-3 md:hidden">
                    {property?.status?.last === 'published' && (
                        <Button
                            type="default"
                            className="flex-1 rounded-md font-medium"
                            onClick={() => {
                                remove();
                            }}
                            disabled={disabled}
                        >
                            Eliminar
                        </Button>
                    )}

                    {property?.status?.last === 'cancelled' && (
                        <Button
                            type="default"
                            className="flex-1 rounded-md font-medium"
                            onClick={() => {
                                restore();
                            }}
                            disabled={disabled}
                        >
                            Reactivar
                        </Button>
                    )}
                    <Button
                        type="default"
                        className="flex-1 rounded-md font-medium"
                        onClick={() => {
                            showProperty({ property });
                        }}
                    >
                        Ver propiedad
                    </Button>
                </div>
            )}
        </div>
    );
};

export default ComparableCard;
