import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { INote } from '@pulppo/pulppo-models';
import { Button, Input } from 'antd';
import { useEffect, useState } from 'react';
import Note from '../Elements/Note';

export const NoteDrawer = ({
    notes,
    visible,
    onSave,
    onClose
}: {
    visible: boolean;
    notes: INote[];
    onSave: (message: string) => void;
    onClose: () => void;
}) => {
    const [textInput, setTextInput] = useState('');

    useEffect(() => {
        setTextInput('');
    }, [visible]);

    return (
        <PulppoDrawer visible={visible} onClose={() => onClose()} title="Notas">
            <div className="flex h-full flex-col">
                <div className="flex-1">
                    <p className="mb-1 text-sm text-pulppo-primary-gray">Ingresar notas</p>
                    <Input.TextArea
                        value={textInput}
                        className="rounded-none"
                        onInput={(ev) => {
                            setTextInput(ev.currentTarget.value);
                        }}
                        placeholder="Escribe tus notas aqui..."
                        rows={5}
                    />
                    <div className="flex flex-col gap-2 py-4">
                        {notes
                            ?.sort((a, b) => b?.createdAt?.valueOf() - a?.createdAt?.valueOf())
                            .map((note, index) => <Note key={`note_${index}`} {...note} />)}
                    </div>
                </div>
                <div className="flex w-full items-center justify-center gap-3">
                    <Button
                        type="primary"
                        className="h-10 flex-1"
                        disabled={!textInput}
                        onClick={() => {
                            setTextInput('');
                            onSave(textInput);
                        }}
                    >
                        Guardar
                    </Button>
                </div>
            </div>
        </PulppoDrawer>
    );
};
