import { api_url, fetcher } from '../helpers/fetcher';
import { IResponse } from '../types';
import { MetricType } from '@pulppo/pulppo-models/build/enums/MetricType';

interface GetMetricUrl {
    type: 'company' | 'agent';
    id: string;
    select: Array<MetricType>;
    limit: number;
    currentWeek?: boolean;
    simple?: boolean;
}

interface GetMetric extends GetMetricUrl {}

export type ResponseMetric = {
    week: string;
    data: {
        firstResponseTime: number;
        avgResponseTime: number;
        interactions: number;
        visitRate: number;
        tasks: number;
        answeredLeads: number;
        unansweredLeads: number;
        properties: number;
        propertiesSale: number;
        visitsFinished: number;
        visitsTotal: number;
        tasksPending: number;
        tasksPendingClient: number;
        tasksExpiredClient: number;
        tasksPendingProperty: number;
        tasksExpiredProperty: number;
        offerRate: number;
        avgSuggestedProperties: number;
    };
};

export const getMetricsComplex = (type: MetricType) => {
    const newSelect: Array<MetricType> = [];
    if (type === MetricType.TasksPendingClient || type === MetricType.TasksExpiredClient) {
        newSelect.push(MetricType.TasksPendingClient);
        newSelect.push(MetricType.TasksExpiredClient);
    }
    if (type === MetricType.TasksPendingProperty || type === MetricType.TasksExpiredProperty) {
        newSelect.push(MetricType.TasksPendingProperty);
        newSelect.push(MetricType.TasksExpiredProperty);
    }
    if (type === MetricType.VisitRate || type === MetricType.VisitsTotal) {
        newSelect.push(MetricType.VisitsTotal);
        newSelect.push(MetricType.VisitRate);
    }
    if (type === MetricType.PropertiesSale || type === MetricType.Properties) {
        newSelect.push(MetricType.Properties);
        newSelect.push(MetricType.PropertiesSale);
    }

    newSelect.push(type);

    return newSelect;
};

const MetricService = {
    getUrl: ({ type, id, select, limit, currentWeek, simple }: GetMetricUrl) => {
        const newSelect = [...select];
        if (!simple) {
            for (const s of select) {
                newSelect.push(...getMetricsComplex(s));
            }
        }
        return `${api_url}/metrics/${type}/${id}/?${newSelect
            .map((s) => `select=${s}`)
            .join('&')}&limit=${limit}${currentWeek ? '&currentWeek=true' : ''}
    `;
    },
    get: async (props: GetMetric): Promise<IResponse<Array<ResponseMetric>>> => {
        return await fetcher(
            MetricService.getUrl({
                type: props.type,
                id: props.id,
                select: props.select,
                limit: props.limit,
                currentWeek: props.currentWeek,
                simple: props.simple
            })
        );
    }
};

export default MetricService;
