import { useState } from 'react';
// import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { PlusCircleOutlined } from '@ant-design/icons';
import { TrashIcon } from '@heroicons/react/outline';
import { Label } from '../ui/label';
import Button from './Button';
import { PulppoDrawer } from './PulppoDrawer';
import { PulppoSelect } from './PulppoSelect';
import dayjs from 'dayjs';
import 'dayjs/locale/es'; // For Spanish
dayjs.locale('es'); // Set default locale if needed

type TimeRange = {
    id: string;
    startTime: string;
    endTime: string;
};

type DayAvailability = TimeRange[];

type WeekAvailability = {
    [key: string]: DayAvailability;
};

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export const dayOfWeekToName = (day: string) => {
    // Create mapping for correct day numbers (Monday = 1, ..., Sunday = 7)
    const dayNumber = daysOfWeek.indexOf(day) + 1;
    // Use isoWeekday to treat Monday as 1 through Sunday as 7
    const date = dayjs().day(dayNumber);
    return capitalize(date.format('dddd'));
};

export default function AvailabilitySelector({
    value,
    onChange
}: {
    value?: WeekAvailability;
    onChange?: (value: WeekAvailability) => void;
}) {
    const timeOptions = new Array(24).fill(1).map((_, index) => dayjs().hour(index).startOf('hour').format('HH:mm'));
    const [showAvailabilityDrawer, setShowAvailabilityDrawer] = useState(false);

    const [availability, setAvailability] = useState<WeekAvailability>(() => {
        if (value) return value;
        const initial: WeekAvailability = {};
        daysOfWeek.forEach((day) => {
            initial[day] = [
                {
                    id: `${day}-range-1`,
                    startTime: '09:00',
                    endTime: '20:00'
                }
            ];
        });
        return initial;
    });

    const addTimeRange = (day: string) => {
        setAvailability((prev) => {
            const newRange = {
                id: `${day}-range-${prev[day].length + 1}`,
                startTime: dayjs(prev[day][prev[day].length - 1].endTime, 'HH:mm')
                    .add(1, 'hour')
                    .format('HH:mm'),
                endTime: dayjs(prev[day][prev[day].length - 1].endTime, 'HH:mm')
                    .add(3, 'hour')
                    .format('HH:mm')
            };
            return {
                ...prev,
                [day]: [...prev[day], newRange]
            };
        });
    };

    const removeTimeRange = (day: string, rangeId: string) => {
        setAvailability((prev) => ({
            ...prev,
            [day]: prev[day].filter((range) => range.id !== rangeId)
        }));
    };

    const updateTimeRange = (day: string, rangeId: string, field: 'startTime' | 'endTime', value: string) => {
        setAvailability((prev) => ({
            ...prev,
            [day]: prev[day].map((range) => (range.id === rangeId ? { ...range, [field]: value } : range))
        }));
    };

    return (
        <>
            <PulppoDrawer
                title="Disponibilidad del inmueble para agendar visitas"
                visible={showAvailabilityDrawer}
                onClose={() => {
                    setShowAvailabilityDrawer(false);
                }}
                bodyStyle={{
                    height: '100%'
                }}
            >
                <div className="space-between flex h-full min-h-[1px] w-full flex-1 flex-col">
                    <div className="flex-1 space-y-2">
                        {daysOfWeek.map((day) => (
                            <div
                                key={day}
                                className="flex w-full items-center justify-between gap-4 border-b border-solid border-gray-200 pb-2"
                            >
                                <h3 className="font-medium">{dayOfWeekToName(day)}</h3>

                                <div className="w-3/4 space-y-2">
                                    {availability[day].map((range, index) => (
                                        <div key={range.id} className="flex flex-wrap items-center gap-2">
                                            <div className="min-w-[100px] flex-1">
                                                <PulppoSelect
                                                    value={range.startTime}
                                                    onChange={(value) =>
                                                        updateTimeRange(day, range.id, 'startTime', value)
                                                    }
                                                    options={timeOptions.map((opt) => ({
                                                        label: opt,
                                                        value: opt,
                                                        key: `${day}-${range.id}-${opt}-start`,
                                                        disabled:
                                                            availability[day][index - 1] &&
                                                            dayjs(opt, 'HH:mm').isBefore(
                                                                dayjs(
                                                                    availability[day][index - 1]?.endTime,
                                                                    'HH:mm'
                                                                ).add(1, 'hour')
                                                            )
                                                    }))}
                                                />
                                                {/* <PulppoSelectItem value={range.startTime} />
                                                </PulppoSelect>
                                                    value={range.startTime}
                                                    onValueChange={(value) =>
                                                        updateTimeRange(day, range.id, 'startTime', value)
                                                    }
                                                >
                                                    <SelectTrigger id={`${range.id}-start`}>
                                                        <SelectValue placeholder="Select start time" />
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        {timeOptions.map((time) => (
                                                            <SelectItem key={`${range.id}-start-${time}`} value={time}>
                                                                {time}
                                                            </SelectItem>
                                                        ))}
                                                    </SelectContent>
                                                </Select> */}
                                            </div>
                                            <span>-</span>

                                            <div className="min-w-[100px] flex-1">
                                                <PulppoSelect
                                                    value={range.endTime}
                                                    onChange={(value) =>
                                                        updateTimeRange(day, range.id, 'endTime', value)
                                                    }
                                                    options={timeOptions.map((opt) => ({
                                                        label: opt,
                                                        value: opt,
                                                        disabled: dayjs(opt, 'HH:mm').isBefore(
                                                            dayjs(range.startTime, 'HH:mm').add(1, 'hour')
                                                        ),
                                                        key: `${day}-${range.id}-${opt}-end`
                                                    }))}
                                                />
                                            </div>

                                            <Button
                                                type="ghost"
                                                className="h-8 w-8"
                                                onClick={() => removeTimeRange(day, range.id)}
                                            >
                                                <TrashIcon className="h-4 w-4" />
                                                <span className="sr-only">Eliminar rango de tiempo</span>
                                            </Button>
                                            <Button
                                                type="ghost"
                                                className={
                                                    availability[day].length - 1 === index ? 'visible' : 'invisible'
                                                }
                                                onClick={() => addTimeRange(day)}
                                            >
                                                <PlusCircleOutlined className="h-4 w-4" />
                                                <span className="sr-only">Añadir Rango de Tiempo</span>
                                            </Button>
                                        </div>
                                    ))}
                                </div>
                                {availability[day].length === 0 && (
                                    <Button type="secondary" className="w-full" onClick={() => addTimeRange(day)}>
                                        <PlusCircleOutlined className="h-4 w-4" />
                                        Añadir Rango de Tiempo
                                    </Button>
                                )}
                            </div>
                        ))}
                    </div>
                    <Button
                        type="primary"
                        onClick={() => {
                            onChange?.(availability);
                            setShowAvailabilityDrawer(false);
                        }}
                    >
                        Guardar
                    </Button>
                </div>
            </PulppoDrawer>
            <Button type="secondary" className="w-full" onClick={() => setShowAvailabilityDrawer(true)}>
                Configurar disponibilidad
            </Button>
        </>
    );
}
