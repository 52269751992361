import useSWR from 'swr';
import { fetcher } from '../../helpers/fetcher';
import { IResponse } from '../../types';
import MetricService, { ResponseMetric } from '../../services/metricService';
import { MetricType } from '@pulppo/pulppo-models/build/enums/MetricType';
import useUser from '../useUser';
import useMetricSearchId from './useMetricSearchId';

interface Props {
    currentWeek: boolean;
    select: Array<MetricType>;
    limit: number;
    agentId?: string;
    simple?: boolean;
}

const useMetric = ({ select, agentId, limit, currentWeek, simple = true }: Props) => {
    const user = useUser((u) => u.user);
    const {
        state: { id, statsView }
    } = useMetricSearchId({ agentId });
    const { data, isLoading } = useSWR<IResponse<Array<ResponseMetric>>>(
        user &&
            select?.length > 0 &&
            MetricService.getUrl({
                type: statsView && !agentId ? 'company' : 'agent',
                id,
                select,
                limit,
                currentWeek,
                simple
            }),
        fetcher
    );

    return {
        state: {
            hasError: !isLoading && !data?.success,
            data: data?.data || [
                {
                    week: '2023-01-01',
                    data: {
                        firstResponseTime: 0,
                        avgResponseTime: 0,
                        interactions: 0,
                        visitRate: 0,
                        tasks: 0,
                        answeredLeads: 0,
                        unansweredLeads: 0,
                        properties: 0,
                        propertiesSale: 0,
                        visitsFinished: 0,
                        visitsTotal: 0,
                        tasksPending: 0,
                        tasksPendingClient: 0,
                        tasksExpiredClient: 0,
                        tasksPendingProperty: 0,
                        tasksExpiredProperty: 0,
                        offerRate: 0,
                        avgSuggestedProperties: 0
                    }
                }
            ],
            isLoading
        }
    };
};

export default useMetric;
