import { InputNumber } from 'antd';
import { PercentageOutlined } from '@ant-design/icons';
import { parsePrice } from '../../utils/parser';
import { IProperty } from '@pulppo/pulppo-models';
import { useContext } from 'react';
import { PropertyFormContext } from '../../contexts/PropertyFormContext';
import { DEFAULT_CURRENCY } from '../../utils/constants';
import { useCurrencyConverter } from '../../hooks/useCurrencyConverter';
import { IVA } from '@pulppo/pulppo-models/build/utils/operation';

export const IVAInput = ({
    property,
    disabled,
    value: initialValue,
    onChange
}: {
    property?: Partial<IProperty>;
    disabled?: boolean;
    value?: number;
    onChange?: (value: number) => void;
}) => {
    const { currencyConverter } = useCurrencyConverter();
    const propertyForm = useContext(PropertyFormContext);
    const { formData } = property ? { formData: property } : propertyForm || {};
    const value = initialValue || formData?.contract?.comission;

    const valuePrice =
        formData?.listing?.value ||
        currencyConverter(
            formData?.listing?.price?.currency || DEFAULT_CURRENCY,
            DEFAULT_CURRENCY,
            formData?.listing?.price?.price || 0
        );

    const divisor = formData?.listing?.operation === 'sale' ? 100 : 1;

    return (
        <div className="flex gap-x-2">
            <div className="w-full">
                <label className="text-sm font-semibold">Sin IVA</label>
                <div className="relative mt-2">
                    <InputNumber
                        disabled={disabled}
                        min={formData?.listing?.operation === 'rent' ? 0.5 : 1}
                        max={100}
                        value={parseFloat(value?.toFixed(2))}
                        onChange={(value) => {
                            onChange?.(value);
                        }}
                        placeholder={formData?.listing?.operation === 'rent' ? 'Desde 1' : 'Desde 1%'}
                        className="flex h-[38px] w-full items-center [&>div>input]:text-sm"
                    />
                    {formData?.listing?.operation === 'rent' ? (
                        <p className="absolute inset-y-0 right-0 flex items-center bg-pulppo-secondary-gray-disabled px-2">
                            Meses
                        </p>
                    ) : (
                        <PercentageOutlined className="absolute inset-y-0 right-0 flex w-[38px] justify-center bg-pulppo-secondary-gray-disabled text-[13px] text-pulppo-primary-black" />
                    )}
                </div>
                <p className="mt-0.5 text-xs">
                    {`Total sin IVA en ${process.env.NEXT_PUBLIC_PRIMARY_CURRENCY || 'MXN'} `}
                    {parsePrice({
                        price: valuePrice * (value / divisor),
                        startStr: '$'
                    })}
                </p>
            </div>
            <div className="w-full">
                <label className="text-sm font-semibold">Con IVA</label>
                <div className="relative mt-2">
                    <InputNumber
                        disabled={disabled}
                        min={formData?.listing?.operation === 'rent' ? 0.5 : 1}
                        max={100}
                        value={parseFloat((value * IVA)?.toFixed(2))}
                        onChange={(value) => {
                            console.log({
                                value,
                                IVA
                            });
                            onChange?.(Number((value / IVA).toFixed(2)));
                        }}
                        placeholder={formData?.listing?.operation === 'rent' ? 'Desde 1' : 'Desde 1%'}
                        className="flex h-[38px] w-full items-center [&>div>input]:text-sm"
                    />
                    {formData?.listing?.operation === 'rent' ? (
                        <p className="absolute inset-y-0 right-0 flex items-center bg-pulppo-secondary-gray-disabled px-2">
                            Meses
                        </p>
                    ) : (
                        <PercentageOutlined className="absolute inset-y-0 right-0 flex w-[38px] justify-center bg-pulppo-secondary-gray-disabled text-[13px] text-pulppo-primary-black" />
                    )}
                </div>
                <p className="mt-0.5 text-xs">
                    {`Total con IVA en ${process.env.NEXT_PUBLIC_PRIMARY_CURRENCY || 'MXN'} `}
                    {parsePrice({
                        price: valuePrice * ((value + IVA) / divisor),
                        startStr: '$'
                    })}
                </p>
            </div>
        </div>
    );
};
