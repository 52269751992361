import { LoadingOutlined, PaperClipOutlined, SendOutlined } from '@ant-design/icons';
import { INote, IOperation } from '@pulppo/pulppo-models';
import { Button, Input, Modal, Typography } from 'antd';
import Upload, { RcFile } from 'antd/lib/upload';
import { useState } from 'react';
import { StepProps } from './OperationStep';
import { MapUserToSimpleUser } from '../../utils/MapContactToSimpleUser';
import Note from '../Elements/Note';
import { ImageUploaderService } from '../../services/ImageUploaderService';
import axios from '../../utils/axios';
import { api_url } from '../../helpers/fetcher';
import useUser from '../../hooks/useUser';
import { useShallow } from 'zustand/react/shallow';
import ButtonBottomStep from './ButtonBottomStep';

export const NotesStep = ({
    onPrevious,
    onFinish,
    operation,
    setOperation
}: StepProps & {
    operation: Partial<IOperation>;
    setOperation: (operation: Partial<IOperation> | ((operation: Partial<IOperation>) => Partial<IOperation>)) => void;
}) => {
    const user = useUser(useShallow((state) => state.user));
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState<INote>({} as any);
    const notes = (operation?.notes || []).map((note) => (
        <Note key={`note-${note?._id || note?.content?.trim()}`} {...note} />
    ));

    return (
        <div className="h-full [&>.ant-spin-nested-loading>.ant-spin-container]:h-full [&>.ant-spin-nested-loading]:h-full">
            <div className="flex h-full w-full flex-col justify-between">
                <div className="container-operation-form mx-auto w-full">
                    <Typography.Title level={3} className="mb-0">
                        Notas
                    </Typography.Title>
                    <p className="text-pulppo-status-dark-disabled">
                        Puedes dejar comentarios y adjuntar archivos, los mismos serán agendados con fecha y hora.
                    </p>

                    <div className="mt-6 flex items-stretch gap-2">
                        <Input
                            value={note.content}
                            onChange={(val) => {
                                setNote((note) => ({
                                    ...note,
                                    createdAt: new Date(),
                                    creator: MapUserToSimpleUser(user || operation?.buyer?.contact),
                                    content: val.target?.value
                                }));
                            }}
                            className="flex-1 rounded-none py-2"
                            placeholder="Escribe el una nota aquí..."
                        />
                        <Upload
                            className="p-0"
                            multiple={false}
                            showUploadList={false}
                            customRequest={async ({ file, ...events }) => {
                                if (file) {
                                    ImageUploaderService.upload(
                                        file as RcFile,
                                        `property/notes/${operation?._id}/${(file as RcFile)?.name?.replace(
                                            /\s/gi,
                                            '_'
                                        )}`,
                                        events,
                                        true
                                    ).catch((err) => console.error(`No se pudieron subir los archivos ${err}`));
                                }
                            }}
                            onChange={(info) => {
                                const { status } = info.file;
                                if (status === 'uploading') {
                                    setLoading(true);
                                }

                                if (status === 'done') {
                                    const newFileName = info.file.response;
                                    setNote((n) => ({
                                        ...n,
                                        createdAt: new Date(),
                                        creator: MapUserToSimpleUser(user),
                                        files: [...(n.files || []), newFileName]
                                    }));

                                    setLoading(false);
                                } else if (status === 'error') {
                                    Modal.error({
                                        title: `${info.file.name} file upload failed.`
                                    });
                                }
                            }}
                        >
                            <Button className="h-auto rounded-none py-2">
                                {loading ? <LoadingOutlined /> : <PaperClipOutlined />}
                            </Button>
                        </Upload>
                        <Button
                            onClick={async () => {
                                if (operation?._id)
                                    await axios
                                        .post(`${api_url}/operation/${operation._id}/notes`, note)
                                        .catch((err) => console.error(err));
                                setOperation((o) => ({
                                    ...o,
                                    notes: [...(o.notes || []), note]
                                }));
                                setNote({} as any);
                            }}
                            className="h-auto rounded-none"
                        >
                            <SendOutlined />
                        </Button>
                    </div>
                    {note.files?.length ? (
                        <div className="mt-4 flex flex-wrap items-center gap-2">
                            {note.files.map((file) => (
                                <div
                                    key={file}
                                    onClick={() => {
                                        const a = document.createElement('a');
                                        a.href = file;
                                        a.download = decodeURIComponent(file).split('/').pop();
                                        document.body.appendChild(a);
                                        a.target = '_blank';
                                        a.click();
                                        document.body.removeChild(a);
                                    }}
                                    className="shrink-0 cursor-pointer rounded-full bg-gray-200 px-4 py-1"
                                >
                                    <PaperClipOutlined className="mr-2" />
                                    {decodeURIComponent(file).split('/').pop()}
                                </div>
                            ))}
                        </div>
                    ) : null}
                    <div className="mt-6 flex w-full flex-col gap-2">{notes}</div>
                </div>
                <ButtonBottomStep onFinish={onFinish} onPrevious={onPrevious} />
            </div>
        </div>
    );
};
