import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { api_url, fetcher } from '../../helpers/fetcher';
import PropertyDetail, { shareProperties } from '../PropertyDetail/PropertyDetail';
import { useRouter } from 'next/router';
import useIsMobile from '../../hooks/isMobile';
import { UserContext } from '../../contexts/UserContext';
import { ShowContactList } from '../Contact/SimpleContactList';
import { PropertyCard } from '../Elements/PropertyCard';
import PropertyMap from './PropertyMap';
import { IProperty, ISimpleUser } from '@pulppo/pulppo-models';
import { PulppoLoader } from '../Elements/PulppoLoader';
import { PropertyStatusMapper } from '../../utils/status';
import { EmptySearchCard } from '../Elements/EmptySearchCard';
import { PropertyListHeader } from '../Elements/PropertyListHeader';
import { PropertyListFooter } from '../Elements/PropertyListFooter';
import { PropertyShareDrawer } from '../Elements/PropertyShareDrawer';
import { PropertyListHeaderMobile } from '../Elements/PropertyListHeaderMobile';
import { fakeProperties } from '../../utils/fake';
import ExclusiveTag from './ExclusiveTag';
import { isExclusive } from '../../utils/property';
import mapboxgl from 'mapbox-gl';
import { useTranslate } from '@tolgee/react';
import { COLORS } from '../../utils/color';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import useMLSInfo from '../../hooks/useMLSInfo';
import dayjs from 'dayjs';

interface Show {
    exclusive?: boolean;
    pulppo?: boolean;
    mls?: boolean;
}

export interface PropertyListProps {
    setSelectedProperties?: any;
    actions?: ReactNode;
    propertyDetailActions?: (str: any) => ReactNode | string;
    emptyMessage?: any;
    additionalProperties?: IProperty[];
    showAddButton?: boolean;
    showFilters?: boolean;
    onClick?: (property: any) => void;
    inCollection?: boolean;
    emptyFooter?: any;
    saveFilters?: (filters: any) => void;
    filters: any;
    setFilters: (filters: any) => void;
    onAdd?: (properties: any) => Promise<void>;
    onShareCollection?: () => void;
    checkable?: boolean;
    openPropertyDescription?: boolean;
    isPublic?: boolean;
    showTab?: Show;
    showMorePropertiesOption?: boolean;
    showEmptySearchCard?: boolean;
}

const getTabs = ({
    user,
    isPublic,
    count,
    additionalProperties,
    show,
    lastUpdated
}: {
    user?: ISimpleUser;
    isPublic?: boolean;
    count: {
        mls: number;
        pulppo: number;
        exclusive: number;
        company: number;
        user: number;
    };
    additionalProperties?: Array<IProperty>;
    show?: Show;
    lastUpdated?: Date;
}) => {
    return [
        user &&
            !isPublic && {
                label: `Propias (${count.user?.toLocaleString()})`,
                value: 'user',
                group: 'tab',
                description: `Propiedades donde yo soy el productor.`
            },
        user?.company &&
            !isPublic && {
                label: `${user?.company?.name} (${count.company?.toLocaleString()})`,
                value: 'company',
                group: 'tab',
                description: `Propiedades de todos los asesores de ${user?.company?.name}`
            },
        show.exclusive &&
            user?.company &&
            !isPublic && {
                label: `Exclusivas (${count?.exclusive?.toLocaleString()})`,
                value: 'exclusive',
                group: 'tab',
                description: `Propiedades exclusivas de ${process.env.NEXT_PUBLIC_APP_NAME || 'Pulppo'}`
            },
        show.pulppo && {
            label: `${isPublic ? 'Recomendadas' : process.env.NEXT_PUBLIC_APP_NAME || 'Pulppo'} (${count.pulppo?.toLocaleString()})`,
            group: 'tab',
            value: 'pulppo',
            description: `Propiedades de toda la comunidad de ${process.env.NEXT_PUBLIC_APP_NAME || 'Pulppo'} `
        },
        additionalProperties?.length && {
            label: `Compartidas (${additionalProperties.length})`,
            value: 'shared',
            description: `Propiedades compartidas entre el asesor y el cliente.`
        },
        show.mls && {
            label: (
                <Tooltip overlay={<p>{`Actualizado el ${dayjs(lastUpdated).format('DD/MM/YYYY')}`}</p>}>
                    <p>
                        {`${isPublic ? 'Todas' : 'MLS'} (${count.mls?.toLocaleString()})`} <InfoCircleOutlined />{' '}
                    </p>
                </Tooltip>
            ),
            group: 'tab',
            value: 'mls',
            description: `Propiedades de varias bolsas inmobiliarias. Se actualiza la disponibilidad todos los viernes, tambien al consultar una en especifico.`
        }
    ].filter(Boolean);
};

const PAGE_SIZE = 52;

export const PropertyList = ({
    actions = undefined,
    showAddButton = true,
    propertyDetailActions = null as (str: any) => ReactNode | string,
    showFilters = true,
    onClick = undefined,
    isPublic = false,
    emptyMessage = '' as any,
    inCollection = false,
    additionalProperties = [],
    showTab = {
        exclusive: false,
        mls: true,
        pulppo: true
    },
    filters = [],
    saveFilters = () => {},
    setFilters: setInitialFilters = () => {},
    onAdd = async () => {},
    onShareCollection = () => {},
    checkable = true,
    emptyFooter = null,
    openPropertyDescription = true,
    showMorePropertiesOption = true,
    showEmptySearchCard = true
}: PropertyListProps) => {
    useTranslate('property_detail');
    const router = useRouter();
    const { user } = useContext(UserContext);
    const isMobile = useIsMobile();
    const [property, setProperty] = useState(null);
    const [center, setCenter] = useState<mapboxgl.LngLatLike>(undefined);
    const [hoveredProperty, setHoveredProperty] = useState(null);
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [refreshMap, setRefreshMap] = useState(Date.now());
    const [refreshOnNextFetch, setRefreshOnNextFetch] = useState(true);
    const [properties, setProperties] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
    const [count, setCount] = useState({
        mls: 0,
        pulppo: 0,
        exclusive: 0,
        company: 0,
        user: 0,
        notAvailable: 0
    });
    const { t } = useTranslate('common');
    const [propertiesLoading, setPropertiesLoading] = useState(false);
    const [view, setView] = useState('grid');
    const [showAddDrawer, setShowAddDrawer] = useState(false);
    const tabRef = useRef(filters?.tab);
    const grid = useRef(null);
    const queryRef = useRef<string>();
    const [showCreateButton, setShowCreateButton] = useState(false);
    const {
        state: { lastUpdated }
    } = useMLSInfo();
    if (!user && property) {
        property.skipCheck = true;
    }

    useEffect(() => {
        tabRef.current = filters?.tab;
    }, [filters?.tab]);

    useEffect(() => {
        setView(localStorage.getItem('view') || 'grid');
    }, []);

    useEffect(() => {
        localStorage.setItem('view', view);
    }, [view]);

    useEffect(() => {
        if (additionalProperties?.length) setProperties(additionalProperties);
    }, [additionalProperties?.length]);

    useEffect(() => {
        if (router.query.selected && router.query.selected !== 'undefined' && properties && !property) {
            fetcher(`${api_url}/property/${router.query.selected}`).then((res) => {
                setProperty(res);
            });
        }
    }, [router.query.selected, properties]);

    useEffect(() => {
        setShowCreateButton(user && showAddButton);
    }, [user, showAddButton]);

    const setFilters = (func: (oldFilters: any) => { offset?: number; tab?: any }) => {
        setInitialFilters((oldFilters: { [x: string]: any }) => {
            return func(oldFilters);
        });
    };

    const tabs = getTabs({
        user,
        isPublic,
        count,
        additionalProperties,
        show: showTab,
        lastUpdated
    });

    useEffect(() => {
        if (!user?.uid) {
            const properties = fakeProperties?.filter((p) => p.listing?.operation?.includes(filters?.operation));
            setCount({ pulppo: properties.length } as any);
            setProperties(properties);
            return;
        }
        filters = Object.fromEntries(Object.entries(filters || {}).filter(([_, v]) => v));
        saveFilters(filters);

        if (!Object.keys(filters).length) {
            return;
        }

        const query = new URLSearchParams({
            ...filters,
            producers: filters?.producers?.map((u: { uid: string }) => u.uid) || '',
            sellers: filters?.sellers?.map((u: { uid: string }) => u.uid) || '',
            offset: +(filters?.offset || 0),
            limit: +(filters?.limit || PAGE_SIZE),
            coordinates: filters?.coordinates?.join
                ? (filters?.coordinates as any[]).join?.(',')
                : filters?.coordinates?.split(',')?.length === 8
                  ? filters?.coordinates
                  : localStorage.getItem('coordinates')?.replace('[', '')?.replace(']', '') || ''
        });
        if (filters?.addresses && filters?.addresses?.[0]?.name) {
            for (const address of filters.addresses) {
                if (address.type && !address[address.type]) {
                    address[address.type] = {
                        id: address.id,
                        name: address.name
                    };
                } else if (!address.type) {
                    address.type = address.neighborhood ? 'neighborhood' : address.city ? 'city' : 'state';
                }
            }
            query.set('addresses', JSON.stringify(filters?.addresses));
            const ls = localStorage?.getItem('recent_locations');

            let recentAddresses = ls ? JSON.parse(ls) : [];
            if (!recentAddresses || !Array.isArray(recentAddresses)) {
                recentAddresses = [];
            }
            for (const newAddress of filters?.addresses) {
                const index = recentAddresses.findIndex((ra: { id: string }) => ra.id === newAddress.id);
                if (index == -1) {
                    recentAddresses = [newAddress, ...recentAddresses];
                } else {
                    recentAddresses[index] = newAddress;
                }
            }
            const aux = [...filters?.addresses];
            localStorage.setItem(
                'recent_locations',
                JSON.stringify(
                    [
                        ...aux.reverse(),
                        ...recentAddresses.filter((elem) => !filters?.addresses.some((ad) => ad.id === elem.id))
                    ].slice(0, 5)
                )
            );
        }
        if (filters?.price) {
            query.set('price', `${filters?.price.min || 0},${filters?.price.max || Number.POSITIVE_INFINITY}`);
            query.set('currency', filters?.price?.currency || process.env.NEXT_PUBLIC_PRIMARY_CURRENCY);
        }
        if (filters?.totalSurface) {
            query.set('totalSurface', JSON.stringify(filters.totalSurface));
        }
        if (filters?.roofedSurface) {
            query.set('roofedSurface', JSON.stringify(filters.roofedSurface));
        }
        query.forEach((val, key) => {
            if (!val) query.delete(key);
        });
        query.delete('source');
        const queryString = query.toString();
        if (queryString === queryRef.current) {
            return;
        }
        query.delete('tab');

        queryRef.current = queryString;

        if (!filters?.status && !inCollection) {
            query?.set('draft', 'true');
        }
        fetcher(`${api_url}/property/countAll?${query}`)
            .then((res) => setCount(res))
            .catch(() =>
                setCount({
                    mls: 0,
                    pulppo: 0,
                    company: 0,
                    user: 0,
                    exclusive: 0,
                    notAvailable: 0
                })
            );
        query.delete('draft');
        if (filters?.tab === 'pulppo') {
            // query.delete('quality');
        } else if (filters?.tab === 'mls') {
            // query.delete('amenities');
            // query.delete('company');
            // query.delete('quality');
            // query.delete('valuation');
        } else if (filters?.tab === 'exclusive' && typeof filters?.isExclusive === 'undefined') {
            // query.delete('quality');
            // query.delete('valuation');
            query.set('isExclusive', 'true');
        } else if (filters?.tab === 'user') {
            if (user?._id) query.set('agent', user?._id as string);

            // query.delete('company');
        } else if (user?.company?._id && !filters?.company) {
            query.set('company', user?.company?._id as string);
        }
        if (filters?.tab === 'shared') {
            setProperties(additionalProperties);
        }

        if (filters?.tab === 'user' && !filters?.sort) {
            query?.set('sort', 'editedAt,-1');
        }
        if (filters?.tab === 'user' && !filters?.status && !inCollection) {
            query?.set('draft', 'true');
        }
        query.set('project', 'contract');
        let url = `${api_url}/${filters?.tab === 'mls' ? 'MLS' : 'property'}/search?${query}`;
        if (filters?.tab !== 'shared') {
            setPropertiesLoading(true);

            fetcher(url)
                .then((properties) => {
                    setPropertiesLoading(false);
                    if (!properties?.count && filters?.addresses?.length) {
                        fetcher(`${api_url}/location/${filters?.addresses[filters?.addresses.length - 1]?.id}`).then(
                            (res) => {
                                if (res?.location?.coordinates?.[0]) {
                                    setCenter(res?.location?.coordinates);
                                    setRefreshMap((r) => r + 1);
                                }
                            }
                        );
                    }
                    if (filters?.tab === tabRef?.current) {
                        setProperties(properties?.count ? properties?.result : []);
                    }
                    if (refreshOnNextFetch) {
                        setRefreshMap((refreshMap) => refreshMap + 1);
                        setRefreshOnNextFetch(false);
                    }
                })
                .catch(() => {
                    if (filters?.tab === tabRef?.current) setProperties([]);
                    setPropertiesLoading(false);
                });
        }

        if (grid && grid.current) {
            grid.current.scrollTo(0, 0);
        }
    }, [filters, user?.uid]);

    const onShare = async () => {
        if (inCollection) {
            if (user) setShowAddDrawer(true);
            else onAdd(selectedProperties);
            return;
        }
        const { destroy } = ShowContactList({
            onSelect: (contact) => {
                shareProperties({
                    properties: selectedProperties,
                    contact,
                    user,
                    t,
                    onSend: () => {
                        destroy({
                            triggerCancel: true
                        });
                        setSelectedProperties([]);
                        setIsAllSelected(false);
                    }
                });
            },
            onAdd: (contact) => {
                shareProperties({
                    properties: selectedProperties,
                    contact,
                    user,
                    t,
                    onSend: () => {
                        destroy({
                            triggerCancel: true
                        });
                        setSelectedProperties([]);
                        setIsAllSelected(false);
                    }
                });
            }
        });
    };

    const handleSelectAll = async () => {
        const query = new URLSearchParams({
            ...filters,
            producers: filters?.producers?.map((u: { uid: string }) => u.uid) || '',
            sellers: filters?.sellers?.map((u: { uid: string }) => u.uid) || '',
            offset: 0,
            limit: 99999,
            coordinates: filters?.coordinates?.join
                ? (filters?.coordinates as any[]).join?.(',')
                : filters?.coordinates?.split(',')?.length === 8
                  ? filters?.coordinates
                  : localStorage.getItem('coordinates')?.replace('[', '')?.replace(']', '') || ''
        });
        if (filters?.tab === 'company' && user?.company?._id) {
            query.set('company', user.company._id.toString());
        }
        if (filters?.tab === 'user' && user?._id) {
            query.set('agent', user._id.toString());
        }

        try {
            let url = `${api_url}/property/search?${query}`;
            const properties = await fetcher(url);
            setSelectedProperties(properties.result || []);
            setIsAllSelected(true);
        } catch (error) {
            console.error('Error fetching properties:', error);
        }
    };

    const getFilteredPropertiesByTab = (properties: IProperty[], tab: string) => {
        switch (tab) {
            case 'company':
                return properties.filter((prop) => prop.company?._id === user?.company?._id);
            case 'user':
                return properties.filter((prop) => prop.agent?._id === user?._id);
            default:
                return properties;
        }
    };

    const handleTabChange = async (newTab: string): Promise<void> => {
        const filteredProperties = getFilteredPropertiesByTab(selectedProperties, newTab);
        setIsAllSelected(filteredProperties.length === count[newTab]);

        setFilters((oldFilters) => ({
            ...oldFilters,
            tab: newTab
        }));
    };

    const updateIsAllSelected = (properties: IProperty[]) => {
        const filteredProperties = getFilteredPropertiesByTab(properties, filters?.tab);
        setIsAllSelected(filteredProperties.length === count[filters.tab]);
    };

    return (
        <div className="flex h-full flex-col">
            <div className="shrink-0">
                <PropertyListHeader
                    showCreateButton={showCreateButton}
                    setFilters={setFilters}
                    filters={filters}
                    user={user}
                    setProperty={setProperty}
                    view={view}
                    setView={setView}
                    showFilters={showFilters}
                    onShare={onShare}
                    tabs={tabs}
                    actions={actions}
                    selectedProperties={selectedProperties}
                    setSelectedProperties={setSelectedProperties}
                    onSelectAll={handleSelectAll}
                    isAllSelected={isAllSelected}
                    inCollection={inCollection}
                    onTabChange={handleTabChange}
                    setIsAllSelected={setIsAllSelected}
                />
                <PropertyListHeaderMobile
                    user={user}
                    tabs={tabs}
                    filters={filters}
                    setFilters={setFilters}
                    setProperty={setProperty}
                />
            </div>
            <div className="flex-1 overflow-y-auto overflow-x-hidden px-4" ref={grid}>
                {view === 'map' ? (
                    <div className="w-full">
                        <PropertyMap
                            count={count[filters?.tab]}
                            center={center}
                            refreshMap={refreshMap}
                            searchArea={(coordinates) => {
                                setFilters((filters) => ({
                                    ...filters,
                                    offset: 0,
                                    address: [],
                                    addresses: null,
                                    coordinates: coordinates.flat().join(',')
                                }));
                            }}
                            hoveredProperty={hoveredProperty}
                            properties={properties?.filter((prop) => prop.address?.location?.coordinates?.length === 2)}
                            selectedProperties={selectedProperties?.filter(
                                (prop) => prop.address?.location?.coordinates?.length === 2
                            )}
                            onClick={(property) => setProperty(property)}
                        />
                    </div>
                ) : properties.length === 0 && !propertiesLoading ? (
                    showEmptySearchCard ? (
                        <div className="flex h-full w-full flex-col items-center justify-center bg-white">
                            <div className="w-full lg:w-1/3">
                                <EmptySearchCard
                                    hoverable={false}
                                    title="No se encontraron propiedades"
                                    description={
                                        count?.mls === 0 && count.pulppo === 0
                                            ? 'En este momento, no hay propiedades que se ajusten a tus criterios de búsqueda. Para aumentar tus opciones, te sugerimos revisar y reducir algunos de los filtros aplicados'
                                            : 'Por el momento no hay propiedades para tu búsqueda, prueba modificando alguno de tus filtros.'
                                    }
                                    footer={emptyFooter}
                                    count={count}
                                    filters={filters}
                                    setFilters={setFilters}
                                    extra={emptyMessage}
                                />
                            </div>
                        </div>
                    ) : null
                ) : (
                    <PulppoLoader loading={propertiesLoading} className="mt-2 h-full">
                        <div className="flex w-full flex-1 flex-wrap items-stretch pt-2 md:pt-0">
                            {properties?.length === 0 && propertiesLoading && <div className="h-96"></div>}
                            {properties.map((property: IProperty) => (
                                <div
                                    key={`property-list-${property._id}`}
                                    className="w-full shrink-0 px-0 py-2 md:w-1/2 md:p-2 lg:w-1/3 xl:w-1/4"
                                >
                                    <PropertyCard
                                        badges={
                                            ['company', 'user'].includes(filters?.tab)
                                                ? [
                                                      ...(property?.status?.last !== 'published'
                                                          ? [
                                                                {
                                                                    ...PropertyStatusMapper[property.status.last],
                                                                    label: t(
                                                                        PropertyStatusMapper[property.status.last]
                                                                            ?.label
                                                                    )
                                                                }
                                                            ]
                                                          : []),
                                                      ...(['published'].includes(property?.status?.last) &&
                                                      !property?.listing?.publishOnPortals
                                                          ? [
                                                                {
                                                                    label: 'No difundida en portales',
                                                                    color: COLORS.status.dark.error
                                                                }
                                                            ]
                                                          : []),
                                                      ...[
                                                          (property?.mostAdvancedOperationStatus === 'offer' ||
                                                              property?.mostAdvancedOperationStatus ===
                                                                  'offer_blocked') && {
                                                              label: t('CON OFERTA'),
                                                              color: '#44be6d'
                                                          },
                                                          property?.mostAdvancedOperationStatus === 'contract' && {
                                                              label: t('EN CONTRATO'),
                                                              color: '#44be6d'
                                                          }
                                                      ].filter(Boolean)
                                                  ]
                                                : []
                                        }
                                        tag={
                                            isExclusive(property) ? (
                                                <ExclusiveTag
                                                    isPulppoExclusive={!!property?.contract?.exclusive?.pulppo}
                                                />
                                            ) : null
                                        }
                                        showControls={isMobile}
                                        onHover={(hover) => {
                                            if (hover) {
                                                setHoveredProperty(property._id);
                                            }
                                        }}
                                        checked={
                                            selectedProperties.findIndex((prop) => prop._id === property._id) !== -1
                                        }
                                        onCheck={(checked) => {
                                            if (checked) {
                                                const newSelectedProperties = [
                                                    ...selectedProperties,
                                                    {
                                                        ...property,
                                                        isMLS: filters?.tab === 'mls'
                                                    }
                                                ];
                                                setSelectedProperties(newSelectedProperties);
                                                updateIsAllSelected(newSelectedProperties);
                                            }

                                            if (!checked) {
                                                const newSelectedProperties = selectedProperties.filter(
                                                    (prop) => prop._id !== property._id
                                                );
                                                setSelectedProperties(newSelectedProperties);
                                                updateIsAllSelected(newSelectedProperties);
                                            }
                                        }}
                                        showValuationTag={(property as any)?.valuation?.columns?.length > 0}
                                        checkbox={checkable}
                                        property={property}
                                        onClick={() => {
                                            openPropertyDescription && setProperty(property);
                                            onClick && onClick(property);
                                        }}
                                    />
                                </div>
                            ))}
                            {properties?.length > 0 &&
                            (filters?.offset || 0) + 50 >= count[filters?.tab] &&
                            showMorePropertiesOption ? (
                                <div key="more_card" className="w-full shrink-0 px-0 py-2 md:w-1/2 md:p-2 lg:w-1/4">
                                    <EmptySearchCard
                                        title="Continúa tu búsqueda"
                                        description=""
                                        footer={null}
                                        count={count}
                                        filters={filters}
                                        setFilters={setFilters}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </PulppoLoader>
                )}

                <PropertyDetail
                    onClose={() => {
                        setProperty(null);
                        if (router.query.selected) router.push('/search');
                    }}
                    visible={!!property}
                    refresh={() => {
                        setFilters((p) => ({ ...p }));
                    }}
                    showInternal={!isPublic}
                    showStats={!isPublic}
                    isMLS={filters?.tab === 'mls'}
                    property={property}
                    showValuation={
                        ['user', 'company'].includes(filters?.tab) && (property as any)?.valuation?.columns?.length > 0
                    }
                    propertyDetailActions={propertyDetailActions}
                />
            </div>
            <div className="flex w-full shrink-0 items-center justify-center shadow-md lg:bg-white">
                <PropertyListFooter
                    filters={filters}
                    setFilters={setFilters}
                    count={count}
                    inCollection={inCollection}
                    selectedProperties={selectedProperties}
                    view={view}
                    setView={setView}
                    onShare={
                        inCollection
                            ? () => {
                                  setShowAddDrawer(true);
                              }
                            : onShare
                    }
                />
            </div>
            <PropertyShareDrawer
                show={showAddDrawer}
                onClose={() => setShowAddDrawer(false)}
                selectedProperties={selectedProperties}
                onAdd={onAdd}
                onShare={onShareCollection}
                user={user}
            />
        </div>
    );
};
