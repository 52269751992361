export const fakeContacts: Array<any> = [
    {
        _id: '65a99412eb454a3309d7e207',
        firstName: 'Cliente',
        lastName: '1',
        phones: [
            {
                phone: '+52 55 7005 5225',
                plain: '525570055225',
                type: 'whatsapp',
                is_default: true,
                valid: true,
                callingCountryCode: '52',
                countryCode: 'MX',
                nationalFormat: '5570055225'
            }
        ],
        emails: [
            {
                email: 'cliente1@gmail.com',
                is_default: true,
                type: 'personal'
            }
        ],
        profilePicture: 'https://ui-avatars.com/api/?name=Cliente+1&size=128&background=0D8ABC&color=fff'
    },
    {
        _id: '65a99412eb454a3309d7e207',
        firstName: 'Cliente',
        lastName: '2',
        phones: [
            {
                phone: '+52 55 7005 5215',
                plain: '525570055215',
                type: 'whatsapp',
                is_default: true,
                valid: true,
                callingCountryCode: '52',
                countryCode: 'MX',
                nationalFormat: '5570055215'
            }
        ],
        emails: [
            {
                email: 'cliente2@gmail.com',
                is_default: true
            }
        ],
        profilePicture: 'https://ui-avatars.com/api/?name=Cliente+2&size=128&background=0D8ABC&color=fff'
    },
    {
        _id: '65a99412eb454a3309d7e207',
        firstName: 'Cliente',
        lastName: '3',
        phones: [
            {
                phone: '+52 55 7005 5110',
                plain: '525570055110',
                type: 'whatsapp',
                is_default: true,
                valid: true,
                callingCountryCode: '52',
                countryCode: 'MX',
                nationalFormat: '5570055110'
            }
        ],
        emails: [
            {
                email: 'cliente3@gmail.com',
                is_default: true
            }
        ],
        profilePicture: 'https://ui-avatars.com/api/?name=Cliente+3&size=128&background=0D8ABC&color=fff'
    }
];

export const fakeAgents = [
    {
        _id: '65a99382eb454a3309d7e204',
        firstName: 'Broker 1',
        lastName: 'Pulppo',
        uid: '65a99382eb454a3309d7e204',
        phone: '525570055225',
        email: 'broker1@pulppo.com',
        profilePicture: 'https://ui-avatars.com/api/?name=Broker+1&size=128&background=0D8ABC&color=fff'
    },
    {
        _id: '65a99390eb454a3309d7e205',
        firstName: 'Broker 2',
        lastName: 'Pulppo',
        uid: '65a99390eb454a3309d7e205',
        phone: '525570055215',
        email: 'broker2@pulppo.com',
        profilePicture: 'https://ui-avatars.com/api/?name=Broker+2&size=128&background=0D8ABC&color=fff'
    },
    {
        _id: '65a993bceb454a3309d7e206',
        firstName: 'Broker 3',
        lastName: 'Pulppo',
        uid: '65a993bceb454a3309d7e206',
        phone: '52557005110',
        email: 'broker3@pulppo.com',
        profilePicture: 'https://ui-avatars.com/api/?name=Broker+3&size=128&background=0D8ABC&color=fff'
    }
];

export const fakeProperties = [
    {
        _id: '65aaa997e1fa45fb59683257',
        type: 'Departamento',
        attributes: {
            condition: 'Excelente',
            expenses: 0,
            suites: 3,
            bathrooms: 3,
            toilettes: 1,
            parkings: 2,
            roofedSurface: 151,
            totalSurface: 226,
            disposition: 'front',
            yearBuild: 2021
        },
        address: {
            zip: '11550',
            street: 'Av. Homero 1205',
            location: { type: 'Point', coordinates: [-99.2008517, 19.4363034] },
            city: 'Miguel Hidalgo',
            neighborhood: 'Polanco',
            state: 'Ciudad de México'
        },
        pictures: [
            {
                url: 'https://images.pulppo.com/property/626c06008447411d53e69a52/picture_1.jpeg',
                description: 'DSC_0118.jpg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/626c06008447411d53e69a52/picture_5.jpeg',
                description: 'DSC_0112.jpg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/626c06008447411d53e69a52/picture_2.jpeg',
                description: 'DSC_0119.jpg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/626c06008447411d53e69a52/picture_3.jpeg',
                description: 'DSC_0115.jpg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/626c06008447411d53e69a52/picture_4.jpeg',
                description: 'DSC_0072.jpg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/626c06008447411d53e69a52/picture_6.jpeg',
                description: 'DSC_0016.jpg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/626c06008447411d53e69a52/picture_8.jpeg',
                description: 'DSC_0024-HDR.jpg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/626c06008447411d53e69a52/picture_7.jpeg',
                description: 'DSC_0030-HDR.jpg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/626c06008447411d53e69a52/picture_9.jpeg',
                description: 'DSC_0109.jpg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/626c06008447411d53e69a52/picture_10.jpeg',
                description: 'DSC_0044.jpg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/626c06008447411d53e69a52/picture_12.jpeg',
                description: 'DSC_0061-HDR.jpg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/626c06008447411d53e69a52/picture_13.jpeg',
                description: 'DSC_0054.jpg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/626c06008447411d53e69a52/picture_11.jpeg',
                description: 'DSC_0047.jpg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/626c06008447411d53e69a52/picture_14.jpeg',
                description: 'DSC_0084-HDR.jpg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/626c06008447411d53e69a52/picture_15.jpeg',
                description: 'DSC_0103.jpg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/626c06008447411d53e69a52/picture_16.jpeg',
                description: 'DSC_0097-HDR.jpg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/626c06008447411d53e69a52/picture_17.jpeg',
                description: 'DSC_0090-HDR.jpg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/626c06008447411d53e69a52/picture_18.jpeg',
                description: 'DSC_9985.jpg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/626c06008447411d53e69a52/picture_19.jpeg',
                description: 'DSC_9994-HDR.jpg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/626c06008447411d53e69a52/picture_20.jpeg',
                description: 'DSC_9958-HDR.jpg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/626c06008447411d53e69a52/picture_21.jpeg',
                description: 'DSC_9968-HDR.jpg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/626c06008447411d53e69a52/picture_22.jpeg',
                description: 'DSC_9995-HDR.jpg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/626c06008447411d53e69a52/picture_0.jpeg',
                description: '0_homero-1205-apt-102-ciudad-de-mexico-df-11550-mexico_0.png',
                is_blueprint: true
            }
        ],
        videos: [
            {
                provider: 'youtube',
                url: 'https://www.youtube.com/watch?v=6fSuxybC8-M',
                title: '',
                description: '',
                id: '6fSuxybC8-M'
            }
        ],
        listing: {
            title: 'Departamento en venta en Polanco III Secc 3 recámaras',
            operation: 'sale',
            price: {
                _id: '63521b011884d98d78fdb019',
                currency: 'MXN',
                price: 21800000
            }
        }
    },
    {
        _id: '65aaa997e1fa45fb59683256',
        type: 'Departamento',
        attributes: {
            age: 60,
            condition: 'Excelente',
            expenses: null,
            suites: 3,
            bathrooms: 3,
            toilettes: 1,
            parkings: 2,
            roofedSurface: 229,
            totalSurface: 249,
            orientation: 'west',
            disposition: 'front',
            yearBuild: 2014,
            unroofedSurface: 20
        },
        address: {
            zip: '11550',
            street: 'Goldsmith 341',
            location: {
                type: 'Point',
                coordinates: [-99.20009449999999, 19.4375618]
            },
            floor: '4',
            apartment: '',
            city: 'Miguel Hidalgo',
            neighborhood: 'Polanco',
            state: 'Ciudad de México'
        },
        pictures: [
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_6d238135f6ff47e29755ac03d54dcc42.jpeg',
                description: 'IMG_1590.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_b49f2ecc073d428c95aafb6c2c97ad4b.jpeg',
                description: 'IMG_1596 3.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_19808255ca6149eb853728efaeb8ce4f.jpeg',
                description: 'IMG_1493 2.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_bfa89dd3e5ab448d9ce7d82c0947f4df.jpeg',
                description: 'IMG_1595.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_893b2ac36ac8457e93cb88fa31431557.jpeg',
                description: 'IMG_1589 3.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_a69ff943922d40f7ba68dbc8bd1b3ba0.jpeg',
                description: 'IMG_1594 3.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_e15b7b37f4d4423bb4fed6e000bc7a59.jpeg',
                description: 'IMG_1499 2.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_481cab73be6a4a23aa9e9b49b4af763c.jpeg',
                description: 'IMG_1497 2.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_ac6084a60b8a46f5817a87b8c86d0e17.jpeg',
                description: 'IMG_1593 3.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_b9b7554861064c1fa9090b7956581e18.jpeg',
                description: 'IMG_1503 2.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_2093bf557b4c419e98d29f6d26edf8ff.jpeg',
                description: 'IMG_1502 2.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_39625370a9764c378acc3bfa8abdad3f.jpeg',
                description: 'IMG_1495 2.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_f91d271f24e74b15a1c18e631932e8d3.jpeg',
                description: 'IMG_1494 2.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_94aaaa57de1f44e6b56fbfccf1a90a02.jpeg',
                description: 'IMG_1507 2.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_b1ec3920edc343f1967e12f92111a97b.jpeg',
                description: 'IMG_1522.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_4c65286a3b2048fa9dac91b3079440a7.jpeg',
                description: 'IMG_1508 2.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_abcb75455a924af58d339718621694a5.jpeg',
                description: 'IMG_1509 2.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_8649101aea2d4a90b1c1644aa3749a1e.jpeg',
                description: 'IMG_1514.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_778cdf3ca8ff4189b283d5acdafc991d.jpeg',
                description: 'IMG_1518.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_ce873d5a1b4342108f8b983b436ac60d.jpeg',
                description: 'IMG_1512.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_3c0ebd3317d641f591594d2934d5b86e.jpeg',
                description: 'IMG_1510.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_f489c5c52d9b463f9ecc3d1ac29f090d.jpeg',
                description: 'IMG_1517.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_3f82f61d2bd44bf6adcdc9192f8931f6.jpeg',
                description: 'IMG_1523.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_622d4939a29a4426b4d9bdecfb7d0472.jpeg',
                description: 'IMG_1527.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_cfa79809ec6649d2ba1e05286c50111f.jpeg',
                description: 'IMG_1538.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_071730e5b99847afb92da5d4f9b1ab83.jpeg',
                description: 'IMG_1525.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_b981fcb48a1143b4bf6c53d777864f53.jpeg',
                description: 'IMG_1524.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_2282963e944c46f58cbaad073832c14e.jpeg',
                description: 'IMG_1528.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_51cf80284c454d40949ae4cac010299d.jpeg',
                description: 'IMG_1529.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_bd2d5522379a45a9b65ff15a3b27388f.jpeg',
                description: 'IMG_1535.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_da97ce23516a41ee81fa71787b99baee.jpeg',
                description: 'IMG_1536.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_ef370a4d1a824586bfdd60bf81c0b18d.jpeg',
                description: 'IMG_1533.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_f126f8bf3cf148f685aea565dcb0b64b.jpeg',
                description: 'IMG_1544.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_90324271e41a4cec8b2cba592433f238.jpeg',
                description: 'IMG_1545.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_c3e8544c66aa46f380221c3e3bffc2d2.jpeg',
                description: 'IMG_1553.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_0a6465a9e1c44d21adbd6398a5271c49.jpeg',
                description: 'IMG_1546.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_6213ff31de7f476081d072ecb31df12f.jpeg',
                description: 'IMG_1548.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_461c4510def44bc7a59e318daf9540f2.jpeg',
                description: 'IMG_1557.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_2bdcad5933f14e46aaf76dc6ed0780bd.jpeg',
                description: 'IMG_1554.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_da7b6ba80451454ba4cea82b010a7144.jpeg',
                description: 'IMG_1555.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_d2ca36f505b34db1b3fc269ecb32d822.jpeg',
                description: 'IMG_1552.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_8da4bcbd573b48388406882784b09d42.jpeg',
                description: 'IMG_1549.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_090ec2a06f5c47d980322aff724c97bd.jpeg',
                description: 'IMG_1550.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_355b3b0eecbc442eaded4249681e8fd9.jpeg',
                description: 'IMG_1551.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_0f10c3e4c4eb4733abfca5b9036175ee.jpeg',
                description: 'IMG_1543.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_f2d635356e69441eba56fb0fbcf69cb6.jpeg',
                description: 'IMG_1541.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_82bd417104f5460eb341db277fbc5d84.jpeg',
                description: 'IMG_1488 2.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_b11bd686394e4d5f96d7391e8e8388cf.jpeg',
                description: 'IMG_1597.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_db875b60635641e09a66428715a3e973.jpeg',
                description: 'IMG_1489 2.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_1678e7fe33a847a7a9cc6968a393ac89.jpeg',
                description: 'IMG_1490.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_b809c6163f7940f0840f97af7814cd9f.jpeg',
                description: 'IMG_1491.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/6256f7d6114eb2c6a908e3c7/picture_586d68df45c04293b4f905a2bec010af.jpeg',
                description: 'IMG_1492.jpeg',
                is_blueprint: false
            }
        ],
        videos: [],
        listing: {
            title: 'Departamento PH en venta en Polanco 3 recámaras',
            operation: 'sale',
            price: {
                _id: '63521af51884d98d78fdaf3a',
                price: 21000000,
                currency: 'MXN'
            }
        }
    },
    {
        _id: '65aaa997e1fa45fb59683255',
        type: 'Departamento',
        attributes: {
            age: 0,
            condition: 'Excelente',
            expenses: 0,
            suites: 2,
            bathrooms: 2,
            toilettes: 1,
            parkings: 2,
            roofedSurface: 264.78,
            totalSurface: 264.78,
            orientation: 'north',
            disposition: 'front',
            yearBuild: 0
        },
        address: {
            zip: '11550',
            street: 'A. Musset 326',
            location: {
                type: 'Point',
                coordinates: [-99.19663399999999, 19.4368813]
            },
            city: 'Miguel Hidalgo',
            neighborhood: 'Polanco',
            state: 'Ciudad de México'
        },
        pictures: [
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_0.jpeg',
                description: 'IMG_0337.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_1.jpeg',
                description: 'IMG_0339.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_3.jpeg',
                description: 'IMG_0331.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_2.jpeg',
                description: 'IMG_0333.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_4.jpeg',
                description: 'IMG_0338.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_5.jpeg',
                description: 'IMG_0340.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_6.jpeg',
                description: 'IMG_0332.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_7.jpeg',
                description: 'IMG_0326.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_8.jpeg',
                description: 'IMG_0329.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_10.jpeg',
                description: 'IMG_0334.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_11.jpeg',
                description: 'IMG_0342.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_12.jpeg',
                description: 'IMG_0322.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_9.jpeg',
                description: 'IMG_0330.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_14.jpeg',
                description: 'IMG_0328.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_13.jpeg',
                description: 'IMG_0343.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_15.jpeg',
                description: 'IMG_0325.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_16.jpeg',
                description: 'IMG_0345.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_17.jpeg',
                description: 'IMG_0344.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_18.jpeg',
                description: 'IMG_0314.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_19.jpeg',
                description: 'IMG_0320.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_20.jpeg',
                description: 'IMG_0346.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_21.jpeg',
                description: 'IMG_0317.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_22.jpeg',
                description: 'IMG_0318.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_23.jpeg',
                description: 'IMG_0316.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_24.jpeg',
                description: 'IMG_0321.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_25.jpeg',
                description: 'IMG_0319.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_26.jpeg',
                description: 'IMG_0323.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_27.jpeg',
                description: 'IMG_0354.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_28.jpeg',
                description: 'IMG_0353.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_29.jpeg',
                description: 'IMG_0355.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_30.jpeg',
                description: 'IMG_0347.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_31.jpeg',
                description: 'IMG_0357.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/621ef714d5ac1c00137b17f1/picture_32.jpeg',
                description: 'A. Musset 326',
                is_blueprint: false
            }
        ],
        videos: [
            {
                provider: 'youtube',
                url: 'https://www.youtube.com/watch?v=uTPuWbcB9hs',
                title: '',
                description: '',
                id: 'uTPuWbcB9hs'
            }
        ],
        listing: {
            title: 'Departamento en Venta en Polanco III Secc ',
            operation: 'sale',
            price: { price: 19500000, currency: 'MXN' }
        }
    },
    {
        _id: '65aaaa55235434e03eabb657',
        address: {
            street: 'C. Lago Zurich 243',
            zip: '11528',
            location: { type: 'Point', coordinates: [-99.2029831, 19.4450274] },
            floor: '34',
            apartment: '34B',
            city: 'Miguel Hidalgo',
            neighborhood: 'Ampliación Granada',
            state: 'Ciudad de México'
        },
        type: 'Departamento',
        attributes: {
            suites: 3,
            bathrooms: 2,
            toilettes: 1,
            parkings: 2,
            totalSurface: 268,
            roofedSurface: 151,
            disposition: 'front',
            condition: 'Excelente',
            expenses: 0,
            unroofedSurface: 117,
            yearBuild: 2018
        },
        pictures: [
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4065.JPG',
                description: 'IMG_4065.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4063.JPG',
                description: 'IMG_4063.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4059.JPG',
                description: 'IMG_4059.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4061.JPG',
                description: 'IMG_4061.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4060.JPG',
                description: 'IMG_4060.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4064.JPG',
                description: 'IMG_4064.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4067.JPG',
                description: 'IMG_4067.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4081.JPG',
                description: 'IMG_4081.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4072.JPG',
                description: 'IMG_4072.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4070.JPG',
                description: 'IMG_4070.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4074.JPG',
                description: 'IMG_4074.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4075.JPG',
                description: 'IMG_4075.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4071.JPG',
                description: 'IMG_4071.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4068.JPG',
                description: 'IMG_4068.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4069.JPG',
                description: 'IMG_4069.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4073.JPG',
                description: 'IMG_4073.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4076.JPG',
                description: 'IMG_4076.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4077.JPG',
                description: 'IMG_4077.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4079.JPG',
                description: 'IMG_4079.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4078.JPG',
                description: 'IMG_4078.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4080.JPG',
                description: 'IMG_4080.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4093.JPG',
                description: 'IMG_4093.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4096.JPG',
                description: 'IMG_4096.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4100.JPG',
                description: 'IMG_4100.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4091.JPG',
                description: 'IMG_4091.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4097.JPG',
                description: 'IMG_4097.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4110.JPG',
                description: 'IMG_4110.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4114.JPG',
                description: 'IMG_4114.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4113.JPG',
                description: 'IMG_4113.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4118.JPG',
                description: 'IMG_4118.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_3994.JPG',
                description: 'IMG_3994.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_3995.JPG',
                description: 'IMG_3995.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_3992.JPG',
                description: 'IMG_3992.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_3989.JPG',
                description: 'IMG_3989.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_3996.JPG',
                description: 'IMG_3996.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_3997.JPG',
                description: 'IMG_3997.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_3993.JPG',
                description: 'IMG_3993.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_3990.JPG',
                description: 'IMG_3990.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_3991.JPG',
                description: 'IMG_3991.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4104.JPG',
                description: 'IMG_4104.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638966c71f220e511c58121f/IMG_4103.JPG',
                description: 'IMG_4103.JPG',
                is_blueprint: false
            }
        ],
        videos: [
            {
                provider: 'youtube',
                url: 'https://www.youtube.com/watch?v=uTPuWbcB9hs',
                title: '',
                description: '',
                id: 'uTPuWbcB9hs'
            }
        ],
        listing: {
            operation: 'rent',
            title: 'Departamento en renta en Torre Viena - Polarea -  3 recámaras',
            price: { currency: 'MXN', price: 80000 }
        }
    },
    {
        _id: '65aaaa55235434e03eabb658',
        address: {
            street: 'México D.F. CP',
            zip: '11529',
            location: { type: 'Point', coordinates: [-99.2034917, 19.4456193] },
            floor: '34',
            apartment: '34C',
            city: 'Miguel Hidalgo',
            neighborhood: 'Ampliación Granada',
            state: 'Ciudad de México'
        },
        type: 'Departamento',
        attributes: {
            suites: 3,
            bathrooms: 3,
            toilettes: 1,
            parkings: 2,
            totalSurface: 268,
            roofedSurface: 151,
            unroofedSurface: 117,
            disposition: 'front',
            condition: 'Excelente',
            expenses: 0,
            yearBuild: 2020
        },
        pictures: [
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4002.JPG',
                description: 'IMG_4002.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4015.JPG',
                description: 'IMG_4015.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4005.JPG',
                description: 'IMG_4005.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4001.JPG',
                description: 'IMG_4001.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4014.JPG',
                description: 'IMG_4014.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4008.JPG',
                description: 'IMG_4008.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4003.JPG',
                description: 'IMG_4003.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4011.JPG',
                description: 'IMG_4011.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4010.JPG',
                description: 'IMG_4010.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4009.JPG',
                description: 'IMG_4009.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4012.JPG',
                description: 'IMG_4012.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4013.JPG',
                description: 'IMG_4013.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4006.JPG',
                description: 'IMG_4006.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4007.JPG',
                description: 'IMG_4007.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4017.JPG',
                description: 'IMG_4017.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4032.JPG',
                description: 'IMG_4032.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4020.JPG',
                description: 'IMG_4020.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4021.JPG',
                description: 'IMG_4021.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4019.JPG',
                description: 'IMG_4019.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4022.JPG',
                description: 'IMG_4022.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4023.JPG',
                description: 'IMG_4023.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4024.JPG',
                description: 'IMG_4024.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4031.JPG',
                description: 'IMG_4031.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4029.JPG',
                description: 'IMG_4029.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4028.JPG',
                description: 'IMG_4028.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4027.JPG',
                description: 'IMG_4027.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4026.JPG',
                description: 'IMG_4026.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4033.JPG',
                description: 'IMG_4033.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4034.JPG',
                description: 'IMG_4034.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4037.JPG',
                description: 'IMG_4037.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4035.JPG',
                description: 'IMG_4035.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4036.JPG',
                description: 'IMG_4036.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_3995.JPG',
                description: 'IMG_3995.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_3998.JPG',
                description: 'IMG_3998.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_3996.JPG',
                description: 'IMG_3996.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_3989.JPG',
                description: 'IMG_3989.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4056.JPG',
                description: 'IMG_4056.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_3990.JPG',
                description: 'IMG_3990.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_3997.JPG',
                description: 'IMG_3997.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_3992.JPG',
                description: 'IMG_3992.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_3994.JPG',
                description: 'IMG_3994.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_3993.JPG',
                description: 'IMG_3993.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4106 2.jpg',
                description: 'IMG_4106 2.jpg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4107 2.JPG',
                description: 'IMG_4107 2.JPG',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/638971441f220e99ea598bd6/IMG_4108.JPG',
                description: 'IMG_4108.JPG',
                is_blueprint: false
            }
        ],
        videos: [
            {
                provider: 'youtube',
                url: 'https://www.youtube.com/watch?v=uTPuWbcB9hs',
                title: '',
                description: '',
                id: 'uTPuWbcB9hs'
            }
        ],
        listing: {
            operation: 'rent',
            title: 'Departamento en renta en Torre Viena - Polarea -  3 recámaras',
            price: { currency: 'MXN', price: 75000 }
        }
    },
    {
        _id: '65aaaa55235434e03eabb659',
        pictures: [
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_3cc709f1f0044977bb441a3eb90dbf13.jpeg',
                description: 'IMG_6572.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_a0fe5cd1010240dc8bbd80b3d2f8cec9.jpeg',
                description: 'IMG_6570.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_b97f3144d0d745fab966c80ca4161df5.jpeg',
                description: 'IMG_6574.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_026204a8ac274a81bb113e1d90275600.jpeg',
                description: 'IMG_6575.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_157aa04bc0ba4f74afb157a29dde256f.jpeg',
                description: 'IMG_6586.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_005c9b1acedb426caa9510e0349dbc47.jpeg',
                description: 'IMG_6590.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_4fb34ab83ea349e3a0bab77ab1e884d3.jpeg',
                description: 'IMG_6579.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_2b45e24db3b041948ecfc3bfa4dc6101.jpeg',
                description: 'IMG_6578.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_fd37d5c89ebf413fb3bc8031e2176e7c.jpeg',
                description: 'IMG_6576.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_af6ab47034924cd1b07873a0f8beaafa.jpeg',
                description: 'IMG_6585.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_cc94731fb7ab4551b9375e167f268abf.jpeg',
                description: 'IMG_6607.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_243ce0b303cd4e2ea31c13e0abcf6135.jpeg',
                description: 'IMG_6604.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_a48489b7a96d477b84404548ef6cd61a.jpeg',
                description: 'IMG_6594.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_dec3fb2518f64a78a3dd995d37632704.jpeg',
                description: 'IMG_6593.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_1e3489e4dff94b9e960c99f8ef62a534.jpeg',
                description: 'IMG_6596.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_5d21a7e00c9a4dbe9c1e20e9ec56e4a9.jpeg',
                description: 'IMG_6605.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_bd821edd1bc64922b76f33c297ab2962.jpeg',
                description: 'IMG_6595.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_5dd08d40af7347cabc36071bef2ea8b8.jpeg',
                description: 'IMG_6602.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_adea137b76ce4471bb96d01e67541e5a.jpeg',
                description: 'IMG_6591.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_ccb65fe573c14f1ab894556ad7385307.jpeg',
                description: 'IMG_6612.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_59e9bc92ccb34774ac006b691a7746e0.jpeg',
                description: 'IMG_6582.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_f6a38c4270e540daa59c26d402de282f.jpeg',
                description: 'IMG_6609.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_ae2c3aa7d57742149309ba07aaa0bfe5.jpeg',
                description: 'IMG_6611.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_c6f41ecc37594bebb25f0db4d33b08b7.jpeg',
                description: 'IMG_6608.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_d6fcdc5a5de6484e9404367d01976ce7.jpeg',
                description: 'IMG_6622.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_9654421c03f14e7d8470bc2c453c8ed4.jpeg',
                description: 'IMG_6613.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_4fbcb4b758154dd5a161a4a399e8f70a.jpeg',
                description: 'IMG_6620.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_723befcf69e24fed98aade0ad896d30e.jpeg',
                description: 'IMG_6621.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_4ded81f587db46c5bfaac80edbd23957.jpeg',
                description: 'IMG_6615.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_d608db70d71f47b7b272db4638a1a1ee.jpeg',
                description: 'IMG_6616.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_894ed9b212bc43e8955d5c3cbb85a1f9.jpeg',
                description: 'IMG_6631.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_e0daef6af72a4b4eb4104c8cb874ff87.jpeg',
                description: 'IMG_6630.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_0ffef1043b3c47d380bcd574bb4b586a.jpeg',
                description: 'IMG_6629.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_267b415d828b4a1698e827106ba120e4.jpeg',
                description: 'IMG_6633.jpeg',
                is_blueprint: false
            },
            {
                url: 'https://images.pulppo.com/property/64ffc14105e8d89471210504/picture_ce1c49a2c43d44d5a239d9d548a994c9.jpeg',
                description: 'IMG_6632.jpeg',
                is_blueprint: false
            }
        ],
        listing: {
            operation: 'rent',
            title: 'Oficina en renta en  Dowtown Reforma Be Grand.',
            price: { currency: 'MXN', price: 110000 }
        },
        videos: [
            {
                url: 'https://www.youtube.com/watch?v=akkUnP3ClpI',
                description: 'MarketingVideo',
                provider: 'youtube',
                title: 'Oficina en renta en  Dowtown Reforma Be Grand. - OCA-228',
                id: 'akkUnP3ClpI'
            }
        ],
        address: {
            street: 'Downtown® Reforma',
            location: {
                coordinates: [-99.15443259999999, 19.4345228],
                type: 'Point'
            },
            zip: '06030',
            floor: '9',
            apartment: '912',
            city: 'Cuauhtémoc',
            neighborhood: 'Cuauhtémoc',
            state: 'Ciudad de México'
        },
        type: 'Oficina',
        attributes: {
            parkings: 3,
            yearBuild: 0,
            disposition: 'front',
            condition: 'Excelente',
            totalSurface: 197,
            roofedSurface: 113.56
        }
    }
].map((el, i) => ({
    ...el,
    contact: fakeContacts[i % 3],
    agent: fakeAgents[i % 3],
    agentSeller: fakeAgents[i % 3],
    status: {
        last: 'published'
    }
}));
