import React from 'react';
import { PropertyDelete } from './PropertyDelete';
import { PropertyNew } from './PropertyNew';
import { PropertyPriceDrop } from './PropertyPriceDrop';
import { OperationCompletedPost } from './OperationCompletedPost';
const elements = {
    PropertyDelete: (props: any) => {
        return <PropertyDelete {...props} />;
    },
    PropertyCreate: (props: any) => {
        return <PropertyNew {...props} />;
    },
    PriceDrops: (props: any) => {
        return <PropertyPriceDrop {...props} />;
    },
    OperationCompleted: (props: any) => {
        return <OperationCompletedPost {...props} />;
    }
}


export const PostElements = ({ name, props }: { name: string, props: any }) => {
    const Component = elements[name];
    if (!Component) return null;
    return <div>
        <Component {...props} />
    </div>;
};
