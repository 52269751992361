'use client';
import { Wrapper } from '@googlemaps/react-wrapper';
import { useEffect, useRef } from 'react';
import { DEFAULT_COORDINATES } from '../../utils/coordinates';

function MapInside({
    center,
    zoom,
    className,
    markers
}: {
    center?: google.maps.LatLng | google.maps.LatLngLiteral;
    markers?: (google.maps.LatLng | google.maps.LatLngLiteral)[];
    zoom: number;
    className?: string;
}) {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const map = new google.maps.Map(ref.current as HTMLDivElement, {
            center,
            zoom,
            styles: [
                {
                    elementType: 'geometry',
                    stylers: [
                        {
                            color: '#212121'
                        }
                    ]
                },
                {
                    elementType: 'labels.icon',
                    stylers: [
                        {
                            visibility: 'off'
                        }
                    ]
                },
                {
                    elementType: 'labels.text.fill',
                    stylers: [
                        {
                            color: '#757575'
                        }
                    ]
                },
                {
                    elementType: 'labels.text.stroke',
                    stylers: [
                        {
                            color: '#212121'
                        }
                    ]
                },
                {
                    featureType: 'administrative',
                    elementType: 'geometry',
                    stylers: [
                        {
                            color: '#757575'
                        }
                    ]
                },
                {
                    featureType: 'administrative.country',
                    elementType: 'labels.text.fill',
                    stylers: [
                        {
                            color: '#9e9e9e'
                        }
                    ]
                },
                {
                    featureType: 'administrative.land_parcel',
                    stylers: [
                        {
                            visibility: 'off'
                        }
                    ]
                },
                {
                    featureType: 'administrative.locality',
                    elementType: 'labels.text.fill',
                    stylers: [
                        {
                            color: '#bdbdbd'
                        }
                    ]
                },
                {
                    featureType: 'administrative.neighborhood',
                    stylers: [
                        {
                            visibility: 'off'
                        }
                    ]
                },
                {
                    featureType: 'poi',
                    elementType: 'labels.text',
                    stylers: [
                        {
                            visibility: 'off'
                        }
                    ]
                },
                {
                    featureType: 'poi',
                    elementType: 'labels.text.fill',
                    stylers: [
                        {
                            color: '#757575'
                        }
                    ]
                },
                {
                    featureType: 'poi.business',
                    stylers: [
                        {
                            visibility: 'off'
                        }
                    ]
                },
                {
                    featureType: 'poi.park',
                    elementType: 'geometry',
                    stylers: [
                        {
                            color: '#181818'
                        }
                    ]
                },
                {
                    featureType: 'poi.park',
                    elementType: 'labels.text.fill',
                    stylers: [
                        {
                            color: '#616161'
                        }
                    ]
                },
                {
                    featureType: 'poi.park',
                    elementType: 'labels.text.stroke',
                    stylers: [
                        {
                            color: '#1b1b1b'
                        }
                    ]
                },
                {
                    featureType: 'road',
                    elementType: 'geometry.fill',
                    stylers: [
                        {
                            color: '#2c2c2c'
                        }
                    ]
                },
                {
                    featureType: 'road',
                    elementType: 'labels',
                    stylers: [
                        {
                            visibility: 'off'
                        }
                    ]
                },
                {
                    featureType: 'road',
                    elementType: 'labels.icon',
                    stylers: [
                        {
                            visibility: 'off'
                        }
                    ]
                },
                {
                    featureType: 'road',
                    elementType: 'labels.text.fill',
                    stylers: [
                        {
                            color: '#8a8a8a'
                        }
                    ]
                },
                {
                    featureType: 'road.arterial',
                    elementType: 'geometry',
                    stylers: [
                        {
                            color: '#373737'
                        }
                    ]
                },
                {
                    featureType: 'road.highway',
                    elementType: 'geometry',
                    stylers: [
                        {
                            color: '#3c3c3c'
                        }
                    ]
                },
                {
                    featureType: 'road.highway.controlled_access',
                    elementType: 'geometry',
                    stylers: [
                        {
                            color: '#4e4e4e'
                        }
                    ]
                },
                {
                    featureType: 'road.local',
                    elementType: 'labels.text.fill',
                    stylers: [
                        {
                            color: '#616161'
                        }
                    ]
                },
                {
                    featureType: 'transit',
                    stylers: [
                        {
                            visibility: 'off'
                        }
                    ]
                },
                {
                    featureType: 'transit',
                    elementType: 'labels.text.fill',
                    stylers: [
                        {
                            color: '#757575'
                        }
                    ]
                },
                {
                    featureType: 'water',
                    elementType: 'geometry',
                    stylers: [
                        {
                            color: '#000000'
                        }
                    ]
                },
                {
                    featureType: 'water',
                    elementType: 'labels.text',
                    stylers: [
                        {
                            visibility: 'off'
                        }
                    ]
                },
                {
                    featureType: 'water',
                    elementType: 'labels.text.fill',
                    stylers: [
                        {
                            color: '#3d3d3d'
                        }
                    ]
                }
            ],
            disableDefaultUI: true
        });
        if (markers?.length > 1) {
            const bounds = new google.maps.LatLngBounds();
            for (const marker of markers || []) {
                new google.maps.Marker({
                    position: marker,
                    icon: '/icons/pin-white.svg',
                    map
                });
                bounds.extend(marker);
            }
            map.fitBounds(bounds);
        } else if (center) {
            new google.maps.Marker({
                position: center,
                icon: '/icons/pin-white.svg',
                map
            });
        }
    });

    return <div ref={ref} className={className || 'aspect-video w-full'} />;
}

export const PulppoFallbackMap = ({
    center,
    zoom = 12,
    className = '',
    markers = []
}: {
    center?: google.maps.LatLng | google.maps.LatLngLiteral;
    markers?: (google.maps.LatLng | google.maps.LatLngLiteral)[];
    zoom?: number;
    className?: string;
}) => {
    return (
        <Wrapper libraries={['maps', 'geocoding', 'places', 'core']} apiKey={'AIzaSyDCMPUOVzmP2XTOs-rlqx9Hscbx3Irj0ig'}>
            <MapInside
                markers={markers}
                className={className}
                center={center || DEFAULT_COORDINATES}
                zoom={zoom || 8}
            />
        </Wrapper>
    );
};
