import { IProperty } from '@pulppo/pulppo-models';
import { Button, PageHeader, Steps, Typography } from 'antd';
import { useRouter } from 'next/router';
import { PulppoSelect } from '../Elements/PulppoSelect';
import useUser from '../../hooks/useUser';
import { useShallow } from 'zustand/react/shallow';

interface ActionButtonProps {
    onClick: any;
    disabled: boolean;
    text: string;
    additionalProps?: any;
}

export const Header = ({
    step,
    next,
    previous,
    title,
    additional = null,
    isACM = true,
    property = null,
    subTitle = null,
    capture
}: {
    step: number;
    next: ActionButtonProps;
    previous: ActionButtonProps;
    additional?: ActionButtonProps;
    capture?: {
        onClick: any;
    };
    isACM?: boolean;
    property?: IProperty;
    subTitle?: any;
    title: {
        text: string;
        onChange: any;
    };
}) => {
    const router = useRouter();
    const user = useUser(useShallow((state) => state.user));

    return (
        <div className="sticky top-0 z-20 bg-white p-6 shadow-sm md:static md:bg-transparent md:shadow-none">
            <PageHeader
                className="m-0 p-0"
                extra={
                    <div className="hidden w-full flex-col items-end justify-center md:flex lg:flex-row">
                        <Steps
                            progressDot
                            current={step}
                            className="hidden md:block"
                            onChange={
                                property
                                    ? (e) => {
                                          router.push(`/${isACM ? 'acm' : 'listing'}/${property._id}/step-${e + 1}`);
                                      }
                                    : undefined
                            }
                        >
                            {isACM ? (
                                <>
                                    <Steps.Step title="Caracteristicas" />
                                    <Steps.Step title="Comparar" />
                                    <Steps.Step title="Analizar" />
                                    <Steps.Step title="Estimar" />
                                </>
                            ) : (
                                <>
                                    <Steps.Step title="Caracteristicas" />
                                    <Steps.Step title="Operación" />
                                    <Steps.Step title="Galería" />
                                    <Steps.Step title="Legales" />
                                </>
                            )}
                        </Steps>
                        <div className="flex flex-1">
                            {user?.features?.capture10 && capture ? (
                                <Button className="mr-2 bg-white font-medium" onClick={capture.onClick}>
                                    Ir a su captación
                                </Button>
                            ) : null}
                            <Button
                                className="mr-2 bg-white font-medium"
                                onClick={previous.onClick}
                                disabled={previous.disabled}
                            >
                                {previous.text || 'Anterior'}
                            </Button>
                            {additional && (
                                <Button
                                    className="mr-2 bg-white font-medium"
                                    onClick={additional.onClick}
                                    disabled={additional.disabled}
                                >
                                    {additional.text}
                                </Button>
                            )}
                            <Button
                                type="primary"
                                className="font-medium"
                                onClick={next.onClick}
                                disabled={next.disabled}
                                {...next.additionalProps}
                            >
                                {next.text || 'Siguiente'}
                            </Button>
                        </div>
                    </div>
                }
                title={
                    <div className="flex w-full items-center justify-between gap-8 md:block">
                        <Typography.Title
                            level={3}
                            editable={title.onChange && { onChange: title.onChange }}
                            className="level-3 mb-0 w-full whitespace-normal"
                        >
                            {title.text || 'Título del ACM'}
                        </Typography.Title>
                        <Typography.Paragraph className="mb-0 text-sm font-light text-pulppo-status-dark-disabled">
                            {subTitle || ''}
                        </Typography.Paragraph>
                    </div>
                }
            ></PageHeader>
            <div className="md:hidden">
                <PulppoSelect
                    allowSearch={false}
                    disabled={!property}
                    value={`${+step + 1}`}
                    onChange={
                        property
                            ? async (e) => {
                                  await router.push(`/${isACM ? 'acm' : 'listing'}/${property._id}/step-${e}`);
                              }
                            : undefined
                    }
                    options={
                        isACM
                            ? [
                                  {
                                      key: '1',
                                      value: '1',
                                      label: 'Caracteristicas'
                                  },
                                  {
                                      key: '2',
                                      value: '2',
                                      label: 'Comparar'
                                  },
                                  {
                                      key: '3',
                                      value: '3',
                                      label: 'Analizar'
                                  },
                                  {
                                      key: '4',
                                      value: '4',
                                      label: 'Estimar'
                                  }
                              ]
                            : [
                                  {
                                      key: '1',
                                      value: '1',
                                      label: 'Caracteristicas',
                                      description: `Datos de la propiedad, como ubicación, metros, amenidades`
                                  },
                                  {
                                      key: '2',
                                      value: '2',
                                      label: 'Operación',
                                      description: 'Datos de la operación y publicación, como precio, comisión, título'
                                  },
                                  {
                                      key: '3',
                                      value: '3',
                                      label: 'Galería',
                                      description: 'Fotos, videos y planos de la propiedad'
                                  },
                                  {
                                      key: '4',
                                      value: '4',
                                      label: 'Legales',
                                      description: 'Aspectos legales, como el contrato'
                                  }
                              ]
                    }
                />
                <div className="mt-2 flex flex-wrap gap-2 md:hidden">
                    <Button className="h-auto flex-1 py-1" onClick={previous.onClick} disabled={previous.disabled}>
                        {previous.text || 'Anterior'}
                    </Button>
                    <Button
                        type="primary"
                        className="h-auto flex-1 py-1"
                        onClick={next.onClick}
                        disabled={next.disabled}
                        {...next.additionalProps}
                    >
                        {next.text || 'Siguiente'}
                    </Button>
                    {user?.features?.capture10 && capture ? (
                        <Button className="w-full bg-white font-medium" onClick={capture.onClick}>
                            Ir a su captación
                        </Button>
                    ) : null}
                    {additional && (
                        <Button
                            type="primary"
                            className="h-auto w-full py-1"
                            onClick={additional.onClick}
                            disabled={additional.disabled}
                            {...additional.additionalProps}
                        >
                            {additional.text}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};
