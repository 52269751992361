import { uuidv4 } from '../helpers/uuid';
import { IProperty, ISearch, ISimpleUser, ITask } from '@pulppo/pulppo-models';
import dayjs from 'dayjs';

export const generateTaskQueueId = ({
    currentIndex,
    tasks
}: {
    currentIndex: number;
    tasks: Array<{
        _id: ITask['_id'];
        reddirectTo: ITask['reddirectTo'];
    }>;
}) => {
    const id = uuidv4();
    sessionStorage.setItem(
        `queue_${id}`,
        JSON.stringify({
            current: currentIndex,
            tasks
        })
    );
    return id;
};

export const taskIsExpired = (task: ITask) => {
    return task?.status === 'pending' && dayjs(task?.dueDate).tz().isBefore(dayjs().tz()) && task.type !== 'search';
};

export const createSearchInitTask = ({ user, search }: { user: ISimpleUser; search: ISearch }): Partial<ITask> => {
    return {
        agent: user,
        dueDate: dayjs().tz().add(7, 'day').toDate(),
        type: 'search',
        reddirectTo: `/new-searches/${search?._id}`,
        name: null,
        message: '',
        metadata: {
            contact: {
                firstName: search?.contact?.firstName,
                lastName: search?.contact?.lastName,
                _id: search?.contact?._id,
                email: (search?.contact as any)?.emails?.[0]?.email,
                phone: (search?.contact as any)?.phones?.[0]?.phone
            },
            property: null,
            search: search?._id
        }
    };
};

export const createCaptureInitTask = ({
    user,
    property
}: {
    user: ISimpleUser;
    property: Partial<IProperty>;
}): Partial<ITask> => {
    return {
        agent: user,
        dueDate: dayjs().tz().add(7, 'day').toDate(),
        type: 'capture',
        reddirectTo: `/captaciones/${property?._id}`,
        name: null,
        message: '',
        metadata: {
            contact: {
                firstName: property?.contact?.firstName,
                lastName: property?.contact?.lastName,
                _id: property?.contact?._id,
                email: property?.contact?.email || (property?.contact as any)?.emails?.[0]?.email,
                phone: property?.contact?.phone || (property?.contact as any)?.phones?.[0]?.phone
            },
            property: null,
            capture: property?._id
        }
    };
};
