import Button from './Button';
import { twMerge } from 'tailwind-merge';
import { EditOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

interface Props {
    disabled?: boolean;
    value?: Date | string | Dayjs;
    onSelectDate?: (date: Dayjs) => void;
    availableHours?: Array<Dayjs>;
}

const FIRST_HOUR = 9;
const LAST_HOUR = 20;
const TIMESLOTS = new Array((LAST_HOUR - FIRST_HOUR) * 2 + 1).fill(dayjs()).map((_, index) => {
    if (index % 2 === 0)
        return dayjs()
            .tz()
            .startOf('day')
            .add(FIRST_HOUR + index / 2, 'hour');
    return dayjs()
        .tz()
        .startOf('day')
        .add(FIRST_HOUR + (index - 1) / 2, 'hour')
        .add(30, 'minutes');
});

const PulppoNewTimePicker = ({ disabled, value, availableHours, onSelectDate }: Props) => {
    const [showAvailableHours, setShowAvailableHours] = useState(false);
    const ref = useRef(null);
    const date = value ? dayjs(value) : null;
    const handleClick = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setShowAvailableHours(false); // Acción al hacer clic fuera
        }
    };

    useEffect(() => {
        window.addEventListener('mousedown', handleClick);
        return () => window.removeEventListener('mousedown', handleClick);
    }, []);
    return (
        <div ref={ref} className="relative">
            <Button
                type="input"
                className={twMerge('relative pr-10 text-center text-pulppo-primary-black')}
                onClick={() => setShowAvailableHours(!showAvailableHours)}
                disabled={disabled}
            >
                {date?.hour() === 0 ? 'Seleccionar' : date?.format('hh:mm a')}

                <EditOutlined className="absolute inset-y-0 right-0 bg-pulppo-secondary-gray-disabled px-3 text-lg text-pulppo-primary-black" />
            </Button>

            {showAvailableHours ? (
                <div
                    className="absolute inset-x-0 z-10 divide-y divide-solid divide-pulppo-status-light-disabled overflow-y-auto bg-white shadow-lg"
                    style={{ maxHeight: '300px' }}
                >
                    {TIMESLOTS.map((timeslot) => {
                        const hour = timeslot.format('hh:mm a');
                        const available = availableHours
                            ? availableHours.some((elem) => elem.hour() === timeslot.hour())
                            : true;
                        return (
                            <Button
                                type="secondary"
                                className={twMerge(
                                    'block w-full text-center ring-0 hover:bg-black hover:text-white',
                                    available
                                        ? 'text-black'
                                        : 'bg-pulppo-status-light-disabled text-pulppo-status-dark-disabled'
                                )}
                                key={`hour-${hour}`}
                                onClick={() => {
                                    onSelectDate?.(timeslot);
                                    setShowAvailableHours(false);
                                }}
                            >
                                {hour}
                            </Button>
                        );
                    })}
                </div>
            ) : null}
        </div>
    );
};

export default PulppoNewTimePicker;
