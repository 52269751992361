import { Button } from 'antd';
import { useState } from 'react';
import { PulppoRadio } from './PulppoRadio';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { Filters } from '../Property/Filters';
import { TextSearchMobile } from './TextSearchMobile ';

export const PropertyListHeaderMobile = ({ setFilters, setProperty, filters, tabs, user }) => {
    const [openFilters, setOpenFilters] = useState(false);
    return (
        <div className="flex w-full flex-col gap-2 px-4 pt-2 lg:hidden">
            <Filters
                hide={{
                    location: true,
                    search: true,
                    radius: true,
                    internal: !user
                }}
                setAppliedFilters={setFilters}
                visible={openFilters}
                setVisible={setOpenFilters}
                appliedFilters={filters}
            />
            <div className="flex w-full gap-2">
                <div className="shrink-1 flex-1">
                    <TextSearchMobile
                        selectedLocations={filters?.addresses || []}
                        onSearch={(value) => {
                            setFilters((filters) => ({
                                ...filters,
                                search: value
                            }));
                        }}
                        onSelectLocation={(location) => {
                            const aux = [...(filters?.addresses || [])];
                            const index = aux.findIndex((elem) => elem.id === location.id);
                            if (index === -1) aux.push(location);
                            else aux.splice(index, 1);
                            setFilters((filters) => ({
                                ...filters,
                                addresses: aux
                            }));
                        }}
                        onSelectProperty={(property) => {
                            setProperty(property);
                        }}
                        render={() => (
                            <div
                                className="flex items-center justify-between rounded-none border border-solid border-gray-300 bg-white px-2"
                                style={{
                                    height: '36px'
                                }}
                            >
                                <p className="text-pulppo-status-dark-disabled">
                                    {filters?.search ? filters?.search : 'Buscar por dirección...'}
                                </p>
                                <SearchOutlined />
                            </div>
                        )}
                    />
                </div>

                <Button
                    onClick={() => {
                        setOpenFilters(true);
                    }}
                    style={{
                        height: '36px'
                    }}
                    className="flex-0 shrink-0 rounded-none bg-white px-2"
                >
                    Filtros
                </Button>
            </div>
            <div className="w-full">
                <PulppoRadio
                    options={tabs}
                    value={filters?.tab}
                    onApply={(value) => {
                        setFilters((filters) => ({
                            ...filters,
                            tab: value
                        }));
                    }}
                    onClear={() => {
                        setFilters((filters) => ({
                            ...filters,
                            tab: 'pulppo'
                        }));
                    }}
                    defaultOptionValue="pulppo"
                    render={(value) => (
                        <div className="flex h-11 items-center justify-between rounded-none border border-solid border-gray-300 bg-white px-2 text-pulppo-status-dark-disabled">
                            <p>{value?.label}</p>
                            <DownOutlined className="text-xl text-gray-300" />
                        </div>
                    )}
                />
            </div>
        </div>
    );
};
