import React, { SVGProps } from 'react';

const DoorSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M11.0699 13.33H9.62986V12.33H11.0699C11.0884 12.33 11.1062 12.3226 11.1194 12.3095C11.1325 12.2964 11.1399 12.2786 11.1399 12.26V2.08C11.14 2.06041 11.133 2.04144 11.1201 2.02669C11.1072 2.01195 11.0893 2.00245 11.0699 2H4.92986C4.91042 2.00245 4.89256 2.01195 4.87965 2.02669C4.86675 2.04144 4.85971 2.06041 4.85986 2.08V12.26C4.85986 12.2786 4.86724 12.2964 4.88037 12.3095C4.89349 12.3226 4.9113 12.33 4.92986 12.33V13.33C4.64608 13.33 4.37392 13.2173 4.17326 13.0166C3.9726 12.8159 3.85986 12.5438 3.85986 12.26V2.08C3.85985 1.79529 3.97226 1.52209 4.17264 1.31984C4.37303 1.11758 4.64517 1.00264 4.92986 1H11.0699C11.3546 1.00264 11.6267 1.11758 11.8271 1.31984C12.0275 1.52209 12.1399 1.79529 12.1399 2.08V12.26C12.1399 12.5438 12.0271 12.8159 11.8265 13.0166C11.6258 13.2173 11.3536 13.33 11.0699 13.33Z"
            fill="black"
        ></path>
        <path
            d="M9.13023 14.9997C8.997 15.0008 8.86473 14.9771 8.74023 14.9297L4.74023 13.3397C4.48396 13.2196 4.26603 13.0307 4.11067 12.7942C3.95532 12.5576 3.86862 12.2826 3.86023 11.9997V1.99969C3.85351 1.84195 3.88663 1.68506 3.95654 1.54349C4.02645 1.40193 4.13089 1.28025 4.26023 1.18969C4.38811 1.10691 4.53455 1.05717 4.6864 1.04496C4.83825 1.03274 4.99075 1.05842 5.13023 1.11969L9.13023 2.70969C9.37736 2.82726 9.58843 3.00895 9.74145 3.23584C9.89447 3.46273 9.98384 3.72652 10.0002 3.99969V13.9997C10.0115 14.1213 9.99698 14.244 9.95769 14.3596C9.91839 14.4753 9.85518 14.5814 9.77217 14.671C9.68916 14.7606 9.5882 14.8318 9.47587 14.8798C9.36355 14.9278 9.24237 14.9516 9.12023 14.9497L9.13023 14.9997ZM4.86023 2.04969V11.9997C4.86967 12.0858 4.89898 12.1686 4.94583 12.2415C4.99268 12.3143 5.0558 12.3753 5.13023 12.4197L9.00023 13.9997V3.99969C8.99078 3.91357 8.96148 3.83082 8.91463 3.75794C8.86777 3.68506 8.80465 3.62404 8.73023 3.57969L4.86023 2.04969Z"
            fill="black"
        ></path>
        <path
            d="M8.22988 9.07012C8.42318 9.07012 8.57988 8.91342 8.57988 8.72012C8.57988 8.52682 8.42318 8.37012 8.22988 8.37012C8.03658 8.37012 7.87988 8.52682 7.87988 8.72012C7.87988 8.91342 8.03658 9.07012 8.22988 9.07012Z"
            fill="black"
        ></path>
    </svg>
);
export default DoorSvg;
