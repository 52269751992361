import { HomeOutlined, LineChartOutlined } from '@ant-design/icons';
import { IContact, IProperty, ISimpleUser } from '@pulppo/pulppo-models';
import { Badge, List } from 'antd';
import router from 'next/router';
import { useEffect, useState } from 'react';
import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { twMerge } from 'tailwind-merge';
import Button from '../Elements/Button';
import useUser from '../../hooks/useUser';
import { ShowContactList } from '../Contact/SimpleContactList';
import axios from '../../utils/axios';
import { api_url } from '../../helpers/fetcher';
import { AddressInputDrawer } from '../Elements/AddressDrawer';
import { MapsLocationToAddress } from '../../helpers/mapsLocationToAddress';
import CaptureDataOption from '../Captures/CaptureDataOption';
import { FileUploadInput } from '../Property/Steps/step-3';
import { LoadingButton } from '../Elements/ButtonLoading';
import useCaptureProperty from '../../hooks/useCaptureProperty';
import { useShallow } from 'zustand/react/shallow';

interface Props {
    buttonText?: string;
    className?: string;
    type?: 'black';
}

export const CreatePropertyButton = ({ className, buttonText, type }: Props) => {
    const [show, setShow] = useState(false);

    return (
        <>
            <Button
                disabled={false}
                onClick={() => {
                    setShow(true);
                }}
                type={type || 'primary'}
                className={twMerge(
                    'h-[38px] w-full px-6 md:flex md:w-auto md:items-center md:justify-center md:py-2',
                    className
                )}
            >
                {buttonText || 'Nueva propiedad'}
            </Button>
            <CreatePropertyDrawer
                show={show}
                onClose={() => {
                    setShow(false);
                }}
            />
        </>
    );
};

export const CreatePropertyDrawer = ({
    contact = null,
    show,
    onClose = () => {}
}: {
    contact?: IContact;
    show: boolean;
    onClose?: () => void;
}) => {
    const user = useUser((state) => state.user);
    const [showDrawerCaptureData, setShowDrawerCaptureData] = useState(false);

    return (
        <>
            <PulppoDrawer
                visible={show}
                onClose={() => {
                    onClose();
                }}
                title="¿Qué quieres crear?"
            >
                <List>
                    {!!user?.features?.capture10 && (
                        <List.Item
                            className="cursor-pointer hover:underline"
                            onClick={() => {
                                setShowDrawerCaptureData(true);
                            }}
                        >
                            <List.Item.Meta
                                title={
                                    <p className="flex gap-x-2 text-sm font-semibold">
                                        <img width={18} height={18} src="/icons/docHouse.svg" alt="Document House" />
                                        Captación de propiedad
                                    </p>
                                }
                                className="[&>.ant-list-item-meta-content>.ant-list-item-meta-description]:text-sm [&>.ant-list-item-meta-content>.ant-list-item-meta-description]:font-normal"
                                description={<p>Crea una captación de una potencial propiedad</p>}
                            />
                        </List.Item>
                    )}
                    {process.env.NEXT_PUBLIC_COUNTRY_CODE === 'AR' ? (
                        <></>
                    ) : (
                        <>
                            <List.Item
                                className="cursor-pointer hover:underline"
                                onClick={async () => {
                                    await router.push(
                                        contact?._id
                                            ? `/listing/new-property/step-1?contact=${contact?._id}`
                                            : '/listing/new-property/step-1?'
                                    );
                                }}
                            >
                                <List.Item.Meta
                                    title={
                                        <p className="text-sm font-semibold">
                                            <HomeOutlined className="mr-2 text-xl" />
                                            Propiedad
                                        </p>
                                    }
                                    className="[&>.ant-list-item-meta-content>.ant-list-item-meta-description]:text-sm [&>.ant-list-item-meta-content>.ant-list-item-meta-description]:font-normal"
                                    description="Crea una nueva propiedad. Completa todos los datos, carga las fotos e indica el precio. Nosotros nos encargaremos de publicarla en los portales por ti!"
                                />
                            </List.Item>
                            <List.Item
                                className="cursor-pointer hover:underline"
                                onClick={async () => {
                                    await router.push(
                                        contact?._id
                                            ? `/acm/new-acm/step-1?contact=${contact?._id}`
                                            : `/acm/new-acm/step-1`
                                    );
                                }}
                            >
                                <List.Item.Meta
                                    title={
                                        <p className="text-sm font-semibold">
                                            <LineChartOutlined className="mr-2 text-xl" />
                                            Análisis comparativo de mercado
                                        </p>
                                    }
                                    className="[&>.ant-list-item-meta-content>.ant-list-item-meta-description]:text-sm [&>.ant-list-item-meta-content>.ant-list-item-meta-description]:font-normal"
                                    description={
                                        <p>
                                            Crea un nuevo análsis comparativo de mercado para estimar el precio de la
                                            propiedad a vender. Luego podrás compartir el documento generado con tu
                                            contacto
                                        </p>
                                    }
                                />
                            </List.Item>
                        </>
                    )}
                </List>
            </PulppoDrawer>
            <CreateCaptureDrawer
                contact={contact}
                show={showDrawerCaptureData}
                onClose={() => {
                    setShowDrawerCaptureData(false);
                    onClose();
                }}
            />
        </>
    );
};

export const CreateCaptureDrawer = ({
    show,
    onClose,
    contact
}: {
    show: boolean;
    onClose: () => void;
    contact?: IContact | ISimpleUser;
}) => {
    const [showDrawerLocation, setShowDrawerLocation] = useState(false);
    const [showDrawerImport, setShowDrawerImport] = useState(false);
    const [pictureToAnalyze, setPictureToAnalyze] = useState(null);
    const user = useUser((state) => state.user);
    const [metadata, setMetadata] = useState<{
        gps: {
            latitude?: number;
            longitude?: number;
        };
    }>();
    const setValuesIADetected = useCaptureProperty(useShallow((state) => state.setValuesIADetected));

    useEffect(() => {
        setPictureToAnalyze(null);
    }, [showDrawerImport]);

    const createProperty = async (property: Partial<IProperty>) => {
        axios
            .post(`${api_url}/property`, {
                contact: contact || null,
                ...property,
                editedAt: new Date()
            })
            .then((p) => {
                router.push(`/captaciones/${p.data._id}`);
            });
    };

    return (
        <>
            <PulppoDrawer title="Selecciona una opción" visible={show} onClose={onClose}>
                <p className="font-light">Necesitamos que cargues alguno de los siguientes datos para comenzar</p>
                <div className="mt-4 space-y-6">
                    {!contact && (
                        <CaptureDataOption
                            title="Datos del cliente"
                            description="Selecciona un contacto de tu agenda o añade uno nuevo"
                            button={{
                                text: 'Añadir propietario',
                                onClick: () => {
                                    const { destroy } = ShowContactList({
                                        onAdd: async (contact) => {
                                            await createProperty({
                                                contact
                                            });
                                            onClose();
                                            destroy();
                                        },
                                        onSelect: async (contact) => {
                                            await createProperty({
                                                contact
                                            });
                                            onClose();
                                            destroy();
                                        }
                                    });
                                }
                            }}
                            img={{
                                src: '/icons/document-user.svg',
                                className: 'w-[48px] h-[48px]'
                            }}
                        />
                    )}
                    <CaptureDataOption
                        title="Datos de la propiedad"
                        description="Si cuentas con algunos datos de la propiedad complétalos"
                        button={{
                            text: 'Completar datos',
                            onClick: () => {
                                setShowDrawerLocation(true);
                            }
                        }}
                        img={{
                            src: '/icons/docHouseDetail.svg',
                            className: 'w-[42px] mr-2 h-[42px]'
                        }}
                    />
                    {user?.features?.allowCaptureFromImage ? (
                        <CaptureDataOption
                            title="Subir una imagen"
                            description="Si cuentas con fotos de la propiedad, notas o lonas, súbela y cargaremos los datos que identifiquemos"
                            button={{
                                text: 'Completar datos',
                                onClick: () => {
                                    setShowDrawerImport(true);
                                }
                            }}
                            img={{
                                src: '/icons/image-gallery.svg',
                                className: 'w-[42px] mr-4 h-[42px]'
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </PulppoDrawer>
            <PulppoDrawer
                title="Importar atravez de una fotografía"
                visible={showDrawerImport}
                onClose={() => setShowDrawerImport(false)}
            >
                <div className="flex h-full flex-col">
                    <FileUploadInput
                        multiple={false}
                        value={pictureToAnalyze ? [pictureToAnalyze] : undefined}
                        onChange={(pics) => {
                            setPictureToAnalyze(pics[0]);
                        }}
                        onGetMetadata={setMetadata}
                        text="Clic aquí o arrastre la fotografía"
                        imgSrc="/icons/image-gallery.svg"
                        buttonText="Subir fotografía"
                        innerDescription={
                            <div className="mx-auto mt-2 max-w-sm list-disc px-4 text-left font-light text-primary-black">
                                <p>Sube una fotografía de:</p>
                                <ul className="list-inside list-disc">
                                    <li>Una nota que hayas tomado</li>
                                    <li>Fotografía de lona</li>
                                </ul>
                            </div>
                        }
                    />
                    <div className="flex flex-grow items-end gap-x-2">
                        <Button
                            onClick={() => {
                                setShowDrawerImport(false);
                            }}
                            className="w-full"
                            type="secondary"
                        >
                            Atrás
                        </Button>
                        <LoadingButton
                            onClick={async () => {
                                if (pictureToAnalyze) {
                                    const result = await axios.post(`${api_url}/property/capture/analyze-image`, {
                                        imageUrl: pictureToAnalyze?.url,
                                        metadata
                                    });
                                    if (result?.data?.data) {
                                        setValuesIADetected(result.data.data.valuesIADetect);
                                        await router.push(`/captaciones/${result.data.data._id}`);
                                        onClose();
                                        setShowDrawerImport(false);
                                    }
                                }
                            }}
                            disabled={!pictureToAnalyze}
                            className="h-10 w-full"
                            type="primary"
                        >
                            Continuar
                        </LoadingButton>
                    </div>
                </div>
            </PulppoDrawer>
            <AddressInputDrawer
                focus
                onSelect={async (pl) => {
                    const mappedAddress = await MapsLocationToAddress(pl);
                    if (!mappedAddress) {
                        return;
                    }
                    await createProperty({
                        address: mappedAddress
                    });
                    setShowDrawerLocation(false);
                    onClose();
                }}
                show={showDrawerLocation}
                onClose={() => setShowDrawerLocation(false)}
            />
        </>
    );
};
