import { RightOutlined, UpOutlined } from '@ant-design/icons';
import { IProperty } from '@pulppo/pulppo-models';

const tabs = {
    low: {
        bg: 'bg-notification-green',
        text: 'text-strong-green',
        title: 'Precio óptimo'
    },
    medium: {
        bg: 'bg-notification-yellow',
        text: 'text-strong-yellow',
        title: 'Precio no competitivo'
    },
    high: {
        bg: 'bg-notification-red',
        text: 'text-strong-red',
        title: 'Precio fuera de mercado'
    }
};

const ComparableSectionHeader = ({
    onSelect,
    tab,
    comparables,
    active
}: {
    comparables: IProperty[];
    tab: 'low' | 'medium' | 'high' | null;
    active: boolean;
    onSelect: (tab) => void;
}) => {
    return (
        <div
            className="flex w-full cursor-pointer items-center justify-between border-b border-solid border-gray-200 py-4"
            onClick={() => onSelect(tab)}
        >
            <p className="font-semibold">{tabs[tab].title}</p>
            <div className="flex items-center gap-2">
                <p className={`px-2 py-1 ${tabs[tab].text} ${tabs[tab].bg}`}>
                    {
                        comparables?.filter((cmp) => cmp.valuation?.result === tab && cmp.status?.last !== 'cancelled')
                            ?.length
                    }{' '}
                    propiedades
                </p>
                {active ? <UpOutlined className="text-xl" /> : <RightOutlined className="text-xl" />}
            </div>
        </div>
    );
};

export default ComparableSectionHeader;
