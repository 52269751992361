import { Row, Col, Button, List, Input, Modal } from 'antd';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { api_url } from '../../helpers/fetcher';
import { SearchOutlined, TeamOutlined } from '@ant-design/icons';
import { IAgent, IContact } from '@pulppo/pulppo-models';
import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { CreateContact } from '../CreateContact/CreateContact';
import useOnScreen from '../../hooks/useOnScreen';
import { useDebounceValue } from '../../hooks/debounceValue';
import { NextImage } from '../Elements/NextImage';
import { BasicInformation } from '../CreateContact/BasicInformation';
import axios from '../../utils/axios';
import useIsMobile from '../../hooks/isMobile';
import { PulppoLoader } from '../Elements/PulppoLoader';
import { fakeContacts } from '../../utils/fake';
import { twMerge } from 'tailwind-merge';
import { AgentSelectContacts } from '../Elements/AgentSelectContacts';
import useContactSearch from '../../hooks/useContactSearch';

export const ContactList = ({
    user,
    broker = null,
    onSelect,
    onAdd = null,
    inDrawer = false,
    editContact = null,
    useTypesense = false,
    sort
}: {
    user: IAgent;
    broker?: IAgent;
    onSelect?: (elem: IContact) => void;
    onAdd?: (contact: IContact) => void;
    inDrawer?: boolean;
    editContact?: IContact;
    useTypesense?: boolean;
    sort?: {
        createdAt: 'asc' | 'desc';
    };
}) => {
    const router = useRouter();
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearch = useDebounceValue(searchTerm, 500);
    const lastContact = useRef(null);
    const loadMore = useOnScreen(lastContact);
    const [showEditDrawer, setShowEditDrawer] = useState(!!editContact?._id);
    const [editedContact, setEditedContact] = useState(editContact);
    const [company, setCompany] = useState(broker?.company?._id || user?.company?._id);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [addContact, setAddContact] = useState(false);
    const [selectedAgent, setSelectedAgent] = useState(broker?.uid || user?.uid);
    const isMobile = useIsMobile();
    const {
        contacts: allPages,
        setSize,
        isValidating,
        reload: updateContacts
    } = useContactSearch({
        query: debouncedSearch,
        userUid: selectedAgent,
        company,
        options: {
            useTypesense
        },
        sort
    });

    useEffect(() => {
        if (broker?.uid || user?.uid) {
            setSelectedAgent(broker?.uid || user?.uid);
            setCompany(broker?.company?._id || user?.company?._id);
        }
    }, [broker?.uid, user?.uid]);

    useEffect(() => {
        if (loadMore && !isValidating) {
            setSize((size) => size + 1);
        }
    }, [loadMore]);

    const contacts: Array<IContact> = user ? allPages : fakeContacts;

    return (
        <div className="flex h-full flex-col">
            <PulppoDrawer
                title="Editar contacto"
                visible={showEditDrawer}
                onClose={() => {
                    setShowEditDrawer(false);
                }}
            >
                <BasicInformation
                    showAdditionalInfo={false}
                    onUpdate={(contact) => {
                        setEditedContact(contact);
                    }}
                    footer={
                        <div className="flex w-full gap-4">
                            <Button
                                className="h-10 flex-1 rounded-md font-medium"
                                onClick={() => {
                                    setShowEditDrawer(false);
                                }}
                            >
                                Elegir otro contacto
                            </Button>
                            <Button
                                loading={saving}
                                type="primary"
                                className="h-10 flex-1 rounded-md font-medium"
                                onClick={() => {
                                    setSaving(true);
                                    axios
                                        .patch(`${api_url}/contact/${editContact._id}`, editedContact)
                                        .then((res) => {
                                            setSaving(false);
                                            setShowEditDrawer(false);
                                            onSelect(res.data);
                                        })
                                        .catch(() => {
                                            setLoading(false);
                                            Modal.error({
                                                title: 'Error al editar contacto',
                                                content:
                                                    'Hubo un error al intentar editar este contacto. Por favor intenelo más tarde.'
                                            });
                                        });
                                }}
                            >
                                Guardar
                            </Button>
                        </div>
                    }
                    user={user}
                    contact={editContact}
                    onCancel={() => {}}
                    onFinish={() => {}}
                />
            </PulppoDrawer>
            <PulppoDrawer
                title="Agregar contacto"
                visible={addContact}
                onClose={() => {
                    setAddContact(false);
                }}
            >
                <CreateContact
                    user={user}
                    initialValue={null}
                    onCancel={() => setAddContact(false)}
                    setCurrentStep={() => {}}
                    onFinish={
                        onAdd
                            ? (contact) => {
                                  setAddContact(false);
                                  setLoading(true);
                                  updateContacts();
                                  onAdd(contact);
                              }
                            : null
                    }
                />
            </PulppoDrawer>

            <div
                className={twMerge(
                    'sticky top-0 z-20 flex w-full flex-col gap-4 bg-white p-4 pb-2 shadow-sm md:shadow-none',
                    inDrawer && 'items-stretch'
                )}
            >
                {!inDrawer && (
                    <div className="hidden w-full justify-between md:flex">
                        <p className="flex-1 text-lg">Contactos</p>
                        <Button
                            className="flex-0 flex h-10 w-fit items-center justify-center font-medium"
                            type="primary"
                            onClick={() => {
                                setAddContact(true);
                            }}
                        >
                            Agregar contacto
                        </Button>
                    </div>
                )}

                {
                    <div className="flex gap-2">
                        <Input
                            className="h-10 w-full rounded-none bg-white py-2 text-pulppo-status-dark-disabled"
                            prefix={<SearchOutlined className="text-black" />}
                            value={searchTerm}
                            allowClear
                            onKeyDown={(ev) => {
                                if (ev.code === 'Escape') setSearchTerm('');
                            }}
                            placeholder="Buscar..."
                            onInput={(e) => setSearchTerm(e.currentTarget.value)}
                        />
                        <AgentSelectContacts
                            renderButton={() => (
                                <Button className="h-10 rounded-none bg-white py-2">
                                    <TeamOutlined />
                                </Button>
                            )}
                            allowAll={true}
                            user={user}
                            className="flex-1 md:w-auto"
                            onChange={(val) => setSelectedAgent(val?.uid)}
                            onChangeCompany={(val) => setCompany(val?._id)}
                        />
                    </div>
                }
            </div>
            <Row className="flex min-h-0 flex-1 overflow-y-auto overflow-x-hidden px-4" id="scrollableDiv">
                <Col span={24}>
                    <PulppoLoader loading={loading || isValidating}>
                        <List
                            itemLayout="horizontal"
                            dataSource={contacts || []}
                            renderItem={(item: IContact) => (
                                <List.Item
                                    className="cursor-pointer"
                                    actions={[
                                        item.tags?.includes('broker') ? (
                                            <div className="rounded-none bg-black p-2 px-4 text-sm text-white">
                                                Asesor
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    ]}
                                    onClick={async () => {
                                        if (onSelect) {
                                            if (useTypesense) {
                                                axios.get(`${api_url}/contact/${item._id}`).then((res) => {
                                                    onSelect(res.data);
                                                });
                                            } else {
                                                onSelect(item);
                                            }
                                        } else {
                                            await router.push(`/contact/${item._id}`);
                                        }
                                    }}
                                >
                                    <List.Item.Meta
                                        title={<p className="mb-0">{`${item.firstName} ${item.lastName}`}</p>}
                                        description={
                                            <>
                                                {item.emails?.[0] && (
                                                    <p className="text-sm">{item.emails?.[0]?.email}</p>
                                                )}
                                                {item.phones?.[0] && (
                                                    <p className="text-sm">{item.phones?.[0]?.plain}</p>
                                                )}
                                            </>
                                        }
                                        avatar={
                                            <NextImage
                                                showPreview={false}
                                                src={
                                                    item.profilePicture ||
                                                    `https://ui-avatars.com/api/?name=${item?.firstName}%20${item?.lastName}`
                                                }
                                                alt={item.profilePicture}
                                                width={120}
                                                height={120}
                                                className="h-20 w-20 rounded-full"
                                            />
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                        <div className="h-10" ref={lastContact} />
                    </PulppoLoader>
                </Col>
            </Row>

            {(inDrawer || isMobile) && user && (
                <div className="sticky bottom-0 z-20 flex bg-white px-4 py-4">
                    <Button
                        className="h-auto flex-1 py-2 font-medium"
                        type="primary"
                        onClick={() => {
                            setAddContact(true);
                        }}
                    >
                        Agregar contacto
                    </Button>
                </div>
            )}
        </div>
    );
};
