import Link from 'next/link';
import Button from '../Elements/Button';
import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { FunnelFilters, FunnelInner } from '../Home/Funnel';
import { useState } from 'react';
import { DaysFunnel } from '../Home/BarPorgressStat';
import { RightOutlined } from '@ant-design/icons';
import useSWR from 'swr';
import { api_url, fetcher } from '../../helpers/fetcher';
import useUser from '../../hooks/useUser';

export const Searches = ({ show, onClose }) => {
    const user = useUser((u) => u.user);
    const [filters, setFilters] = useState({
        show: false,
        typeOperation: 'all',
        timeVisualization: 30 as DaysFunnel,
        agent: user
    });
    const { data } = useSWR(filters?.agent?.uid && `${api_url}/metrics/agent/${filters?.agent?.uid}/searches`, fetcher);

    return (
        <>
            <PulppoDrawer
                visible={show}
                onClose={onClose}
                title={`Búsquedas (últimos ${filters?.timeVisualization} días)`}
            >
                <div className="flex h-full flex-col justify-between">
                    <div>
                        <FunnelInner
                            timeVisualization={filters?.timeVisualization}
                            typeOperation={filters?.typeOperation}
                            agentId={filters?.agent?.uid}
                        />
                        {data?.data?.pending > 0 && (
                            <Link href={`/buyers?noFollowUp=true`}>
                                <div className="mt-4 flex cursor-pointer items-center justify-between bg-pulppo-status-light-error p-2 text-pulppo-status-dark-error">
                                    <div className="flex items-center gap-2 font-light">
                                        <span className="font-bold">{data?.data?.pending || 0}</span>
                                        <span>búsquedas sin seguimiento</span>
                                    </div>
                                    <RightOutlined />
                                </div>
                            </Link>
                        )}
                    </div>
                    <div className="flex gap-2">
                        <Button
                            onClick={() => setFilters((f) => ({ ...f, show: true }))}
                            className="flex-1"
                            type="secondary"
                        >
                            Filtros
                        </Button>
                        <Link href={'/buyers'}>
                            <Button className="flex-1" type="primary">
                                Ir a búsquedas
                            </Button>
                        </Link>
                    </div>
                </div>
            </PulppoDrawer>
            <FunnelFilters
                show={filters?.show}
                onClose={() => setFilters((f) => ({ ...f, show: false }))}
                filters={filters}
                setFilters={setFilters}
            />
        </>
    );
};
