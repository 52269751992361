import { ResponseMetric } from '../../services/metricService';
import useMetricDrawer, { UseMetricDrawerData } from '../../hooks/useMetric/useMetricDrawer';
import { secondsToMinutes } from '../../utils/parser';
import dayjs from 'dayjs';
import { BasicColor, PropsAgentListActions } from './AgentsList';
import MetricBarChart from './MetricBarChart';
import { METRICS_DESCRIPTION } from '../../utils/constants';
import { MetricType } from '@pulppo/pulppo-models/build/enums/MetricType';
import { twMerge } from 'tailwind-merge';
import { ReactNode } from 'react';
import Badge from '../Elements/Badge';
import { getColorBadgeMetric, getColorMetricChart } from '../../utils/ConstantsMetrics';

const getPercentagePropertySale = (metrics: ResponseMetric) => {
    const percentage = Math.trunc((metrics.data.propertiesSale / metrics.data.properties) * 100 || 0);
    return percentage > 100 ? 100 : percentage;
};

interface Props {
    data: Array<ResponseMetric>;
    type: MetricType;
    simple?: boolean;
}

const MetricChart = ({ data, type, simple = false }: Props) => {
    let newData: Array<UseMetricDrawerData>;
    let actions: PropsAgentListActions<any>;

    if (type === MetricType.UnansweredLeads) {
        newData = data.map((metric) => {
            return {
                id: metric.week,
                value: {
                    y: metric.data.unansweredLeads,
                    x: dayjs(new Date(metric.week)).tz().toISOString()
                },
                status: getColorMetricChart(type, metric.data.unansweredLeads)
            };
        });
        actions = {
            sort: (a, b) => b.metrics.data.unansweredLeads - a.metrics.data.unansweredLeads,
            tag: {
                condition: (agent) => {
                    return getColorMetricChart(type, agent.metrics.data.unansweredLeads);
                },
                text: {
                    success: (agent) => `${agent.metrics.data.unansweredLeads} sin responder`,
                    error: (agent) => `${agent.metrics.data.unansweredLeads} sin responder`
                }
            }
        };
    } else if (type === MetricType.FirstResponseTime) {
        newData = data.map((metric) => {
            const minutes = secondsToMinutes(metric.data.firstResponseTime);
            return {
                id: metric.week,
                value: {
                    x: dayjs(new Date(metric.week)).tz().toISOString(),
                    y: minutes || 0
                },
                status: getColorMetricChart(type, minutes)
            };
        });
        actions = {
            sort: (a, b) => {
                if (!a.metrics) {
                    return -1;
                }
                if (!b.metrics) {
                    return 1;
                }
                return b.metrics.data.firstResponseTime - a.metrics.data.firstResponseTime;
            },
            tag: {
                condition: (agent) => {
                    const minutes = !agent.metrics ? 0 : secondsToMinutes(agent.metrics.data.firstResponseTime);
                    return getColorMetricChart(type, minutes);
                },
                text: {
                    success: (agent) =>
                        !agent.metrics
                            ? 'Sin datos'
                            : `${secondsToMinutes(agent.metrics.data.firstResponseTime)} minutos`,
                    error: (agent) =>
                        !agent.metrics
                            ? 'Sin datos'
                            : `${secondsToMinutes(agent.metrics.data.firstResponseTime)} minutos`
                }
            }
        };
    } else if (type === MetricType.AvgResponseTime) {
        newData = data.map((metric) => {
            const minutes = secondsToMinutes(metric.data.avgResponseTime);
            return {
                id: metric.week,
                value: {
                    x: dayjs(new Date(metric.week)).tz().toISOString(),
                    y: minutes
                },
                status: getColorMetricChart(type, minutes)
            };
        });
        actions = {
            sort: (a, b) => {
                if (!a.metrics) {
                    return -1;
                }
                if (!b.metrics) {
                    return 1;
                }
                return b.metrics.data.avgResponseTime - a.metrics.data.avgResponseTime;
            },
            tag: {
                condition: (agent) => {
                    const minutes = !agent.metrics ? 0 : secondsToMinutes(agent.metrics.data.avgResponseTime);
                    return getColorMetricChart(type, minutes);
                },
                text: {
                    success: (agent) =>
                        !agent.metrics
                            ? 'Sin datos'
                            : `${secondsToMinutes(agent.metrics.data.avgResponseTime)} minutos`,
                    error: (agent) =>
                        !agent.metrics ? 'Sin datos' : `${secondsToMinutes(agent.metrics.data.avgResponseTime)} minutos`
                }
            }
        };
    } else if (type === MetricType.Interactions) {
        newData = data.map((metric) => {
            const interactions = metric.data.interactions || 0;
            return {
                id: metric.week,
                value: {
                    y: Math.round(interactions),
                    x: dayjs(new Date(metric.week)).tz().toISOString()
                },
                verticalTextY: interactions >= 75 ? 'Bueno' : interactions < 50 ? 'Malo' : 'Regular',
                status: getColorMetricChart(type, interactions)
            };
        });
        actions = {
            sort: (a, b) => {
                if (!a.metrics) {
                    return -1;
                }
                if (!b.metrics) {
                    return 1;
                }
                return a.metrics.data.interactions - b.metrics.data.interactions;
            },
            tag: {
                condition: (agent) =>
                    !agent.metrics
                        ? BasicColor.Wrong
                        : agent.metrics.data.interactions >= 75
                          ? BasicColor.Good
                          : agent.metrics.data.interactions < 50
                            ? BasicColor.Wrong
                            : BasicColor.Neutral,
                text: {
                    success: (agent) => (!agent.metrics ? 'Sin datos' : 'Bueno'),
                    error: (agent) =>
                        !agent.metrics ? 'Sin datos' : `${agent.metrics.data.interactions < 50 ? 'Malo' : 'Regular'}`
                }
            }
        };
    } else if (type === MetricType.VisitRate) {
        newData = data.map((metric) => {
            return {
                id: metric.week,
                value: {
                    y: Math.round(metric.data.visitRate) || 0,
                    x: dayjs(new Date(metric.week)).tz().toISOString()
                },
                status: getColorMetricChart(type, metric.data.visitRate)
            };
        });
        actions = {
            sort: (a, b) => a.metrics.data.visitRate - b.metrics.data.visitRate,
            tag: {
                condition: (agent) => {
                    return getColorMetricChart(MetricType.VisitRate, agent?.metrics?.data?.visitRate || 0);
                },
                text: {
                    success: (agent) => `${agent.metrics.data.visitRate}%`,
                    error: (agent) => `${agent.metrics.data.visitRate}%`
                }
            }
        };
    } else if (type === MetricType.OfferRate) {
        newData = data.map((metric) => {
            return {
                id: metric.week,
                value: {
                    y: parseFloat(metric.data.offerRate?.toFixed(2)) || 0,
                    x: dayjs(new Date(metric.week)).tz().toISOString()
                },
                status: getColorMetricChart(type, metric.data.offerRate)
            };
        });
        actions = {
            sort: (a, b) => a.metrics.data.offerRate - b.metrics.data.offerRate,
            tag: {
                condition: (agent) => {
                    const offerRate = agent?.metrics?.data?.offerRate || 0;
                    return getColorMetricChart(type, offerRate);
                },
                text: {
                    success: (agent) => `${agent.metrics.data.offerRate}%`,
                    error: (agent) => `${agent.metrics.data.offerRate}%`
                }
            }
        };
    } else if (type === MetricType.PropertiesSale) {
        newData = data.map((metric) => {
            const percentage = getPercentagePropertySale(metric);
            return {
                id: metric.week,
                value: {
                    y: isFinite(percentage) ? percentage : 0,
                    x: dayjs(new Date(metric.week)).tz().toISOString()
                },
                status: getColorMetricChart(type, percentage)
            };
        });
        actions = {
            sort: (a, b) => getPercentagePropertySale(a.metrics) - getPercentagePropertySale(b.metrics),
            tag: {
                condition: (agent) => {
                    const percentage = getPercentagePropertySale(agent.metrics);
                    return getColorMetricChart(type, percentage);
                },
                text: {
                    success: (agent) => `${getPercentagePropertySale(agent.metrics)}%`,
                    error: (agent) => `${getPercentagePropertySale(agent.metrics)}%`
                }
            }
        };
    } else if (type === MetricType.Properties) {
        newData = data.map((metric) => {
            return {
                id: metric.week,
                value: {
                    y: Math.round(metric.data.properties),
                    x: dayjs(new Date(metric.week)).tz().toISOString()
                },
                status: getColorMetricChart(type, metric.data.properties)
            };
        });
        actions = {
            sort: (a, b) => a.metrics.data.properties - b.metrics.data.properties,
            tag: {
                condition: (agent) => {
                    const properties = agent?.metrics?.data?.properties || 0;
                    return getColorMetricChart(type, properties);
                },
                text: {
                    success: (agent) => `${agent.metrics.data.properties} propiedades`,
                    error: (agent) => `${agent.metrics.data.properties} propiedades`
                }
            }
        };
    } else if (type === MetricType.TasksPendingClient || type === MetricType.TasksExpiredClient) {
        newData = data.map((metric) => {
            const totalTasks = Math.floor(metric.data.tasksExpiredClient + metric.data.tasksPendingClient);

            return {
                id: metric.week,
                value: {
                    y: totalTasks,
                    x: dayjs(new Date(metric.week)).tz().toISOString()
                },
                status: getColorMetricChart(type, totalTasks)
            };
        });
        actions = {
            sort: (a, b) => {
                const totalTasksA = a.metrics.data.tasksExpiredClient + a.metrics.data.tasksPendingClient;
                const totalTasksB = b.metrics.data.tasksExpiredClient + b.metrics.data.tasksPendingClient;

                return (totalTasksB || 0) - (totalTasksA || 0);
            },
            tag: {
                condition: (agent) => {
                    const totalTasks =
                        Math.floor(
                            agent?.metrics?.data?.tasksExpiredClient + agent?.metrics?.data?.tasksPendingClient
                        ) || 0;

                    return getColorMetricChart(type, totalTasks);
                },
                text: {
                    success: 'Completadas',
                    error: (agent) => {
                        const totalTasks =
                            Math.floor(
                                agent?.metrics?.data?.tasksExpiredClient + agent?.metrics?.data?.tasksPendingClient
                            ) || 0;

                        return `${totalTasks} pendientes`;
                    }
                }
            }
        };
    } else if (type === MetricType.TasksPendingProperty || type === MetricType.TasksExpiredProperty) {
        newData = data.map((metric) => {
            const totalTasks = Math.floor(metric.data.tasksExpiredProperty + metric.data.tasksPendingProperty);

            return {
                id: metric.week,
                value: {
                    y: totalTasks,
                    x: dayjs(new Date(metric.week)).tz().toISOString()
                },
                status: getColorMetricChart(type, totalTasks)
            };
        });
        actions = {
            sort: (a, b) => {
                const totalTasksA = a.metrics.data.tasksExpiredProperty + a.metrics.data.tasksPendingProperty;
                const totalTasksB = b.metrics.data.tasksExpiredProperty + b.metrics.data.tasksPendingProperty;

                return (totalTasksB || 0) - (totalTasksA || 0);
            },
            tag: {
                condition: (agent) => {
                    const totalTasks =
                        Math.floor(
                            agent?.metrics?.data?.tasksExpiredProperty + agent?.metrics?.data?.tasksPendingProperty
                        ) || 0;

                    return getColorMetricChart(type, totalTasks);
                },
                text: {
                    success: 'Completadas',
                    error: (agent) => {
                        const totalTasks =
                            Math.floor(
                                agent?.metrics?.data?.tasksExpiredProperty + agent?.metrics?.data?.tasksPendingProperty
                            ) || 0;

                        return `${totalTasks} pendientes`;
                    }
                }
            }
        };
    } else if (type === MetricType.AvgSuggestedProperties) {
        newData = data.map((metric) => {
            return {
                id: metric.week,
                value: {
                    y: Math.round(metric.data.avgSuggestedProperties) || 0,
                    x: dayjs(new Date(metric.week)).tz().toISOString()
                },
                status: getColorMetricChart(type, metric.data.avgSuggestedProperties)
            };
        });
        actions = {
            sort: (a, b) => a.metrics.data.avgSuggestedProperties - b.metrics.data.avgSuggestedProperties,
            tag: {
                condition: (agent) => {
                    const avgSuggestedProperties = agent?.metrics?.data?.avgSuggestedProperties || 0;
                    return getColorMetricChart(type, avgSuggestedProperties);
                },
                text: {
                    success: (agent) => `${agent.metrics.data.avgSuggestedProperties} propiedades`,
                    error: (agent) => `${agent.metrics.data.avgSuggestedProperties} propiedades`
                }
            }
        };
    }

    const {
        state: {
            metrics,
            differenceBeforeLastWeek,
            lastWeekValue,
            beforeLastWeekVerticalTextY,
            lastWeekVerticalTextY,
            beforeLastWeekValue
        }
    } = useMetricDrawer({
        data: newData
    });

    let Header = <></>;
    let label: ReactNode = undefined;
    let suffix: string = undefined;
    let referenceLineY: number = undefined;
    let tooltip: string = undefined;

    if (type === MetricType.UnansweredLeads) {
        if (!simple) {
            Header = (
                <Badge color={getColorBadgeMetric(type, lastWeekValue)} value={`${lastWeekValue} sin responder`} />
            );
            label = (
                <>
                    {differenceBeforeLastWeek >= 0 ? 'Crecimiento de' : 'Decayeron un'}{' '}
                    <span
                        className={twMerge(
                            'text-sm font-bold',
                            differenceBeforeLastWeek > 0
                                ? 'text-pulppo-status-dark-error'
                                : 'text-pulppo-status-dark-success'
                        )}
                    >
                        {Math.abs(differenceBeforeLastWeek)}
                    </span>{' '}
                    respecto a la semana anterior que fue de{' '}
                    <span
                        className={twMerge(
                            'text-sm font-bold',
                            differenceBeforeLastWeek > 0
                                ? 'text-pulppo-status-dark-error'
                                : 'text-pulppo-status-dark-success'
                        )}
                    >
                        {beforeLastWeekValue}
                    </span>
                </>
            );
            tooltip = METRICS_DESCRIPTION[MetricType.UnansweredLeads].description;
        }
    } else if (type === MetricType.FirstResponseTime) {
        referenceLineY = 15;
        suffix = '´';

        if (!simple) {
            Header = <Badge color={getColorBadgeMetric(type, lastWeekValue)} value={`${lastWeekValue} minutos`} />;
            label = (
                <>
                    Demoraron{' '}
                    <span
                        className={twMerge(
                            'text-sm font-bold',
                            differenceBeforeLastWeek <= 0
                                ? 'text-pulppo-status-dark-success'
                                : 'text-pulppo-status-dark-error'
                        )}
                    >
                        {Math.abs(differenceBeforeLastWeek)} minutos
                    </span>{' '}
                    {differenceBeforeLastWeek <= 0 ? 'menos' : 'más'} respecto de la semana anterior
                </>
            );
            tooltip = label ? METRICS_DESCRIPTION[MetricType.FirstResponseTime].description : undefined;
        }
    } else if (type === MetricType.AvgResponseTime) {
        suffix = '´';
        referenceLineY = 15;
        if (!simple) {
            Header = <Badge color={getColorBadgeMetric(type, lastWeekValue)} value={`${lastWeekValue} minutos`} />;
            label = (
                <>
                    Demoraron{' '}
                    <span
                        className={twMerge(
                            'text-sm font-bold',
                            differenceBeforeLastWeek <= 0
                                ? 'text-pulppo-status-dark-success'
                                : 'text-pulppo-status-dark-error'
                        )}
                    >
                        {Math.abs(differenceBeforeLastWeek)} minutos
                    </span>{' '}
                    {differenceBeforeLastWeek <= 0 ? 'menos' : 'más'} respecto de la semana anterior
                </>
            );
            tooltip = METRICS_DESCRIPTION[MetricType.AvgResponseTime].description;
        }
    } else if (type === MetricType.Interactions) {
        referenceLineY = 75;

        if (!simple) {
            Header = (
                <Badge
                    color={getColorBadgeMetric(type, lastWeekValue)}
                    value={lastWeekValue >= 75 ? 'Bueno' : lastWeekValue < 50 ? 'Malo' : 'Regular'}
                />
            );
            label = (
                <>
                    {beforeLastWeekVerticalTextY === lastWeekVerticalTextY ? (
                        'Se mantuvo igual que la semana anterior'
                    ) : (
                        <>
                            Se {differenceBeforeLastWeek >= 0 ? 'mejoro' : 'empeoro'} de {beforeLastWeekVerticalTextY} a{' '}
                            <span
                                className={twMerge(
                                    'text-sm font-bold',
                                    differenceBeforeLastWeek >= 0
                                        ? 'text-pulppo-status-dark-success'
                                        : 'text-pulppo-status-dark-error'
                                )}
                            >
                                {lastWeekVerticalTextY}
                            </span>
                        </>
                    )}
                </>
            );
            tooltip = METRICS_DESCRIPTION[MetricType.Interactions].description;
        }
    } else if (type === MetricType.VisitRate) {
        referenceLineY = 40;
        suffix = '%';

        if (!simple) {
            Header = <Badge color={getColorBadgeMetric(type, lastWeekValue)} value={`${lastWeekValue}%`} />;
            label = (
                <>
                    {differenceBeforeLastWeek >= 0 ? 'Crecimiento del' : 'Decayeron un'}{' '}
                    <span
                        className={twMerge(
                            'text-sm font-bold',
                            differenceBeforeLastWeek >= 0
                                ? 'text-pulppo-status-dark-success'
                                : 'text-pulppo-status-dark-error'
                        )}
                    >
                        {Math.abs(differenceBeforeLastWeek)}%
                    </span>{' '}
                    respecto a la semana anterior que fue de{' '}
                    <span
                        className={twMerge(
                            'text-sm font-bold',
                            differenceBeforeLastWeek >= 0
                                ? 'text-pulppo-status-dark-success'
                                : 'text-pulppo-status-dark-error'
                        )}
                    >
                        {beforeLastWeekValue}%
                    </span>
                </>
            );
            tooltip = METRICS_DESCRIPTION[MetricType.VisitRate].description;
        }
    } else if (type === MetricType.OfferRate) {
        referenceLineY = 3;
        suffix = '%';

        if (!simple) {
            Header = <Badge color={getColorBadgeMetric(type, lastWeekValue)} value={`${lastWeekValue}%`} />;
            label = (
                <>
                    {differenceBeforeLastWeek >= 0 ? 'Crecimiento del' : 'Decayeron un'}{' '}
                    <span
                        className={twMerge(
                            'text-sm font-bold',
                            differenceBeforeLastWeek >= 0
                                ? 'text-pulppo-status-dark-success'
                                : 'text-pulppo-status-dark-error'
                        )}
                    >
                        {Math.abs(differenceBeforeLastWeek)}%
                    </span>{' '}
                    respecto a la semana anterior que fue de{' '}
                    <span
                        className={twMerge(
                            'text-sm font-bold',
                            differenceBeforeLastWeek >= 0
                                ? 'text-pulppo-status-dark-success'
                                : 'text-pulppo-status-dark-error'
                        )}
                    >
                        {beforeLastWeekValue}%
                    </span>
                </>
            );
            tooltip = METRICS_DESCRIPTION[MetricType.OfferRate].description;
        }
    } else if (type === MetricType.PropertiesSale) {
        referenceLineY = 70;
        suffix = '%';

        if (!simple) {
            Header = <Badge color={getColorBadgeMetric(type, lastWeekValue)} value={`${lastWeekValue}%`} />;
            label = (
                <>
                    Hubo un{' '}
                    <span
                        className={twMerge(
                            'text-sm font-bold',
                            differenceBeforeLastWeek >= 0
                                ? 'text-pulppo-status-dark-success'
                                : 'text-pulppo-status-dark-error'
                        )}
                    >
                        {Math.abs(differenceBeforeLastWeek)}%
                    </span>{' '}
                    {differenceBeforeLastWeek >= 0 ? 'más' : 'menos'} propiedades en venta que la semana anterior
                </>
            );
            tooltip = METRICS_DESCRIPTION[MetricType.PropertiesSale].description;
        }
    } else if (type === MetricType.Properties) {
        referenceLineY = 30;

        if (!simple) {
            Header = <Badge color={getColorBadgeMetric(type, lastWeekValue)} value={`${lastWeekValue} propiedades`} />;
            label = (
                <>
                    Hubo{' '}
                    <span
                        className={twMerge(
                            'text-sm font-bold',
                            differenceBeforeLastWeek >= 0
                                ? 'text-pulppo-status-dark-success'
                                : 'text-pulppo-status-dark-error'
                        )}
                    >
                        {Math.abs(differenceBeforeLastWeek)} propiedades
                    </span>{' '}
                    {differenceBeforeLastWeek >= 0 ? 'más' : 'menos'} que la semana anterior
                </>
            );
            tooltip = METRICS_DESCRIPTION[MetricType.Properties].description;
        }
    } else if (type === MetricType.TasksPendingClient || type === MetricType.TasksExpiredClient) {
        if (!simple) {
            Header = <Badge color={getColorBadgeMetric(type, lastWeekValue)} value={`${lastWeekValue} pendientes`} />;
            label = (
                <>
                    Se realizaron{' '}
                    <span
                        className={twMerge(
                            'text-sm font-bold',
                            differenceBeforeLastWeek > 0
                                ? 'text-pulppo-status-dark-error'
                                : 'text-pulppo-status-dark-success'
                        )}
                    >
                        {Math.abs(differenceBeforeLastWeek)}
                    </span>{' '}
                    {differenceBeforeLastWeek > 0 ? 'menos' : 'más'} que la semana anterior
                </>
            );
            tooltip = METRICS_DESCRIPTION[MetricType.TasksPendingClient].description;
        }
    } else if (type === MetricType.TasksPendingProperty || type === MetricType.TasksExpiredProperty) {
        if (!simple) {
            Header = <Badge color={getColorBadgeMetric(type, lastWeekValue)} value={`${lastWeekValue} pendientes`} />;
            label = (
                <>
                    Se realizaron{' '}
                    <span
                        className={twMerge(
                            'text-sm font-bold',
                            differenceBeforeLastWeek > 0
                                ? 'text-pulppo-status-dark-error'
                                : 'text-pulppo-status-dark-success'
                        )}
                    >
                        {Math.abs(differenceBeforeLastWeek)}
                    </span>{' '}
                    {differenceBeforeLastWeek > 0 ? 'menos' : 'más'} que la semana anterior
                </>
            );
            tooltip = METRICS_DESCRIPTION[MetricType.TasksPendingProperty].description;
        }
    } else if (type === MetricType.AvgSuggestedProperties) {
        if (!simple) {
            Header = <Badge color={getColorBadgeMetric(type, lastWeekValue)} value={`${lastWeekValue} propiedades`} />;
            label = (
                <>
                    {differenceBeforeLastWeek >= 0 ? 'Crecimiento del' : 'Decayeron un'}{' '}
                    <span
                        className={twMerge(
                            'text-sm font-bold',
                            differenceBeforeLastWeek >= 0
                                ? 'text-pulppo-status-dark-success'
                                : 'text-pulppo-status-dark-error'
                        )}
                    >
                        {Math.abs(differenceBeforeLastWeek)}
                    </span>{' '}
                    respecto a la semana anterior que fue de{' '}
                    <span
                        className={twMerge(
                            'text-sm font-bold',
                            differenceBeforeLastWeek >= 0
                                ? 'text-pulppo-status-dark-success'
                                : 'text-pulppo-status-dark-error'
                        )}
                    >
                        {beforeLastWeekValue}
                    </span>
                </>
            );
            tooltip = METRICS_DESCRIPTION[MetricType.AvgSuggestedProperties].description;
        }
    }

    return {
        Header,
        Chart: (
            <MetricBarChart
                extra={{
                    suffix,
                    label,
                    referenceLineY,
                    tooltip
                }}
                data={metrics}
            />
        ),
        actions
    };
};

export default MetricChart;
