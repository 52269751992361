import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { api_url, fetcher } from '../../helpers/fetcher';
import { ReactNode, useEffect, useState } from 'react';
import { ClockCircleOutlined, RightOutlined, SearchOutlined, StarFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import { IAgent, IVisit } from '@pulppo/pulppo-models';
import Button from '../Elements/Button';
import { PulppoLoader } from '../Elements/PulppoLoader';
import { Input } from 'antd';
import { useDebounceValue } from '../../hooks/debounceValue';
import { PulppoRadio } from '../Elements/PulppoRadio';
import { showVisitReview } from '../Elements/VisitReviewDrawer';
import { ShowOldTourDrawer } from '../Elements/TourDrawer';
import { Image } from './IntelliHome';
import { useVisible } from '../../hooks/useVisible';
import useUser from '../../hooks/useUser';
import { AgentSelect } from '../Elements/AgentSelect';
import { ShowPortal } from '../../utils/ShowPortal';

const statusOptions = [
    { label: 'Todos', value: 'all' },
    { label: 'Pendientes', value: 'pending' },
    { label: 'Confirmadas', value: 'confirmed' },
    { label: 'Canceladas', value: 'cancelled' }
];
const timeOptions = [
    { label: 'Todas', value: 'all' },
    { label: 'Últimos 7 días', value: 'last_7_days' },
    { label: 'Últimos 15 días', value: 'last_15_days' },
    { label: 'Últimos 30 días', value: 'last_30_days' },
    { label: 'Últimos 60 días', value: 'last_60_days' },
    { label: 'Últimos 90 días', value: 'last_90_days' }
];
const SIZE = 10;

export const Visits = ({ show, onClose }) => {
    const user = useUser((u) => u.user);
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState({
        show: false,
        name: 'Seleccione las opciones deseadas',
        step: 'filter',
        status: 'all',
        time: 'all',
        agent: user
    });

    const [appliedFilters, setAppliedFilters] = useState(filters);

    const [search, setSearch] = useState('');

    const debounced = useDebounceValue(search, 300);

    const [visits, setVisits] = useState<IVisit[]>([]);
    const [count, setCount] = useState(0);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        fetcher(
            appliedFilters?.agent?.uid &&
                `${api_url}/visit/agent/${appliedFilters?.agent?.uid}/search?offset=${(page - 1) * SIZE}&limit=${SIZE}&tab=all&sort=asc${debounced ? `&search=${debounced}` : ''}&status=${appliedFilters.status}&date=${appliedFilters.time}`
        ).then((r) => {
            setCount(r.count);
            setVisits((v) => [...v, ...r.visits]);
            setLoading(false);
        });
    }, [user?.uid, page, debounced, appliedFilters?.status, appliedFilters?.time, appliedFilters?.agent?.uid]);

    const { ref: endRef, visible } = useVisible();

    useEffect(() => {
        if (visible) {
            setPage((l) => l + 1);
        }
    }, [visible]);

    return (
        <>
            <PulppoDrawer visible={show} onClose={onClose} title={`Visitas (${count})`}>
                <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2">
                        <Input
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Buscar..."
                            className="rounded-none py-2"
                            suffix={<SearchOutlined className="text-gray-300" />}
                        />
                        <Button
                            onClick={() =>
                                setFilters((filters) => ({
                                    ...filters,
                                    show: !filters.show,
                                    name: 'Seleccione las opciones deseadas',
                                    step: 'filter'
                                }))
                            }
                            type="secondary"
                        >
                            Filtros
                        </Button>
                    </div>
                    <PulppoLoader loading={isLoading} />

                    {visits.map((visit) => {
                        return (
                            <VisitCard
                                visit={visit}
                                key={visit?._id?.toString()}
                                onSave={() => {}}
                                onCancel={() => {}}
                            />
                        );
                    })}
                    <div className="h-px w-full bg-gray-200" ref={endRef} />
                </div>
            </PulppoDrawer>
            <PulppoDrawer
                title={filters.name}
                visible={filters.show}
                onClose={() => {
                    setFilters((f) => ({ ...f, show: false }));
                }}
                bodyStyle={{ height: '100%' }}
            >
                <div className="flex h-full flex-col justify-between">
                    <div className="flex flex-1 flex-col gap-4">
                        <div>
                            <p>Estado de consulta</p>
                            <PulppoRadio
                                value={filters.status}
                                title="Estado"
                                options={statusOptions}
                                onApply={function (value: string): void {
                                    setFilters((f) => ({ ...f, status: value }));
                                }}
                                onClear={() => {
                                    setFilters((f) => ({ ...f, status: 'all' }));
                                }}
                                render={({ label, value }) => (
                                    <div className="flex cursor-pointer items-center justify-between border border-solid border-gray-300 p-2">
                                        {statusOptions?.find((el) => el.value === filters.status)?.label}
                                        <RightOutlined className="text-gray-300" />
                                    </div>
                                )}
                            ></PulppoRadio>
                        </div>
                        <div>
                            <p>Tiempo</p>
                            <PulppoRadio
                                value={filters.time}
                                title="Tiempo"
                                options={timeOptions}
                                onApply={function (value: string): void {
                                    setFilters((f) => ({ ...f, time: value }));
                                }}
                                onClear={() => {
                                    setFilters((f) => ({ ...f, time: 'all' }));
                                }}
                                render={({ label, value }) => (
                                    <div className="flex cursor-pointer items-center justify-between border border-solid border-gray-300 p-2">
                                        {timeOptions?.find((el) => el.value === filters.time)?.label}
                                        <RightOutlined className="text-gray-300" />
                                    </div>
                                )}
                            ></PulppoRadio>
                        </div>
                        {(user?.features?.viewAll || user?.permissions?.searches?.view) && (
                            <div>
                                <p>Asesor</p>
                                <AgentSelect
                                    className=""
                                    onChange={(agent: IAgent) => {
                                        setFilters((f) => ({ ...f, agent }) as any);
                                    }}
                                    allowAll={true}
                                    user={user}
                                    value={filters?.agent}
                                    renderButton={(item: { label: string | ReactNode }) => {
                                        return (
                                            <div className="flex cursor-pointer items-center justify-between border border-solid border-gray-300 p-2">
                                                {filters?.agent?.firstName} {filters?.agent?.lastName}
                                                <RightOutlined className="text-gray-300" />
                                            </div>
                                        );
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <Button
                        isLoading={isLoading}
                        onClick={() => {
                            setAppliedFilters(filters);
                            setPage(1);
                            setVisits([]);
                            setFilters((f) => ({ ...f, show: false }));
                        }}
                        type="primary"
                    >
                        Filtrar
                    </Button>
                </div>
            </PulppoDrawer>
        </>
    );
};

export const VisitCard = ({
    visit,
    onSave,
    onCancel,
    closeParent = () => {}
}: {
    visit: IVisit;
    onSave: () => void;
    onCancel: () => void;
    closeParent?: () => void;
}) => {
    const status =
        visit?.status?.last === 'confirmed' && dayjs(visit?.startTime).isBefore(dayjs())
            ? 'completed'
            : visit?.status?.last;
    const { user } = useUser();
    return (
        <div key={`${visit._id}`} className="flex w-full flex-col gap-2 border border-solid border-gray-200 p-2">
            <div className="flex w-full items-center justify-between">
                <div className="flex items-center gap-2">
                    <div className="h-10 w-10">
                        <Image
                            src={
                                visit?.contact?.profilePicture ||
                                `https://ui-avatars.com/api/?name=${(visit.contact?.firstName + ' ' + visit.contact?.lastName).replace(/\b/gi, '+')}`
                            }
                            width={256}
                            height={256}
                            alt={`${visit.contact.firstName} ${visit.contact.lastName}`}
                            className="h-10 w-10 rounded-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col">
                        <h5 className="m-0 p-0 font-medium">
                            {visit.contact.firstName} {visit.contact.lastName}
                        </h5>
                        <p className="m-0 p-0 font-light text-pulppo-status-dark-disabled">
                            {dayjs(visit.startTime).format('DD/MM/YYYY')}
                        </p>
                    </div>
                </div>
                <div className="flex items-center gap-2">
                    {status === 'completed' ? (
                        <div className="flex items-center justify-center gap-1 border border-solid border-pulppo-secondary-gray-line px-2 py-1">
                            <p>{visit?.survey?.data?.avg || '-'}</p>
                            <StarFilled className="text-lg text-pulppo-primary-yellow" />
                        </div>
                    ) : status === 'confirmed' ? (
                        <div className="bg-pulppo-status-light-success px-2 py-1 text-pulppo-status-dark-success">
                            Confirmada
                        </div>
                    ) : status === 'pending' ? (
                        <div className="bg-pulppo-status-light-warning px-2 py-1 text-pulppo-status-dark-warning">
                            Pendiente
                        </div>
                    ) : status === 'cancelled' ? (
                        <div className="bg-pulppo-status-light-disabled px-2 py-1 text-pulppo-status-dark-disabled">
                            Cancelada
                        </div>
                    ) : (
                        <div className="bg-pulppo-status-light-error px-2 py-1 text-pulppo-status-dark-error">
                            Perdida
                        </div>
                    )}
                </div>
            </div>
            <div className="h-px w-full bg-gray-200" />
            {visit?.steps?.map((step) => {
                const property = step.property;
                const src = property?.pictures?.[0]?.url || '';

                return (
                    <div className="flex w-full items-center justify-between" key={step?.property?._id?.toString()}>
                        <div className="flex items-center gap-2">
                            <div className="h-12 w-12">
                                {src?.includes('images.pulppo.com') ? (
                                    <Image
                                        src={src}
                                        width={256}
                                        height={256}
                                        alt={`${property?.address?.street}`}
                                        className="h-12 w-12 object-cover"
                                    />
                                ) : (
                                    <img
                                        src={src}
                                        width={256}
                                        height={256}
                                        alt={`${property?.address?.street}`}
                                        className="h-12 w-12 object-cover"
                                    />
                                )}
                            </div>
                            <div className="flex flex-col">
                                <h5 className="m-0 p-0">{property?.address?.street}</h5>
                                <p className="m-0 p-0 font-light text-pulppo-status-dark-disabled">
                                    {[
                                        property?.address?.neighborhood?.name,
                                        property?.address?.city?.name,
                                        property?.address?.state?.name
                                    ]
                                        .filter(Boolean)
                                        .slice(0, 2)
                                        .join(', ')}
                                </p>
                            </div>
                        </div>
                        <div className="flex items-center justify-center gap-1 bg-pulppo-status-light-disabled px-2 py-1 text-xs text-pulppo-status-dark-disabled">
                            {dayjs(step.date).tz().format('HH:mm')}
                            <ClockCircleOutlined />
                        </div>
                        {/* {visit?.survey?.data?.avg && (
                            <div className="flex items-center">
                                {Math.round(visit?.survey?.data?.avg)}
                                <StarFilled className="text-pulppo-status-dark-warning" />
                            </div>
                        )} */}
                    </div>
                );
            })}

            <Button
                onClick={() => {
                    const { destroy } = ShowPortal({
                        type: 'form-visit',
                        config: {
                            onClose: (shouldCloseParent) => {
                                if (shouldCloseParent) {
                                    closeParent();
                                }
                                destroy();
                            },
                            visit,
                            onSave: async (shouldClose) => {
                                onSave();
                                if (shouldClose) destroy();
                            }
                        }
                    });
                }}
                className="h-auto py-2"
                type="secondary"
            >
                Abrir visita
            </Button>
        </div>
    );
};
