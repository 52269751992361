import { SearchOutlined } from '@ant-design/icons';
import { IAgent, ICompany } from '@pulppo/pulppo-models';
import { List, Input } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { useState } from 'react';
import { api_url, fetcher } from '../helpers/fetcher';
import useSWR from 'swr';
import { NextImage } from './Elements/NextImage';
import { CompanySelect } from './Elements/CompanySelect';

export const AgentList = ({
    user,
    onSelect,
    showAll = false,
    disableSupended = false
}: {
    user;
    showAll?: boolean;
    disableSupended?: boolean;
    onSelect: (agent: IAgent) => void;
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [company, setCompany] = useState(user?.company);

    const { data: companies } = useSWR<{
        result: Array<ICompany>;
    }>(user?.features?.viewAll && showAll && `${api_url}/company`, fetcher);

    const { data: agents } = useSWR<IAgent[]>(company?._id && `${api_url}/company/${company?._id}/agents`, fetcher);

    return (
        <>
            {companies && (
                <div className="mb-4">
                    <CompanySelect onChange={(val) => setCompany(val)} value={company} />
                </div>
            )}
            <Input
                placeholder="Buscar"
                prefix={<SearchOutlined />}
                className="mb-5 rounded-md"
                value={searchTerm}
                allowClear
                onChange={(e) => setSearchTerm(e.currentTarget.value)}
            />
            <List
                dataSource={agents?.filter((option) =>
                    option.firstName

                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase())
                )}
                renderItem={(item: IAgent) => {
                    const isSuspended = item.suspensions?.length > 0;
                    return (
                        <List.Item
                            className={`${isSuspended && disableSupended ? 'cursor-not-allowed bg-gray-100 opacity-80' : 'cursor-pointer hover:underline'} `}
                            key={item.uid}
                            onClick={
                                isSuspended && disableSupended
                                    ? undefined
                                    : () => {
                                          onSelect(item);
                                          setSearchTerm('');
                                      }
                            }
                        >
                            <List.Item.Meta
                                className="items-center"
                                avatar={
                                    <Avatar
                                        src={
                                            <NextImage
                                                showPreview={false}
                                                src={
                                                    item.profilePicture ||
                                                    `https://ui-avatars.com/api/?name=${item?.firstName}%20${item?.lastName}`
                                                }
                                                alt={item.profilePicture}
                                                width={64}
                                                height={64}
                                            />
                                        }
                                        // size="large"
                                    ></Avatar>
                                }
                                title={
                                    <p className="mb-0">
                                        {item.firstName} {item.lastName}
                                    </p>
                                }
                            ></List.Item.Meta>
                        </List.Item>
                    );
                }}
            ></List>
        </>
    );
};
