import { CloseCircleOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Row, Col, InputNumber, Input } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Services } from '@pulppo/pulppo-models/build/enums/Services';
import useIsMobile from '../../hooks/isMobile';
import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { LocationDrawer } from '../Elements/LocationDrawer';
import { PulppoMultiselect } from '../Elements/PulppoMultiselect';
import { AgentSelect } from '../Elements/AgentSelect';
import { UserContext } from '../../contexts/UserContext';
import { PropertyStatusMapper } from '../../utils/status';
import { PulppoRadio } from '../Elements/PulppoRadio';
import { OperationAndPriceSelect } from '../Elements/OperationAndPriceSelect';
import { PropertyTypeSelector } from '../Elements/PropertyTypeSelector';
import { PropertySort } from '../Elements/PropertySort';
import { CompanySelect } from '../Elements/CompanySelect';
import { useTranslate } from '@tolgee/react';
import { twMerge } from 'tailwind-merge';
import { showBoards } from '../Elements/BoardsDrawer';
import { showSpaces } from '../../utils/config';
import useSWR from 'swr';
import { api_url, fetcher } from '../../helpers/fetcher';
import { ITag } from '@pulppo/pulppo-models/@types/pulppo-models/schemas/Tag';
import { TagSelect } from '../Elements/TagSelect';

export const qualityMapper = {
    high: 'Alta',
    medium: 'Media',
    low: 'Baja',
    notPublished: 'Sin publicar'
};
export const valuationMapper = {
    low: 'Precio óptimo',
    medium: 'Precio poco competitivo',
    high: 'Precio fuera de mercado',
    'not-valuated': 'Sin valuación'
};

export const exclusiveMapper = {
    exclusive: 'Con exclusiva',
    'not-exclusive': 'Sin exclusiva'
};

export const publishMapper = {
    published: 'Difundida en portales',
    'not-published': 'No difundida en portales'
};

export const Filters = ({
    visible,
    setVisible,
    setAppliedFilters,
    appliedFilters,
    hide = {
        location: false,
        operation: false,
        type: false,
        search: false,
        suites: false,
        spaces: false,
        bathrooms: false,
        parkings: false,
        roofedSurface: false,
        totalSurface: false,
        yearBuild: false,
        services: false,
        internal: false,
        seller: false,
        radius: false,

        sort: false
    } as {
        location?: boolean;
        operation?: boolean;
        type?: boolean;
        search?: boolean;
        suites?: boolean;
        spaces?: boolean;
        bathrooms?: boolean;
        parkings?: boolean;
        roofedSurface?: boolean;
        totalSurface?: boolean;
        yearBuild?: boolean;
        services?: boolean;
        internal?: boolean;
        radius?: boolean;
        sort?: boolean;
    }
}) => {
    const { t } = useTranslate('common');
    const { user } = useContext(UserContext);
    const isMobile = useIsMobile();
    const [filters, setFilters] = useState(appliedFilters);

    useEffect(() => {
        setFilters(appliedFilters);
    }, [appliedFilters]);

    useEffect(() => {
        const params = Object.fromEntries(Object.entries(filters || {}).filter((elem) => elem[1]));
        for (const key of Object.keys(params)) {
            if (Array.isArray(params[key]) && (params[key] as any)?.length === 0) delete params[key];
        }
    }, [filters]);

    const clearFilters = () => {
        setAppliedFilters((filters) => {
            return { tab: filters?.tab };
        });
    };

    const updateFilters = (filters) => {
        if (!isMobile) delete filters?.address;
        // if (
        //     filters?.producers?.[0] ||
        //     filters?.sellers?.[0] ||
        //     filters?.quality?.[0] ||
        //     filters?.valuation?.[0] ||
        //     filters?.exclusive?.[0] ||
        //     filters?.published?.[0]
        // ) {
        //     filters.tab = 'company';
        // } else if (filters?.status?.[0]) {
        //     filters.tab = 'user';
        // }
        setAppliedFilters((appliedFilters) => ({
            ...appliedFilters,
            ...filters
        }));
    };

    const onChangeStateFilter = (values: Array<string>) => {
        if (values.slice(-1)[0] === 'valuation') {
            setFilters((filters) => ({
                ...filters,
                status: ['valuation']
            }));
        } else {
            setFilters((filters) => ({
                ...filters,
                status: values.filter((value) => value !== 'valuation')
            }));
        }
    };

    return (
        <>
            <PulppoDrawer
                visible={visible}
                onClose={() => {
                    setVisible(false);

                    updateFilters(filters);
                }}
                title="Seleccione los filtros deseados"
            >
                <div className="flex h-full flex-col justify-between">
                    <div className="flex h-3/4 flex-1 flex-col gap-4 overflow-y-auto overflow-x-hidden pb-4 pr-2">
                        {!hide.location && (
                            <>
                                <p className="font-medium">{`Busca por ${t('state', 'estado')} o ${t('neighborhood', 'colonia')}`}</p>
                                <LocationDrawer
                                    value={filters?.addresses}
                                    onChange={(value) =>
                                        setFilters((filters) => ({
                                            ...filters,
                                            addresses: value
                                        }))
                                    }
                                    render={(value) => (
                                        <div className="flex items-center justify-between rounded-none border border-solid border-gray-300 bg-white px-2 py-2">
                                            <div className="align-center flex items-center">
                                                {value?.length === 0 ? (
                                                    <p className={`text-pulppo-status-dark-disabled`}>
                                                        Seleccione las opciones deseadas
                                                    </p>
                                                ) : (
                                                    <div className="flex flex-wrap items-center gap-2">
                                                        {value?.slice(0, value?.length === 2 ? 2 : 1)?.map?.((val) => (
                                                            <div
                                                                key={val?.id}
                                                                className="flex h-6 items-center justify-center gap-2 rounded-none bg-black px-2 text-sm text-white"
                                                            >
                                                                <p>{val?.name?.split(',')?.[0]}</p>
                                                                <CloseCircleOutlined className="text-base" />
                                                            </div>
                                                        ))}
                                                        {value?.length > 2 && (
                                                            <div className="flex h-6 items-center justify-center gap-2 rounded-none bg-black px-2 text-sm text-white">
                                                                <p>{`+${value?.length - 2}`}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            {value?.length === 0 ? (
                                                <RightOutlined className="text-xl text-gray-300" />
                                            ) : (
                                                <SearchOutlined className="text-xl text-gray-300" />
                                            )}
                                        </div>
                                    )}
                                />
                            </>
                        )}

                        {!hide.operation && (
                            <div>
                                <p className="mb-2 font-medium">Operacion y precio</p>
                                <OperationAndPriceSelect
                                    value={{
                                        operation: filters?.operation || 'all',
                                        price: {
                                            min: filters?.price?.min || null,
                                            max: filters?.price?.max || null,
                                            currency:
                                                filters?.price?.currency || process.env.NEXT_PUBLIC_PRIMARY_CURRENCY
                                        }
                                    }}
                                    apply={(value) => {
                                        setFilters((filters) => ({
                                            ...filters,
                                            ...value
                                        }));
                                    }}
                                    render={() => (
                                        <div className="flex h-10 cursor-pointer items-center justify-between rounded-none border border-solid border-gray-300 px-2">
                                            {(filters?.operation && filters?.operation !== 'all') ||
                                            filters?.price?.min ||
                                            filters?.price?.max ? (
                                                <div className="flex gap-2">
                                                    {filters?.operation && filters?.operation !== 'all' ? (
                                                        <div className="flex h-6 items-center justify-center gap-2 rounded-none bg-black px-2 text-sm text-white">
                                                            <p>
                                                                {filters.operation === 'sale'
                                                                    ? 'Comprar'
                                                                    : t('to-rent', 'Rentar')}
                                                            </p>
                                                            <CloseCircleOutlined className="text-base" />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {parseInt(filters?.price?.min) || parseInt(filters?.price?.max) ? (
                                                        <div className="flex h-6 items-center justify-center gap-2 rounded-none bg-black px-2 text-sm text-white">
                                                            <p>
                                                                {`${
                                                                    filters?.price?.currency ||
                                                                    process.env.NEXT_PUBLIC_PRIMARY_CURRENCY
                                                                } ${
                                                                    parseInt(filters.price?.min)
                                                                        ? `${parseInt(
                                                                              filters.price.min
                                                                          ).toLocaleString()}`
                                                                        : ''
                                                                } ${
                                                                    parseInt(filters?.price?.min) &&
                                                                    parseInt(filters?.price?.max)
                                                                        ? '-'
                                                                        : ''
                                                                } ${
                                                                    parseInt(filters.price?.max)
                                                                        ? `${parseInt(
                                                                              filters.price.max
                                                                          ).toLocaleString()}`
                                                                        : ''
                                                                } `}
                                                            </p>
                                                            <CloseCircleOutlined className="text-base" />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            ) : (
                                                <p className="text-pulppo-status-dark-disabled">
                                                    Seleccione las opciones deseadas
                                                </p>
                                            )}
                                            {(filters?.operation && filters?.operation !== 'all') ||
                                            filters?.price?.min ||
                                            filters?.price?.max ? (
                                                <SearchOutlined className="text-xl text-gray-300" />
                                            ) : (
                                                <RightOutlined className="text-xl text-gray-300" />
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                        )}
                        {!hide.type && (
                            <div>
                                <p className="mb-2 font-medium">Tipo de Propiedad</p>
                                <PropertyTypeSelector
                                    value={filters?.types}
                                    apply={(values) => {
                                        setFilters((filters) => ({
                                            ...filters,
                                            types: values
                                        }));
                                    }}
                                    render={(value) => (
                                        <div className="flex items-center justify-between rounded-none border border-solid border-gray-300 bg-white px-2 py-2">
                                            <div className="align-center flex items-center">
                                                {value?.length === 0 ? (
                                                    <p className={`text-pulppo-status-dark-disabled`}>
                                                        Seleccione las opciones deseadas
                                                    </p>
                                                ) : (
                                                    <div className="flex flex-wrap items-center gap-2">
                                                        {value?.slice(0, value?.length === 2 ? 2 : 1)?.map?.((val) => (
                                                            <div
                                                                key={val}
                                                                className="flex h-6 items-center justify-center gap-2 rounded-none bg-black px-2 text-sm text-white"
                                                            >
                                                                <p>{val}</p>
                                                                <CloseCircleOutlined className="text-base" />
                                                            </div>
                                                        ))}
                                                        {value?.length > 2 && (
                                                            <div className="flex h-6 items-center justify-center gap-2 rounded-none bg-black px-2 text-sm text-white">
                                                                <p>{`+${value?.length - 2} filtros`}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            {value?.length === 0 ? (
                                                <RightOutlined className="text-xl text-gray-300" />
                                            ) : (
                                                <SearchOutlined className="text-xl text-gray-300" />
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                        )}
                        {!hide.internal && (
                            <div className="flex flex-col gap-2 lg:hidden">
                                <p className="font-medium">Estados</p>
                                <PulppoMultiselect
                                    allowSearch={false}
                                    showButtons
                                    className={{
                                        container: 'w-full [&>button]:w-full'
                                    }}
                                    options={Object.keys(PropertyStatusMapper).map((service) => ({
                                        key: service,
                                        label: PropertyStatusMapper[service].label,
                                        value: service
                                    }))}
                                    placeholder="Seleccione las opciones deseadas"
                                    title="Estados"
                                    onClear={() => {
                                        setFilters((filters) => ({
                                            ...filters,
                                            status: []
                                        }));
                                    }}
                                    onApply={onChangeStateFilter}
                                    onChange={onChangeStateFilter}
                                    value={filters?.status || []}
                                />
                            </div>
                        )}
                        {!hide.sort && (
                            <div>
                                <p className="mb-2 font-medium">Ordenar por</p>
                                <PropertySort
                                    filters={filters}
                                    setFilters={setFilters}
                                    render={(value) => (
                                        <div className="flex h-10 cursor-pointer items-center justify-between rounded-none border border-solid border-gray-300 px-2">
                                            {filters?.sort ? (
                                                <div className="flex h-6 items-center justify-center gap-2 rounded-none bg-black px-2 text-sm text-white">
                                                    <p>{value.label}</p>
                                                    <CloseCircleOutlined className="text-base" />
                                                </div>
                                            ) : (
                                                <p className="text-pulppo-status-dark-disabled">Relevancia</p>
                                            )}
                                            {filters?.sort ? (
                                                <SearchOutlined className="text-xl text-gray-300" />
                                            ) : (
                                                <RightOutlined className="text-xl text-gray-300" />
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                        )}
                        {!hide.search && (
                            <>
                                <p className="font-medium">Búsqueda general</p>
                                <Input
                                    placeholder="busca por calle, id o titulo"
                                    className="w-full rounded-none text-lg"
                                    value={filters?.search}
                                    onChange={(ev) =>
                                        setFilters((filters) => ({
                                            ...filters,
                                            search: ev.target.value
                                        }))
                                    }
                                    allowClear
                                />
                            </>
                        )}
                        {!hide.suites && (
                            <div className="w-full">
                                <p className="mb-2 font-medium">{t('suites', 'Recámaras')}</p>
                                <Selector
                                    max={5}
                                    min={1}
                                    value={filters?.suites || []}
                                    onChange={(value) =>
                                        setFilters((filters) => ({
                                            ...filters,
                                            suites: value
                                        }))
                                    }
                                />
                            </div>
                        )}
                        {!hide.spaces && showSpaces && (
                            <div className="w-full">
                                <p className="mb-2 font-medium">{t('spaces', 'Ambientes')}</p>
                                <Selector
                                    max={5}
                                    min={1}
                                    value={filters?.spaces || []}
                                    onChange={(value) =>
                                        setFilters((filters) => ({
                                            ...filters,
                                            spaces: value
                                        }))
                                    }
                                />
                            </div>
                        )}
                        {!hide.bathrooms && (
                            <div className="w-full">
                                <p className="mb-2 font-medium">{t('bathrooms', 'Baños')}</p>

                                <Selector
                                    max={5}
                                    min={1}
                                    value={filters?.bathrooms || []}
                                    onChange={(value) =>
                                        setFilters((filters) => ({
                                            ...filters,
                                            bathrooms: value
                                        }))
                                    }
                                />
                            </div>
                        )}
                        {!hide.parkings && (
                            <div className="w-full">
                                <p className="mb-2 font-medium">{t('parkings', 'Estacionamientos')}</p>

                                <Selector
                                    max={5}
                                    min={1}
                                    value={filters?.parkings || []}
                                    onChange={(value) =>
                                        setFilters((filters) => ({
                                            ...filters,
                                            parkings: value
                                        }))
                                    }
                                />
                            </div>
                        )}
                        {!hide.roofedSurface && (
                            <div>
                                <p className="mb-2 font-medium">Metros construidos</p>
                                <div className="flex w-full justify-between">
                                    <div className="flex">
                                        <InputNumber
                                            controls={false}
                                            className="flex h-10 w-full items-center rounded-none border-gray-300"
                                            inputMode="numeric"
                                            placeholder="min"
                                            value={filters?.roofedSurface?.min}
                                            formatter={(value) => {
                                                const aux = parseInt(value);
                                                if (!aux) return '';
                                                return `${aux.toLocaleString(process.env.NEXT_PUBLIC_LANGUAGE_CODE || 'es-MX')}`;
                                            }}
                                            min={0}
                                            max={filters?.roofedSurface?.max || Infinity}
                                            onChange={(value) =>
                                                setFilters((filters) => ({
                                                    ...filters,
                                                    roofedSurface: {
                                                        min: value,
                                                        max: filters?.roofedSurface?.max || null
                                                    }
                                                }))
                                            }
                                        />
                                        <div className="flex h-10 items-center justify-center rounded-none bg-gray-300 px-2">
                                            m2
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <InputNumber
                                            controls={false}
                                            className="flex h-10 w-full items-center rounded-none border-gray-300"
                                            inputMode="numeric"
                                            formatter={(value) => {
                                                const aux = parseInt(value);
                                                if (!aux) return '';
                                                return `${aux.toLocaleString(process.env.NEXT_PUBLIC_LANGUAGE_CODE || 'es-MX')}`;
                                            }}
                                            placeholder="max"
                                            min={filters?.roofedSurface?.min || 0}
                                            value={filters?.roofedSurface?.max}
                                            onChange={(value) =>
                                                setFilters((filters) => ({
                                                    ...filters,
                                                    roofedSurface: {
                                                        max: value,
                                                        min: filters?.roofedSurface?.min || null
                                                    }
                                                }))
                                            }
                                        />
                                        <div className="flex h-10 items-center justify-center rounded-none bg-gray-300 px-2">
                                            m2
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {!hide.totalSurface && (
                            <div>
                                <p className="mb-2 font-medium">Metros de terreno</p>
                                <div className="flex w-full justify-between">
                                    <div className="flex">
                                        <InputNumber
                                            controls={false}
                                            className="flex h-10 w-full items-center rounded-none border-gray-300"
                                            inputMode="numeric"
                                            placeholder="min"
                                            formatter={(value) => {
                                                const aux = parseInt(value);
                                                if (!aux) return '';
                                                return `${aux.toLocaleString(process.env.NEXT_PUBLIC_LANGUAGE_CODE || 'es-MX')}`;
                                            }}
                                            min={0}
                                            max={filters?.totalSurface?.max || Infinity}
                                            value={filters?.totalSurface?.min}
                                            onChange={(value) =>
                                                setFilters((filters) => ({
                                                    ...filters,
                                                    totalSurface: {
                                                        min: value,
                                                        max: filters?.totalSurface?.max || null
                                                    }
                                                }))
                                            }
                                        ></InputNumber>
                                        <div className="flex h-10 items-center justify-center rounded-none bg-gray-300 px-2">
                                            m2
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <InputNumber
                                            controls={false}
                                            className="flex h-10 w-full items-center rounded-none border-gray-300"
                                            inputMode="numeric"
                                            placeholder="max"
                                            formatter={(value) => {
                                                const aux = parseInt(value);
                                                if (!aux) return '';
                                                return `${aux.toLocaleString(process.env.NEXT_PUBLIC_LANGUAGE_CODE || 'es-MX')}`;
                                            }}
                                            min={filters?.totalSurface?.min || 0}
                                            value={filters?.totalSurface?.max}
                                            onChange={(value) =>
                                                setFilters((filters) => ({
                                                    ...filters,
                                                    totalSurface: {
                                                        min: filters?.totalSurface?.min || null,
                                                        max: value
                                                    }
                                                }))
                                            }
                                        />
                                        <div className="flex h-10 items-center justify-center rounded-none bg-gray-300 px-2">
                                            m2
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {!hide.yearBuild && (
                            <div>
                                <p className="mb-2 font-medium">Antigüedad</p>
                                <PulppoRadio
                                    options={[
                                        {
                                            label: 'Todas',
                                            value: null
                                        },
                                        {
                                            label: 'En construcción',
                                            value: '-1'
                                        },
                                        {
                                            label: 'A estrenar',
                                            value: '0'
                                        },
                                        {
                                            label: 'Hasta 5 años',
                                            value: '5'
                                        },
                                        {
                                            label: 'Hasta 10 años',
                                            value: '10'
                                        },
                                        {
                                            label: 'Hasta 20 años',
                                            value: '20'
                                        },
                                        {
                                            label: 'Hasta 30 años',
                                            value: '30'
                                        },
                                        {
                                            label: 'Hasta 40 años',
                                            value: '40'
                                        },
                                        {
                                            label: 'Más 50 años',
                                            value: '50'
                                        }
                                    ]}
                                    onApply={(value) => {
                                        setFilters((filters) => ({
                                            ...filters,
                                            age: value
                                        }));
                                    }}
                                    onClear={() => {
                                        setFilters((filters) => ({
                                            ...filters,
                                            age: null
                                        }));
                                    }}
                                    value={`${filters?.age}`}
                                    defaultOptionValue=""
                                    render={(active) => (
                                        <div className="flex h-10 cursor-pointer items-center justify-between rounded-none border border-solid border-gray-300 px-2">
                                            {active ? (
                                                <div className="flex h-6 items-center justify-center gap-2 rounded-none bg-black px-2 text-sm text-white">
                                                    <p>{active.label}</p>
                                                    <CloseCircleOutlined className="text-base" />
                                                </div>
                                            ) : (
                                                <p className="text-pulppo-status-dark-disabled">Todas</p>
                                            )}
                                            {active ? (
                                                <SearchOutlined className="text-xl text-gray-300" />
                                            ) : (
                                                <RightOutlined className="text-xl text-gray-300" />
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                        )}
                        {!hide.services && (
                            <div>
                                <p className="font-medium">{`${t('amenities', 'Amenidades')}, Servicios y Características`}</p>
                                <p className="mb-2 text-sm text-pulppo-status-dark-disabled">
                                    Este criterio no se utilizará para filtrar propiedades en la base MLS
                                </p>
                                <PulppoMultiselect
                                    showButtons={true}
                                    allowAll={false}
                                    applyOnChange={true}
                                    options={Services.map((service) => ({
                                        key: service.name,
                                        label: t(service.name),
                                        value: service.name
                                    }))}
                                    title={`${t('amenities', 'Amenidades')}, Servicios y Características`}
                                    placeholder="Seleccione las opciones deseadas"
                                    onApply={(values) => {
                                        setFilters((filters) => ({
                                            ...filters,
                                            amenities: values
                                        }));
                                    }}
                                    onClear={() => {
                                        setFilters((filters) => ({
                                            ...filters,
                                            amenities: []
                                        }));
                                    }}
                                    onChange={(values) => {
                                        setFilters((filters) => ({
                                            ...filters,
                                            amenities: values
                                        }));
                                    }}
                                    value={filters?.amenities}
                                />
                            </div>
                        )}
                        {!hide.internal && (
                            <div>
                                <p className="mb-2 font-medium">Filtros internos</p>
                                <div className="grid grid-cols-3 justify-items-stretch gap-2 md:grid-cols-4">
                                    <AgentSelect
                                        user={user}
                                        multi={true}
                                        className="w-full md:w-full [&>div>button]:w-full"
                                        value={filters?.sellers}
                                        onChange={(val) => {
                                            setFilters((filters) => ({
                                                ...filters,
                                                sellers: val
                                            }));
                                        }}
                                        renderButton={() => (
                                            <Button
                                                type="default"
                                                className={`h-10 rounded-none px-4 font-medium ${
                                                    filters?.sellers?.length
                                                        ? 'text-black'
                                                        : 'text-pulppo-status-dark-disabled'
                                                }`}
                                            >
                                                Vendedor{' '}
                                                {`${filters?.sellers?.length ? `(${filters?.sellers?.length})` : ''}`}
                                            </Button>
                                        )}
                                    />
                                    <AgentSelect
                                        className="w-full md:w-full [&>div>button]:w-full"
                                        user={user}
                                        multi={true}
                                        value={filters?.producers}
                                        onChange={(val) => {
                                            setFilters((filters) => ({
                                                ...filters,
                                                producers: val
                                            }));
                                        }}
                                        renderButton={() => (
                                            <Button
                                                type="default"
                                                className={`h-10 rounded-none px-4 font-medium ${
                                                    filters?.producers?.length
                                                        ? 'text-black'
                                                        : 'text-pulppo-status-dark-disabled'
                                                }`}
                                            >
                                                Productor
                                                {`${
                                                    filters?.producers?.length ? `(${filters?.producers?.length})` : ''
                                                }`}
                                            </Button>
                                        )}
                                    />
                                    <CompanySelect
                                        value={filters?.company}
                                        allowAdd={false}
                                        multi={true}
                                        onApply={(value) => {
                                            setFilters((filters) => ({
                                                ...filters,
                                                company: value
                                            }));
                                        }}
                                        onClear={() => {
                                            setFilters((filters) => ({
                                                ...filters,
                                                company: null
                                            }));
                                        }}
                                        render={(value) => (
                                            <Button
                                                type="default"
                                                className={`h-10 w-full rounded-none px-4 font-medium ${
                                                    value?.length ? 'text-black' : 'text-pulppo-status-dark-disabled'
                                                }`}
                                            >
                                                Inmobiliaria {value?.length ? `(${value?.length})` : ''}
                                            </Button>
                                        )}
                                    />
                                    <PulppoMultiselect
                                        options={['high', 'medium', 'low', 'notPublished'].map((qty) => ({
                                            key: qty,
                                            label: qualityMapper[qty],
                                            value: qty
                                        }))}
                                        className={{
                                            container: 'w-full [&>button]:w-full'
                                        }}
                                        renderButton={() => (
                                            <Button
                                                type="default"
                                                className={`h-10 rounded-none px-4 font-medium ${
                                                    filters?.quality?.length
                                                        ? 'text-black'
                                                        : 'text-pulppo-status-dark-disabled'
                                                }`}
                                            >
                                                Calidad
                                                {`${filters?.quality?.length ? `(${filters?.quality?.length})` : ''}`}
                                            </Button>
                                        )}
                                        allowSearch={false}
                                        showButtons={true}
                                        title="Calidad"
                                        placeholder="Seleccione las opciones deseadas"
                                        onApply={(values) => {
                                            setFilters((filters) => ({
                                                ...filters,
                                                quality: values
                                            }));
                                        }}
                                        onClear={() => {
                                            setFilters((filters) => ({
                                                ...filters,
                                                quality: []
                                            }));
                                        }}
                                        onChange={(values) => {
                                            setFilters((filters) => ({
                                                ...filters,
                                                quality: values
                                            }));
                                        }}
                                        value={filters?.quality || []}
                                    />
                                    <PulppoMultiselect
                                        className={{
                                            container: 'w-full [&>button]:w-full'
                                        }}
                                        options={['low', 'medium', 'high', 'not-valuated'].map((qty) => ({
                                            key: qty,
                                            label: valuationMapper[qty],
                                            value: qty,
                                            disabled:
                                                filters?.valuation?.includes('not-valuated') && qty !== 'not-valuated'
                                        }))}
                                        allowAll={false}
                                        allowSearch={false}
                                        renderButton={() => (
                                            <Button
                                                type="default"
                                                className={`h-10 rounded-none px-4 font-medium ${
                                                    filters?.valuation?.length
                                                        ? 'text-black'
                                                        : 'text-pulppo-status-dark-disabled'
                                                }`}
                                            >
                                                Valuación
                                                {`${
                                                    filters?.valuation?.length ? `(${filters?.valuation?.length})` : ''
                                                }`}
                                            </Button>
                                        )}
                                        showButtons={true}
                                        title="Valuación"
                                        onClear={() => {
                                            setFilters((filters) => ({
                                                ...filters,
                                                valuation: []
                                            }));
                                        }}
                                        onApply={(values) => {
                                            setFilters((filters) => ({
                                                ...filters,
                                                valuation: values?.includes('not-valuated') ? ['not-valuated'] : values
                                            }));
                                        }}
                                        placeholder="Seleccione las opciones deseadas"
                                        onChange={(values) => {
                                            setFilters((filters) => ({
                                                ...filters,
                                                valuation: values?.includes('not-valuated') ? ['not-valuated'] : values
                                            }));
                                        }}
                                        value={filters?.valuation || []}
                                    />
                                    <PulppoMultiselect
                                        className={{
                                            container: 'w-full [&>button]:w-full'
                                        }}
                                        options={['exclusive', 'not-exclusive'].map((qty) => ({
                                            key: qty,
                                            label: exclusiveMapper[qty],
                                            value: qty
                                        }))}
                                        allowAll={false}
                                        allowSearch={false}
                                        renderButton={() => (
                                            <Button
                                                type="default"
                                                className={`h-10 rounded-none px-4 font-medium ${
                                                    filters?.exclusive?.length
                                                        ? 'text-black'
                                                        : 'text-pulppo-status-dark-disabled'
                                                }`}
                                            >
                                                Exclusivas
                                                {`${
                                                    filters?.exclusive?.length ? `(${filters?.exclusive?.length})` : ''
                                                }`}
                                            </Button>
                                        )}
                                        showButtons={true}
                                        title="Exclusivas"
                                        onClear={() => {
                                            setFilters((filters) => ({
                                                ...filters,
                                                exclusive: []
                                            }));
                                        }}
                                        onApply={(values) => {
                                            setFilters((filters) => ({
                                                ...filters,
                                                exclusive: values
                                            }));
                                        }}
                                        placeholder="Seleccione las opciones deseadas"
                                        onChange={(values) => {
                                            setFilters((filters) => ({
                                                ...filters,
                                                exclusive: values
                                            }));
                                        }}
                                        value={filters?.exclusive || []}
                                    />
                                    <PulppoMultiselect
                                        className={{
                                            container: 'w-full [&>button]:w-full'
                                        }}
                                        options={['published', 'not-published'].map((qty) => ({
                                            key: qty,
                                            label: publishMapper[qty],
                                            value: qty,
                                            disabled:
                                                filters?.valuation?.includes('not-valuated') && qty !== 'not-valuated'
                                        }))}
                                        allowAll={true}
                                        allowSearch={false}
                                        renderButton={() => (
                                            <Button
                                                type="default"
                                                className={`h-10 rounded-none px-4 font-medium ${
                                                    filters?.published?.length
                                                        ? 'text-black'
                                                        : 'text-pulppo-status-dark-disabled'
                                                }`}
                                            >
                                                Difusión
                                                {`${
                                                    filters?.published?.length ? `(${filters?.published?.length})` : ''
                                                }`}
                                            </Button>
                                        )}
                                        showButtons={true}
                                        title="Difusión"
                                        onClear={() => {
                                            setFilters((filters) => ({
                                                ...filters,
                                                published: []
                                            }));
                                        }}
                                        onApply={(values) => {
                                            setFilters((filters) => ({
                                                ...filters,
                                                published: values
                                            }));
                                        }}
                                        placeholder="Seleccione las opciones deseadas"
                                        onChange={(values) => {
                                            setFilters((filters) => ({
                                                ...filters,
                                                published: values
                                            }));
                                        }}
                                        value={filters?.published || []}
                                    />
                                    <TagSelect
                                        value={filters?.types}
                                        onApply={(values) => {
                                            setFilters((filters) => ({
                                                ...filters,
                                                tags: values
                                            }));
                                        }}
                                        onClear={() => {
                                            setFilters((filters) => ({
                                                ...filters,
                                                tags: []
                                            }));
                                        }}
                                        renderButton={(value) => (
                                            <Button
                                                type="default"
                                                className={`h-10 rounded-none px-4 font-medium ${
                                                    filters?.exclusive?.length
                                                        ? 'text-black'
                                                        : 'text-pulppo-status-dark-disabled'
                                                }`}
                                            >
                                                Etiquetas
                                                {`${
                                                    filters?.exclusive?.length ? `(${filters?.exclusive?.length})` : ''
                                                }`}
                                            </Button>
                                        )}
                                    />
                                </div>
                                {/* <Button
                                    type="default"
                                    className={`mt-2 h-10 rounded-none px-4 font-medium ${
                                        filters?.exclusive?.length ? 'text-black' : 'text-pulppo-status-dark-disabled'
                                    }`}
                                    onClick={() => {
                                        showBoards({
                                            onSelect: (board) => {
                                                window.open(`/board/${board._id}`, '_blank');
                                            }
                                        });
                                    }}
                                >
                                    Mis tableros
                                </Button> */}
                            </div>
                        )}
                        {!hide.radius && (
                            <>
                                <p className="font-medium">Radio de búsqueda</p>
                                <Row className="w-full" justify="space-between">
                                    <Col span={11}>
                                        <InputNumber
                                            controls={false}
                                            addonAfter="m2"
                                            className="w-full"
                                            inputMode="numeric"
                                            placeholder="radio"
                                            min={50}
                                            value={filters?.radio}
                                            onChange={(value) =>
                                                setFilters((filters) => ({
                                                    ...filters,
                                                    radio: value
                                                }))
                                            }
                                        ></InputNumber>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </div>
                    <div className="flex w-full items-center gap-5 pt-4">
                        <Button
                            className="h-auto w-full flex-1 rounded-none py-2 font-medium lg:w-auto lg:px-4"
                            type="default"
                            onClick={() => {
                                setVisible(false);
                                clearFilters();
                            }}
                        >
                            Borrar filtros
                        </Button>
                        <Button
                            className="h-auto w-full flex-1 rounded-none py-2 font-medium lg:w-36"
                            type="primary"
                            onClick={() => {
                                setVisible(false);

                                updateFilters(filters);
                            }}
                        >
                            Filtrar
                        </Button>
                    </div>
                </div>
            </PulppoDrawer>
        </>
    );
};

const Selector = ({
    min,
    max,
    value,
    onChange
}: {
    min: number;
    max: number;
    onChange: (value: number[]) => void;
    value: number[];
}) => {
    return (
        <div className="flex w-full">
            <SelectorElement onClick={() => onChange([])} active={!value?.length} text={'Todas'} type={'start'} />
            {new Array(max + 1 - min).fill(1).map((_, index) => (
                <SelectorElement
                    onClick={() => {
                        if (value.length === 0) onChange([index + 1]);
                        else if (value.length === 1) {
                            if (index + 1 < value[0]) onChange([index + 1]);
                            else onChange([value[0], index + 1]);
                        } else {
                            if (index + 1 < value[0]) onChange([index + 1, value[1]]);
                            else if (index + 1 === value[0]) onChange([index + 1, index + 1]);
                            else if (index + 1 > value[1]) onChange([value[0], index + 1]);
                            else onChange([index + 1, value[1]]);
                        }
                    }}
                    key={`selector_${index + 1}`}
                    active={
                        (value?.length === 1 && index + 1 >= value[0]) ||
                        (value?.length === 2 && value[0] <= index + 1 && value[1] >= index + 1)
                    }
                    text={`${index + 1}${index + 1 === max ? '+' : ''}`}
                    type={index + 1 === max ? 'end' : 'middle'}
                />
            ))}
        </div>
    );
};

const SelectorElement = ({
    active,
    text,
    type,
    onClick
}: {
    active: boolean;
    text: string;
    type: 'start' | 'end' | 'middle';
    onClick: () => void;
}) => {
    return (
        <div
            className={twMerge(
                'flex h-10 flex-1 cursor-pointer items-center justify-center border-y border-solid',
                type === 'start' ? 'border-x' : type === 'end' && 'border-r',
                active
                    ? 'border-black bg-black text-white'
                    : 'border-gray-300 bg-white text-pulppo-status-dark-disabled'
            )}
            onClick={onClick}
        >
            {text}
        </div>
    );
};
