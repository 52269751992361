import { CheckOutlined, CloseCircleOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, List } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import { PulppoDrawer } from './PulppoDrawer';
import { twMerge } from 'tailwind-merge';

interface Props {
    onChange?: (value: Array<string>) => void;
    className?: {
        container?: string;
    };
    value?: Array<string>;
    placeholder?: string;
    options: Array<{
        key: string;
        value: any;
        label: string;
        disabled?: boolean;
        description?: string;
        icon?: Element | ReactNode;
    }>;
    title?: string;
    onButtonClick?: Function;
    showButtons?: boolean;
    allowSearch?: boolean;
    allowAll?: boolean;
    disabled?: boolean;
    applyOnChange?: boolean;
    onApply?: (value: Array<string>) => void;
    onClear?: () => void;
    primaryButtonLabel?: string;
    secondaryButtonLabel?: string;
    renderButton?: (item: {
        key: string;
        value: any;
        label: string;
        icon?: ReactNode | Element;
    }) => ReactNode | Element;
}

export const PulppoMultiselect = ({
    value,
    placeholder = 'Seleccione una opción',
    options,
    allowAll = true,
    title = 'Seleccione varias opciónes',
    onButtonClick = () => {},
    allowSearch = true,
    disabled = false,
    renderButton,
    showButtons = false,
    applyOnChange = true,
    onChange = (_: Array<string>) => {},
    onApply = (_) => {},
    onClear = () => {},
    primaryButtonLabel = 'Filtrar',
    secondaryButtonLabel = 'Borrar filtros',
    className
}: Props) => {
    const [showDrawer, setShowDrawer] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selected, setSelected] = useState([]);

    const apply = (value: any[]) => {
        if (applyOnChange) {
            onChange(value);
        } else {
            setSelected(value);
        }
    };

    useEffect(() => {
        setSelected(value || []);
    }, [value]);

    return (
        <>
            <div
                className={twMerge(
                    'cursor-pointer',
                    disabled ? 'cursor-not-allowed opacity-50' : '',
                    className?.container
                )}
                onClick={() => {
                    if (disabled) return;
                    setShowDrawer(true);
                    onButtonClick();
                }}
            >
                {renderButton ? (
                    (renderButton(options.find((opt) => opt.value === value)) as ReactNode)
                ) : (
                    <div className="flex items-center justify-between border border-solid border-gray-300 bg-white px-2 py-2">
                        <div className="align-center flex items-center">
                            {!value?.length ? (
                                <p
                                    className={`ml-2 truncate ${
                                        options?.find((opt) => opt.value === value) && !disabled
                                            ? 'text-black'
                                            : 'text-pulppo-status-dark-disabled'
                                    }`}
                                >
                                    {options.find((opt) => opt.value === value)?.label || placeholder}
                                </p>
                            ) : (
                                <div className="flex flex-wrap items-center gap-2">
                                    {value?.slice(0, value?.length === 3 ? 3 : 2)?.map?.((val) =>
                                        options.findIndex((opt) => opt.value === val) !== -1 ? (
                                            <div
                                                key={val}
                                                className="flex h-6 items-center justify-center gap-2 bg-black px-2 text-sm text-white"
                                            >
                                                <p>{options.find((opt) => opt.value === val)?.label}</p>
                                                <CloseCircleOutlined className="text-base" />
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    )}
                                    {value?.length > 3 && (
                                        <div className="flex h-6 items-center justify-center gap-2 bg-black px-2 text-sm text-white">
                                            <p>{`+${value?.length - 2} filtros`}</p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        {value?.length ? (
                            <SearchOutlined className="text-xl text-gray-300" />
                        ) : (
                            <RightOutlined className="text-xl text-gray-300" />
                        )}
                    </div>
                )}
            </div>
            <PulppoDrawer
                bodyStyle={{ paddingTop: '0px', paddingBottom: '0px' }}
                title={title}
                visible={showDrawer}
                onClose={() => {
                    setShowDrawer(false);
                }}
            >
                <div className="flex h-full w-full flex-col">
                    {allowSearch && (
                        <div className="flex-0 shrink-0 bg-white pt-2">
                            <div className="flex w-full flex-wrap gap-2">
                                {selected
                                    ?.filter?.((_, i) => i < 5)
                                    .map((val) => (
                                        <div
                                            key={val}
                                            className="flex h-7 cursor-pointer items-center justify-center gap-2 border border-solid border-gray-300 px-2 text-sm text-pulppo-status-dark-disabled"
                                            onClick={() => {
                                                const aux = [...selected];
                                                const index = aux.indexOf(val);

                                                aux.splice(index, 1);
                                                apply(aux);
                                            }}
                                        >
                                            <p>{options.find((opt) => opt.value === val)?.label}</p>
                                            <CloseCircleOutlined className="text-base" />
                                        </div>
                                    ))}
                                {selected?.length > 5 && (
                                    <div className="flex h-7 cursor-pointer items-center justify-center gap-2 border border-solid border-gray-300 px-2 text-sm text-pulppo-status-dark-disabled">
                                        <p>+{selected?.length - 5} filtros</p>
                                    </div>
                                )}
                            </div>
                            <Input
                                suffix={<SearchOutlined className="text-gray-300" />}
                                placeholder="Buscar"
                                className="my-4 h-11"
                                value={searchTerm}
                                allowClear
                                onChange={(e) => setSearchTerm(e.currentTarget.value)}
                            />
                        </div>
                    )}
                    <div className="flex-1 overflow-y-auto overflow-x-hidden">
                        {allowAll && (
                            <List.Item
                                className="cursor-pointer hover:underline"
                                onClick={() => {
                                    if (options.every((opt) => selected?.includes(opt.value))) {
                                        return apply([]);
                                    }
                                    apply(options.map((opt) => opt.value));
                                }}
                            >
                                <div className="flex items-center gap-2">
                                    <div
                                        className={`flex h-5 w-5 items-center justify-center rounded-none border border-solid bg-white ${
                                            options.every((opt) => selected?.includes(opt.value))
                                                ? 'border-black'
                                                : 'border-gray-200'
                                        }`}
                                    >
                                        {options.every((opt) => selected?.includes(opt.value)) && (
                                            <CheckOutlined className="p-1 text-xs font-bold text-black" />
                                        )}
                                    </div>
                                    <p>Todos</p>
                                </div>
                            </List.Item>
                        )}
                        <List
                            dataSource={
                                allowSearch
                                    ? options.filter((option) =>
                                          option.label
                                              ?.normalize('NFD')
                                              .replace(/[\u0300-\u036f]/g, '')
                                              ?.toLowerCase()
                                              .includes(searchTerm?.toLowerCase())
                                      )
                                    : options
                            }
                            renderItem={(item) => (
                                <List.Item
                                    className={
                                        item.disabled
                                            ? 'cursor-not-allowed text-pulppo-status-dark-disabled'
                                            : 'cursor-pointer hover:underline'
                                    }
                                    key={item.key}
                                    onClick={() => {
                                        if (item.disabled) return;
                                        setSearchTerm('');
                                        const aux = [...selected];
                                        const index = aux.indexOf(item.value);
                                        if (index === -1) apply([...selected, item.value]);
                                        else {
                                            aux.splice(index, 1);
                                            apply(aux);
                                        }
                                    }}
                                >
                                    <div className="flex items-center gap-2">
                                        <div
                                            className={`flex h-5 w-5 items-center justify-center rounded-none border border-solid ${
                                                selected?.includes(item.value) ? 'border-black' : 'border-gray-200'
                                            } ${item.disabled ? 'bg-gray-200' : 'bg-white'}`}
                                        >
                                            {selected?.includes(item.value) && (
                                                <CheckOutlined className="p-1 text-xs font-bold text-black" />
                                            )}
                                        </div>
                                        {item.icon && (item.icon as ReactNode)}
                                        <p>{item.label}</p>
                                        {item?.description && <p className="text-sm">{item.description}</p>}
                                    </div>
                                </List.Item>
                            )}
                        />
                    </div>

                    {showButtons && (
                        <div className="flex shrink-0 gap-2 bg-white pb-4 pt-2">
                            <Button
                                onClick={() => {
                                    onClear();
                                    setShowDrawer(false);
                                }}
                                className="h-10 flex-1 rounded-none font-medium"
                            >
                                {secondaryButtonLabel}
                            </Button>
                            <Button
                                onClick={() => {
                                    onApply(selected);
                                    setShowDrawer(false);
                                }}
                                type="primary"
                                className="h-10 flex-1 rounded-none font-medium"
                            >
                                {primaryButtonLabel}
                            </Button>
                        </div>
                    )}
                </div>
            </PulppoDrawer>
        </>
    );
};
