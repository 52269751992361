import { useContext, useState } from 'react';
import { Collapse, Form, Input, Typography } from 'antd';
import { ClearOutlined, EditOutlined } from '@ant-design/icons';
import { capitalize } from '../../helpers/string-helper';
import axios from '../../utils/axios';
import { api_url } from '../../helpers/fetcher';
import { formEventEmitter } from '../../utils/FormEvents';
import { PropertyFormContext } from '../../contexts/PropertyFormContext';
import { useTranslate } from '@tolgee/react';
import { IProperty } from '@pulppo/pulppo-models';
import Button from '../Elements/Button';

interface Props {
    property?: Partial<IProperty>;
    isDisabled?: boolean;
}

const { Panel } = Collapse;

function capitalizeAllUppercaseWords(str) {
    return str.replace(/\b([A-Z]+)\b/g, function (match) {
        return match.charAt(0).toUpperCase() + match.slice(1).toLowerCase();
    });
}

const DescriptionInput = ({
    value,
    onChange,
    setSuggestionsDescription,
    placeholder,
    disabled
}: {
    value?: string;
    onChange?: (value: string) => void;
    setSuggestionsDescription?: any;
    placeholder?: string;
    disabled?: boolean;
}) => {
    return (
        <Input.TextArea
            placeholder={placeholder}
            showCount={{ formatter: ({ count }) => `${count} / 400` }}
            className="rounded-none [&>textarea]:rounded-none"
            rows={6}
            value={value}
            disabled={disabled}
            onChange={(ev) => {
                const start = ev.target.selectionStart;
                onChange?.(capitalizeAllUppercaseWords(ev.target.value));
                setTimeout(() => {
                    ev.target.setSelectionRange(start, start);
                }, 0);
                setSuggestionsDescription?.((prevState) => ({
                    ...prevState,
                    description: ''
                }));
            }}
        />
    );
};

const validateDescription = (value, companyName) => {
    const foundEB = value.match(/(\W|^)EB\-\w{6,8}(\W|$)/gi);
    if (foundEB?.[0]) {
        return Promise.reject('La descripción no puede tener un código de easybroker');
    }
    const foundEmail = value.match(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/gi);
    if (foundEmail?.[0]) {
        return Promise.reject('La descripción no puede tener emails');
    }
    const foundUrl = value.match(/(http|https)?:\/\/[^\s$.?#].[^\s]*|www\.[^\s$.?#].[^\s]*/gi);
    if (foundUrl?.[0]) {
        return Promise.reject('La descripción no puede tener ligas');
    }
    if (value?.toLocaleLowerCase().includes(companyName?.toLocaleLowerCase())) {
        return Promise.reject('La descripción no puede incluir el nombre de la inmobiliaria');
    }
    const foundPhone = value.match(/(\+?\d{1,3}\s?)?\(?\d{1,4}\)?[\s.-]?\d{4}[\s.-]?\d{4}/gi);
    if (foundPhone?.[0]) {
        return Promise.reject('No se aceptan números de teléfono en la descripción');
    }
    return Promise.resolve();
};

const PropertyListingDetails = ({ property, isDisabled }: Props) => {
    const { t } = useTranslate('common');
    const form = Form.useFormInstance();
    const propertyForm = useContext(PropertyFormContext);
    const [showEnglish, setShowEnglish] = useState(false);
    const [suggestionsDescription, setSuggestionsDescription] = useState<{
        description: string;
        descriptionEnglish: string;
    }>({
        description: '',
        descriptionEnglish: ''
    });
    const [suggestionLoad, setSuggestionLoad] = useState(false);

    const { formData } = property ? { formData: property } : propertyForm || {};

    const price = formData?.listing?.price?.price;
    const operation = formData?.listing?.operation;
    const type = formData?.type;
    const generationDisabled = !price || !operation || !type || suggestionLoad;

    const generateTitleAndDescription = async () => {
        const cleanProperty = () => {
            form.setFieldsValue({
                listing: {
                    description: '',
                    title: '',
                    translation: {
                        en: {
                            title: '',
                            description: ''
                        }
                    }
                }
            });
        };
        cleanProperty();
        const operation = form.getFieldValue(['listing', 'operation']);
        const address = form.getFieldValue(['address']);
        const type = form.getFieldValue('type');
        const _id = form.getFieldValue('_id');
        const price = form.getFieldValue(['listing', 'price', 'price']);
        const currency = form.getFieldValue(['listing', 'price', 'currency']);
        const suites = form.getFieldValue(['attributes', 'suites']);
        const title = `${capitalize(t(type))} en ${t(
            operation
        )?.toLowerCase()} en ${address?.neighborhood?.name} ${suites} ${t('suites', 'recámaras')}`;
        form.setFieldsValue({
            listing: {
                title
            }
        });
        setSuggestionLoad(true);
        try {
            const result = await axios.get<{
                description: string;
                descriptionEnglish: string;
                titleEnglish: string;
            }>(
                `${api_url}/property/${_id}/suggestionDescription?price=${price}&operation=${operation}&currency=${currency}&translateTitle=${title}`
            );
            if (result.data) {
                setSuggestionsDescription(result.data);
                formEventEmitter.emit('changes', {
                    listing: {
                        ...formData.listing,
                        title,
                        description: result.data.description,
                        translation: {
                            en: {
                                title: result.data.titleEnglish,
                                description: result.data.descriptionEnglish
                            }
                        }
                    }
                });
                form.setFieldsValue({
                    listing: {
                        description: result.data.description,
                        translation: {
                            en: {
                                title: result.data.titleEnglish,
                                description: result.data.descriptionEnglish
                            }
                        }
                    }
                });
            }
        } catch (e) {
            console.error(e);
        }
        setSuggestionLoad(false);
    };

    return (
        <div className="@container">
            <div className="flex flex-col gap-y-4 @lg:flex-col-reverse">
                <div>
                    <Form.Item
                        className="form-item-one-column"
                        name={['listing', 'title']}
                        label="Título de la operación"
                        rules={[
                            {
                                required: true,
                                message: 'Ingrese un titulo para la operación'
                            }
                        ]}
                    >
                        <Input
                            className="rounded-none py-2"
                            placeholder="Ingrese un título"
                            suffix={<EditOutlined className="text-lg" />}
                            disabled={isDisabled}
                        />
                    </Form.Item>
                    <div className="relative mt-2">
                        <Form.Item
                            className="form-item-one-column [&>div>div>div>.ant-form-item-explain]:pr-16"
                            name={['listing', 'description']}
                            label="Descripción"
                            extra={
                                suggestionsDescription.description ? 'Descripcion generada por Pulppo IA' : undefined
                            }
                            rules={[
                                {
                                    required: true,
                                    message: 'Ingrese una descripción para la operación'
                                },
                                {
                                    min: 400,
                                    max: 5000,
                                    message: 'La descripción debe tener al menos 400 caracteres y un máximo de 5000'
                                },
                                () => ({
                                    validator(_, value: string) {
                                        return validateDescription(value, formData?.company?.name);
                                    }
                                })
                            ]}
                        >
                            <DescriptionInput
                                placeholder="Escribe una descripción aquí..."
                                setSuggestionsDescription={setSuggestionsDescription}
                                disabled={isDisabled}
                            />
                        </Form.Item>
                        <div className="absolute right-0 top-0">
                            <Button
                                tooltip={{
                                    title: 'Limpiar descripción',
                                    placement: 'left'
                                }}
                                className="px-2 py-1"
                                type="secondary"
                                onClick={() => {
                                    form.setFieldsValue({
                                        listing: {
                                            description: ''
                                        }
                                    });
                                    setSuggestionsDescription((prevState) => ({
                                        ...prevState,
                                        description: ''
                                    }));
                                }}
                                disabled={isDisabled}
                            >
                                <ClearOutlined />
                            </Button>
                        </div>
                        {suggestionLoad ? (
                            <div className="absolute inset-0 z-10 flex flex-col items-center justify-center bg-black bg-opacity-75 px-2 text-center font-medium text-white">
                                <p>
                                    Generando descripción en español e inglés con Pulppo AI. Suele tardar
                                    aproximadamente 10 segundos.
                                </p>
                                <p className="mb-2">
                                    Puedes seguir completando la Información interna y el contrato de servicio.
                                </p>
                                <img className="w-12 object-contain" src="/loaders/pulppo.gif" alt="pulppo gif" />
                            </div>
                        ) : null}
                    </div>
                    <Collapse className="mt-8" onChange={() => setShowEnglish(!showEnglish)} expandIconPosition="end">
                        <Panel
                            className="[&>.ant-collapse-header]:py-2"
                            header={
                                <div className="flex flex-col gap-y-4 md:flex-row md:justify-between">
                                    <Typography>
                                        {showEnglish ? 'Esconder' : 'Ver'} título y descripción en inglés
                                    </Typography>
                                </div>
                            }
                            key="panel-description-title-english"
                        >
                            <div className="mt-2">
                                <Form.Item
                                    className="form-item-one-column"
                                    name={['listing', 'translation', 'en', 'title']}
                                    label="Título en inglés"
                                >
                                    <Input className="rounded-none" disabled={isDisabled} />
                                </Form.Item>
                                <Form.Item
                                    className="form-item-one-column"
                                    name={['listing', 'translation', 'en', 'description']}
                                    label="Descripción en inglés"
                                >
                                    <Input.TextArea className="rounded-none" rows={3} disabled={isDisabled} />
                                </Form.Item>
                            </div>
                        </Panel>
                    </Collapse>
                </div>
                <div className="w-full">
                    <Button
                        type="primary"
                        className="flex w-full items-center justify-center disabled:bg-secondary-gray-disabled @lg:w-64"
                        onClick={generateTitleAndDescription}
                        icon={<img className="ml-2 w-4" src="/icons/magic-wand.png" alt="Varita magica" />}
                        disabled={generationDisabled || isDisabled}
                        isLoading={suggestionLoad}
                    >
                        Generar titulo y descripción
                    </Button>
                    <Typography className="mt-2 text-xs text-gray-600">
                        *Una vez ingresado el tipo de operacion y el precio, se habilitara el boton
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default PropertyListingDetails;
