import { create } from 'zustand';
import { IProperty } from '@pulppo/pulppo-models';
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { DEFAULT_COMMISSION_RECOMMENDED_BROKER } from '@pulppo/pulppo-models/build/utils/operation';
import { useShallow } from 'zustand/react/shallow';
import { DEFAULT_CURRENCY } from '../utils/constants';
import { PropertyCaptureStatus } from '@pulppo/pulppo-models/build/enums/PropertyCaptureStatus';
import { useDebounceValue } from './debounceValue';
import { formEventEmitter } from '../utils/FormEvents';
import { toComplex } from '../utils/toPlain';

type SetPropertyAction = ((property: Partial<IProperty>) => Partial<IProperty>) | Partial<IProperty>;

interface UseCaptureProperty {
    property?: Partial<IProperty>;
    stackedChanges: Partial<IProperty>;
    valuesIADetected?: Partial<IProperty>;
    isDisabled: boolean;
    setProperty: (action: SetPropertyAction, tagInvoke: string) => void;
    resetProperty: () => void;
    cleanStackedChanges: () => void;
    setValuesIADetected: (values: Partial<IProperty>) => void;
}

export const useCapturePropertyForm = ({ show }: { show: boolean }) => {
    const { property, setProperty } = useCaptureProperty(
        useShallow((state) => ({
            property: state.property,
            setProperty: state.setProperty
        }))
    );
    const [form] = useForm();
    const [currentPrice, setCurrentPrice] = useState<number | null>(null);
    const debouncedPrice = useDebounceValue(currentPrice, 1500);

    useEffect(() => {
        if (
            debouncedPrice !== null &&
            property?.capture?.status?.last === 'completed' &&
            debouncedPrice !== property?.listing?.price?.price
        ) {
            Modal.confirm({
                title: '¿Estás seguro?',
                content: `Al cambiar el precio de la propiedad, se volvera a estado documentacion y se dejara de publicar en portales.`,
                onOk: () => {
                    setProperty(
                        {
                            ...property,
                            listing: {
                                ...property.listing,
                                price: {
                                    ...property.listing?.price,
                                    price: debouncedPrice
                                }
                            }
                        },
                        'onFormChange-price-status-completed'
                    );
                },
                onCancel: () => {
                    form.setFieldsValue({
                        listing: {
                            price: {
                                price: property?.listing?.price?.price
                            }
                        }
                    });
                    setCurrentPrice(null);
                }
            });
        }
    }, [debouncedPrice]);

    useEffect(() => {
        if (property) {
            form.setFieldsValue(property);
        }
    }, [show]);

    useEffect(() => {
        formEventEmitter.on('changes', (changes) => {
            setProperty((formData) => toComplex({ ...formData, ...changes }), 'onFormChange-changes-event-emitter');
            for (const key in changes) {
                form.setFieldValue(key.split('.'), changes[key]);
            }
        });
    }, []);

    const onFormChange = (value, allValues) => {
        if (value?.listing?.price?.price && property?.capture?.status?.last === 'completed') {
            setCurrentPrice(value.listing.price.price);
        } else {
            setProperty((prevProperty) => {
                return {
                    ...allValues,
                    ...(allValues?.attributes
                        ? {
                              attributes: {
                                  ...prevProperty.attributes,
                                  ...allValues.attributes
                              }
                          }
                        : {}),
                    ...(allValues?.listing
                        ? {
                              listing: {
                                  ...prevProperty.listing,
                                  ...allValues.listing
                              }
                          }
                        : {})
                };
            }, 'onFormChange');
        }
    };

    return {
        property,
        form,
        onFormChange
    };
};

export const useCaptureProperty = create<UseCaptureProperty>((set) => ({
    isDisabled: false,
    stackedChanges: {},
    setValuesIADetected: (values) => set({ valuesIADetected: values }),
    cleanStackedChanges: () => {
        set({ stackedChanges: {} });
    },
    setProperty: (action, tagInvoke) => {
        set((state) => {
            let newPartialProperty: Partial<IProperty>;
            if (typeof action === 'function') {
                newPartialProperty = action(state.property);
            } else {
                newPartialProperty = action;
            }

            if (newPartialProperty?.listing?.operation) {
                newPartialProperty = {
                    ...newPartialProperty,
                    contract: {
                        ...newPartialProperty.contract,
                        comission:
                            newPartialProperty?.contract?.comission ??
                            (newPartialProperty.listing.operation === 'sale'
                                ? DEFAULT_COMMISSION_RECOMMENDED_BROKER?.sale
                                : DEFAULT_COMMISSION_RECOMMENDED_BROKER?.rent)
                    }
                };
            }
            if (newPartialProperty?.listing?.price?.price) {
                if (!state?.property?.listing?.price?.currency) {
                    newPartialProperty = {
                        ...newPartialProperty,
                        listing: {
                            ...newPartialProperty?.listing,
                            price: {
                                ...newPartialProperty?.listing?.price,
                                currency: newPartialProperty?.listing?.price?.currency ?? DEFAULT_CURRENCY
                            }
                        }
                    };
                }
            }

            let newProperty = {
                ...state.property,
                ...newPartialProperty
            };
            // if (
            //     newProperty?.capture?.status?.last === PropertyCaptureStatus.Completed ||
            //     newProperty?.capture?.status?.last === PropertyCaptureStatus.Cancelled
            // ) {
            //     set({ isDisabled: true });
            // }
            if (newPartialProperty?.acm) {
                newProperty = {
                    ...newProperty,
                    acm: {
                        ...newProperty?.acm,
                        ...newPartialProperty?.acm
                    }
                };
                delete newPartialProperty.acm;
            }

            return {
                stackedChanges: newPartialProperty,
                property: Object.keys(newProperty).length ? newProperty : undefined
            };
        });
    },
    resetProperty: () => {
        set({ property: undefined, valuesIADetected: undefined, isDisabled: false });
    }
}));

export default useCaptureProperty;
