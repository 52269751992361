import { parsePrice } from '../../utils/parser';
import { twMerge } from 'tailwind-merge';

export interface ValuationBarChartProps {
    columns: Array<{
        comparables: Array<{ _id: string; value: number }>;
        max: number;
        min: number;
    }>;
    marks?: Array<{ value: number; column: number; label?: string }>;
    selectedColumn: number;
    onSelect: (column: number) => void;
}

const getColor = (index, selectedColumn) => {
    if (selectedColumn < 0 || selectedColumn === index) {
        if (index < 2) return 'bg-strong-green';
        else if (index < 6) return 'bg-strong-yellow';
        return 'bg-strong-red';
    }
    if (index < 2) return 'bg-notification-green';
    else if (index < 6) return 'bg-notification-yellow';
    return 'bg-notification-red';
};

export const ValuationBarChart = ({ columns, selectedColumn, marks = [], onSelect }: ValuationBarChartProps) => {
    const maxPrice = Math.round(columns?.[7]?.max / 100) * 100;
    const minPrice = Math.round(columns?.[0]?.min / 100) * 100;
    const maxHeight = columns?.reduce((max, col) => {
        if (col.comparables?.length > max) return col.comparables.length;
        return max;
    }, 0);
    const startEmptyColumn = marks.find((mark) => mark?.column === -1);
    const endEmptyColumn = marks.find((mark) => mark?.column === 8);

    return (
        <div className="mt-12 flex h-full w-full flex-col">
            <div className="flex flex-1 items-end justify-around">
                {startEmptyColumn ? (
                    <div className="flex w-8 flex-col items-center justify-center md:w-10">
                        <div className="relative top-0 flex h-8 w-fit items-center justify-center bg-black px-1 text-sm font-medium text-white">
                            {parsePrice({
                                price: Math.round(startEmptyColumn?.value),
                                startStr: ' '
                            })}
                        </div>
                        <div className="h-48 w-px bg-black" />
                    </div>
                ) : null}

                {columns?.map((col, index) => {
                    return (
                        <button
                            type="button"
                            onClick={() => {
                                onSelect(index);
                            }}
                            key={`bar_${index}`}
                            className="relative flex h-40 flex-col items-end justify-end"
                        >
                            {marks.map((mark, j) => {
                                if (mark.column === index) {
                                    const isFirst = marks.findIndex((m) => m.column === index) === j;
                                    let position = marks.length > 1 ? 'top-[4.5rem]' : 'top-0';
                                    if (j === 1) {
                                        position = 'top-9';
                                    } else if (j === 2) {
                                        position = 'top-0';
                                    }

                                    return (
                                        <div
                                            className={twMerge(
                                                'absolute -left-4 bottom-6 z-10 flex w-16 flex-col items-center justify-center md:-left-3',
                                                position,
                                                !isFirst && 'justify-start'
                                            )}
                                            key={`mark_${j}`}
                                        >
                                            <div
                                                className={twMerge(
                                                    'flex h-8 w-fit items-center justify-center bg-primary-black px-1 text-xs text-white',
                                                    index !== selectedColumn &&
                                                        'bg-secondary-gray-disabled text-primary-gray',
                                                    mark.label && 'flex-col py-1 leading-3'
                                                )}
                                            >
                                                {parsePrice({
                                                    price: Math.round(mark.value),
                                                    startStr: ' '
                                                })}
                                                {mark.label ? (
                                                    <p className="text-center text-[8px]">{mark.label}</p>
                                                ) : null}
                                            </div>
                                            <div
                                                className={twMerge(
                                                    'h-full w-px bg-black',
                                                    index !== selectedColumn && 'bg-secondary-gray-disabled',
                                                    !isFirst && 'h-0'
                                                )}
                                            />
                                        </div>
                                    );
                                }
                                return null;
                            })}
                            <div
                                className={`flex w-8 items-end justify-center rounded-t-sm md:w-10 ${getColor(
                                    index,
                                    selectedColumn
                                )}`}
                                style={{
                                    height: `${Math.round((100 * (col.comparables?.length + 0.3)) / maxHeight)}%`
                                }}
                            >
                                <p className="font-medium">{col.comparables?.length}</p>
                            </div>
                        </button>
                    );
                })}
                {endEmptyColumn ? (
                    <div className="flex w-8 flex-col items-center justify-center md:w-10">
                        <div className="relative top-0 flex h-8 w-fit items-center justify-center bg-black px-1 text-sm font-medium text-white">
                            {parsePrice({
                                price: Math.round(endEmptyColumn.value),
                                startStr: ' '
                            })}
                        </div>
                        <div className="h-48 w-px bg-black"></div>
                    </div>
                ) : null}
            </div>
            <div className="flex w-full justify-between border-t border-solid border-gray-300 py-1 text-xs">
                <p>{`${parsePrice({
                    price: minPrice,
                    startStr: ' '
                })} ${process.env.NEXT_PUBLIC_PRIMARY_CURRENCY} / m2`}</p>
                <p>{`${parsePrice({ price: maxPrice, startStr: ' ' })} ${
                    process.env.NEXT_PUBLIC_PRIMARY_CURRENCY
                } / m2`}</p>
            </div>
            <div className="mt-4 flex w-full justify-between text-xs">
                <div className="flex items-center gap-1">
                    <div className="h-3 w-3 rounded-full bg-strong-green"></div>
                    <p>Precio óptimo</p>
                </div>
                <div className="flex items-center gap-1">
                    <div className="h-3 w-3 rounded-full bg-strong-yellow"></div>
                    <p>Precio no competitivo</p>
                </div>
                <div className="flex items-center gap-1">
                    <div className="h-3 w-3 rounded-full bg-strong-red"></div>
                    <p>Precio fuera de mercado</p>
                </div>
            </div>
        </div>
    );
};

export default ValuationBarChart;
