import { create } from 'zustand';
import { IUser } from '../contexts/UserContext';

interface IHomeUser extends IUser {
    showAll?: boolean;
}
interface UseUserStore {
    user?: IHomeUser;
    setUser: (user: IHomeUser | Partial<IHomeUser> | ((user: IHomeUser) => IHomeUser)) => void;
}

export const useHomeUser = create<UseUserStore>((set, store) => ({
    user: null,
    setUser: async (user) => {
        const oldUser = store().user;
        if (!user) {
            return set({ user: null });
        }
        const newUser = typeof user === 'function' ? user(oldUser) : { ...oldUser, ...user };
        set({ user: newUser });
        return;
    }
}));

export default useHomeUser;
