import { IProperty } from '@pulppo/pulppo-models';
import { ShowPulppoDrawer } from '../Elements/showPulppoDrawer';
import { useEffect, useState } from 'react';
import { useTranslate } from '@tolgee/react';
import { AttributeMapper } from '../../utils/AttributeMapper';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { PropertyStatusMapper } from '../../utils/status';
import { LoadingButton } from '../Elements/ButtonLoading';
import { DeletePropertyReason } from '../PropertyDetail/PropertyDetail';
import axios from '../../utils/axios';
import { api_url } from '../../helpers/fetcher';
import { notification } from 'antd';
import useIsMobile from '../../hooks/isMobile';

export const PropertyStatusDrawer = ({ property }) => {
    return (
        <div className="h-full w-full">
            {property?.status?.last === 'edition' && <PropertyStatusEdition property={property} />}
            {property?.status?.last === 'published' && <PropertyStatusPublished property={property} />}
            {['cancelled', 'completed'].includes(property?.status?.last) && (
                <PropertyStatusFinished property={property} />
            )}
        </div>
    );
};

const PropertyStatusEdition = ({ property }) => {
    const { t } = useTranslate('common');
    const [missingFields, setMissingFields] = useState<string[]>([]);

    useEffect(() => {
        if (property) {
            setMissingFields(getPropertyMissingFields(property, t));
        }
    }, [property]);

    return (
        <div className="flex h-full w-full flex-col items-center justify-between">
            <div className="w-full">
                <p className="mb-4">
                    La propiedad se encuentra en estado <b>{PropertyStatusMapper[property?.status?.last].label}</b>
                </p>
                {missingFields?.length ? (
                    <div className="flex w-full flex-col items-center justify-start gap-3">
                        <p className="mb-1 w-full">
                            Para <b>publicarla</b> debes completar todos los campos obligatorios:{' '}
                        </p>
                        {missingFields?.map((msg, index) => (
                            <p key={`message_${index}`} className="w-full">
                                <CloseCircleFilled className="mr-1 bg-status-light-error text-status-dark-error" />{' '}
                                {msg}
                            </p>
                        ))}
                    </div>
                ) : (
                    <p>
                        <CheckCircleFilled className="mr-1 text-status-dark-success" />
                        Ya estas listo para publicar la propiedad
                    </p>
                )}
            </div>

            <LoadingButton
                type="primary"
                className="h-10 !w-full font-medium"
                disabled={!!missingFields?.length}
                onClick={async () => {
                    await axios
                        .post(`${api_url}/property/${property?._id}/publish`)
                        .then(() => {
                            notification.success({ message: 'Propiedad publicada con éxtio' });
                            window.location.reload();
                        })
                        .catch(() => {
                            notification.error({ message: 'Ocurrió un error al publicar la propiedad' });
                        });
                }}
            >
                Publicar propiedad
            </LoadingButton>
        </div>
    );
};

const PropertyStatusPublished = ({ property }) => {
    const isMobile = useIsMobile();
    return (
        <div className="flex h-full w-full flex-col items-center justify-between">
            <div className="w-full">
                <p className="mb-4">
                    La propiedad se encuentra en estado <b>{PropertyStatusMapper[property?.status?.last].label}</b>
                </p>
                <p>
                    Si la propiedad ya no está disponible puedes <b>despublicarla</b>, para quitarla de tu inventario.
                </p>
            </div>

            <LoadingButton
                type="primary"
                className="h-10 !w-full font-medium"
                onClick={async () => {
                    const destroy = DeletePropertyReason((reason) => {
                        axios
                            .delete(`${api_url}/property/${property?._id}`, {
                                data: {
                                    reasonToFinish: reason
                                }
                            })
                            .then(() => {
                                notification.success({
                                    message: 'Propiedad eliminada con éxito',
                                    duration: isMobile ? 2 : 4,
                                    placement: isMobile ? 'bottomRight' : 'topRight'
                                });
                                window.location.reload();

                                if (
                                    reason === 'Concreté la operación' ||
                                    reason === '"La vendió o rentó otro broker compartiendo comisión"'
                                )
                                    window.open(
                                        `${window?.location?.origin}/operations/new?property=${property?._id}`,
                                        '_blank'
                                    );
                                destroy();
                            });
                    });
                }}
            >
                Despublicar propiedad
            </LoadingButton>
        </div>
    );
};

const PropertyStatusFinished = ({ property }) => {
    return (
        <div className="flex h-full w-full flex-col items-center justify-between">
            <div className="w-full">
                <p className="mb-4">
                    La propiedad se encuentra en estado <b>{PropertyStatusMapper[property?.status?.last].label}</b>
                </p>
                <p>
                    Si quieres puedes <b>reactivar</b> la propiedad para que vuelva a estar disponible en tu inventario.
                </p>
            </div>

            <LoadingButton
                type="primary"
                className="h-10 !w-full font-medium"
                onClick={async () => {
                    await axios.patch(`${api_url}/property/${property?._id}/reactivate`).then((r) => r.data);

                    notification.success({
                        message: 'Propiedad reactivada con éxito'
                    });
                    window.location.reload();
                }}
            >
                Reactivar propiedad
            </LoadingButton>
        </div>
    );
};

const getPropertyMissingFields = (property: IProperty, t) => {
    const missingFields: string[] = [];
    if (!property?.address?.street) missingFields.push('Tienes que agregar una dirección');
    if (!property?.contact) missingFields.push('Tienes que agregar un propietario');
    if (!property?.type) missingFields.push('Tienes que agregar un tipo de propiedad');
    if (!property?.address?.neighborhood?.name) missingFields.push('Tienes que agregar una colonia');
    if (!property?.address?.city?.name) missingFields.push('Tienes que agregar una ciudad o delegación');
    if (!property?.address?.state?.name) missingFields.push('Tienes que agregar un estado');
    for (const key in AttributeMapper) {
        if (
            AttributeMapper[key][property.type] === 'required' &&
            [null, undefined, ''].includes(property.attributes?.[key]) &&
            [null, undefined, ''].includes(property.additionalFields?.[key])
        )
            missingFields.push(`Tienes que agregar ${t(key)}`);
    }
    if (!property?.listing?.operation) missingFields.push('Tienes que agregar un tipo de operación');
    if (!property?.listing?.price?.price) missingFields.push('Tienes que agregar un precio');
    if (!property?.listing?.title) missingFields.push('Tienes que agregar un título');
    if (!property?.listing?.description) missingFields.push('Tienes que agregar una descripción');
    if (!property?.contract?.durationMonths) missingFields.push('Tienes que agregar una duración del acuerdo');
    if (!property?.contract?.comission) missingFields.push('Tienes que agregar una comisión');
    if (!property.pictures?.length) missingFields.push('Tienes que agregar al menos una imagen');
    return missingFields;
};

export const openPropertyStatusDrawer = ({ property }: { property: IProperty }) => {
    const { destroy } = ShowPulppoDrawer({
        title: 'Estado de la propiedad',
        children: <PropertyStatusDrawer property={property} />,
        onCancel: () => destroy()
    });
};
