import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { useCaptureProperty, useCapturePropertyForm } from '../../hooks/useCaptureProperty';
import Badge from '../Elements/Badge';
import { ColorBadge } from '../../utils/color';
import { InfoCircleOutlined, LinkOutlined, RightOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { PropertyLocation } from '../PropertyDetail/PropertyLocation';
import { Form, Input } from 'antd';
import CapturePropertyAttributes from './CapturePropertyAttributes';
import CapturePropertyPromotion from './CapturePropertyPromotion';
import { PropertyInternalInformation } from '../Property/Steps/step-2';
import { PropertyStep3 } from '../Property/Steps/step-3';
import { PropertyListing } from '../PropertyDetail/PropertyListing';
import Button from '../Elements/Button';
import { pendingKeysPropertyCapture, PropertyForm } from '../../utils/constants';
import { useRouter } from 'next/router';
import { useShallow } from 'zustand/react/shallow';
import useCapturePropertyUpdate from '../../hooks/useCapturePropertyUpdate';
import axios from '../../utils/axios';
import { api_url } from '../../helpers/fetcher';
import { PulppoLoader } from '../Elements/PulppoLoader';
import { IProperty } from '@pulppo/pulppo-models';
import Link from 'next/link';

export interface CapturePropertyDrawerProps {
    show: boolean;
    onClose: (propertyUpdated?: Partial<IProperty>) => void;
    disableUpdate?: boolean;
    propertyInitId?: string;
    showButtonGoCapture?: boolean;
}

const CapturePropertyDrawer = ({
    show,
    onClose,
    disableUpdate,
    propertyInitId,
    showButtonGoCapture
}: CapturePropertyDrawerProps) => {
    const { propertyId, resetProperty, setProperty } = useCaptureProperty(
        useShallow((state) => ({
            propertyId: state?.property?._id,
            setProperty: state?.setProperty,
            resetProperty: state?.resetProperty
        }))
    );
    const { form, property, onFormChange } = useCapturePropertyForm({ show });
    const [showPropertyForm, setShowPropertyForm] = useState<PropertyForm>();
    const [showImport, setShowImport] = useState(false);
    const [isLoadingInit, setIsLoadingInit] = useState(false);
    const router = useRouter();
    useCapturePropertyUpdate({ disabled: disableUpdate });

    const list = [
        {
            key: PropertyForm.Address,
            title: 'Ubicación',
            pendingKeys: pendingKeysPropertyCapture({
                propertyForm: PropertyForm.Address
            })
        },
        {
            key: PropertyForm.Characteristics,
            title: 'Características',
            pendingKeys: pendingKeysPropertyCapture({
                type: property?.type,
                propertyForm: PropertyForm.Characteristics
            })
        },
        {
            key: PropertyForm.Price,
            title: 'Operación',
            pendingKeys: pendingKeysPropertyCapture({
                propertyForm: PropertyForm.Price
            })
        },
        {
            key: PropertyForm.Promotion,
            title: 'Promoción',
            pendingKeys: pendingKeysPropertyCapture({
                propertyForm: PropertyForm.Promotion
            })
        },
        {
            key: PropertyForm.InternalInformation,
            title: 'Información interna',
            pendingKeys: pendingKeysPropertyCapture({
                propertyForm: PropertyForm.InternalInformation
            })
        },
        {
            key: PropertyForm.Gallery,
            title: 'Galería',
            pendingKeys: pendingKeysPropertyCapture({
                propertyForm: PropertyForm.Gallery
            })
        }
    ];
    const title = !property ? 'Nueva propiedad' : 'Editar propiedad';
    const titleSelected = list.find((l) => l.key === showPropertyForm)?.title;

    useEffect(() => {
        if (!disableUpdate && propertyInitId) {
            setIsLoadingInit(true);
            axios
                .get(`${api_url}/property/${propertyInitId}`)
                .then((res) => res.data)
                .then((p) => {
                    setProperty(p, 'initProperty-drawer');
                })
                .finally(() => {
                    setIsLoadingInit(false);
                });

            return () => {
                resetProperty();
            };
        }
    }, [propertyInitId, disableUpdate]);

    useEffect(() => {
        if (router?.query?.propertyEditOpen === 'operation') {
            setShowPropertyForm(PropertyForm.Price);
            router.push(`/captaciones/${propertyId}`, undefined, {
                shallow: true
            });
        }
    }, [router?.query?.propertyEditOpen]);

    return (
        <PulppoDrawer visible={show} onClose={() => onClose(property)} title={title}>
            <PulppoLoader className="h-full" loading={isLoadingInit}>
                <Form form={form} onValuesChange={onFormChange}>
                    <PulppoDrawer
                        visible={!!showPropertyForm}
                        onClose={() => setShowPropertyForm(null)}
                        title={`${title} | ${titleSelected}`}
                    >
                        <div className="overflow-y-auto overflow-x-hidden pb-12">
                            {showPropertyForm !== PropertyForm.Promotion &&
                            showPropertyForm !== PropertyForm.InternalInformation &&
                            showPropertyForm !== PropertyForm.Gallery ? (
                                <h2 className="mb-4 font-semibold text-primary-black">{titleSelected}</h2>
                            ) : null}
                            {showPropertyForm === PropertyForm.Address ? (
                                <PropertyLocation property={property} focusAddress />
                            ) : showPropertyForm === PropertyForm.Characteristics ? (
                                <CapturePropertyAttributes property={property} />
                            ) : showPropertyForm === PropertyForm.Promotion ? (
                                <CapturePropertyPromotion form={form} property={property} />
                            ) : showPropertyForm === PropertyForm.InternalInformation ? (
                                <PropertyInternalInformation
                                    property={property}
                                    disabled={{
                                        contract: true,
                                        notes: true
                                    }}
                                    className={{
                                        taskView: 'my-0 border-none p-0 md:my-0 md:p-0',
                                        title: 'text-sm font-semibold text-primary-black'
                                    }}
                                />
                            ) : showPropertyForm === PropertyForm.Gallery ? (
                                <PropertyStep3 property={property} simple />
                            ) : showPropertyForm === PropertyForm.Price ? (
                                <PropertyListing property={property} disableDetails calcValue />
                            ) : null}
                        </div>
                        <div className="absolute inset-x-6 bottom-0 z-10 flex gap-x-4 bg-white py-4">
                            <Button type="secondary" className="w-full" onClick={() => setShowPropertyForm(null)}>
                                Cerrar
                            </Button>
                            <Button type="primary" className="w-full" onClick={() => setShowPropertyForm(null)}>
                                Continuar
                            </Button>
                        </div>
                    </PulppoDrawer>
                </Form>
                <div className="flex h-full flex-col">
                    <ul className="flex-grow divide-y divide-solid divide-secondary-gray-disabled">
                        {list.map((item) => (
                            <li key={`list-${item.key}`}>
                                <button
                                    className="flex w-full items-center justify-between py-4"
                                    onClick={() => setShowPropertyForm(item.key)}
                                >
                                    <span>{item.title}</span>
                                    <div>
                                        {!item.pendingKeys.every((key) => {
                                            if (key.includes('.')) {
                                                const keys = key.split('.');
                                                const attribute = property?.[keys[0]]?.[keys[1]];

                                                if (item.key === PropertyForm.Gallery) {
                                                    return !!attribute;
                                                } else if (item.key === PropertyForm.Characteristics) {
                                                    if (!(!!attribute || typeof attribute === 'number')) {
                                                        const additionalField =
                                                            property?.['additionalFields']?.[keys[1]];
                                                        return !!additionalField || typeof additionalField === 'number';
                                                    }
                                                }
                                                return !!attribute || typeof attribute === 'number';
                                            }
                                            return !!property?.[key];
                                        }) ? (
                                            <Badge color={ColorBadge.Red} value="Pendiente" />
                                        ) : null}
                                        <RightOutlined className="ml-4 text-pulppo-primary-black" />
                                    </div>
                                </button>
                            </li>
                        ))}
                    </ul>
                    {showButtonGoCapture ? (
                        <div className="w-full">
                            <Link href={`/captaciones/${propertyId}`}>
                                <a className="btn btn-secondary block w-full text-center">Ir a captación</a>
                            </Link>
                        </div>
                    ) : null}
                </div>
                <PulppoDrawer visible={showImport} onClose={() => setShowImport(false)} title="Liga de la propiedad">
                    <Input
                        className="rounded-none [&>span>input]:rounded-none [&>span>span]:rounded-none"
                        placeholder="Pega la liga de portales"
                        addonAfter={<LinkOutlined />}
                    />
                    <p className="mt-2 text-xs text-primary-black">
                        <InfoCircleOutlined /> Pega aquí las ligas de Inmuebles 24, Easybroker o Mercadolibre
                    </p>
                </PulppoDrawer>
            </PulppoLoader>
        </PulppoDrawer>
    );
};

export default CapturePropertyDrawer;
