import { CheckOutlined, CloseCircleOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, List } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import { PulppoDrawer } from './PulppoDrawer';
import { twMerge } from 'tailwind-merge';
import useSWR from 'swr';
import { api_url, fetcher } from '../../helpers/fetcher';
import useUser from '../../hooks/useUser';

interface Props {
    value?: Array<string>;
    allowSearch?: boolean;
    onApply?: (value: Array<string>) => void;
    onClear?: () => void;
    renderButton?: (values: string[]) => ReactNode | Element;
    applyButtonText?: string;
    clearButtonText?: string;
}

export const TagSelect = ({
    value,
    allowSearch = true,
    renderButton,
    onApply = (_) => {},
    applyButtonText = 'Filtrar',
    clearButtonText = 'Limpiar',
    onClear = () => {}
}: Props) => {
    const [showDrawer, setShowDrawer] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selected, setSelected] = useState([]);
    const { user } = useUser();
    const { data: customTags } = useSWR<{ _id: string; labels: string[] }[]>(
        `${api_url}/tags?company=${user?.company?._id}&type=property`,
        fetcher
    );
    const disabled = !customTags?.length;
    const tags = customTags?.map((el) => el.labels).flat();
    useEffect(() => {
        setSelected(value || []);
    }, [value]);

    return (
        <>
            <div
                className={twMerge('cursor-pointer', disabled ? 'cursor-not-allowed opacity-50' : '')}
                onClick={() => {
                    if (disabled) return;
                    setShowDrawer(true);
                }}
            >
                {renderButton(value) as ReactNode}
            </div>

            <PulppoDrawer
                bodyStyle={{ paddingTop: '0px', paddingBottom: '0px' }}
                title={'Etiquetas internas'}
                visible={showDrawer}
                onClose={() => {
                    setShowDrawer(false);
                }}
            >
                <div className="flex h-full w-full flex-col">
                    {allowSearch && (
                        <div className="flex-0 shrink-0 bg-white pt-2">
                            <div className="flex w-full flex-wrap gap-2">
                                {selected
                                    ?.filter?.((_, i) => i < 5)
                                    .map((val) => (
                                        <div
                                            key={val}
                                            className="flex h-7 cursor-pointer items-center justify-center gap-2 border border-solid border-gray-300 px-2 text-sm text-pulppo-status-dark-disabled"
                                            onClick={() => {
                                                const aux = [...selected];
                                                const index = aux.indexOf(val);

                                                aux.splice(index, 1);
                                                setSelected(aux);
                                            }}
                                        >
                                            <p>{tags?.find((opt) => opt === val)}</p>
                                            <CloseCircleOutlined className="text-base" />
                                        </div>
                                    ))}
                                {selected?.length > 5 && (
                                    <div className="flex h-7 cursor-pointer items-center justify-center gap-2 border border-solid border-gray-300 px-2 text-sm text-pulppo-status-dark-disabled">
                                        <p>+{selected?.length - 5} filtros</p>
                                    </div>
                                )}
                            </div>
                            <Input
                                suffix={<SearchOutlined className="text-gray-300" />}
                                placeholder="Buscar"
                                className="my-4 h-11"
                                value={searchTerm}
                                allowClear
                                onChange={(e) => setSearchTerm(e.currentTarget.value)}
                            />
                        </div>
                    )}
                    <div className="flex-1 overflow-y-auto overflow-x-hidden">
                        {customTags
                            ?.filter((g) =>
                                g.labels.some((label) =>
                                    label
                                        ?.normalize('NFD')
                                        .replace(/[\u0300-\u036f]/g, '')
                                        ?.toLowerCase()
                                        .includes(searchTerm?.toLowerCase())
                                )
                            )
                            ?.map((group) => (
                                <div key={`group_${group._id}`}>
                                    <p className="my-2 text-lg font-semibold">{group._id || 'Otros'}</p>
                                    <List
                                        dataSource={
                                            allowSearch
                                                ? group.labels.filter((label) =>
                                                      label
                                                          ?.normalize('NFD')
                                                          .replace(/[\u0300-\u036f]/g, '')
                                                          ?.toLowerCase()
                                                          .includes(searchTerm?.toLowerCase())
                                                  )
                                                : group.labels
                                        }
                                        renderItem={(item) => (
                                            <List.Item
                                                className={'cursor-pointer hover:underline'}
                                                key={item}
                                                onClick={() => {
                                                    setSearchTerm('');
                                                    const aux = [...selected];
                                                    const index = aux.indexOf(item);
                                                    if (index === -1) setSelected([...selected, item]);
                                                    else {
                                                        aux.splice(index, 1);
                                                        setSelected(aux);
                                                    }
                                                }}
                                            >
                                                <div className="flex items-center gap-2">
                                                    <div
                                                        className={`flex h-5 w-5 items-center justify-center rounded-none border border-solid ${
                                                            selected?.includes(item)
                                                                ? 'border-black'
                                                                : 'border-gray-200'
                                                        } bg-white`}
                                                    >
                                                        {selected?.includes(item) && (
                                                            <CheckOutlined className="p-1 text-xs font-bold text-black" />
                                                        )}
                                                    </div>
                                                    <p>{item}</p>
                                                </div>
                                            </List.Item>
                                        )}
                                    />
                                </div>
                            ))}
                    </div>

                    <div className="flex shrink-0 gap-2 bg-white pb-4 pt-2">
                        <Button
                            onClick={() => {
                                onClear();
                                setShowDrawer(false);
                            }}
                            className="h-10 flex-1 rounded-none font-medium"
                        >
                            {clearButtonText}
                        </Button>
                        <Button
                            onClick={() => {
                                onApply(selected);
                                setShowDrawer(false);
                            }}
                            type="primary"
                            className="h-10 flex-1 rounded-none font-medium"
                        >
                            {applyButtonText}
                        </Button>
                    </div>
                </div>
            </PulppoDrawer>
        </>
    );
};
