export const hasPermissions = (user, property) => {
    if (user?.features?.viewAll) {
        return true;
    }
    if (property?.agentSeller?._id == user?._id) {
        return true;
    }
    if (property?.agent?._id == user?._id) {
        return true;
    }
    if (user?.company?._id !== property?.company?._id) {
        return false;
    }
    return !!user?.permissions?.properties?.edit;
};
