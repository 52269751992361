import dayjs, { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generateCalendar, { CalendarProps } from 'antd/lib/calendar/generateCalendar';
import { Select } from 'antd';
import locale from 'antd/lib/date-picker/locale/es_ES';

export const rotateArray = (arr: any[], count: number) => {
    for (let i = 0; i < count; i++) {
        arr.push(arr.shift());
    }
    return arr;
};

const Calendar = generateCalendar<Dayjs>(dayjsGenerateConfig);

export const disabledDateBefore = (current: Dayjs, beforeThisDate: Dayjs) => {
    return current && current < beforeThisDate;
};

export const CalendarDefault = (props: CalendarProps<Dayjs>) => {
    return (
        <Calendar
            locale={locale}
            fullscreen={false}
            headerRender={(config) => {
                return (
                    <div className="flex w-full justify-center gap-4 py-4">
                        <Select
                            value={`${config.value.month() + 1}`}
                            className="w-1/3"
                            onSelect={(value) => {
                                config.onChange(
                                    dayjs(config.value)
                                        .tz()
                                        .month(+value - 1)
                                );
                            }}
                            options={rotateArray(
                                [
                                    {
                                        label: 'Enero',
                                        key: 'Enero',
                                        value: '1'
                                    },
                                    {
                                        label: 'Febrero',
                                        key: 'Febrero',
                                        value: '2'
                                    },
                                    {
                                        label: 'Marzo',
                                        key: 'Marzo',
                                        value: '3'
                                    },
                                    {
                                        label: 'Abril',
                                        key: 'Abril',
                                        value: '4'
                                    },
                                    {
                                        label: 'Mayo',
                                        key: 'Mayo',
                                        value: '5'
                                    },
                                    {
                                        label: 'Junio',
                                        key: 'Junio',
                                        value: '6'
                                    },
                                    {
                                        label: 'Julio',
                                        key: 'Julio',
                                        value: '7'
                                    },
                                    {
                                        label: 'Agosto',
                                        key: 'Agosto',
                                        value: '8'
                                    },
                                    {
                                        label: 'Septiembre',
                                        key: 'Septiembre',
                                        value: '9'
                                    },
                                    {
                                        label: 'Octubre',
                                        key: 'Octubre',
                                        value: '10'
                                    },
                                    {
                                        label: 'Noviembre',
                                        key: 'Noviembre',
                                        value: '11'
                                    },
                                    {
                                        label: 'Diciembre',
                                        key: 'Diciembre',
                                        value: '12'
                                    }
                                ],
                                dayjs().tz().month()
                            )}
                        />
                        <Select
                            onSelect={(value) => {
                                config.onChange(dayjs(config.value).tz().year(+value));
                            }}
                            value={config.value.year()}
                            options={new Array(10).fill(dayjs().tz().year() - 2).map((offset, index) => ({
                                label: `${offset + index}`,
                                key: `${offset + index}`,
                                value: offset + index
                            }))}
                            className="w-1/3"
                        />
                    </div>
                );
            }}
            {...props}
        />
    );
};

export default Calendar;
