import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { api_url, fetcher } from '../../helpers/fetcher';
import { useEffect, useState } from 'react';
import {
    CheckCircleFilled,
    MailOutlined,
    MessageOutlined,
    PhoneOutlined,
    QuestionCircleFilled,
    RightOutlined,
    SearchOutlined,
    WhatsAppOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { IAgent, IContact, ILead } from '@pulppo/pulppo-models';
import Button from '../Elements/Button';
import useChat from '../../hooks/useChat';
import { useDebounceValue } from '../../hooks/debounceValue';
import { Input, notification } from 'antd';
import { PulppoLoader } from '../Elements/PulppoLoader';
import { PulppoRadio } from '../Elements/PulppoRadio';
import { Image } from './IntelliHome';
import { useVisible } from '../../hooks/useVisible';
import { AgentSelect } from '../Elements/AgentSelect';
import useUser from '../../hooks/useUser';
import AvatarUser from '../Elements/AvatarUser';
import axios from '../../utils/axios';

const statusOptions = [
    { label: 'Todos', value: 'all' },
    { label: 'Pendientes', value: 'pending' },
    { label: 'Respondidas', value: 'answered' }
];
const timeOptions = [
    { label: 'Todas', value: 'all' },
    { label: 'Últimos 7 días', value: 'last_7_days' },
    { label: 'Últimos 15 días', value: 'last_15_days' },
    { label: 'Últimos 30 días', value: 'last_30_days' },
    { label: 'Últimos 60 días', value: 'last_60_days' },
    { label: 'Últimos 90 días', value: 'last_90_days' }
];
const operationOptions = [
    { label: 'Todas', value: 'all' },
    { label: 'Venta', value: 'sale' },
    { label: 'Renta', value: 'rent' }
];

const SIZE = 10;
export const Leads = ({ show, onClose }) => {
    const user = useUser((u) => u.user);
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState({
        show: false,
        name: 'Seleccione las opciones deseadas',
        step: 'filter',
        status: 'all',
        time: 'all',
        operation: 'all',
        agent: user
    });

    const [appliedFilters, setAppliedFilters] = useState(filters);

    const [search, setSearch] = useState('');

    const debounced = useDebounceValue(search, 300);
    const [leads, setLeads] = useState<(ILead & { contact: IContact })[]>([]);
    const [count, setCount] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const getLeads = ({ user, page, debounced, status, time, operation, agent }) => {
        setLoading(true);
        fetcher(
            `${api_url}/lead/search?limit=${SIZE}&offset=${(page - 1) * SIZE}&filter=${status}&date=${time}&operation=${operation}&agent=${agent?.uid}${
                debounced ? `&search=${debounced}` : ''
            }`
        ).then((r) => {
            setCount(r.count);
            setLeads((v) => (page == 1 ? r.leads : [...v, ...r.leads]));
            setLoading(false);
        });
    };
    useEffect(() => {
        if (debounced) {
            setPage(1);
        }
        const newPage = debounced ? 1 : page;
        getLeads({
            user,
            page: newPage,
            debounced,
            status: appliedFilters?.status,
            time: appliedFilters?.time,
            operation: appliedFilters?.operation,
            agent: appliedFilters.agent
        });
    }, [
        user?.uid,
        page,
        debounced,
        appliedFilters?.status,
        appliedFilters?.time,
        appliedFilters?.operation,
        appliedFilters?.agent?.uid
    ]);

    const { ref: endRef, visible } = useVisible();

    useEffect(() => {
        if (visible) {
            setPage((l) => l + 1);
        }
    }, [visible]);

    const setChatContact = useChat((c) => c.setChatContact);

    return (
        <>
            <PulppoDrawer visible={show} onClose={onClose} title={`Consultas (${count || 0})`}>
                <div className="flex items-center gap-2">
                    <Input
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Buscar..."
                        className="rounded-none py-2"
                        suffix={<SearchOutlined className="text-gray-300" />}
                    />
                    <Button
                        onClick={() =>
                            setFilters((filters) => ({
                                ...filters,
                                show: !filters.show,
                                name: 'Seleccione las opciones deseadas',
                                step: 'filter'
                            }))
                        }
                        type="secondary"
                    >
                        Filtros
                    </Button>
                </div>
                <div className="flex flex-col gap-2">
                    <PulppoLoader loading={isLoading} />

                    {leads.map((lead) => (
                        <div
                            key={`${lead._id}`}
                            className="flex w-full flex-col gap-2 border border-solid border-gray-200 p-2"
                        >
                            <div className="flex w-full items-center justify-between">
                                <div className="flex items-center gap-2">
                                    <div className="h-10 w-10">
                                        <Image
                                            src={
                                                lead?.contact?.profilePicture ||
                                                `https://ui-avatars.com/api/?name=${(lead.contact?.firstName + ' ' + lead.contact?.lastName).replace(/\b/gi, '+')}`
                                            }
                                            width={256}
                                            height={256}
                                            alt={`${lead.contact.firstName} ${lead.contact.lastName}`}
                                            className="h-10 w-10 rounded-full object-cover"
                                        />
                                    </div>
                                    <div className="flex flex-col">
                                        <h5 className="m-0 p-0 font-medium">
                                            {lead.contact.firstName} {lead.contact.lastName}
                                        </h5>
                                        <p className="m-0 p-0 font-light text-pulppo-status-dark-disabled">
                                            {dayjs(lead.sentAt).fromNow()}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex items-center gap-2">
                                    <div className="flex items-center gap-1">
                                        {[
                                            {
                                                key: 'lastWhatsapp',
                                                icon: <WhatsAppOutlined className="h-5 w-5 text-lg" />
                                            },
                                            {
                                                key: 'lastMail',
                                                icon: <MailOutlined className="h-5 w-5 text-lg" />
                                            },
                                            {
                                                key: 'lastSMS',
                                                icon: <MessageOutlined className="h-5 w-5 text-lg" />
                                            },
                                            {
                                                key: 'lastCall',
                                                icon: <PhoneOutlined className="h-5 w-5 text-lg" />
                                            }
                                        ].map((el) => (
                                            <div
                                                key={`${lead._id}_${el.key}`}
                                                className={`relative ${lead?.contact?.qualification?.[el.key] ? '' : 'hidden'}`}
                                            >
                                                <div className="h-5 w-5">{el.icon}</div>
                                                <div className="absolute bottom-0 right-0">
                                                    {lead?.contact?.qualification?.[el.key] ? (
                                                        <CheckCircleFilled className="h-2 w-2 bg-white text-green-500" />
                                                    ) : (
                                                        <QuestionCircleFilled className="h-2 w-2 bg-white text-gray-300" />
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {lead.answeredAt ? (
                                        <div className="bg-pulppo-status-light-success px-2 py-1 text-pulppo-status-dark-success">
                                            Respondida
                                        </div>
                                    ) : (
                                        <div className="bg-pulppo-status-light-warning px-2 py-1 text-pulppo-status-dark-warning">
                                            Pendiente
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="h-px w-full bg-gray-200" />
                            <div className="flex w-full items-center justify-between">
                                <div className="flex items-center gap-2">
                                    <div className="h-12 w-12">
                                        <Image
                                            src={lead?.property?.pictures?.[0]?.url}
                                            width={256}
                                            height={256}
                                            alt={`${lead.property?.address?.street}`}
                                            className="h-12 w-12 object-cover"
                                        />
                                    </div>
                                    <div className="flex flex-col">
                                        <h5 className="m-0 p-0">{lead.property?.address?.street}</h5>
                                        <p className="m-0 p-0 font-light text-pulppo-status-dark-disabled">
                                            {[
                                                lead?.property?.address?.neighborhood?.name,
                                                lead?.property?.address?.city?.name,
                                                lead?.property?.address?.state?.name
                                            ]
                                                .filter(Boolean)
                                                .slice(0, 2)
                                                .join(', ')}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <h5 className="m-0 p-0">{lead?.testSource || lead.source}</h5>
                                    <p className="m-0 p-0 font-light text-pulppo-status-dark-disabled">
                                        {lead?.medium}
                                    </p>
                                </div>
                            </div>
                            {user?.uid !== lead?.agent?.uid && lead?.search ? (
                                <>
                                    <div className="h-px w-full bg-gray-200" />

                                    <div className="flex w-full items-center justify-between">
                                        <AvatarUser user={lead.agent} />
                                        <AgentSelect
                                            multi={false}
                                            onChange={(agent) => {
                                                axios
                                                    .post(`${api_url}/search/${lead.search}/delegate`, {
                                                        agent: agent._id
                                                    })
                                                    .then((res) => {
                                                        getLeads({
                                                            user,
                                                            page,
                                                            debounced,
                                                            status: appliedFilters?.status,
                                                            time: appliedFilters?.time,
                                                            operation: appliedFilters?.operation,
                                                            agent: appliedFilters.agent
                                                        });
                                                        notification.success({
                                                            message: 'Consulta reasignada con éxito',
                                                            description: `La consulta ha sido reasignada a ${agent.firstName} ${agent.lastName}`
                                                        });
                                                    })
                                                    .catch((err) => {
                                                        notification.error({
                                                            message: 'Hubo un problema al reasignar la consulta',
                                                            description: `No hemos podido reasignar la consulta al broker ${agent.firstName} ${agent.lastName}`
                                                        });
                                                    });
                                            }}
                                            user={user}
                                            renderButton={() => (
                                                <Button
                                                    className="h-auto px-6 py-2"
                                                    type="secondary"
                                                    disabled={!!lead?.answeredAt}
                                                >
                                                    Reasignar
                                                </Button>
                                            )}
                                        />
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}

                            <Button
                                className="h-auto py-2"
                                type="secondary"
                                onClick={() => {
                                    setChatContact({
                                        ...lead.contact,
                                        asAgent: lead.agent,
                                        search: { _id: lead.search } as any
                                    });
                                }}
                            >
                                {user?.uid !== lead?.agent?.uid ? 'Ver conversación' : 'Responder consulta'}
                            </Button>
                        </div>
                    ))}
                    <div className="h-px w-full bg-gray-200" ref={endRef} />
                </div>
            </PulppoDrawer>
            <PulppoDrawer
                title={filters.name}
                visible={filters.show}
                onClose={() => {
                    setFilters((f) => ({ ...f, show: false }));
                }}
                bodyStyle={{ height: '100%' }}
            >
                <div className="flex h-full flex-col justify-between">
                    <div className="flex flex-1 flex-col gap-4">
                        <div>
                            <p>Estado de consulta</p>
                            <PulppoRadio
                                value={filters.status}
                                title="Estado"
                                options={statusOptions}
                                onApply={function (value: string): void {
                                    setFilters((f) => ({ ...f, status: value }));
                                }}
                                onClear={() => {
                                    setFilters((f) => ({ ...f, status: 'all' }));
                                }}
                                render={() => (
                                    <div className="flex cursor-pointer items-center justify-between border border-solid border-gray-300 p-2">
                                        {statusOptions?.find((el) => el.value === filters.status)?.label}
                                        <RightOutlined className="text-gray-300" />
                                    </div>
                                )}
                            ></PulppoRadio>
                        </div>
                        <div>
                            <p>Tiempo</p>
                            <PulppoRadio
                                value={filters.time}
                                title="Tiempo"
                                options={timeOptions}
                                onApply={function (value: string): void {
                                    setFilters((f) => ({ ...f, time: value }));
                                }}
                                onClear={() => {
                                    setFilters((f) => ({ ...f, time: 'all' }));
                                }}
                                render={() => (
                                    <div className="flex cursor-pointer items-center justify-between border border-solid border-gray-300 p-2">
                                        {timeOptions?.find((el) => el.value === filters.time)?.label}
                                        <RightOutlined className="text-gray-300" />
                                    </div>
                                )}
                            ></PulppoRadio>
                        </div>
                        <div>
                            <p>Operación</p>
                            <PulppoRadio
                                value={filters.operation}
                                title="Operación"
                                options={operationOptions}
                                onApply={function (value: string): void {
                                    setFilters((f) => ({ ...f, operation: value }));
                                }}
                                onClear={() => {
                                    setFilters((f) => ({ ...f, operation: 'all' }));
                                }}
                                render={() => (
                                    <div className="flex cursor-pointer items-center justify-between border border-solid border-gray-300 p-2">
                                        {operationOptions?.find((el) => el.value === filters.operation)?.label}
                                        <RightOutlined className="text-gray-300" />
                                    </div>
                                )}
                            />
                        </div>
                        {(user?.features?.viewAll || user?.permissions?.searches?.view) && (
                            <div>
                                <p>Asesor</p>
                                <AgentSelect
                                    className=""
                                    onChange={(agent: IAgent) => {
                                        setFilters((f) => ({ ...f, agent }) as any);
                                    }}
                                    allowAll={true}
                                    user={user}
                                    value={filters?.agent}
                                    renderButton={() => {
                                        return (
                                            <div className="flex cursor-pointer items-center justify-between border border-solid border-gray-300 p-2">
                                                {filters?.agent?.firstName} {filters?.agent?.lastName}
                                                <RightOutlined className="text-gray-300" />
                                            </div>
                                        );
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <Button
                        isLoading={isLoading}
                        onClick={() => {
                            setAppliedFilters(filters);
                            setLeads([]);
                            setPage(1);
                            setFilters((f) => ({ ...f, show: false }));
                        }}
                        type="primary"
                    >
                        Filtrar
                    </Button>
                </div>
            </PulppoDrawer>
        </>
    );
};
