import { RefObject, useEffect, useState } from 'react';

const useOnScreen = (ref: RefObject<HTMLElement>, dependencies?: Array<any>) => {
    const [isOnScreen, setIsOnScreen] = useState(false);

    useEffect(() => {
        if (!ref.current) return;
        const observer = new IntersectionObserver(([entry]) => setIsOnScreen(entry.isIntersecting));
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, dependencies);

    return isOnScreen;
};

export default useOnScreen;
