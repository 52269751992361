import { SVGProps } from "react"

const YellowLightBulb = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx="14.7342" cy="14" r="14" fill="#C69B1D"/>
        <g clip-path="url(#clip0_2165_779)">
        <path d="M9.77269 18.4355C9.77269 18.7121 9.9951 18.9355 10.2704 18.9355H19.1978C19.4731 18.9355 19.6955 18.7121 19.6955 18.4355V14.7949C19.6955 12.0418 17.4745 9.81055 14.7341 9.81055C11.9937 9.81055 9.77269 12.0418 9.77269 14.7949V18.4355ZM10.8925 14.7949C10.8925 12.6637 12.6127 10.9355 14.7341 10.9355C16.8555 10.9355 18.5757 12.6637 18.5757 14.7949V17.8105H13.0544V15.1387C13.0544 15.0527 12.9844 14.9824 12.8988 14.9824H12.2145C12.129 14.9824 12.059 15.0527 12.059 15.1387V17.8105H10.8925V14.7949ZM10.1444 10.8496L10.7603 10.2309C10.8085 10.1824 10.8085 10.1027 10.7603 10.0543L9.70426 8.99336C9.68087 8.9701 9.64929 8.95705 9.61638 8.95705C9.58347 8.95705 9.55189 8.9701 9.52851 8.99336L8.91261 9.61211C8.88945 9.6356 8.87646 9.66733 8.87646 9.70039C8.87646 9.73345 8.88945 9.76518 8.91261 9.78867L9.96866 10.8496C10.0169 10.898 10.0946 10.898 10.1444 10.8496ZM20.5587 9.61211L19.9428 8.99336C19.9194 8.9701 19.8878 8.95705 19.8549 8.95705C19.822 8.95705 19.7904 8.9701 19.767 8.99336L18.711 10.0543C18.6878 10.0778 18.6749 10.1095 18.6749 10.1426C18.6749 10.1756 18.6878 10.2074 18.711 10.2309L19.3269 10.8496C19.3751 10.898 19.4544 10.898 19.5026 10.8496L20.5587 9.78867C20.6069 9.73867 20.6069 9.66055 20.5587 9.61211ZM19.7111 19.9355H9.75714C9.48185 19.9355 9.25944 20.159 9.25944 20.4355V20.8105C9.25944 20.8793 9.31543 20.9355 9.38387 20.9355H20.0843C20.1528 20.9355 20.2088 20.8793 20.2088 20.8105V20.4355C20.2088 20.159 19.9863 19.9355 19.7111 19.9355ZM14.2986 8.81055H15.1696C15.238 8.81055 15.294 8.7543 15.294 8.68555V7.18555C15.294 7.1168 15.238 7.06055 15.1696 7.06055H14.2986C14.2302 7.06055 14.1742 7.1168 14.1742 7.18555V8.68555C14.1742 8.7543 14.2302 8.81055 14.2986 8.81055Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_2165_779">
        <rect width="12" height="14" fill="white" transform="translate(8.73422 7)"/>
        </clipPath>
        </defs>
        </svg>

    )
}

export default YellowLightBulb;