import { Collapse, Skeleton } from 'antd';
import { MetricType } from '@pulppo/pulppo-models/build/enums/MetricType';
import { getMetricContent } from '../../utils/ConstantsMetrics';
import useMetricView from '../../hooks/useMetric/useMetricView';
import { useState } from 'react';
import useUser from '../../hooks/useUser';
import Button from '../Elements/Button';
import DrawerMetricChartTeam from './DrawerMetricChartTeam';

interface Props {
    type: MetricType;
    agentId?: string;
}

const Panel = Collapse.Panel;

const GenericMetricView = ({ type, agentId }: Props) => {
    const {
        state: { ContentHow, isLoading }
    } = useMetricView({ type, agentId });
    const content = getMetricContent(type);
    const user = useUser((u) => u.user);
    const [showDrawerChartTeam, setShowDrawerChartTeam] = useState(false);
    const statsView = user?.permissions?.stats?.view;

    return (
        <div className="flex h-full flex-col justify-between">
            <div className="flex-grow overflow-y-auto">
                <div className="bg-pulppo-status-light-disabled p-4 text-center">
                    <Skeleton
                        active={isLoading.initMetrics}
                        loading={isLoading.initMetrics}
                        title={{
                            className: 'mx-auto'
                        }}
                        paragraph={{
                            className: 'items-center flex flex-col'
                        }}
                    >
                        {ContentHow.Primary}
                    </Skeleton>
                </div>
                <Collapse
                    className="mt-4 border-none bg-white pb-12"
                    expandIconPosition="end"
                    defaultActiveKey={['measure']}
                >
                    <Panel
                        className="bg-white [&>div]:pl-0"
                        header={
                            <p>
                                <img
                                    src="/icons/calculator.svg"
                                    alt="calculator"
                                    className="mr-2 inline-block h-6 w-6"
                                />
                                ¿Qué mide esta métrica?
                            </p>
                        }
                        key="measure"
                    >
                        <ul className="list-disc space-y-2">
                            {content?.measure.description.map((item) => <li key={`measure-${item}`}>{item}</li>)}
                        </ul>
                    </Panel>
                    <Panel
                        className="bg-white [&>div]:pl-0"
                        header={
                            <p>
                                <img src="/icons/warning.svg" alt="important" className="mr-2 inline-block h-6 w-6" />
                                {content?.important.title}
                            </p>
                        }
                        key="important"
                    >
                        <ul className="list-disc space-y-2">
                            {content?.important.description.map((item) => <li key={`important-${item}`}>{item}</li>)}
                        </ul>
                    </Panel>
                    <Panel
                        className="bg-white [&>div]:pl-0"
                        header={
                            <p>
                                <img src="/icons/alarm.svg" alt="tips" className="mr-2 inline-block h-6 w-6" />
                                Consejos para mejorar esta métrica
                            </p>
                        }
                        key="tips"
                    >
                        <ul className="list-disc space-y-2">
                            {content?.tips.description.map((item) => <li key={`tips-${item}`}>{item}</li>)}
                        </ul>
                    </Panel>
                    <Panel
                        className="bg-white [&>div]:pl-0"
                        header={
                            <p>
                                <img src="/icons/stats.svg" alt="chart" className="mr-2 inline-block h-6 w-6" />
                                ¿Cómo viene evolucionando está métrica?
                            </p>
                        }
                        key="chart"
                    >
                        <Skeleton
                            active={isLoading?.allMetrics}
                            loading={isLoading?.allMetrics}
                            title={{
                                className: 'h-24'
                            }}
                            paragraph={false}
                        >
                            {ContentHow.Chart}
                        </Skeleton>
                    </Panel>
                </Collapse>
            </div>
            {statsView ? (
                <div className="flex items-end">
                    <Button
                        className="w-full"
                        type="primary"
                        onClick={() => {
                            setShowDrawerChartTeam(true);
                        }}
                    >
                        Ver equipo
                    </Button>
                </div>
            ) : null}
            <DrawerMetricChartTeam
                type={type}
                show={showDrawerChartTeam}
                onClose={() => setShowDrawerChartTeam(false)}
            />
        </div>
    );
};

export default GenericMetricView;
