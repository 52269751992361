import dayjs from 'dayjs';
import { MutableRefObject, ReactNode, forwardRef } from 'react';
import useUser from '../../hooks/useUser';

export const BadgeCard = forwardRef(function BadgeCardNoRef(
    {
        badge,
        showDetails = false,
        extra = null,
        border = true,
        onClick
    }: {
        showDetails?: boolean;
        extra?: ReactNode;
        onClick?: () => void;
        border?: boolean;
        badge: {
            imageColor?: string;
            id: string;
            title: string;
            image: string;
            description: string;
        };
    },
    ref: MutableRefObject<HTMLDivElement>
) {
    const badges = useUser((u) => u.user?.badges);
    return (
        <div
            ref={ref}
            onClick={onClick}
            className={`shrink-0 cursor-pointer ${border ? 'border' : ''} border-solid border-pulppo-secondary-gray-disabled p-2 ${
                badges?.[badge.id] ? '' : ''
            }`}
        >
            <div className="flex items-center justify-between gap-4">
                <img
                    alt={badge?.title}
                    className="h-[66px] w-[66px] shrink-0"
                    src={(badges?.[badge.id] && badge.imageColor) || badge.image}
                />
                {showDetails && (
                    <div className="mt-4">
                        <h5 className="text-sm font-semibold">{badge.title}</h5>
                        <ul className="ml-4">
                            <li className="list-disc pb-3 text-xs">{badge.description}</li>
                        </ul>
                    </div>
                )}
            </div>
            {badges?.[badge.id] && showDetails && (
                <>
                    <div className="h-px w-full w-full bg-pulppo-secondary-gray-disabled px-4"></div>

                    <div className="flex w-full items-center justify-end gap-2 px-2 py-1 text-sm">
                        Ganada {dayjs(badges?.[badge.id]).tz().format('DD/MM/YYYY')}
                    </div>
                </>
            )}
            {extra}
        </div>
    );
});
