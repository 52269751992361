import { IProperty } from '@pulppo/pulppo-models';
import { Form, Row, Col, Typography, Input, FormInstance } from 'antd';
import axios from '../../../utils/axios';
import { useState, useContext } from 'react';
import { TaskView } from '../../Elements/TaskView';
import { api_url } from '../../../helpers/fetcher';
import { CheckboxInput, PropertyManagement } from '../../PropertyDetail/PropertyManagement';
import { NoteEditor } from '../../Elements/NoteEditor';
import { PropertyListing } from '../../PropertyDetail/PropertyListing';
import { PulppoSelect } from '../../Elements/PulppoSelect';
import { AgentSelect } from '../../Elements/AgentSelect';
import { PulppoDrawer } from '../../Elements/PulppoDrawer';
import {
    CheckCircleFilled,
    CloseCircleFilled,
    CloseCircleOutlined,
    DollarCircleOutlined,
    DownOutlined,
    LineChartOutlined,
    SearchOutlined,
    WarningOutlined
} from '@ant-design/icons';
import { PropertyFormContext } from '../../../contexts/PropertyFormContext';
import { useTranslate } from '@tolgee/react';
import Button from '../../Elements/Button';
import { LoadingButton } from '../../Elements/ButtonLoading';
import { twMerge } from 'tailwind-merge';
import useUser from '../../../hooks/useUser';
import AvailabilitySelector from '../../Elements/availability-selector';
import { TagSelect } from '../../Elements/TagSelect';
import { formEventEmitter } from '../../../utils/FormEvents';

export const PromotionDrawer = ({ visible, onClose, property, setFormData }) => {
    const [showPhraseExamples, setShowPhraseExamples] = useState(false);
    const [form] = Form.useForm();
    const [phrases, setPhrases] = useState<Array<string>>(
        property?.development?.promotion?.phrases || new Array(3).fill('')
    );
    const [saving, setSaving] = useState(false);

    return (
        <>
            <PulppoDrawer
                title="Frases de ejemplo"
                visible={showPhraseExamples}
                onClose={() => {
                    setShowPhraseExamples(false);
                }}
            >
                <div className="mb-2 flex items-center gap-2">
                    <DollarCircleOutlined className="rounded-md bg-gray-100 px-2 py-2 text-xl" />
                    <p className="text-xl font-medium">Comercial</p>
                </div>
                <ul
                    className="px-8 text-lg text-pulppo-status-dark-disabled"
                    style={{
                        listStyleType: 'disc',
                        listStylePosition: 'inside'
                    }}
                >
                    <li>Preventa exclusiva</li>
                    <li>Precios de preventa</li>
                    <li>Últimas unidades</li>
                    <li>Rendimientos asegurados</li>
                    <li>Plusvalía a corto plazo</li>
                    <li>Desde $1,000,000 pesos</li>
                    <li>Inversión segura y confiable</li>
                    <li>Entrega Julio 2024</li>
                </ul>
                <div className="mb-2 mt-4 flex items-center gap-2">
                    <LineChartOutlined className="rounded-md bg-gray-100 px-2 py-2 text-xl" />
                    <p className="text-xl font-medium">Financiamiento</p>
                </div>
                <ul
                    className="px-8 text-lg text-pulppo-status-dark-disabled"
                    style={{
                        listStyleType: 'disc',
                        listStylePosition: 'inside'
                    }}
                >
                    <li>Opciones de Financiamiento</li>
                    <li>Hasta 60 mensualidades</li>
                    <li>Acepta Infonavit</li>
                    <li>Acepta créditos hipotecarios</li>
                </ul>
                <div className="mb-2 mt-4 flex items-center gap-2">
                    <LineChartOutlined className="rounded-md bg-gray-100 px-2 py-2 text-xl" />
                    <p className="text-xl font-medium">Características</p>
                </div>
                <ul
                    className="px-8 text-lg text-pulppo-status-dark-disabled"
                    style={{
                        listStyleType: 'disc',
                        listStylePosition: 'inside'
                    }}
                >
                    <li>Calidad Asegurada</li>
                    <li>Vista al mar</li>
                    <li>Exclentes amenidades</li>
                    <li>Ideal para tu familia</li>
                </ul>
            </PulppoDrawer>
            <PulppoDrawer title="Configurar promoción" visible={visible} onClose={onClose}>
                <Form
                    form={form}
                    className="h-full"
                    layout="vertical"
                    initialValues={{
                        title:
                            property?.development?.promotion?.title ||
                            `${property?.development?.name || ''} - ${property?.address?.neighborhood?.name || ''}`,
                        subtitle: property?.development?.promotion?.subtitle || `${property?.type} en venta`
                    }}
                    onFinish={(values) => {
                        setSaving(true);
                        axios
                            .patch(`${api_url}/property/${property?._id}`, {
                                'development.promotion': {
                                    title: values.title,
                                    subtitle: values.subtitle,
                                    phrases: phrases.filter((phrase) => phrase)
                                }
                            })
                            .then(() => {
                                setFormData((formData) => ({
                                    ...formData,
                                    development: {
                                        ...formData?.development,
                                        promotion: {
                                            title: values.title,
                                            subtitle: values.subtitle,
                                            phrases: phrases.filter((phrase) => phrase)
                                        }
                                    }
                                }));
                                onClose();
                            })
                            .catch(() => {})
                            .finally(() => setSaving(false));
                    }}
                >
                    <div className="flex h-full flex-col justify-between">
                        <div className="flex-1">
                            <Typography.Paragraph className="mb-1 text-lg font-bold">Titulo</Typography.Paragraph>
                            <Form.Item
                                rules={[
                                    {
                                        max: 25,
                                        required: true,
                                        message: 'Debes ingresar un titulo de menos de 25 caracteres'
                                    }
                                ]}
                                name="title"
                                label={
                                    <p className="whitespace-normal font-light text-pulppo-status-dark-disabled">
                                        Datos para la promoción. Máximo 25 caracteres.
                                    </p>
                                }
                            >
                                <Input max={25} />
                            </Form.Item>
                            <Typography.Paragraph className="mb-1 text-lg font-bold">Bajada</Typography.Paragraph>
                            <Form.Item
                                name="subtitle"
                                rules={[
                                    {
                                        max: 25,
                                        required: true,
                                        message: 'Debes ingresar una bajada de menos de 25 caracteres'
                                    }
                                ]}
                                label={
                                    <p className="whitespace-normal font-light text-pulppo-status-dark-disabled">
                                        Para poder ofrecer una promoción más personalizada, le pedimos que nos
                                        proporcione algunas frases que destaquen las ventajas de nuestro desarrollo.
                                        Máximo 25 caracteres.
                                    </p>
                                }
                            >
                                <Input max={25} />
                            </Form.Item>
                            <Typography.Paragraph className="mb-1 text-lg font-bold">Frases</Typography.Paragraph>
                            <p className="whitespace-normal font-light text-pulppo-status-dark-disabled">
                                Estas palabras se veran en la publicacion en distintas posiciones.
                                <a
                                    className="mx-2 font-medium text-secondary-color"
                                    onClick={() => {
                                        setShowPhraseExamples(true);
                                    }}
                                >
                                    Ver ejemplos
                                </a>
                            </p>

                            <div className="mt-2 flex flex-col items-center gap-2">
                                {phrases.map((_, index) => (
                                    <div className="flex w-full gap-2" key={`phrase_${index}`}>
                                        <Input
                                            placeholder={`Frase ${index + 1}`}
                                            value={phrases[index]}
                                            onInput={(ev) => {
                                                const aux = [...phrases];
                                                aux[index] = ev.currentTarget.value;
                                                setPhrases(aux);
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="mt-4 flex w-full justify-center">
                            <LoadingButton
                                loading={saving}
                                type="primary"
                                onClick={() => {
                                    form.submit();
                                }}
                                className="h-10 w-full px-4 font-medium"
                            >
                                Guardar
                            </LoadingButton>
                        </div>
                    </div>
                </Form>
            </PulppoDrawer>
        </>
    );
};

export const PropertyStep2 = ({ property }: { property: IProperty }) => {
    return (
        <div>
            <div className="bg-white @container md:bg-transparent">
                <Row>
                    <Col xs={24} md={12}>
                        <TaskView title="Operación" content={<PropertyListing />} />
                        <TaskView title="Promoción" content={<PromotionForm />} />
                    </Col>
                    <PropertyInternalInformation property={property} />
                </Row>
            </div>
        </div>
    );
};

export const PropertyInternalInformation = ({
    property,
    disabled,
    className
}: {
    property: Partial<IProperty>;
    disabled?: {
        contract?: boolean;
        notes?: boolean;
    };
    className?: {
        taskView?: string;
        title?: string;
    };
}) => {
    const { t } = useTranslate('common');
    const user = useUser((state) => state.user);
    const [tags, setTags] = useState(property?.tags?.map((t) => t?.label) || []);
    return (
        <div className="w-full @lg:w-1/2">
            <TaskView title="Información interna" className={className?.taskView} classNameTitle={className?.title}>
                {user?.features?.availability && (
                    <Form.Item className="form-item-one-column" name={['availability']}>
                        <AvailabilitySelector />
                    </Form.Item>
                )}
                <p className="mb-1">Etiquetas internas</p>
                <TagSelect
                    applyButtonText="Guardar"
                    onApply={async (values) => {
                        setTags(values);
                        formEventEmitter.emit('changes', { tags: values.map((v) => ({ label: v, group: '' })) });
                        // await axios
                        // .patch(`${api_url}/patch/${property._id}/tag`, { tags: values })
                        // .then(() => setTags(values));
                    }}
                    onClear={async () => {
                        setTags([]);
                        formEventEmitter.emit('changes', { tags: [] });
                        // await axios.patch(`${api_url}/patch/${property._id}/tag`, { tags: [] });
                    }}
                    value={(property?.tags || [])?.map((t) => t?.label)}
                    renderButton={(value) => (
                        <div className="mb-1 flex items-center justify-between border border-solid border-gray-300 bg-white px-2 py-2">
                            <div className="align-center flex items-center">
                                {!value?.length ? (
                                    <p className={`truncate text-gray-400`}>Agregar etiquetas internas</p>
                                ) : (
                                    <div className="flex flex-wrap items-center gap-2">
                                        {value?.slice(0, value?.length === 3 ? 3 : 2)?.map?.((val) => (
                                            <div
                                                key={val}
                                                className="flex h-6 items-center justify-center gap-2 bg-black px-2 text-sm text-white"
                                            >
                                                <p>{val}</p>
                                                <CloseCircleOutlined className="text-base" />
                                            </div>
                                        ))}
                                        {value?.length > 3 && (
                                            <div className="flex h-6 items-center justify-center gap-2 bg-black px-2 text-sm text-white">
                                                <p>{`+${value?.length - 2} etiquetas`}</p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            {value?.length ? (
                                <SearchOutlined className="text-lg text-gray-300" />
                            ) : (
                                <DownOutlined className="text-lg text-gray-300" />
                            )}
                        </div>
                    )}
                />

                {!!user?.features?.capture10 && (
                    <>
                        <Form.Item
                            className="form-item-one-column"
                            name={['capture', 'origin']}
                            label="Origen de la captación"
                            tooltip={
                                <p className="text-white">
                                    Indica si la propiedad ha sido captada por la inmobiliaria o por el asesor.
                                </p>
                            }
                        >
                            <PulppoSelect
                                allowSearch={false}
                                options={['Inmobiliaria', 'Productor'].map((val) => ({
                                    value: val,
                                    key: val,
                                    label: val
                                }))}
                                className="w-full py-2"
                            />
                        </Form.Item>
                    </>
                )}

                <Row gutter={10} className="mt-2">
                    <Col span={24}>
                        <Form.Item
                            className="form-item-one-column"
                            name={['legal', 'status']}
                            label="Detalles del estado legal del inmueble"
                            tooltip={
                                <p className="text-white">
                                    Aclaraciones necesarias para el equipo legal, y poder evaluar tiempos de entrega
                                </p>
                            }
                        >
                            <PulppoSelect
                                options={[
                                    'En Regularización',
                                    'Regularizado',
                                    'Hipotecado',
                                    'Remate Bancario',
                                    'Sucesión Testamentaria',
                                    'Otro'
                                ].map((val) => ({
                                    value: val,
                                    key: val,
                                    label: val
                                }))}
                                className="w-full py-2"
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24} className="mt-4">
                        <Form.Item name={['legal', 'description']}>
                            <Input.TextArea
                                className="rounded-none"
                                placeholder="Institución y estado de hipoteca, otras aclaraciones..."
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20} className="mt-4">
                    <Col flex="auto">
                        <Form.Item
                            label="Detalle de forma de pago"
                            tooltip="Informar créditos aceptados y otras formas de pago"
                            name={['financing', '0']}
                            className="form-item-one-column"
                        >
                            <Input.TextArea
                                className="rounded-none"
                                placeholder="Créditos aceptados y otras formas de pago..."
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-between" className="mt-4">
                    <Col span={24}>
                        <Form.Item
                            name={['keys', 'location']}
                            label="Ubicación de llaves del inmueble"
                            className="form-item-one-column"
                        >
                            <PulppoSelect
                                options={[
                                    {
                                        key: 'owner',
                                        value: 'Owner',
                                        label: 'Propietario'
                                    },
                                    {
                                        key: 'agent',
                                        value: 'Agent',
                                        label: 'Asesor'
                                    },
                                    {
                                        key: 'property',
                                        value: 'Property',
                                        label: 'En la propiedad'
                                    },
                                    {
                                        key: 'doorman',
                                        value: 'Doorman',
                                        label: 'Portero'
                                    }
                                ]}
                                className="w-full py-2"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="mt-2">
                        <Form.Item name={['keys', 'description']}>
                            <Input.TextArea
                                className="rounded-none"
                                placeholder="Aclaraciones de dónde se encuentran las llaves..."
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {!disabled?.notes ? (
                    <>
                        <Typography.Paragraph className="mb-0 mt-4 font-semibold">Notas internas</Typography.Paragraph>
                        <NoteEditor
                            className="[&>div]:pt-1"
                            isPublic
                            notes={property?.notes || []}
                            onNoteAdd={async (notes) => {
                                await axios.patch(`${api_url}/property/${property?._id}`, {
                                    notes
                                });
                            }}
                        />
                    </>
                ) : null}
            </TaskView>
            {!disabled?.contract ? (
                <TaskView title="Contrato de servicio">
                    <Typography.Paragraph className="font-light text-pulppo-status-dark-disabled">
                        {`Conforme a la información completada se generará un ${t('contrato de servicio digital')} para ser
                        firmado por el propietario`}
                    </Typography.Paragraph>
                    <PropertyManagement property={property} />
                </TaskView>
            ) : null}
        </div>
    );
};

export const PromotionForm = ({
    property,
    form,
    className,
    isDisabled
}: {
    property?: Partial<IProperty>;
    form?: FormInstance;
    className?: { title?: string };
    isDisabled?: boolean;
}) => {
    const [showPromotionDrawer, setShowPromotionDrawer] = useState(false);
    const user = useUser((state) => state.user);
    const propertyForm = useContext(PropertyFormContext);
    const { t } = useTranslate(['property_edit', 'common']);
    const { formData, setFormData } = property
        ? {
              formData: property,
              setFormData: (callBack: (property: Partial<IProperty>) => Partial<IProperty>) => {
                  const newProperty = callBack(property);
                  form.setFieldsValue(newProperty);
              }
          }
        : propertyForm || {};

    return (
        <>
            <PromotionDrawer
                visible={showPromotionDrawer}
                onClose={() => {
                    setShowPromotionDrawer(false);
                }}
                setFormData={setFormData}
                property={formData}
            />
            <div className="mb-10px justify-between">
                <h4 className={twMerge('mb-1 text-lg font-bold', className?.title)}>
                    {t('developments', 'Desarrollos')}
                </h4>
                <p className="mt-4 text-primary-black">
                    {t(
                        'development_campaign_description',
                        '¿Es un desarrollo? Configura la información para las campañas de promoción. Es obligatorio tener completado:'
                    )}
                </p>
                <ul className="mt-2">
                    <li className="flex items-center gap-2">
                        {formData?.development?.name ? (
                            <CheckCircleFilled className="text-strong-green" />
                        ) : (
                            <CloseCircleFilled className="text-strong-red" />
                        )}
                        <span>{t('development_title', 'Nombre el desarrollo')}</span>
                    </li>
                    <li className="flex items-center gap-2">
                        {formData?.attributes?.yearBuild <= 0 ? (
                            <CheckCircleFilled className="text-strong-green" />
                        ) : (
                            <CloseCircleFilled className="text-strong-red" />
                        )}
                        <span>Año de construcción: A estrenar o En construcción</span>
                    </li>
                    <li className="flex items-center gap-2">
                        {formData?.listing?.operation === 'sale' ? (
                            <CheckCircleFilled className="text-strong-green" />
                        ) : (
                            <CloseCircleFilled className="text-strong-red" />
                        )}
                        <span>Operacion: Venta</span>
                    </li>
                </ul>
                <div className="my-4 flex items-center gap-2">
                    <Button
                        onClick={() => {
                            setShowPromotionDrawer(true);
                        }}
                        disabled={
                            !formData?.development?.name ||
                            formData?.listing?.operation !== 'sale' ||
                            formData?.attributes?.yearBuild > 0 ||
                            isDisabled
                        }
                        type="primary"
                        className="w-full disabled:border disabled:border-solid disabled:border-gray-200 disabled:bg-white"
                    >
                        Configurar promoción
                    </Button>
                    {formData?.development?.promotion?.title && (
                        <CheckCircleFilled className="text-2xl text-strong-green" />
                    )}
                </div>
                <h4 className={twMerge('mb-4 text-lg font-bold', className?.title)}>Portales</h4>
                <Form.Item
                    className="[&>div>div>label]:h-full"
                    name={['listing', 'publishOnPortals']}
                    label={
                        <span className="whitespace-normal font-normal text-primary-black">
                            En caso que quiera podrá configurar que esta propiedad no se difunda en ningun medio
                            publico, solo estará disponible en la bolsa de propiedades de Pulppo.
                        </span>
                    }
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <CheckboxInput disabled={isDisabled}>Difundir públicamente</CheckboxInput>
                </Form.Item>
                <Form.Item name={['listing', 'addressIsRounded']}>
                    <CheckboxInput disabled={isDisabled}>Redondear número exterior</CheckboxInput>
                </Form.Item>
            </div>
            <div className="mb-10px mt-4 justify-between">
                <h4 className={twMerge('mb-4 text-lg font-bold', className?.title)}>Asignación de consultas</h4>
                <Form.Item
                    className="[&>div>div>label]:h-full"
                    name={['agentSeller']}
                    label={
                        <div className="whitespace-normal font-normal text-primary-black">
                            En caso que quiera podrá configurar que las consultas que genere esta propiedad se les
                            asigne a un asesor en especifico.
                        </div>
                    }
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <AgentSelect disabled={isDisabled} user={user} className="flex-1 md:w-auto" />
                </Form.Item>
                <div className="mt-2 flex items-center gap-x-2">
                    <WarningOutlined />
                    <p className="text-xs text-primary-black">
                        Consideración: En caso que el asesor seleccionado este no disponible, o con mucha carga de
                        trabajo, Pulppo decidirá quien atenderá al cliente
                    </p>
                </div>
            </div>
        </>
    );
};
