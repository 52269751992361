import { Attributes } from '../Property/Steps/step-1';
import { IProperty } from '@pulppo/pulppo-models';
import { PulppoMultiselect } from '../Elements/PulppoMultiselect';
import { Services } from '@pulppo/pulppo-models/build/enums/Services';
import { Form } from 'antd';
import { useTranslate } from '@tolgee/react';

interface Props {
    property: Partial<IProperty>;
    isDisabled?: boolean;
}

const CapturePropertyAttributes = ({ property, isDisabled }: Props) => {
    const { t } = useTranslate('common');
    return (
        <div>
            <Attributes isDisabled={isDisabled} property={property} />
            {property?.type ? (
                <>
                    <div className="space-y-2">
                        <Form.Item
                            className="form-item-one-column"
                            name={['attributes', 'services']}
                            label={t('amenities', 'Amenidades')}
                        >
                            <ServicesMultiSelect disabled={isDisabled} type={1} />
                        </Form.Item>
                        <Form.Item
                            className="form-item-one-column"
                            name={['attributes', 'services']}
                            label={t('spaces-of-services', 'Espacios')}
                        >
                            <ServicesMultiSelect disabled={isDisabled} type={2} />
                        </Form.Item>
                        <Form.Item
                            className="form-item-one-column"
                            name={['attributes', 'services']}
                            label={t('extras', 'Adicionales')}
                        >
                            <ServicesMultiSelect disabled={isDisabled} type={3} />
                        </Form.Item>
                    </div>
                </>
            ) : null}
        </div>
    );
};

interface Service {
    id: number;
    name: string;
    type: number;
}

const ServicesMultiSelect = ({
    type,
    value,
    onChange,
    disabled
}: {
    type: number;
    value?: Array<Service>;
    onChange?: (services: Array<Service>) => void;
    disabled?: boolean;
}) => {
    const services = Services.filter((service) => service.type === type);
    const { t } = useTranslate('common');

    return (
        <PulppoMultiselect
            disabled={disabled}
            value={services
                .filter((service) => value?.some((v) => v.id === service.id && v.type === service.type))
                .map((service) => `${service.id}`)}
            onChange={(servicesSelected) => {
                if (onChange) {
                    const prevValues = value || [];
                    onChange([
                        ...prevValues.filter(
                            (service) => !servicesSelected.includes(`${service.id}`) && service.type !== type
                        ),
                        ...services.filter((service) => servicesSelected.includes(`${service.id}`))
                    ]);
                }
            }}
            options={services.map((service) => ({
                key: `${service.id}`,
                label: `${t(service.name)}`,
                value: `${service.id}`
            }))}
            onClear={() => {
                if (onChange) {
                    const prevValues = value || [];
                    onChange(prevValues.filter((service) => service.type !== type));
                }
            }}
            showButtons
        />
    );
};

export default CapturePropertyAttributes;
