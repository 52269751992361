import dayjs from 'dayjs';
import { LoyaltyEvent } from './LoyaltyDrawer';
import useUser from '../../hooks/useUser';
import { EventOrder } from './EventDrawer';
import { CheckCircleOutlined, TeamOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { api_url, instance } from '../../helpers/fetcher';
import { LoadingButton } from '../Elements/ButtonLoading';

export const CalendarIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.1216 19.0687H7.44764C6.22364 19.0687 5.23364 18.0727 5.23364 16.8547V9.49269C5.23364 8.26869 6.22964 7.27869 7.44764 7.27869H18.1216C19.3456 7.27869 20.3356 8.27469 20.3356 9.49269V16.8547C20.3356 18.0787 19.3396 19.0687 18.1216 19.0687ZM7.44764 8.1727C6.72164 8.1727 6.13364 8.76068 6.13364 9.48668V16.8487C6.13364 17.5747 6.72164 18.1627 7.44764 18.1627H18.1216C18.8476 18.1627 19.4356 17.5747 19.4356 16.8487V9.48668C19.4356 8.76068 18.8476 8.1727 18.1216 8.1727H7.44764Z"
                fill="#26312D"
            />
            <path
                d="M10.2677 9.19875C10.0217 9.19875 9.81775 8.99475 9.81775 8.74875V6.05474C9.81775 5.80874 10.0217 5.60474 10.2677 5.60474C10.5137 5.60474 10.7177 5.80874 10.7177 6.05474V8.74875C10.7177 8.99475 10.5137 9.19875 10.2677 9.19875Z"
                fill="#26312D"
            />
            <path
                d="M15.6136 9.19875C15.3676 9.19875 15.1636 8.99475 15.1636 8.74875V6.05474C15.1636 5.80874 15.3676 5.60474 15.6136 5.60474C15.8596 5.60474 16.0636 5.80874 16.0636 6.05474V8.74875C16.0636 8.99475 15.8596 9.19875 15.6136 9.19875Z"
                fill="#26312D"
            />
            <path
                d="M18.1817 10.9327L7.21965 10.8847C6.96765 10.8847 6.76965 10.6807 6.76965 10.4347C6.76965 10.1887 6.97365 9.98474 7.21965 9.98474L18.1817 10.0327C18.4337 10.0327 18.6317 10.2367 18.6317 10.4827C18.6317 10.7287 18.4277 10.9327 18.1817 10.9327Z"
                fill="#26312D"
            />
        </svg>
    );
};

export const TimeIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.9678 19.7287C8.84581 19.7287 5.4978 16.3747 5.4978 12.2587C5.4978 8.14267 8.85181 4.7887 12.9678 4.7887C15.4518 4.7887 17.7678 6.01869 19.1658 8.08269C19.3038 8.28669 19.2498 8.56871 19.0458 8.70671C18.8418 8.84471 18.5598 8.79067 18.4218 8.58667C17.1978 6.77467 15.1578 5.6887 12.9738 5.6887C9.34982 5.6887 6.40381 8.63467 6.40381 12.2587C6.40381 15.8827 9.34982 18.8287 12.9738 18.8287C15.4818 18.8287 17.8098 17.3647 18.9018 15.1027C19.0098 14.8807 19.2798 14.7847 19.5018 14.8927C19.7238 15.0007 19.8198 15.2707 19.7118 15.4927C18.4758 18.0667 15.8298 19.7287 12.9738 19.7287H12.9678Z"
                fill="#1D1D1B"
            />
            <path
                d="M15.3499 13.8247H12.4399C12.1939 13.8247 11.9899 13.6207 11.9899 13.3747V8.29863C11.9899 8.05263 12.1939 7.84863 12.4399 7.84863C12.6859 7.84863 12.8899 8.05263 12.8899 8.29863V12.9247H15.3499C15.5959 12.9247 15.7999 13.1287 15.7999 13.3747C15.7999 13.6207 15.5959 13.8247 15.3499 13.8247Z"
                fill="#1D1D1B"
            />
        </svg>
    );
};

export const EventCard = ({
    event: initialEvent,
    showDetails,
    onClick,
    onEventChange
}: {
    event: LoyaltyEvent & { disabled?: boolean };
    showDetails?: boolean;
    onClick?: () => void;
    onEventChange: (event: LoyaltyEvent) => void;
}) => {
    const level = useUser((u) => u.user?.level);
    const [event, setEvent] = useState(initialEvent);
    const uid = useUser((u) => u.user?.uid);
    const allowed = EventOrder[level || 'unaffiliated'] >= EventOrder[event.minLevel || 'unaffiliated'];
    const available = event.seats > event.participants?.length;
    const registered = !!event.participants?.find((p) => p.uid === uid);
    // const
    return (
        <div
            onClick={onClick}
            className={`shrink-0 cursor-pointer border border-solid border-pulppo-secondary-gray-disabled p-2 pr-4`}
        >
            <div className={`flex items-start gap-4`}>
                <img
                    className={`h-14 w-14 rounded-full object-cover ${allowed ? '' : 'opacity-30'}`}
                    src={event.photo}
                />

                <div className="flex-1">
                    <div className="items-left flex flex-col flex-wrap justify-between gap-2">
                        <div>
                            <h5 className="pb-2 font-medium">{event.title}</h5>
                            <div className="flex items-center gap-2 text-sm">
                                <CalendarIcon />
                                <p>{dayjs(event.date).tz().format('DD/MM/YYYY')}</p>
                                <div className="h-4 w-px bg-black" />
                                <TimeIcon />
                                <p>
                                    {dayjs(event.date).tz().format('HH')}
                                    hs.
                                </p>
                                <div className="h-4 w-px bg-black" />
                                <TeamOutlined />
                                <p>{event?.seats}</p>
                            </div>
                        </div>
                    </div>

                    {!available && !registered ? (
                        <div className="bg-pulppo-status-light-disabled py-1 text-center text-xs">
                            <p>Agotado</p>
                        </div>
                    ) : allowed || registered ? (
                        <></>
                    ) : (
                        <div className="bg-pulppo-status-light-disabled py-1 text-center text-xs">
                            <p>Aún no está disponible para tu nivel</p>
                        </div>
                    )}
                </div>
            </div>
            {showDetails && ((allowed && available) || registered) && (
                <>
                    {registered ? (
                        <>
                            <div
                                className={`mx-auto mt-2 flex w-full justify-center bg-pulppo-status-light-success py-px text-sm text-pulppo-status-dark-success`}
                            >
                                <CheckCircleOutlined className="mr-2 text-pulppo-status-dark-success" /> Registrado
                            </div>
                            <LoadingButton
                                type="text"
                                onClick={async () => {
                                    const newEvent = (
                                        await instance.patch(`${api_url}/loyalty/events/${event._id}/participant`)
                                    ).data;
                                    setEvent(newEvent);
                                    onEventChange(newEvent);
                                }}
                                className="mt-2 w-full cursor-pointer text-center text-xs"
                            >
                                <span className="underline">No puedo ir</span>
                            </LoadingButton>
                        </>
                    ) : (
                        <LoadingButton
                            onClick={async () => {
                                // setRegistered((r) => !r)
                                const newEvent = (
                                    await instance.patch(`${api_url}/loyalty/events/${event._id}/participant`)
                                ).data;
                                setEvent(newEvent);
                                onEventChange(newEvent);
                            }}
                            className={`my-2 h-auto w-full py-2 text-sm transition-all duration-300 ${registered ? 'bg-pulppo-primary-black text-white' : 'bg-white text-pulppo-primary-black'}`}
                        >
                            Registrarme
                        </LoadingButton>
                    )}
                </>
            )}
        </div>
    );
};
