import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { useRouter } from 'next/router';
import { LoyaltyBadges } from './badges';
import { BadgeCard } from './BadgeCard';
import { useEffect, useRef } from 'react';

export const BadgesDrawer = () => {
    const router = useRouter();

    const badgeRef = useRef<HTMLDivElement>();

    useEffect(() => {
        badgeRef?.current?.scrollIntoView();
    }, [router.query.badge]);

    return (
        <PulppoDrawer
            title="Insignias"
            visible={router.query.badges === 'true'}
            onClose={() => {
                delete router.query.badges;
                delete router.query.badge;
                router.push(router);
            }}
        >
            <div className="flex h-full w-full flex-col gap-2">
                {LoyaltyBadges.map((badge) => (
                    <BadgeCard
                        ref={badge.id == router.query.badge ? badgeRef : null}
                        showDetails={true}
                        badge={badge}
                        key={badge.title}
                    />
                ))}
            </div>
        </PulppoDrawer>
    );
};
