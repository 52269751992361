import { PulppoDrawer } from '../Elements/PulppoDrawer';
import MetricBarChart from './MetricBarChart';
import { IAgent } from '@pulppo/pulppo-models';
import useMetricDrawer from '../../hooks/useMetric/useMetricDrawer';
import useMetricAgents from '../../hooks/useMetric/useMetricAgents';
import useMetric from '../../hooks/useMetric/useMetric';
import AgentsList, { BasicColor } from './AgentsList';
import { ResponseMetric } from '../../services/metricService';
import { MetricType } from '@pulppo/pulppo-models/build/enums/MetricType';
import dayjs from 'dayjs';
import { twMerge } from 'tailwind-merge';
import { METRICS_DESCRIPTION } from '../../utils/constants';
import useUser from '../../hooks/useUser';
import Badge from '../Elements/Badge';
import { ColorBadge } from '../../utils/color';

interface Props {
    show: boolean;
    onClose: () => void;
}

const metricsNeed = [MetricType.TasksPending];

const AgentMetric = ({
    agent
}: {
    agent: IAgent & {
        metrics: ResponseMetric;
    };
}) => {
    const {
        state: { data, isLoading }
    } = useMetric({
        currentWeek: false,
        limit: 11,
        select: metricsNeed,
        agentId: `${agent._id}`
    });
    const {
        state: { metrics, differenceBeforeLastWeek }
    } = useMetricDrawer({
        data: data.map((metric) => {
            return {
                id: metric.week,
                value: {
                    y: Math.floor(metric.data.tasksPending) || 0,
                    x: dayjs(new Date(metric.week)).tz().toISOString()
                },
                status: metric.data.tasksPending !== 0 ? BasicColor.Wrong : BasicColor.Good
            };
        }),
        mostRecentData: {
            id: dayjs(new Date()).tz().toISOString(),
            value: {
                y: agent.metrics.data.tasksPending || 0,
                x: dayjs(new Date()).tz().toISOString()
            },
            status: agent.metrics.data.tasksPending !== 0 ? BasicColor.Wrong : BasicColor.Good
        }
    });

    if (isLoading) return null;

    return (
        <MetricBarChart
            data={metrics}
            extra={{
                label: (
                    <>
                        Se realizaron{' '}
                        <span
                            className={twMerge(
                                'text-sm font-bold',
                                differenceBeforeLastWeek > 0
                                    ? 'text-pulppo-status-dark-error'
                                    : 'text-pulppo-status-dark-success'
                            )}
                        >
                            {Math.abs(differenceBeforeLastWeek)}
                        </span>{' '}
                        {differenceBeforeLastWeek > 0 ? 'menos' : 'más'} que la semana anterior
                    </>
                )
            }}
        />
    );
};

const MetricTaskPending = () => {
    const user = useUser((u) => u.user);
    const statsView = user?.showAll;
    const {
        state: { data, isLoading }
    } = useMetric({
        currentWeek: false,
        limit: 12,
        select: metricsNeed
    });

    const {
        state: { differenceBeforeLastWeek, lastWeekValue, metrics }
    } = useMetricDrawer({
        data: data.map((metric) => {
            return {
                id: metric.week,
                value: {
                    y: Math.floor(metric.data.tasksPending),
                    x: dayjs(new Date(metric.week)).tz().toISOString()
                },
                status: metric.data.tasksPending !== 0 ? BasicColor.Wrong : BasicColor.Good
            };
        })
    });
    const {
        state: { agents, isLoading: isLoadingAgents }
    } = useMetricAgents({
        select: metricsNeed,
        currentWeek: true
    });

    if (isLoading) return null;

    return (
        <>
            <section className="mb-8 border-[0.5px] border-solid border-pulppo-secondary-gray-disabled p-4 text-base">
                <div className="flex items-center justify-between">
                    <h4 className="text-sm">
                        {statsView ? 'Datos generales de todos los asesores' : 'Tus datos generales'}
                    </h4>
                    <Badge
                        color={lastWeekValue > 0 ? ColorBadge.Red : ColorBadge.Green}
                        value={`${lastWeekValue} pendientes`}
                    />
                </div>
                <div className="mt-4">
                    <MetricBarChart
                        extra={{
                            label: (
                                <>
                                    Se realizaron{' '}
                                    <span
                                        className={twMerge(
                                            'text-sm font-bold',
                                            differenceBeforeLastWeek > 0
                                                ? 'text-pulppo-status-dark-error'
                                                : 'text-pulppo-status-dark-success'
                                        )}
                                    >
                                        {Math.abs(differenceBeforeLastWeek)}
                                    </span>{' '}
                                    {differenceBeforeLastWeek > 0 ? 'menos' : 'más'} que la semana anterior
                                </>
                            ),
                            tooltip: METRICS_DESCRIPTION[MetricType.TasksPending].description
                        }}
                        data={metrics}
                    />
                </div>
            </section>
            {statsView ? (
                <AgentsList
                    agents={agents}
                    isLoading={isLoadingAgents}
                    actions={{
                        sort: (a, b) => (a.metrics.data.tasksPending || 0) - (b.metrics.data.tasksPending || 0),
                        tag: {
                            condition: (agent) =>
                                (agent?.metrics?.data?.tasksPending || 0) === 0 ? BasicColor.Good : BasicColor.Wrong,
                            text: {
                                success: 'Completadas',
                                error: (agent) => `${agent?.metrics.data.tasksPending || 0} pendientes`
                            }
                        }
                    }}
                >
                    {(agent) => <AgentMetric agent={agent} />}
                </AgentsList>
            ) : null}
        </>
    );
};

const DrawerMetricTaskPending = ({ show, onClose }: Props) => {
    return (
        <PulppoDrawer visible={show} onClose={onClose} title={METRICS_DESCRIPTION[MetricType.TasksPending].title}>
            <MetricTaskPending />
        </PulppoDrawer>
    );
};

export default DrawerMetricTaskPending;
