import dayjs, { Dayjs } from 'dayjs';

const fieldsToIgnore = {
    agentSeller: true,
    agent: true,
    pictures: true,
    company: true,
    services: true,
    availability: true,
    tags: true
};
export const toPlain = (obj: any, prev: string) => {
    /**
     * si el objeto es {hola: {mundo: "mun"},test: "t"}
     * tiene que devolver {"hola.mundo": "mun", "test": "t"}
     */
    let result = {};
    for (const key in obj) {
        if (key in fieldsToIgnore) {
            result[prev ? `${prev}.${key}` : key] = obj[key];
        } else if (dayjs.isDayjs(obj[key]) || obj[key] instanceof Date) {
            result[prev ? `${prev}.${key}` : key] = (obj[key] as Dayjs | Date).toISOString();
        } else if (typeof obj[key] != 'object' || !obj[key]) {
            result[prev ? `${prev}.${key}` : key] = obj[key];
        } else if (Object.keys(obj[key]).length == 0) {
            result = {
                ...result,
                [prev ? `${prev}.${key}` : key]: Array.isArray(obj[key]) ? [] : {}
            };
        } else {
            result = {
                ...result,
                ...toPlain(obj[key], prev ? `${prev}.${key}` : key)
            };
        }
    }
    return result;
};

export const toComplex = (object, initializer = {}) => {
    const result = initializer;
    for (const key in object) {
        if (key.includes('.')) {
            const parts = key.split('.');
            if (!result[parts[0]]) {
                result[parts[0]] = {};
            }
            result[parts[0]] = toComplex(
                {
                    [parts.slice(1).join('.')]: object[key]
                },
                result[parts[0]]
            );
        } else {
            result[key] = object[key];
        }
    }
    return result;
};
