import {
    AlertFilled,
    CheckCircleFilled,
    CloseCircleFilled,
    InfoCircleFilled,
    QuestionCircleFilled
} from '@ant-design/icons';
import { IProperty } from '@pulppo/pulppo-models';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

interface Props {
    checking: boolean;
    property: IProperty;
    permission: boolean;
}

const STATE_MAPPER = {
    available: {
        className: 'bg-notification-green text-strong-green',
        icon: <CheckCircleFilled className="text-3xl" />,
        text: () => <p>Propiedad disponible</p>
    },
    not_available: {
        className: 'bg-notification-red text-strong-red',
        icon: <CloseCircleFilled className="text-3xl" />,
        text: () => <p>Propiedad no disponible</p>
    },
    checking: {
        className: 'bg-notification-yellow text-strong-yellow',
        icon: <InfoCircleFilled className="text-3xl" />,
        text: () => <p>Estamos verificando la disponibilidad</p>
    },
    not_published: {
        className: 'bg-notification-red text-strong-red',
        icon: <AlertFilled className="text-3xl" />,
        text: (property) => (
            <span>
                Propiedad no difundida en portales.{' '}
                <a
                    className="font-medium underline"
                    href={`/listing/${property?._id}/step-2`}
                    target="_blank"
                    rel="noreferrer"
                >
                    Ingresa aquí{' '}
                </a>
                para editarla y comenzar a difundirla
            </span>
        )
    },
    unknown: {
        className: 'bg-status-light-disabled text-status-dark-disabled',
        icon: <QuestionCircleFilled className="text-3xl" />,
        text: (property) => (
            <span>
                No pudimos verificar la disponibilidad.
                <a className="font-medium underline" href={property?.import?.url} target="_blank" rel="noreferrer">
                    {' '}
                    Haz click aqui{' '}
                </a>
                para verla en el portal.
            </span>
        )
    }
};

const PropertyDetailTitle = ({ checking, property, permission }: Props) => {
    const [state, setState] = useState<'available' | 'not_available' | 'checking' | 'not_published' | 'unknown'>(null);

    useEffect(() => {
        if (property?.mls) {
            if (checking) setState('checking');
            else if (['published'].includes(property?.status?.last)) {
                if (dayjs(property.updatedAt).isBefore(dayjs().subtract(24, 'hours'))) {
                    setState('unknown');
                } else {
                    setState('available');
                }
            } else setState('not_available');
        } else if (property.status?.last === 'published' && permission && !property?.listing?.publishOnPortals)
            setState('not_published');
    }, [checking, property]);

    if (!state) return null;

    return (
        <span
            className={`flex w-auto items-center gap-3 p-2 text-base font-normal transition ease-in-out ${STATE_MAPPER[state]?.className}`}
        >
            {STATE_MAPPER[state].icon}
            <span>{STATE_MAPPER[state].text(property)}</span>
        </span>
    );
};

export default PropertyDetailTitle;
