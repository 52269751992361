import { IProperty, ISimpleUser, ITask } from '@pulppo/pulppo-models';
import { Button, Input, Select } from 'antd';
import dayjs from 'dayjs';
import { AgentSelect } from './AgentSelect';
import { ContactSelect } from './ContactSelect';
import PropertySelect from './PropertySelect';
import CalendarInput from './CalendarInput';
import { TaskType } from '../../types';
import { useEffect } from 'react';

export const taskTypes = (type: TaskType) => {
    return type === 'search'
        ? [
              {
                  value: 'Contactar cliente',
                  label: 'Contactar cliente'
              },
              {
                  value: 'Organizar visita',
                  label: 'Organizar visita'
              },
              {
                  value: 'Organizar búsqueda',
                  label: 'Organizar búsqueda'
              },
              {
                  value: 'Pedir devolución de una visita',
                  label: 'Pedir devolución de una visita'
              }
          ]
        : [
              {
                  value: 'Contactar propietario',
                  label: 'Contactar propietario'
              },
              {
                  value: 'Organizar visita',
                  label: 'Organizar visita'
              },
              {
                  value: 'Organizar captación',
                  label: 'Organizar captación'
              },
              {
                  value: 'Pedir devolución de una visita',
                  label: 'Pedir devolución de una visita'
              }
          ];
};

const NewTask = ({
    user,
    task,
    setTask,
    properties,
    type
}: {
    user: ISimpleUser;
    task: Partial<ITask>;
    setTask: (task: Partial<ITask> | ((task: Partial<ITask>) => Partial<ITask>)) => void;
    properties?: Array<IProperty>;
    type: TaskType;
}) => {
    useEffect(() => {
        if (type === 'capture' && properties?.length) {
            setTask((task) => ({
                ...task,
                metadata: { ...task.metadata, property: properties?.[0] }
            }));
        }
    }, [type]);

    return (
        <div className="flex h-full flex-col gap-4 pt-6">
            <div>
                <p className="font-medium">Tipo de tarea</p>
                <Select
                    className="mt-2 w-full rounded-none"
                    onSelect={(value) => {
                        setTask((task) => ({ ...task, name: value }));
                    }}
                    value={task.name}
                    options={taskTypes(type)}
                    placeholder="Selecciona la tarea a realizar"
                />
            </div>
            <div>
                <p className="font-medium">Descripcion de la tarea</p>
                <Input.TextArea
                    className="mt-2 w-full rounded-none"
                    rows={4}
                    placeholder="Describe la tarea aqui..."
                    value={task.message}
                    onChange={(ev) =>
                        setTask((task) => ({
                            ...task,
                            message: ev.currentTarget.value
                        }))
                    }
                />
            </div>
            <div>
                <p className="font-medium">Recordarme en:</p>
                <div className="mt-2 flex w-full gap-2">
                    <Button
                        className={`h-10 px-4 font-medium ${
                            dayjs(task.dueDate).tz().isSame(dayjs().tz().add(1, 'day'), 'day')
                                ? 'bg-black text-white'
                                : ''
                        }`}
                        type="default"
                        onClick={() => {
                            setTask((task) => ({
                                ...task,
                                dueDate: dayjs().tz().add(1, 'day').toDate()
                            }));
                        }}
                    >
                        1 día
                    </Button>
                    <Button
                        className={`h-10 px-4 font-medium ${
                            dayjs(task.dueDate).tz().isSame(dayjs().tz().add(7, 'day'), 'day')
                                ? 'bg-black text-white'
                                : ''
                        }`}
                        type={'default'}
                        onClick={() => {
                            setTask((task) => ({
                                ...task,
                                dueDate: dayjs().tz().add(7, 'day').toDate()
                            }));
                        }}
                    >
                        7 días
                    </Button>
                    <Button
                        className={`h-10 px-4 font-medium ${
                            dayjs(task.dueDate).tz().isSame(dayjs().tz().add(15, 'day'), 'day')
                                ? 'bg-black text-white'
                                : ''
                        }`}
                        type="default"
                        onClick={() => {
                            setTask((task) => ({
                                ...task,
                                dueDate: dayjs().tz().add(15, 'day').toDate()
                            }));
                        }}
                    >
                        15 días
                    </Button>
                    <Button
                        className={`h-10 px-4 font-medium ${
                            dayjs(task.dueDate).tz().isSame(dayjs().tz().add(30, 'day'), 'day')
                                ? 'bg-black text-white'
                                : ''
                        }`}
                        type="default"
                        onClick={() => {
                            setTask((task) => ({
                                ...task,
                                dueDate: dayjs().tz().add(30, 'day').toDate()
                            }));
                        }}
                    >
                        1 mes
                    </Button>
                    <CalendarInput
                        value={dayjs(task.dueDate).tz()}
                        onChange={(value) => setTask((task) => ({ ...task, dueDate: value?.tz()?.toDate() }))}
                        iconPosition="left"
                        className={{
                            calendar: 'left-auto right-10 w-96'
                        }}
                    />
                </div>
            </div>
            <div>
                <p className="font-medium">Asignar a</p>
                <AgentSelect
                    className="mt-2"
                    user={user}
                    value={task?.agent}
                    onChange={(agent) => {
                        setTask((task) => ({ ...task, agent }));
                    }}
                />
            </div>
            <div>
                <p className="mb-2 font-medium">Vincular a contacto</p>
                <ContactSelect
                    value={task.metadata.contact}
                    onSelect={(contact) => {
                        setTask((task) => ({
                            ...task,
                            metadata: {
                                ...task.metadata,
                                contact: {
                                    _id: contact._id,
                                    fistName: contact.firstName,
                                    lastName: contact.lastName,
                                    phone: contact.phone,
                                    email: contact.email
                                }
                            }
                        }));
                    }}
                    user={user}
                />
            </div>
            {type === 'search' ? (
                <div>
                    <p className="mb-2 font-medium">Vincular a propiedad</p>
                    <PropertySelect
                        value={task.metadata?.property || null}
                        onSelect={(property) =>
                            setTask((task) => ({
                                ...task,
                                metadata: { ...task.metadata, property }
                            }))
                        }
                        properties={properties || []}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default NewTask;
