import { RightOutlined } from '@ant-design/icons';
import formatNumber from '../../utils/numberFormatter';
import { twMerge } from 'tailwind-merge';
import { useMemo } from 'react';

export const EmptySearchCard = ({
    count,
    filters,
    setFilters,
    title,
    description = '',
    extra = '',
    footer = null,
    hoverable = true
}) => {
    const onClick = (opt) => {
        const filter = {} as any;
        if (opt === 'pulppo') {
            filter.tab = 'pulppo';
        }
        if (opt === 'mls') filter.tab = 'mls';
        if (opt === 'not-available') {
            filter.tab = 'company';
            filter.status = ['cancelled', 'completed'];
        }
        setFilters((filters) => ({ ...filters, ...filter }));
    };

    const appliedFilterCount = useMemo(() => {
        let total = 0;
        for (const key in filters) {
            if (typeof filters[key] === 'undefined') continue;
            else if (typeof filters[key] === 'number') total++;
            else if (Array.isArray(filters[key])) total += filters[key].length;
            else if (typeof filters[key] === 'boolean') total++;
            else if (typeof filters[key] === 'string' && key && key !== 'tab') total++;
            else continue;
        }
        return total;
    }, [filters]);

    return (
        <div className={`flex h-full flex-col bg-white px-6 py-4 ${hoverable ? 'hover:shadow-md' : ''}`}>
            <div
                className={twMerge(
                    'flex h-1/2 w-full flex-col items-center justify-center gap-2',
                    title && description && extra && 'h-auto'
                )}
            >
                <img src="/icons/houseSearch.svg" alt="empty" />
                <p className="font-bold">{title}</p>
                <p className="text-center text-pulppo-status-dark-disabled">{description}</p>
                <p className="text-center text-pulppo-status-dark-disabled">{extra}</p>
            </div>
            {footer ? (
                footer
            ) : (
                <div className="flex flex-1 flex-col justify-center gap-4">
                    {['user', 'company', 'exclusive'].includes(filters?.tab) && count?.pulppo ? (
                        <div
                            className="flex w-full cursor-pointer items-center justify-between border-b border-solid border-gray-200 py-2 hover:underline"
                            onClick={() => onClick('pulppo')}
                        >
                            <div>
                                <p className="text-lg">{formatNumber(count?.pulppo)}</p>
                                <p>Propiedades {process.env.NEXT_PUBLIC_APP_NAME}</p>
                            </div>
                            <div>
                                <RightOutlined className="text-xl" />
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    {filters?.tab !== 'mls' && count?.mls ? (
                        <div
                            className="flex w-full cursor-pointer items-center justify-between border-b border-solid border-gray-200 py-2 hover:underline"
                            onClick={() => onClick('mls')}
                        >
                            <div>
                                <p className="text-lg">{formatNumber(count?.mls)}</p>
                                <p>Propiedades MLS</p>
                            </div>
                            <div>
                                <RightOutlined className="text-xl" />
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    {['user', 'company'].includes(filters?.tab) && count?.notAvailable ? (
                        <div
                            className="flex w-full cursor-pointer items-center justify-between py-2 hover:underline"
                            onClick={() => onClick('not-available')}
                        >
                            <div>
                                <p className="text-lg">{formatNumber(count?.notAvailable)}</p>
                                <p>Propiedades no disponibles</p>
                            </div>
                            <div>
                                <RightOutlined className="text-xl" />
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    {appliedFilterCount > 0 && (
                        <div
                            className="flex w-full cursor-pointer items-center justify-between py-2 hover:underline"
                            onClick={() => {
                                setFilters((f) => ({ tab: f.tab }));
                            }}
                        >
                            <div>
                                <p className="text-lg">{appliedFilterCount} filtros aplicados</p>
                                <p>Borrar filtros</p>
                            </div>
                            <div>
                                <RightOutlined className="text-xl" />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
