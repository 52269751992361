import { IProperty, ISimpleProperty } from '@pulppo/pulppo-models';
import Avatar from '../Elements/Avatar';
import { showProperty } from '../../utils/showProperty';
import { twMerge } from 'tailwind-merge';
import { parsePrice } from '../../utils/parser';
import { ReactNode } from 'react';
import useSWR from 'swr';
import { api_url, fetcher } from '../../helpers/fetcher';

export interface AvatarPropertyProps {
    property: ISimpleProperty | IProperty;
    tag?: 'isMLS' | ReactNode;
    className?: {
        image?: string;
        action?: string;
        label?: string;
        description?: string;
    };
    disabled?: boolean;
    style?: {
        disableTruncate?: boolean;
    };
    stopPropagation?: boolean;
    description?: 'neighborhood' | 'price';
    size?: 'small' | 'medium' | 'large';
}

export const AvatarProperty = ({
    property,
    className,
    disabled,
    style,
    stopPropagation,
    description = 'neighborhood',
    tag,
    size
}: AvatarPropertyProps) => {
    const image = property?.pictures?.[0];
    const address = property?.address;
    const price = property?.listing?.price;

    const { data: mlsData } = useSWR(
        property && !(property as any)?.mls ? `${api_url}/property/mls/${property?._id}` : null,
        fetcher
    );

    return (
        <Avatar
            img={{
                src: image?.url,
                alt: image?.description,
                className: twMerge('rounded', className?.image)
            }}
            title={address?.street}
            description={
                description === 'neighborhood'
                    ? address?.neighborhood?.name
                    : price
                      ? parsePrice({
                            price: price?.price,
                            startStr: `${price?.currency || process.env.NEXT_PUBLIC_PRIMARY_CURRENCY} `
                        })
                      : 'Sin precio'
            }
            tag={tag === 'isMLS' ? ((property as any)?.mls || mlsData?.isMLS ? 'MLS' : 'Pulppo') : tag || undefined}
            onClick={
                disabled
                    ? undefined
                    : () => {
                          showProperty({ property });
                      }
            }
            stopPropagation={stopPropagation}
            className={{
                action: twMerge('w-full', className?.action),
                title: twMerge(style?.disableTruncate && 'whitespace-normal', className?.label),
                description: twMerge(style?.disableTruncate && 'whitespace-normal', className?.description)
            }}
            size={size}
        />
    );
};

export default AvatarProperty;
