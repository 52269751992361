import { Button, Progress } from 'antd';
import { NextImage } from './NextImage';
import { PulppoDrawer } from './PulppoDrawer';
import { PulppoSelect } from './PulppoSelect';
import { AgentSelect } from './AgentSelect';
import { DevelopmentInput } from '../PropertyDetail/PropertyLocation';
import { PropertyStatusMapper } from '../../utils/status';
import { DeletePropertyReason } from '../PropertyDetail/PropertyDetail';
import { CheckboxInput } from '../PropertyDetail/PropertyManagement';
import axios from '../../utils/axios';
import { toPlain } from '../../utils/toPlain';
import { api_url } from '../../helpers/fetcher';
import { useState } from 'react';
import { PriceInput } from '../PropertyDetail/PropertyListing';
import { IAgent } from '@pulppo/pulppo-models';

interface Changes {
    currency?: string;
    price?: number;
    agentSeller?: IAgent;
    agent?: IAgent;
    development?: any;
    deleteReason?: string;
    status?: string;
    addressIsRounded?: boolean;
    publish?: boolean;
}

export const PropertyEditMultiple = ({
    open,
    onClose,
    selectedProperties,
    user,
    setSelectedProperties,
    setFilters,
    setIsAllSelected
}) => {
    const [editMultiple, setEditMultiple] = useState<{
        step: number;
        progress?: number;
    }>({ step: 0 });
    const [changes, setChanges] = useState<Changes>({});

    return (
        <PulppoDrawer
            title={
                editMultiple?.step === 0
                    ? 'Editar propiedades'
                    : editMultiple?.step === 1
                      ? 'Atributos a cambiar'
                      : 'Confirmar cambios'
            }
            visible={open}
            onClose={() => {
                onClose();
                setEditMultiple({ step: 0 });
                setChanges({});
            }}
        >
            <div className="flex h-full flex-col">
                {editMultiple?.step === 0 && (
                    <div className="flex w-full flex-1 flex-col gap-4 overflow-y-auto overflow-x-hidden">
                        {selectedProperties?.map((prop) => (
                            <div className="flex gap-2" key={prop._id}>
                                <div className="w-24 shrink-0">
                                    <NextImage
                                        className="h-24 w-24 shrink-0 rounded-lg object-cover"
                                        width={128}
                                        height={128}
                                        alt={prop?.address?.street}
                                        showPreview={false}
                                        src={prop?.pictures?.[0]?.url}
                                    />
                                </div>
                                <div className="">
                                    <h5 className="truncate text-lg font-medium">{prop?.address?.street}</h5>
                                    <p className="truncate">
                                        {[
                                            prop?.address?.neighborhood?.name,
                                            prop?.address?.city?.name,
                                            prop?.address?.state?.name
                                        ]
                                            .filter(Boolean)
                                            .join(', ')}
                                    </p>
                                    <h3 className="text-lg font-semibold">
                                        {prop?.listing?.price?.currency} {prop?.listing?.price?.price}
                                    </h3>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {editMultiple?.step === 1 && (
                    <div className="flex flex-1 flex-col gap-4">
                        <div>
                            <p className="mb-2 font-medium">Precio</p>
                            <div className="flex items-center gap-2">
                                <PulppoSelect
                                    allowSearch={false}
                                    value={changes?.currency || process.env.NEXT_PUBLIC_PRIMARY_CURRENCY}
                                    className="py-2"
                                    onChange={(value) =>
                                        setChanges((e) => ({
                                            ...e,
                                            currency: value
                                        }))
                                    }
                                    options={process.env.NEXT_PUBLIC_CURRENCIES.split(',').map((currency) => ({
                                        key: currency,
                                        label: currency,
                                        value: currency
                                    }))}
                                />
                                <PriceInput
                                    // formatter={(value) =>
                                    //     parsePrice({
                                    //         price: value,
                                    //         withMathRound: false,
                                    //         startStr: '$'
                                    //     })
                                    // }
                                    value={changes?.price}
                                    onChange={(value) =>
                                        setChanges((e) => ({
                                            ...e,
                                            price: value
                                        }))
                                    }
                                    inputMode="numeric"
                                    className="flex h-auto w-full items-center rounded-md py-1 text-lg"
                                />
                            </div>
                        </div>
                        <div>
                            <p className="mb-2 font-medium">Vendedor</p>
                            <AgentSelect
                                disableSuspended={true}
                                company={user?.company}
                                placeholder="Seleccione la opción deseada"
                                className="flex-1 md:w-auto"
                                value={changes?.agentSeller}
                                onChange={(val: IAgent) => {
                                    setChanges((e) => ({
                                        ...e,
                                        agentSeller: val
                                    }));
                                }}
                            />
                        </div>
                        <div>
                            <p className="mb-2 font-medium">Productor</p>
                            <AgentSelect
                                disableSuspended={true}
                                company={user?.company}
                                placeholder="Seleccione la opción deseada"
                                className="flex-1 md:w-auto"
                                value={changes?.agent}
                                onChange={(val: IAgent) => {
                                    setChanges((e) => ({
                                        ...e,
                                        agent: val
                                    }));
                                }}
                            />
                        </div>
                        <div>
                            <p className="mb-2 font-medium">Desarrollo</p>
                            <DevelopmentInput
                                city={null}
                                value={changes?.development}
                                onChange={(val) =>
                                    setChanges((e) => ({
                                        ...e,
                                        development: val
                                    }))
                                }
                            />
                        </div>
                        <div>
                            <p className="mb-2 font-medium">Estado</p>
                            <PulppoSelect
                                className="h-auto py-2"
                                options={['cancelled', 'completed', 'published'].map((service) => ({
                                    key: service,
                                    label: PropertyStatusMapper[service].label,
                                    value: service
                                }))}
                                placeholder="Seleccione la opción deseada"
                                onSelect={() => {}}
                                onChange={(values: string) => {
                                    if (values === 'cancelled') {
                                        DeletePropertyReason((reason: string) => {
                                            if (reason === 'Concreté la operación') {
                                                setChanges((e) => ({
                                                    ...e,
                                                    status: 'completed'
                                                }));
                                            }
                                            setChanges((e) => ({
                                                ...e,
                                                deleteReason: reason
                                            }));
                                        });
                                    } else {
                                        setChanges((e) => {
                                            delete e.deleteReason;
                                            return e;
                                        });
                                    }
                                    setChanges((e) => ({
                                        ...e,
                                        status: values
                                    }));
                                }}
                                value={changes.status || []}
                            />
                        </div>
                        <div>
                            <p className="mb-2 font-medium"></p>
                            <CheckboxInput
                                value={changes?.addressIsRounded}
                                onChange={(val: boolean) => {
                                    setChanges((e) => ({
                                        ...e,
                                        addressIsRounded: val
                                    }));
                                }}
                            >
                                Redondear número exterior
                            </CheckboxInput>
                        </div>
                        <div>
                            <p className="mb-2 font-medium"></p>
                            <CheckboxInput
                                value={changes?.publish}
                                onChange={(val: boolean) => {
                                    setChanges((e) => ({
                                        ...e,
                                        publish: val
                                    }));
                                }}
                            >
                                Difundir públucamente
                            </CheckboxInput>
                        </div>
                    </div>
                )}
                {editMultiple?.step === 2 && (
                    <div className="flex flex-1 flex-col overflow-y-auto">
                        <div className="flex-1">
                            <h4 className="mb-6 text-lg">Cambiará los siguientes campos:</h4>
                            <div className="flex flex-col gap-4">
                                {changesToStrings(changes).map((row) => (
                                    <div className="text-lg" key={row.field}>
                                        {row.field} por: <b>{row.value}</b>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="text-center">
                            <p>Estos cambios no se podrán des-hacer.</p>
                            <p className="text-red-500">¿Está seguro que quiere cambiarlos?</p>{' '}
                            <p>En caso de querer cancelar los cambios, haga click en cerrar</p>
                        </div>
                    </div>
                )}
                <div className="p-4">
                    {editMultiple?.progress && <Progress percent={editMultiple?.progress} />}
                    <Button
                        disabled={editMultiple?.progress && editMultiple?.progress < 100}
                        onClick={async () => {
                            if (editMultiple?.progress >= 100) {
                                onClose();
                                setEditMultiple({ step: 0 });
                                setChanges({});
                                return;
                            }
                            if (editMultiple?.step === 2) {
                                setEditMultiple((e) => ({
                                    ...e,
                                    progress: 1
                                }));
                                let i = 0;
                                for (const property of selectedProperties) {
                                    await axios.patch(
                                        `${api_url}/property/${property?._id}`,
                                        MapChangesToPatch(changes)
                                    );
                                    if (changes.status === 'published') {
                                        await axios.patch(
                                            `${api_url}/property/${property?._id}/status/${changes.status}`
                                        );
                                    } else if (changes.status) {
                                        await axios.delete(`${api_url}/property/${property?._id}`, {
                                            data: {
                                                reasonToFinish: changes.deleteReason
                                            }
                                        });
                                    }
                                    i++;
                                    setEditMultiple((e) => ({
                                        ...e,
                                        progress: Math.floor((i / selectedProperties.length) * 100)
                                    }));
                                }
                                setSelectedProperties([]);
                                setIsAllSelected(false);
                                setFilters((f) => ({ ...f }));
                                setEditMultiple((e) => ({
                                    ...e,
                                    progress: 100
                                }));
                                return;
                            }
                            setEditMultiple((e) => ({
                                ...e,
                                step: e.step + 1
                            }));
                        }}
                        type="primary"
                        className="h-auto w-full rounded-md py-2"
                    >
                        {editMultiple?.progress >= 100
                            ? 'Cerrar'
                            : editMultiple?.step === 2
                              ? 'Confirmar'
                              : 'Continuar'}
                    </Button>
                </div>
            </div>
        </PulppoDrawer>
    );
};

const changesToStrings = (changes?: Changes) => {
    if (!changes) return [];
    const result = [];
    for (const key in changes) {
        if (key === 'agent') {
            result.push({
                field: 'Productor',
                value: `${changes[key]?.firstName} ${changes[key]?.lastName}`
            });
        } else if (key === 'agentSeller') {
            result.push({
                field: 'Vendedor',
                value: `${changes[key]?.firstName} ${changes[key]?.lastName}`
            });
        } else if (key === 'development') {
            result.push({
                field: 'Desarrollo',
                value: changes[key]?.name
            });
        } else if (key === 'status') {
            result.push({
                field: 'Estado',
                value: PropertyStatusMapper[changes[key]]?.label
            });
        } else if (key === 'publish') {
            result.push({
                field: 'Difusión',
                value: changes[key] ? 'Difundir' : 'No difundir'
            });
        } else if (key === 'addressIsRounded') {
            result.push({
                field: 'Redondear número exterior',
                value: changes[key] ? 'Redondear' : 'No redondear'
            });
        } else if (key === 'price') {
            result.push({
                field: 'Precio',
                value: `${changes.currency || process.env.NEXT_PUBLIC_PRIMARY_CURRENCY} $${changes[key]}`
            });
        }
    }
    return result;
};
const MapChangesToPatch = (changes?: Changes) => {
    if (!changes) return {};
    let result: { [key: string]: any } = { editedAt: new Date() };
    for (const key in changes) {
        if (key === 'agent') {
            result = { ...result, ...toPlain(changes[key], 'agent') };
        } else if (key === 'agentSeller') {
            result = { ...result, ...toPlain(changes[key], 'agentSeller') };
        } else if (key === 'development') {
            result = { ...result, development: changes[key] };
        } else if (key === 'publish') {
            result = { ...result, 'listing.publishOnPortals': changes[key] };
        } else if (key === 'addressIsRounded') {
            result = { ...result, 'listing.addressIsRounded': changes[key] };
        } else if (key === 'price') {
            result = {
                ...result,
                'listing.price.currency': changes.currency || process.env.NEXT_PUBLIC_PRIMARY_CURRENCY,
                'listing.price.price': changes[key]
            };
        }
    }
    return result;
};
