import { MetricType } from '@pulppo/pulppo-models/build/enums/MetricType';
import DrawerMetricTaskPending from './DrawerMetricTaskPending';
import DrawerMetricCharts from './DrawerMetricCharts';

interface Props {
    type: MetricType;
    show: boolean;
    onClose: () => void;
}

const DrawerMetricChartTeam = ({ type, ...drawerProps }: Props) => {
    switch (type) {
        case MetricType.FirstResponseTime:
        case MetricType.AvgResponseTime:
        case MetricType.Interactions:
        case MetricType.PropertiesSale:
        case MetricType.Properties:
        case MetricType.OfferRate:
        case MetricType.AvgSuggestedProperties:
            return <DrawerMetricCharts {...drawerProps} type={[type]} />;
        case MetricType.UnansweredLeads:
            return <DrawerMetricCharts {...drawerProps} type={[MetricType.UnansweredLeads]} />;
        case MetricType.VisitsTotal:
        case MetricType.VisitsFinished:
        case MetricType.VisitRate:
            return <DrawerMetricCharts {...drawerProps} type={[MetricType.VisitRate]} />;
        case MetricType.TasksPendingClient:
        case MetricType.TasksExpiredClient:
            return (
                <DrawerMetricCharts
                    {...drawerProps}
                    type={[MetricType.TasksPendingClient, MetricType.TasksExpiredClient]}
                />
            );
        case MetricType.TasksPendingProperty:
        case MetricType.TasksExpiredProperty:
            return (
                <DrawerMetricCharts
                    {...drawerProps}
                    type={[MetricType.TasksPendingProperty, MetricType.TasksExpiredProperty]}
                />
            );
        case MetricType.TasksPending:
            return <DrawerMetricTaskPending {...drawerProps} />;
    }
    return null;
};

export default DrawerMetricChartTeam;
