import { ISimpleUser, ITask } from '@pulppo/pulppo-models';
import { PulppoDrawer } from './PulppoDrawer';
import * as ReactDOM from 'react-dom';
import useSWR from 'swr';
import { api_url, fetcher } from '../../helpers/fetcher';
import { Avatar, Button, Modal, Tabs } from 'antd';
import { useState } from 'react';
import { CalendarOutlined, FileDoneOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import NewTask from './NewTask';
import axios from '../../utils/axios';
import { LoadingButton } from '../../pages/listing/[id]/[step]';
import { TaskType } from '../../types';

const tabs = [
    {
        key: 'pending',
        label: 'Pendientes',
        filter: (task: ITask) => task.status === 'pending'
    },
    {
        key: 'done',
        label: 'Realizadas',
        filter: (task: ITask) => task.status === 'done'
    }
];

interface ITaskDrawer {
    show: boolean;
    setShow: (show: boolean) => void;
    onClose: () => void;
    user: ISimpleUser;
    type: TaskType;
    task: Partial<ITask>;
    metadata?: {
        id?: string;
        properties?: Array<any>;
        agent?: ISimpleUser;
    };
}

const TaskDrawer = ({ show, setShow, onClose, user, type, task: initialTask, metadata }: ITaskDrawer) => {
    const [tab, setTab] = useState('pending');
    const [showNewTask, setShowNewTask] = useState(false);
    const [task, setTask] = useState<Partial<ITask>>(initialTask);
    const { data: tasks, mutate: refreshTasks } = useSWR<Array<ITask>>(
        `${api_url}${type === 'search' ? `/search/${metadata?.id}` : `/property/${metadata?.id}/capture`}/tasks`,
        fetcher
    );
    return (
        <PulppoDrawer
            title="Tareas"
            bodyStyle={{ paddingTop: 0 }}
            visible={show}
            onClose={() => {
                if (showNewTask) {
                    setShowNewTask(false);
                    setTask(initialTask);
                } else {
                    setShow(false);
                    onClose();
                }
            }}
        >
            <div className="flex h-full flex-col justify-between">
                <div className="shrink-1 flex-1 overflow-x-hidden">
                    {showNewTask ? (
                        <NewTask
                            properties={metadata?.properties}
                            user={user}
                            task={task}
                            setTask={setTask}
                            type={type}
                        />
                    ) : (
                        <div className="flex flex-1 flex-col">
                            <Tabs
                                activeKey={tab}
                                centered={false}
                                onChange={(key) => setTab(key)}
                                items={tabs.map((elem) => ({
                                    key: elem.key,
                                    label: (
                                        <p className={`${tab === elem.key ? 'font-medium text-black' : ''}`}>
                                            {`${elem.label} (${tasks?.filter((tsk) => elem.filter(tsk))?.length || 0})`}
                                        </p>
                                    )
                                }))}
                            />
                            {tasks?.filter((tsk) => tabs.find((elem) => elem.key === tab)?.filter(tsk)).length ===
                                0 && (
                                <div className="flex flex-col items-center py-10">
                                    <img src="/icons/taskEmpty.svg" width={100} alt="empty" />
                                    <p className="font-bold">
                                        {tab === 'done' ? 'Sin tareas realizadas' : 'Sin tareas pendientes'}
                                    </p>
                                    <p className="font-medium text-pulppo-status-dark-disabled">
                                        {tab === 'done'
                                            ? 'No encontramos tareas realizadas en esta búsqueda'
                                            : 'No encontramos tareas pendientes en esta búsqueda'}
                                    </p>
                                </div>
                            )}
                            <div>
                                {tasks
                                    ?.filter((tsk) => tabs.find((elem) => elem.key === tab)?.filter(tsk))
                                    ?.sort(
                                        (a, b) => dayjs(a.createdAt).tz().valueOf() - dayjs(b.createdAt).tz().valueOf()
                                    )
                                    ?.map((tsk) => (
                                        <div key={tsk._id as string} className="mb-4">
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setTask({
                                                        ...tsk,
                                                        dueDate: dayjs(tsk.dueDate).tz().toDate()
                                                    });
                                                    setShowNewTask(true);
                                                }}
                                                className="mb-2 w-full border border-solid border-gray-200 p-4 text-left"
                                                disabled={tsk.type !== 'search' || tsk.agent?.uid !== user?.uid}
                                            >
                                                <div className="flex justify-between gap-4">
                                                    <div className="flex items-center gap-2 font-medium">
                                                        <div>
                                                            <FileDoneOutlined className="bg-gray-100 p-2 text-xl" />
                                                        </div>
                                                        <p>{tsk.name}</p>
                                                    </div>
                                                    <div className="flex shrink-0 gap-2">
                                                        {tsk.status === 'pending' && (
                                                            <div className="flex h-8 items-center justify-center gap-2 border border-solid border-gray-400 px-4 font-medium text-pulppo-status-dark-disabled">
                                                                <p>
                                                                    {dayjs(tsk.dueDate).tz().format('DD/MM')}{' '}
                                                                    <CalendarOutlined />
                                                                </p>
                                                            </div>
                                                        )}
                                                        <div
                                                            className={`hidden h-8 items-center border border-solid px-4 font-medium md:flex ${
                                                                tsk.status === 'done'
                                                                    ? 'border-confirmationGreen text-confirmationGreen'
                                                                    : 'border-strong-red text-strong-red'
                                                            }`}
                                                        >
                                                            {tsk.status === 'done' ? 'Realizada' : 'Pendiente'}
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="text-pulppo-status-dark-disabled">{tsk.message}</p>
                                                <div className="mt-4 flex items-center justify-between gap-4">
                                                    <div className="flex items-center gap-4">
                                                        {tsk.metadata.property && (
                                                            <>
                                                                <img
                                                                    src={tsk.metadata.property?.pictures?.[0]?.url}
                                                                    alt={tsk.metadata.property._id}
                                                                    className="h-12 w-12 object-cover"
                                                                />
                                                                <div>
                                                                    <p className="font-medium">
                                                                        {tsk.metadata.property.address.street}
                                                                    </p>
                                                                    <p className="font-medium text-pulppo-status-dark-disabled">
                                                                        {`${tsk.metadata.property.address?.neighborhood?.name}, ${tsk.metadata.property.address?.city?.name}`}
                                                                    </p>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>

                                                    <div className="flex shrink-0 items-center gap-2">
                                                        {tsk.type === 'search' || tsk.type === 'capture' ? (
                                                            <>
                                                                <Avatar
                                                                    shape="circle"
                                                                    className="h-10 w-10"
                                                                    src={
                                                                        tsk.agent?.profilePicture ||
                                                                        `https://ui-avatars.com/api/?name=${tsk.agent?.firstName}+${tsk.agent?.lastName}`
                                                                    }
                                                                />
                                                                <div>
                                                                    <p className="font-medium">{`${tsk.agent?.firstName} ${tsk.agent?.lastName}`}</p>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Avatar
                                                                    shape="circle"
                                                                    className="h-10 w-10"
                                                                    src="/pulppo.png"
                                                                />
                                                                <div>
                                                                    <p className="font-medium">Creada por Pulppo</p>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </button>
                                            <p className="w-full text-right text-pulppo-status-dark-disabled">{`${dayjs(
                                                tsk.createdAt
                                            )
                                                .tz()
                                                .format('DD/MM/YYYY')} a las ${dayjs(tsk.createdAt)
                                                .tz()
                                                .format('HH:mm')}Hs`}</p>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )}
                </div>
                <div className="shrink-0 py-4">
                    {showNewTask ? (
                        <div className="flex justify-between gap-10 px-4">
                            <Button
                                disabled={user?.uid !== metadata?.agent?.uid}
                                onClick={() => {
                                    if (task._id) {
                                        axios
                                            .patch(`${api_url}/task/${task._id}/markAsDone`)
                                            .then(() => {
                                                setShowNewTask(false);
                                                setTask(initialTask);
                                                refreshTasks();
                                            })
                                            .catch(() => {
                                                Modal.error({
                                                    title: 'Error al marcar como realizada',
                                                    content:
                                                        'Hubo un error al intentar marcar la tarea como realizada. Intenta nuevamente mas tarde'
                                                });
                                            });
                                    } else {
                                        setShowNewTask(false);
                                        setTask(initialTask);
                                    }
                                }}
                                type="default"
                                className="h-10 flex-1 shrink-0 font-medium"
                            >
                                {task._id ? 'Marcar como realizada' : 'Cancelar'}
                            </Button>
                            <LoadingButton
                                disabled={user?.uid !== metadata?.agent?.uid}
                                onClick={async () => {
                                    if (!task.name) {
                                        Modal.error({
                                            title: 'Falta un campo requerido',
                                            content: 'Debes seleccionar un "tipo de tarea"'
                                        });
                                        return;
                                    }
                                    if (task._id) {
                                        try {
                                            await axios.patch(`${api_url}/task/${task._id}`, task);
                                            setShowNewTask(false);
                                            setTask(initialTask);
                                            await refreshTasks();
                                        } catch (err) {
                                            Modal.error({
                                                title: 'Error creando la tarea',
                                                content:
                                                    'Hubo un error al intentar crear la tarea. Intenta nuevamente mas tarde'
                                            });
                                        }
                                    } else {
                                        try {
                                            await axios.post(`${api_url}/task`, {
                                                ...task,
                                                reddirectTo:
                                                    type === 'search'
                                                        ? `/new-searches/${metadata?.id}`
                                                        : `/captaciones/${metadata?.id}`
                                            });
                                            setShowNewTask(false);
                                            setTask(initialTask);
                                            await refreshTasks();
                                        } catch (err) {
                                            Modal.error({
                                                title: 'Error creando la tarea',
                                                content:
                                                    'Hubo un error al intentar crear la tarea. Intenta nuevamente mas tarde'
                                            });
                                        }
                                    }
                                }}
                                type="primary"
                                className="h-10 flex-1 font-medium"
                            >
                                Guardar tarea
                            </LoadingButton>
                        </div>
                    ) : (
                        <Button
                            disabled={user?.uid !== metadata?.agent?.uid}
                            onClick={() => {
                                setShowNewTask(true);
                                setTask(initialTask);
                            }}
                            type="primary"
                            className="h-10 w-full font-medium"
                        >
                            Crear nueva tarea
                        </Button>
                    )}
                </div>
            </div>
        </PulppoDrawer>
    );
};

export const ShowTaskDrawer = (config: Omit<ITaskDrawer, 'show' | 'setShow'> & { onClose?: () => void }) => {
    const container = document.createDocumentFragment();
    let currentConfig = {
        ...config,
        close,
        show: true
    } as any;

    function destroy() {
        ReactDOM.unmountComponentAtNode(container);
    }

    function render(props) {
        /**
         * https://github.com/ant-design/ant-design/issues/23623
         *
         * Sync render blocks React event. Let's make this async.
         */
        setTimeout(() => {
            ReactDOM.render(<TaskDrawer {...props} setShow={() => {}} />, container);
        });
    }

    function close(...args: any[]) {
        currentConfig = {
            ...currentConfig,
            show: false,
            afterClose: () => {
                destroy.apply(this, args);
            }
        };
        render(currentConfig);
    }

    function update(configUpdate) {
        if (typeof configUpdate === 'function') {
            currentConfig = configUpdate(currentConfig);
        } else {
            currentConfig = {
                ...currentConfig,
                ...configUpdate
            };
        }
        render(currentConfig);
    }

    render(currentConfig);

    return {
        destroy: close,
        update
    };
};

export default TaskDrawer;
