import { useCallback, useRef, useState } from 'react';

export const useVisible = () => {
    const [visible, setVisible] = useState(false);

    const observerRef = useRef<IntersectionObserver>();
    const visibleRef = useRef<boolean>(false);
    const elementRef = useRef<HTMLElement>();

    const measuredRef = useCallback((node: HTMLElement | null) => {
        if (node !== null) {
            elementRef.current = node;
            if (observerRef.current) observerRef.current.disconnect();
            observerRef.current = new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting == visibleRef.current) return;
                setVisible(entry.isIntersecting);
                visibleRef.current = entry.isIntersecting;
            });
            observerRef.current.observe(node);
        }
    }, []);

    return { visible, ref: measuredRef, elementRef };
};
