export const LoyaltyBadges = [
    {
        id: 'academy',
        image: '/icons/loyalty/academy-white.svg',
        imageColor: '/icons/loyalty/academy-color.svg',
        title: 'Certificación Pulppo',
        subtitle: 'Certificación',
        description: 'Completa los cursos de la academia para convertirte en un experto en la plataforma.'
    },
    {
        id: 'first-sale',
        image: '/icons/loyalty/first-sale-white.svg',
        imageColor: '/icons/loyalty/first-sale-color.svg',
        title: 'Primera Venta',
        subtitle: 'Concretada',
        description: 'Concreta tu primera venta, sin importar qué lado de la operación consigas.'
    },
    {
        id: 'first-rent',
        image: '/icons/loyalty/first-rent-white.svg',
        imageColor: '/icons/loyalty/first-rent-color.svg',
        title: 'Primera Renta',
        subtitle: 'Concretada',
        description: 'Completa tu primera operación de renta ya sea que consigas la propiedad o al inquilino.'
    },
    {
        id: 'exclusive',
        image: '/icons/loyalty/exclusive-white.svg',
        imageColor: '/icons/loyalty/exclusive-color.svg',
        title: 'Éxito Exclusivo.',
        subtitle: 'Captada y vendida',
        description: 'Capta una propiedad en exclusiva y concreta la operación de venta.'
    },
    {
        id: '20-millions',
        image: '/icons/loyalty/20m-white.svg',
        imageColor: '/icons/loyalty/20m-color.svg',
        title: '20M Vendidos',
        subtitle: 'Vendidos',
        description: 'Acumula un total de 20 millones de pesos en el valor de las propiedades vendidas.'
    },
    {
        id: '50-millions',
        image: '/icons/loyalty/50m-white.svg',
        imageColor: '/icons/loyalty/50m-color.svg',
        title: '50M Vendidos',
        subtitle: 'Vendidos',
        description: 'Acumula un total de 50 millones de pesos en el valor de las propiedades vendidas.'
    },
    {
        id: '100-millions',
        image: '/icons/loyalty/100m-white.svg',
        imageColor: '/icons/loyalty/100m-color.svg',
        title: '100M Vendidos',
        subtitle: 'Vendidos',
        description: 'Acumula un total de 100 millones de pesos en el valor de las propiedades vendidas.'
    },
    {
        id: 'sale-shared',
        image: '/icons/loyalty/sale-shared-white.svg',
        imageColor: '/icons/loyalty/sale-shared-color.svg',
        title: 'Operación Pulpper',
        subtitle: 'Comunidad Pulppo',
        description: 'Concreta una venta en colaboración con otro asesor de la red de Pulppo.'
    },
    {
        id: '100-visits',
        image: '/icons/loyalty/100visits-white.svg',
        imageColor: '/icons/loyalty/100visits-color.svg',
        title: 'Centurión de visitas',
        subtitle: 'Realizadas',
        description: 'Alcanza las 100 visitas registradas y confirmadas en la plataforma.'
    },
    {
        id: '3-months-rent',
        image: '/icons/loyalty/3rent-white.svg',
        imageColor: '/icons/loyalty/3rent-color.svg',
        title: '3 meses de renta continua',
        subtitle: 'En ventas concretadas',
        description: 'Concreta al menos una renta mensual durante tres meses consecutivos.'
    },
    {
        id: '6-months-rent',
        image: '/icons/loyalty/6rent-white.svg',
        imageColor: '/icons/loyalty/6rent-color.svg',
        title: '6 meses de renta continua',
        subtitle: 'En ventas concretadas',
        description: 'Concreta al menos una renta mensual durante seis meses consecutivos.'
    },
    {
        id: '12-months-rent',
        image: '/icons/loyalty/12rent-white.svg',
        imageColor: '/icons/loyalty/12rent-color.svg',
        title: '12 meses de renta continua',
        subtitle: 'En ventas concretadas',
        description: 'Concreta al menos una renta mensual durante doce meses consecutivos.'
    },
    {
        id: '3-months-sale',
        image: '/icons/loyalty/3sale-white.svg',
        imageColor: '/icons/loyalty/3sale-color.svg',
        title: '3 meses de venta continua',
        subtitle: 'En ventas concretadas',
        description: 'Concreta al menos una venta mensual durante tres meses consecutivos.'
    },
    {
        id: '6-months-sale',
        image: '/icons/loyalty/6sale-white.svg',
        imageColor: '/icons/loyalty/6sale-color.svg',
        title: '6 meses de venta continua',
        subtitle: 'En ventas concretadas',
        description: 'Concreta al menos una venta mensual durante seis meses consecutivos.'
    },
    {
        id: '12-months-sale',
        image: '/icons/loyalty/12sale-white.svg',
        imageColor: '/icons/loyalty/12sale-color.svg',
        title: '12 meses de venta continua',
        subtitle: 'En ventas concretadas',
        description: 'Concreta al menos una venta mensual durante doce meses consecutivos.'
    }
];
