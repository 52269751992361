import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { twMerge } from 'tailwind-merge';

export interface PulppoPaginationProps {
    page: number;
    onChange: (page: number, pageSize: number) => void;
    count: number;
    pageSize?: number;
    numOfPages?: number;
}
export const PulppoPagination = ({ page, onChange, pageSize = 50, count, numOfPages = 3 }: PulppoPaginationProps) => {
    const totalPages = Math.ceil(count / pageSize);

    return (
        <div className="flex items-center justify-center gap-4 p-2 text-pulppo-status-dark-disabled">
            {page > 1 ? <LeftOutlined onClick={() => onChange(page - 1, pageSize)} /> : <></>}
            {page >= numOfPages ? (
                <p onClick={() => onChange(1, pageSize)} className="cursor-pointer hover:underline">
                    1
                </p>
            ) : (
                <></>
            )}
            {page >= numOfPages ? <p>...</p> : <></>}

            {new Array(numOfPages)
                .fill(1)
                .map((_, index) => {
                    if (page <= Math.ceil(numOfPages / 2)) return index + 1;
                    return page - Math.floor(numOfPages / 2) + index;
                })
                .filter((page) => page > 0 && page <= totalPages)
                .map((pg) => (
                    <div
                        onClick={() => onChange(pg, pageSize)}
                        key={`page_${pg}`}
                        className={twMerge(
                            'flex h-8 w-8 cursor-pointer items-center justify-center',
                            page === pg ? 'bg-black font-book text-white' : 'hover:underline'
                        )}
                    >
                        <p>{pg}</p>
                    </div>
                ))}
            {totalPages - page >= numOfPages ? <p>...</p> : <></>}
            {totalPages - page >= numOfPages ? (
                <p
                    className="cursor-pointer hover:underline"
                    onClick={() => onChange(totalPages, pageSize)}
                >{`${totalPages}`}</p>
            ) : (
                <></>
            )}
            {page < totalPages ? <RightOutlined onClick={() => onChange(page + 1, pageSize)} /> : <></>}
        </div>
    );
};
