import useUser from '../useUser';

interface Props {
    agentId?: string;
}

const useMetricSearchId = ({ agentId }: Props) => {
    const user = useUser((u) => u.user);
    const statsView = user?.showAll;
    const id = agentId ? agentId : statsView ? `${user?.company?._id}` : `${user?._id}`;
    const isCompany = id === `${user?.company?._id}`;

    return {
        state: {
            statsView,
            id,
            isCompany
        }
    };
};

export default useMetricSearchId;
