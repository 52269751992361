// import * as ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { PulppoDrawer, PulppoDrawerProps } from './PulppoDrawer';
import { TolgeeNextProvider } from '../../contexts/TolgeeProvider';

export const ShowPulppoDrawer = (
    config: Omit<PulppoDrawerProps, 'visible' | 'onClose'> & {
        onCancel?: any;
        afterClose?: any;
    }
) => {
    const container = document.createDocumentFragment();
    let currentConfig = {
        ...config,
        onClose,
        visible: true
    } as any;
    const root = createRoot(container);

    function destroy(...args: any[]) {
        root.unmount();
        const triggerCancel = args.some((param) => param && param.triggerCancel);
        if (config.onCancel && triggerCancel) {
            config.onCancel(...args);
        }
    }

    function render(props) {
        /**
         * https://github.com/ant-design/ant-design/issues/23623
         *
         * Sync render blocks React event. Let's make this async.
         */
        setTimeout(() => {
            root.render(
                <TolgeeNextProvider>
                    <PulppoDrawer {...props} />
                </TolgeeNextProvider>
            );
        });
    }

    function onClose(...args: any[]) {
        currentConfig = {
            ...currentConfig,
            visible: false,
            afterClose: () => {
                if (typeof config.afterClose === 'function') {
                    config.afterClose();
                }
                destroy.apply(this, args);
            }
        };
        render(currentConfig);
    }

    function update(configUpdate) {
        if (typeof configUpdate === 'function') {
            currentConfig = configUpdate(currentConfig);
        } else {
            currentConfig = {
                ...currentConfig,
                ...configUpdate
            };
        }
        render(currentConfig);
    }

    render(currentConfig);

    return {
        destroy: onClose,
        update
    };
};
