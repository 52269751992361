import decode from 'heic-decode';

function toBuffer(ab?: ArrayBuffer) {
    if (!ab) return;
    return Buffer.from(ab);
}

export const TransformHEICService = async (file: File) => {
    const arrayBuffer = await file.arrayBuffer();
    const outputBuffer = await decode({
        buffer: toBuffer(arrayBuffer)
    });
    const canvas = document.createElement('canvas');
    const pixels = new Uint8ClampedArray(outputBuffer.data);

    const ctx = canvas.getContext('2d');
    canvas.height = outputBuffer.height;
    canvas.width = outputBuffer.width;
    const imageData = new ImageData(pixels, outputBuffer.width, outputBuffer.height);

    ctx?.putImageData(imageData, 0, 0);
    return Buffer.from(canvas.toDataURL('image/jpeg', 0.85).split(',')[1], 'base64');
};
