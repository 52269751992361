import { useEffect, useState } from 'react';
import { CountryCode } from 'libphonenumber-js/types';
import { AsYouType } from 'libphonenumber-js/min';
import { getCountries, getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input';
import {
    CloseOutlined,
    DownOutlined,
    PhoneOutlined,
    CheckCircleFilled,
    CloseCircleFilled,
    QuestionCircleFilled,
    WhatsAppOutlined,
    DeleteOutlined,
    PlusOutlined
} from '@ant-design/icons';
import { IAgent, IContact } from '@pulppo/pulppo-models';
import { Drawer, Input, List, Row, Typography, Form, Button } from 'antd';
import useIsMobile from '../../hooks/isMobile';
import { PulppoSelect } from '../Elements/PulppoSelect';
import es from 'react-phone-number-input/locale/es.json';
import { twMerge } from 'tailwind-merge';
import { IDisabledInputs } from './BasicInformation';
import { contactService } from '../../services/contactService';

export interface IPhoneProps {
    onChange?: Function;
    value?: { phone: string; countryCode?: CountryCode };
    disabled?: boolean;
    className?: string;
    onContactFound?: Function;
}

export const PhoneInput = ({
    onChange,
    value: initialValue = { phone: '', countryCode: (process.env.NEXT_PUBLIC_COUNTRY_CODE as any) || 'MX' },
    disabled = false,
    className = '',
    onContactFound = null
}: IPhoneProps) => {
    const [selectedCountry, setSelectedCountry] = useState<CountryCode>(
        initialValue?.countryCode || (process.env.NEXT_PUBLIC_COUNTRY_CODE as any) || 'MX'
    );
    const [value, setValue] = useState(new AsYouType().input(initialValue.phone));
    const [searchTerm, setSearchTerm] = useState('');
    const [showDrawer, setShowDrawer] = useState(false);
    const isMobile = useIsMobile();

    useEffect(() => {
        if (!initialValue) return;
        const parsed = parsePhoneNumber(`+${initialValue?.phone?.replace(/\D/gi, '')}`);
        const country = parsed?.country;
        const countryCallingCode = parsed?.countryCallingCode;
        if (country) setSelectedCountry(country);
        else if (countryCallingCode === '1') setSelectedCountry('US');
        setValue(
            new AsYouType()
                .input(initialValue?.phone)
                .replace(
                    `+${country ? getCountryCallingCode(country) : getCountryCallingCode(selectedCountry || (process.env.NEXT_PUBLIC_COUNTRY_CODE as any) || 'MX')} `,
                    ''
                )
        );
    }, [initialValue?.phone]);

    return (
        <>
            <Drawer
                title="Elija el código de pais"
                closeIcon={<CloseOutlined className="text-2xl" />}
                placement={isMobile ? 'bottom' : 'right'}
                width={500}
                height={800}
                open={showDrawer}
                closable
                onClose={() => {
                    setShowDrawer(false);
                }}
            >
                <Input
                    placeholder="Buscar pais"
                    className="mb-5 rounded-md"
                    value={searchTerm}
                    allowClear
                    onInput={(e) => setSearchTerm(e.currentTarget.value)}
                />
                <List
                    dataSource={getCountries().filter((country) =>
                        es[country]
                            ?.normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            ?.toLowerCase()
                            .includes(searchTerm?.toLowerCase())
                    )}
                    renderItem={(item) => (
                        <List.Item
                            key={item}
                            onClick={() => {
                                setSelectedCountry(item);
                                setSearchTerm('');
                                setShowDrawer(false);
                            }}
                        >
                            <List.Item.Meta
                                avatar={
                                    <img
                                        width={40}
                                        alt={selectedCountry}
                                        style={{ minWidth: '40px' }}
                                        src={`https://catamphetamine.gitlab.io/country-flag-icons/3x2/${item}.svg`}
                                    />
                                }
                                title={<p className="text-lg">{es[item]}</p>}
                            />
                        </List.Item>
                    )}
                />
            </Drawer>
            <div className="flex w-full items-center">
                <PulppoSelect
                    disabled={disabled}
                    title="Elija un código de pais"
                    value={selectedCountry}
                    options={getCountries().map((country) => ({
                        label: es[country],
                        key: country,
                        value: country,
                        icon: (
                            <img
                                width={40}
                                alt={selectedCountry}
                                style={{ minWidth: '40px' }}
                                src={`https://catamphetamine.gitlab.io/country-flag-icons/3x2/${country}.svg`}
                            />
                        )
                    }))}
                    onSelect={(item) => {
                        if (disabled) return;
                        const prefix = `+${getCountryCallingCode(item.value as CountryCode)} `;
                        const newValue = new AsYouType(item as any).input(`${prefix}${value}`);
                        onChange({
                            phone: newValue,
                            countryCode: item.value as CountryCode
                        });
                        setSelectedCountry(item.value as CountryCode);
                    }}
                    renderButton={(item) => (
                        <div
                            className={twMerge(
                                'flex rounded border border-solid border-gray-300 bg-white px-2 py-2 hover:border-blue-400',
                                className
                            )}
                        >
                            <img
                                width={30}
                                alt={selectedCountry}
                                style={{ minWidth: '20px' }}
                                src={`https://catamphetamine.gitlab.io/country-flag-icons/3x2/${item.value}.svg`}
                            />
                            <DownOutlined className="ml-2 py-1 text-pulppo-status-dark-disabled" />
                        </div>
                    )}
                />
                <Input
                    inputMode="numeric"
                    disabled={disabled}
                    onChange={async (evt) => {
                        if (disabled) return;
                        const value = evt.currentTarget.value.replace(/\D/gi, '');
                        if (!value) {
                            setValue(value);
                            return onChange({
                                phone: value,
                                countryCode: selectedCountry || process.env.NEXT_PUBLIC_COUNTRY_CODE || 'MX'
                            });
                        }
                        const prefix = `+${getCountryCallingCode((selectedCountry as CountryCode) || (process.env.NEXT_PUBLIC_COUNTRY_CODE as any) || 'MX')} `;
                        const newValue = new AsYouType(
                            (selectedCountry as any) || process.env.NEXT_PUBLIC_COUNTRY_CODE || 'MX'
                        ).input(`${prefix}${value}`);
                        setValue(newValue.replace(prefix, ''));
                        onChange({
                            phone: newValue,
                            countryCode: selectedCountry
                        });

                        if (onContactFound) {
                            const contact = await contactService.searchExistByPhone(newValue);
                            if (contact) onContactFound(contact);
                        }
                    }}
                    value={value.replace(`+${getCountryCallingCode(selectedCountry as CountryCode)}`, '')}
                    prefix={
                        <span className="text-secondary-color">
                            +{getCountryCallingCode(selectedCountry as CountryCode)}
                        </span>
                    }
                    className={twMerge('ml-10px w-full py-2', className)}
                />
            </div>
        </>
    );
};

const AGENT_PULPPO_INMO_UID = 'p8otaxMSkWTU9JApohEDpDUBTP83';

export const Phones = ({
    contact,
    user,
    initialContact,
    onContactFound = (_) => {},
    disabled,
    onRemove
}: {
    user: IAgent;
    contact: IContact;
    initialContact: IContact;
    showIcons: boolean;
    onContactFound?: (contact: IContact) => void;
    disabled?: IDisabledInputs;
    onRemove?: (index: number) => Promise<void> | void;
}) => {
    return (
        <div className="my-10px">
            <Row>
                <Typography.Text className="font-medium">Teléfono</Typography.Text>
            </Row>
            <Form.List name="phones">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field, index) => (
                            <div key={field.key} className="mb-2 flex items-stretch gap-4">
                                <div className="flex flex-1 items-center gap-2">
                                    <Form.Item name={[field.name]} className="mb-0 flex-1">
                                        <PhoneInput
                                            className="rounded-none"
                                            onContactFound={onContactFound}
                                            disabled={
                                                (contact &&
                                                    initialContact?.phones?.[index] &&
                                                    contact?.agent?.uid != user?.uid &&
                                                    contact?.agent?.uid != AGENT_PULPPO_INMO_UID) ||
                                                (disabled?.current && index + 1 <= contact?.phones?.length)
                                            }
                                        />
                                    </Form.Item>
                                    <div className="relative">
                                        <PhoneOutlined className="rounded-full border border-solid border-gray-400 bg-white p-1 text-pulppo-status-dark-disabled" />
                                        {typeof initialContact?.phones?.[index]?.valid === 'boolean' ? (
                                            initialContact?.phones?.[index]?.valid ? (
                                                <CheckCircleFilled className="absolute right-0 top-0 translate-x-1/2 translate-y-0 transform bg-white text-green-400" />
                                            ) : (
                                                <CloseCircleFilled className="absolute right-0 top-0 translate-x-1/2 translate-y-0 transform bg-white text-red-400" />
                                            )
                                        ) : (
                                            <QuestionCircleFilled className="absolute right-0 top-0 translate-x-1/2 translate-y-0 transform bg-white text-gray-200" />
                                        )}
                                    </div>
                                    <div className="relative">
                                        <WhatsAppOutlined className="rounded-full border border-solid border-gray-400 bg-white p-1 text-pulppo-status-dark-disabled" />
                                        {typeof initialContact?.phones?.[index]?.whatsapp === 'string' &&
                                        initialContact?.phones?.[index]?.whatsapp !== 'unchecked' ? (
                                            initialContact?.phones?.[index]?.whatsapp === 'valid' ? (
                                                <CheckCircleFilled className="absolute right-0 top-0 translate-x-1/2 translate-y-0 transform bg-white text-green-400" />
                                            ) : (
                                                <CloseCircleFilled className="absolute right-0 top-0 translate-x-1/2 translate-y-0 transform bg-white text-red-400" />
                                            )
                                        ) : (
                                            <QuestionCircleFilled className="absolute right-0 top-0 translate-x-1/2 translate-y-0 transform bg-white text-gray-200" />
                                        )}
                                    </div>
                                </div>

                                <div className="flex items-stretch gap-2">
                                    <Button
                                        className={twMerge(
                                            'h-auto w-full rounded-md px-4 py-2',
                                            index !== 0 ? 'block' : 'hidden'
                                        )}
                                        onClick={async () => {
                                            if (!disabled?.delete || user.features?.contactsEdit) {
                                                if (onRemove) {
                                                    await onRemove(index);
                                                } else {
                                                    remove(index);
                                                }
                                            }
                                        }}
                                        disabled={
                                            fields.length <= 1 ||
                                            (disabled?.delete &&
                                                index + 1 <= contact?.phones?.length &&
                                                !user.features?.contactsEdit)
                                        }
                                        icon={
                                            <DeleteOutlined
                                                className={fields.length <= 1 && 'text-pulppo-status-dark-disabled'}
                                            />
                                        }
                                    />
                                    <Button
                                        onClick={() => {
                                            if (!disabled?.add) {
                                                add({
                                                    phone: '',
                                                    type: 'whatsapp'
                                                });
                                            }
                                        }}
                                        disabled={disabled?.add}
                                        className={twMerge(
                                            'h-auto w-full px-4 py-2',
                                            index === fields.length - 1 ? 'block' : 'hidden'
                                        )}
                                        icon={<PlusOutlined className="font-bold" />}
                                    />
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </Form.List>
        </div>
    );
};
