import { ComponentState, useState, MouseEvent } from 'react';
import { MapsGallery } from './MapsGallery';
import { PhotoGallery } from './PhotoGallery';
import { VideoGallery } from './VideoGallery';
import { Carousel } from '../Elements/Carousel';
import { IAddress, IPicture, IVideo } from '@pulppo/pulppo-models';
import mapboxgl from 'mapbox-gl';
import { NextImage } from '../Elements/NextImage';
import { StreetView } from './StreetView';
import { twMerge } from 'tailwind-merge';

import '@glidejs/glide/dist/css/glide.core.min.css';
import { ReactSVG } from 'react-svg';

type menuItems = {
    title: string;
    img: string;
    withContent: boolean;
};

type TMediaContent = {
    images: Array<IPicture>;
    virtualTour: string;
    videos: Array<IVideo>;
    bluePrints: Array<IPicture>;
    coordinates: mapboxgl.LngLatLike;
    streetView: string;
};

interface IPropertyModalProps {
    fullScreened?: { url?: string; index: number };
    modal: ComponentState;
    buildOptions: () => Array<menuItems>;
    setActive: ComponentState;
    mediaContent: TMediaContent;
    showModal: (e: MouseEvent<HTMLButtonElement>) => void;
    active: number;
}

export const PropertyMedia = ({
    pictures,
    videos,
    streetView,
    address,
    virtualTour = undefined
}: {
    address: IAddress;
    pictures: Array<IPicture>;
    videos: Array<IVideo>;
    streetView: string;
    virtualTour: string;
}) => {
    const [modal, setModal] = useState(false);
    const [active, setActive] = useState(0);
    const [fullScreened, setFullScreened] = useState(null);

    pictures = pictures.filter(Boolean);
    const images = pictures?.filter?.((p) => !p?.is_blueprint) || [];
    const bluePrints = pictures?.filter?.((p) => p?.is_blueprint) || [];
    const coordinates = address?.location?.coordinates || [0, 0];
    const mediaContent = {
        images: images?.length
            ? images
            : [
                  {
                      url: '/empty_bg.png',
                      is_blueprint: false,
                      description: ''
                  } as IPicture
              ],
        videos,
        bluePrints,
        coordinates,
        streetView,
        virtualTour
    };

    const setActiveAndShowModal = (index, e = null) => {
        e?.preventDefault?.();
        setActive(index);
        showModal(e);
    };

    const showModal = (e: any) => {
        e?.preventDefault?.();
        if (modal) {
            setModal(false);
            document.body.classList.remove('no-scroll');
        } else {
            document.body.classList.add('no-scroll');
            setModal(true);
        }
    };

    const buildOptions = () => {
        const items: Array<menuItems> = [
            {
                title: 'Fotos',
                img: '/icons/picture.svg',
                withContent: mediaContent.images.length > 0
            },
            {
                title: 'Videos',
                img: '/icons/play.svg',
                withContent: mediaContent.videos?.length > 0
            },
            {
                title: 'Tour',
                img: '/icons/360.svg',
                withContent: !!mediaContent.virtualTour
            },
            {
                title: 'Planos',
                img: '/icons/planos.svg',
                withContent: mediaContent.bluePrints?.length > 0
            },
            {
                title: 'Mapa',
                img: '/icons/map.svg',
                withContent: !!mediaContent.coordinates
            },
            {
                title: 'Streetview',
                img: '/icons/street-view.svg',
                withContent: true
            }
        ];
        return items;
    };

    return (
        <div className="w-full">
            <PropertyModal
                fullScreened={fullScreened}
                modal={modal}
                active={active}
                setActive={setActive}
                mediaContent={mediaContent}
                showModal={showModal}
                buildOptions={buildOptions}
            />
            <div className="PropertyMedia w-full">
                <Carousel
                    items={
                        images?.length
                            ? images
                            : [
                                  {
                                      url: '/empty_bg.png',
                                      is_blueprint: false,
                                      description: ''
                                  } as IPicture
                              ]
                    }
                    renderItem={(image, index) => (
                        <button
                            onClick={() => {
                                setActiveAndShowModal(0);
                                setFullScreened({ url: image.url, index });
                            }}
                        >
                            <NextImage
                                loading="lazy"
                                width={512}
                                height={288}
                                src={image.url}
                                fit="cover"
                                showPreview={false}
                                alt=""
                            />
                        </button>
                    )}
                />
                <div className="relative -top-10 w-full bg-transparent px-4">
                    <div className="flex w-full items-center justify-evenly rounded-sm bg-white px-2 py-4 shadow-md">
                        {buildOptions().map((i, index) => (
                            <button
                                key={i.title + index}
                                onClick={i.withContent ? (e) => setActiveAndShowModal(index, e) : () => null}
                                className={twMerge(
                                    'flex h-14 w-14 items-center justify-center rounded-sm text-3xl',
                                    i.withContent ? 'cursor-pointer' : 'cursor-default',
                                    index === active
                                        ? 'bg-black fill-white stroke-white text-white'
                                        : i.withContent
                                          ? 'fill-black stroke-black'
                                          : 'fill-secondary-gray-disabled stroke-secondary-gray-disabled'
                                )}
                            >
                                <ReactSVG src={i.img} />
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const PropertyModal = ({
    modal,
    buildOptions,
    active,
    setActive,
    fullScreened,
    mediaContent,
    showModal
}: IPropertyModalProps) => {
    const [picModal, setPicModal] = useState(false);
    const { coordinates, images, bluePrints, videos, virtualTour } = mediaContent;
    return (
        <div
            className={twMerge(
                modal ? 'PropertyMedia-modal-active' : 'PropertyMedia-modal-hidden',
                picModal ? 'PropertyMedia-modal-glide_bg' : '',
                'PropertyMedia-modal md:pr-14'
            )}
        >
            <div className="menu-modal">
                <div className="pd-property-controls">
                    {buildOptions().map((i, index) => (
                        <div
                            key={index + i.title}
                            onClick={
                                i.withContent && index !== active
                                    ? () => {
                                          setActive(index);
                                          setPicModal(false);
                                      }
                                    : () => null
                            }
                            className={`controls-item-list f-Mark-bold ${
                                index === active ? 'active' : ''
                            } ${i.withContent ? '' : 'inactiveTab'}`}
                        >
                            <span className={`${i.img}`} />
                            <p>{i.title}</p>
                        </div>
                    ))}
                </div>
                <button
                    className="icon-close"
                    onClick={(e) => {
                        showModal(e);
                        setPicModal(false);
                    }}
                />
            </div>
            {active === 0 && modal ? (
                <PhotoGallery
                    fullScreened={fullScreened}
                    idMenuReference={0}
                    active={active}
                    pictures={images}
                    setPicModal={setPicModal}
                />
            ) : null}
            {active === 1 && modal ? <VideoGallery videos={videos} /> : null}
            {active === 2 && modal ? <iframe src={virtualTour} width={1000} height={1000} /> : null}
            {active === 3 && modal ? (
                <PhotoGallery idMenuReference={3} active={active} pictures={bluePrints} setPicModal={setPicModal} />
            ) : null}
            {active === 4 && modal ? <MapsGallery coordinates={coordinates} /> : null}
            {active === 5 && modal ? <StreetView center={{ lng: coordinates[0], lat: coordinates[1] }} /> : null}
        </div>
    );
};
