import React from 'react';

export const CustomMapIcon = (props: React.SVGProps<SVGSVGElement>) => {

  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.70728 15.4197L3.92727 16.0797C3.56727 16.1397 3.20728 15.8397 3.20728 15.4797V3.95977C3.20728 3.65977 3.38728 3.41977 3.68728 3.35977L7.64727 2.69971L7.70728 15.4197Z" stroke="#212322" stroke-width="0.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.70728 15.4197L12.2073 16.1398V3.47974L7.70728 2.69971V15.4197Z" stroke="#212322" stroke-width="0.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.7073 4.47675V14.9398C16.6473 15.2398 16.4073 15.4797 16.1673 15.4797L12.2073 16.1398V4.97972" stroke="#212322" stroke-width="0.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M14.5595 8.20341L12.6395 5.14335C11.9195 3.70335 12.9995 2.08344 14.5595 2.08344C16.1195 2.08344 17.1995 3.76335 16.4795 5.14335L14.5595 8.20341Z" stroke="#212322" stroke-width="0.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M14.5595 5.44332C15.1229 5.44332 15.5795 4.98668 15.5795 4.42335C15.5795 3.86002 15.1229 3.40338 14.5595 3.40338C13.9962 3.40338 13.5396 3.86002 13.5396 4.42335C13.5396 4.98668 13.9962 5.44332 14.5595 5.44332Z" stroke="#212322" stroke-width="0.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};