import { ExclamationCircleFilled } from '@ant-design/icons';
import { PulppoDrawer } from '../Elements/PulppoDrawer';
import NewSectionMetrics from '../Home/NewSectionMetrics';
import useUser from '../../hooks/useUser';
import useMetricSearchId from '../../hooks/useMetric/useMetricSearchId';
import { useEffect, useState } from 'react';
import Button from '../Elements/Button';
import useHomeUser from '../../hooks/useHomeUser';

interface Props {
    show: boolean;
    onClose: () => void;
}

export const Metrics = ({ show, onClose }: Props) => {
    const userLogged = useUser((u) => u.user);
    const homeUser = useHomeUser((u) => u.user);
    const user = userLogged || homeUser;
    const {
        state: { isCompany }
    } = useMetricSearchId({});
    const [viewAgent, setViewAgent] = useState(false);

    useEffect(() => {
        if (user?.showAll) {
            setViewAgent(false);
        } else {
            setViewAgent(true);
        }
    }, [user]);

    return (
        <PulppoDrawer
            visible={show}
            onClose={onClose}
            title={`Métricas${isCompany && !viewAgent ? ` de ${user?.company?.name}` : ''}`}
        >
            <div className="flex h-full flex-col justify-between">
                <div className="flew-grow overflow-y-auto overflow-x-hidden">
                    {isCompany ? (
                        <div className="mb-4 flex">
                            <Button
                                type={!viewAgent ? 'black' : 'secondary'}
                                onClick={() => setViewAgent(false)}
                                className={`w-full ${!viewAgent ? 'cursor-default' : ''}`}
                            >
                                Métricas por equipo
                            </Button>
                            <Button
                                type={viewAgent ? 'black' : 'secondary'}
                                onClick={() => setViewAgent(true)}
                                className={`w-full ${viewAgent ? 'cursor-default' : ''}`}
                            >
                                Métricas propias
                            </Button>
                        </div>
                    ) : null}
                    <div className="flex items-center gap-2 bg-pulppo-status-light-warning px-3 py-4 text-pulppo-status-dark-warning">
                        <ExclamationCircleFilled className="text-xl" />
                        <p>
                            Mejora los niveles de métricas que <b className="font-semibold">tienes en rojo</b>
                        </p>
                    </div>
                    <div className="mt-6 pb-6">
                        <NewSectionMetrics
                            agentId={
                                isCompany && viewAgent
                                    ? `${user?._id}`
                                    : !isCompany && viewAgent
                                      ? `${user?._id}`
                                      : undefined
                            }
                        />
                    </div>
                </div>
            </div>
        </PulppoDrawer>
    );
};
