import { useEffect, useState } from 'react';
import { PulppoDrawer } from './PulppoDrawer';
import { PulppoLoader } from './PulppoLoader';
import {
    AlertOutlined,
    CheckCircleOutlined,
    CheckOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
    QuestionCircleOutlined,
    SearchOutlined,
    WarningOutlined
} from '@ant-design/icons';
import { Button, Modal, notification } from 'antd';
import { IProperty, ISearch } from '@pulppo/pulppo-models';
import useSWR from 'swr';
import { api_url, fetcher, wapp_url } from '../../helpers/fetcher';
import { NextImage } from './NextImage';
import { parsePrice } from '../../utils/parser';
import Icon, { IconType } from '../Icon/Icon';
import axios from '../../utils/axios';
import useChat from '../../hooks/useChat';
import { BROKER_URL } from '@pulppo/pulppo-models/build/utils/config';
import { LoadingButton } from './ButtonLoading';
import { Types } from 'mongoose';
import TextArea from 'antd/lib/input/TextArea';
import { ShowPortal } from '../../utils/ShowPortal';
import { useTranslate } from '@tolgee/react';

export const CancelSearchDrawer = ({
    visible,
    onClose,
    search
}: {
    visible: boolean;
    onClose: (success: boolean) => void;
    search: ISearch;
}) => {
    const [cancel, setCancel] = useState<{ success: boolean; reason: string; summary?: string }>(null);
    const [selectedProperties, setSelectedProperties] = useState<IProperty[]>([]);
    const [input, setInput] = useState('');
    const { data: operations } = useSWR(search?._id && `${api_url}/search/${search._id}/operations`, fetcher);
    const getCancelReason = async (searchId: string | Types.ObjectId) => {
        return axios
            .get(`${api_url}/search/${searchId}/close`)
            .then((res) => {
                if (res.data.success) {
                    onClose(true);
                    return;
                }
                setCancel(res.data);
            })
            .catch(() => setCancel({ success: false, reason: 'error' }));
    };

    const closeSearch = async (
        searchId: string | Types.ObjectId,
        reason: string,
        summary: string,
        description: string
    ) => {
        return axios
            .delete(`${api_url}/search/${searchId}`, {
                data: { reasonToFinish: reason, message: description, summary: summary }
            })
            .then(() => {
                notification.success({
                    message: 'Se ha finalizado la búsqueda',
                    description: 'La búsqueda ha sido finalizada correctamente',
                    duration: 4,
                    placement: 'topRight'
                });
                onClose(true);
            })
            .catch((err) => {
                notification.error({
                    message: `No se pudo cerrar`,
                    description: `${err?.response?.data?.message || err}`
                });
            });
    };
    const { data: suggestedProperties } = useSWR<IProperty[]>(
        search?._id && `${api_url}/search/${search?._id}/suggested`,
        fetcher
    );
    const setChatContact = useChat((store) => store.setChatContact);
    const shareProperties = async (search, properties) => {
        await axios.patch(`${api_url}/search/${search._id}/properties/add`, {
            properties,
            user: search?.agent?.uid,
            source: search?.agent
        });
        await axios.post(`${wapp_url}/message/${search?.agent?.uid}`, {
            to: search?.contact?.phone?.replace(/\D/gi, ''),
            content: `Hola ${search?.contact.firstName}. Quería compartirte esta liga: ${process.env.NEXT_PUBLIC_MI_PULPPO}/${search?.key}. Alli podras ver algunas propiedades que busqué especialmente para ti`,
            attachment: properties?.pictures?.[0]?.url || null
        });
    };
    const startFollowUp = async (search) => {
        await axios.post(`${api_url}/search/${search._id}/followup`).catch((err) => {
            console.log(err);
        });
    };
    const stopFollowUp = async (search) => {
        await axios.delete(`${api_url}/search/${search._id}/followup`).catch((err) => {
            console.log(err);
        });
    };

    useEffect(() => {
        if (search?.automaticFollowUp) {
            setCancel({ success: false, reason: 'in_follow_up' });
            return;
        }
        if (operations?.length) {
            setCancel({ success: false, reason: 'has_operation' });
            return;
        }
        setCancel({ success: false, reason: 'question' });

        // setCancel(null);
        // if (visible && search?._id)

        // getCancelReason(search?._id).then((res) => {
        //     setCancel(res);
        // });
        // else setCancel(null);
    }, [visible, search, operations]);

    useEffect(() => {
        if (suggestedProperties?.length) setSelectedProperties(suggestedProperties);
    }, [suggestedProperties]);

    return (
        <PulppoDrawer
            visible={visible}
            onClose={() => {
                onClose(cancel?.success || false);
            }}
            title="Cerrar búsqueda"
        >
            <div className="h-full">
                {!cancel && <PulppoLoader className="h-full" loading={!cancel}></PulppoLoader>}
                {cancel?.reason === 'question' && (
                    <div className="flex h-full flex-col">
                        <div className="mt-5 flex flex-1 flex-col items-center">
                            <div className="bg-pulppo-status-light-warning p-2">
                                <QuestionCircleOutlined className="text-4xl text-pulppo-status-dark-warning" />
                            </div>
                            <p className="mb-4 mt-2 w-2/3 text-center text-base font-medium">
                                ¿Cómo desea cerrar la búsqueda?
                            </p>
                            <p className="text-center">
                                <b>Ganada</b> si has concretado la operación con el cliente, <br /> <b>Perdida</b> si la
                                búsqueda se ha caído
                            </p>
                        </div>
                        <div className="flex w-full gap-2 py-2">
                            <LoadingButton
                                type="default"
                                onClick={async () => {
                                    await getCancelReason(search?._id);
                                }}
                                className="h-10 flex-1 font-medium"
                            >
                                Perdida
                            </LoadingButton>
                            <Button
                                type="primary"
                                onClick={() => {
                                    Modal.confirm({
                                        title: 'Cargar oferta',
                                        content:
                                            'Para cerrar una búsqueda como ganada, debes cargar una operación asociada a la misma.',
                                        okText: 'Cargar operación',
                                        cancelText: 'Cancelar',
                                        onOk: () => {
                                            window.open(
                                                `${window?.location?.origin}/operations/new?search=${search?._id}`,
                                                '_blank'
                                            );
                                            onClose(false);
                                        }
                                    });
                                }}
                                className="h-10 flex-1 font-medium"
                            >
                                Ganada
                            </Button>
                        </div>
                    </div>
                )}
                {cancel?.reason === 'has_operation' && (
                    <div className="flex h-full flex-col">
                        <div className="mt-5 flex flex-1 flex-col items-center">
                            <div className="bg-pulppo-status-light-warning p-2">
                                <AlertOutlined className="text-4xl text-pulppo-status-dark-warning" />
                            </div>
                            <p className="mb-4 mt-2 w-2/3 text-center text-base font-medium">Búsqueda con operación</p>
                            <p className="text-center">
                                Esta búsqueda esta asociada a una operación en curso. Debes finalizarla para poder
                                cerrar la búsqueda.
                            </p>
                        </div>
                        <div className="flex w-full gap-2 py-2">
                            <Button
                                type="default"
                                onClick={async () => {
                                    onClose(false);
                                }}
                                className="h-10 flex-1 font-medium"
                            >
                                Cancelar
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => {
                                    const { destroy } = ShowPortal({
                                        type: 'operation',
                                        config: {
                                            onClose: () => {
                                                destroy();
                                            },
                                            operation: operations?.[0]
                                        }
                                    });
                                }}
                                className="h-10 flex-1 font-medium"
                            >
                                Ver operación
                            </Button>
                        </div>
                    </div>
                )}
                {['descartado', 'inesperado'].includes(cancel?.reason) && (
                    <div className="flex h-full flex-col">
                        <div className="mt-5 flex flex-1 flex-col items-center gap-6">
                            <p className="text-lg">
                                Motivo de cierre: <span className="font-semibold capitalize">{cancel.reason}</span>
                            </p>
                            {cancel.summary && (
                                <>
                                    {' '}
                                    <p className="w-full text-left">
                                        <b>Pulppo AI</b> analizó la búsqueda y su resumen fue:
                                    </p>
                                    <p className="w-full bg-status-light-disabled p-4 font-semibold text-status-dark-disabled">
                                        &quot;{cancel.summary}
                                        &quot;
                                    </p>
                                </>
                            )}
                            <p className="">
                                Por favor completa el motivo de cierre de esta búsqueda. Este resumen en conjunto con el
                                motivo de Pulppo AI, sera visualizado por los titulares de la inmobiliaria y Pulppo.{' '}
                                <br />
                            </p>
                            <TextArea
                                rows={10}
                                minLength={50}
                                className="rounded-none"
                                value={input}
                                onChange={(val) => {
                                    setInput(val.target.value);
                                }}
                                placeholder="Escribe aquí el motivo de cierre"
                            />
                            <div className="flex w-full justify-between">
                                <p className="flex-1 text-strong-red">
                                    {input.length < 50 && input.length > 0 && (
                                        <span>Escribe por lo menos 50 caracteres</span>
                                    )}
                                </p>
                                <p className="text-pulppo-status-dark-disabled">{`${input.length}/50`}</p>
                            </div>
                        </div>
                        <div className="flex w-full gap-2 py-2">
                            <LoadingButton
                                type="default"
                                onClick={async () => {
                                    onClose(false);
                                }}
                                className="h-10 flex-1 font-medium"
                            >
                                Cancelar
                            </LoadingButton>
                            <Button
                                disabled={input?.length < 50}
                                type="primary"
                                onClick={() => {
                                    closeSearch(search._id, cancel.reason, cancel?.summary || '', input);
                                    onClose(true);
                                }}
                                className="h-10 flex-1 font-medium"
                            >
                                Cerrar búsqueda
                            </Button>
                        </div>
                    </div>
                )}
                {cancel?.reason === 'in_follow_up' && (
                    <div className="flex h-full flex-col">
                        <div className="mt-4 flex flex-1 flex-col items-center">
                            <div className="bg-pulppo-status-light-warning p-2">
                                <AlertOutlined className="text-5xl text-pulppo-status-dark-warning" />
                            </div>
                            <p className="w-2/3 pb-4 pt-2 text-center text-base font-medium">
                                Búsqueda con seguimiento automático
                            </p>
                            <p className="text-center">
                                La búsqueda se encuentra en <b>seguimiento automático.</b> No se puede cerrar hasta
                                finalizar el seguimiento. <br />
                                Haz clic en <b>Cancelar seguimiento</b> si prefieres realizar el seguimiento
                                manualmente. <br />
                                De lo contrario, haz clic en <b>Continuar</b> para mantener el seguimiento automático.
                            </p>
                        </div>
                        <div className="flex w-full gap-2 py-2">
                            <LoadingButton
                                onClick={async () => {
                                    setCancel({
                                        reason: 'descartado',
                                        success: false,
                                        summary:
                                            'El cliente nunca respondió, y no se le envió los toques suficientes (4)'
                                    });
                                }}
                                danger
                                className="h-auto w-min py-2 font-medium"
                            >
                                <DeleteOutlined />
                            </LoadingButton>
                            <LoadingButton
                                type="default"
                                onClick={async () => {
                                    await stopFollowUp(search);
                                    onClose(true);
                                }}
                                className="h-10 flex-1 font-medium"
                            >
                                Cancelar seguimiento
                            </LoadingButton>
                            <Button
                                type="primary"
                                onClick={() => {
                                    onClose(false);
                                }}
                                className="h-10 flex-1 font-medium"
                            >
                                Continuar
                            </Button>
                        </div>
                    </div>
                )}
                {cancel?.success && (
                    <div className="flex h-full flex-col">
                        <div className="mt-20 flex flex-1 flex-col items-center gap-6">
                            <div className="bg-pulppo-status-light-success p-2">
                                <CheckCircleOutlined className="text-5xl text-pulppo-status-dark-success" />
                            </div>
                            <p className="w-2/3 text-center text-lg font-medium">Búsqueda cerrada con éxito</p>
                            <p className="text-center text-base font-medium text-gray-400">
                                La búsqueda ha sido cerrada exitosamente.
                            </p>
                        </div>
                        <Button
                            type="primary"
                            onClick={() => {
                                onClose(cancel?.success);
                            }}
                            className="h-auto w-full py-2 font-medium"
                        >
                            Cerrar
                        </Button>
                    </div>
                )}
                {!cancel?.success && cancel?.reason === 'follow_up' && (
                    <div className="flex h-full flex-col">
                        <div className="mt-16 flex flex-1 flex-col items-center">
                            <img src="info.png" alt="info" />
                            <p className="my-2 text-center font-semibold">El cliente no ha respondido</p>
                            <div className="w-3/4">
                                <p className="mb-4">
                                    No hubo suficientes intentos para contactar al cliente. Puedes optar por una de las
                                    siguientes opciones:
                                </p>
                                <ul
                                    className=""
                                    style={{
                                        listStyleType: 'disc',
                                        listStylePosition: 'inside'
                                    }}
                                >
                                    <li>
                                        Automático: enviaremos mensajes desde tu WhatsApp periódicamente para lograr
                                        recibir respuesta del cliente. En caso de no recibir respuesta cerraremos la
                                        búsqueda.
                                    </li>
                                    <li className="mt-2">
                                        Manual: continúa el seguimiento de manera manual utilizando los 4 toques.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="flex w-full gap-2">
                            <LoadingButton
                                onClick={async () => {
                                    setCancel({
                                        reason: 'descartado',
                                        success: false,
                                        summary:
                                            'El cliente nunca respondió, y no se le envió los toques suficientes (4)'
                                    });
                                }}
                                danger
                                className="h-auto w-min py-2 font-medium"
                            >
                                <DeleteOutlined />
                            </LoadingButton>
                            <Button
                                onClick={() => {
                                    onClose(false);
                                    setChatContact({
                                        ...search.contact,
                                        message:
                                            'Hola! Aún sigues buscando propiedades? Hazme saber como puedo ayudarte'
                                    });
                                }}
                                className="h-auto w-full py-2 font-medium"
                            >
                                Manual
                            </Button>
                            <LoadingButton
                                type="primary"
                                onClick={async () => {
                                    await startFollowUp(search);
                                    onClose(true);
                                }}
                                className="h-auto w-full py-2 font-medium"
                            >
                                Automático
                            </LoadingButton>
                        </div>
                    </div>
                )}
                {!cancel?.success && cancel?.reason === 'share_properties' && (
                    <div className="flex h-full flex-col">
                        <div className="flex flex-1 flex-col items-center gap-6">
                            <div className="rounded bg-pulppo-status-light-warning p-2">
                                <WarningOutlined className="text-5xl text-pulppo-status-dark-warning" />
                            </div>
                            <p className="w-2/3 text-center text-lg font-medium">
                                Estás cerrando la búsqueda sin compartirle más opciones
                            </p>
                            <p className="text-center text-base font-medium text-gray-400">
                                Creemos que aún hay propiedades de Pulppo para ofrecerle a este cliente. Intenta
                                compartirle mas opciones. <br />
                                Aqui hay algunas que coinciden con su búsqueda:
                            </p>
                            <div className="flex w-full flex-col items-center gap-2 overflow-y-auto">
                                {suggestedProperties?.slice(0, 3).map((prop) => (
                                    <PropertyRow
                                        key={prop._id?.toString()}
                                        property={prop}
                                        checked={selectedProperties?.some(
                                            (el) => el._id?.toString() === prop._id?.toString()
                                        )}
                                        onCheck={() => {
                                            const index = selectedProperties.findIndex(
                                                (el) => el._id?.toString() === prop._id?.toString()
                                            );
                                            if (index === -1)
                                                setSelectedProperties((selectedProperties) => [
                                                    ...selectedProperties,
                                                    prop
                                                ]);
                                            else
                                                setSelectedProperties((selectedProperties) =>
                                                    selectedProperties.filter(
                                                        (el) => el._id?.toString() !== prop._id?.toString()
                                                    )
                                                );
                                        }}
                                    />
                                ))}
                                <a
                                    className="mt-4 flex cursor-pointer items-center gap-1 text-base underline underline-offset-2 hover:font-medium hover:text-black"
                                    href={`${BROKER_URL}/new-searches/${search?._id}?suggestedPropertiesFrom=true`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Buscar más propiedades
                                    <SearchOutlined className="text-lg" />
                                </a>
                            </div>
                        </div>
                        <div className="flex w-full gap-2">
                            <LoadingButton
                                onClick={async () => {
                                    setCancel({
                                        reason: 'descartado',
                                        success: false,
                                        summary: 'No se le ha compartido más propiedades al cliente en la búsqueda.'
                                    });
                                }}
                                className="h-auto w-full py-2 font-medium"
                            >
                                Cerrar búsqueda
                            </LoadingButton>
                            <LoadingButton
                                disabled={!selectedProperties?.length}
                                type="primary"
                                onClick={async () => {
                                    await shareProperties(search, selectedProperties);
                                    onClose(true);
                                }}
                                className="h-auto w-full py-2 font-medium"
                            >
                                Compartir propiedades
                            </LoadingButton>
                        </div>
                    </div>
                )}
                {cancel && !cancel?.success && cancel.reason === 'error' && (
                    <div className="flex h-full flex-col">
                        <div className="mt-20 flex flex-1 flex-col items-center gap-6">
                            <div className="bg-pulppo-status-light-error p-2">
                                <CloseCircleOutlined className="text-5xl text-pulppo-status-dark-error" />
                            </div>
                            <p className="w-2/3 text-center text-lg font-medium">Ocurrió un problema</p>
                            <p className="text-center text-base font-medium text-gray-400">
                                Lo sentimos, no se pudo cerrar la búsqueda en este momento. Vuelve a intentarlo más
                                tarde.
                            </p>
                        </div>
                        <Button
                            type="primary"
                            onClick={() => {
                                onClose(false);
                            }}
                            className="h-auto w-full py-2 font-medium"
                        >
                            Cerrar
                        </Button>
                    </div>
                )}
            </div>
        </PulppoDrawer>
    );
};

const PropertyRow = ({
    property,
    checked,
    onCheck
}: {
    property: IProperty;
    checked: boolean;
    onCheck: (value: boolean) => void;
}) => {
    const { t } = useTranslate('common');
    return (
        <div
            className={`w-full border border-solid border-gray-200 p-2 ${
                property?.status?.last === 'cancelled' ? 'bg-light-background' : ''
            }`}
        >
            <div className="flex w-full items-center gap-4 border-b border-solid border-gray-200 pb-2">
                <div className="flex-0 h-fit shrink-0">
                    <NextImage
                        className="h-20 w-20"
                        src={property?.pictures?.[0]?.url}
                        alt="thumb"
                        showPreview={false}
                        width={250}
                    />
                </div>
                <div className="shrink-1 flex w-1/5 flex-1 flex-col justify-between">
                    <p className="w-full truncate text-lg font-medium">
                        {property?.address?.street || property?.listing?.title || '-'}
                    </p>
                    <p className="truncate text-base text-gray-400">
                        {`${property?.address?.neighborhood?.name || property?.address?.city?.name}`}
                    </p>
                    <p className="text-base font-medium">
                        {`${parsePrice({
                            price: Math.round(property?.listing?.price?.price),
                            startStr: `${property?.listing?.price?.currency || process.env.NEXT_PUBLIC_PRIMARY_CURRENCY} `
                        })}`}
                    </p>
                </div>
                <div
                    className="cursor-pointer p-2"
                    onClick={(e) => {
                        e.stopPropagation();
                        onCheck(!checked);
                    }}
                >
                    <div
                        className={`flex h-7 w-7 items-center justify-center ${
                            checked ? 'bg-black' : 'border border-solid border-gray-200 bg-white'
                        }`}
                    >
                        {checked && <CheckOutlined className="p-1 text-lg text-white" />}
                    </div>
                </div>
            </div>
            <div className="mt-2 flex w-full justify-around">
                <div className="flex items-center justify-center gap-1">
                    <p className="font-semibold">{Math.round(property?.attributes?.suites)}</p>
                    <p className="hidden text-sm md:block">{t('suites', 'recámaras')}</p>
                    <Icon className="md:hidden" width={18} height={18} name={IconType.Bed} />
                </div>
                <div className="h-6 w-px bg-gray-200"></div>
                <div className="flex items-center justify-center gap-1">
                    <p className="font-semibold">{Math.round(property?.attributes?.bathrooms)}</p>
                    <p className="hidden text-sm md:block">{t('bathrooms', 'baños')}</p>
                    <Icon className="md:hidden" width={18} height={18} name={IconType.Bathroom} />
                </div>
                <div className="h-6 w-px bg-gray-200" />
                <div className="flex items-center justify-center gap-1">
                    <p className="font-semibold">{Math.round(property?.attributes?.parkings)}</p>
                    <p className="hidden text-sm md:block">{t('parkings', 'Estac.')}</p>
                    <Icon className="md:hidden" width={18} height={18} name={IconType.Garage} />
                </div>
                <div className="h-6 w-px bg-gray-200" />
                <div className="flex items-center justify-center gap-1">
                    <p className="font-semibold">{property?.attributes?.roofedSurface}</p>
                    <Icon className="md:hidden" width={18} height={18} name={IconType.Mts} />
                    <p className="hidden text-sm md:block">m2</p>
                </div>
                <div className="h-6 w-px bg-gray-200" />
                <div className="flex items-center justify-center gap-1">
                    <p className="font-semibold">
                        {property?.attributes?.yearBuild > 0 ? property.attributes.yearBuild : new Date().getFullYear()}
                    </p>
                </div>
            </div>
        </div>
    );
};
