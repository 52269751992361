import AvatarUser from '../Elements/AvatarUser';
import { ISimpleProperty, ISimpleUser, IVisit } from '@pulppo/pulppo-models';
import Button from '../Elements/Button';
import {
    CheckCircleFilled,
    CloseOutlined,
    DeleteOutlined,
    LoadingOutlined,
    MessageOutlined,
    StarFilled
} from '@ant-design/icons';
import { useCallback, useEffect, useState } from 'react';
import AvatarProperty from '../Property/AvatarProperty';
import useChat from '../../hooks/useChat';
import CalendarInput from '../Elements/CalendarInput';
import dayjs, { Dayjs } from 'dayjs';
import { api_url, fetcher, instance } from '../../helpers/fetcher';
import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { PulppoMap, PulppoMarker } from '../Elements/PulppoMap';
import { DEFAULT_COORDINATES } from '../../utils/coordinates';
import { Collapse, Spin } from 'antd';
import useSWR from 'swr';
import { ID } from '../../types';
import { twMerge } from 'tailwind-merge';
import { CancelDrawer } from './CancelDrawer';
import { NoteDrawer } from './NoteDrawer';
import { ShareDrawer } from './ShareDrawer';
import useUser from '../../hooks/useUser';
import modal from 'antd/lib/modal';
import PulppoNewTimePicker from '../Elements/PulppoNewTimePicker';

export interface IFormVisit {
    onSave: (shouldClose: boolean) => Promise<void>;
    visit: Partial<Omit<IVisit, 'contact' | 'search'>> & {
        search: ID;
        contact: ISimpleUser;
    };
    property?: ISimpleProperty;
}

type IVisitStep = Omit<IVisit['steps'][0], 'date'> & { date: dayjs.Dayjs };

// todo ver en todas las llamadas que onda
// todo ver visita

const DrawerFormVisit = ({ visit: initialVisit, onSave, property = null, show, onClose }: IDrawerFormVisit) => {
    const setChatContact = useChat((store) => store.setChatContact);
    const [visit, setVisit] = useState(initialVisit);
    const { data: initSharedProperties } = useSWR<Array<ISimpleProperty>>(
        visit?.search && `${api_url}/search/${visit.search}/properties`,
        fetcher
    );
    const [visitSteps, setVisitSteps] = useState<Array<IVisitStep>>(
        visit?.steps?.length ? visit?.steps?.map((v) => ({ ...v, date: dayjs(v.date).tz() })) : []
    );
    const [showCancel, setShowCancel] = useState(false);
    const [showShare, setShowShare] = useState(false);
    const [sharedProperties, setSharedProperties] = useState<Array<ISimpleProperty>>(initSharedProperties);
    const [selectDate, setSelectDate] = useState<Dayjs>(
        visit?.startTime ? dayjs(visit?.startTime).tz() : dayjs().add(1, 'day').tz()
    );
    const [showMap, setShowMap] = useState(false);
    const [hoursUsed, setHoursUsed] = useState<Array<number>>([]);
    const [isLoadingMoreProperties, setIsLoadingMoreProperties] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useUser();
    const notEditable =
        !!visit?._id &&
        (visit?.status?.last === 'cancelled' ||
            visit?.status?.last === 'completed' ||
            (visit?.status?.last === 'confirmed' && visit?.status?.last && dayjs(visit?.startTime).isBefore(dayjs())));
    const close = useCallback(
        (closeParent: boolean = false, cb = () => {}) => {
            if (!visit?._id && visitSteps?.length) {
                modal.confirm({
                    title: 'Cambios sin guardar',
                    content: `Los cambios que has realizado no se han guardado. Si cierras esta pantalla ahora los perderas. Vuelve atras y haz click en "Guardar" si deseas conservarlos.`,
                    okText: 'Cerrar sin guardar',
                    cancelText: 'Volver',
                    onOk: () => {
                        cb?.();
                        onClose(closeParent);
                    }
                });
            } else {
                onClose(closeParent);
                cb?.();
            }
        },
        [onClose, visit, visitSteps]
    );
    useEffect(() => {
        setSharedProperties(
            initSharedProperties?.filter((p) => !visitSteps.find((tp) => tp?.property?._id === p?._id))
        );
    }, [initSharedProperties]);
    useEffect(() => {
        setVisit(initialVisit);
    }, [initialVisit]);

    useEffect(() => {
        if (!visit.startTime || !dayjs(visit.startTime).tz().isSame(selectDate, 'day')) {
            setVisitSteps((visitSteps) =>
                visitSteps.map((visitStep) => ({
                    ...visitStep,
                    date: selectDate.set('hour', visitStep.date.hour()).set('minute', visitStep.date.minute())
                }))
            );
        }
    }, [selectDate]);

    useEffect(() => {
        onSaveVisit();
        if (visitSteps.length) {
            setHoursUsed(visitSteps.map((visitStep) => visitStep?.date?.hour()));
        } else {
            setHoursUsed([]);
        }
    }, [visitSteps]);
    useEffect(() => {
        if (property) {
            addTourProperty(property);
        }
    }, [property]);

    const availableButton = visitSteps?.length && !visitSteps.some((visitStep) => !visitStep.confirmed);

    const addTourProperty = (property: ISimpleProperty) => {
        if (visitSteps?.some((step) => step.property?._id?.toString() === property?._id?.toString())) return;
        setVisitSteps((v) => [
            ...v,
            {
                property,
                confirmed: false,
                notes: [],
                date: v?.length
                    ? v
                          ?.sort((a, b) => b.date.valueOf() - a.date.valueOf())?.[0]
                          ?.date?.clone()
                          ?.add(30, 'minutes')
                    : selectDate.clone().set('hour', 9).set('minute', 0)
            }
        ]);
        setSharedProperties((p) => p?.filter((sp) => sp?._id !== property?._id));
    };

    const removeTourVisitStep = useCallback(
        (visitStep: IVisitStep) => {
            if (visit._id && visitSteps?.length === 1) {
                setShowCancel(true);
                return;
            }
            setSharedProperties((p) => [...p, visitStep?.property]);
            setVisitSteps((v) => v.filter((vs) => vs?.property?._id !== visitStep?.property?._id));
        },
        [visit, visitSteps]
    );

    const onSaveVisit = (force = false) => {
        if (notEditable) return;
        if (visit._id) {
            if (visitSteps.length === 0) {
                setShowCancel(true);
            } else {
                if (visitSteps?.some((step) => step.date.isBefore(dayjs()))) {
                    modal.confirm({
                        title: 'Atención',
                        content:
                            'La fecha seleccionada es en el pasasdo. Si deseas registrar una visita que ya se realizó haz click en confirmar, de lo contrario regresa y edita la fecha o el horario de la visita.',
                        okText: 'Confirmar',
                        cancelText: 'Volver',
                        onOk: () => {
                            setIsLoading(true);

                            instance
                                .patch(`${api_url}/visit/${visit._id}`, {
                                    steps: visitSteps.map((vs) => ({ ...vs, date: vs.date.toDate(), confirmed: true }))
                                })
                                .then((res) => {
                                    onSave(false);
                                    setVisit(res.data);
                                })
                                .catch((err) => {
                                    console.error(err);
                                })
                                .finally(() => {
                                    setIsLoading(false);
                                });
                        }
                    });
                } else {
                    setIsLoading(true);

                    instance
                        .patch(`${api_url}/visit/${visit._id}`, {
                            steps: visitSteps.map((vs) => ({ ...vs, date: vs.date.toDate() }))
                        })
                        .then((res) => {
                            onSave(false);
                            setVisit(res.data);
                        })
                        .catch((err) => {
                            console.error(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        });
                }
            }
        } else if (force || visitSteps?.some((step) => step.confirmed)) {
            if (visitSteps?.some((step) => step.date.isBefore(dayjs()))) {
                modal.confirm({
                    title: 'Atención',
                    content:
                        'La fecha seleccionada es en el pasasdo. Si deseas registrar una visita que ya se realizó haz click en confirmar, de lo contrario regresa y edita la fecha o el horario de la visita.',
                    okText: 'Confirmar',
                    cancelText: 'Volver',
                    onOk: () => {
                        setIsLoading(true);

                        instance
                            .post(`${api_url}/visit/${visit.search}`, {
                                createdBy: 'agent',
                                steps: visitSteps.map((vs) => ({ ...vs, date: vs.date.toDate(), confirmed: true }))
                            })
                            .then((res) => {
                                onSave(false);
                                setVisit(res.data);
                            })
                            .catch((err) => {
                                console.error(err);
                            })
                            .finally(() => {
                                setIsLoading(false);
                            });
                    }
                });
            } else {
                setIsLoading(true);

                instance
                    .post(`${api_url}/visit/${visit.search}`, {
                        createdBy: 'agent',
                        steps: visitSteps.map((vs) => ({ ...vs, date: vs.date.toDate() }))
                    })
                    .then((res) => {
                        onSave(false);
                        setVisit(res.data);
                    })
                    .catch((err) => {
                        console.error(err);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        }
    };
    return (
        <PulppoDrawer
            visible={show}
            onClose={() => {
                close();
            }}
            title="Recorrido"
            className=""
        >
            <section className="-mx-2 flex h-full flex-col">
                <div className="flex-1 overflow-y-auto pb-4">
                    {!notEditable && (
                        <p className="text-pulppo-primary-black">
                            Confirma las propiedades, puedes cambiar la fecha, hora o eliminarla.
                        </p>
                    )}
                    <div className="mt-4 flex items-center justify-between border border-solid border-pulppo-secondary-gray-disabled px-2 py-3">
                        <AvatarUser
                            user={visit.contact}
                            description="Cliente"
                            onClick={() => {
                                close(true, () => {
                                    window?.location?.replace(
                                        `${window?.location?.origin}/new-searches/${visit?.search}`
                                    );
                                });
                            }}
                        />
                        <Button
                            type="secondary"
                            onClick={() => {
                                close(true);
                                setChatContact(visit?.contact);
                            }}
                        >
                            <MessageOutlined className="text-lg" />
                        </Button>
                    </div>
                    {notEditable ? (
                        <div className="mt-4 flex h-10 items-center rounded-none border border-solid border-pulppo-secondary-gray-line px-4">
                            <p>Fecha del recorrido: {dayjs(visit?.startTime).format('DD/MM/YYYY')}</p>
                        </div>
                    ) : (
                        <div className="mt-4">
                            <CalendarInput
                                showPrevDays={true}
                                value={selectDate}
                                disabled={notEditable}
                                onChange={setSelectDate}
                                placeholderValue="Fecha de recorrido:"
                            />
                            <div className="flex w-full items-center justify-between gap-2 lg:hidden">
                                <p className="w-1/2 shrink-0 font-semibold">Fecha del recorrido: </p>

                                <input
                                    className="rounded-none border border-solid border-pulppo-secondary-gray-line px-4 py-1"
                                    value={selectDate?.format('YYYY-MM-DD')}
                                    onChange={(ev) => setSelectDate(dayjs(ev.target.value))}
                                    type="date"
                                ></input>
                                {/* <MobileDateInput
                            value={selectDate}
                            onChange={(value) => setSelectDate(value)}
                            showPrevDays={showPrevDays}
                        /> */}
                            </div>
                        </div>
                    )}
                    {visitSteps.length ? (
                        <div className="mt-6">
                            <div className="flex items-center justify-between">
                                <h2 className="font-semibold text-pulppo-primary-black">
                                    Propiedades del recorrido ({visitSteps.length})
                                </h2>
                                <Button
                                    key="mapa"
                                    type="secondary"
                                    className="flex h-10 items-center justify-center gap-2 px-4"
                                    onClick={() => setShowMap(true)}
                                >
                                    <p>Ver mapa</p>

                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M13.6407 0.0211058C12.913 0.154699 12.6563 0.235559 12.2133 0.471106C11.0145 1.10743 10.227 2.33087 10.1391 3.69142C10.0969 4.31017 10.2516 4.89728 10.6911 5.78322C11.2114 6.84493 12.1571 8.19493 13.3278 9.56603C13.8059 10.125 13.9817 10.2164 14.2946 10.0688C14.5864 9.92814 16.1544 7.93478 16.861 6.80626C17.7926 5.31212 18.109 4.32071 17.9508 3.36798C17.6766 1.69454 16.4848 0.435949 14.8536 0.0879021C14.5829 0.0281363 13.82 -0.0105352 13.6407 0.0211058ZM14.8536 1.24806C15.859 1.54337 16.6008 2.36251 16.8188 3.4172C16.8505 3.56486 16.8751 3.77931 16.8751 3.89181C16.8751 4.59493 16.2141 5.87814 15.0188 7.48829C14.6391 8.00157 14.0907 8.68361 14.0626 8.68361C13.9993 8.68361 12.8989 7.23868 12.4559 6.57423C11.8969 5.73751 11.4434 4.85509 11.3169 4.35939C11.1376 3.66329 11.3801 2.77384 11.918 2.134C12.6352 1.2797 13.7848 0.931652 14.8536 1.24806Z"
                                            fill="black"
                                        />
                                        <path
                                            d="M13.6864 2.30273C12.8496 2.48555 12.2696 3.34336 12.4067 4.19414C12.5543 5.10469 13.4192 5.7375 14.3192 5.59336C15.2297 5.4457 15.8625 4.58086 15.7184 3.68086C15.6621 3.32578 15.5004 3.0164 15.2438 2.75976C14.9871 2.50312 14.7446 2.36953 14.4246 2.30273C14.1364 2.23945 13.9711 2.23945 13.6864 2.30273ZM14.2875 3.42422C14.3508 3.45234 14.4457 3.52617 14.4985 3.59297C14.6813 3.83555 14.6637 4.10976 14.4493 4.32422C14.2172 4.55625 13.9184 4.55976 13.6793 4.33125C13.2293 3.90234 13.711 3.18515 14.2875 3.42422Z"
                                            fill="black"
                                        />
                                        <path
                                            d="M3.1957 5.65313C1.6207 6.28594 0.284766 6.83086 0.232031 6.86602C0.179297 6.90117 0.105469 6.99609 0.0667969 7.07695C0.00351563 7.22109 0 7.39688 0 12.3926V17.5605L0.0808594 17.7012C0.165234 17.8559 0.404297 18 0.569531 18C0.622266 18 1.90898 17.5043 3.42773 16.8961L6.1875 15.7922L8.94727 16.8961C10.466 17.5043 11.7527 18 11.8125 18C11.9426 18 17.6238 15.7359 17.7785 15.6234C18.0141 15.4477 18.0035 15.6199 17.993 11.7352C17.9824 8.2582 17.9824 8.23008 17.9086 8.13516C17.7715 7.94883 17.659 7.89258 17.4375 7.89258C17.216 7.89258 17.1035 7.94883 16.9664 8.13516C16.8926 8.23008 16.8926 8.26875 16.875 11.5242L16.8574 14.8148L14.6777 15.6867C13.4789 16.1684 12.4699 16.5691 12.4383 16.5797C12.3785 16.5973 12.375 16.4215 12.368 13.5387C12.3574 10.5117 12.3574 10.4801 12.2836 10.3852C12.1465 10.1988 12.034 10.1426 11.8125 10.1426C11.591 10.1426 11.4785 10.1988 11.3414 10.3852C11.2676 10.4801 11.2676 10.5117 11.257 13.5387C11.25 16.4215 11.2465 16.5973 11.1867 16.5797C11.1551 16.5691 10.1461 16.1684 8.94727 15.6867L6.76758 14.8148L6.75703 10.3605C6.75352 7.91016 6.75703 5.90625 6.76758 5.90625C6.77813 5.90625 7.38281 6.14531 8.11055 6.43711C9.33047 6.92578 9.45352 6.96797 9.61875 6.95391C9.71719 6.94336 9.83672 6.9082 9.88945 6.86953C10.009 6.78516 10.125 6.55313 10.125 6.40195C10.125 6.25781 10.0125 6.03984 9.9 5.96602C9.72422 5.85 6.29297 4.5 6.17695 4.5C6.11367 4.50352 4.82344 4.99922 3.1957 5.65313ZM5.61797 10.3605L5.60742 14.8148L3.42773 15.6867C2.22891 16.1684 1.21992 16.5691 1.18477 16.5797C1.12852 16.5973 1.125 16.3758 1.13203 12.143L1.14258 7.68516L3.35742 6.7957C4.57734 6.31055 5.58281 5.90977 5.60039 5.90625C5.61445 5.90625 5.62148 7.91016 5.61797 10.3605Z"
                                            fill="black"
                                        />
                                    </svg>
                                </Button>
                            </div>
                            <div className="mt-2 space-y-2">
                                {visitSteps.map((visitStep) => {
                                    return (
                                        <TourProperty
                                            onClose={() => {
                                                close(true);
                                            }}
                                            key={`tour-property-${visitStep.property?._id}`}
                                            notEditable={notEditable || isLoading}
                                            visit={visit as any}
                                            user={user}
                                            visitStep={visitStep}
                                            onRemove={removeTourVisitStep}
                                            selectDate={selectDate}
                                            searchId={`${visit.search}`}
                                            disableDelete={notEditable}
                                            hoursUsed={hoursUsed}
                                            updateVisitStep={(visitStep) =>
                                                setVisitSteps((visitSteps) => {
                                                    return visitSteps
                                                        .map((oldVisitStep) =>
                                                            oldVisitStep.property._id === visitStep?.property?._id
                                                                ? visitStep
                                                                : oldVisitStep
                                                        )
                                                        .sort((a, b) =>
                                                            a?.date?.hour() === 0
                                                                ? 1
                                                                : a?.date?.isBefore(b?.date)
                                                                  ? -1
                                                                  : 1
                                                        );
                                                })
                                            }
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    ) : null}
                    {!notEditable && (
                        <div className="mt-6">
                            <h2 className="font-semibold text-pulppo-primary-black">
                                Propiedades de la búsqueda ({sharedProperties?.length})
                            </h2>
                            {!sharedProperties?.length ? (
                                <Spin spinning={isLoadingMoreProperties}>
                                    <div className="mt-2 flex flex-col items-center border border-solid border-pulppo-secondary-gray-disabled px-4 pb-4 pt-8">
                                        <div className="flex">
                                            <img className="px-8" src="/icons/houseSearch.svg" alt="empty" />
                                            <div>
                                                <p className="text-base font-medium text-pulppo-primary-black">
                                                    Tenemos más propiedades en Pulppo para visitar
                                                </p>
                                                <p className="text-base font-medium text-pulppo-primary-gray">
                                                    Aprovecha el recorrido para añadir nuevas propiedades.
                                                </p>
                                            </div>
                                        </div>
                                        <button
                                            type="button"
                                            className="mt-4 text-pulppo-primary-black underline"
                                            onClick={() => {
                                                close(true, () => {
                                                    window?.location?.replace(
                                                        `${window?.location?.origin}/new-searches/${visit?.search}?list=true`
                                                    );
                                                });
                                            }}
                                        >
                                            Buscar más propiedades
                                        </button>
                                    </div>
                                </Spin>
                            ) : (
                                <div className="mt-2 border border-solid border-pulppo-secondary-gray-disabled px-2">
                                    {sharedProperties?.map((property, index) => {
                                        return (
                                            <div
                                                className={twMerge(
                                                    'flex w-full items-center justify-between py-2',
                                                    index < sharedProperties?.length - 1
                                                        ? 'border-b border-solid border-pulppo-secondary-gray-disabled'
                                                        : ''
                                                )}
                                                key={`shared-property-${property?._id}`}
                                            >
                                                <div className="w-3/4 pr-2">
                                                    <AvatarProperty
                                                        className={{
                                                            label: 'font-semibold text-pulppo-primary-black',
                                                            description:
                                                                'text-sm font-medium text-pulppo-primary-black',
                                                            image: 'rounded-none'
                                                        }}
                                                        size="large"
                                                        property={property}
                                                        description="price"
                                                        tag="isMLS"
                                                    />
                                                </div>

                                                <Button
                                                    type="secondary"
                                                    className="w-1/4 shrink-0"
                                                    onClick={() => addTourProperty(property)}
                                                >
                                                    Añadir
                                                </Button>
                                            </div>
                                        );
                                    })}
                                    <p
                                        onClick={() => {
                                            close(true, () => {
                                                window?.location?.replace(
                                                    `${window?.location?.origin}/new-searches/${visit?.search}?list=true`
                                                );
                                            });
                                        }}
                                        className="my-3 w-full cursor-pointer text-center font-semibold underline"
                                    >
                                        Buscar más propiedades
                                    </p>
                                </div>
                            )}
                        </div>
                    )}
                    {notEditable && (
                        <Collapse className="mt-2 w-full border-0 bg-white" expandIconPosition="end">
                            <Collapse.Panel
                                className="border border-b border-solid border-pulppo-secondary-gray-line p-2 [&>div.ant-collapse-content>div]:p-0 [&>div]:border-0"
                                header={
                                    <div className="flex items-center gap-2">
                                        <StarFilled className="text-lg text-pulppo-primary-yellow" />
                                        <p className="text-pulppo-primary-black">
                                            Review {visit?.survey?.status !== 'answered' ? '(Sin responder)' : ''}
                                        </p>
                                    </div>
                                }
                                key="review"
                            >
                                {visit?.survey?.data?.avg ? (
                                    <>
                                        <p className="px-2 text-xs text-pulppo-primary-gray">
                                            “{visit?.survey?.data?.comment || 'Sin comentarios'}”
                                        </p>
                                        <div className="mt-4 grid grid-cols-4 gap-x-4">
                                            <div className="border border-solid border-pulppo-secondary-gray-disabled p-2 text-pulppo-primary-black">
                                                <p className="flex items-center justify-center gap-x-0.5 font-semibold">
                                                    {visit?.survey?.data?.expectation}
                                                    <StarFilled className="text-lg text-pulppo-primary-yellow" />
                                                </p>
                                                <p className="text-center text-xs">Expectativa</p>
                                            </div>
                                            <div className="border border-solid border-pulppo-secondary-gray-disabled p-2 text-pulppo-primary-black">
                                                <p className="flex items-center justify-center gap-x-0.5 font-semibold">
                                                    {visit?.survey?.data?.location}{' '}
                                                    <StarFilled className="text-lg text-pulppo-primary-yellow" />
                                                </p>
                                                <p className="text-center text-xs">Ubicación</p>
                                            </div>
                                            <div className="border border-solid border-pulppo-secondary-gray-disabled p-2 text-pulppo-primary-black">
                                                <p className="flex items-center justify-center gap-x-0.5 font-semibold">
                                                    {visit?.survey?.data?.cleaning}{' '}
                                                    <StarFilled className="text-lg text-pulppo-primary-yellow" />
                                                </p>
                                                <p className="text-center text-xs">Limpieza</p>
                                            </div>
                                            <div className="border border-solid border-pulppo-secondary-gray-disabled p-2 text-pulppo-primary-black">
                                                <p className="flex items-center justify-center gap-x-0.5 font-semibold">
                                                    {visit?.survey?.data?.agent}
                                                    <StarFilled className="text-lg text-pulppo-primary-yellow" />
                                                </p>
                                                <p className="text-center text-xs">Atención</p>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="flex h-20 w-full items-center justify-center px-2">
                                        <p>
                                            El cliente aún no ha respondido la encuesta. Contáctalo para que la
                                            responda.{' '}
                                        </p>
                                    </div>
                                )}
                            </Collapse.Panel>
                        </Collapse>
                    )}
                </div>

                {!notEditable && (
                    <div className="flex w-full shrink-0 flex-col items-center justify-center gap-4 bg-white pt-2">
                        {visit?._id ? (
                            <Tooltip
                                message={'Confirma o elimina las propiedades para avanzar'}
                                show={!availableButton}
                            >
                                <Button
                                    disabled={!availableButton}
                                    onClick={() => setShowShare(true)}
                                    className="h-11 w-full"
                                >
                                    {isLoading ? (
                                        <p>
                                            Guardando... <LoadingOutlined />
                                        </p>
                                    ) : (
                                        'Compartir recorrido'
                                    )}
                                </Button>
                            </Tooltip>
                        ) : (
                            <Button
                                disabled={visitSteps?.some((step) => !step?.date?.hour())}
                                onClick={() => onSaveVisit(true)}
                                className="h-11 w-full"
                            >
                                {isLoading ? (
                                    <p>
                                        Guardando... <LoadingOutlined />
                                    </p>
                                ) : (
                                    'Guardar'
                                )}
                            </Button>
                        )}

                        <p
                            className="cursor-pointer font-semibold underline"
                            onClick={() => {
                                if (visit?._id) setShowCancel(true);
                                else onSave(true);
                            }}
                        >
                            Cancelar visita
                        </p>
                    </div>
                )}

                <PulppoDrawer visible={showMap} onClose={() => setShowMap(false)} title="Mapa del recorrido">
                    <MapTour properties={visitSteps.map((v) => v.property)} />
                </PulppoDrawer>
                <CancelDrawer
                    visitId={visit?._id?.toString()}
                    visible={showCancel}
                    onClose={() => {
                        setShowCancel(false);
                    }}
                    onConfirm={() => {
                        onSave(true);
                        setShowCancel(false);
                    }}
                />
                <ShareDrawer visit={visit as any} visible={showShare} onClose={() => setShowShare(false)} />
            </section>
        </PulppoDrawer>
    );
};

const Tooltip = ({ children, message, show }) => {
    const [visible, setVisible] = useState(false);

    const handleMouseEnter = () => {
        setVisible(true);
    };

    const handleMouseLeave = () => {
        setVisible(false);
    };

    return (
        <div className="relative inline-block w-full" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children}
            {visible && show && (
                <div
                    className="absolute z-10 w-full -translate-y-full bg-white py-2 text-center text-sm text-black shadow-lg"
                    style={{ top: '-8px' }}
                >
                    {message}
                </div>
            )}
        </div>
    );
};

export const MapTour = ({ properties }: { properties: Array<ISimpleProperty> }) => {
    return (
        <PulppoMap
            className="h-full w-full"
            bounds={
                properties?.filter((property) => property?.address?.location?.coordinates?.[1])?.length > 1
                    ? (['min', 'max'].map((operation) =>
                          [0, 1].map((i) =>
                              Math[operation](
                                  ...properties
                                      .filter((property) => property.address?.location?.coordinates?.[1])
                                      .map((property) => property.address?.location.coordinates[i])
                              )
                          )
                      ) as [[number, number], [number, number]])
                    : null
            }
            center={
                (properties.filter((property) => property?.address?.location?.coordinates?.[1])?.[0]?.address?.location
                    ?.coordinates as any) || [DEFAULT_COORDINATES.lng, DEFAULT_COORDINATES.lat]
            }
            route={
                properties.length > 1
                    ? properties
                          .filter((property) => property?.address?.location?.coordinates?.[1])
                          .map((property) => property.address.location.coordinates)
                    : null
            }
        >
            {properties
                .filter((property) => property?.address?.location?.coordinates?.[1])
                .map((property, index) => (
                    <PulppoMarker key={`marker-${property._id}`} coordinates={property.address.location.coordinates}>
                        <div className="-mt-12 flex flex-col items-center">
                            <p className="bg-black p-2 text-white">{property?.address?.street}</p>
                            <p className="mt-3 flex h-6 w-6 items-center justify-center rounded-full bg-black font-bold text-white ring-4 ring-black ring-offset-4">
                                {index + 1}
                            </p>
                        </div>
                    </PulppoMarker>
                ))}
        </PulppoMap>
    );
};

const TourProperty = ({
    visit,
    visitStep,
    onRemove,
    selectDate,
    searchId,
    updateVisitStep,
    hoursUsed,
    user,
    disableDelete = false,
    notEditable = false,
    onClose = () => {}
}: {
    visit: IVisit;
    visitStep: IVisitStep;
    onRemove: (visitStep: IVisitStep) => void;
    selectDate: Dayjs;
    searchId: string;
    updateVisitStep: (visitStep: IVisitStep) => void;
    user: ISimpleUser;
    hoursUsed: Array<number>;
    disableDelete?: boolean;
    notEditable?: boolean;
    onClose?: () => void;
}) => {
    const setChatContact = useChat((store) => store.setChatContact);
    const [showRemove, setShowRemove] = useState(false);
    const [showNotes, setShowNotes] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [availableHours, setAvailableHours] = useState<Array<Dayjs>>([]);
    const [showAvailableHours, setShowAvailableHours] = useState(false);

    const generateMessage = (visitStep: IVisitStep) => {
        return `Hola ${visitStep.property?.agent?.firstName || ''}, 
Soy ${user?.firstName} de ${user?.company?.name} By Pulppo, estoy coordinando con un cliente para visitar ${visitStep?.property?.address?.street || visitStep?.property?.listing?.title},  el dia ${visitStep.date.format('DD/MM')} a las ${visitStep.date.format('HH:mm')}. 
Podras confirmarme la visita, asi ya confirmo con mi cliente.`;
    };

    const onChatContact = async () => {
        const contact = await instance
            .post(`${api_url}/contact`, {
                firstName: visitStep?.property?.agent?.firstName || '',
                lastName: visitStep?.property?.agent?.lastName || '',
                emails: [
                    {
                        email: visitStep?.property?.agent?.email || ''
                    }
                ],
                phones: [
                    {
                        phone: visitStep?.property?.agent?.phone || '',
                        plain: visitStep?.property?.agent?.phone.replace(/\D/gi, '')
                    }
                ]
            })
            .then((r) => r.data)
            .catch((err) => console.error(err));
        onClose();
        setChatContact({
            ...(contact
                ? {
                      ...contact,
                      firstName: visitStep?.property?.agent?.firstName || '',
                      lastName: visitStep?.property?.agent?.lastName || ''
                  }
                : visitStep?.property?.agent),
            message: generateMessage(visitStep)
        });
    };

    useEffect(() => {
        if (visitStep?.property?._id && selectDate && searchId) {
            setIsLoading(true);
            fetcher(
                `${api_url}/property/${visitStep.property._id}/visit-availability?date=${selectDate.format('YYYY-MM-DD')}&searchId=${searchId}`
            )
                .then((res) => {
                    setAvailableHours(res.map((hour: string) => dayjs(hour)));
                })
                .catch(() => setAvailableHours([]))
                .finally(() => setIsLoading(false));
        }
    }, [selectDate, visitStep?.property?._id, searchId]);

    return (
        <div className="border border-solid border-pulppo-secondary-gray-disabled p-2">
            <Spin spinning={isLoading}>
                <div className="flex items-center justify-between">
                    <div className="flex-0 w-2/3 pr-2">
                        <AvatarProperty
                            className={{
                                label: 'font-semibold text-pulppo-primary-black',
                                description: 'text-sm font-medium text-pulppo-primary-black',
                                image: 'rounded-none'
                            }}
                            size="large"
                            property={visitStep?.property}
                            description="price"
                            tag={'isMLS'}
                        />
                    </div>

                    {notEditable ? (
                        <div className="flex items-center justify-center gap-2 border border-solid border-pulppo-secondary-gray-line px-10 py-2">
                            {visitStep?.date?.format('hh:mm a')}
                        </div>
                    ) : (
                        <div className="relative hidden w-1/3 shrink-0 lg:block">
                            <PulppoNewTimePicker
                                value={visitStep?.date}
                                onSelectDate={(time) => {
                                    updateVisitStep({
                                        ...visitStep,
                                        date: selectDate.set('hour', time.hour()).set('minute', time.minute())
                                    });
                                    setShowAvailableHours(false);
                                }}
                                disabled={notEditable}
                                availableHours={availableHours}
                            />
                        </div>
                    )}
                    {!notEditable && (
                        <div className="flex w-1/3 items-center justify-end lg:hidden">
                            <input
                                type="time"
                                id="appt"
                                name="appt"
                                min="09:00"
                                max="18:00"
                                step={60 * 30}
                                value={visitStep.date.format('HH:mm')}
                                onChange={(ev) => {
                                    const hours = parseInt(ev.target.value?.split(':')?.[0]);
                                    const minutes = parseInt(ev.target.value?.split(':')?.[1]);
                                    updateVisitStep({
                                        ...visitStep,
                                        date: selectDate.clone().set('hour', hours).set('minute', minutes)
                                    });
                                }}
                                className="rounded-none border border-solid border-pulppo-secondary-gray-line px-4 py-1"
                            />
                        </div>
                    )}
                </div>
                <div className="mt-3 pb-4">
                    <p className="text-xs text-pulppo-primary-black">Coordinar propiedad con:</p>
                    <div className="mt-2 flex items-center justify-between">
                        <AvatarUser
                            onClick={onChatContact}
                            user={visitStep?.property?.agent}
                            description={visitStep?.property?.agent?.company?.name || 'Sin inmobiliaria'}
                        />
                        {visitStep?.property?.agent?.phone ? (
                            <Button type="secondary" onClick={onChatContact}>
                                <MessageOutlined className="text-lg" />
                            </Button>
                        ) : (
                            <a
                                className="btn btn-secondary flex h-10 shrink-0 items-center justify-center font-medium"
                                href={(visitStep?.property as any)?.import?.url}
                                target="_blank"
                            >
                                <p>Ver en portal</p>
                            </a>
                        )}
                    </div>
                </div>
                <div className="flex gap-x-2 border-t border-solid border-pulppo-secondary-gray-disabled pt-2">
                    <Button type="secondary" className="relative h-10 w-10" onClick={() => setShowNotes(true)}>
                        {visitStep?.notes?.length > 0 && (
                            <div className="absolute -right-1 -top-1 h-3 w-3 rounded-full bg-pulppo-status-dark-error"></div>
                        )}
                        <img src="/icons/note.svg" alt="note" className="h-[18px] w-[18px]" />
                    </Button>

                    <Button
                        type="secondary"
                        onClick={() => setShowRemove((val) => !val)}
                        disabled={disableDelete || notEditable}
                        className="flex h-10 w-10 items-center justify-center text-base disabled:bg-white"
                    >
                        {showRemove ? (
                            <CloseOutlined />
                        ) : (
                            <DeleteOutlined
                                className={twMerge(
                                    disableDelete ? 'stroke-pulppo-secondary-gray-disabled' : 'stroke-icon-black'
                                )}
                            />
                        )}
                    </Button>
                    {showRemove ? (
                        <Button
                            className="h-10 flex-grow border border-solid border-pulppo-status-dark-error bg-pulppo-status-light-error text-pulppo-status-dark-error"
                            onClick={() => onRemove(visitStep)}
                        >
                            Eliminar propiedad
                        </Button>
                    ) : visit?.status?.last === 'cancelled' ? (
                        <p className="relative flex flex-1 items-center justify-center gap-2 bg-pulppo-status-light-disabled text-center text-pulppo-status-dark-disabled">
                            <p>Cancelada</p>
                        </p>
                    ) : visitStep.confirmed ? (
                        <p className="relative flex flex-1 items-center justify-center gap-2 bg-pulppo-status-light-success text-center text-pulppo-status-dark-success">
                            <p>Confirmada</p>
                            <CheckCircleFilled className="text-xl text-pulppo-status-dark-success" />
                        </p>
                    ) : (
                        <Button
                            className="flex-grow"
                            type="black"
                            disabled={visitStep?.date?.hour() === 0 || notEditable}
                            onClick={() => updateVisitStep({ ...visitStep, confirmed: true })}
                        >
                            Confirmar
                        </Button>
                    )}
                </div>
            </Spin>
            <NoteDrawer
                notes={visitStep?.notes || []}
                visible={showNotes}
                onClose={() => setShowNotes(false)}
                onSave={(message) =>
                    updateVisitStep({
                        ...visitStep,
                        notes: [
                            ...(visitStep?.notes || []),
                            {
                                content: message,
                                creator: user,
                                isPublic: false,
                                createdAt: new Date()
                            }
                        ]
                    })
                }
            />
        </div>
    );
};

export type IDrawerFormVisit = IFormVisit & { show: boolean; onClose: (closeParent?: boolean) => void };

export default DrawerFormVisit;
