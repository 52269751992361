import * as ReactDOM from 'react-dom';
import { IAgent, IContact, ISimpleUser } from '@pulppo/pulppo-models';
import { Button, Typography } from 'antd';
import axios from '../../utils/axios';
import { useState } from 'react';
import { api_url } from '../../helpers/fetcher';
import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { ContactList } from './ContactList';
import useUser from '../../hooks/useUser';

interface Props {
    show: boolean;
    setShow: (show: boolean) => void;
    close: (params) => void;
    onAdd: (contact: IContact) => void;
    broker?: ISimpleUser;
    title: string;
    editContact?: IContact;
    onSelect: (contact: IContact) => void;
    useTypesense?: boolean;
    sort?: {
        createdAt: 'asc' | 'desc';
    };
}

export const ShowContactList = (
    config: Partial<Omit<Props, 'show' | 'setShow'>> & { onCancel?: any; afterClose?: () => void }
) => {
    const container = document.createDocumentFragment();
    let currentConfig = {
        ...config,
        close,
        show: true
    } as any;

    function destroy(...args: any[]) {
        ReactDOM.unmountComponentAtNode(container);
        const triggerCancel = args.some((param) => param && param.triggerCancel);
        if (config.onCancel && triggerCancel) {
            config.onCancel(...args);
        }
    }

    function render(props) {
        /**
         * https://github.com/ant-design/ant-design/issues/23623
         *
         * Sync render blocks React event. Let's make this async.
         */
        setTimeout(() => {
            ReactDOM.render(<SimpleContactList {...props} setShow={() => {}} />, container);
        }, 10);
    }

    function close(...args: any[]) {
        currentConfig = {
            ...currentConfig,
            show: false,
            afterClose: () => {
                if (typeof config.afterClose === 'function') {
                    config.afterClose();
                }
                destroy.apply(this, args);
            }
        };
        render(currentConfig);
    }

    function update(configUpdate) {
        if (typeof configUpdate === 'function') {
            currentConfig = configUpdate(currentConfig);
        } else {
            currentConfig = {
                ...currentConfig,
                ...configUpdate
            };
        }
        render(currentConfig);
    }

    render(currentConfig);

    return {
        destroy: close,
        update
    };
};

export const SimpleContactList = ({
    show,
    setShow,
    close = undefined,
    onAdd = undefined,
    broker = undefined,
    title = 'Selecciona un contacto',
    onSelect = null,
    editContact = null,
    useTypesense = false,
    sort
}: Props) => {
    const [showPersonalAccountDrawer, setShowPersonalAccountDrawer] = useState(false);
    const [contact, setContact] = useState(null);
    const user = useUser((state) => state.user);

    return (
        <>
            <PulppoDrawer
                title={title}
                onClose={() => {
                    setShow(false);
                    close && close({ triggerCancel: true });
                }}
                visible={show}
                bodyStyle={{ padding: '0px' }}
            >
                <ContactList
                    editContact={{
                        ...editContact,
                        phones: editContact?.phones
                            ? editContact.phones
                            : [
                                  {
                                      plain: '',
                                      phone: editContact?.phone,
                                      is_default: true,
                                      type: 'whatsapp'
                                  }
                              ],
                        emails: editContact?.emails
                            ? editContact.emails
                            : [
                                  {
                                      email: editContact?.email,
                                      type: 'email',
                                      is_default: true
                                  }
                              ]
                    }}
                    user={user as IAgent}
                    broker={broker as IAgent}
                    inDrawer={true}
                    useTypesense={useTypesense}
                    sort={sort}
                    onAdd={(contact) => {
                        onAdd?.(contact);
                    }}
                    onSelect={(contact) => {
                        setContact(contact);
                        if (user && !contact.whitelist?.some((agent) => agent && agent?.uid == user?.uid)) {
                            setShowPersonalAccountDrawer(true);
                        } else {
                            onSelect(contact);
                        }
                    }}
                />
            </PulppoDrawer>
            <PulppoDrawer
                title={null}
                onClose={() => {
                    setShowPersonalAccountDrawer(false);
                }}
                visible={showPersonalAccountDrawer}
                bodyStyle={{ padding: '0px' }}
            >
                <div className="space-evenly h-full">
                    <Typography.Title className="flex h-64 items-center justify-center text-center">
                        Contacto Personal
                    </Typography.Title>
                    <Typography.Paragraph className="mx-auto w-2/3 text-center text-lg">
                        Para poder compartir desde Pulppo, la persona tiene que <b>estar como contacto Comercial</b>.
                    </Typography.Paragraph>
                    <div className="mt-12 text-center">
                        <Button
                            onClick={async () => {
                                await axios.patch(`${api_url}/contact/${contact?._id}/whitelist`, {});
                                setShowPersonalAccountDrawer(false);
                                onSelect(contact);
                            }}
                            type="primary"
                            className="mb-6 w-2/3 rounded-md text-lg"
                        >
                            Convertir a Comercial
                        </Button>
                        <Typography.Paragraph
                            className="cursor-pointer text-sm"
                            onClick={() => {
                                setShowPersonalAccountDrawer(false);
                            }}
                        >
                            No enviar
                        </Typography.Paragraph>
                    </div>
                </div>
            </PulppoDrawer>
        </>
    );
};
