import { ISearch } from '@pulppo/pulppo-models';
import { PulppoDrawer } from './PulppoDrawer';
import * as ReactDOM from 'react-dom';
import useSWR from 'swr';
import { api_url, fetcher } from '../../helpers/fetcher';
import { Tabs } from 'antd';
import { useState } from 'react';
import { CheckCircleOutlined, StopOutlined, WhatsAppOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { parsePrice } from '../../utils/parser';
import { searchCancelReasons } from './SearchDrawer';
import { useTranslate } from '@tolgee/react';
import { TolgeeNextProvider } from '../../contexts/TolgeeProvider';

const tabs = [
    {
        key: 'open',
        label: 'Abiertas',
        filter: (search: ISearch) => search.status.last !== 'completed' && search.status.last !== 'cancelled'
    },
    {
        key: 'closed',
        label: 'Cerradas',
        filter: (search: ISearch) => search.status.last === 'completed' || search.status.last === 'cancelled'
    }
];

const SearchHistory = ({
    search,
    show,
    setShow,
    onClose
}: {
    search: ISearch;
    show: boolean;
    setShow: (show: boolean) => void;
    onClose: () => void;
}) => {
    const [tab, setTab] = useState('open');
    const { data: searchHistory } = useSWR<ISearch[]>(
        `${api_url}/contact/${search.contact._id}/searchHistory`,
        fetcher
    );
    const { t } = useTranslate('common');
    return (
        <PulppoDrawer
            title={'Historial de búsquedas'}
            visible={show}
            onClose={() => {
                setShow(false);
                onClose();
            }}
        >
            <Tabs
                activeKey={tab}
                centered={false}
                onChange={(key) => setTab(key)}
                items={tabs.map((elem) => ({
                    key: elem.key,
                    label: (
                        <p className={`${tab === elem.key ? 'font-medium text-black' : ''}`}>
                            {`${elem.label} (${searchHistory?.filter((srch) => elem.filter(srch)).length})`}
                        </p>
                    )
                }))}
            ></Tabs>
            {tab === 'open' ? (
                <div className="mb-6 flex gap-2">
                    <div>
                        <CheckCircleOutlined className="rounded-md bg-gray-100 p-2 text-xl font-medium" />
                    </div>
                    <div>
                        <p className="text-lg font-medium">Búsquedas abiertas</p>
                        <p className="text-pulppo-status-dark-disabled">
                            Aquí te mostramos las búsquedas abiertas que tiene actualmente tu cliente
                        </p>
                    </div>
                </div>
            ) : (
                <div className="mb-6 flex gap-2">
                    <div>
                        <StopOutlined className="rounded-md bg-gray-100 p-2 text-xl font-medium" />
                    </div>
                    <div>
                        <p className="text-lg font-medium">Búsquedas cerradas</p>
                        <p className="text-pulppo-status-dark-disabled">
                            Aquí te mostramos las búsquedas cerradas que ha tenido anteriormente tu cliente
                        </p>
                    </div>
                </div>
            )}
            {searchHistory?.filter((srch) => tabs.find((elem) => elem.key === tab)?.filter(srch)).length === 0 && (
                <div className="flex flex-col items-center py-10">
                    <img src="/icons/houseSearch.svg" alt="house" />
                    <p className="font-bold">{tab === 'open' ? 'Sin búsquedas abiertas' : 'Sin búsquedas cerradas'}</p>
                    <p className="font-medium text-pulppo-status-dark-disabled">
                        {tab === 'open'
                            ? 'No encontramos búsquedas abiertas de tu cliente'
                            : 'No encontramos búsquedas cerradas de tu cliente'}
                    </p>
                </div>
            )}
            {searchHistory
                ?.filter((srch) => tabs.find((elem) => elem.key === tab)?.filter(srch))
                ?.sort((a, b) => dayjs(a.createdAt).tz().valueOf() - dayjs(b.createdAt).tz().valueOf())
                ?.map((srch) => (
                    <div key={srch._id as string} className="mb-4 rounded-md border border-solid border-gray-200 p-4">
                        <div className="flex gap-4">
                            <img
                                src={
                                    srch.agent.profilePicture ||
                                    `https://ui-avatars.com/api/?name=${srch.agent.firstName}+${srch.agent.lastName}`
                                }
                                alt={srch.agent.email}
                                className="h-12 w-12 rounded-md object-cover"
                            />
                            <div className="flex-1">
                                <p className="font-medium">{`${srch.agent.firstName} ${srch.agent.lastName}`}</p>
                                <p className="font-medium text-pulppo-status-dark-disabled">
                                    {srch.agent.company?.name || ''}
                                </p>
                            </div>
                            <a
                                href={`https://wa.me/${srch.agent.phone}`}
                                target="_blank"
                                rel="noreferrer"
                                className="flex h-12 w-12 items-center justify-center rounded-md border border-solid border-gray-300"
                            >
                                <WhatsAppOutlined className="text-xl" />
                            </a>
                            <div className="flex-0 h-auto w-px bg-gray-300"></div>
                            <div className="flex-0">
                                <p className="text-right font-medium">Fecha de creación</p>
                                <p className="text-right font-medium text-pulppo-status-dark-disabled">
                                    {dayjs(srch.createdAt).tz().format('DD/MM/YYYY')}
                                </p>
                            </div>
                        </div>
                        <div className="my-4 flex flex-wrap gap-2">
                            <div className="rounded-md bg-gray-200 px-4">
                                {srch.filters?.operation === 'sale' ? 'Comprar' : t('to-rent', 'Rentar')}
                            </div>
                            {srch.filters?.addresses?.map((address) => (
                                <div key={address.id} className="rounded-md bg-gray-200 px-4">
                                    {address.name?.split(',')?.[0]}
                                </div>
                            ))}
                            {srch.filters?.price?.min && (
                                <div className="rounded-md bg-gray-200 px-4">
                                    {parsePrice({
                                        price: Math.round(srch.filters.price.min),
                                        startStr: 'Desde $'
                                    })}
                                </div>
                            )}
                            {srch.filters?.price?.max && (
                                <div className="rounded-md bg-gray-200 px-4">
                                    {parsePrice({
                                        price: Math.round(srch.filters.price.max),
                                        startStr: 'Hasta $'
                                    })}
                                </div>
                            )}
                            {srch.filters?.types?.map((type) => (
                                <div key={type} className="rounded-md bg-gray-200 px-4">
                                    {type}
                                </div>
                            ))}
                            {srch.filters?.bathrooms?.length > 0 && (
                                <div className="rounded-md bg-gray-200 px-4">
                                    {srch.filters.bathrooms.join(',') +
                                        ` ${t('bathrooms', 'baños')?.toLocaleLowerCase()}`}
                                </div>
                            )}
                            {srch.filters?.suites?.length > 0 && (
                                <div className="rounded-md bg-gray-200 px-4">
                                    {srch.filters.suites.join(',') +
                                        ` ${t('suites', 'recámaras')?.toLocaleLowerCase()}`}
                                </div>
                            )}
                            {srch.filters?.parkings?.length > 0 && (
                                <div className="rounded-md bg-gray-200 px-4">
                                    {srch.filters.parkings.join(',') +
                                        ` ${t('parkings', 'estacionamientos')?.toLocaleLowerCase()}`}
                                </div>
                            )}
                        </div>
                        <div className="mb-2 h-px w-full bg-gray-200"></div>
                        <p>
                            Interacciones: <b>12 mensajes</b>
                        </p>
                        {srch.status?.reasonToFinish && (
                            <>
                                <div className="my-2 h-px w-full bg-gray-200"></div>
                                <p>
                                    Busqueda cerrada:{' '}
                                    <b>
                                        {searchCancelReasons.find(
                                            (elem) => elem.name.toLowerCase() === srch.status.reasonToFinish
                                        )?.name || ''}
                                    </b>
                                </p>
                                <p>
                                    {' '}
                                    {searchCancelReasons.find(
                                        (elem) => elem.name.toLowerCase() === srch.status.reasonToFinish
                                    )?.description || ''}
                                </p>
                            </>
                        )}
                    </div>
                ))}
        </PulppoDrawer>
    );
};

export const ShowSearcHistory = (config) => {
    const container = document.createDocumentFragment();
    let currentConfig = {
        ...config,
        close,
        show: true
    } as any;

    function destroy(...args: any[]) {
        ReactDOM.unmountComponentAtNode(container);
        const triggerCancel = args.some((param) => param && param.triggerCancel);
    }

    function render(props) {
        /**
         * https://github.com/ant-design/ant-design/issues/23623
         *
         * Sync render blocks React event. Let's make this async.
         */
        setTimeout(() => {
            ReactDOM.render(
                <TolgeeNextProvider>
                    <SearchHistory {...props} setShow={() => {}} />
                </TolgeeNextProvider>,
                container
            );
        });
    }

    function close(...args: any[]) {
        currentConfig = {
            ...currentConfig,
            show: false,
            afterClose: () => {
                destroy.apply(this, args);
            }
        };
        render(currentConfig);
    }

    function update(configUpdate) {
        if (typeof configUpdate === 'function') {
            currentConfig = configUpdate(currentConfig);
        } else {
            currentConfig = {
                ...currentConfig,
                ...configUpdate
            };
        }
        render(currentConfig);
    }

    render(currentConfig);

    return {
        destroy: close,
        update
    };
};

export default SearchHistory;
