import { Input, Typography } from 'antd';
import { useState } from 'react';
import axios from '../../utils/axios';
import { provider_url } from '../Providers';
import { LoadingButton } from './ButtonLoading';

export const ProviderLogin = ({ setProvider }) => {
    const [providerId, setProviderId] = useState(null);
    const [apiKey, setApiKey] = useState(null);

    return (
        <div className="flex min-h-screen w-full items-center justify-center">
            <div className="w-1/2 max-w-[500px] rounded bg-white p-6 shadow">
                <Typography.Title level={3}>Iniciar sesión</Typography.Title>
                <p className="text-light text-pulppo-status-dark-disabled">
                    Para poder ver esto necesitas iniciar sesión como proveedor
                </p>
                <div className="mt-4">
                    <>
                        <div className="mt-8">
                            <p className="mb-4 font-medium">ID Proveedor</p>
                            <Input
                                value={providerId}
                                onChange={(e) => setProviderId(e.target.value)}
                                className="rounded-md py-2"
                            />
                        </div>
                        <div className="mt-8">
                            <p className="mb-4 font-medium">API Key</p>
                            <Input
                                type="password"
                                value={apiKey}
                                onChange={(e) => setApiKey(e.target.value)}
                                className="rounded-md py-2"
                            />
                        </div>
                        <div className="mt-8">
                            <LoadingButton
                                onClick={async () => {
                                    const response = await axios.post(`${provider_url}/providers/login`, {
                                        providerId,
                                        apiKey
                                    });
                                    setProvider(response.data);
                                }}
                                className="bg-primary h-auto w-full rounded-md py-2 font-bold"
                                type="primary"
                            >
                                Iniciar sesión
                            </LoadingButton>
                        </div>
                    </>
                </div>
            </div>
        </div>
    );
};
