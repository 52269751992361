import {
    AimOutlined,
    AlertOutlined,
    ApiOutlined,
    CalendarOutlined,
    CheckCircleOutlined,
    CheckOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    EyeInvisibleOutlined,
    FileDoneOutlined,
    FormOutlined,
    HistoryOutlined,
    PlusCircleOutlined,
    ShareAltOutlined,
    StopOutlined,
    SwapOutlined,
    TrophyOutlined,
    UserDeleteOutlined,
    UserSwitchOutlined,
    WarningFilled
} from '@ant-design/icons';
import { IOperation, ISearch, ISimpleUser } from '@pulppo/pulppo-models';
import { Alert, Button, Divider, Input, List, Modal, notification, Switch } from 'antd';
import { useRouter } from 'next/router';
import { cloneElement, useEffect, useState } from 'react';
import useSWR from 'swr';
import { api_url, fetcher } from '../../helpers/fetcher';
import useIsMobile from '../../hooks/isMobile';
import ImportProperty from '../importProperty/importProperty';
import { AgentList } from '../AgentList';
import { PulppoDrawer } from './PulppoDrawer';
import { ShowSearcHistory } from './SearchHistory';
import axios from '../../utils/axios';
import { ShowTaskDrawer } from './TaskDrawer';
import { ShowNoteDrawer } from './NoteDrawer';
import { NextImage } from './NextImage';
import { useInteractions } from '../../hooks/useInteractions';
import { CancelSearchDrawer } from './CancelSearchDrawer';
import useUser from '../../hooks/useUser';
import { ServiceRequest } from '../Providers';
import useOperation from '../../hooks/useOperation';
import { LoadingButton } from './ButtonLoading';
import Link from 'next/link';
import dayjs from 'dayjs';
import { ShowPortal } from '../../utils/ShowPortal';
import { PulppoLoader } from './PulppoLoader';
import { showShareDrawer } from '../Contact/ShareDrawer';
import { COLORS } from '../../utils/color';
import { createSearchInitTask } from '../../utils/task';
import { twMerge } from 'tailwind-merge';
import { useTranslate } from '@tolgee/react';

export const shareSearch = ({ search, onSend }: { search: ISearch; onSend: () => void }) => {
    showShareDrawer({
        contact: search?.contact,
        whatsapp: {
            content: `Hola ${search?.contact?.firstName}. Quería compartirte esta búsqueda: ${process.env.NEXT_PUBLIC_MI_PULPPO}/${search?.key}`,
            attachment: search?.properties.find((prop) => prop.status?.last !== 'deleted' && prop.source === 'pulppo')
                ?.pictures?.[0]?.url
                ? {
                      image: {
                          url: search?.properties.find(
                              (prop) => prop.status?.last !== 'deleted' && prop.source === 'pulppo'
                          )?.pictures?.[0]?.url
                      }
                  }
                : null
        },
        mail: {
            subject: 'Búsqueda compartida',
            content: `Hola ${search?.contact?.firstName}. Quería compartirte esta búsqueda: ${process.env.NEXT_PUBLIC_MI_PULPPO}/${search?.key}`,
            url: `${api_url}/search/${search._id}/share`
        },
        onSend
    });
};

const CancelInputDrawer = ({ cancelReason, search, show, setShow, onCancel }) => {
    const { messages } = useInteractions(search);
    const [textInput, setTextInput] = useState('');

    return (
        <PulppoDrawer title={`Motivo de cancelación: ${cancelReason}`} visible={show} onClose={() => setShow(false)}>
            <div className="flex h-full flex-col justify-between px-4">
                {cancelReason.toLowerCase() === 'incontactable' && messages.some((msg) => msg.from === 'client') && (
                    <div className="flex-1">
                        <div className={`flex items-center gap-4 bg-notification-red px-4 py-2`}>
                            <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-strong-red text-xl text-white">
                                <AlertOutlined />
                            </div>
                            <div className="flex items-center">
                                <p className="">
                                    Este contacto te ha enviado
                                    <span className="ml-1 mr-1 font-medium text-strong-red">
                                        {messages.filter((msg) => msg.from === 'client')?.length} mensajes.
                                    </span>{' '}
                                    No puedes cerrar ésta búsqueda con el motivo
                                    <span className="font-medium">incontactble, </span> vuelve atrás y elige otro
                                    motivo.
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                {(cancelReason.toLowerCase() !== 'incontactable' || !messages.some((msg) => msg.from === 'client')) && (
                    <div className="flex-1 flex-col">
                        {messages.length <= 10 && (
                            <div className={`flex items-center gap-4 bg-notification-red px-4 py-2`}>
                                <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-strong-red text-xl text-white">
                                    <AlertOutlined />
                                </div>
                                <div className="flex items-center">
                                    <p className="">
                                        Tienes solo
                                        <span className="ml-1 mr-1 font-medium text-strong-red">
                                            {messages.length} mensajes
                                        </span>
                                        con este contacto. Prueba enviarle{' '}
                                        <span className="font-medium">al menos 3 toques</span> antes de descartar la
                                        búsqueda. En caso contrario se podrá{' '}
                                        <span className="font-medium">realizar una auditoria</span> sobre el motivo de
                                        cierre.
                                    </p>
                                </div>
                            </div>
                        )}
                        {messages.length >= 10 && (
                            <div className={`flex items-center gap-4 bg-notification-green px-4 py-2`}>
                                <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-strong-green text-xl text-white">
                                    <CheckOutlined />
                                </div>
                                <div className="flex items-center">
                                    <p className="">
                                        Tienes
                                        <span className="ml-1 mr-1 font-medium text-strong-green">
                                            {messages.length} mensajes
                                        </span>
                                        con este contacto. Recuerda enviarle la respuesta rápida de{' '}
                                        <span className="font-medium">despedida</span> antes de cerrar la búsqueda
                                    </p>
                                </div>
                            </div>
                        )}
                        <p className="mb-2 mt-10 font-medium">
                            Escribe un comentario sobre el motivo de cancelación de la búsqueda
                        </p>
                        <div className="flex flex-col gap-1">
                            <Input.TextArea
                                value={textInput}
                                onInput={(ev) => {
                                    setTextInput(ev.currentTarget.value);
                                }}
                                placeholder="Escribe al menos 30 caracteres"
                                rows={4}
                                minLength={50}
                            />
                            <div className="flex w-full justify-between">
                                <p className="flex-1 text-strong-red">
                                    {textInput.length < 30 && textInput.length > 0 && (
                                        <span>Escribe por lo menos 30 caracteres</span>
                                    )}
                                </p>
                                <p className="text-pulppo-status-dark-disabled">{`${textInput.length}/50`}</p>
                            </div>
                        </div>
                    </div>
                )}
                <div className="flex w-full gap-5">
                    <Button
                        className="h-10 w-full flex-1 font-medium"
                        onClick={() => {
                            setShow(false);
                        }}
                    >
                        Atrás
                    </Button>
                    <Button
                        onClick={() => {
                            onCancel(textInput);
                            setShow(false);
                        }}
                        disabled={textInput?.length < 30}
                        type="primary"
                        className="h-10 w-full flex-1 font-medium"
                    >
                        Cerrar búsqueda
                    </Button>
                </div>
            </div>
        </PulppoDrawer>
    );
};

const RadioButton = ({
    name,
    description,
    isSelected,
    onClick
}: {
    name: string;
    description: string;
    isSelected: boolean;
    onClick: () => void;
}) => {
    return (
        <div className="flex w-full cursor-pointer justify-between" onClick={onClick}>
            <div>
                <p className="text-sm font-semibold">{name}</p>
                <p className="text-pulppo-status-dark-disabled">{description}</p>
            </div>
            <div className="ml-2 mt-0.5">
                {isSelected ? (
                    <div className="flex h-5 w-5 rounded-full border-2 border-solid border-black bg-white p-0.5">
                        <div className="h-full w-full rounded-full bg-black" />
                    </div>
                ) : (
                    <div className="h-5 w-5 rounded-full border-2 border-solid border-black bg-white" />
                )}
            </div>
        </div>
    );
};

const SearchDrawerItem = ({
    onClick,
    title,
    description,
    icon,
    isNew = false,
    isTerminal = false,
    disabled = false as boolean | string
}) => {
    if (disabled) {
        icon = cloneElement(icon, { className: twMerge(icon.props.className, 'text-gray-300') });
    }
    return (
        <List.Item
            onClick={disabled ? undefined : onClick}
            className="flex w-full cursor-pointer border-none bg-transparent text-left text-lg"
        >
            <List.Item.Meta
                avatar={icon}
                title={
                    <p className={twMerge('text-sm font-semibold hover:underline', disabled && 'text-gray-300')}>
                        {title}
                    </p>
                }
                className={twMerge(
                    '[&>.ant-list-item-meta-content>.ant-list-item-meta-description]:text-sm [&>.ant-list-item-meta-content>.ant-list-item-meta-description]:font-light',
                    disabled && '[&>.ant-list-item-meta-content>.ant-list-item-meta-description]:text-gray-300'
                )}
                description={
                    <>
                        <p>{description}</p>
                        {isTerminal && (
                            <p className="text-black">
                                <span>
                                    <ExclamationCircleOutlined className="mr-2 text-red-500" />
                                </span>
                                Ten en cuenta que esta acción no se puede <b>deshacer</b>
                            </p>
                        )}
                        {typeof disabled === 'string' && (
                            <p className="mt-2 text-sm text-pulppo-status-dark-error">
                                <span>
                                    <WarningFilled className="mr-2 text-pulppo-status-dark-error" />
                                </span>
                                {disabled}
                            </p>
                        )}
                    </>
                }
            />
            {isNew && (
                <div className="h-fit shrink-0 rounded-md bg-pulppo-status-light-info px-8 py-1 text-sm text-pulppo-status-dark-info">
                    NUEVO
                </div>
            )}
        </List.Item>
    );
};

export const SearchDrawer = ({
    search,
    show,
    setShow,
    refreshSearch,
    onCreate = () => {},
    onCancel = () => {}
}: {
    search: ISearch;
    setShow: Function;
    show: boolean;
    refreshSearch?: Function;
    onCreate?: () => void;
    onCancel?: () => void;
}) => {
    const [reassign, setReassign] = useState<{
        step: number;
        agent?: ISimpleUser;
        shareConversation?: boolean;
        reason?: string;
    }>(null);
    const showBanner = process.env.NEXT_PUBLIC_COMPLEMENTARY_SERVICES === 'true';
    const [cancelReason, setCancelReason] = useState('');
    const isMobile = useIsMobile();
    const router = useRouter();
    const setOperation = useOperation((o) => o.setOperation);
    const [showImportProperty, setShowImportProperty] = useState(false);
    const [showCancelDrawer, setShowCancelDrawer] = useState(false);
    const [showChangeStatusDrawer, setShowChangeStatusDrawer] = useState(false);
    const { data: hasLeadsUnanswered, mutate: refreshHasLeadsUnanswered } = useSWR<number>(
        search?._id && `${api_url}/search/has-leads-not-responded/${search?._id}`,
        fetcher
    );
    const { user } = useUser();
    const [operationsClosed, setOperationsClosed] = useState<Array<IOperation>>([]);
    const [showOperationsClosed, setShowOperationsClosed] = useState(false);

    useEffect(() => {
        if (search?._id) {
            axios
                .get(`${api_url}/search/${search?._id}/operation/closed`)
                .then((res) => {
                    setOperationsClosed(res.data?.data?.operations);
                })
                .catch(() => {
                    setOperationsClosed([]);
                });
        }
    }, [search]);

    const openTasks = () => {
        const { destroy } = ShowTaskDrawer({
            metadata: {
                id: `${search?._id}`,
                agent: search?.agent,
                properties: search?.properties
            },
            type: 'search',
            task: createSearchInitTask({
                search,
                user
            }),
            user,
            onClose: () => {
                destroy();
            }
        });
    };
    const openVisits = () => {
        const { destroy } = ShowPortal({
            type: 'visit-drawer',
            config: {
                contact: search?.contact,
                searchId: search?._id?.toString(),
                refreshSearch: refreshSearch as any,
                onClose: () => {
                    destroy();
                }
            }
        });
    };
    const openNotes = () => {
        const { destroy } = ShowNoteDrawer({
            search: search,
            user,
            onClose: () => {
                destroy();
            }
        });
    };
    const closeSearch = async (status: string, reason: string, message: string) => {
        try {
            if (status === 'completed') {
                await axios.patch(`${api_url}/search/${search?._id}/status/${status}`);
                notification.success({
                    message: 'Se ha finalizado la búsqueda',
                    description: 'La búsqueda ha sido finalizada correctamente',
                    duration: isMobile ? 2 : 4,
                    placement: isMobile ? 'bottomRight' : 'topRight'
                });
                onCancel();
                reddirect();
            } else {
                await axios.delete(`${api_url}/search/${search?._id}`, {
                    data: { reasonToFinish: reason, message: message }
                });
                notification.success({
                    message: 'Se ha finalizado la búsqueda',
                    description: 'La búsqueda ha sido finalizada correctamente',
                    duration: isMobile ? 2 : 4,
                    placement: isMobile ? 'bottomRight' : 'topRight'
                });
                onCancel();
                reddirect();
            }
        } catch (err) {
            notification.error({
                message: `No se pudo cerrar`,
                description: `${err?.response?.data?.message || err}`
            });
        }
    };

    const [showServices, setShowServices] = useState(false);
    const reddirect = () => {
        if (router.query?.['queueId']) {
            const taskQueue = JSON.parse(window.sessionStorage.getItem(`queue_${router.query?.['queueId']}`));
            if (!taskQueue || taskQueue.current === taskQueue.tasks.length - 1) {
                return router.push('/tasks');
            }
            const aux = { ...taskQueue };
            aux.current++;
            sessionStorage.setItem(`queue_${router.query['queueId']}`, JSON.stringify(aux));
            const url = taskQueue?.tasks[aux.current].reddirectTo;
            router.push(url + '?queueId=' + router.query['queueId']);
        } else {
            if (router.pathname === '/buyers') {
                return window.location.reload();
            }
            router.push('/buyers');
        }
    };
    if (!search)
        return (
            <PulppoDrawer visible={show} title="Opciones" onClose={() => setShow(false)}>
                <List>
                    <List.Item
                        className="w-full cursor-pointer border-none bg-transparent text-left text-lg hover:underline"
                        onClick={onCreate}
                    >
                        <List.Item.Meta
                            title={
                                <p className="text-lg">
                                    <AimOutlined className="mr-2 text-xl" />
                                    Crear búsqueda
                                </p>
                            }
                            description="Cree una búsqueda para compartirle propiedades y realizarle un  correcto seguimiento al cliente desde Búsquedas"
                        />
                    </List.Item>
                </List>
            </PulppoDrawer>
        );
    return (
        <>
            <CancelInputDrawer
                show={!!cancelReason}
                setShow={() => setCancelReason('')}
                search={search}
                cancelReason={cancelReason}
                onCancel={(message) => {
                    closeSearch('cancelled', cancelReason.toLowerCase(), message);
                }}
            />
            <ServiceRequest open={showServices} onClose={() => setShowServices(false)} />
            <PulppoDrawer visible={show} title="Opciones" onClose={() => setShow(false)}>
                <List>
                    {showBanner && process.env.NEXT_PUBLIC_COMPLEMENTARY_SERVICES === 'true' && (
                        <SearchDrawerItem
                            onClick={() => {
                                setOperation({
                                    buyer: {
                                        broker: user,
                                        contact: search?.contact,
                                        search: search._id as string,
                                        source: 'search',
                                        company: user?.company
                                    },
                                    company: user?.company
                                });
                                setShowServices(true);
                            }}
                            icon={<ApiOutlined className="bg-gray-100 px-2 py-2 text-xl" />}
                            title="Servicios complementarios"
                            description="Ofrece a tus clientes una gama de servicios complementarios para brindar una experiencia integral."
                            isNew={true}
                        />
                    )}
                    {hasLeadsUnanswered && (
                        <SearchDrawerItem
                            onClick={() => {
                                axios
                                    .patch(`${api_url}/search/${search?._id}/mark-as-read`, {
                                        contact: search?.contact,
                                        agent: search?.agent
                                    })
                                    .catch((err) => console.error(err))
                                    .then(() => {
                                        setShow(false);
                                        refreshHasLeadsUnanswered();
                                    });
                            }}
                            icon={<CheckCircleOutlined className="bg-gray-100 px-2 py-2 text-xl" />}
                            title="Marcar como respondida"
                            description="Marca esta consulta como respondida. Ya no aparecerá en la pestaña de pendientes, pero podras seguir consultandola en tus búsquedas y en la pestaña de todas"
                        />
                    )}
                    <SearchDrawerItem
                        disabled={
                            !user?.whatsapp && 'No puedes realizar esta acción, debes tener vinculado tu WhatsApp'
                        }
                        onClick={() => {
                            shareSearch({
                                search,
                                onSend: () => {
                                    setShow(false);
                                }
                            });
                        }}
                        icon={<ShareAltOutlined className="bg-gray-100 px-2 py-2 text-xl" />}
                        title="Compartir propiedades"
                        description="Comparte esta búsqueda con tu contacto para que pueda ver todas las propiedades que agregaste"
                    />
                    <SearchDrawerItem
                        onClick={() => {
                            setShowChangeStatusDrawer(true);
                        }}
                        icon={<SwapOutlined className="bg-gray-100 px-2 py-2 text-xl" />}
                        title="Cambiar estado de búsqueda"
                        description="Selecciona un nuevo estado para la búsqueda del cliente"
                    />
                    <SearchDrawerItem
                        onClick={() => {
                            openVisits();
                            setShow(false);
                        }}
                        icon={<CalendarOutlined className="bg-gray-100 px-2 py-2 text-xl" />}
                        title="Visitas"
                        description="Coordinar una nueva visita o ver las que tienes agendadas"
                    />
                    <SearchDrawerItem
                        onClick={() => {
                            setShowImportProperty(true);
                            setShow(false);
                        }}
                        icon={<PlusCircleOutlined className="bg-gray-100 p-2 text-xl" />}
                        title="Importar Propiedades"
                        description="Importa propiedades desde portales externos usando ligas"
                        disabled={
                            !user?.whatsapp && 'No puedes realizar esta acción, debes tener vinculado tu WhatsApp'
                        }
                    />
                    <SearchDrawerItem
                        onClick={() => {
                            openTasks();
                            setShow(false);
                        }}
                        icon={<FileDoneOutlined className="bg-gray-100 px-2 py-2 text-xl" />}
                        title="Tareas"
                        description="Crea las tareas que necesites realizar o visualiza las pendientes y realizadas"
                    />
                    <SearchDrawerItem
                        onClick={() => {
                            openNotes();
                            setShow(false);
                        }}
                        icon={<FormOutlined className="bg-gray-100 px-2 py-2 text-xl" />}
                        title="Notas"
                        description="Añade notas personales o para compartir con tu equipo de trabajo"
                    />
                    <SearchDrawerItem
                        onClick={() => {
                            const { destroy } = ShowSearcHistory({
                                onClose: () => {
                                    destroy();
                                },
                                search: search
                            });
                            setShow(false);
                        }}
                        icon={<HistoryOutlined className="bg-gray-100 px-2 py-2 text-xl" />}
                        title="Historial de búsquedas"
                        description="Aqui puedes visualizar el historial de búsquedas"
                    />
                    <Divider />

                    {user && !['cancelled', 'completed'].includes(search?.status?.last) && (
                        <>
                            <SearchDrawerItem
                                onClick={() => {
                                    setReassign({ step: 0 });
                                    setShow(false);
                                }}
                                icon={<UserSwitchOutlined className="bg-gray-100 px-2 py-2 text-xl" />}
                                title="Reasignar búsqueda"
                                description="Reasigna esta búsqueda a un colega"
                                isTerminal={true}
                            />

                            <SearchDrawerItem
                                onClick={() => {
                                    if (!operationsClosed?.length) {
                                        setShow(false);
                                        setShowCancelDrawer(true);
                                    } else {
                                        setShowOperationsClosed(true);
                                    }
                                }}
                                icon={<StopOutlined className="bg-gray-100 px-2 py-2 text-xl" />}
                                title="Cerrar búsqueda"
                                description="Cierra la búsqueda si el cliente ya no está buscando una propiedad"
                                isTerminal={true}
                            />
                        </>
                    )}
                </List>
            </PulppoDrawer>
            <PulppoDrawer
                visible={showOperationsClosed}
                title="Operaciones cerradas"
                onClose={() => setShowOperationsClosed(false)}
            >
                <Alert
                    message="¡No puedes cerrar la búsqueda porque tienes operaciones concretadas asociadas!"
                    type="warning"
                    showIcon
                />
                <div className="mt-4 space-y-2">
                    {operationsClosed.map((operation) => {
                        return (
                            <div
                                className="border border-solid border-pulppo-secondary-gray-disabled px-2 pb-4 pt-2"
                                key={`operation-closed-${operation._id}`}
                            >
                                <p className="text-lg font-semibold">Operación ID: {operation?.id}</p>
                                <p className="text-base">Propiedad calle: {operation.property?.address?.street}</p>
                                <p>Fecha de cierre: {dayjs(operation?.closedAt).tz().format('DD/MM/YYYY')}</p>
                                <Link href={`/operations/${operation?._id}`}>
                                    <a
                                        onClick={() => {
                                            setShowOperationsClosed(false);
                                        }}
                                        className="btn btn-secondary mt-2 block w-full text-center"
                                    >
                                        Ver operación
                                    </a>
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </PulppoDrawer>

            <CancelSearchDrawer
                visible={showCancelDrawer}
                onClose={(success: boolean) => {
                    setShowCancelDrawer(false);
                    if (success) {
                        onCancel();
                        reddirect();
                    }
                }}
                search={search}
            />

            <ImportProperty
                sendRawData={true}
                show={showImportProperty}
                setShow={setShowImportProperty}
                onFinished={async (selectedListings) => {
                    await axios.patch(`${api_url}/search/${search?._id}/properties/add`, {
                        properties: selectedListings,
                        source: search?.agent
                    });
                    setShowImportProperty(false);
                    refreshSearch?.();
                }}
                noOportunity
            />
            <DrawerChangeStatus
                show={showChangeStatusDrawer}
                search={search}
                onClose={(success) => {
                    if (success) {
                        refreshSearch();
                    }
                    setShowChangeStatusDrawer(false);
                }}
                onCancel={() => setShowCancelDrawer(true)}
                onVisit={() => openVisits()}
            />
            {user && user?.company && (
                <PulppoDrawer
                    visible={!!reassign}
                    title={
                        reassign?.step === 0
                            ? 'A quien le quieres asignar la búsqueda?'
                            : reassign?.step === 1
                              ? 'Reasignar búsqueda'
                              : reassign?.step === 2
                                ? '¡Cliente reasignado con éxito!'
                                : 'No pudimos reasignar la búsqueda'
                    }
                    onClose={() => {
                        setReassign(null);
                    }}
                >
                    {reassign?.step == 0 && (
                        <AgentList
                            showAll={true}
                            user={user}
                            onSelect={(agent) => {
                                setReassign((r) => ({
                                    ...r,
                                    step: 1,
                                    agent,
                                    shareConversation: true
                                }));
                            }}
                        />
                    )}
                    {reassign?.step === 1 && (
                        <div className="flex h-full flex-col">
                            <div className="flex flex-1 flex-col items-center gap-10">
                                <div className="flex flex-col items-center gap-2">
                                    <div className="h-28 w-28 shrink-0 grow-0">
                                        <NextImage
                                            showPreview={false}
                                            className="h-28 w-28 rounded-full object-cover"
                                            src={reassign?.agent?.profilePicture}
                                            width={256}
                                            height={256}
                                            alt="agent profile picture"
                                        />
                                    </div>

                                    <p className="w-2/3 text-center">
                                        Estás a punto de reasignar la Búsqueda. Indícale a{' '}
                                        <b>
                                            {reassign?.agent?.firstName} {reassign?.agent?.lastName} el motivo
                                        </b>
                                        , para que pueda atender mejor al cliente.
                                    </p>
                                </div>
                                <div className="w-full">
                                    <p className="mb-2 font-medium">Motivo de reasignación</p>
                                    <Input.TextArea
                                        maxLength={400}
                                        showCount
                                        value={reassign?.reason}
                                        onChange={(e) =>
                                            setReassign((r) => ({
                                                ...r,
                                                reason: e.target.value
                                            }))
                                        }
                                        placeholder="Escribe el motivo aquí..."
                                        rows={5}
                                    />
                                    <div className="mt-8 flex items-center justify-between">
                                        <div>
                                            <p className="font-medium">Compartir conversación</p>
                                            <p>Se compartirá el historial de tu conversación</p>
                                        </div>
                                        <Switch
                                            checkedChildren={<CheckCircleOutlined />}
                                            checked={reassign?.shareConversation}
                                            onChange={() =>
                                                setReassign((r) => ({
                                                    ...r,
                                                    shareConversation: !r.shareConversation
                                                }))
                                            }
                                            className={reassign?.shareConversation ? 'bg-primary-color' : 'bg-gray-200'}
                                        />
                                    </div>
                                </div>
                            </div>
                            <LoadingButton
                                type="primary"
                                className="h-auto w-full py-2 font-medium"
                                disabled={!reassign?.reason}
                                onClick={async () => {
                                    try {
                                        await axios.post(`${api_url}/search/${search?._id}/delegate`, {
                                            agent: reassign?.agent._id,
                                            reason: reassign?.reason,
                                            shareConversation: reassign?.shareConversation
                                        });
                                        notification.success({
                                            duration: isMobile ? 2 : 4,
                                            placement: isMobile ? 'bottomRight' : 'topRight',
                                            message: 'Se ha reasignado la busqueda',
                                            description: `La busqueda ahora pertenece a ${reassign?.agent.firstName} ${reassign?.agent.lastName}`
                                        });
                                        reddirect();
                                    } catch (err) {
                                        setReassign((r) => ({
                                            ...r,
                                            step: 3,
                                            error: err
                                        }));
                                    }
                                }}
                            >
                                Reasignar búsqueda
                            </LoadingButton>
                        </div>
                    )}
                    {reassign?.step == 2 && (
                        <div className="flex h-full flex-col">
                            <div className="flex flex-1 flex-col items-center gap-6">
                                <div className="p-2" style={{ background: '#E8FFF7' }}>
                                    <CheckCircleOutlined className="text-5xl" style={{ color: '#13EA9D' }} />
                                </div>
                                <p className="w-2/3 text-center">
                                    Búsqueda reasignada con éxito. Ya enviamos a{' '}
                                    <b>
                                        {reassign?.agent?.firstName} {reassign?.agent?.lastName}
                                    </b>{' '}
                                    tu solicitud.
                                </p>
                            </div>
                            <Button
                                type="primary"
                                onClick={() => {
                                    setReassign(null);
                                    router.push('/buyers');
                                }}
                                className="h-auto w-full py-2 font-medium"
                            >
                                Ir a mis búsquedas
                            </Button>
                        </div>
                    )}
                    {reassign?.step == 3 && (
                        <div className="flex h-full flex-col">
                            <div className="flex flex-1 flex-col items-center gap-6">
                                <div className="p-2" style={{ background: COLORS.status.light.error }}>
                                    <CloseCircleOutlined
                                        className="text-5xl"
                                        style={{ color: COLORS.status.dark.error }}
                                    />
                                </div>
                                <p className="w-2/3 text-center">
                                    Se produjo un error al reasignar tu búsqueda. Te pedimos que por favor,{' '}
                                    <b>vuelvas a intentarlo</b>
                                </p>
                            </div>
                            <Button
                                type="primary"
                                onClick={() => setReassign((r) => ({ ...r, step: 0 }))}
                                className="h-auto w-full py-2 font-medium"
                            >
                                Reintentar
                            </Button>
                        </div>
                    )}
                </PulppoDrawer>
            )}
        </>
    );
};

export const SearchStatuses = [
    {
        value: 'pending',
        name: 'Pendiente',
        description: 'El cliente aún  no fue atendido'
    },
    {
        value: 'searching',
        name: 'Buscando',
        description: 'El cliente fue atendido y se le esta buscando propiedades '
    },
    {
        value: 'visiting',
        name: 'Visitando',
        description: 'El cliente tiene una visita pendiente y/o confirmada'
    },
    {
        value: 'offer_done',
        name: 'Ofertando',
        description: 'El cliente realiza una oferta sobre una propiedad '
    },
    {
        value: 'closing',
        name: 'Cerrando',
        description: 'Se está avanzando en una operación'
    },
    {
        value: 'completed',
        name: 'Ganada',
        description: 'El cliente compró o rentó una propiedad '
    },
    {
        value: 'cancelled',
        name: 'Perdida',
        description: 'Se perdió la oportunidad de realizar una operación con el cliente'
    }
];

export const searchCancelReasons = [
    {
        name: 'Ganada',
        icon: (props) => <TrophyOutlined {...props} />,
        description: 'El cliente compró o rentó con nosotros'
    },
    {
        name: 'Incontactable',
        icon: (props) => <ApiOutlined {...props} />,
        description: 'Imposible comunicarse con el cliente, sus datos son incorrectos '
    },
    {
        name: 'Fantasma',
        icon: (props) => <EyeInvisibleOutlined {...props} />,
        description: 'Los datos son correctos pero no responde mensajes o llamadas '
    },
    {
        name: 'Cancelado',
        icon: (props) => <StopOutlined {...props} />,
        description: 'El cliente canceló la búsqueda, ya no tiene la necesidad de comprar o rentar '
    },
    {
        name: 'Perdido',
        icon: (props) => <UserDeleteOutlined {...props} />,
        description: 'Se perdió la oportunidad, ya compró o rentó con otra inmobiliaria'
    },
    {
        name: 'Descartado',
        icon: (props) => <DeleteOutlined {...props} />,
        description: 'El cliente tiene alguna característica que nos impide trabajar con él '
    },
    {
        name: 'Asesor',
        icon: (props) => <img src="/icons/hand-gesture.png" alt="hand" {...props} />,
        description: 'Se envió la información al asesor externo '
    }
];

const DrawerChangeStatus = ({
    show,
    onClose,
    search,
    onCancel,
    onVisit
}: {
    show: boolean;
    onClose: (success: boolean) => void;
    search: ISearch;
    onCancel: () => void;
    onVisit: () => void;
}) => {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslate('common');
    return (
        <PulppoDrawer
            visible={show}
            onClose={() => {
                onClose(false);
            }}
            title="Seleccione el estado de búsqueda"
        >
            <PulppoLoader loading={loading}>
                <div className="flex flex-col gap-4">
                    {SearchStatuses.map((elem) => {
                        elem = {
                            ...elem,
                            name: t(`${elem.name}`)
                        };
                        return (
                            <RadioButton
                                {...elem}
                                key={`search-status-selector-${elem.value}`}
                                isSelected={elem.value === search?.status?.last}
                                onClick={async () => {
                                    if (elem.value === 'cancelled') {
                                        onCancel();
                                        return;
                                    }
                                    setLoading(true);
                                    await axios
                                        .patch(`${api_url}/search/${search?._id}/status/${elem.value}`)
                                        .then((res) => {
                                            if (res.data.success) onClose(true);
                                            else if (res.data.reason === 'no_visit') {
                                                Modal.confirm({
                                                    title: 'No hay visitas registradas',
                                                    content:
                                                        'No puedes cambiar el estado a visitando, ya que no tenemos visitas registradas en esta búsqueda. Agenda una visita a futuro, o registra una visita pasada para continuar',
                                                    okText: 'Crear visita',
                                                    cancelText: 'Cancelar',
                                                    onOk: () => {
                                                        onVisit();
                                                    },
                                                    onCancel: () => {}
                                                });
                                            } else if (res.data.reason === 'no_operation') {
                                                Modal.confirm({
                                                    title: 'No hay operaciones registradas',
                                                    content:
                                                        'No puedes cambiar el estado de la búsqueda, ya que no tenemos operaciones registradas en esta búsqueda. Carga una nueva operación para continuar',
                                                    okText: 'Cargar operación',
                                                    cancelText: 'Cancelar',
                                                    onOk: () => {
                                                        window.open(
                                                            `${window?.location?.origin}/operations/new?search=${search?._id}`,
                                                            '_blank'
                                                        );
                                                        onClose(false);
                                                    },
                                                    onCancel: () => {}
                                                });
                                            } else if (res.data.reason === 'wrong_operation_status') {
                                                Modal.confirm({
                                                    title: 'Operación registrada',
                                                    content:
                                                        'No puedes cambiar el estado de la búsqueda, ya que hay una operacion registrada relacionada. Cambia de estado la operación y se verá reflejado en la búsqueda',
                                                    okText: 'Ver operación',
                                                    cancelText: 'Cancelar',
                                                    onOk: () => {
                                                        const { destroy } = ShowPortal({
                                                            type: 'operation',
                                                            config: {
                                                                onClose: () => {
                                                                    destroy();
                                                                },
                                                                operation: res.data.data.operation
                                                            }
                                                        });
                                                        onClose(false);
                                                    },
                                                    onCancel: () => {}
                                                });
                                            }
                                        })
                                        .catch((err) => {
                                            notification.error({
                                                message: `No se pudo cambiar el estado`,
                                                description: `${err?.response?.data?.message || err}`
                                            });
                                        })
                                        .finally(() => setLoading(false));
                                }}
                            />
                        );
                    })}
                </div>
            </PulppoLoader>
        </PulppoDrawer>
    );
};
