import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { useRouter } from 'next/router';
import { EventCard } from './EventCard';
import { LoyaltyEvent } from './LoyaltyDrawer';
import useUser from '../../hooks/useUser';

export const EventOrder = {
    unaffiliated: 0,
    standard: 1,
    professional: 2,
    elite: 3
};

export const EventsDrawer = ({
    events,
    onEventChange
}: {
    events: LoyaltyEvent[];
    onEventChange: (event: LoyaltyEvent) => void;
}) => {
    const router = useRouter();
    const level = useUser((u) => u?.user?.level);

    return (
        <PulppoDrawer
            title="Experiencias"
            bodyStyle={{ padding: 0 }}
            visible={router.query.events === 'true'}
            onClose={() => {
                delete router.query.events;
                router.push(router);
            }}
        >
            <div className="p-4">
                {events?.every(
                    (ev) => EventOrder[level || 'unaffiliated'] < EventOrder[ev.minLevel || 'unaffiliated']
                ) && (
                    <p className="mb-4 text-sm text-pulppo-primary-gray">
                        No tienes experiencias disponibles al momento. Alcanza el siguiente nivel para poder disfrutar
                        de éstos beneficios exclusivos
                    </p>
                )}
                <div className="flex h-full w-full flex-col gap-2">
                    {events?.map((event) => (
                        <EventCard onEventChange={onEventChange} showDetails={true} event={event} key={event.title} />
                    ))}
                </div>
            </div>
        </PulppoDrawer>
    );
};
