import { IPicture } from '@pulppo/pulppo-models/build/@types/pulppo-models/schemas/Property';
import { Typography, Upload, Row, Button, Progress, notification } from 'antd';
import { RcFile } from 'antd/lib/upload';
import {
    DndContext,
    closestCenter,
    useSensor,
    useSensors,
    TouchSensor,
    MouseSensor,
    KeyboardSensor
} from '@dnd-kit/core';
import { arrayMove, SortableContext, rectSortingStrategy, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { TransformHEICService } from '../../services/heic-transform';
import { uuidv4 } from '../../helpers/uuid';
import dynamic from 'next/dynamic';
import { PulppoLoader } from './PulppoLoader';
import { twMerge } from 'tailwind-merge';
import { ImageUploaderService } from '../../services/ImageUploaderService';
import { parse } from 'file-type-mime';
import exifr from 'exifr';

const ImageSortableItemDynamic = dynamic<any>(
    () => import('./ImageSortableItem').then((r) => r.ImageSortableItem as any),
    {
        ssr: false
    }
);

interface Props {
    icon?: ReactNode;
    title?: ReactNode;
    description?: ReactNode;
    editable?: boolean;
    style?: CSSProperties;
    prefix?: string;
    text?: string;
    pictures?: (IPicture & { hasError?: boolean })[];
    setPictures?: (func: ((pictures: IPicture[]) => IPicture[]) | IPicture[]) => void;
    containerClassName?: string;
    is_blueprint?: boolean;
    multiple?: boolean;
    showList?: boolean;
    accept?: string;
    type?: string;
    showUploadButton?: boolean;
    className?: string;
    isPrivate?: boolean;
    minSize?: number;
    hasVioo?: boolean;
    propertyId?: string;
    children?: ReactNode;
    setNameFile?: (file: RcFile) => string;
    onUploaded?: (fileName: string) => void;
    disableShowListPictures?: boolean;
    classNameTitle?: string;
    onGetMetadata?: (metadata?: {
        gps: {
            latitude?: number;
            longitude?: number;
        };
    }) => void;
    noShowProgress?: boolean;
    disabled?: boolean;
}

export const FileUpload = ({
    icon = null,
    title = null,
    description = null,
    editable = true,
    isPrivate = false,
    style = null,
    prefix = '/property',
    minSize = null,
    text = 'Click aqui o arrastre archivos para subir las imágenes',
    pictures: initialPictures = [] as any[],
    setPictures: setInitialPictures = (_) => {},
    containerClassName = 'mb-20px',
    is_blueprint = false,
    multiple = true,
    showList = true,
    accept = 'image/*',
    type = 'image',
    showUploadButton = true,
    className = '',
    propertyId = null,
    children,
    setNameFile,
    onUploaded,
    disableShowListPictures,
    classNameTitle = '',
    onGetMetadata,
    noShowProgress = false,
    disabled = false
}: Props) => {
    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: { distance: 8 }
        }),
        useSensor(TouchSensor, {
            activationConstraint: { delay: 300, tolerance: 5 }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState([] as Array<{ id: string; percent: number }>);
    const percent =
        status.length === 0 ? -1 : status.reduce((total, status, index, arr) => total + status.percent / arr.length, 0);
    const [pictures, setPictures] = useState(
        initialPictures.filter((pic, ind, arr) => arr.findIndex((p) => p.url == pic.url) == ind)
    );

    useEffect(() => {
        if (propertyId && type === 'image')
            setPictures(initialPictures.filter((pic, ind, arr) => arr.findIndex((p) => p.url == pic.url) == ind));
    }, [propertyId]);

    useEffect(() => {
        setInitialPictures(pictures.filter((pic) => pic && !pic.hasError));
    }, [pictures]);

    const validate = (file: RcFile, options?: { showMessage?: boolean }) => {
        const isGt30Mb = (file?.size || 1) / 1024 / 1024 > 30;
        if (isGt30Mb && options?.showMessage) {
            notification.error({
                message: `El archivo ${file?.name} debe ser menor a 30MB`
            });
        }
        return !isGt30Mb;
    };

    return (
        <div className={containerClassName}>
            <PulppoLoader loading={loading}>
                <div className="flex w-full flex-col @lg:w-auto @lg:flex-1">
                    {title ? (
                        <Typography.Title className={classNameTitle} level={4}>
                            <div className="flex items-center">
                                {icon ? <div className="mr-10px">{icon}</div> : null}
                                {title}
                            </div>
                        </Typography.Title>
                    ) : null}
                    {description ? (
                        <Typography.Paragraph className="w-full text-pulppo-status-dark-disabled">
                            {description}
                        </Typography.Paragraph>
                    ) : null}
                    <Upload.Dragger
                        style={style}
                        height={typeof style?.height === 'string' ? parseInt(style?.height) : style?.height}
                        name="file"
                        accept={accept}
                        disabled={disabled}
                        className={twMerge('w-full grow rounded-none', className)}
                        multiple={multiple}
                        maxCount={multiple ? undefined : 1}
                        showUploadList={type !== 'image' && showList}
                        customRequest={async ({ file, ...events }) => {
                            try {
                                if (!validate(file as RcFile, { showMessage: true })) {
                                    events.onError(new Error('El archivo debe pesar menos de 30MB'));
                                    return;
                                }
                                let buffer = null;
                                const arrayBuffer = await (file as RcFile).arrayBuffer();
                                if (onGetMetadata) {
                                    onGetMetadata(await getMetadataFromBuffer(arrayBuffer));
                                }
                                const result = parse(arrayBuffer);
                                const fileParts = (file as RcFile).name.split('.');
                                if (result?.mime.toLocaleLowerCase().includes('heic')) {
                                    buffer = await TransformHEICService(file as RcFile);
                                    fileParts[fileParts.length - 1] = 'jpeg';
                                }
                                if (result?.mime.includes('image') && minSize) {
                                    const size = await new Promise<{
                                        width: number;
                                        height: number;
                                        url: string;
                                    }>((resolve, reject) => {
                                        const img = new Image();
                                        const objectUrl = URL.createObjectURL(
                                            buffer ? new Blob([buffer]) : (file as Blob)
                                        );
                                        img.onload = function () {
                                            resolve({
                                                width: img.width,
                                                height: img.height,
                                                url: objectUrl
                                            });
                                        };
                                        img.onerror = () => reject('La imagen tiene un formato inválido');
                                        img.src = objectUrl;
                                    });
                                    if (size.width < minSize && size.height < minSize) {
                                        return events.onSuccess({
                                            url: size.url,
                                            hasError: true
                                        });
                                    } else {
                                        URL.revokeObjectURL(size.url);
                                    }
                                }

                                const getNameFile = () => {
                                    if (setNameFile) {
                                        return setNameFile(file as RcFile);
                                    }
                                    return `picture_${uuidv4().replace(/\-/gi, '')}.${fileParts[fileParts.length - 1]}`;
                                };
                                ImageUploaderService.upload(
                                    buffer || (file as RcFile),
                                    `${prefix}/${getNameFile()}`,
                                    events,
                                    true,
                                    { private: isPrivate }
                                ).catch((err) => console.error(`No se pudieron subir los archivos ${err}`));
                            } catch (err) {
                                setLoading(false);
                                notification.error({
                                    message: 'No se pudo subir el archivo',
                                    description: `${err}`
                                });
                            }
                        }}
                        onChange={async (info) => {
                            if (!validate(info.file as RcFile)) {
                                info.file.status = 'error';
                                return;
                            }
                            let { status } = info.file;
                            setStatus((statuses) => {
                                const clone = [...statuses];
                                const found = clone.find((s) => s.id === info.file.uid);
                                if (found) {
                                    found.percent = info.file.percent || 0;
                                } else {
                                    clone.push({
                                        id: info.file.uid,
                                        percent: info.file.percent
                                    });
                                }
                                return clone;
                            });
                            if (status === 'uploading') {
                                setLoading(true);
                            } else if (status == 'removed') {
                            } else if (info.file.response?.hasError) {
                                setLoading(false);
                                setPictures((pics) => [
                                    ...pics,
                                    {
                                        type: '',
                                        url: info.file.response.url,
                                        description: info.file.name,
                                        is_blueprint: is_blueprint,
                                        hasError: true
                                    }
                                ]);
                            } else if (status === 'done') {
                                const newFileName = info.file.response;
                                onUploaded && onUploaded(newFileName);
                                if (!multiple) {
                                    setPictures([
                                        {
                                            url: newFileName,
                                            description: info.file.name,
                                            is_blueprint: is_blueprint
                                        } as IPicture
                                    ]);
                                } else {
                                    setPictures(
                                        (pictures) =>
                                            [
                                                ...pictures,
                                                {
                                                    url: newFileName,
                                                    description: info.file.name,
                                                    is_blueprint: is_blueprint
                                                } as IPicture
                                            ] as any[]
                                    );
                                }
                                setLoading(false);
                            } else if (status === 'error') {
                                info.file.status = 'error';
                                notification.error({
                                    message: `No se pudo subir el archivo ${info.file.fileName}`,
                                    description: `${info.file.error}`
                                });
                                setLoading(false);
                            }
                        }}
                    >
                        {children || (
                            <>
                                <p className="ant-upload-text">{text}</p>
                                {showUploadButton && (
                                    <Button type="primary" className="font-medium" disabled={disabled}>
                                        Subir archivos
                                    </Button>
                                )}
                                <p className="mt-1 text-xs text-pulppo-primary-gray">Tamaño máximo: 30MB</p>
                            </>
                        )}
                    </Upload.Dragger>
                </div>
                {!noShowProgress && percent !== -1 ? (
                    <Progress className="transition-all" percent={Math.floor(percent) || 0} />
                ) : null}
                {type === 'image' && !disableShowListPictures && (
                    <Row gutter={10} className="mt-5px">
                        <DndContext
                            autoScroll={{ threshold: { x: 0, y: 0.2 } }}
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={(event) => {
                                if (!editable) return;
                                const { active, over } = event;

                                if (active?.id !== over?.id) {
                                    const oldIndex = pictures.findIndex((pic) => pic.url == active?.id);
                                    const newIndex = pictures.findIndex((pic) => pic.url == over?.id);

                                    const movedPictures = arrayMove(pictures, oldIndex, newIndex);
                                    setPictures(movedPictures as any[]);
                                }
                            }}
                        >
                            <SortableContext items={pictures.map((pic) => pic.url)} strategy={rectSortingStrategy}>
                                <div className="mx-[5px] grid grid-cols-2 gap-2 @lg:grid-cols-4 @4xl:grid-cols-6 @6xl:grid-cols-8">
                                    {pictures.filter(Boolean).map((pic, index) => (
                                        <ImageSortableItemDynamic
                                            hasError={pic.hasError}
                                            key={pic.url}
                                            urlImage={pic.url}
                                            highlight={index < 6}
                                            isFirst={index === 0}
                                            onDelete={() => {
                                                const picIndex = pictures.findIndex((img) => img.url === pic.url);
                                                let pics = [...pictures];
                                                pics.splice(picIndex, 1);
                                                setPictures(pics as any[]);
                                            }}
                                            onRotate={(rotate) => {
                                                const pics = [...pictures];
                                                if (pics[index]) {
                                                    (pics[index] as any).url = rotate;
                                                    setPictures(pics as any);
                                                }
                                            }}
                                        />
                                    ))}
                                </div>
                            </SortableContext>
                        </DndContext>
                    </Row>
                )}
            </PulppoLoader>
        </div>
    );
};

const getMetadataFromBuffer = async (file: ArrayBuffer) => {
    try {
        const metadata = await exifr.parse(file);
        if (metadata) {
            return {
                gps: {
                    latitude: metadata?.latitude as number,
                    longitude: metadata?.longitude as number
                }
            };
        }

        return null;
    } catch (error) {
        console.error('Error al obtener metadatos:', error);
        return null;
    }
};
