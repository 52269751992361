import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { METRICS_DESCRIPTION } from '../../utils/constants';
import { MetricType } from '@pulppo/pulppo-models/build/enums/MetricType';
import useMetric from '../../hooks/useMetric/useMetric';
import useMetricAgents from '../../hooks/useMetric/useMetricAgents';
import MetricChart from './MetricChart';
import AgentsList from './AgentsList';
import { IAgent } from '@pulppo/pulppo-models';
import { ResponseMetric } from '../../services/metricService';
import { currentWeekMetricFromSelect } from '../../utils/ConstantsMetrics';
import useHomeUser from '../../hooks/useHomeUser';

interface MetricChartsProps {
    type: Array<MetricType>;
}

interface Props extends MetricChartsProps {
    show: boolean;
    onClose: () => void;
}

const AgentMetric = ({
    agent,
    type
}: {
    agent: IAgent & {
        metrics: ResponseMetric;
    };
    type: Array<MetricType>;
}) => {
    const {
        state: { data, isLoading }
    } = useMetric({
        currentWeek: currentWeekMetricFromSelect(type),
        limit: 12,
        select: type,
        agentId: `${agent._id}`
    });

    const MetricChartData = MetricChart({
        data,
        type: type[0],
        simple: true
    });

    if (isLoading) return null;

    return MetricChartData.Chart;
};

const MetricCharts = ({ type }: MetricChartsProps) => {
    const user = useHomeUser((u) => u.user);
    const statsView = user?.showAll;
    const {
        state: { data, isLoading }
    } = useMetric({
        currentWeek: currentWeekMetricFromSelect(type),
        limit: 12,
        select: type
    });
    const {
        state: { agents, isLoading: isLoadingAgents }
    } = useMetricAgents({
        select: type,
        currentWeek: currentWeekMetricFromSelect(type)
    });

    if (isLoading) return null;

    const MetricChartData = MetricChart({
        data,
        type: type[0],
        simple: false
    });

    return (
        <>
            <section className="mb-8 border-[0.5px] border-solid border-pulppo-secondary-gray-disabled p-4 text-base">
                <div className="flex items-center justify-between">
                    {statsView ? 'Datos generales de todos los asesores' : 'Tus datos generales'}
                    {MetricChartData.Header}
                </div>
                <div className="mt-4">{MetricChartData.Chart}</div>
            </section>
            {statsView ? (
                <AgentsList agents={agents} isLoading={isLoadingAgents} actions={MetricChartData.actions}>
                    {(agent) => <AgentMetric agent={agent} type={type} />}
                </AgentsList>
            ) : null}
        </>
    );
};

const DrawerMetricCharts = ({ show, onClose, type }: Props) => {
    return (
        <PulppoDrawer
            visible={show}
            onClose={onClose}
            title={METRICS_DESCRIPTION[type[0]].titleResume || METRICS_DESCRIPTION[type[0]].title}
        >
            <MetricCharts type={type} />
        </PulppoDrawer>
    );
};

export default DrawerMetricCharts;
