import { ReactNode } from 'react';
import { NextImage } from './NextImage';
import Link from 'next/link';
import { twMerge } from 'tailwind-merge';
import Badge from './Badge';
import { ColorBadge } from '../../utils/color';

export interface AvatarProps {
    title: string | ReactNode;
    img: {
        src: string;
        alt?: string;
        className?: string;
    };
    description?: ReactNode;
    tag?: string | ReactNode;
    size?: 'small' | 'medium' | 'large';
    label?: {
        bgColor?: string;
        textColor?: string;
        text: string;
    };
    onClickPicture?: () => void;
    className?: {
        body?: string;
        container?: string;
        title?: string;
        description?: string;
        action?: string;
    };
    href?: string;
    onClick?: () => void;
    onPictureClick?: () => void;
    stopPropagation?: boolean;
}

export const Avatar = ({
    img,
    title,
    tag,
    description,
    href,
    onClick,
    className,
    stopPropagation,
    size,
    label,
    onClickPicture
}: AvatarProps) => {
    const elementSize = size === 'large' ? 60 : size === 'small' ? 30 : 40;
    const element = (
        <div className={twMerge('flex items-center gap-x-4 text-base', className?.body)}>
            <div
                onClick={
                    onClickPicture
                        ? (ev) => {
                              ev.stopPropagation();
                              onClickPicture();
                          }
                        : null
                }
                className={twMerge(
                    'relative flex flex-shrink-0 items-center justify-center',
                    size === 'large'
                        ? 'h-[60px] w-[60px]'
                        : size === 'small'
                          ? 'h-[30px] w-[30px]'
                          : 'h-[40px] w-[40px]',
                    onClickPicture && 'cursor-pointer'
                )}
            >
                <NextImage
                    width={elementSize}
                    height={elementSize}
                    className={twMerge('rounded-full', img?.className)}
                    src={img.src}
                    alt={img.alt || typeof title === 'string' ? (title as string) : undefined}
                    showPreview={false}
                    fallback={`https://ui-avatars.com/api/?name=${title}`}
                />
                {label ? (
                    <div
                        className={twMerge(
                            'absolute bottom-0 flex items-center justify-center px-2 font-medium',
                            size === 'large' ? 'h-5 text-xs' : size === 'medium' ? 'h-4 text-2xs' : 'h-3 text-3xs'
                        )}
                        style={{
                            color: label?.textColor,
                            backgroundColor: label?.bgColor
                        }}
                    >
                        <p>{label.text}</p>
                    </div>
                ) : null}
            </div>
            <div className={twMerge('min-w-0 max-w-[16rem] text-left', className?.container)}>
                {typeof title === 'string' ? (
                    <p className={twMerge('truncate text-sm font-normal text-black', className?.title)}>{title}</p>
                ) : (
                    title
                )}
                {description ? (
                    typeof description === 'string' ? (
                        <p className={twMerge('truncate text-sm text-pulppo-primary-gray', className?.description)}>
                            {description}
                        </p>
                    ) : (
                        description
                    )
                ) : null}
                {typeof tag === 'string' ? <Badge color={ColorBadge.Gray} value={tag} /> : tag || null}
            </div>
        </div>
    );

    if (href) {
        return (
            <Link href={href} scroll={false}>
                <a className="cursor-pointer">
                    <div
                        className={twMerge(
                            'cursor-pointer rounded p-0.5 transition-all hover:bg-pulppo-secondary-bg',
                            className?.action
                        )}
                    >
                        {element}
                    </div>
                </a>
            </Link>
        );
    }
    if (onClick) {
        return (
            <button
                type="button"
                className={twMerge(
                    'rounded p-0.5 text-left transition-all hover:bg-pulppo-secondary-bg',
                    className?.action
                )}
                onClick={(e) => {
                    stopPropagation && e.stopPropagation();
                    onClick();
                }}
            >
                {element}
            </button>
        );
    }
    return element;
};

export default Avatar;
