import ReactPlayer from 'react-player';
import Glide from '@glidejs/glide';
import { IVideo } from '@pulppo/pulppo-models';
import { useEffect, useRef } from 'react';

interface IVideoGalleryProps {
    videos: Array<IVideo>;
}

export const VideoGallery = ({ videos }: IVideoGalleryProps) => {
    const reference = useRef(null);
    const glideAPI: any = useRef(null);
    const sliderConfiguration = useRef({
        type: 'carousel',
        startAt: 0,
        animationTimingFunc: 'ease-in-out',
        perView: 1,
        gap: 0
    });

    useEffect(() => {
        glideAPI.current = new Glide(reference.current, sliderConfiguration.current as any);
        glideAPI.current instanceof Glide ? glideAPI.current.mount() : '';

        return () => {
            glideAPI.current instanceof Glide ? glideAPI.current.destroy() : '';
        };
    }, []);

    return (
        <>
            <div className={'VideoGallery'}>
                <div className="picture-details">
                    {/* GLIDE SLIDE CAROUSEL */}
                    <div ref={reference} className="glide">
                        <div className="glide__arrows" data-glide-el="controls">
                            <button className="glide__arrow glide__arrow--left" data-glide-dir="<">
                                <span className="icon-arrow-back" />
                            </button>
                        </div>
                        <div className="glide__track" data-glide-el="track">
                            <ul className="glide__slides">
                                {videos.map((video, index) => (
                                    <div key={video.url}>
                                        <ReactPlayer height={'95%'} width={'100%'} controls={true} url={video.url} />
                                    </div>
                                ))}
                            </ul>
                        </div>
                        {window.innerWidth > 992 ? (
                            <div style={{ height: '82vh' }} className="container">
                                <div className="glide__bullets" data-glide-el="controls[nav]">
                                    {videos.map((i, index) => (
                                        <button key={i.url} className="glide__bullet" data-glide-dir={`=${index}`} />
                                    ))}
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        <div className="glide__arrows" data-glide-el="controls">
                            <button className="glide__arrow glide__arrow--right" data-glide-dir=">">
                                <span className="icon-arrow-forward" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
