import { Image, notification } from 'antd';
import { useState } from 'react';
import {
    CloseOutlined,
    DownloadOutlined,
    EyeOutlined,
    LoadingOutlined,
    PlayCircleOutlined,
    ShareAltOutlined
} from '@ant-design/icons';
import useIsMobile from '../../hooks/isMobile';
import { ShowContactList } from '../Contact/SimpleContactList';
import { api_url } from '../../helpers/fetcher';
import Alert from '../Closes/Alert';
import { createRoot } from 'react-dom/client';
import useUser from '../../hooks/useUser';
import { showShareDrawer } from '../Contact/ShareDrawer';
import { useTranslate } from '@tolgee/react';

const VideoPreview = ({ onClose, url }) => {
    return (
        <div className="fixed left-0 top-0 h-full w-full p-4" style={{ zIndex: 99999999999999 }}>
            <div
                onClick={() => {
                    onClose();
                }}
                className="absolute left-0 top-0 h-full w-full bg-black opacity-40"
            />
            <div className="absolute right-6 top-6">
                <CloseOutlined onClick={onClose} className="text-2xl text-white" />
            </div>

            <div className="flex h-full w-full items-center justify-center">
                <video autoPlay className="relative z-10 max-h-full max-w-full rounded-md object-contain" controls>
                    <source src={url} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    );
};

const previewVideo = (img) => {
    const container = document.createElement('div');
    document.body.appendChild(container);
    const root = createRoot(container);
    setTimeout(() => {
        root.render(
            <VideoPreview
                url={img?.videoUrl}
                onClose={() => {
                    root.unmount();
                    document.body.removeChild(container);
                }}
            />
        );
    });
};

export const PropertyMarketing = ({ property }) => {
    const isMobile = useIsMobile();
    const { t } = useTranslate('common');
    const user = useUser((store) => store.user);
    const images = Object.keys(property.marketing || {}).reduce(
        (result, template) => [
            ...result,
            {
                name: template,
                url: property.marketing[template]?.url,
                videoUrl: property.marketing[template]?.videoUrl
            }
        ],
        []
    );
    const [showImages, setShowImages] = useState(property?.marketing);
    const [loading, setLoading] = useState(false);
    if (!property.marketing) return <></>;
    return (
        <div>
            <div className="mb-4">
                <Alert
                    type="warning"
                    content={[
                        {
                            text: 'Las piezas se generan automáticamente y pueden demorar'
                        },
                        { text: 'hasta 24 horas', emphasize: true },
                        { text: 'en reflejarse' }
                    ]}
                />
            </div>
            <div className="relative grid grid-cols-2 items-center gap-4">
                {loading && (
                    <div className="absolute left-0 top-0 z-20 flex h-full w-full items-center justify-center">
                        <div className="absolute left-0 top-0 h-full w-full bg-white opacity-50"></div>
                        <LoadingOutlined className="text-4xl" />
                    </div>
                )}
                {images.map((img) => (
                    <div key={img.url} className="flex h-60 flex-col rounded-md shadow-md">
                        <div className="flex w-full items-center justify-evenly gap-4 rounded-t-md border-b border-solid border-gray-100 p-2">
                            <ShareAltOutlined
                                className="text-xl"
                                onClick={async () => {
                                    try {
                                        setLoading(true);
                                        if (isMobile) {
                                            let response = await fetch(
                                                img.videoUrl ||
                                                    `${window.location.origin}/_next/image?url=${encodeURIComponent(
                                                        img.url
                                                    )}&w=1080&q=80`
                                            );
                                            let data = await response.blob();
                                            let metadata = {
                                                type: img.videoUrl ? 'video/mp4' : 'image/jpeg'
                                            };
                                            let file = new File(
                                                [data],
                                                `${property?.address?.street.replace(/\W/gi, '_')}.${
                                                    img.videoUrl ? 'mp4' : 'jpg'
                                                }`,
                                                metadata
                                            );
                                            await navigator
                                                .share?.({
                                                    files: [file]
                                                })
                                                .catch((err) => console.error(err));
                                        } else {
                                            const { destroy } = ShowContactList({
                                                onSelect: (contact) => {
                                                    showShareDrawer({
                                                        contact,
                                                        whatsapp: {
                                                            content: `*${property.listing.title}*
*${t('suites', 'Recámaras')}: ${property.attributes.suites}
*${t('bathrooms', 'Baños')}: ${property.attributes.bathrooms}
*${t('totalSurface', 'Superficie')}: ${property.attributes.totalSurface}m2
*${t('roofedSurface', 'Superficie cubierta')}: ${property.attributes.roofedSurface}m2
                                                            
*${t('price', 'Precio')}: ${property.listing.price?.currency} $${property.listing.price?.price?.toLocaleString()}
`,
                                                            attachment: {
                                                                video: {
                                                                    url: img?.videoUrl,
                                                                    mimetype: 'video/mp4'
                                                                }
                                                            }
                                                        },
                                                        mail: {
                                                            url: `${api_url}/marketing/email`,
                                                            subject: `Envío presentación ${
                                                                property
                                                                    ? `de la propiedad ${property?.address?.street}`
                                                                    : 'de Pulppo'
                                                            }`,
                                                            content: '',
                                                            params: { url: img?.videoUrl || img?.url }
                                                        },
                                                        onSend: () => {
                                                            destroy({
                                                                triggerCancel: true
                                                            });
                                                        }
                                                    });
                                                },
                                                onAdd: (contact) => {
                                                    showShareDrawer({
                                                        contact,
                                                        whatsapp: {
                                                            content: `*${property.listing.title}*
*${t('suites', 'Recámaras')}: ${property.attributes.suites}
*${t('bathrooms', 'Baños')}: ${property.attributes.bathrooms}
*${t('totalSurface', 'Superficie')}: ${property.attributes.totalSurface}m2
*${t('roofedSurface', 'Superficie cubierta')}: ${property.attributes.roofedSurface}m2
                                                            
*${t('price', 'Precio')}: ${property.listing.price?.currency} $${property.listing.price?.price?.toLocaleString()}
                                                            `
                                                        },
                                                        mail: {
                                                            url: `${api_url}/marketing/email`,
                                                            subject: `Envío presentación ${
                                                                property
                                                                    ? `de la propiedad ${property?.address?.street}`
                                                                    : 'de Pulppo'
                                                            }`,
                                                            content: '',
                                                            params: { url: img?.videoUrl || img?.url }
                                                        },
                                                        onSend: () => {
                                                            destroy({
                                                                triggerCancel: true
                                                            });
                                                        }
                                                    });
                                                }
                                            });
                                        }
                                    } catch (err) {
                                        notification.error({
                                            message: 'No se pudo compartir',
                                            description: `${err}`
                                        });
                                    } finally {
                                        setLoading(false);
                                    }
                                }}
                            />
                            <EyeOutlined
                                className="text-xl"
                                onClick={() => {
                                    if (img.videoUrl) {
                                        previewVideo(img);
                                    } else {
                                        setShowImages((i) => ({
                                            ...i,
                                            [img.url]: { visible: true }
                                        }));
                                    }
                                }}
                            />
                            <DownloadOutlined
                                className="text-xl"
                                onClick={async () => {
                                    setLoading(true);
                                    try {
                                        let url =
                                            img.videoUrl ||
                                            `${window.location?.origin}/_next/image?url=${encodeURIComponent(
                                                img.url
                                            )}&w=1080&q=80`;
                                        const blob = await fetch(url).then((resp) => resp.blob());
                                        const downloadUrl = window.URL.createObjectURL(blob);
                                        const a = document.createElement('a');
                                        a.style.display = 'none';
                                        a.href = downloadUrl;
                                        // the filename you want
                                        a.download = `${img.name}_${property?.address?.street?.replace(
                                            /\W/gi,
                                            '_'
                                        )}.${img.videoUrl ? 'mp4' : 'jpg'}`;
                                        document.body.appendChild(a);
                                        a.click();
                                        window.URL.revokeObjectURL(downloadUrl);
                                    } catch (err) {
                                        notification.error({
                                            message: 'No se pudo descargar',
                                            description: `${err}`
                                        });
                                    } finally {
                                        setLoading(false);
                                    }
                                }}
                            />
                        </div>
                        <div className="relative flex w-full flex-1 overflow-y-hidden">
                            {img?.videoUrl && (
                                <div className="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center">
                                    <PlayCircleOutlined
                                        className="cursor-pointer text-5xl text-white shadow-md"
                                        onClick={() => {
                                            previewVideo(img);
                                        }}
                                    />
                                </div>
                            )}
                            <Image
                                key={img.url}
                                src={img.url}
                                wrapperStyle={{
                                    width: '100%',
                                    flex: 1,
                                    overflowY: 'hidden',
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                                className="h-full w-full object-contain"
                                preview={{
                                    mask: null,
                                    visible: !img?.videoUrl && showImages?.[img.url]?.visible,
                                    onVisibleChange(value, prevValue) {
                                        if (prevValue)
                                            setShowImages((i) => ({
                                                ...i,
                                                [img.url]: { visible: value }
                                            }));
                                    }
                                }}
                                alt="marketing template"
                            />
                        </div>
                        <div className="flex justify-center py-2 font-medium">
                            {img.name.split(/(?=[A-Z])/).join(' ')}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
