import { ReactNode, useState } from 'react';
import dayjs from 'dayjs';
import { notification, Tooltip } from 'antd';
import { PhoneOutlined, WarningOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { IContact, IProperty, ISimpleUser } from '@pulppo/pulppo-models';
import axios from '../../utils/axios';
import useChat from '../../hooks/useChat';
import { NoteEditor } from '../Elements/NoteEditor';
import { api_url, instance } from '../../helpers/fetcher';
import { NextImage } from '../Elements/NextImage';
import { isExclusive } from '../../utils/property';
import { useTranslate } from '@tolgee/react';
import Button from '../Elements/Button';
import { LoadingButton } from '../Elements/ButtonLoading';
import Badge from '../Elements/Badge';
import { ColorBadge } from '../../utils/color';
import useUser from '../../hooks/useUser';

const PropertyDetailInternal = ({
    property,
    permission,
    setProperty
}: {
    property: IProperty;
    permission: boolean;
    setProperty: () => void;
}) => {
    let contractDays = 0;
    const contractStartDate = property?.contract?.start || property.createdAt;
    const contractDurationMonths = property?.contract?.durationMonths || 6;
    const { user } = useUser();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslate('common');
    if (contractStartDate && contractDurationMonths) {
        contractDays = dayjs(contractStartDate).tz().add(contractDurationMonths, 'months').diff(dayjs().tz(), 'days');
    }
    const details: Array<{
        label: string;
        prefix?: string;
        value: ReactNode;
    }> = property.mls
        ? [
              {
                  label: 'Revisión',
                  value: property?.sharing?.lastCheckedAt
                      ? dayjs(property.sharing.lastCheckedAt).format('DD/MM/YYYY')
                      : 'Nunca'
              },
              {
                  label: 'Comparte comisión',
                  value: property?.sharing?.status === 'completed' ? (property?.sharing?.shares ? 'Si' : 'No') : '-'
              },
              {
                  label: 'Comisión para ti',
                  value: property?.sharing?.status === 'completed' ? `${property?.sharing?.percent || 0}%` : '-'
              },
              {
                  label: 'Disponibilidad',
                  value:
                      property?.sharing?.status === 'completed' ? (
                          property?.sharing?.available ? (
                              <Badge color={ColorBadge.Green} value="Disponible" />
                          ) : (
                              <Badge color={ColorBadge.Red} value="No disponible" />
                          )
                      ) : (
                          <Badge color={ColorBadge.Yellow} value="A consultar" />
                      )
              }
          ]
        : [
              {
                  label: 'Mantenimiento',
                  value: property?.attributes?.expensesIncluded
                      ? 'Incluído'
                      : `$${(property?.attributes?.expenses || 0)?.toLocaleString()}`
              },
              {
                  label: 'Tipo de operación',
                  prefix: '',
                  value: property?.listing?.operation == 'sale' ? t('sale', 'Venta') : t('rent', 'Renta')
              },
              {
                  label: 'Moneda ' + property?.listing?.price?.currency?.toUpperCase?.(),
                  prefix: property?.listing?.price?.currency?.toUpperCase?.(),
                  value: property?.listing?.price?.price?.toLocaleString?.()
              },

              {
                  label: 'Es exclusiva',
                  value: isExclusive(property) ? 'Si' : 'No'
              },
              {
                  label: 'Comisión',
                  value: `${property?.contract?.comission ? Number(property.contract.comission).toFixed(2) : '-'} ${
                      property?.listing?.operation === 'sale' ? '%' : 'meses'
                  }`
              },
              {
                  label: 'Ubicación de las llaves',
                  value: `${property?.keys?.location ? `${property.keys.location}:` : ''} ${property?.keys?.description || ''}`
              },
              {
                  label: 'Inicio de contrato',
                  value: `${dayjs(contractStartDate).format('DD/MM/YYYY')}`
              },
              {
                  label: 'Duración de contrato',
                  value: `${contractDurationMonths} meses`
              },
              {
                  label: 'Días restantes de contrato',
                  value: `${contractDays} días`
              }
          ];

    return (
        <div>
            <div>
                <div>
                    <div>
                        {property.mls ? (
                            <>
                                <MLSSellers property={property} />
                                <p className="font-semibold uppercase">Validación</p>
                                {(!property?.sharing?.lastCheckedAt ||
                                    dayjs(property?.sharing?.lastCheckedAt).isBefore(dayjs().subtract(7, 'days'))) && (
                                    <Button
                                        disabled={!property?.company?.phone}
                                        type="black"
                                        className="mt-2 h-10 w-full"
                                        isLoading={loading}
                                        onClick={async () => {
                                            setLoading(true);
                                            await instance
                                                .post(`${api_url}/mls/${property?._id}/sharing-intent`)
                                                .then(() => setProperty())
                                                .catch((err) => console.error(err))
                                                .finally(() => setLoading(false));
                                        }}
                                    >
                                        Consultar
                                    </Button>
                                )}
                                {property?.sharing?.status === 'pending' && (
                                    <p className="mt-2 flex items-center gap-x-4 text-pulppo-primary-black">
                                        <WarningOutlined className="text-base" />
                                        <span>
                                            Hemos enviado un mensaje al asesor. En cuanto tengamos la información podras
                                            verla en la tabla de abajo
                                        </span>
                                    </p>
                                )}
                                {property?.sharing?.status === 'cancelled' && (
                                    <p className="mt-2 flex items-center gap-x-4 text-pulppo-primary-black">
                                        <WarningOutlined className="text-base" />
                                        <span>
                                            No tuvimos respuestas de disponibilidad y comisión compartida. Contacta al
                                            asesor
                                        </span>
                                    </p>
                                )}
                            </>
                        ) : (
                            <>
                                <div>
                                    {['published'].includes(property?.status?.last) && permission && (
                                        <Portals property={property} />
                                    )}
                                    <Producers permission={permission} property={property} />
                                </div>
                                <p className="my-2 text-base font-medium uppercase">Detalles</p>
                            </>
                        )}
                        {details.map(({ label, value }) => (
                            <div
                                key={label}
                                className="flex items-center justify-between border-b border-solid border-gray-200 py-3 text-black"
                            >
                                <p>{label}</p>
                                <p className="truncate">{value || '-'}</p>
                            </div>
                        ))}
                        {!!(property as any)?.tags?.length && (
                            <div>
                                <h2 className="font-medium uppercase">Etiquetas</h2>
                                <ul className="mt-3 flex flex-wrap gap-x-2 gap-y-2">
                                    {(property as any).tags?.map((tag) => (
                                        <li
                                            key={`service-${tag?.label}`}
                                            className="inline-flex h-7 items-center rounded border border-solid border-black bg-white px-2 text-sm text-black"
                                        >
                                            {t(tag?.label)}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
                <p className="my-4 font-medium uppercase">Notas públicas</p>
                {user?.company?._id?.toString() === property?.company?._id?.toString() && (
                    <NoteEditor
                        isPublic={true}
                        notes={property?.notes || []}
                        onNoteAdd={async (notes) => {
                            await axios.patch(`${api_url}/property/${property?._id}`, {
                                notes
                            });
                        }}
                    />
                )}
            </div>
        </div>
    );
};

const Portals = ({ property }: { property: IProperty }) => {
    const { t } = useTranslate('common');
    if (!['published'].includes(property?.status?.last)) return <></>;
    return (
        <div>
            <p className="flex items-center gap-2 text-base font-medium uppercase">Portales</p>

            <p className="mt-2">
                {property?.listing?.publishOnPortals
                    ? t(
                          'internal_portals_description',
                          `La propiedad tambien se encuentra publicada en
otros portales, tales como Vivanuncios, Lamudi,
ICasas y demás, pero no contamos con las ligas
disponibles.`
                      )
                    : 'La configuración actual de la propiedad restringe su difusión en portales externos'}
            </p>
            <div className="mt-2 flex flex-wrap justify-center gap-2">
                {portals
                    .filter((portal) => property?.portals?.[portal.path]?.url && portal.condition(property))
                    .map((portal) => (
                        <Tooltip overlay={<p className="capitalize text-white">{portal.name}</p>} key={portal?.name}>
                            <a
                                href={`${property?.portals?.[portal?.path]?.url}`}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                    backgroundImage: `url(.${portal?.logo})`,
                                    backgroundPosition: '50% 50%',
                                    backgroundSize: portal?.isFull ? 'cover' : 'contain',
                                    backgroundRepeat: 'no-repeat'
                                }}
                                className="h-12 w-12 border border-solid border-gray-200"
                            />
                        </Tooltip>
                    ))}
                <Tooltip overlay={<p className="capitalize text-white">{t('pulppo')}</p>}>
                    <a
                        href={`${process.env.NEXT_PUBLIC_PUBLIC_URL || 'https://pulppo.com'}/propiedades/${property?._id}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                            backgroundImage: `url(${process.env.NEXT_PUBLIC_LOGO_BLANCO_SOLO || './icons/apple-touch-icon.png'})`,
                            backgroundPosition: '50% 50%',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat'
                        }}
                        className="h-12 w-12 border border-solid border-gray-200 bg-black"
                    />
                </Tooltip>
            </div>
        </div>
    );
};

export const contactByChat = async (agent: ISimpleUser): Promise<IContact> => {
    let contact: IContact;
    if (agent?.phone) {
        contact = await axios.get(`${api_url}/contact/phones/${agent.phone}`).then((r) => r.data);
    }
    if (!contact) {
        contact = await axios
            .post(`${api_url}/contact`, {
                firstName: agent?.firstName,
                lastName: agent?.lastName,
                phones: [
                    {
                        phone: agent.phone,
                        plain: agent.phone.replace(/\D/gi, '')
                    }
                ]
            })
            .then((r) => r.data);
    }
    return contact;
};

const Producers = ({ property, permission }: { property: IProperty; permission: boolean }) => {
    const setChatContact = useChat((store) => store.setChatContact);

    return (
        <div className="mb-10">
            <p className="my-4 text-base font-medium uppercase">ANUNCIANTE</p>
            <img
                src={
                    property?.company?.logo?.pulppo ||
                    `https://api.dicebear.com/5.x/identicon/svg?seed=${property?.agent?._id}`
                }
                className="h-16 object-contain"
                alt="company"
            />

            <p className="my-4 text-base font-medium uppercase">Productor</p>
            <div className="flex items-center justify-between gap-4">
                <div className="flex items-center gap-2">
                    <NextImage
                        src={
                            property?.agent?.profilePicture ||
                            `https://api.dicebear.com/5.x/identicon/svg?seed=${property?.agent?._id}`
                        }
                        showPreview={false}
                        className="h-12 w-12 rounded-full object-cover"
                        width={64}
                        height={64}
                        alt="vendedor"
                    />
                    <p>
                        {property?.agent?.firstName} {property?.agent?.lastName}
                    </p>
                </div>
                <div className="flex items-center gap-1">
                    <a
                        href={`tel:${property?.agent?.phone}`}
                        className="btn btn-secondary flex h-10 w-10 items-center justify-center p-0"
                    >
                        <PhoneOutlined />
                    </a>
                    <Button
                        type="secondary"
                        onClick={async () => {
                            if (!property?.agent?.phone) {
                                return notification.error({
                                    message: 'No tenemos teléfono para este contacto'
                                });
                            }
                            const contact = await contactByChat(property?.agent);
                            setChatContact({
                                ...(contact as any),
                                message: `Hola, te escribo para consultarte por esta propiedad: ${process.env.NEXT_PUBLIC_PULPPO_URL || 'https://pulppo.com'}/propiedades/${property?._id}`
                            });
                        }}
                        className="flex h-10 w-10 items-center justify-center p-0"
                    >
                        <WhatsAppOutlined />
                    </Button>
                </div>
            </div>
            {property?.agentSeller?._id && property?.agentSeller?._id !== property?.agent?._id && (
                <>
                    <p className="my-4 font-medium uppercase">Vendedor</p>
                    <div className="flex items-center justify-between gap-4">
                        <div className="flex items-center gap-2">
                            <NextImage
                                src={
                                    property?.agentSeller?.profilePicture ||
                                    `https://ui-avatars.com/api/?name=${property?.agentSeller?.firstName}%20${property?.agentSeller?.lastName}`
                                }
                                showPreview={false}
                                className="h-12 w-12 rounded-full object-cover"
                                width={64}
                                height={64}
                                alt="vendedor"
                            />
                            <p>
                                {property?.agentSeller?.firstName} {property?.agentSeller?.lastName}
                            </p>
                        </div>
                        <div className="flex items-center gap-3">
                            <a
                                href={`tel:${property?.agentSeller?.phone}`}
                                className="btn btn-secondary flex h-10 w-10 items-center justify-center p-0"
                            >
                                <PhoneOutlined />
                            </a>
                            <Button
                                onClick={async () => {
                                    if (!property?.agentSeller?.phone) {
                                        return notification.error({
                                            message: 'No tenemos teléfono para este contacto'
                                        });
                                    }
                                    const contact = await contactByChat(property?.agentSeller);
                                    setChatContact({
                                        ...(contact as any),
                                        message: `Hola, te escribo para consultarte por esta propiedad: ${process.env.NEXT_PUBLIC_PULPPO_URL || 'https://pulppo.com'}/propiedades/${property?._id}`
                                    });
                                }}
                                className="flex h-10 w-10 items-center justify-center p-0"
                            >
                                <WhatsAppOutlined />
                            </Button>
                        </div>
                    </div>
                </>
            )}
            {!!permission && (
                <>
                    <p className="my-4 font-medium uppercase">Propietario</p>
                    <div className="flex items-center justify-between gap-4">
                        <div className="flex items-center gap-2">
                            <NextImage
                                src={
                                    property?.contact?.profilePicture ||
                                    `https://ui-avatars.com/api/?name=${property?.contact?.firstName}%20${property?.contact?.lastName}`
                                }
                                showPreview={false}
                                className="h-12 w-12 rounded-full object-cover"
                                width={64}
                                height={64}
                                alt="vendedor"
                            />
                            <p>
                                {property?.contact?.firstName} {property?.contact?.lastName}
                            </p>
                        </div>
                        <div className="flex items-center gap-3">
                            <a
                                href={`tel:${property?.contact?.phone}`}
                                className="btn btn-secondary flex h-10 w-10 items-center justify-center p-0"
                            >
                                <PhoneOutlined />
                            </a>
                            <Button
                                onClick={async () => {
                                    if (!property?.contact?.phone) {
                                        return notification.error({
                                            message: 'No tenemos teléfono para este contacto'
                                        });
                                    }
                                    const contact = await axios
                                        .post(`${api_url}/contact`, {
                                            firstName: property?.contact?.firstName,
                                            lastName: property?.contact?.lastName,
                                            phones: [
                                                {
                                                    phone: property?.contact.phone,
                                                    plain: property?.contact.phone.replace(/\D/gi, '')
                                                }
                                            ]
                                        })
                                        .then((r) => r.data);
                                    setChatContact({
                                        ...contact
                                    });
                                }}
                                className="flex h-10 w-10 items-center justify-center p-0"
                            >
                                <WhatsAppOutlined />
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

const MLSSellers = ({ property }: { property: IProperty }) => {
    const setChatContact = useChat((store) => store.setChatContact);
    const [gettingPhone, setGettingPhone] = useState(false);
    const companies = property.mls
        ? [{ ...(property?.company || {}) }, ...(property?.import?.announcers || [])].filter(
              (company, index, array) => {
                  return company.name && array.findIndex((el) => el.name == company.name) === index;
              }
          )
        : [];
    return (
        <div>
            <p className="text-base font-medium uppercase">Anunciantes</p>
            {!companies.some((company) => company.phone) && (
                <p className="mt-4">
                    No contamos con los datos de contacto de este anunciante. Para contactarte con el, haz click en
                    &quot;Ver en portal&quot; y contáctate a traves del portal.
                </p>
            )}
            <div className="my-7">
                {companies?.length > 0 ? (
                    companies.map((company) => (
                        <div key={company.name} className="flex items-center justify-between gap-4">
                            <div className="flex items-center gap-2">
                                <img
                                    src={
                                        company?.logo?.default ||
                                        `https://api.dicebear.com/5.x/identicon/svg?seed=${company?.name}`
                                    }
                                    className="h-12 w-12 rounded-full object-contain shadow-sm"
                                    alt={company?.name}
                                />
                                <p>{company?.name}</p>
                            </div>
                            <div className="flex items-center gap-3">
                                {(property?.portals?.inmuebles24 as any)?.url?.length > 0 && !!company?.phone && (
                                    <a
                                        href={`${(property?.portals?.inmuebles24 as any)?.url}`}
                                        target="_blank"
                                        className="btn btn-secondary flex h-10 w-10 items-center justify-center p-0"
                                    >
                                        <img src="/inmuebles.png" alt="inmuebles" />
                                    </a>
                                )}
                                {(property?.portals?.easybroker as any)?.url?.length > 0 && !!company?.phone && (
                                    <a
                                        href={`${(property?.portals?.easybroker as any)?.url}`}
                                        target="_blank"
                                        className="btn btn-secondary flex h-10 w-10 items-center justify-center border-none p-0"
                                    >
                                        <img
                                            src="/easybroker.svg"
                                            className="h-10 w-10 object-contain"
                                            alt="easybroker"
                                        />
                                    </a>
                                )}
                                {company?.phone && (
                                    <a
                                        href={`tel:${company?.phone}`}
                                        className={`btn btn-secondary flex h-10 w-10 items-center justify-center p-0 ${!company?.phone && 'pointer-events-none'}`}
                                    >
                                        <PhoneOutlined />
                                    </a>
                                )}
                                {company?.phone && (
                                    <LoadingButton
                                        loading={gettingPhone}
                                        disabled={!company?.phone}
                                        onClick={async () => {
                                            setGettingPhone(true);
                                            if (!company.phone) {
                                                return notification.error({
                                                    message: 'No tenemos teléfono para este contacto'
                                                });
                                            }
                                            const contact = await axios
                                                .post(`${api_url}/contact`, {
                                                    firstName: company.name,
                                                    lastName: '',
                                                    emails: [
                                                        {
                                                            email: company.email
                                                        }
                                                    ],
                                                    phones: [
                                                        {
                                                            phone: company.phone,
                                                            plain: company.phone.replace(/\D/gi, '')
                                                        }
                                                    ]
                                                })
                                                .then((r) => r.data)
                                                .catch((err) => console.error(err))
                                                .finally(() => {
                                                    setGettingPhone(false);
                                                });
                                            setChatContact({
                                                ...contact,
                                                message: `Hola, te escribo para consultarte por esta propiedad:  ${
                                                    property?.import?.url || property?.address?.street
                                                }`
                                            });
                                        }}
                                        target="_blank"
                                        className="flex h-10 w-10 items-center justify-center p-0"
                                    >
                                        {!gettingPhone && <WhatsAppOutlined />}
                                    </LoadingButton>
                                )}
                                {!company.phone && (
                                    <a
                                        className="btn btn-secondary flex h-10 items-center justify-center font-medium"
                                        href={property?.import?.url}
                                        target="_blank"
                                    >
                                        <p>Ver en portal</p>
                                    </a>
                                )}
                            </div>
                        </div>
                    ))
                ) : (
                    <a
                        className="btn btn-secondary flex h-10 items-center justify-center font-medium"
                        href={property?.import?.url}
                        target="_blank"
                    >
                        <p>Ver en portal</p>
                    </a>
                )}
            </div>
        </div>
    );
};

export const portals = [
    {
        name: 'Inmuebles24',
        path: 'inmuebles24',
        isFull: true,
        logo: '/inmuebles24_logo.png',
        condition: (property: IProperty) => property?.portals?.inmuebles24?.status === 'ONLINE'
    },
    {
        name: 'Easybroker',
        path: 'easybroker',
        isFull: true,
        logo: '/easybroker.svg',
        condition: (property: IProperty) => property?.portals?.easybroker?.status === 'published'
    },
    {
        name: 'Mercadolibre',
        path: 'mercadolibre',
        isFull: true,
        logo: '/mercadolibre_logo.png',
        condition: (property: any) =>
            property?.portals?.mercadolibre?.status === 'ONLINE' && !!property?.portals?.mercadolibre?.url
    },
    {
        name: 'Tu360Inmobiliario',
        path: 'tu360',
        isFull: true,
        logo: '/tu360.jpeg',
        condition: () => true
    },
    {
        name: 'Fincaraiz',
        path: 'fincaRaiz',
        isFull: true,
        logo: '/fincaraiz.png',
        condition: (property) =>
            property?.portals?.fincaRaiz?.status === 'ONLINE' && !!property?.portals?.fincaRaiz?.url
    },
    {
        name: 'Nestoria',
        path: 'nestoria',
        isFull: true,
        logo: '/nestoria.png',
        condition: () => true
    },
    {
        name: 'Nuroa',
        isFull: false,
        path: 'nuroa',
        logo: '/nuroa.png',
        condition: () => true
    },
    {
        name: 'Mitula',
        isFull: true,
        path: 'mitula',
        logo: '/mitula.png',
        condition: () => true
    },
    {
        name: 'Trovit',
        isFull: false,
        logo: '/trovit.png',
        path: 'trovit',
        condition: () => true
    },
    {
        name: 'Icasas',
        isFull: false,
        path: 'icasas',
        logo: '/icasas.png',
        condition: () => true
    },
    {
        name: 'Lamudi',
        isFull: true,
        path: 'lamudi',
        logo: '/lamudi_logo.png',
        condition: () => true
    },
    {
        name: 'Facebook (Página)',
        path: 'facebook_page',
        isFull: true,
        logo: '/facebook_logo.png',
        condition: () => true
    },
    {
        name: 'Instagram (Página)',
        path: 'instagram_page',
        isFull: true,
        logo: '/instagram_logo.png',
        condition: () => true
    },
    {
        name: 'Instagram',
        isFull: true,
        path: 'instagram',
        logo: '/instagram_logo.png',
        condition: () => true
    },
    {
        name: 'Facebook',
        isFull: true,
        path: 'facebook',
        logo: '/facebook_logo.png',
        condition: () => true
    }
];

export default PropertyDetailInternal;
