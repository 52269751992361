import React from 'react';
import { Button } from 'antd';
import { CustomMapIcon } from '../Icon/CustomMapIcon';
import { CustomListingIcon } from '../Icon/CustomListingIcon';

interface MapButtonProps {
  isMapView: boolean;
  onToggleView: () => void;
}

export const MapButton: React.FC<MapButtonProps> = ({ isMapView, onToggleView }) => {
  
  return (
    <Button
      onClick={onToggleView}
      className="flex items-center gap-2 bg-transparent px-3 h-8 border-none p-0"
    >
      {isMapView ? 'Ver listado' :  'Ver mapa'}
      {isMapView ?  <CustomListingIcon /> : <CustomMapIcon /> }
    </Button>
  );
};