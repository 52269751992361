import useSWR from 'swr';
import { api_url, fetcher } from '../helpers/fetcher';

const useMLSInfo = () => {
    const {
        data: scrapperInfo,
        mutate: refresh,
        isLoading
    } = useSWR<{
        lastUpdated: Date;
        health: {
            mercadolibre: 'healthy' | 'unhealthy' | 'intermittent';
            easybroker: 'healthy' | 'unhealthy' | 'intermittent';
            inmuebles24: 'healthy' | 'unhealthy' | 'intermittent';
        };
    }>(`${api_url}/scrapper/info`, fetcher);

    return {
        refresh,
        isLoading,
        state: {
            ...scrapperInfo
        }
    };
};

export default useMLSInfo;
