import { Services } from '@pulppo/pulppo-models/build/enums/Services';
import { useTranslate } from '@tolgee/react';

interface Props {
    type: 'amenities' | 'spaces' | 'additional';
    services: typeof Services;
}

export const ListServices = ({ type, services }: Props) => {
    const { t } = useTranslate('common');
    const servicesFiltered = (services || []).filter((service) =>
        service && type === 'amenities'
            ? service?.type === 1
            : type === 'spaces'
              ? service?.type === 2
              : service?.type === 3
    );
    const title = type === 'amenities' ? 'Amenidades' : type === 'spaces' ? 'Espacios' : 'Adicionales';
    return servicesFiltered?.length ? (
        <div>
            <h2 className="font-medium uppercase">{title}</h2>
            <ul className="mt-3 flex flex-wrap gap-x-2 gap-y-2">
                {servicesFiltered.map((service) => (
                    <li
                        key={`service-${service?.id}`}
                        className="inline-flex h-7 items-center rounded border border-solid border-black bg-white px-2 text-sm text-black"
                    >
                        {t(service?.name)}
                    </li>
                ))}
            </ul>
        </div>
    ) : (
        <></>
    );
};
