import {
    CaretRightFilled,
    CheckCircleFilled,
    DeleteOutlined,
    LoadingOutlined,
    PlusOutlined,
    SearchOutlined,
    WarningOutlined
} from '@ant-design/icons';
import { Row, Col, Form, Input, Upload, Avatar, Button as ButtonAnt, Typography, Modal, List, Divider } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { RcFile } from 'antd/lib/upload';
import { ReactNode, useEffect, useState } from 'react';
import axios from '../../utils/axios';
import { api_url } from '../../helpers/fetcher';
import dayjs from 'dayjs';
import { PulppoSelect } from '../Elements/PulppoSelect';
import { PulppoMultiselect } from '../Elements/PulppoMultiselect';
import { IAgent, IContact } from '@pulppo/pulppo-models';
import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { NextImage } from '../Elements/NextImage';
import { Phones } from './Phones';
import DatePicker from '../Elements/DatePicker';
import { UserAvatar } from '../Home/UserAvatar';
import { contactService } from '../../services/contactService';
import { twMerge } from 'tailwind-merge';
import { PulppoLoader } from '../Elements/PulppoLoader';
import { ImageUploaderService } from '../../services/ImageUploaderService';
import { getCountryCallingCode } from 'react-phone-number-input';
import { CountryCode } from 'libphonenumber-js/types';
import { IPhone } from '@pulppo/pulppo-models/@types/pulppo-models/schemas/Contact';
import AvatarUser from '../Elements/AvatarUser';
import Button from '../Elements/Button';
import { useDebounce } from '../../hooks/debounce';
import { useRouter } from 'next/router';
import { CreateContact } from './CreateContact';
import useUser from '../../hooks/useUser';
import { AgentSelect } from '../Elements/AgentSelect';
import { IResponse } from '../../types';
import { IEmail } from '@pulppo/pulppo-models/build';
import { ContactTag } from '@pulppo/pulppo-models/build/enums/ContactTag';

export interface IAvatarUploadProps {
    avatar: string;
    setAvatar: Function;
    setLoadingAvatar: Function;
    loadingAvatar: boolean;
    contact_id: string;
}

export interface IDisabledInputs {
    current?: boolean;
    add?: boolean;
    delete?: boolean;
}

export const AvatarUpload = ({
    avatar,
    setAvatar,
    setLoadingAvatar,
    loadingAvatar,
    contact_id
}: IAvatarUploadProps) => {
    return (
        <Upload
            name="profilePicture"
            listType="picture-card"
            accept="image/*"
            showUploadList={false}
            customRequest={({ file, ...events }) => {
                ImageUploaderService.upload(
                    file as RcFile,
                    `/property/contact/${contact_id}/profile_picture`,
                    events
                ).catch((err) => console.error(`No se pudieron subir los archivos ${err}`));
            }}
            beforeUpload={() => {}}
            onChange={(info: any) => {
                if (info.file.status === 'uploading') {
                    setLoadingAvatar(true);
                    return;
                }
                if (info.file.status === 'done') {
                    setLoadingAvatar(false);
                    const newFileName = info.file.response;
                    setAvatar(newFileName);
                }
            }}
        >
            {avatar ? (
                <Avatar
                    className="shrink-0"
                    style={{
                        width: '100%',
                        height: '100%'
                    }}
                    src={<NextImage showPreview={false} src={avatar} alt={avatar} width={256} height={256} />}
                />
            ) : (
                <UploadButton loading={loadingAvatar} />
            )}
        </Upload>
    );
};

const UploadButton = ({ loading }) => (
    <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
    </div>
);

const Emails = ({
    contact,
    user,
    initialContact,
    showIcons = true,
    onContactFound = (_) => {},
    disabled,
    onRemove
}: {
    user: IAgent;
    contact: IContact;
    initialContact: IContact;
    showIcons: boolean;
    onContactFound?: (contact: IContact) => void;
    disabled?: IDisabledInputs;
    onRemove?: (index: number) => Promise<void> | void;
}) => {
    return (
        <div className="my-10px">
            <Row>
                <Typography.Text className="font-medium">Email</Typography.Text>
            </Row>
            <Form.List name="emails">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field, index) => {
                            return (
                                <div key={field.key} className="mb-2 flex items-stretch gap-2">
                                    <div className="flex-1">
                                        <Form.Item name={[field.name, 'email']} className="mb-0">
                                            <Input
                                                type="email"
                                                inputMode="email"
                                                className="rounded-none py-2"
                                                onChange={async (e) => {
                                                    if (disabled?.current) return;
                                                    if (!e.target.value) return;

                                                    const contact = await axios
                                                        .get(`${api_url}/contact/emails/${e.target.value}`)
                                                        .then((res) => res.data);
                                                    if (contact) {
                                                        onContactFound(contact);
                                                    }
                                                }}
                                                disabled={
                                                    (contact &&
                                                        initialContact?.emails?.[index] &&
                                                        contact?.agent?.uid != user?.uid &&
                                                        contact?.agent?.uid != 'p8otaxMSkWTU9JApohEDpDUBTP83') ||
                                                    (disabled?.current && index + 1 <= contact?.emails?.length)
                                                }
                                            />
                                        </Form.Item>
                                    </div>
                                    {showIcons && (index !== 0 || index == fields.length - 1) && (
                                        <div className="flex items-stretch gap-2">
                                            {index !== 0 && (
                                                <ButtonAnt
                                                    onClick={async () => {
                                                        if (!disabled?.delete || user.features?.contactsEdit) {
                                                            if (onRemove) {
                                                                await onRemove(index);
                                                            } else {
                                                                remove(index);
                                                            }
                                                        }
                                                    }}
                                                    disabled={
                                                        fields.length <= 1 ||
                                                        (disabled?.delete &&
                                                            index + 1 <= contact?.emails?.length &&
                                                            !user.features?.contactsEdit)
                                                    }
                                                    className="h-auto w-full px-4 py-2"
                                                    icon={
                                                        <DeleteOutlined
                                                            className={fields.length <= 1 && 'text-pulppo-primary-gray'}
                                                        />
                                                    }
                                                />
                                            )}
                                            {index == fields.length - 1 && (
                                                <ButtonAnt
                                                    onClick={() => {
                                                        if (disabled?.add) return;
                                                        add({
                                                            phone: '',
                                                            type: 'personal'
                                                        });
                                                    }}
                                                    disabled={disabled?.add}
                                                    className="h-auto w-full px-4 py-2"
                                                    icon={<PlusOutlined className="font-bold" />}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </>
                )}
            </Form.List>
        </div>
    );
};
export const BasicInformation = ({
    onCancel,
    onFinish,
    contact: initialContact,
    footer = null,
    user,
    onUpdate = undefined,
    inDrawer = false,
    showSave = false,
    saveButton = <></>,
    cancelButton = <></>,
    showAdditionalInfo = true,
    disabled,
    useLocalContact = false,
    migrate
}: {
    contact: IContact;
    footer?: any;
    user: IAgent;
    onUpdate?: (contact: IContact) => void;
    onFinish: (contact: IContact) => void;
    onCancel: () => void;
    inDrawer?: boolean;
    showSave?: boolean;
    saveButton?: ReactNode;
    cancelButton?: ReactNode;
    showAdditionalInfo?: boolean;
    disabled?: IDisabledInputs;
    useLocalContact?: boolean;
    migrate?: {
        type: 'phone' | 'email';
        fromId: string;
    };
}) => {
    const [form] = useForm<IContact>();
    const [avatar, setAvatar] = useState('');
    const [loading, setLoading] = useState(false);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [foundContact, setFoundContact] = useState<IContact>(null);
    const [showFoundContact, setShowFoundContact] = useState(false);
    const [contact, setContact] = useState<IContact>(null);
    const [showModalMigrateCommunicationChannel, setShowModalMigrateCommunicationChannel] = useState<
        | ({
              type: 'phone';
          } & IPhone)
        | ({
              type: 'email';
          } & IEmail)
        | null
    >(null);

    useEffect(() => {
        if (initialContact?._id && !useLocalContact) {
            setLoading(true);
            axios
                .get(`${api_url}/contact/${initialContact._id}`)
                .then((r) => setContact(r.data))
                .catch(() => setContact(initialContact))
                .finally(() => setLoading(false));
        }
        if (useLocalContact) {
            setContact(initialContact);
        }
    }, [initialContact, useLocalContact]);

    useEffect(() => {
        if (!onUpdate) form.resetFields();
        setAvatar(contact?.profilePicture);
        form.setFieldsValue({
            ...contact,
            tags: contact?.tags || [],
            dob: contact?.dob && dayjs(contact?.dob).tz(),
            phones: contact?.phones?.length
                ? contact?.phones
                : [
                      {
                          phone: '',
                          type: 'whatsapp',
                          is_default: true
                      }
                  ],
            emails: contact?.emails?.length
                ? contact?.emails
                : [
                      {
                          email: '',
                          type: 'personal',
                          is_default: true
                      }
                  ]
        });
    }, [contact]);

    const sendNewContact = async (form: IContact) => {
        let { firstName, lastName, profilePicture, company, phones, emails, dob, tags } = form;
        phones = phones?.filter((p) => p.phone);
        if (!phones?.filter((elem) => elem.phone).length && !emails?.filter((elem) => elem.email).length) {
            Modal.error({
                title: 'Error',
                content: 'El numero de télefono o el email que cargó estan incompletos.'
            });
            setLoading(false);
            return;
        } else if (phones?.length && phones?.some((phone) => phone.phone.length < 12)) {
            Modal.error({
                title: 'Error',
                content: 'El número de teléfono no esta completo'
            });
            setLoading(false);
            return;
        }
        phones = phones?.length
            ? phones
                  .filter((phone) => phone.phone)
                  .map((p) => {
                      let phone = p?.phone;
                      if (phone?.length === 11 && p?.countryCode) {
                          try {
                              const numberCountryCode = getCountryCallingCode(p.countryCode as CountryCode);
                              phone = phone.replace(`+${numberCountryCode} `, '');
                          } catch {}
                      }
                      return {
                          ...p,
                          phone,
                          plain: phone.replace(/\D/gi, '')
                      };
                  })
            : [];

        let response;
        if (contact?._id) {
            response = await axios
                .patch(`${api_url}/contact/${contact?._id}`, {
                    ...form,
                    firstName,
                    lastName,
                    profilePicture: profilePicture || avatar,
                    dob: dob?.toISOString(),
                    phones,
                    emails: emails?.length ? emails?.filter((email) => email.email) : [],
                    tags,
                    company,
                    updateSearches: true,
                    ...(firstName !== contact?.firstName || lastName !== contact?.lastName
                        ? {
                              notes: [
                                  ...(contact.notes || []),
                                  {
                                      createdAt: new Date(),
                                      content: `Cambiado el nombre de ${contact.firstName} ${contact.lastName} a ${firstName} ${lastName}`,
                                      creator: user,
                                      isPublic: true
                                  }
                              ]
                          }
                        : {})
                })
                .then((res) => res.data);
        } else {
            response = await axios
                .post(`${api_url}/contact`, {
                    ...form,
                    firstName,
                    lastName,
                    profilePicture: profilePicture || avatar,
                    dob: dob?.toISOString(),
                    contact_status: 'contact_required',
                    phones,
                    emails: emails?.length ? emails?.filter((email) => email.email) : [],
                    tags: tags,
                    company,
                    ...(migrate
                        ? {
                              migrate: {
                                  fromId: migrate.fromId,
                                  type: migrate.type,
                                  channel: migrate.type === 'phone' ? phones?.[0]?.plain : emails?.[0]?.email
                              }
                          }
                        : {})
                })
                .then((res) => res.data);
        }
        return response;
    };

    const onContactFound = (contact: IContact) => {
        setFoundContact(contact);
        setShowFoundContact(true);
    };

    const onRemoveCommunicationChannel = ({ type, index }: { type: 'email' | 'phone'; index: number }) => {
        let communicationChannel = null;
        if (type === 'phone') {
            const phones = form.getFieldValue('phones');
            const phone = phones[index];
            if (!phone || !phone?._id) {
                return;
            }
            if (phones.length - 1 < 1) {
                Modal.error({
                    title: 'Error',
                    content: 'No puedes eliminar el único número de teléfono'
                });
                return;
            }
            communicationChannel = {
                ...phone,
                type: 'phone'
            };
        } else {
            const emails = form.getFieldValue('emails');
            const email = emails[index];
            if (!email || !email?._id) {
                return;
            }
            if (emails.length - 1 < 1) {
                Modal.error({
                    title: 'Error',
                    content: 'No puedes eliminar el único email'
                });
                return;
            }
            communicationChannel = {
                ...email,
                type: 'email'
            };
        }
        setShowModalMigrateCommunicationChannel(communicationChannel);
    };

    if (!user) return <div>Loading user</div>;

    return (
        <PulppoLoader loading={loading}>
            <div className="h-full">
                <Form
                    scrollToFirstError={false}
                    onFocus={() => {}}
                    form={form}
                    id="basic_information"
                    key="basic_information"
                    name="basic_information"
                    className="flex h-full flex-col justify-between"
                    layout="vertical"
                    initialValues={
                        contact
                            ? {
                                  ...contact,
                                  dob: contact?.dob && dayjs(contact?.dob).tz(),
                                  phones: contact?.phones?.length
                                      ? contact?.phones
                                      : [
                                            {
                                                phone: '',
                                                type: 'whatsapp',
                                                is_default: true
                                            }
                                        ],
                                  emails: contact?.emails?.length
                                      ? contact?.emails
                                      : [
                                            {
                                                email: '',
                                                type: 'personal',
                                                is_default: true
                                            }
                                        ]
                              }
                            : {
                                  phones: [
                                      {
                                          phone: '',
                                          type: 'whatsapp',
                                          is_default: true
                                      }
                                  ],
                                  emails: [
                                      {
                                          email: '',
                                          type: 'personal',
                                          is_default: true
                                      }
                                  ]
                              }
                    }
                    onValuesChange={(_, form) => {
                        if (onUpdate) {
                            onUpdate({
                                ...form,
                                profilePicture: form.profilePicture || avatar,
                                dob: form.dob?.toISOString() as any as Date,
                                emails: form.emails?.filter((email) => email.email),
                                phones: form.phones
                                    .filter((phone) => phone.phone)
                                    .map((phone) => ({
                                        ...phone,
                                        plain: phone.phone.replace(/\D/gi, '')
                                    }))
                            });
                        }
                    }}
                    onFinish={async (form) => {
                        setLoading(true);
                        let responseContact: IContact = null;
                        if (foundContact) {
                            const { firstName, lastName } = form;
                            await contactService.addViewer({
                                contactId: foundContact._id,
                                firstName,
                                lastName,
                                agentUid: user.uid
                            });
                            responseContact = {
                                ...foundContact,
                                firstName,
                                lastName
                            };
                        } else {
                            const newPhone = form?.phones?.find(
                                (phone) => !contact?.phones?.find((p) => p?.phone === phone?.phone)
                            );
                            if (newPhone?.phone) {
                                const contactExist = await contactService.searchExistByPhone(newPhone.phone);
                                if (contactExist) {
                                    onContactFound(contactExist);
                                    setLoading(false);
                                    return;
                                }
                            }
                            responseContact = await sendNewContact(form);
                        }
                        setLoading(false);

                        if (!responseContact) {
                            return;
                        }
                        onFinish(responseContact);
                    }}
                    autoComplete="off"
                >
                    <div className="flex-1">
                        {foundContact?.profilePicture ? (
                            <div>
                                <UserAvatar agent={foundContact} />
                            </div>
                        ) : null}
                        <Row gutter={10} className="my-10px">
                            <Col xs={24}>
                                <Form.Item
                                    label="Nombres"
                                    name="firstName"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Ingrese nombre'
                                        }
                                    ]}
                                >
                                    <Input className="rounded-none" />
                                </Form.Item>
                            </Col>

                            <Col xs={24}>
                                <Form.Item
                                    label="Apellidos"
                                    name="lastName"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Ingrese apellido'
                                        }
                                    ]}
                                >
                                    <Input className="rounded-none" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Phones
                            contact={contact}
                            user={user}
                            initialContact={contact}
                            showIcons={true}
                            onContactFound={onContactFound}
                            disabled={
                                foundContact
                                    ? {
                                          current: true,
                                          delete: true
                                      }
                                    : disabled
                            }
                            onRemove={(index) => {
                                onRemoveCommunicationChannel({ type: 'phone', index });
                            }}
                        />
                        <Emails
                            contact={contact}
                            user={user}
                            initialContact={contact}
                            showIcons={true}
                            onContactFound={(contact) => {
                                setFoundContact(contact);
                                setShowFoundContact(true);
                            }}
                            disabled={
                                foundContact
                                    ? {
                                          current: true,
                                          delete: true
                                      }
                                    : disabled
                            }
                            onRemove={(index) => {
                                onRemoveCommunicationChannel({ type: 'email', index });
                            }}
                        />
                        {!inDrawer ? (
                            <Row className="my-10px">
                                <Col span={24}>
                                    <Form.Item label="Etiquetas" name="tags">
                                        <PulppoMultiselect
                                            title="Seleccione las etiquetas"
                                            options={Object.values(ContactTag).map((tag) => ({
                                                value: tag,
                                                key: tag,
                                                label: tag.charAt(0).toUpperCase() + tag.slice(1)
                                            }))}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        ) : null}
                        {onUpdate && showAdditionalInfo && (
                            <>
                                <div
                                    className="cursor-pointer text-lg font-bold"
                                    onClick={() => setShowAdvanced((a) => !a)}
                                >
                                    Información adicional{' '}
                                    <CaretRightFilled
                                        className={twMerge(
                                            'ml-2 transform transition-all duration-500',
                                            showAdvanced ? 'rotate-90' : 'rotate-0'
                                        )}
                                    />
                                </div>
                                <p className="text-base text-pulppo-primary-gray">
                                    Completa la ficha de tu contacto agregando información adicional
                                </p>
                                <div className={showAdvanced ? 'block' : 'hidden'}>
                                    <Row className="my-10px">
                                        <Col md={24} xs={24}>
                                            <Row gutter={10}>
                                                <Col lg={12} md={24} xs={24}>
                                                    <Form.Item label="Fecha de nacimiento" name="dob">
                                                        <DatePicker
                                                            className="w-full"
                                                            disabled={
                                                                contact &&
                                                                contact?.dob &&
                                                                contact?.agent?.uid != user?.uid
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col lg={12} md={24} xs={24}>
                                                    <Form.Item label="Empresa" name={['company', 'name']}>
                                                        <Input
                                                            disabled={
                                                                contact &&
                                                                contact?.company?.name &&
                                                                contact?.agent?.uid != user?.uid
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row gutter={10}>
                                        <Col lg={12} md={24} xs={24}>
                                            <Form.Item label="Estado Civil" name="civilStatus">
                                                <PulppoSelect
                                                    title="Estado civil"
                                                    onSelect={() => {}}
                                                    allowSearch={false}
                                                    placeholder="Seleccione estado civil"
                                                    options={[
                                                        {
                                                            value: 'soltero',
                                                            label: 'Soltero',
                                                            key: 'soltero'
                                                        },
                                                        {
                                                            value: 'casadoSeparacionBienes',
                                                            label: 'Casado separación de bienes',
                                                            key: 'casadoSeparacionBienes'
                                                        }
                                                    ]}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={12} md={24} xs={24}>
                                            <Form.Item label="Tipo de persona" name={['moralPerson', 'type']}>
                                                <PulppoSelect
                                                    onSelect={() => {}}
                                                    allowSearch={false}
                                                    placeholder="Tipo de Persona"
                                                    title="Tipo de Persona"
                                                    options={[
                                                        {
                                                            value: 'fisica',
                                                            label: 'Física',
                                                            key: 'fisica'
                                                        },
                                                        {
                                                            value: 'moral',
                                                            label: 'Moral',
                                                            key: 'moral'
                                                        }
                                                    ]}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {form.getFieldValue(['moralPerson', 'type']) === 'moral' && (
                                        <Row gutter={10}>
                                            <Col lg={12} md={24} xs={24}>
                                                <Form.Item
                                                    label="Representante"
                                                    name={['moralPerson', 'representative']}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>

                                            <Col lg={12} md={24} xs={24}>
                                                <Form.Item
                                                    label="Nombre de la persona moral"
                                                    name={['moralPerson', 'name']}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                    {showSave && (
                        <div className="mt-5 flex w-full items-center justify-between gap-2">
                            {cancelButton}
                            {saveButton}
                        </div>
                    )}
                    {footer || (
                        <div className="mt-6 flex items-center gap-2">
                            <Form.Item className="flex-1">
                                <ButtonAnt
                                    className="h-auto w-full py-2 font-medium"
                                    htmlType="button"
                                    onClick={onCancel}
                                >
                                    Cancelar
                                </ButtonAnt>
                            </Form.Item>
                            <Form.Item className="flex-1">
                                <ButtonAnt
                                    loading={loading}
                                    type="primary"
                                    htmlType="submit"
                                    className="h-auto w-full py-2 font-medium"
                                >
                                    Agregar contacto
                                </ButtonAnt>
                            </Form.Item>
                        </div>
                    )}
                </Form>
                <FoundContactDrawer
                    contact={foundContact}
                    show={showFoundContact}
                    onClose={() => {
                        setFoundContact(null);
                        setShowFoundContact(false);
                    }}
                    onOk={async () => {
                        form.setFieldsValue(foundContact);
                        setShowFoundContact(false);
                    }}
                />
            </div>
            <ModalMigratePhone
                show={!!showModalMigrateCommunicationChannel}
                channelCommunicationToMigrate={showModalMigrateCommunicationChannel}
                onClose={() => setShowModalMigrateCommunicationChannel(null)}
                contact={contact}
            />
        </PulppoLoader>
    );
};

const ModalMigratePhone = ({
    show,
    onClose,
    channelCommunicationToMigrate,
    contact
}: {
    show: boolean;
    onClose: () => void;
    channelCommunicationToMigrate:
        | ({
              type: 'email';
          } & IEmail)
        | ({
              type: 'phone';
          } & IPhone);
    contact: IContact;
}) => {
    const [showSelectAgent, setShowSelectAgent] = useState(false);
    const [isLoadingAgents, setIsLoadingAgents] = useState(false);
    const [selectedAgent, setSelectedAgent] = useState<IAgent>(null);
    const [agents, setAgents] = useState<Array<IAgent>>([]);
    const [showAgents, setShowAgents] = useState(false);
    const [showContactToMigrate, setShowContactToMigrate] = useState(false);
    const [searchContact, setSearchContact] = useState('');
    const [contactsToMigrate, setContactsToMigrate] = useState([]);
    const [showAddContact, setShowAddContact] = useState(false);
    const user = useUser((state) => state.user);
    const router = useRouter();

    useDebounce(
        () => {
            if (searchContact) {
                axios
                    .get(`${api_url}/contact/search?search=${searchContact}&searchAll=true`)
                    .then((res) => res.data)
                    .then((contacts) => {
                        setContactsToMigrate(contacts?.result || []);
                    });
            } else {
                setContactsToMigrate([]);
            }
        },
        500,
        [searchContact]
    );

    useEffect(() => {
        if (show) {
            setShowSelectAgent(true);
        } else {
            setShowSelectAgent(false);
            setSelectedAgent(null);
        }
    }, [show]);

    useEffect(() => {
        if (!showContactToMigrate) {
            setSearchContact('');
            setContactsToMigrate([]);
        }
    }, [showContactToMigrate]);

    useEffect(() => {
        if (selectedAgent) {
        }
    }, [selectedAgent]);

    return (
        <>
            <Modal
                title="Migrar teléfono | Paso 1/3"
                open={!!showSelectAgent}
                onCancel={() => {
                    setSelectedAgent(null);
                    setShowSelectAgent(false);
                    onClose();
                }}
                confirmLoading={isLoadingAgents}
                onOk={async () => {
                    if (!selectedAgent) return;
                    setIsLoadingAgents(true);
                    await axios
                        .get<
                            IResponse<{
                                agents: Array<IAgent>;
                            }>
                        >(
                            `${api_url}/contact/${contact._id}/${channelCommunicationToMigrate.type}/${channelCommunicationToMigrate.type === 'phone' ? channelCommunicationToMigrate.plain : channelCommunicationToMigrate.email}/agents/has-conversation`
                        )
                        .then(({ data }) => {
                            if (data?.success && data?.data?.agents?.length) {
                                setAgents(data.data.agents.filter((agent) => agent.uid !== selectedAgent.uid));
                            }
                        })
                        .finally(() => {
                            setIsLoadingAgents(false);
                            setShowSelectAgent(false);
                            setShowAgents(true);
                        });
                }}
            >
                <p className="mb-1 text-base font-medium">¿Quien solicito el cambio de teléfono?</p>
                <AgentSelect showAllAgents user={user} value={selectedAgent} onChange={setSelectedAgent} />
            </Modal>
            <Modal
                title="Migrar teléfono | Paso 2/3"
                open={!!showAgents}
                footer={agents.length ? null : undefined}
                onCancel={() => {
                    setShowAgents(false);
                    setAgents([]);
                    setSelectedAgent(null);
                    onClose();
                }}
                onOk={() => {
                    setShowAgents(false);
                    setShowContactToMigrate(true);
                }}
            >
                {agents?.length ? (
                    <>
                        <p className="text-base font-medium">
                            Todos estos asesores tienen algun tipo de contacto con el cliente:
                        </p>
                        <div className="mt-2 grid max-h-96 grid-cols-2 gap-2 overflow-y-auto">
                            {agents.map((agent) => (
                                <AvatarUser
                                    isAgent
                                    key={`agent-${agent?.uid}`}
                                    user={agent}
                                    description={`${agent?.company?.name}`}
                                />
                            ))}
                        </div>
                        <p className="mt-2 text-lg font-medium text-status-dark-error">
                            <WarningOutlined className="mr-2" />
                            Es por ello que no se puede realizar la migración
                        </p>
                    </>
                ) : (
                    <div className="flex flex-col items-center justify-center">
                        <CheckCircleFilled className="text-6xl text-status-dark-success" />
                        <p className="mt-2 text-lg font-medium">Se puede migrar el número</p>
                    </div>
                )}
            </Modal>
            <Modal
                title="Migrar teléfono | Paso 3/3"
                open={!!showContactToMigrate}
                footer={null}
                onCancel={() => {
                    setShowContactToMigrate(false);
                    setAgents([]);
                    setSelectedAgent(null);
                    onClose();
                }}
            >
                <p>Selecciona el contacto al que quieres migrarle el teléfono</p>
                <Input
                    className="mt-2"
                    placeholder="Buscar contacto por nombre, email o teléfono"
                    prefix={<SearchOutlined />}
                    onChange={(e) => setSearchContact(e.target.value)}
                    value={searchContact}
                />
                <div className="mt-2 max-h-96 space-y-2 overflow-y-auto">
                    {contactsToMigrate.map((contactToMigrate) => (
                        <button
                            onClick={() => {
                                const channel = channelCommunicationToMigrate.type === 'phone' ? 'teléfono' : 'email';
                                Modal.confirm({
                                    title: `¿Estás seguro de migrar el ${channel}?`,
                                    content: `Estás a punto de migrar el ${channel} a ${contactToMigrate.firstName} ${contactToMigrate.lastName}`,
                                    onOk: async () => {
                                        await axios.patch(
                                            `${api_url}/contact/${contact._id}/migrate/${channelCommunicationToMigrate.type}/${channelCommunicationToMigrate._id}/to/${contactToMigrate._id}`
                                        );
                                        await router.push(`/contact/${contactToMigrate._id}`);
                                        onClose();
                                        setShowContactToMigrate(false);
                                    }
                                });
                            }}
                            key={contactToMigrate._id}
                            className="block w-full p-1 transition-colors hover:bg-primary-gray"
                        >
                            <AvatarUser disabled user={contactToMigrate} />
                        </button>
                    ))}
                </div>
                <div className="mt-4">
                    {contactsToMigrate.length ? <Divider /> : null}
                    <Button
                        onClick={() => {
                            setShowAddContact(true);
                        }}
                        type="secondary"
                        icon={<PlusOutlined className="ml-2" />}
                    >
                        Crear nuevo contacto
                    </Button>
                </div>
            </Modal>
            <PulppoDrawer
                title="Agregar contacto"
                visible={showAddContact}
                onClose={() => {
                    setShowAddContact(false);
                }}
            >
                <CreateContact
                    user={user}
                    initialValue={
                        channelCommunicationToMigrate?.type === 'phone'
                            ? ({
                                  phones: [channelCommunicationToMigrate]
                              } as IContact)
                            : ({
                                  emails: [channelCommunicationToMigrate]
                              } as IContact)
                    }
                    onCancel={() => setShowAddContact(false)}
                    setCurrentStep={() => {}}
                    onFinish={async (contact) => {
                        await router.push(`/contact/${contact._id}`);
                        setShowAddContact(false);
                        setShowContactToMigrate(false);
                    }}
                    useLocalContact
                    migrate={{
                        type: channelCommunicationToMigrate?.type,
                        fromId: `${contact?._id}`
                    }}
                />
            </PulppoDrawer>
        </>
    );
};

const FoundContactDrawer = ({
    contact,
    show,
    onClose,
    onOk
}: {
    contact: IContact;
    show: boolean;
    onClose: () => void;
    onOk: () => void;
}) => {
    return (
        <PulppoDrawer visible={show} onClose={onClose} title="Contacto encontrado">
            <p className="mb-5 text-pulppo-primary-gray">
                Hemos encontrado el contacto que quieres cargar en nuestra base de datos. Puedes <b>Seleccionarlo</b>{' '}
                para continuar trabajando con él o <b>cancelar</b> para volver atrás y cargar otro contacto.
            </p>
            {contact && (
                <List.Item
                    className="cursor-pointer rounded-md border border-solid border-gray-300 px-5"
                    actions={[<CheckCircleFilled className="text-3xl text-green-400" key="check" />]}
                >
                    <List.Item.Meta
                        title={<p className="text-lg">{`${contact.firstName} ${contact.lastName}`}</p>}
                        description={
                            <>
                                <p className="text-sm">{contact.email}</p>
                                <p className="text-sm">{contact.phone}</p>
                            </>
                        }
                        avatar={
                            <Avatar
                                src={
                                    <NextImage
                                        showPreview={false}
                                        src={
                                            contact.profilePicture ||
                                            `https://ui-avatars.com/api/?name=${contact?.firstName}%20${contact?.lastName}`
                                        }
                                        alt={contact.profilePicture}
                                        width={64}
                                        height={64}
                                    />
                                }
                                size="large"
                            />
                        }
                    />
                </List.Item>
            )}
            <div className="mt-5 flex justify-end gap-5">
                <ButtonAnt className="rounded-md" onClick={onClose}>
                    Cancelar
                </ButtonAnt>
                <ButtonAnt onClick={onOk} type="primary">
                    Unificar contacto
                </ButtonAnt>
            </div>
        </PulppoDrawer>
    );
};
