import { List, notification } from 'antd';
import { PulppoDrawer } from './PulppoDrawer';
import { PlusCircleOutlined, ShareAltOutlined } from '@ant-design/icons';
import useIsMobile from '../../hooks/isMobile';

export const PropertyShareDrawer = ({ show, onClose, onAdd, onShare, user, selectedProperties }) => {
    const isMobile = useIsMobile();
    return (
        <PulppoDrawer title="Agregar a búsqueda" visible={show} onClose={onClose}>
            <List>
                {user && (
                    <List.Item
                        onClick={() => {
                            onAdd(selectedProperties, false).then(() => {
                                onShare();
                            });
                            onClose();
                        }}
                        className="cursor-pointer hover:underline"
                    >
                        <List.Item.Meta
                            title={
                                <p className="text-lg">
                                    <ShareAltOutlined className="mr-2 text-xl" />
                                    Agregar y compartir
                                </p>
                            }
                            description="Agrega las propiedades a la búsqueda y compartela con tu cliente"
                        ></List.Item.Meta>
                    </List.Item>
                )}
                <List.Item
                    className="cursor-pointer hover:underline"
                    onClick={() => {
                        onAdd(selectedProperties, false).then(() => {
                            notification.success({
                                message: 'Propiedades agregadas con éxito',
                                description: `Se agregaron correctamente las propiedades a la búsqueda`,
                                duration: isMobile ? 2 : 4,
                                placement: isMobile ? 'bottomRight' : 'topRight'
                            });
                        });
                        onClose();
                    }}
                >
                    <List.Item.Meta
                        title={
                            <p className="text-lg">
                                <PlusCircleOutlined className="mr-2 text-xl" />
                                Solo agregar
                            </p>
                        }
                        description="Agrega las propiedades a la búsqueda. Podrás compartirla mas adelante desde la pantalla de búsqueda"
                    ></List.Item.Meta>
                </List.Item>
            </List>
        </PulppoDrawer>
    );
};
