import { ICourseAgent, ICourseWithStatus, STATUS_NOT_STARTED } from '../types';
import { ICourse } from '@pulppo/pulppo-models';

export const setCourseToAgentOngoing = (
    agentCourses: Array<ICourseAgent>,
    courses: Array<ICourse>
): Array<ICourseWithStatus> => {
    const agentCoursesArray = Array.isArray(agentCourses) ? agentCourses : [];
    const AgentCoursesNormalize: Array<ICourseWithStatus> = [];
    for (const course of courses) {
        const agentCourseFound = agentCoursesArray.find((agentCourse) => agentCourse?._id === course?._id);
        AgentCoursesNormalize.push({
            ...course,
            progress: {
                status: agentCourseFound?.status || STATUS_NOT_STARTED,
                ongoing: !!agentCourseFound?.idLastClass,
                idLastClass: agentCourseFound?.idLastClass
            }
        });
    }
    return AgentCoursesNormalize;
};
