import { IOperation, IProperty } from '@pulppo/pulppo-models';
import { MetricType } from '@pulppo/pulppo-models/build/enums/MetricType';
import { Currency } from '@pulppo/pulppo-models/build/@types/pulppo-models/enums/enums';
import { AttributeMapper } from './AttributeMapper';

export const MARKETING_CATEGORIES = [
    {
        name: 'Marca personal',
        iconUrl: '/icons/brand-identity.png',
        key: 'personal'
    },
    {
        name: 'Produccion de propiedades',
        iconUrl: '/icons/for-sale.png',
        key: 'production'
    },
    {
        name: 'Promoción de propiedades',
        iconUrl: '/icons/public-relation.png',
        key: 'promotion'
    }
];
export const MARKETING_CATEGORIES_AUTOMATE = [
    {
        name: 'Promoción de propiedades',
        iconUrl: '/icons/public-relation.png',
        key: 'promotion'
    }
];
export const MARKETING_SUB_CATEGORIES = [
    {
        name: 'Historia',
        match: ['Historia de Instagram de Propiedad'],
        category: 'promotion'
    },
    {
        name: 'Posteo',
        match: ['Posteo de Instagram de Propiedad'],
        category: 'promotion'
    },
    {
        name: 'Posteo',
        match: ['Notificar que me uní a Pulppo'],
        category: 'personal'
    },
    {
        name: 'Tarjeta',
        match: ['Tarjeta'],
        category: 'personal'
    },
    {
        name: 'Firma',
        match: ['Firma'],
        category: 'personal'
    },
    {
        name: 'Portada',
        match: ['Portada'],
        category: 'personal'
    },
    {
        name: 'Aviso Carta',
        match: ['Aviso Carta de Propiedad'],
        category: 'promotion'
    },
    {
        name: 'Instagram',
        match: ['Comprar con Pulppo Instagram'],
        category: 'promotion'
    },
    {
        name: 'Historia',
        match: ['Vender con Pulppo Historia de Instagram'],
        category: 'production'
    },
    {
        name: 'Posteo',
        match: ['Vender con Pulppo Instagram', 'Vender con Pulppo Presentación'],
        category: 'production'
    }
];

export const MARKETING_SUB_CATEGORIES_AUTOMATE = [
    {
        name: 'Promocionar propiedades mias',
        category: 'promotion',
        key: 'me',
        iconUrl: '/icons/user.png'
    },
    {
        name: 'Propiedades de [masterbroker]',
        category: 'promotion',
        key: 'masterbroker',
        iconUrl: '/icons/enterprise.png'
    },
    {
        name: 'Propiedades de Pulppo',
        category: 'promotion',
        key: 'pulppo',
        iconUrl: '/icons/icon-512x512.png'
    }
];

export const FREQUENCY = [
    { label: '1 vez por día', value: 'once a day' },
    { label: '2 veces por día', value: 'two times a day' },
    { label: '1 vez por semana', value: 'once a week' },
    { label: '3 veces por semana', value: 'three times a week' }
];

export enum IconType {
    'Bed' = 'bed',
    'Bathroom' = 'bathroom',
    'DownFloor' = 'downFloor',
    'UpFloor' = 'upFloor',
    'Measure' = 'measure',
    'Mts' = 'mts',
    'Door' = 'door',
    'Upgrade' = 'upgrade',
    'Garage' = 'garage',
    'ArrowBottomLeftOnSquare' = 'arrowBottomLeftOnSquare',
    'ArrowTopRightOnSquare' = 'arrowTopRightOnSquare',
    'Compass' = 'compass',
    'Hourglass' = 'hourglass',
    'Price' = 'price',
    'Tree' = 'tree',
    'Toilet' = 'toilet'
}

export const topFunnelScales = {
    7: {
        pending: {
            high: 93,
            medium: 81
        },
        searching: {
            high: 9,
            medium: 5
        },
        visiting: {
            high: 1,
            medium: 1
        },
        offer_done: {
            high: 1,
            medium: 1
        },
        closing: {
            high: 1,
            medium: 1
        }
    },
    15: {
        pending: {
            high: 93,
            medium: 81
        },
        searching: {
            high: 15,
            medium: 11
        },
        visiting: {
            high: 1,
            medium: 1
        },
        offer_done: {
            high: 1,
            medium: 1
        },
        closing: {
            high: 1,
            medium: 1
        }
    },
    30: {
        pending: {
            high: 93,
            medium: 81
        },
        searching: {
            high: 20,
            medium: 16
        },
        visiting: {
            high: 3,
            medium: 1
        },
        offer_done: {
            high: 3,
            medium: 1
        },
        closing: {
            high: 3,
            medium: 1
        }
    },
    90: {
        pending: {
            high: 93,
            medium: 81
        },
        searching: {
            high: 21,
            medium: 17
        },
        visiting: {
            high: 4,
            medium: 3
        },
        offer_done: {
            high: 3,
            medium: 2
        },
        closing: {
            high: 3,
            medium: 2
        }
    }
};

export const DEFAULT_CURRENCY: Currency = (process.env.NEXT_PUBLIC_PRIMARY_CURRENCY as Currency) || 'MXN';

export const OPERATION_EDIT_STATUS_TO_DISABLED: Array<IOperation['status']['last']> = ['cancelled', 'closed', 'paying'];

export const METRICS_DESCRIPTION = {
    suspension: {
        title: 'Pausa de consultas',
        description:
            'Un usuario puede ser pausado temporalmente debido a mala calidad de servicio. Procura que mejore sus métricas cuanto antes.'
    },
    warning: {
        title: 'Advertencia',
        description:
            'Un usuario puede ser pausado temporalmente debido a mala calidad de servicio. Procura que mejore sus métricas cuanto antes.'
    },
    whatsapp: {
        title: 'WhatsApp vinculado',
        description:
            'Es vital que tu equipo mantenga su WhatsApp conectado a nuestra plataforma para su óptimo rendimiento.'
    },
    responsePercentage: {
        title: 'Tasa de respuesta',
        description:
            'Responder todas las consultas es crucial, ya que no hacerlo resulta en pérdidas de inversión en marketing y oportunidades de negocio desaprovechadas.'
    },
    [MetricType.UnansweredLeads]: {
        title: 'Consultas sin responder',
        description:
            'Las consultas sin responder son oportunidades perdidas. Por eso, es importante responder a todas las consultas que recibimos.'
    },
    [MetricType.FirstResponseTime]: {
        title: 'Tiempo de primera respuesta',
        titleResume: 'T. de 1° respuesta',
        description: 'Responder con prontitud nos permite aprovechar oportunidades y superar a otras inmobiliarias.'
    },
    [MetricType.AvgResponseTime]: {
        title: 'Tiempo de respuesta promedio',
        titleResume: 'T. de respuesta medio',
        description: 'En nuestro análisis, examinamos el tiempo que tardan en responder a las consultas que reciben.'
    },
    [MetricType.Interactions]: {
        title: 'Calidad de seguimiento',
        description:
            'Cumplir con la ley de los 5 toques en el proceso de contacto y coordinación de visitas nos permite realizar un seguimiento efectivo y destacarnos de la competencia.',
        tooltip: ({ from, to }: { from: string; to: string }) =>
            `La muestra tomada en esta semana son las búsquedas creadas entre el ${from} al ${to}`
    },
    [MetricType.VisitRate]: {
        title: 'Tasa de visita',
        description:
            'Las visitas son fundamentales para generar ofertas. Por eso, es imprescindible registrarlas en nuestra plataforma para evaluar el porcentaje de conversión de consultas a visitas.'
    },
    [MetricType.TasksPending]: {
        title: 'Tareas pendientes',
        description:
            'Las tareas se generan gracias a nuestro asistente automático (Pulppo). Nos indicará gestiones a realizar para potenciar el negocio.'
    },
    [MetricType.TasksPendingClient]: {
        title: 'Tareas pendientes de clientes',
        description:
            'Las tareas se generan gracias a nuestro asistente automático (Pulppo). Nos indicará gestiones a realizar para potenciar el negocio.'
    },
    [MetricType.TasksPendingProperty]: {
        title: 'Tareas pendientes de propiedades',
        description:
            'Las tareas se generan gracias a nuestro asistente automático (Pulppo). Nos indicará gestiones a realizar para potenciar el negocio.'
    },
    [MetricType.PropertiesSale]: {
        title: 'Inventario en venta',
        description:
            'La proporción de propiedades en renta en el inventario, no debe superar el 20%, ya que la renta genera mucho trabajo y poca ganancia.'
    },
    [MetricType.Properties]: {
        title: 'Inventario por asesor',
        titleResume: 'Inventario',
        description:
            'Tener suficiente inventario por asesor, nos brinda suficiente trabajo. Debemos tener 20 propiedades por cada asesor como mínimo.'
    },
    brokersCertificate: {
        title: 'Asesores certificados',
        description: 'Realizar los cursos de nuestra academia, ayudan a desempeñar correctamente las tareas del Asesor.'
    },
    [MetricType.OfferRate]: {
        title: 'Tasa de ofertas',
        description:
            'Las ofertas son el paso previo a la venta. Por eso, es importante registrarlas en nuestra plataforma para evaluar el porcentaje de conversión de visitas a ofertas.'
    },
    [MetricType.AvgSuggestedProperties]: {
        title: 'Propiedades sugeridas',
        description:
            'Las propiedades sugeridas son una herramienta para incrementar la oferta de propiedades a los clientes.'
    }
};

export const LENGTH_ID_DOCUSIGN = 36;

export const TOOLTIP_TIMEOUT = 5000;
export const TOOLTIP_DELAY = 1000;

export enum PropertyForm {
    Address = 'address',
    Characteristics = 'characteristics',
    Promotion = 'promotion',
    InternalInformation = 'internal_information',
    Gallery = 'gallery',
    Price = 'price'
}

export const pendingKeysPropertyCapture = ({
    type,
    propertyForm
}: {
    type?: IProperty['type'];
    propertyForm: PropertyForm;
}) => {
    let characteristicsObligatory = [];
    if (propertyForm === PropertyForm.Characteristics) {
        characteristicsObligatory = Object.entries(AttributeMapper).filter(([_, value]) => {
            return Object.entries(value).some(([k, v]) => {
                return k === type && v === 'required';
            });
        });
    }
    const pendingKeys = {
        [PropertyForm.Address]: ['address'],
        [PropertyForm.Characteristics]: [
            'type',
            ...characteristicsObligatory.map(([key]) => `attributes.${key}`)
        ].filter(Boolean),
        [PropertyForm.Price]: ['listing.operation', 'listing.price.price'],
        [PropertyForm.Promotion]: ['listing.title', 'listing.description'],
        [PropertyForm.InternalInformation]: ['legal'],
        [PropertyForm.Gallery]: ['pictures.length']
    };

    return pendingKeys[propertyForm];
};

export const getAllPendingKeysPropertyCapture = (
    type: IProperty['type']
): {
    [key in PropertyForm]: Array<string>;
} => {
    return Object.values(PropertyForm).reduce((acc, form) => {
        return {
            ...acc,
            [form]: pendingKeysPropertyCapture({ type, propertyForm: form })
        };
    }, {}) as any;
};
