import { CheckOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Form, Input, List, Modal } from 'antd';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { PulppoDrawer } from './PulppoDrawer';
import useSWR from 'swr';
import { api_url, fetcher } from '../../helpers/fetcher';
import { ICompany, ISimpleCompany } from '@pulppo/pulppo-models';
import { useDebounceValue } from '../../hooks/debounceValue';
import { PhoneInput } from '../CreateContact/Phones';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { AddressComponentSelect } from '../PropertyDetail/PropertyLocation';
import axios from '../../utils/axios';
import Button from './Button';
import { IResponse } from '../../types';
import useSWRInfinite from 'swr/infinite';
import useOnScreen from '../../hooks/useOnScreen';
import { useTranslate } from '@tolgee/react';

const COMPANY_LIMIT = 20;

export const CompanySelect = ({
    onChange,
    value,
    disabled,
    status,
    external = false,
    render = null,
    multi = false,
    allowAdd = true,
    allowIndependent = false,
    onApply = (_) => {},
    onClear = () => {}
}: {
    onChange?: (ICompany: ICompany) => void;
    onApply?: (value: string[]) => void;
    onClear?: () => void;
    value?: ISimpleCompany | string | string[];
    disabled?: boolean;
    status?: string;
    external?: boolean;
    render?: (value) => ReactElement;
    multi?: boolean;
    allowAdd?: boolean;
    allowIndependent?: boolean;
}) => {
    const [form] = useForm();
    const state = useWatch('state', form);
    const stateId = state?.id;
    const [showOptions, setShowOptions] = useState(false);
    const [newCompany, setNewCompany] = useState<ICompany>(null);
    const [selected, setSelected] = useState<Array<string>>(Array.isArray(value) ? value : []);
    const [isPossibleIndependent, setIsPossibleIndependent] = useState<'possible' | 'yes' | 'no'>('possible');
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounceValue(search, 300);
    const lastCompany = useRef(null);
    const loadMore = useOnScreen(lastCompany);
    const { t } = useTranslate('common');
    const {
        data: companiesResponse,
        setSize,
        isValidating
    } = useSWRInfinite((index) => {
        return `${api_url}/company/search?search=${debouncedSearch}&external=${external || ''}&limit=${COMPANY_LIMIT}&offset=${
            index * COMPANY_LIMIT
        }`;
    }, fetcher);
    const { data: dataIndependentCompany, isLoading: isLoadingIndependentCompany } = useSWR<
        IResponse<{
            company: ICompany;
        }>
    >(`${api_url}/company/independent`, fetcher, {
        revalidateOnFocus: false,
        refreshInterval: 0
    });
    const { data: cities } = useSWR(stateId && `${api_url}/location?type=city&state=${stateId}`, fetcher);
    const { data: states } = useSWR(newCompany && `${api_url}/location?type=state`, fetcher);
    const companies = companiesResponse?.flat() || [];
    const independentCompany = dataIndependentCompany?.data?.company;

    const detectIsPossibleIndependent = (value: string) => {
        if (value?.length >= 3 && value.includes('ind') && isPossibleIndependent === 'possible') {
            Modal.confirm({
                title: '¿Es un asesor independiente?',
                content: 'Detectamos que posiblemente estás buscando seleccionar "asesor independiente", ¿es correcto?',
                onOk: () => {
                    setIsPossibleIndependent('yes');
                },
                onCancel: () => {
                    setIsPossibleIndependent('no');
                }
            });
        }
    };

    useEffect(() => {
        if (loadMore && !isValidating) {
            setSize((size) => size + 1);
        }
    }, [loadMore]);

    useEffect(() => {
        if (allowIndependent && search?.length >= 3 && search.includes('ind') && isPossibleIndependent === 'possible') {
            Modal.confirm({
                title: '¿Es un asesor independiente?',
                content: 'Detectamos que posiblemente estás buscando seleccionar "asesor independiente", ¿es correcto?',
                onOk: () => {
                    setIsPossibleIndependent('yes');
                },
                onCancel: () => {
                    setIsPossibleIndependent('no');
                }
            });
        }
    }, [search, allowIndependent]);

    useEffect(() => {
        if (!showOptions) {
            setSearch('');
            setIsPossibleIndependent('possible');
        }
    }, [showOptions]);

    useEffect(() => {
        if (newCompany) {
            setIsPossibleIndependent('possible');
        }
    }, [newCompany]);

    useEffect(() => {
        if (Array.isArray(value)) setSelected(value);
    }, [value]);

    useEffect(() => {
        if (isPossibleIndependent === 'yes' && independentCompany) {
            setIndependentCompany();
        }
    }, [isPossibleIndependent, independentCompany]);

    const setIndependentCompany = () => {
        onChange?.(independentCompany);
        setShowOptions(false);
        setNewCompany(null);
    };

    value = typeof value === 'string' ? companies?.find((c) => c._id === value) : value;

    return (
        <>
            <PulppoDrawer visible={showOptions} title="Inmobiliarias" onClose={() => setShowOptions(false)}>
                <div className="flex h-full flex-col gap-2">
                    <Input
                        className="h-11"
                        suffix={<SearchOutlined className="text-gray-300" />}
                        value={search}
                        allowClear={true}
                        placeholder="Buscar"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <div className="flex-1 overflow-y-auto">
                        <List loading={isValidating || isLoadingIndependentCompany} split={false}>
                            {companies?.map((option, index) =>
                                multi ? (
                                    <button
                                        type="button"
                                        key={option?._id?.toString()}
                                        className="flex w-full cursor-pointer items-center justify-between border-b border-solid border-gray-100 py-4 transition-all hover:underline"
                                        onClick={() => {
                                            const aux = [...selected];
                                            const index = aux.indexOf(option._id?.toString());
                                            if (index === -1) setSelected([...selected, option._id?.toString()]);
                                            else {
                                                aux.splice(index, 1);
                                                setSelected(aux);
                                            }
                                        }}
                                    >
                                        <div className="flex items-center gap-2">
                                            <div
                                                className={`flex h-5 w-5 items-center justify-center rounded-sm border-2 border-solid bg-white transition-all ${
                                                    selected?.includes?.(option._id?.toString())
                                                        ? 'border-black'
                                                        : 'border-gray-200'
                                                } `}
                                            >
                                                {selected?.includes?.(option._id?.toString()) && (
                                                    <CheckOutlined className="p-1 text-xs font-bold text-black" />
                                                )}
                                            </div>
                                            <p className="capitalize">{option.name?.toLowerCase()}</p>
                                        </div>
                                        <img
                                            style={{
                                                height: '40px',
                                                width: '60px',
                                                objectFit: 'contain'
                                            }}
                                            alt={option?.name}
                                            src={option?.logo?.default}
                                        />
                                    </button>
                                ) : (
                                    <List.Item
                                        onClick={async () => {
                                            onChange?.(option);
                                            setShowOptions(false);
                                        }}
                                        className="cursor-pointer items-start p-2 text-lg transition-all hover:bg-gray-100"
                                        key={index}
                                        extra={null}
                                    >
                                        <List.Item.Meta
                                            avatar={
                                                <img
                                                    className="h-10 w-10 rounded-full object-contain shadow-md"
                                                    src={option.logo?.default || '/pulppo.png'}
                                                    alt={option.name}
                                                />
                                            }
                                            title={
                                                <div className="flex items-center justify-between font-medium">
                                                    {option.name}{' '}
                                                    {option?.external && (
                                                        <div className="ml-2 rounded-md bg-black px-2 py-1 text-xs text-white">
                                                            EXTERNA
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            description={option.email}
                                        />
                                    </List.Item>
                                )
                            )}
                            <div className="h-10" ref={lastCompany} />
                        </List>
                    </div>
                    {allowAdd ? (
                        <>
                            <Button
                                onClick={() => {
                                    setNewCompany({ external: true } as any);
                                }}
                                type="primary"
                                className="h-auto py-2"
                            >
                                Agregar
                            </Button>
                            {allowIndependent ? (
                                <Button type="secondary" onClick={setIndependentCompany}>
                                    Es asesor independiente
                                </Button>
                            ) : null}
                        </>
                    ) : (
                        <div className="flex shrink-0 gap-2 bg-white px-4 pb-4 pt-2">
                            <Button
                                type="secondary"
                                onClick={() => {
                                    onClear();
                                    setShowOptions(false);
                                }}
                                className="h-10 flex-1 rounded-md font-medium"
                            >
                                Borrar filtros
                            </Button>
                            <Button
                                onClick={() => {
                                    onApply(selected);
                                    setShowOptions(false);
                                }}
                                type="primary"
                                className="h-10 flex-1 rounded-md font-medium"
                            >
                                Filtrar
                            </Button>
                        </div>
                    )}
                </div>
            </PulppoDrawer>
            <button type="button" className="w-full cursor-pointer" onClick={() => setShowOptions(true)}>
                {render ? (
                    render(value)
                ) : (
                    <Input
                        status={status as any}
                        value={Array.isArray(value) ? value[0] : typeof value === 'string' ? value : value?.name}
                        disabled={disabled}
                        suffix={<EditOutlined className="text-gray-300" />}
                        className="w-full py-2"
                        placeholder="Seleccionar..."
                    />
                )}
            </button>

            {allowAdd ? (
                <PulppoDrawer
                    title="Agregar nueva inmobiliaria"
                    visible={!!newCompany}
                    onClose={() => {
                        setNewCompany(null);
                        form.resetFields();
                    }}
                >
                    <Form
                        initialValues={newCompany}
                        onFinish={async (values) => {
                            values.externalSite = `https://${values.externalSite}`;
                            values.external = true;
                            values.status = 'active';
                            values.phone = typeof values.phone === 'string' ? values.phone : values?.phone?.phone || '';
                            const company = await axios.post(`${api_url}/company`, values).then((r) => r.data);
                            setNewCompany(null);
                            form.resetFields();
                            onChange(company);
                            setShowOptions(false);
                        }}
                        onChange={
                            allowIndependent
                                ? (formEvent) => {
                                      if ((formEvent.target as any)?.id === 'name') {
                                          if (typeof (formEvent.target as any).value === 'string') {
                                              detectIsPossibleIndependent((formEvent.target as any).value);
                                          }
                                      }
                                  }
                                : undefined
                        }
                        form={form}
                        layout="vertical"
                    >
                        <Form.Item
                            rules={[{ required: true, message: 'Campo obligatorio' }]}
                            name="name"
                            label="Nombre de la inmobiliaria"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            rules={[{ required: true, message: 'Campo obligatorio' }]}
                            name="externalSite"
                            label="Sitio web"
                        >
                            <Input prefix="https://" />
                        </Form.Item>
                        <Form.Item
                            rules={[
                                {
                                    validator: (rule, value) =>
                                        !value?.phone || value?.phone?.replace(/\D/gm, '')?.length < 12
                                            ? Promise.reject()
                                            : Promise.resolve(new Error('Largo mínimo de 10 caracteres')),
                                    message: 'Largo mínimo de 10 caracteres'
                                }
                            ]}
                            name="phone"
                            label="Teléfono"
                        >
                            <PhoneInput />
                        </Form.Item>
                        <div className="flex w-full items-center gap-2">
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obligatorio'
                                    }
                                ]}
                                className="flex-1"
                                name="state"
                                label={t('state', 'Estado')}
                            >
                                <AddressComponentSelect options={states}></AddressComponentSelect>
                            </Form.Item>
                            <Form.Item
                                className="flex-1"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Campo obligatorio'
                                    }
                                ]}
                                name={'city'}
                                label={t('district', 'Delegación')}
                            >
                                <AddressComponentSelect options={cities}></AddressComponentSelect>
                            </Form.Item>
                        </div>
                        <Button htmlType="submit" type="primary" className="mt-6 h-auto w-full py-2">
                            Agregar
                        </Button>
                    </Form>
                </PulppoDrawer>
            ) : null}
        </>
    );
};
