import { CloseOutlined } from '@ant-design/icons';
import { cloneElement, ReactElement, useState } from 'react';
import useIsMobile from '../hooks/isMobile';
import { twMerge } from 'tailwind-merge';

const videoMapper = {
    tasks: '833463996',
    operations: '833465271',
    searches: '833465899',
    whatsapp: '833465692',
    pulppoPlus: '833462957',
    legal: '843285832',
    referral: '922513869',
    services: '969273347'
};
interface Props {
    video: keyof typeof videoMapper;
    children?: ReactElement;
    className?: {
        button?: {
            text?: string;
        };
    };
}

const HelpVideo = ({ video, className, children }: Props) => {
    const [showVideo, setShowVideo] = useState(false);
    const isMobile = useIsMobile();

    return (
        <>
            {children ? (
                cloneElement(children, {
                    onClick: () => {
                        setShowVideo(true);
                    }
                })
            ) : (
                <button
                    type="button"
                    className="flex cursor-pointer items-center px-1 underline"
                    onClick={() => {
                        setShowVideo(true);
                    }}
                >
                    <p className={twMerge('m-0 text-sm', className?.button?.text)}>{`(Conoce más)`}</p>
                </button>
            )}
            {showVideo && (
                <div className="fixed left-0 top-0 h-full w-full p-4" style={{ zIndex: 99999999999999 }}>
                    <div
                        onClick={() => {
                            setShowVideo(false);
                        }}
                        className="absolute left-0 top-0 h-full w-full bg-black opacity-50"
                    ></div>

                    <div className="flex h-full w-full items-center justify-center">
                        <div className="relative">
                            <div
                                className="absolute right-2 top-2 z-50 flex h-10 w-10 cursor-pointer items-center justify-center rounded-md bg-black"
                                onClick={() => {
                                    setShowVideo(false);
                                }}
                            >
                                <CloseOutlined className="text-2xl text-white" />
                            </div>
                            <iframe
                                src={`https://player.vimeo.com/video/${videoMapper[video]}?autoplay=1`}
                                className="relative rounded-md object-contain"
                                width={isMobile ? '355' : '1066'}
                                height={isMobile ? '200' : '600'}
                                allow="autoplay; fullscreen"
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default HelpVideo;
