import { ICourseAgent, IResponse } from '../types';
import { ICourse } from '@pulppo/pulppo-models';
import axios from '../utils/axios';

interface Filters {
    isActive?: boolean;
    classesActive?: boolean;
}

interface Ids {
    idAgent: string;
    idCourse: string;
    idClass: string;
    idForm: string;
}

class CourseService {
    static getOne = async (id: string, filters: Filters, headers?: any): Promise<IResponse<ICourse>> => {
        try {
            const response = await axios.get(
                `${process.env.NEXT_PUBLIC_API_URL}/course/${id}?${
                    typeof filters.isActive !== 'undefined' ? `isActive=${filters.isActive}&` : ''
                }${typeof filters.classesActive !== 'undefined' ? `classesActive=${filters.classesActive}` : ''}`,
                { headers }
            );
            return response.data;
        } catch (e) {
            console.error(e);
            return {
                data: undefined,
                success: false,
                message: e.message
            };
        }
    };

    static getAgentCourses = async (agentUid?: string, headers?: any): Promise<Array<ICourseAgent>> => {
        try {
            const response = await axios.get(
                `${process.env.NEXT_PUBLIC_API_URL}/agent${agentUid ? `/${agentUid}` : ''}/courses/`,
                { headers }
            );
            return response.data.data;
        } catch (e) {
            console.error(e);
        }
    };

    static getAll = async (filters?: Filters, headers?: any): Promise<IResponse<Array<ICourse>>> => {
        const response = await axios.get(
            `${process.env.NEXT_PUBLIC_API_URL}/course?${
                typeof filters.isActive !== 'undefined' ? `isActive=${filters.isActive}&` : ''
            }${typeof filters.classesActive !== 'undefined' ? `classesActive=${filters.classesActive}` : ''}`,
            { headers }
        );
        return response.data;
    };

    static setAgentCourseOngoing = async ({ idAgent, idCourse, idClass }: Omit<Ids, 'idForm'>) => {
        const response = await axios.patch(
            `${process.env.NEXT_PUBLIC_API_URL}/agent/${idAgent}/course/${idCourse}/class/${idClass}/ongoing`
        );
        return response.data;
    };

    static setAgentFormCompleted = async ({ idAgent, idForm, idCourse }: Omit<Ids, 'idClass'>) => {
        const response = await axios.patch(
            `${process.env.NEXT_PUBLIC_API_URL}/agent/${idAgent}/course/${idCourse}/form/${idForm}`
        );
        return response.data;
    };

    static setAgentCourseCompleted = async ({ idAgent, idCourse }: Omit<Ids, 'idClass' | 'idForm'>) => {
        const response = await axios.patch(
            `${process.env.NEXT_PUBLIC_API_URL}/agent/${idAgent}/course/${idCourse}/complete`
        );
        return response.data;
    };
}

export default CourseService;
