import { DownOutlined } from '@ant-design/icons';
import { Col, Input, InputRef, List, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useDebounce } from '../../hooks/debounce';
import { PulppoDrawer } from './PulppoDrawer';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { PulppoLoader } from './PulppoLoader';
import { twMerge } from 'tailwind-merge';

interface Props {
    onChange?;
    value?: any;
    onSelect?: (value: any) => void;
    onButtonClick?: Function;
    className?: {
        container?: string;
        row?: string;
    };
    focus?: boolean;
    isDisabled?: boolean;
}

export const AddressDrawer = ({
    value = [],
    onButtonClick = () => {},
    onChange = () => {},
    onSelect = () => {},
    className,
    focus,
    isDisabled
}: Props) => {
    const [showDrawer, setShowDrawer] = useState(false);

    return (
        <>
            <button
                className={twMerge('w-full disabled:cursor-not-allowed disabled:opacity-50', className?.container)}
                onClick={() => {
                    setShowDrawer(true);
                    onButtonClick();
                }}
                disabled={isDisabled}
                type="button"
            >
                <Row
                    className={twMerge(
                        'border border-solid border-pulppo-secondary-gray-disabled bg-white p-2',
                        className?.row
                    )}
                    justify="space-between"
                    align="middle"
                >
                    <Col className="align-center flex items-center">
                        {value?.length === 0 ? (
                            <p className="text-sm text-pulppo-primary-gray">Buscar una dirección</p>
                        ) : (
                            <p className="text-sm">{value}</p>
                        )}
                    </Col>
                    <Col span={2} className="flex items-center justify-end">
                        <DownOutlined className="ml-2 text-lg text-pulppo-secondary-gray-disabled" />
                    </Col>
                </Row>
            </button>
            <AddressInputDrawer
                isDisabled={isDisabled}
                show={showDrawer}
                onClose={() => setShowDrawer(false)}
                focus={focus}
                onChange={onChange}
                onSelect={onSelect}
            />
        </>
    );
};

export const AddressInputDrawer = ({
    show,
    onClose,
    focus,
    onChange,
    onSelect,
    isDisabled
}: {
    show: boolean;
    onClose: () => void;
    focus?: boolean;
    onChange?: (value: string) => void;
    onSelect?: (value: any) => void | Promise<void>;
    isDisabled?: boolean;
}) => {
    const inputRef = useRef<InputRef>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
        apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || 'AIzaSyC7dfIWPQRDqAyS7Py3Zu2lKM-BBDHeIF4',
        language: 'es',
        //@ts-ignore
        options: {
            types: ['geocode', 'establishment'],
            componentRestrictions: { country: process.env.NEXT_PUBLIC_COUNTRY_CODE || 'mx' }
        }
    });

    useDebounce(
        () => {
            if (searchTerm) {
                setLoading(true);
                getPlacePredictions({
                    input: searchTerm
                });
                setLoading(false);
            }
        },
        250,
        [searchTerm]
    );

    useEffect(() => {
        if (show && focus && inputRef.current) {
            inputRef.current!.focus();
        }
    }, [focus, inputRef.current, show]);

    return (
        <PulppoDrawer bodyStyle={{ paddingTop: '0px' }} title="Ubicación" visible={show} onClose={onClose}>
            <div className="sticky top-0 z-20 bg-white pt-5">
                <Input
                    ref={inputRef}
                    placeholder="Buscar una dirección"
                    className="mb-2 py-2 [&>input]:font-normal"
                    value={searchTerm}
                    allowClear={true}
                    onChange={(e) => setSearchTerm(e.currentTarget.value)}
                    disabled={isDisabled}
                />
            </div>
            <div>
                <PulppoLoader loading={loading}>
                    <List
                        locale={{
                            emptyText: ' '
                        }}
                        dataSource={[
                            ...placePredictions,
                            ...(searchTerm
                                ? [
                                      {
                                          place_id: 'custom',
                                          description:
                                              'Crear nueva ubicación, Si no existe en el buscador elija esta opción'
                                      }
                                  ]
                                : [])
                        ]}
                        renderItem={(item) => (
                            <List.Item
                                className="mb-6 cursor-pointer border-secondary-gray-disabled py-2 hover:underline"
                                key={item.place_id}
                                onClick={async () => {
                                    if (item.place_id == 'custom') {
                                        await onSelect?.({
                                            address_components: [
                                                {
                                                    short_name: '',
                                                    types: []
                                                },
                                                {
                                                    short_name: searchTerm,
                                                    types: []
                                                }
                                            ]
                                        });
                                        onChange?.(searchTerm);
                                        onClose();
                                        return;
                                    }
                                    placesService?.getDetails(
                                        {
                                            placeId: item.place_id
                                        },
                                        async (place) => {
                                            setSearchTerm(item.description || place?.formatted_address);
                                            await onSelect?.({
                                                ...place,
                                                street: item.description.split(',')[0]
                                            });
                                            onChange?.((item.description || place?.formatted_address)?.split(',')[0]);
                                            onClose();
                                        }
                                    );
                                }}
                            >
                                <List.Item.Meta
                                    className="[&>div>h4]:mb-0"
                                    title={
                                        <span className="font-semibold text-primary-black">
                                            {item.description.split(',')[0]}
                                        </span>
                                    }
                                    description={
                                        <p className="truncate text-primary-gray">
                                            {item.description.split(',').slice(1).join(', ')}
                                        </p>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </PulppoLoader>
            </div>
        </PulppoDrawer>
    );
};
