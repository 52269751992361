import Image from "next/image";
import { Tag } from 'antd';
import PropertyDetail from "../PropertyDetail/PropertyDetail";
import { useState } from "react";
import { ISimpleProperty } from "@pulppo/pulppo-models";

interface PropertyPriceDropProps {
    address?: string;
    price?: string;
    currency?: string;
    state?: string;
    neighborhood?: string;
    oldPrice?: string;
    image?: string;
    property?: ISimpleProperty;
}

export const PropertyPriceDrop: React.FC<PropertyPriceDropProps> = ({
  address,
  neighborhood,
  state,
  price,
  oldPrice,
  currency,
  image,
  property: simpleProperty
}) => {

  const [showPropertyModal, setShowPropertyModal] = useState<boolean>(false);

  return (
    <div className="w-full max-w-3xl border-b border-gray-300 pb-3">
      <div className="px-4 pt-2">
        <div className="flex justify-center items-end"> 
          <Tag color="warning" className="mb-4 text-lg">Baja de precio</Tag>
        </div>

        <div className="flex gap-4 items-start border border-solid border-gray-300 rounded-md p-2 cursor-pointer" onClick={() => {
          setShowPropertyModal(true);
        }}>
          <div className="relative w-16 h-16 flex-shrink-0 rounded-md overflow-hidden">
            <Image
              src={image || "/placeholder.svg"}
              alt={address}
              layout="fill"
              objectFit="cover"
            />
          </div>

          <div className="flex-1">
              <div className="text-sm font-medium text-black">{address}</div>
            <div className="text-xs text-gray-600">
              {state ? `${state}, ` : ""}
              {neighborhood ? `${neighborhood}` : ""}
            </div>
          </div>

          <div className="text-right flex flex-col items-end gap-0">
            <div className="text-md font-semibold text-black">{currency} {price}</div>
            {oldPrice && (
                <div className="flex items-center text-sm text-gray-500">
                    <span className="mr-1">antes</span>
                    <span className="line-through">{currency} {oldPrice}</span>
                </div>
            )}
          </div>
        </div>
      </div>
      { simpleProperty && <PropertyDetail property={simpleProperty} visible={showPropertyModal} onClose={() => setShowPropertyModal(false)} />}
    </div>
  );
};

