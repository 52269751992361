import Typesense from 'typesense';

const client = new Typesense.SearchClient({
    nodes: [
        {
            host: process.env.NEXT_PUBLIC_TYPESENSE_URI, // For Typesense Cloud use xxx.a1.typesense.net
            port: 443, // For Typesense Cloud use 443
            protocol: 'https' // For Typesense Cloud use https
        }
    ],
    apiKey: process.env.NEXT_PUBLIC_TYPESENSE_API_KEY,
    connectionTimeoutSeconds: 2
});

export const searchLocation = (query: string) => {
    return client.collections('locations').documents().search(
        {
            q: query,
            query_by: 'fullName',
            sort_by: 'weight:desc'
        },
        {}
    );
};

export const searchProperty = (query: string) => {
    return client.collections('properties').documents().search(
        {
            q: query,
            query_by: '_id,referenceCode,internalId,development.name,address.street,listing.title,listing.description',
            filter_by: 'status.last:=[published]'
        },
        {}
    );
};

export const searchContact = async ({
    query,
    filter,
    options,
    sort
}: {
    query: string;
    filter?: {
        userUid?: string;
        companyId?: string;
    };
    sort?: {
        createdAt?: 'asc' | 'desc';
    };
    options?: {
        limit?: number;
        offset?: number;
    };
}) => {
    try {
        const searchParameters = {};
        if (filter) {
            searchParameters['filter_by'] = '';
            if (filter?.companyId) {
                searchParameters['filter_by'] += `company._id:=${filter.companyId}`;
            } else if (filter?.userUid) {
                searchParameters['filter_by'] += `agentUid:=${filter.userUid}`;
            }
        }
        if (sort?.createdAt) {
            searchParameters['sort_by'] = `createdAt:${sort.createdAt}`;
        }
        if (options?.limit) {
            searchParameters['limit'] = options.limit;
        }
        if (options?.offset) {
            searchParameters['offset'] = options.offset;
        }

        return client
            .collections<IContactTypeSense>('contacts')
            .documents()
            .search(
                {
                    q: query,
                    query_by: 'firstName,lastName,emails.email,phones.plain',
                    ...searchParameters
                },
                {}
            )
            .then((res) => ({
                ...res,
                hits: res.hits.map((hit) => ({
                    ...hit,
                    document: {
                        ...hit.document,
                        _id: hit.document.contactId
                    }
                }))
            }));
    } catch (error) {
        console.log(error);
        return null;
    }
};

interface IContactTypeSense {
    _id?: string;
    agentUid: string;
    contactId: string;
    company: {
        _id: string;
        email: string;
        logo: {
            default: string;
            pulppo: string;
            pulppoInverted: string;
        };
        name: string;
        phone: string;
    };
    emails: Array<{
        _id: string;
        email: string;
        is_default: boolean;
        type: string;
    }>;
    firstName: string;
    id: string;
    lastName: string;
    phones: Array<{
        _id: string;
        callingCountryCode: string;
        countryCode: string;
        nationalFormat: string;
        phone: string;
        plain: string;
        valid: boolean;
        validationErrors: Array<any>;
        whatsapp: string;
    }>;
}
