import { CommentOutlined, DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { IContact } from '@pulppo/pulppo-models';
import { Avatar, Button, Tag } from 'antd';
import dayjs from 'dayjs';
import { uuidv4 } from '../../helpers/uuid';
import { showAgentData } from '../../utils/showAgentData';
import Icon, { IconType } from '../Icon/Icon';
import { NextImage } from './NextImage';
import useChat from '../../hooks/useChat';
import { useContext, useState } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { Comment } from './Comment';
import { api_url, instance } from '../../helpers/fetcher';
import { PulppoOptions } from './PulppoOptions';
import Markdown from 'react-markdown';
import { PostElements } from '../Post/PostElements';

export const PostMedia = ({ media }) => {
    return (
        <div key={media} className="mt-2">
            {media.type === 'video' ? (
                <video className="max-h-[300px] w-full object-contain" src={media?.videoUrl} controls></video>
            ) : (
                <NextImage
                    wrapperStyle={{ width: '100%' }}
                    alt=""
                    className="max-h-[600px] w-full object-contain"
                    src={media?.url}
                    width={720}
                    fit="outside"
                />
            )}
        </div>
    );
};

export const Post = ({ post, onPostsUpdate }) => {
    const [comments, setComments] = useState(post.comments || []);
    const setChatContact = useChat((store) => store.setChatContact);
    const { user } = useContext(UserContext);
    return (
        <div className="border border-solid border-gray-200 bg-white p-2">
            <div className="flex items-center justify-between px-2">
                <div
                    className="flex w-full cursor-pointer items-center justify-between gap-2"
                    onClick={() => {
                        showAgentData(post.user, (contact: IContact) => {
                            setChatContact(contact);
                        });
                    }}
                >
                    <div className="flex items-center gap-2">
                        <Avatar src={`${post.user?.profilePicture}?reso=128x128&fit=cover`} />
                        <div>
                            <p className="font-bold">
                            {post.user?.firstName} {post.user?.lastName}
                        </p>
                        <p className="text-sm text-pulppo-status-dark-disabled">
                            {dayjs(post.createdAt).tz().fromNow()}
                            </p>
                        </div>
                    </div>
                    <div className="flex items-start gap-2">
                        <Tag color={post.showTo === 'pulppo' ? 'blue' : 'green'}>{post.showTo === 'pulppo' ? 'Pulppo' : 'Inmobiliaria'}</Tag>
                    </div>
                </div>
                {(user?.features?.moderator || user?.uid === post?.user?.uid) && (
                    <PulppoOptions
                        options={[
                            {
                                label: <span className="text-red-500">Eliminar</span>,
                                value: 'delete',
                                description: 'Eliminar publicación',
                                icon: <DeleteOutlined className="text-red-500" />,
                                onClick: async () => {
                                    await instance.delete(`${api_url}/post/${post._id}`);
                                    onPostsUpdate((posts) => {
                                        return posts.filter((p) => p._id !== post._id);
                                    });
                                }
                            }
                        ]}
                    >
                        <Button type="text">
                            <MoreOutlined />
                        </Button>
                    </PulppoOptions>
                )}
            </div>
            <div className="prose mt-2 overflow-x-hidden px-2">
                <Markdown>{post.content}</Markdown>
                {post?.component && (
                    <PostElements name={post?.component?.name} props={post?.component?.props} />
                )}
            </div>
            {!post.component && post.media?.map((media) => <PostMedia media={media} key={media.url} />)}
            <div className="mx-2 my-3 h-px bg-gray-100"></div>
            <div className="mt-2 flex items-center gap-4 px-2">
                <div
                    className={`flex cursor-pointer select-none items-center gap-px text-sm	
                                            ${post?.userHasLiked ? 'text-blue-400' : 'text-pulppo-status-dark-disabled'}`}
                    onClick={async () => {
                        const hasLike = post.userHasLiked;
                        if (hasLike) {
                            onPostsUpdate((posts) => {
                                return posts.map((p) =>
                                    p._id === post._id
                                        ? {
                                              ...post,
                                              likeCount: post.likeCount - 1,
                                              userHasLiked: false
                                          }
                                        : p
                                );
                            });
                            await instance.delete(`${api_url}/post/${post._id}/like`);
                            return;
                        }

                        onPostsUpdate((posts) => {
                            return posts.map((p) =>
                                p._id === post._id
                                    ? {
                                          ...post,
                                          likeCount: post.likeCount + 1,
                                          userHasLiked: true
                                      }
                                    : p
                            );
                        });

                        await instance.post(`${api_url}/post/${post._id}/like`);
                    }}
                >
                    {post?.likeCount || 0}
                    <Icon
                        name={IconType.Clap}
                        className={`h-5 w-5 ${post?.userHasLiked ? 'fill-blue-400' : 'fill-gray-400'}`}
                    />
                </div>
                <div className="flex cursor-pointer items-center gap-px text-pulppo-status-dark-disabled ">
                    <span className="text-sm">{post?.commentCount || 0}</span>
                    <CommentOutlined className="h-5 w-5 text-pulppo-status-dark-disabled" />
                </div>
            </div>

            <div className="mt-2 flex w-full flex-col gap-2 p-2">
                {comments.map((comment) => (
                    <div key={comment._id}>
                        <div className="flex items-center justify-between">
                            <div
                                className="flex cursor-pointer items-center gap-2"
                                onClick={() => {
                                    showAgentData(comment.user, (contact: IContact) => {
                                        setChatContact(contact);
                                    });
                                }}
                            >
                                <Avatar size="small" src={`${comment.user?.profilePicture}?reso=128x128&fit=cover`} />
                                <div>
                                    <p className="text-sm font-bold">
                                        {comment.user?.firstName} {comment.user?.lastName}
                                    </p>
                                    <p className="text-xs text-pulppo-status-dark-disabled">
                                        {dayjs(comment.createdAt).tz().fromNow()}
                                    </p>
                                </div>
                            </div>
                            {(user?.features?.moderator || user?.uid === comment?.user?.uid) && (
                                <PulppoOptions
                                    options={[
                                        {
                                            label: <span className="text-red-500">Eliminar</span>,
                                            value: 'delete',
                                            description: 'Eliminar publicación',
                                            icon: <DeleteOutlined className="text-red-500" />,
                                            onClick: async () => {
                                                await instance.delete(
                                                    `${api_url}/post/${post._id}/comment/${comment._id}`
                                                );
                                                setComments((comments) => {
                                                    return comments.filter((c) => c._id !== comment._id);
                                                });
                                                onPostsUpdate((posts) => {
                                                    return posts.map((p) =>
                                                        p._id === post._id
                                                            ? {
                                                                  ...post,
                                                                  commentCount: (post?.commentCount || 0) - 1
                                                              }
                                                            : p
                                                    );
                                                });
                                            }
                                        }
                                    ]}
                                >
                                    <Button type="text">
                                        <MoreOutlined />
                                    </Button>
                                </PulppoOptions>
                            )}
                        </div>
                        <p className="mt-1 text-sm">{comment.content}</p>
                    </div>
                ))}
            </div>
            {post?.commentCount > comments?.length && (
                <div
                    className="mb-2 flex cursor-pointer items-center justify-center px-2 text-xs text-pulppo-status-dark-disabled"
                    onClick={async () => {
                        const newPost = await instance.get(`${api_url}/post/${post._id}`).then((r) => r.data);
                        setComments(newPost.comments || []);
                    }}
                >
                    Ver más comentarios
                </div>
            )}
            <div className="border-t border-solid border-gray-100 px-2">
                <Comment
                    onSend={async (comment) => {
                        comment._id = uuidv4();
                        const newComment = (await instance.post(`${api_url}/post/${post._id}/comment`, comment)).data;
                        setComments((comments) => {
                            return [...(comments || []), newComment];
                        });
                        onPostsUpdate((posts) => {
                            return posts.map((p) =>
                                p._id === post._id
                                    ? {
                                          ...post,
                                          commentCount: (post?.commentCount || 0) + 1
                                      }
                                    : p
                            );
                        });
                    }}
                />
            </div>
        </div>
    );
};
