import { createRoot } from 'react-dom/client';
import VisitDrawer, { VisitDrawerProps } from '../components/Elements/VisitDrawer';
import { ReactNode } from 'react';
import DrawerFormVisit, { IDrawerFormVisit } from '../components/Visit/FormVisit';
import DrawerOperation, { DrawerOperationProps } from '../components/Closes/DrawerOperation';
import CapturePropertyDrawer, { CapturePropertyDrawerProps } from '../components/Captures/CapturePropertyDrawer';
import { TolgeeNextProvider } from '../contexts/TolgeeProvider';

type IShowPortal =
    | {
          type: 'visit-drawer';
          config: Omit<VisitDrawerProps, 'show' | 'setShow'>;
      }
    | {
          type: 'form-visit';
          config: Omit<IDrawerFormVisit, 'show'>;
      }
    | {
          type: 'operation';
          config: Omit<DrawerOperationProps, 'show'>;
      }
    | {
          type: 'property-edit';
          config: Omit<CapturePropertyDrawerProps, 'show'>;
      };

export const ShowPortal = ({ type, config }: IShowPortal) => {
    const container = document.createDocumentFragment();
    let currentConfig = {
        ...config,
        close,
        show: true
    } as any;
    const root = createRoot(container);

    function destroy(...args: Array<any>) {
        root.unmount();
        args.some((param) => param && param.triggerCancel);
    }

    function render(props: any) {
        setTimeout(() => {
            let component: ReactNode;
            switch (type) {
                case 'visit-drawer':
                    component = <VisitDrawer {...props} />;
                    break;
                case 'form-visit':
                    component = <DrawerFormVisit {...props} />;
                    break;
                case 'operation':
                    component = <DrawerOperation {...props} />;
                    break;
                case 'property-edit':
                    component = <CapturePropertyDrawer {...props} />;
                    break;
            }
            root.render(<TolgeeNextProvider>{component}</TolgeeNextProvider>);
        });
    }

    function close(...args: Array<any>) {
        currentConfig = {
            ...currentConfig,
            show: false,
            afterClose: () => {
                destroy.apply(this, args);
            }
        };
        render(currentConfig);
    }

    function update(configUpdate: any) {
        if (typeof configUpdate === 'function') {
            currentConfig = configUpdate(currentConfig);
        } else {
            currentConfig = {
                ...currentConfig,
                ...configUpdate
            };
        }
        render(currentConfig);
    }

    render(currentConfig);

    return {
        destroy: close,
        update
    };
};
