import React from 'react';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

export const SortArrows = () => {
  
  return (
    <div className="flex flex-row leading-none text-xs">
      <ArrowUpOutlined style={{ lineHeight: '1' }} />
      <ArrowDownOutlined style={{ lineHeight: '1' }} />
    </div>
  );
};