import { DownOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { useEffect, useState } from 'react';
import { PulppoDrawer } from './PulppoDrawer';
import { useTranslate } from '@tolgee/react';
import { PriceInput } from '../PropertyDetail/PropertyListing';

interface IFilter {
    operation: string;
    price: {
        min: number;
        max: number;
        currency: string;
    };
}

export const OperationAndPriceSelect = ({
    value: initialValue = {
        operation: 'all',
        price: {
            min: null,
            max: null,
            currency: process.env.NEXT_PUBLIC_PRIMARY_CURRENCY
        }
    },
    apply = (value) => {},
    render = null
}) => {
    const [showDrawer, setShowDrawer] = useState(false);
    const [value, setValue] = useState<IFilter>({
        operation: 'all',
        price: {
            min: null,
            max: null,
            currency: process.env.NEXT_PUBLIC_PRIMARY_CURRENCY
        }
    });
    const { t } = useTranslate('common');
    const applyFilters = (filters: IFilter) => {
        const aux: IFilter = {
            operation: null,
            price: null
        };
        if (filters.operation && filters.operation !== 'all') aux.operation = filters.operation;
        if (+filters.price?.min && !isNaN(+filters.price?.min))
            aux.price = {
                currency: filters.price.currency,
                max: null,
                min: +filters.price.min
            };
        if (+filters.price?.max && !isNaN(+filters.price.max) && +filters.price.max !== Infinity) {
            aux.price = aux.price
                ? { ...aux.price, max: +filters.price.max }
                : {
                      currency: filters.price.currency,
                      min: null,
                      max: +filters.price.max
                  };
        }
        apply(aux);
    };
    useEffect(() => {
        if (showDrawer) {
            setValue(initialValue);
        }
    }, [showDrawer]);
    return (
        <>
            <div
                className="cursor-pointer"
                onClick={() => {
                    setShowDrawer(true);
                }}
            >
                {render ? (
                    render(value)
                ) : (
                    <Button className="flex h-10 items-center justify-center gap-2 rounded-none bg-white px-3 font-medium">
                        <p className="flex items-center">Operación y precio</p>
                        <DownOutlined className="flex items-center text-sm text-black" />
                    </Button>
                )}
            </div>

            <PulppoDrawer
                bodyStyle={{ padding: '21px' }}
                title="Operación y precio"
                visible={showDrawer}
                onClose={() => {
                    applyFilters(value);
                    setShowDrawer(false);
                }}
            >
                <div className="flex h-full w-full flex-col justify-between">
                    <div className="flex flex-1 flex-col gap-5">
                        <div>
                            <p className="mb-2 font-medium">Operación</p>
                            <div className="flex w-full">
                                <div
                                    className={`flex h-10 w-1/3 cursor-pointer items-center justify-center border border-solid border-gray-300 text-center ${
                                        value.operation === 'all'
                                            ? 'bg-black text-white'
                                            : 'bg-white text-pulppo-status-dark-disabled'
                                    }`}
                                    onClick={() => {
                                        setValue((value) => ({
                                            ...value,
                                            operation: 'all'
                                        }));
                                    }}
                                >
                                    Todas
                                </div>
                                <div
                                    className={`flex h-10 w-1/3 cursor-pointer items-center justify-center border-y border-solid border-gray-300 text-center ${
                                        value.operation === 'sale'
                                            ? 'bg-black text-white'
                                            : 'bg-white text-pulppo-status-dark-disabled'
                                    }`}
                                    onClick={() => {
                                        setValue((value) => ({
                                            ...value,
                                            operation: 'sale'
                                        }));
                                    }}
                                >
                                    {t('to_buy', 'Comprar')}
                                </div>{' '}
                                <div
                                    className={`flex h-10 w-1/3 cursor-pointer items-center justify-center border border-solid border-gray-300 text-center ${
                                        value.operation === 'rent'
                                            ? 'bg-black text-white'
                                            : 'bg-white text-pulppo-status-dark-disabled'
                                    }`}
                                    onClick={() => {
                                        setValue((value) => ({
                                            ...value,
                                            operation: 'rent'
                                        }));
                                    }}
                                >
                                    {t('to_rent', 'Rentar')}
                                </div>
                            </div>
                        </div>
                        <div>
                            <p className="mb-2 font-medium">Precio</p>
                            <div className="flex w-full items-center justify-center gap-2">
                                <PriceInput
                                    value={value.price.min}
                                    onChange={(price) =>
                                        setValue((value) => ({
                                            ...value,
                                            price: {
                                                ...value.price,
                                                min: price
                                            }
                                        }))
                                    }
                                    min={0}
                                    max={value.price.max || Infinity}
                                    // formatter={(value: any) => {
                                    //     const aux = parseInt(value);
                                    //     if (!aux) return '';
                                    //     return `${aux.toLocaleString(process.env.NEXT_PUBLIC_LANGUAGE_CODE || 'es-MX')}`;
                                    // }}
                                    inputMode="numeric"
                                    placeholder="min"
                                    className="h-10 w-2/5 border border-solid border-gray-300"
                                />
                                <PriceInput
                                    value={value.price.max}
                                    onChange={(price) =>
                                        setValue((value) => ({
                                            ...value,
                                            price: {
                                                ...value.price,
                                                max: price
                                            }
                                        }))
                                    }
                                    // formatter={(value: any) => {
                                    //     if (!value || isNaN(parseInt(value))) return '';
                                    //     return `${parseInt(value).toLocaleString(
                                    //         process.env.NEXT_PUBLIC_LANGUAGE_CODE || 'es-MX'
                                    //     )}`;
                                    // }}
                                    min={value.price.min || 0}
                                    inputMode="numeric"
                                    placeholder="max"
                                    className="h-10 w-2/5 border border-solid border-gray-300"
                                />
                                <Select
                                    className="h-10 flex-1 border border-solid border-gray-300 text-pulppo-status-dark-disabled"
                                    bordered={false}
                                    value={value.price.currency}
                                    onSelect={(currency) =>
                                        setValue((value) => ({
                                            ...value,
                                            price: {
                                                ...value.price,
                                                currency: currency
                                            }
                                        }))
                                    }
                                    options={process.env.NEXT_PUBLIC_CURRENCIES.split(',').map((currency) => ({
                                        label: currency,
                                        value: currency
                                    }))}
                                ></Select>
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full gap-5">
                        <Button
                            className="h-10 flex-1 font-medium"
                            type="default"
                            disabled={
                                value.operation === 'all' &&
                                !value.price.max &&
                                !value.price.min &&
                                value.price.currency === process.env.NEXT_PUBLIC_PRIMARY_CURRENCY
                            }
                            onClick={() => {
                                applyFilters({
                                    operation: null,
                                    price: null
                                });
                                setShowDrawer(false);
                            }}
                        >
                            Borrar filtros
                        </Button>
                        <Button
                            className="h-10 flex-1 font-medium"
                            type="primary"
                            onClick={() => {
                                applyFilters(value);
                                setShowDrawer(false);
                            }}
                        >
                            Filtrar
                        </Button>
                    </div>
                </div>
            </PulppoDrawer>
        </>
    );
};
