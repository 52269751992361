import { ISimpleUser } from '@pulppo/pulppo-models';
import Avatar from './Avatar';
import { showAgentData } from '../../utils/showAgentData';
import { twMerge } from 'tailwind-merge';
import { ReactNode } from 'react';
import useChat from '../../hooks/useChat';

export interface AvatarUserProps {
    user: ISimpleUser;
    isAgent?: boolean;
    description?: string | ReactNode;
    disabled?: boolean;
    titleClassName?: string;
    descriptionClassName?: string;
    containerClassName?: string;
    stopPropagation?: boolean;
    onClick?: () => void;
    onClickPicture?: () => void;
    style?: {
        disableTruncate?: boolean;
    };
    size?: 'small' | 'medium' | 'large';
    label?: {
        text: string;
        bgColor: string;
        textColor: string;
    };
    alias?: string;
}

const AvatarUser = ({
    user,
    isAgent,
    alias,
    description,
    disabled,
    style,
    stopPropagation,
    titleClassName,
    size,
    label,
    onClick,
    onClickPicture,
    descriptionClassName,
    containerClassName
}: AvatarUserProps) => {
    const setChatContact = useChat((store) => store.setChatContact);
    const fullName = `${user?.firstName || ''} ${user?.lastName || ''}`;

    return (
        <Avatar
            img={{
                src: user?.profilePicture,
                alt: fullName
            }}
            onClickPicture={onClickPicture}
            label={label || null}
            size={size || 'medium'}
            title={
                alias ? (
                    <span className={titleClassName}>
                        <span className="block truncate text-sm font-normal text-black">{alias}</span>
                        <span className="block truncate text-sm font-normal text-gray-500"> ({fullName})</span>
                    </span>
                ) : (
                    fullName
                )
            }
            description={
                description && typeof description === 'string' && ['email', 'phone', 'both'].includes(description) ? (
                    description !== 'both' ? (
                        user?.[description] || ''
                    ) : (
                        <span className={descriptionClassName}>
                            <span>{user?.email}</span>
                            <span className="block">{user?.phone}</span>
                        </span>
                    )
                ) : (
                    description
                )
            }
            stopPropagation={stopPropagation}
            onClick={
                !disabled
                    ? () => {
                          if (onClick) {
                              onClick();
                          } else if (isAgent) {
                              showAgentData(user, () => {});
                          } else {
                              setChatContact({
                                  ...user
                              });
                          }
                      }
                    : undefined
            }
            className={{
                container: containerClassName,
                title: twMerge('font-normal', style?.disableTruncate && 'whitespace-normal', titleClassName || ''),
                action: 'w-full',
                body: 'w-full',
                description: twMerge(style?.disableTruncate && 'whitespace-normal', descriptionClassName)
            }}
        />
    );
};

export default AvatarUser;
