import { Button, Input, Modal, Tooltip } from 'antd';
import { PropertyCard } from './PropertyCard';
import { CheckCircleOutlined, DeleteOutlined, FormOutlined, LoadingOutlined } from '@ant-design/icons';
import { useState } from 'react';
import dayjs from 'dayjs';
import { showProperty } from '../../utils/showProperty';
import axios from '../../utils/axios';
import { api_url } from '../../helpers/fetcher';
import ExclusiveTag from '../Property/ExclusiveTag';
import { isExclusive } from '../../utils/property';
import { LoadingButton } from './ButtonLoading';
import useUser from '../../hooks/useUser';
import { useTranslate } from '@tolgee/react';

const SearchPropertyCard = ({
    search,
    property,
    onRemove = () => {},
    onVisit = () => {},
    onRestore = () => {},
    onAdd = () => {},
    onShare = () => {}
}) => {
    const [note, setNote] = useState<{
        content: string;
        state: 'show' | 'hide' | 'loading';
    }>({
        content: property?.agentNote?.content || '',
        state: 'hide'
    });
    const { user } = useUser();
    const { t } = useTranslate('common');
    const saveNote = async () => {
        setNote((note) => ({ ...note, state: 'loading' }));
        if (note.content)
            await axios
                .patch(`${api_url}/search/${search._id}/properties/add-note`, {
                    propertyId: property._id,
                    note: note.content
                })
                .catch(() => {
                    Modal.error({
                        title: 'Error al guardar la nota',
                        content: 'Hubo un error al intentar guardar la nota. Pruebe nuevamente más tarde'
                    });
                });
        setNote((note) => ({ ...note, state: 'hide' }));
    };

    const getFullNameBadge = () => {
        if (property?.added?.source?.firstName || property?.added?.source?.lastName) {
            return `${property?.added?.source?.firstName} ${property?.added?.source?.lastName}`;
        } else {
            return `${search?.agent?.firstName} ${search?.agent?.lastName}`;
        }
    };

    const getLabel = (property) => {
        if (!property?.status?.last) return 'Propiedad no compartida';
        if (property?.status?.last === 'deleted')
            return `Descartada el ${dayjs(property.status.history?.[0]?.timestamp)
                .tz()
                .format('DD/MM')} por ${property.status.reasonToFinish}`;
        else
            return `Agregada el ${dayjs(property.added?.timestamp).tz().format('DD/MM/YYYY')} por ${getFullNameBadge()}`;
    };
    return (
        <div>
            <PropertyCard
                banner={{
                    text: getLabel(property),
                    className: !property?.status?.last
                        ? 'bg-primary-color text-black'
                        : property?.status?.last === 'deleted'
                          ? 'bg-pulppo-status-light-error text-pulppo-status-dark-error'
                          : 'bg-gray-100 text-black'
                }}
                onClick={() => {
                    showProperty({
                        property,
                        propertyDetailActions: !property?.added?.source
                            ? () => (
                                  <>
                                      <Button
                                          onClick={() => onAdd()}
                                          type="primary"
                                          className="my-2 h-10 w-full font-medium"
                                          disabled={user?.uid !== search?.agent?.uid}
                                      >
                                          Agregar a la búsqueda
                                      </Button>
                                  </>
                              )
                            : () => (
                                  <>
                                      <Button
                                          onClick={() => onShare()}
                                          type="primary"
                                          className="my-2 h-10 w-full font-medium"
                                          disabled={user?.uid !== search?.agent?.uid}
                                      >
                                          Compartir nuevamente
                                      </Button>
                                  </>
                              ),

                        showStats: false,
                        isMLS: property.source === 'mls',
                        showInternal: true
                    });
                }}
                showContactInfo={true}
                note={
                    note.state !== 'hide' ? (
                        <div className="flex items-start gap-1" onClick={(ev) => ev.stopPropagation()}>
                            <Input.TextArea
                                disabled={note.state === 'loading'}
                                rows={7}
                                value={note.content}
                                onChange={(ev) =>
                                    setNote((note) => ({
                                        ...note,
                                        content: ev.target.value
                                    }))
                                }
                                placeholder="Escribe una nota para tu cliente aqui..."
                            />
                            <Button
                                disabled={note.state === 'loading'}
                                className="h-10 w-10 px-2 text-base font-medium"
                                onClick={(ev) => {
                                    ev.stopPropagation();
                                    saveNote();
                                }}
                            >
                                {note.state === 'loading' ? <LoadingOutlined /> : <CheckCircleOutlined />}
                            </Button>
                        </div>
                    ) : null
                }
                tag={
                    property?.status?.last === 'not_available' ? (
                        <div className="flex h-7 items-center gap-1 bg-pulppo-status-light-error px-6 py-1 uppercase text-pulppo-status-dark-error">
                            <p>NO DISPONIBLE</p>
                        </div>
                    ) : property.source === 'mls' ? (
                        <div className="flex h-7 items-center gap-1 bg-pulppo-status-light-warning px-6 py-1 uppercase text-pulppo-status-dark-warning">
                            <p>MLS</p>
                        </div>
                    ) : isExclusive(property) ? (
                        <ExclusiveTag isPulppoExclusive={!!property?.contract?.exclusive?.pulppo} />
                    ) : null
                }
                property={property}
                actions={
                    property?.status?.last ? (
                        <div className="flex w-full items-center gap-1 py-1">
                            {property?.status?.last !== 'deleted' ? (
                                <>
                                    {' '}
                                    <Button
                                        className="h-10 grow px-1 font-medium"
                                        onClick={(ev) => {
                                            ev.stopPropagation();
                                            onVisit();
                                        }}
                                        disabled={user?.uid !== search?.agent?.uid}
                                    >
                                        Agendar visita
                                    </Button>
                                    <Button
                                        className="h-10 grow px-1 font-medium"
                                        onClick={() => {
                                            window.open(
                                                `${window?.location?.origin}/operations/new?search=${search?._id}&property=${property._id}`,
                                                '_blank'
                                            );
                                        }}
                                        disabled={user?.uid !== search?.agent?.uid}
                                    >
                                        {t('to_offer', 'Ofertar')}
                                    </Button>
                                    <Tooltip
                                        overlay={<p className="text-white">Elimina la propiedad de la búsqueda</p>}
                                        color="black"
                                    >
                                        <LoadingButton
                                            icon={<DeleteOutlined className="text-lg font-medium" />}
                                            className="flex h-10 w-10 items-center justify-center text-lg font-medium"
                                            disabled={user?.uid !== search?.agent?.uid}
                                            onClick={async (ev) => {
                                                ev.stopPropagation();
                                                await onRemove();
                                            }}
                                        />
                                    </Tooltip>
                                </>
                            ) : (
                                <LoadingButton
                                    onClick={async (ev) => {
                                        ev.stopPropagation();
                                        await onRestore();
                                    }}
                                    type="default"
                                    className="h-10 w-full font-medium"
                                    disabled={user?.uid !== search?.agent?.uid}
                                >
                                    Compartir nuevamente
                                </LoadingButton>
                            )}
                            {user?.uid === search?.agent?.uid && (
                                <Tooltip
                                    overlay={<p className="text-white">Agrega una nota compartida con tu cliente</p>}
                                    color="black"
                                >
                                    <Button
                                        className="flex h-10 w-10 items-center justify-center text-lg font-medium"
                                        onClick={(ev) => {
                                            ev.stopPropagation();
                                            setNote((note) => ({ ...note, state: 'show' }));
                                        }}
                                    >
                                        <FormOutlined />
                                    </Button>
                                </Tooltip>
                            )}
                        </div>
                    ) : (
                        <div className="flex w-full items-center gap-1 py-1">
                            <LoadingButton
                                onClick={async (ev) => {
                                    ev.stopPropagation();
                                    await onAdd();
                                }}
                                type="default"
                                className="h-10 w-full font-medium"
                                disabled={user?.uid !== search?.agent?.uid}
                            >
                                Compartir propiedad
                            </LoadingButton>
                        </div>
                    )
                }
            />
        </div>
    );
};

export { SearchPropertyCard };
