import { useTranslate } from '@tolgee/react';
import { Checkbox, Col, Row, Typography } from 'antd';
import { useState } from 'react';

export const ExpandableCheckboxGroup = ({ title, items = [], value, onChange = (_) => {} }) => {
    const [expand, setExpand] = useState(false);
    const options = items.sort((a, b) => a.name.localeCompare(b.name)).map((i) => ({ ...i, show: true }));
    const { t } = useTranslate('common');

    return (
        <div className="mb-20px pr-4">
            <Typography.Title level={5}>{t(title)}</Typography.Title>
            <Row>
                {options.slice(0, expand ? options.length : 10).map((service, i) => (
                    <Col xs={24} className={`${service.show ? '' : 'hidden'}`} key={`${title}-${service.id}-${i}`}>
                        <Checkbox
                            className="py-1 text-base"
                            checked={value?.some((el) => el.id === service.id)}
                            value={service.id}
                            onClick={() => {
                                if (value?.some((el) => el.id === service.id)) {
                                    onChange(value.filter((el) => el.id !== service.id));
                                } else {
                                    onChange([...value, service]);
                                }
                            }}
                        >
                            {t(service.name)}
                        </Checkbox>
                    </Col>
                ))}
            </Row>
            {options.filter((opt) => opt.show).length > 10 && (
                <Typography.Link className="mt-20px" onClick={() => setExpand((val) => !val)}>
                    Mostrar {expand ? 'menos' : 'más'}
                </Typography.Link>
            )}
        </div>
    );
};
