import { MetricType } from '@pulppo/pulppo-models/build/enums/MetricType';
import { BasicColor } from '../components/Home/AgentsList';
import { ColorBadge } from './color';
import { WORKING_HOUR_END, WORKING_HOUR_START } from '@pulppo/pulppo-models/build/utils/config';
import dayjs from 'dayjs';

export const METRICS_TITLES: Record<string, { long: string; short?: string }> = {
    pendingLeads: {
        long: 'Consultas pendientes'
    },
    firstTimeResponse: {
        long: 'Tiempo de primera respuesta',
        short: 'T. de 1° respuesta'
    },
    pendingTasks: {
        long: 'Tareas pendientes de clientes',
        short: 'Tareas pendientes client.'
    },
    avgResponseTime: {
        long: 'Tiempo medio de respuesta',
        short: 'T. de respuesta medio'
    },
    followUpQuality: {
        long: 'Calidad de seguimiento'
    },
    suggestedProperties: {
        long: 'Propiedades sugeridas',
        short: 'Sugerencia de propiedades'
    },
    visitRate: { long: 'Tasa de visita' },
    offerRate: { long: 'Tasa de oferta' },
    pendingPropertyTasks: { long: 'Tareas pendientes de propiedades', short: 'Tareas pendientes prop.' },
    saleInventory: {
        long: 'Inventario en venta'
    },
    inventory: {
        long: 'Inventario'
    }
};

export const METRICS_DATA = {
    pendingLeads: {
        measure: {
            description: [
                'La cantidad de consultas que aun no fueron respondidas.',
                'Cada semana se realiza un corte semanal para evaluar la evolución de la métrica.'
            ]
        },
        important: {
            title: '¿Por qué es importante no dejar consultas pendientes de respuesta?',
            description: [
                'Principalmente porque estás perdiendo oportunidades comerciales y, como consecuencia, dejando de ganar más dinero.',
                'Pulppo penaliza a los asesores que no atienden las consultas. Las propiedades de estos asesores tendrán menos exposición en todos los portales.'
            ]
        },
        tips: {
            description: [
                'Activa las notificaciones de tu celular para recibir alertas cada vez que el Bot de Pulppo te envíe un nuevo contacto, e ingresa proactivamente a la plataforma una vez al día para revisar si hay nuevas consultas.',
                'Cambia la configuración de sonido en WhatsApp  para la notificación del Bot de Pulppo  para identificar rápidamente los mensajes que recibes como nuevas consultas.'
            ]
        }
    },
    firstTimeResponse: {
        measure: {
            description: [
                'La métrica de tiempo de primera respuesta mide cuanto demoras en responder a una consulta desde que la recibes. Por ejemplo, si recibes una consulta a las 11:00 AM y respondes a las 11:30 AM, tu tiempo de primera respuesta es de 30 minutos.',
                `Para el cálculo de esta métrica, se consideran hábiles los horarios de ${dayjs().hour(WORKING_HOUR_START).format('h')} a.m. a ${dayjs().hour(WORKING_HOUR_END).format('h')} p.m. todos los días. Si no estás disponible en Pulppo, esto no afectará la métrica, ya que no recibirás nuevas consultas.`
            ]
        },
        important: {
            title: '¿Por qué es importante reducir este tiempo?',
            description: [
                'Los clientes valoran una respuesta rápida, mejorando la percepción de tu servicio y aumentando las posibilidades de cerrar ventas.',
                'Pulppo prioriza a las asesores con tiempos de respuesta rápidos, incrementando la visibilidad de sus propiedades en los portales y campañas.',
                'Cada minuto que pasa desde la llegada de una consulta reduce significativamente la posibilidad de conversión, con una caída drástica después de 10 minutos.'
            ]
        },
        tips: {
            description: [
                <>
                    Utiliza WhatsApp Business como tu herramienta comercial, que incluye &quot;Respuestas rápidas&quot;
                    para enviar respuestas predefinidas a nuevos contactos.{' '}
                    <a
                        className="underline"
                        href="https://faq.whatsapp.com/1791149784551042/?locale=es_LA&cms_platform=android"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Acá te enseñamos cómo.
                    </a>
                </>,
                'Mantén tu celular en modo sonido durante tus horarios laborales para estar atenta a las nuevas consultas enviadas por el bot de Pulppo.',
                'Personaliza el tono de notificación de WhatsApp para los mensajes del bot de Pulppo para estar siempre alerta a la llegada de una nueva consulta.'
            ]
        }
    },
    pendingTasks: {
        measure: {
            description: [
                'La métrica de tareas pendientes de clientes mide las tareas sin completar, como seguimientos, ofrecer más propiedades y concretar visitas.',
                'Mantener pocas tareas pendientes es vital para una operación diaria eficiente y evitar olvidar lo importante.'
            ]
        },
        important: {
            title: '¿Por qué es importante reducir el número de tareas pendientes de clientes?',
            description: [
                'Una lista de tareas de clientes te ayuda a mantenerte organizado y a priorizar actividades que aportan valor. Pulppo genera en promedio 4 tareas de cliente al día.',
                'Pulppo valora la eficiencia operativa. Un menor número de tareas pendientes mejora tu posicionamiento en la plataforma y si las tareas caducan, se generan penalizaciones que afectan la difusión de tu inventario.'
            ]
        },
        tips: {
            description: [
                'Dedica 20 minutos cada mañana o tarde para realizar tus tareas.',
                'No acumules tareas; hacerlas diariamente puede tomar menos de 20 minutos, mientras que acumularlas puede generar un efecto bola de nieve.'
            ]
        }
    },
    avgResponseTime: {
        measure: {
            description: [
                'La métrica de tiempo medio de respuesta mide cuánto demoras en promedio en responder a los WhatsApp de tus clientes con los cuales tienes una búsqueda activa.',
                'Es importante diferenciar entre la primera respuesta y el tiempo medio de respuesta. La primera mide únicamente la respuesta inicial, mientras que el segundo refleja la atención continua que das a un cliente una vez iniciada la conversación durante la evolución de la búsqueda.'
            ]
        },
        important: {
            title: '¿Por qué es importante reducir este tiempo?',
            description: [
                'Es crucial responder rápidamente para no perder oportunidades y mantener una comunicación fluida con los clientes. La demora en responder puede resultar en la pérdida de la atención del cliente.',
                'Pulppo prioriza a los asesores con tiempos de respuesta rápidos, mejorando la visibilidad de sus propiedades en portales y campañas.'
            ]
        },
        tips: {
            description: [
                'Mantén tu celular en modo sonido durante el horario laboral para estar atenta a las consultas enviadas por el Bot de Pulppo.',
                'Si no tienes la respuesta a una consulta de un cliente, comunícale tu desconocimiento y asegúrate de responder tan pronto como obtengas la información necesaria.'
            ]
        }
    },
    followUpQuality: {
        measure: {
            description: [
                'La métrica de calidad de seguimiento mide la cantidad de mensajes de WhatsApp o correos enviados a clientes con una búsqueda abierta.',
                'Buena: Mayor a 10 interacciones',
                'Regular: Entre 4 y 10 interacciones',
                'Mala: Menos de 4 interacciones'
            ]
        },
        important: {
            title: '¿Por qué es importante mantener una buena calidad de seguimiento?',
            description: [
                'Un buen seguimiento puede aumentar la fidelización de los clientes y mejorar las posibilidades de cerrar una venta.',
                'Es crucial mantener un buen seguimiento para que los clientes se sientan atendidos y valorados.'
            ]
        },
        tips: {
            description: [
                'Se persistente. Muchos clientes no responden al primer mensaje; enviar seguimientos días después aumenta las respuestas y avanza en la búsqueda. Apóyate en las respuestas rápidas y toques que ofrece Pulppo.',
                'Utiliza Pulppo para realizar diariamente todas las tareas del cliente, optimizando tu eficiencia.'
            ]
        }
    },
    suggestedProperties: {
        measure: {
            description: [
                'La cantidad de propiedades compartidas al cliente en las búsquedas creadas de los últimos 30 días. Descartando las búsquedas cerradas como incontactable y fantasma.',
                'Por ejemplo, si recibiste una consulta por una propiedad y en el seguimiento le ofreciste 3 propiedades que pueden llegar a interesarle según su perfilamiento. El numero de sugerencia de propiedades será de 4, la consultada más las tres que ofreciste.'
            ]
        },
        important: {
            title: '¿Por qué es importante sugerir propiedades a tus clientes?',
            description: [
                'Si no ofreces otras propiedades a tus clientes, estás desperdiciando una oportunidad, ya que podrían recurrir a otro asesor y cerrar la operación sin tu participación.'
            ]
        },
        tips: {
            description: [
                'Utiliza las propiedades sugeridas por Pulppo dentro de la búsqueda para captar la atención de tus clientes desde el inicio de la interacción.',
                'Invítalos a explorar Mi.Pulppo y a señalar otras propiedades de interés, comprometiéndote a organizar los recorridos necesarios para acompañarlos en su proceso de compra o renta.'
            ]
        }
    },
    visitRate: {
        measure: {
            description: [
                'La métrica de tasa de visita mide el porcentaje de consultas que tienen registrada al menos una visita, en comparación con el total de consultas recibidas en los últimos 30 días.',
                'Por ejemplo, si recibes 10 consultas en los últimos 30 días y logras llevar a 5 de estos clientes a al menos una visita, tu tasa de visita será del 50%.'
            ]
        },
        important: {
            title: '¿Por qué es importante mantener una alta tasa de visita?',
            description: [
                'Realizar visitas es crucial para que los clientes conozcan mejor las propiedades y aumenten las probabilidades de venta al obtener una idea clara de lo que podrían adquirir.',
                'Mejora tu reputación como asesor activo y comprometido con los propietarios para generar más referencias y atraer nuevos clientes potenciales.',
                'Una baja tasa de visita puede generar penalización dentro de la plataforma de Pulppo, afectando la difusión de tu inventario'
            ]
        },
        tips: {
            description: [
                'Contacta regularmente a tus clientes interesados para proponerles visitas y confirma las citas con anticipación.',
                'Organiza recorridos para mostrar varias propiedades a un mismo cliente y optimizar el tiempo.',
                'No canceles las visitas solicitadas por el cliente a través de Mi.Pulppo, siempre busca ofrecer un nuevo día, horario o propiedad. Los clientes que ya quieren visitar una propiedad son los que tienen mayor probabilidad de cierre.'
            ]
        }
    },
    offerRate: {
        measure: {
            description: [
                'La métrica de tasa de oferta mide el porcentaje de consultas de los últimos 30 días que han registrado al menos una oferta, en comparación con el total de consultas recibidas.',
                'Por ejemplo, si en los últimos 30 días recibes 50 consultas y 3 clientes presentaron una oferta, tú tasa de oferta será del 6%.'
            ]
        },
        important: {
            title: '¿Por qué es importante mantener una buena tasa de oferta?',
            description: [
                'Una alta tasa de oferta refleja un buen manejo de las consultas y una mayor probabilidad de cerrar ventas, lo que mejora tus ingresos y reputación como asesor inmobiliario',
                'Una baja tasa de oferta puede generar penalización dentro de la plataforma de Pulppo, afectando la difusión de tu inventario.'
            ]
        },
        tips: {
            description: [
                'Registra en la plataforma la propuesta de tus clientes desde el momento que manifiestan interés en avanzar con una propiedad.'
            ]
        }
    },
    pendingPropertyTasks: {
        measure: {
            description: [
                'La métrica de tareas pendientes de propiedades mide las tareas sin completar, como',
                'Mantener pocas tareas pendientes es vital para una comercialización eficiente y evitar olvidar lo importante.'
            ]
        },
        important: {
            title: '¿Por qué es importante reducir el número de tareas pendientes de propiedades?',
            description: [
                'Una lista de tareas de propiedades te ayuda a mantener tu inventario optimizado y a priorizar actividades que aportan valor.',
                'Pulppo valora la eficiencia operativa. Un menor número de tareas pendientes mejora tu posicionamiento en la plataforma y si las tareas caducan, se generan penalizaciones que afectan la difusión de tu inventario.'
            ]
        },
        tips: {
            description: [
                'Dedica 20 minutos cada mañana o tarde para realizar tus tareas.',
                'No acumules tareas; hacerlas diariamente puede tomar menos de 20 minutos, mientras que acumularlas puede generar un efecto bola de nieve.'
            ]
        }
    },
    saleInventory: {
        measure: {
            description: [
                'La métrica de inventario en venta mide el porcentaje de propiedades en venta activas que estás comercializando en relación con el total de tu inventario, ya seas productor o vendedor.'
            ]
        },
        important: {
            title: '¿Por qué es importante la captación de propiedades en venta?',
            description: [
                'Es crucial siempre enfocarse en las ventas como asesor inmobiliario para asegurar el éxito y el crecimiento profesional.',
                'Tener muchas propiedades en renta y pocas en venta puede generarte un flujo de ingresos continuo. Pero este flujo de ingreso no va a crecer en el tiempo.'
            ]
        },
        tips: {
            description: [
                'Dedica un día a la semana exclusivamente a la captación de nuevas propiedades. Sal a recorrer las zonas que manejas para generar nuevas oportunidades.',
                'Amplía tu red de contactos y conviértete en embajador en tu círculo cercano para estar siempre presente cuando alguien necesite un asesor inmobiliario.'
            ]
        }
    },
    inventory: {
        measure: {
            description: [
                'La métrica de inventario se refiere al total de propiedades activas que estás comercializando actualmente, ya seas el productor o el vendedor.'
            ]
        },
        important: {
            title: '¿Por qué es importante crecer el inventario?',
            description: [
                'Mantener un flujo constante de nuevas propiedades ayuda a mantenerte competitivo en el mercado inmobiliario, atrayendo a más clientes potenciales y mejorando tu reputación como profesional.'
            ]
        },
        tips: {
            description: [
                'Dedica un día a la semana exclusivamente a la captación de nuevas propiedades. Sal a recorrer las zonas que manejas para generar nuevas oportunidades.',
                'Amplía tu red de contactos y conviértete en embajador en tu círculo cercano para estar siempre presente cuando alguien necesite un asesor inmobiliario.'
            ]
        }
    }
};

export const getMetricTitle = (metricType: MetricType, length: 'short' | 'long') => {
    switch (metricType) {
        case MetricType.UnansweredLeads:
            return METRICS_TITLES.pendingLeads?.[length] || METRICS_TITLES.pendingLeads.long;
        case MetricType.FirstResponseTime:
            return METRICS_TITLES.firstTimeResponse?.[length] || METRICS_TITLES.firstTimeResponse.long;
        case MetricType.TasksPendingClient:
            return METRICS_TITLES.pendingTasks?.[length] || METRICS_TITLES.pendingTasks.long;
        case MetricType.AvgResponseTime:
            return METRICS_TITLES.avgResponseTime?.[length] || METRICS_TITLES.avgResponseTime.long;
        case MetricType.Interactions:
            return METRICS_TITLES.followUpQuality?.[length] || METRICS_TITLES.followUpQuality.long;
        case MetricType.AvgSuggestedProperties:
            return METRICS_TITLES.suggestedProperties?.[length] || METRICS_TITLES.suggestedProperties.long;
        case MetricType.VisitRate:
            return METRICS_TITLES.visitRate?.[length] || METRICS_TITLES.visitRate.long;
        case MetricType.OfferRate:
            return METRICS_TITLES.offerRate?.[length] || METRICS_TITLES.offerRate.long;
        case MetricType.TasksPendingProperty:
            return METRICS_TITLES.pendingPropertyTasks?.[length] || METRICS_TITLES.pendingPropertyTasks.long;
        case MetricType.PropertiesSale:
            return METRICS_TITLES.saleInventory?.[length] || METRICS_TITLES.saleInventory.long;
        case MetricType.Properties:
            return METRICS_TITLES.inventory?.[length] || METRICS_TITLES.inventory.long;
        default:
            return '';
    }
};

export const getMetricContent = (metricType: MetricType) => {
    switch (metricType) {
        case MetricType.UnansweredLeads:
            return METRICS_DATA.pendingLeads;
        case MetricType.FirstResponseTime:
            return METRICS_DATA.firstTimeResponse;
        case MetricType.TasksPendingClient:
            return METRICS_DATA.pendingTasks;
        case MetricType.AvgResponseTime:
            return METRICS_DATA.avgResponseTime;
        case MetricType.Interactions:
            return METRICS_DATA.followUpQuality;
        case MetricType.AvgSuggestedProperties:
            return METRICS_DATA.suggestedProperties;
        case MetricType.VisitRate:
            return METRICS_DATA.visitRate;
        case MetricType.OfferRate:
            return METRICS_DATA.offerRate;
        case MetricType.TasksPendingProperty:
            return METRICS_DATA.pendingPropertyTasks;
        case MetricType.PropertiesSale:
            return METRICS_DATA.saleInventory;
        case MetricType.Properties:
            return METRICS_DATA.inventory;
        default:
            return null;
    }
};

const METRICS_REAL_TIME = [
    MetricType.UnansweredLeads,
    MetricType.Properties,
    MetricType.PropertiesSale,
    MetricType.TasksPendingClient,
    MetricType.TasksExpiredClient,
    MetricType.TasksPendingProperty,
    MetricType.TasksExpiredProperty
];

const METRICS_LAST_WEEK = [
    MetricType.FirstResponseTime,
    MetricType.AvgResponseTime,
    MetricType.Interactions,
    MetricType.OfferRate,
    MetricType.AvgSuggestedProperties,
    MetricType.VisitRate
];

export const currentWeekMetricFromSelect = (select: Array<MetricType>) => {
    return select.some((metric) => METRICS_REAL_TIME.includes(metric));
};

export const selectMetricCurrentWeek = ({ currentWeek }: { currentWeek: boolean }) => {
    let select: Array<MetricType>;
    if (currentWeek) {
        select = METRICS_REAL_TIME;
    } else {
        select = METRICS_LAST_WEEK;
    }

    return select;
};

export const isLoadingMetricByCurrentWeek = ({
    type,
    isLoadingCurrentWeek,
    isLoadingNoCurrentWeek
}: {
    type: MetricType;
    isLoadingCurrentWeek: boolean;
    isLoadingNoCurrentWeek: boolean;
}) => {
    return currentWeekMetricFromSelect([type]) ? isLoadingCurrentWeek : isLoadingNoCurrentWeek;
};

export const interactionsToQuality = (interactions = 0) => {
    return Math.floor(interactions) >= 75 ? 'Bueno' : Math.floor(interactions) < 50 ? 'Malo' : 'Regular';
};

export enum MetricStatus {
    Good = 'good',
    Regular = 'regular',
    Bad = 'bad'
}

export const verifyMetricStatus = (metricType: MetricType, metric: number): MetricStatus => {
    switch (metricType) {
        case MetricType.UnansweredLeads:
        case MetricType.TasksPendingClient:
        case MetricType.TasksExpiredClient:
        case MetricType.TasksPendingProperty:
        case MetricType.TasksExpiredProperty:
        case MetricType.TasksPending:
            return metric === 0 ? MetricStatus.Good : MetricStatus.Bad;
        case MetricType.FirstResponseTime:
        case MetricType.AvgResponseTime:
            return metric < 15 ? MetricStatus.Good : metric < 60 ? MetricStatus.Regular : MetricStatus.Bad;
        case MetricType.Interactions:
            return metric >= 75 ? MetricStatus.Good : metric < 50 ? MetricStatus.Bad : MetricStatus.Regular;
        case MetricType.AvgSuggestedProperties:
            return metric >= 3 ? MetricStatus.Good : MetricStatus.Bad;
        case MetricType.VisitRate:
            return metric > 15 ? MetricStatus.Good : metric >= 10 ? MetricStatus.Regular : MetricStatus.Bad;
        case MetricType.OfferRate:
            return metric >= 3 ? MetricStatus.Good : MetricStatus.Bad;
        case MetricType.PropertiesSale:
            return metric < 50 ? MetricStatus.Bad : metric < 70 ? MetricStatus.Regular : MetricStatus.Good;
        case MetricType.Properties:
            return metric < 20 ? MetricStatus.Bad : metric <= 30 ? MetricStatus.Regular : MetricStatus.Good;
        default:
            return MetricStatus.Bad;
    }
};

export const getColorMetricChart = (metricType: MetricType, metric: number) => {
    const status = verifyMetricStatus(metricType, metric);
    return status === MetricStatus.Good
        ? BasicColor.Good
        : status === MetricStatus.Regular
          ? BasicColor.Neutral
          : BasicColor.Wrong;
};

export const getColorBadgeMetric = (metricType: MetricType, metric: number) => {
    const status = verifyMetricStatus(metricType, metric);
    return status === MetricStatus.Good
        ? ColorBadge.Green
        : status === MetricStatus.Regular
          ? ColorBadge.Yellow
          : ColorBadge.Red;
};

export const getColorVerifyIsMetricIsGood = (metricType: MetricType, metric: number) => {
    const status = verifyMetricStatus(metricType, metric);
    return status === MetricStatus.Good || status === MetricStatus.Regular
        ? 'text-pulppo-status-dark-success'
        : 'text-pulppo-status-dark-error';
};
