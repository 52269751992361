import { PulppoDrawer, PulppoDrawerProps } from '../Elements/PulppoDrawer';
import AgentsList, { BasicColor } from './AgentsList';
import useSWR from 'swr';
import { IAgent } from '@pulppo/pulppo-models';
import { api_url, fetcher } from '../../helpers/fetcher';
import { METRICS_DESCRIPTION } from '../../utils/constants';
import { useRouter } from 'next/router';

interface Props extends PulppoDrawerProps {
    companyId: string;
}

export const DrawerSuspension = ({ companyId, ...props }: Props) => {
    const { data: agents } = useSWR<Array<IAgent>>(`${api_url}/company/${companyId}/agents?filterBy=status`, fetcher);
    const router = useRouter();
    return (
        <PulppoDrawer className="text-base" title={METRICS_DESCRIPTION.suspension.title} {...props}>
            <AgentsList
                filter
                agents={agents}
                actions={{
                    onClick(agent) {
                        router.query.suspension = agent?.uid;
                        router.push(router);
                    },
                    sort: (x, y) =>
                        x.suspensions?.length === y.suspensions?.length
                            ? x.warnings?.length === y.warnings?.length
                                ? 0
                                : y.warnings?.length
                                  ? 1
                                  : -1
                            : y.suspensions?.length
                              ? 1
                              : -1,
                    tag: [
                        {
                            condition: (agent) => {
                                return agent.suspensions?.[0]
                                    ? BasicColor.Wrong
                                    : agent.warnings?.[0]
                                      ? BasicColor.Neutral
                                      : BasicColor.Good;
                            },
                            text: {
                                success: 'Sin riesgo',
                                error: (agent) => (agent.suspensions?.[0] ? 'Pausa de consultas' : 'Advertencia')
                            }
                        }
                    ]
                }}
            />
        </PulppoDrawer>
    );
};

export default DrawerSuspension;
