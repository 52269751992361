import { IconType } from '../components/Icon/Icon';

export const normalizeTextAttribute = (text: IconType, value?: number | string, t?: any): string => {
    switch (text) {
        case IconType.Bed:
            return `${value} ${t('suites_plural', { count: value })}`;
        case IconType.Door:
            return `${value} ${t('spaces_plural', { count: value })}`;
        case IconType.Bathroom:
            return `${value} ${t('bathroom_plural', { count: value })}`;
        case IconType.Garage:
            return `${value} ${t('parkings_plural', { count: value })}`;
        case IconType.Upgrade:
            return `Est. ${value}`;
        case IconType.Mts:
            return `${value} m2`;
        case IconType.Toilet:
            return value === 1 ? `1 ${t('toilette')}` : `${value} ${t('toilettes')}`;
        case IconType.Hourglass:
            return value === -1 ? 'A estrenar' : `${value} Año de construcción`;
        case IconType.UpFloor:
            return `Piso ${value}`;
        default:
            return '';
    }
};
