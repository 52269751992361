import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { ColorBadge } from '../../utils/color';

export interface BadgeProps {
    color: ColorBadge;
    value: ReactNode;
    border?: boolean;
    className?: string;
    onClick?: () => void;
}

export const Badge = ({ color, value, className, onClick, border }: BadgeProps) => {
    let colorClassName = '';
    switch (color) {
        case ColorBadge.Yellow: {
            colorClassName = 'text-pulppo-status-dark-warning bg-pulppo-status-light-warning';
            if (border) {
                colorClassName += ' border-solid border border-pulppo-status-dark-warning';
            }
            break;
        }
        case ColorBadge.Green: {
            colorClassName = 'text-pulppo-status-dark-success bg-pulppo-status-light-success';
            if (border) {
                colorClassName += ' border-solid border border-pulppo-status-dark-success';
            }
            break;
        }
        case ColorBadge.Gray: {
            colorClassName = 'text-pulppo-status-dark-disabled bg-pulppo-status-light-disabled';
            if (border) {
                colorClassName += ' border-solid border border-pulppo-status-dark-disabled';
            }
            break;
        }
        case ColorBadge.Blue: {
            colorClassName = 'text-pulppo-status-dark-info bg-pulppo-status-light-info';
            if (border) {
                colorClassName += ' border-solid border border-pulppo-status-dark-info';
            }
            break;
        }
        case ColorBadge.Red: {
            colorClassName = 'text-pulppo-status-dark-error bg-pulppo-status-light-error';
            if (border) {
                colorClassName += ' border-solid border border-pulppo-status-dark-error';
            }
            break;
        }
        case ColorBadge.Black: {
            colorClassName = 'text-white bg-pulppo-primary-black';
            if (border) {
                colorClassName += ' border-solid border border-pulppo-primary-black';
            }
        }
    }
    const element = (
        <span
            className={twMerge(
                'inline-flex items-center justify-center px-2 py-1 text-xs font-normal capitalize',
                colorClassName,
                className
            )}
        >
            {value}
        </span>
    );

    if (onClick) {
        return (
            <button className={className} onClick={onClick} type="button">
                {element}
            </button>
        );
    }
    return element;
};

export default Badge;
