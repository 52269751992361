import { Row, Col, Input, Form, notification } from 'antd';
import Button from '../Elements/Button';
import axios from '../../utils/axios';
import { useContext, useState } from 'react';
import useSWR from 'swr';
import { PropertyFormContext } from '../../contexts/PropertyFormContext';
import { api_url, fetcher } from '../../helpers/fetcher';
import { getLocationFromCoordinates, MapsLocationToAddress } from '../../helpers/mapsLocationToAddress';
import { formEventEmitter } from '../../utils/FormEvents';
import { AddressDrawer } from '../Elements/AddressDrawer';
import { PulppoMap, PulppoMarker, PulppoZoom } from '../Elements/PulppoMap';
import { PulppoSelect } from '../Elements/PulppoSelect';
import { twMerge } from 'tailwind-merge';
import { useTranslate } from '@tolgee/react';
import { IProperty } from '@pulppo/pulppo-models';
import useUser from '../../hooks/useUser';

export const PropertyLocation = ({
    showAdvanced = true,
    property,
    focusAddress,
    onSelectAddress,
    isDisabled
}: {
    showAdvanced?: boolean;
    property?: Partial<IProperty>;
    focusAddress?: boolean;
    onSelectAddress?: (address: IProperty['address']) => Promise<void>;
    isDisabled?: boolean;
}) => {
    const { t } = useTranslate(['common', 'property_edit']);
    const propertyForm = useContext(PropertyFormContext);

    const { formData } = property ? { formData: property } : propertyForm || {};

    const stateId = formData?.address?.state?.id;
    const cityId = formData?.address?.city?.id;
    const neighborhoodId = formData?.address?.neighborhood?.id;
    const subdivisionId = formData?.address?.subdivision?.id;

    const { data: states } = useSWR(`${api_url}/location?type=state`, fetcher);
    const { data: cities } = useSWR(`${api_url}/location?type=city&state=${stateId}`, fetcher);
    const { data: neighborhoods } = useSWR(`${api_url}/location?type=neighborhood&city=${cityId}`, fetcher);
    const { data: subdivisions } = useSWR(
        `${api_url}/location?type=subdivision&neighborhood=${neighborhoodId}`,
        fetcher
    );
    const { data: subsubdivisions } = useSWR(
        `${api_url}/location?type=subsubdivision&subdivision=${subdivisionId}`,
        fetcher
    );

    return (
        <div className="@container">
            <Row gutter={10} align="stretch">
                <Col flex="auto">
                    <AddressStreetInput
                        isDisabled={isDisabled}
                        onSelectAddress={onSelectAddress}
                        focusAddress={focusAddress}
                    />
                </Col>
            </Row>
            <div className="mt-2 pb-8 @lg:mt-10">
                {showAdvanced && (
                    <Row gutter={10}>
                        <Col span={8}>
                            <Form.Item name={['address', 'floor']} label="Piso">
                                <Input
                                    type="number"
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    className="h-10 rounded-none"
                                    disabled={isDisabled}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name={['address', 'apartment']}
                                rules={[
                                    formData?.type === 'Departamento' &&
                                        showAdvanced && {
                                            required: true,
                                            message: 'El departamento tiene que tener un interno'
                                        }
                                ].filter(Boolean)}
                                label="Interno"
                            >
                                <Input className="h-10 rounded-none" disabled={isDisabled} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={['address', 'zip']} label="Código postal">
                                <Input className="h-10 rounded-none" disabled={isDisabled} />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <div className="mt-2 grid grid-cols-1 gap-2 @lg:mt-10 @lg:grid-cols-3">
                    <Form.Item
                        className="form-item-one-column"
                        name={['address', 'state']}
                        label={t('state', 'Estado', { ns: 'common' })}
                        rules={[
                            {
                                required: true,
                                message: 'Selecciona un estado'
                            }
                        ]}
                    >
                        <AddressComponentSelect disabled={isDisabled} options={states} />
                    </Form.Item>
                    <Form.Item
                        className="form-item-one-column"
                        name={['address', 'city']}
                        label={t('city', 'Ciudad', { ns: 'common' })}
                        rules={[
                            {
                                required: true,
                                message: 'Selecciona una ciudad'
                            }
                        ]}
                    >
                        <AddressComponentSelect options={cities} />
                    </Form.Item>
                    <Form.Item
                        className="form-item-one-column"
                        name={['address', 'neighborhood']}
                        label={t('neighborhood', 'Colonia', { ns: 'common' })}
                        rules={[
                            {
                                required: true,
                                message: t('missing_neighborhood_error', 'Seleccione una colonia', {
                                    ns: 'property_edit'
                                })
                            }
                        ]}
                    >
                        <AddressComponentSelect disabled={isDisabled} options={neighborhoods} />
                    </Form.Item>
                    {!!subdivisions?.length && (
                        <Form.Item
                            className="form-item-one-column"
                            name={['address', 'subdivision']}
                            label={t('subdivision', 'División', { ns: 'common' })}
                            rules={[
                                {
                                    required: false,
                                    message: t('missing_neighborhood_error', 'Seleccione una colonia', {
                                        ns: 'property_edit'
                                    })
                                }
                            ]}
                        >
                            <AddressComponentSelect disabled={isDisabled} options={subdivisions} />
                        </Form.Item>
                    )}
                    {!!subsubdivisions?.length && (
                        <Form.Item
                            className="form-item-one-column"
                            name={['address', 'subsubdivision']}
                            label={t('subsubdivision', 'Subdivisión', { ns: 'common' })}
                            rules={[
                                {
                                    required: false,
                                    message: t('missing_neighborhood_error', 'Seleccione una colonia', {
                                        ns: 'property_edit'
                                    })
                                }
                            ]}
                        >
                            <AddressComponentSelect disabled={isDisabled} options={subsubdivisions} />
                        </Form.Item>
                    )}
                </div>
            </div>
            {cityId ? (
                <Row className="autocomplete-development pb-4">
                    <Col xs={24}>
                        <Form.Item
                            name="development"
                            className="form-item-one-column m-0 h-full"
                            label={t('development_title', 'Nombre del desarrollo', { ns: 'property_edit' })}
                        >
                            <DevelopmentInput disabled={isDisabled} city={formData?.address?.city} />
                        </Form.Item>
                    </Col>
                </Row>
            ) : null}
            <Row className="mb-20px mt-4">
                <Col span={24}>
                    <Form.Item name={['address', 'location', 'type']} hidden />
                    <Form.Item name={['address', 'location', 'coordinates']}>
                        {formData?.address ? <PulppoMapInput disabled={isDisabled} /> : null}
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

const PulppoMapInput = (props?: { value?: Array<number>; onChange?: (arg0: any) => void; disabled?: boolean }) => {
    return (
        <PulppoMap
            center={props.value?.[0] ? [props.value?.[0], props.value?.[1]] : null}
            containerStyle={{
                height: '400px',
                width: '100%'
            }}
        >
            <PulppoMarker
                setCoordinates={async (coordinates: Array<number>) => {
                    if (props?.disabled) return;
                    try {
                        props.onChange(coordinates);
                        const address = await getLocationFromCoordinates({
                            lng: coordinates[0],
                            lat: coordinates[1]
                        });
                        formEventEmitter.emit('changes', {
                            address: {
                                ...address,
                                location: { type: 'Point', coordinates }
                            }
                        });
                    } catch (err) {
                        notification.error({
                            message: `Hubo un error: ${err}`,
                            description: 'Prueba de vuelta en unos segundos'
                        });
                    }
                }}
                coordinates={props.value?.[0] ? [props.value?.[0], props.value?.[1]] : []}
            />
            <PulppoZoom />
        </PulppoMap>
    );
};

export const AddressStreetInput = ({
    onSelectAddress,
    focusAddress,
    isDisabled
}: {
    onSelectAddress?: (address: IProperty['address']) => Promise<void>;
    focusAddress?: boolean;
    isDisabled?: boolean;
}) => {
    const form = Form.useFormInstance();

    return (
        <Form.Item
            className="form-item-one-column"
            name={['address', 'street']}
            label="Calle y número"
            rules={[
                {
                    required: true,
                    message: 'El inmueble tiene que tener una dirección'
                }
            ]}
        >
            <AddressDrawer
                onSelect={async (pl) => {
                    if (!pl.geometry) {
                        pl.geometry = {
                            location: {
                                lat: () => form?.getFieldValue(['address', 'location', 'coordinates', '1']),
                                lng: () => form?.getFieldValue(['address', 'location', 'coordinates', '0'])
                            }
                        };
                    }
                    const mappedAddress = await MapsLocationToAddress(pl);
                    if (!mappedAddress) {
                        return;
                    }
                    await onSelectAddress?.(mappedAddress);
                    form?.setFieldValue('address', mappedAddress);
                    formEventEmitter?.emit('changes', {
                        address: mappedAddress
                    });
                }}
                focus={focusAddress}
                isDisabled={isDisabled}
            />
        </Form.Item>
    );
};

export const DevelopmentInput = ({ city, value = null, onChange = (_) => {}, disabled = false }) => {
    const [searchDevelopment, setSearchDevelopment] = useState('');
    const user = useUser((state) => state.user);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const {
        data: developments,
        isLoading,
        mutate: refreshDevelopments
    } = useSWR(`${api_url}/development?${city?.id ? 'city=' + city.id : ''}`, fetcher);
    const { t } = useTranslate('property_edit');

    return (
        <div>
            <PulppoSelect
                disabled={disabled}
                visible={visible}
                className="h-10"
                onSearchChange={(search) => {
                    setSearchDevelopment(search);
                }}
                onSelect={(value) => {
                    onChange({
                        name: value.label,
                        _id: value.value
                    });
                }}
                value={value?._id}
                emptyState=" "
                loading={isLoading || loading}
                options={developments?.map?.((elem) => ({
                    key: elem._id,
                    label: elem.development.name,
                    value: elem._id
                }))}
                title={t('development_drawer_title', 'Desarrollos en {city}', { city: city?.name })}
                action={
                    value?._id ? (
                        <Button
                            className="h-11 w-full px-10"
                            onClick={() => {
                                onChange({});
                                setVisible(false);
                            }}
                        >
                            {t('delete_development', 'No es un desarrollo')}
                        </Button>
                    ) : searchDevelopment ? (
                        <Button
                            type="primary"
                            className="absolute inset-x-6 bottom-6 flex items-center justify-center truncate font-medium"
                            onClick={() => {
                                axios
                                    .post(`${api_url}/development`, {
                                        name: searchDevelopment,
                                        company: user?.company,
                                        agent: user,
                                        address: {
                                            city: city
                                        }
                                    })
                                    .then((res) => {
                                        setVisible(true);

                                        setLoading(true);
                                        onChange({
                                            _id: res.data._id,
                                            name: res.data.name
                                        });
                                        setTimeout(() => {
                                            refreshDevelopments();
                                            setLoading(false);
                                            setVisible(false);
                                        }, 1000);
                                    });
                            }}
                        >
                            {`${t('create_development', 'Crear desarrollo')}: ${searchDevelopment}`}
                        </Button>
                    ) : null
                }
            />
        </div>
    );
};

export const AddressComponentSelect = (props) => {
    return (
        <PulppoSelect
            placeholder={props?.placeholder}
            className={twMerge('w-full py-2', props?.className)}
            value={props.value?.id}
            onSelect={({ value, label }) => {
                props.onChange({ id: value, name: label });
            }}
            disabled={!props.options?.length || props.disabled}
            options={props.options
                ?.map((state) => ({
                    label: state.name,
                    key: state.id,
                    value: state.id
                }))
                .sort((a, b) => a.label.localeCompare(b.label))}
            allowSearch
        />
    );
};
