import { IVisit } from '@pulppo/pulppo-models';
import { PulppoDrawer } from './PulppoDrawer';
import AvatarUser from './AvatarUser';
import { Button } from 'antd';
import { createRoot } from 'react-dom/client';
import { StarFilled } from '@ant-design/icons';
import useChat from '../../hooks/useChat';
interface VisitReviewProps {
    visit: IVisit;
    visible: boolean;
    onClose: () => void;
}

const VisitReviewDrawer = ({ visit, visible, onClose }: VisitReviewProps) => {
    const setChatContact = useChat((store) => store.setChatContact);

    return (
        <PulppoDrawer
            visible={visible}
            onClose={onClose}
            title={`Review de ${visit?.steps?.[0]?.property?.address?.street || visit?.steps?.[0]?.property?.listing?.title}`}
        >
            <div>
                <div className="w-full border border-solid border-gray-200 p-4">
                    <div className="flex w-full items-start justify-between">
                        <div className="shrink-1">
                            <AvatarUser
                                disabled
                                user={visit?.contact}
                                description="Cliente"
                                titleClassName="font-medium truncate"
                            />
                        </div>
                        <Button
                            type="default"
                            className="hidden h-11 shrink-0 lg:block"
                            onClick={() =>
                                setChatContact({
                                    ...visit.contact,
                                    message: ''
                                })
                            }
                        >
                            Enviar mensaje
                        </Button>
                    </div>
                    <p className="my-3 text-lg">Comentarios:</p>
                    <p className="text-base text-pulppo-status-dark-disabled">
                        {`“${visit?.survey?.data?.comment || 'Sin comentarios'}”
                    `}
                    </p>
                </div>
                <p className="my-4 text-lg font-bold">
                    Calificación general:{' '}
                    <span className="ml-4">
                        {visit?.survey?.data?.avg}
                        <StarFilled className="ml-1 text-pulppo-primary-yellow" />
                    </span>
                </p>
                <div className="flex w-full flex-row flex-wrap">
                    {[
                        { field: 'Expectativa', rate: visit?.survey?.data?.expectation },
                        { field: 'Ubicación', rate: visit?.survey?.data?.location },
                        { field: 'Limpieza', rate: visit?.survey?.data?.cleaning },
                        { field: 'Atención del asesor', rate: visit?.survey?.data?.agent }
                    ].map((elem) => (
                        <div key={`rate_${elem.field}`} className="h-full w-auto p-1">
                            <div className="h-full w-full border border-solid border-gray-200 p-2 py-2">
                                <p className="text-lg font-bold">
                                    {elem.rate}
                                    <span className="ml-1">
                                        <StarFilled className="text-pulppo-primary-yellow" />
                                    </span>
                                </p>
                                <p className="">{elem.field}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="fixed bottom-0 left-0 w-full p-4 pb-8 lg:hidden">
                    <Button
                        className="h-11 w-full"
                        onClick={() =>
                            setChatContact({
                                ...visit.contact,
                                message: ''
                            })
                        }
                    >
                        Enviar mensaje
                    </Button>
                </div>
            </div>
        </PulppoDrawer>
    );
};

export const showVisitReview = (config: Omit<VisitReviewProps, 'visible'>) => {
    const container = document.createDocumentFragment();
    const root = createRoot(container);
    let currentConfig = {
        ...config,
        close,
        visible: true
    } as any;
    function destroy() {
        root.unmount();
    }
    function render(props) {
        setTimeout(() => {
            root.render(<VisitReviewDrawer {...props} />);
        });
    }
    function close() {
        currentConfig = {
            ...currentConfig,
            visible: false,
            afterClose: () => {
                destroy();
            }
        };
        render(currentConfig);
    }

    function update(configUpdate) {
        if (typeof configUpdate === 'function') {
            currentConfig = configUpdate(currentConfig);
        } else {
            currentConfig = {
                ...currentConfig,
                ...configUpdate
            };
        }
        render(currentConfig);
    }

    render(currentConfig);

    return {
        destroy: close,
        update
    };
};

export default VisitReviewDrawer;
