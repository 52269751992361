const capitalize = (str: string) =>
    str
        .split(/\W/gi)
        .map((part) => `${part.charAt(0).toUpperCase()}${part.slice(1)}`)
        .join(' ');

const formatNumber = (num: number) => {
    if (num >= 1e6) {
        return (
            `${Math.floor(num / 1e6)}` +
            '.' +
            (num % 1e6).toString().slice(0, 2) +
            'M'
        );
    } else if (num >= 1e3) {
        return (
            `${Math.floor(num / 1e3)}` +
            '.' +
            (num % 1e3).toString().slice(0, 2) +
            'K'
        );
    } else {
        return num && num.toPrecision(2);
    }
};

export { capitalize, formatNumber };
