import { IProperty } from '@pulppo/pulppo-models';
import dayjs from 'dayjs';
import useSWR from 'swr';
import { api_url, fetcher } from '../../helpers/fetcher';
import { parsePrice } from '../../utils/parser';
import { showValuationDrawer } from '../Valuation/ValuationDrawer';
import useUser from '../../hooks/useUser';
import { isExclusive } from '../../utils/property';
import ExclusiveTag from '../Property/ExclusiveTag';
import { useTranslate } from '@tolgee/react';
import { twMerge } from 'tailwind-merge';

export const PropertyDetailHeader = ({
    property,
    roundAddress = false,
    showValuation = false,
    setProperty = () => {},
    isLoading = false
}: {
    property: IProperty;
    roundAddress?: boolean;
    showValuation?: boolean;
    setProperty?: (property: IProperty) => void;
    isLoading?: boolean;
}) => {
    const user = useUser((store) => store.user);
    const { data: internalId } = useSWR<number>(
        !property?.internalId && `${api_url}/property/${property?._id}/internal`,
        fetcher
    );
    const { t } = useTranslate('common');
    const developmentName = property?.development?.name;
    const propertyNotAvailable = !isLoading && !['published'].includes(property?.status?.last);
    const code = internalId || property?.internalId || property?.portals?.easybroker?.id;
    return (
        <div>
            <div>
                <div className="flex justify-between gap-2">
                    <p className="truncate text-lg font-medium capitalize">
                        {roundAddress
                            ? property?.address?.publicStreet || property?.listing?.title
                            : property?.address?.street || property?.listing?.title || 'Sin dirección'}
                    </p>
                    {code && (
                        <p className="shrink-0 border border-solid border-gray-300 px-2 py-1 text-sm text-pulppo-status-dark-disabled">
                            <span>{code}</span>
                        </p>
                    )}
                </div>
                <p className="h-6 text-base text-pulppo-status-dark-disabled">
                    {property?.address?.neighborhood && property?.address?.neighborhood?.name + ', '}
                    {property?.address?.city && property?.address?.city?.name}
                </p>

                <p className="h-6 text-base text-pulppo-status-dark-disabled">
                    {developmentName?.toLocaleLowerCase() || ''}
                </p>
            </div>
            <div className="flex w-full items-center gap-2">
                <p className="text-lg font-medium">
                    {property?.listing?.price
                        ? `${property?.listing?.price?.currency} ${parsePrice({
                              price: property?.listing?.price?.price,
                              withMathRound: false,
                              startStr: ' '
                          })}`
                        : 'Sin precio'}
                </p>
                {showValuation && (property as any)?.valuation?.result ? (
                    <div
                        onClick={() =>
                            showValuationDrawer({
                                property,
                                setProperty,
                                user
                            })
                        }
                        className={twMerge(
                            'flex min-h-7 cursor-pointer items-center justify-center border border-solid px-2 text-sm',
                            (property as any)?.valuation?.result === 'low'
                                ? 'border-strong-green text-strong-green'
                                : (property as any)?.valuation?.result === 'medium'
                                  ? 'border-strong-yellow text-strong-yellow'
                                  : 'border-strong-red text-strong-red'
                        )}
                    >
                        {`${
                            (property as any)?.valuation?.value
                                ? parsePrice({
                                      price: Math.round((property as any)?.valuation?.value),
                                      startStr: ' '
                                  })
                                : 'Sin precio'
                        } ${process.env.NEXT_PUBLIC_PRIMARY_CURRENCY} / m2`}
                    </div>
                ) : null}
            </div>
            <p className="truncate text-base">{property?.listing?.title || 'Sin titulo'}</p>
            <div className="mt-2 flex w-full gap-2">
                <div className="flex items-center justify-center border border-solid border-gray-300 px-2 text-sm text-pulppo-status-dark-disabled">
                    {`Publicado el ${dayjs(property?.createdAt).tz().format('DD/MM/YYYY')}`}
                </div>
                <div className="flex h-7 items-center justify-center bg-black px-2 text-sm uppercase text-white">
                    {property?.listing?.operation === 'sale' ? t('sale', 'VENTA') : t('rent', 'RENTA')}
                </div>
                {propertyNotAvailable ? (
                    <div className="flex h-7 items-center justify-center bg-notification-red px-2 text-sm text-strong-red">
                        No disponible
                    </div>
                ) : isExclusive(property) ? (
                    <ExclusiveTag isPulppoExclusive={!!property?.contract?.exclusive?.pulppo} />
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};
