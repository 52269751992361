import useSWR from 'swr';
import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { api_url, fetcher } from '../../helpers/fetcher';
import { Skeleton } from 'antd';
import { formatCurrency } from '../../utils/numberFormatter';
import Link from 'next/link';
import { ExclamationCircleFilled } from '@ant-design/icons';

export const Referral = ({ show, onClose }) => {
    const { data: invites } = useSWR(`${api_url}/referral/invites`, fetcher, { fallbackData: 0 });
    const { data: metrics } = useSWR(`${api_url}/referral/metrics`, fetcher, {
        fallbackData: { paid: 0, potential: 0, available: 0 }
    });
    const { data: count, isLoading: referralLoading } = useSWR(`${api_url}/referral/count`, fetcher, {
        fallbackData: 0
    });
    return (
        <PulppoDrawer visible={show} onClose={onClose} title="Referidos">
            <div className="flex h-full flex-col justify-between">
                <div className="">
                    {referralLoading ? (
                        <div className="flex items-start gap-6">
                            <Skeleton className="h-[100px] flex-1 shrink-0" />
                            <Skeleton className="h-[100px] flex-1 shrink-0" />
                            <Skeleton className="h-[100px] flex-1 shrink-0" />
                            <Skeleton className="h-[100px] flex-1 shrink-0" />
                            <Skeleton className="h-[100px] flex-1 shrink-0" />
                        </div>
                    ) : count > 0 ? (
                        <div>
                            {invites > 0 && (
                                <div className="mb-6 flex cursor-pointer items-center gap-2 bg-pulppo-status-light-warning p-3 text-pulppo-status-dark-warning">
                                    <ExclamationCircleFilled className="text-xl" />
                                    <div>
                                        Has enviado {invites} que <b className="font-medium">aún están pendientes</b>
                                    </div>
                                </div>
                            )}
                            <div className="mt-4 grid grid-cols-2 gap-6 lg:mt-0">
                                <div className="flex flex-1 shrink-0 flex-col items-start border border-solid border-gray-100 bg-pulppo-status-light-disabled px-4 py-6">
                                    <h6 className="text-[16px] font-medium">{invites}</h6>
                                    <p className="text-[12px]">Invitaciones pendientes</p>
                                </div>
                                <div className="flex flex-1 shrink-0 flex-col items-start border border-solid border-gray-100 bg-pulppo-status-light-disabled px-4 py-6">
                                    <h6 className="text-[16px] font-medium">{count}</h6>
                                    <p className="text-[12px]">Total de referidos</p>
                                </div>
                                <div className="flex flex-1 shrink-0 flex-col items-start border border-solid border-gray-100 bg-pulppo-status-light-disabled px-4 py-6">
                                    <h6 className="text-[16px] font-medium">
                                        {metrics.available ? formatCurrency(metrics.available) : 0}
                                    </h6>
                                    <p className="text-[12px]">Beneficio disponible</p>
                                </div>
                                <div className="flex flex-1 shrink-0 flex-col items-start border border-solid border-gray-100 bg-pulppo-status-light-disabled px-4 py-6">
                                    <h6 className="text-[16px] font-medium">
                                        {metrics.potential ? formatCurrency(metrics.potential) : 0}
                                    </h6>
                                    <p className="text-[12px]">Beneficio potencial</p>
                                </div>
                                <div className="flex flex-1 shrink-0 flex-col items-start border border-solid border-gray-100 bg-pulppo-status-light-disabled px-4 py-6">
                                    <h6 className="text-[16px] font-medium">
                                        {metrics.paid ? formatCurrency(metrics.paid) : 0}
                                    </h6>
                                    <p className="text-[12px]">Beneficio pagado</p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="flex w-full flex-col rounded">
                            <iframe
                                className="aspect-video w-full rounded"
                                src="https://player.vimeo.com/video/922513869?title=0&byline=0&portrait=0&playsinline=0&autopause=0&app_id=122963"
                            />
                            <div className="mt-8 flex flex-1 flex-col gap-6 overflow-y-scroll">
                                <div className="flex flex-col gap-4">
                                    <h4 className="">¡Amplía la comunidad y gana!</h4>
                                    <p>
                                        Refiere amigos, familiares o colegas que estén interesados en unirse y{' '}
                                        <b>comienza a ganar ingresos adicionales en simples pasos.</b>
                                        <br /> Por cada uno recibirás un porcentaje de la comisión de cada transacción
                                        de venta o renta que generen.
                                    </p>
                                    <p>
                                        Es una oportunidad única para{' '}
                                        <b>
                                            aumentar tus ganancias mientras contribuyes al crecimiento de nuestra red.
                                        </b>{' '}
                                        <br /> ¡Comienza ahora refiriendo!
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <Link href="/referral">
                    <a className="btn btn-primary flex items-center justify-center">
                        {count > 0 ? 'Ver referidos' : 'Comenzar a referir'}
                    </a>
                </Link>
            </div>
        </PulppoDrawer>
    );
};
