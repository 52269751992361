import { ReactNode, useEffect, useRef, useState } from 'react';
import { Skeleton } from 'antd';
import { twMerge } from 'tailwind-merge';
import Badge, { BadgeProps } from './Badge';
import Avatar, { AvatarProps } from './Avatar';
import AvatarUser, { AvatarUserProps } from './AvatarUser';
import { AvatarProperty, AvatarPropertyProps } from '../Property/AvatarProperty';
import { CaretDownOutlined, CaretUpOutlined, RightOutlined } from '@ant-design/icons';
import EmptyState from './EmptyState';

type ColumnAvatar =
    | {
          avatarType: 'property';
          data: AvatarPropertyProps;
      }
    | {
          avatarType: 'free';
          data: AvatarProps;
      }
    | {
          avatarType: 'user';
          data: AvatarUserProps;
      };

interface ColumnDefault {
    value: string | number | ReactNode;
    description?: string;
    className?: string;
    classNameDescription?: string;
}

interface ColumnBadge extends BadgeProps {}

interface ColumnAction {
    action: () => void;
}

interface Props {
    columns: Array<{
        key: string;
        name: string;
        hiddenMobile?: boolean;
        sorter?: () => void;
    }>;
    dataSource: Array<{
        [key: string]:
            | ({
                  type: TypeColumn.Default;
              } & ColumnDefault)
            | ({
                  type: TypeColumn.Avatar;
              } & ColumnAvatar)
            | ({
                  type: TypeColumn.Badge;
              } & ColumnBadge)
            | ({
                  type: TypeColumn.MultiBadge;
              } & {
                  badges: Array<ColumnBadge>;
              })
            | ({
                  type: TypeColumn.Action;
              } & ColumnAction);
    }>;
    isLoading?: boolean;
    rowCount?: number;
    style?: {
        header?: {
            sticky?: boolean;
        };
    };
    className?: {
        div?: {
            scrolled?: string;
        };
        th?: string;
        trBody?: string;
        td?: string;
    };
    emptyState?: {
        title?: string;
        description?: string;
        imageSrc?: string;
    };
    showButtonScrollRight?: boolean;
}

export enum TypeColumn {
    Default,
    Avatar,
    Badge,
    MultiBadge,
    Action
}

const ColumnWithAvatar = (props: ColumnAvatar) => {
    if (props.avatarType === 'user') {
        return <AvatarUser {...props.data} />;
    } else if (props.avatarType === 'property') {
        return <AvatarProperty {...props.data} />;
    }
    return <Avatar {...props.data} />;
};

const ColumnWithBadge = (props: ColumnBadge) => {
    return <Badge {...props} />;
};

export const Table = ({
    columns,
    dataSource,
    isLoading,
    rowCount,
    className,
    style,
    emptyState,
    showButtonScrollRight
}: Props) => {
    const [showButton, setShowButton] = useState(false);
    const scrolledContainerRef = useRef(null);

    useEffect(() => {
        const element = scrolledContainerRef.current;
        if (element) {
            const checkScroll = () => {
                if (element) {
                    const hasHorizontalScroll = element.scrollLeft < element.scrollWidth - element.clientWidth;
                    setShowButton(hasHorizontalScroll);
                }
            };

            checkScroll();
            if (showButtonScrollRight) {
                element.addEventListener('scroll', checkScroll);
            }

            return () => {
                element.removeEventListener('scroll', checkScroll);
            };
        }
    }, [showButtonScrollRight, dataSource]);

    const scrollToEnd = () => {
        const element = scrolledContainerRef.current;
        if (element) {
            element.scrollTo({
                left: element.scrollWidth,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div className="flow-root">
            <div ref={scrolledContainerRef} className={twMerge('-my-2 overflow-x-auto', className?.div?.scrolled)}>
                <div className="inline-block min-w-full py-2 align-middle">
                    <table className="min-w-full divide-y divide-solid divide-pulppo-status-light-disabled">
                        <thead>
                            <tr>
                                {columns.map((column, index) => {
                                    let classNameByPosition: string;
                                    if (index === 0) {
                                        classNameByPosition = 'py-3.5 pl-4 pr-3 text-left';
                                    } else if (index === columns.length - 1) {
                                        classNameByPosition = 'relative py-3.5 pl-3 pr-4 text-center';
                                    } else {
                                        classNameByPosition = 'px-3 py-3.5 text-left';
                                    }
                                    let element: string | ReactNode = column.name;
                                    if (column.sorter) {
                                        element = (
                                            <button
                                                type="button"
                                                onClick={column.sorter}
                                                className="flex items-center justify-between"
                                            >
                                                {column.name}
                                                <span className="ml-1 flex flex-col items-center justify-center rounded text-pulppo-primary-gray group-hover:bg-gray-100">
                                                    <CaretUpOutlined />
                                                    <CaretDownOutlined />
                                                </span>
                                            </button>
                                        );
                                    }
                                    return (
                                        <th
                                            scope="col"
                                            className={twMerge(
                                                'group font-mark text-sm font-normal capitalize tracking-wide text-pulppo-status-dark-disabled',
                                                classNameByPosition,
                                                column.hiddenMobile && 'hidden lg:table-cell',
                                                style?.header?.sticky &&
                                                    'sticky top-0 z-10 bg-white bg-opacity-75 backdrop-blur backdrop-filter',
                                                className?.th
                                            )}
                                            key={`column-${column.key}`}
                                        >
                                            {element}
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-solid divide-pulppo-status-light-disabled bg-white">
                            {isLoading ? (
                                <>
                                    {[...Array(rowCount || 1).keys()].map((value) => {
                                        return (
                                            <tr key={`table-row-loader-${value}`}>
                                                <td colSpan={100} className="px-2 pt-2">
                                                    <Skeleton
                                                        active
                                                        paragraph={false}
                                                        title={{
                                                            className: 'h-20'
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </>
                            ) : (
                                <>
                                    {dataSource.map((data, index) => {
                                        const dataEntries = Object.entries(data);
                                        const actionFind = dataEntries.find(
                                            ([_, value]) => value.type === TypeColumn.Action
                                        );
                                        return (
                                            <tr
                                                key={`row-table-${index}`}
                                                className={twMerge(className?.trBody)}
                                                onClick={
                                                    actionFind
                                                        ? () => {
                                                              (actionFind[1] as ColumnAction).action();
                                                          }
                                                        : null
                                                }
                                            >
                                                {dataEntries
                                                    .filter(([key]) => columns.some((column) => column.key === key))
                                                    .sort(([key]) => columns.findIndex((column) => column.key === key))
                                                    .map(([key, value], index) => {
                                                        if (value.type === TypeColumn.Action) {
                                                            return null;
                                                        }
                                                        let classNameLocal: string;
                                                        let children: ReactNode = null;
                                                        if (index === 0) {
                                                            classNameLocal = 'py-3 pl-4 pr-3';
                                                        } else if (index === dataEntries.length - 1) {
                                                            classNameLocal =
                                                                'relative py-3 pl-3 pr-4 text-center font-medium';
                                                        } else {
                                                            classNameLocal = 'p-3';
                                                        }
                                                        switch (value.type) {
                                                            case TypeColumn.Default:
                                                                children = (
                                                                    <div>
                                                                        <div
                                                                            className={twMerge(
                                                                                twMerge('truncate', value?.className)
                                                                            )}
                                                                        >
                                                                            {value.value}
                                                                        </div>
                                                                        {value?.description ? (
                                                                            <div
                                                                                className={twMerge(
                                                                                    'text-sm text-pulppo-primary-gray',
                                                                                    value?.classNameDescription
                                                                                )}
                                                                            >
                                                                                {value.description}
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                );
                                                                break;
                                                            case TypeColumn.Avatar:
                                                                children = <ColumnWithAvatar {...value} />;
                                                                break;
                                                            case TypeColumn.Badge:
                                                                children = <ColumnWithBadge {...value} />;
                                                                break;
                                                            case TypeColumn.MultiBadge:
                                                                children = (
                                                                    <div className="flex flex-col gap-2">
                                                                        {value.badges.map((badge, index) => (
                                                                            <ColumnWithBadge
                                                                                key={`badge-${index}`}
                                                                                {...badge}
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                );
                                                                break;
                                                        }
                                                        return (
                                                            <td
                                                                className={twMerge(
                                                                    'whitespace-nowrap text-base',
                                                                    classNameLocal,
                                                                    className?.td,
                                                                    columns[index].hiddenMobile &&
                                                                        'hidden lg:table-cell'
                                                                )}
                                                                key={`column-${key}`}
                                                            >
                                                                <div className="text-sm">{children}</div>
                                                            </td>
                                                        );
                                                    })}
                                            </tr>
                                        );
                                    })}
                                </>
                            )}
                        </tbody>
                    </table>
                    {dataSource.length === 0 && !isLoading ? (
                        <EmptyState
                            title="No hay datos"
                            description="No se encontraron datos para mostrar"
                            {...emptyState}
                        />
                    ) : null}
                </div>
            </div>
            {showButtonScrollRight && showButton ? (
                <button
                    onClick={scrollToEnd}
                    className="absolute right-0 top-1/2 hidden h-8 w-8 -translate-y-1/2 items-center justify-center rounded-full bg-primary-black md:flex"
                >
                    <RightOutlined className="text-lg text-white" />
                </button>
            ) : null}
        </div>
    );
};

export default Table;
