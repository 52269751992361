import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';

export const SimpleACMLoader = ({ loading }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const imagesAndMessages = [
        {
            image: '/loaders/acm_1.png',
            message: 'Buscando en el MLS propiedades similares...'
        },
        {
            image: '/loaders/acm_2.png',
            message: 'Calculando el mejor precio para la propiedad...'
        },
        {
            image: '/loaders/acm_3.png',
            message: 'Analizando las tendencias del mercado inmobiliario...'
        },
        {
            image: '/loaders/acm_4.png',
            message: 'Generando informe detallado de la propiedad...'
        },
        {
            image: '/loaders/acm_5.png',
            message: 'Preparando presentación personalizada para el cliente...'
        }
    ];

    useEffect(() => {
        let interval = null;

        if (loading) {
            interval = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % imagesAndMessages.length);
            }, 1e3);
        }

        return () => {
            clearInterval(interval);
        };
    }, [loading, imagesAndMessages.length]);

    return (
        <Modal open={loading} footer={null} closable={false} wrapClassName="loader-modal" centered>
            <div className="loader-content">
                <div className="loader-image-container">
                    {imagesAndMessages.map((item, index) => (
                        <div key={index} className="loader-image flex items-center justify-center">
                            <img
                                src={item.image}
                                alt="Loading"
                                className={`${
                                    index === currentIndex ? 'opacity-100' : 'opacity-0'
                                } mx-auto  object-contain transition-opacity duration-500 ease-in-out`}
                            />
                        </div>
                    ))}
                </div>
                <p className="loader-message h-[30px]">{imagesAndMessages[currentIndex].message}</p>
            </div>
        </Modal>
    );
};

export default SimpleACMLoader;
