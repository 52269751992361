import React from 'react';
export const IconFilters = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="15" height="15" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M13.6784 5.09363C14.3823 5.09363 14.9351 4.51078 14.9351 3.81412C14.9351 3.11744 14.3823 2.53463 13.6784 2.53463C12.9745 2.53463 12.4218 3.11747 12.4218 3.81412C12.4218 4.51074 12.9745 5.09363 13.6784 5.09363Z" fill="#212322" stroke="#212322" stroke-width="0.8"/>
        <path d="M6.54753 11.2643C7.25142 11.2643 7.8042 10.6814 7.8042 9.98477C7.8042 9.28809 7.2514 8.70529 6.54753 8.70529C5.84362 8.70529 5.29092 9.28813 5.29092 9.98477C5.29092 10.6814 5.8436 11.2643 6.54753 11.2643Z" fill="#212322" stroke="#212322" stroke-width="0.8"/>
        <path d="M13.6784 17.5745C14.3823 17.5745 14.9351 16.9917 14.9351 16.295C14.9351 15.5983 14.3823 15.0155 13.6784 15.0155C12.9745 15.0155 12.4218 15.5984 12.4218 16.295C12.4218 16.9916 12.9745 17.5745 13.6784 17.5745Z" fill="#212322" stroke="#212322" stroke-width="0.8"/>
        <path d="M2.67725 3.76459H16.9147" stroke="#212322" stroke-linecap="round"/>
        <path d="M2.67725 9.91968H16.9147" stroke="#212322" stroke-linecap="round"/>
        <path d="M2.67725 16.295H16.9147" stroke="#212322" stroke-linecap="round"/>
        </svg>        
    )
}