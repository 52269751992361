import { IProperty, IVideo } from '@pulppo/pulppo-models';
import { Typography, Button as ButtonAnt, Input, Form } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import { TaskView } from '../../Elements/TaskView';
import { EyeOutlined, GlobalOutlined, LinkOutlined, PlaySquareOutlined, TableOutlined } from '@ant-design/icons';
import { FileUpload } from '../../Elements/FileUpload';
import { VideoRow } from '../../PropertyDetail/PropertyExtraMultimedia';
import Button from '../../Elements/Button';
import { twMerge } from 'tailwind-merge';

export const PropertyStep3 = ({
    property: initialProperty,
    simple
}: {
    property: Partial<IProperty>;
    simple?: boolean;
}) => {
    const [property, setProperty] = useState(initialProperty);
    const [input, setInput] = useState(initialProperty?.virtualTour || '');

    useEffect(() => {
        if (initialProperty) {
            setProperty(initialProperty);
        }
    }, [initialProperty]);

    const hasProvider = !!process.env.NEXT_PUBLIC_IMAGE_PROVIDER_URL;

    return (
        <div className="@container">
            <div className="bg-white md:bg-transparent">
                <Form.Item
                    rules={[
                        {
                            async validator(rule, value) {
                                if (value.length < 4) {
                                    throw new Error(`${rule.message}`);
                                }
                                return true;
                            },
                            message:
                                'Se necesitan al menos 12 fotos y planos para propiedades residenciales y 4 para comerciales.'
                        }
                    ]}
                    name="pictures"
                >
                    <PictureInput property={property} simple={simple} input={input} setInput={setInput} />
                </Form.Item>
                {hasProvider ? (
                    <div className="mx-auto mt-4 w-full max-w-lg @lg:flex @lg:justify-center">
                        <a href={process.env.NEXT_PUBLIC_IMAGE_PROVIDER_URL} target="_blank" rel="noreferrer">
                            <img src={process.env.NEXT_PUBLIC_IMAGE_PROVIDER_BANNER} alt="bg vioo" />
                        </a>
                    </div>
                ) : null}
                {simple ? null : (
                    <TaskView
                        className={simple ? 'my-0 border-none p-0 md:my-0 md:p-0' : ''}
                        title={simple ? '' : 'Otros'}
                    >
                        <InputUrls simple={simple} input={input} setInput={setInput} />
                    </TaskView>
                )}
            </div>
        </div>
    );
};

const InputUrls = ({
    simple,
    input,
    setInput
}: {
    simple?: boolean;
    input?: string;
    setInput?: (val: string) => void;
}) => {
    return (
        <>
            <div className="gap-x-4 @lg:flex">
                <div className="flex items-center">
                    <Typography.Paragraph className={`font-medium ${simple && 'mb-1'}`}>
                        {simple ? null : <PlaySquareOutlined className="mr-4" />}
                        Videos
                    </Typography.Paragraph>
                </div>
                <div className="flex-grow">
                    <Form.Item name="videos">
                        <VideoInput />
                    </Form.Item>
                </div>
            </div>
            <div className="gap-x-4 @lg:flex">
                <div className="flex items-center">
                    <Typography.Paragraph className={`font-medium ${simple && 'mb-1'}`}>
                        {simple ? null : <GlobalOutlined className="mr-4" />}
                        Matterport
                    </Typography.Paragraph>
                </div>
                <div className="flex flex-grow">
                    <Form.Item className="w-full" name="virtualTour">
                        <Input
                            placeholder="Inserte la liga de Matterport"
                            className="rounded-none"
                            onChange={(val) => setInput(val.currentTarget?.value)}
                            suffix={<LinkOutlined className="text-lg text-secondary-gray-disabled" />}
                        />
                    </Form.Item>
                    <a href={input} target="_blank" rel="noreferrer">
                        <ButtonAnt type="link" icon={<EyeOutlined className="text-primary-gray" />}></ButtonAnt>
                    </a>
                </div>
            </div>
        </>
    );
};

export const FileUploadInput = ({
    title,
    icon,
    value,
    onChange,
    propertyId,
    isBlueprint,
    text,
    description,
    imgSrc,
    innerDescription,
    buttonText,
    className,
    type = 'image',
    multiple = true,
    onGetMetadata
}: {
    title?: string;
    icon?: ReactNode;
    value: Array<any>;
    onChange: (pics: any) => void;
    propertyId?: string;
    isBlueprint?: boolean;
    text: string;
    description?: string;
    imgSrc: string;
    innerDescription?: ReactNode;
    buttonText: string;
    className?: {
        container?: string;
    };
    type?: 'image' | 'file';
    multiple?: boolean;
    onGetMetadata?: (metadata?: {
        gps: {
            latitude?: number;
            longitude?: number;
        };
    }) => void;
}) => {
    const IMAGE_ACCEPT = '.png, .jpg, .jpeg, .heic';
    let accept = '';
    if (type === 'image') {
        accept = IMAGE_ACCEPT;
    } else if (type === 'file') {
        accept = `.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .csv, ${IMAGE_ACCEPT}`;
    }
    return (
        <FileUpload
            multiple={multiple}
            propertyId={propertyId}
            accept={accept}
            minSize={756}
            title={title}
            icon={icon}
            is_blueprint={isBlueprint}
            pictures={value}
            setPictures={(pics) => {
                onChange(pics);
            }}
            prefix={`property/${propertyId}`}
            containerClassName="mb-[20px] md:mb-0"
            classNameTitle="text-sm"
            text={text}
            description={description}
            type={type}
            onGetMetadata={onGetMetadata}
        >
            <div className={twMerge('px-4 pt-4', className?.container)}>
                <img className="mx-auto h-[63px] w-[63px]" src={imgSrc} alt="upload" />
                {text}
                {innerDescription}
                <Button type="primary" className="mt-4 w-full max-w-md">
                    {buttonText}
                </Button>
            </div>
        </FileUpload>
    );
};

export const VideoInput = ({ value: videos = [], onChange = () => {} }) => {
    return (
        <div className="w-full">
            {videos?.length > 0 &&
                videos.map((val: IVideo & { _id: string }, index) => (
                    <VideoRow
                        onChange={onChange}
                        videos={videos}
                        key={val._id || `video_${val.url}`}
                        index={index}
                        editable={true}
                        video={val}
                    />
                ))}
            <VideoRow
                onChange={onChange}
                videos={videos}
                key="new_video"
                index={videos.length}
                editable={true}
                isNew={true}
            />
        </div>
    );
};

const PictureInput = ({
    value = [],
    onChange = (_: Array<any>) => {},
    property,
    simple = false,
    input,
    setInput
}: {
    value?: Array<any>;
    onChange?: (pics: any) => void;
    property?: Partial<IProperty>;
    simple?: boolean;
    input?: string;
    setInput?: (val: string) => void;
}) => {
    const [pictures, setPictures] = useState(value.filter((pic) => !pic.is_blueprint));
    const [planes, setPlanes] = useState(value.filter((pic) => pic.is_blueprint));

    useEffect(() => {
        setPictures(value.filter((pic) => !pic.is_blueprint));
        setPlanes(value.filter((pic) => pic.is_blueprint));
    }, [value]);

    return (
        <>
            <TaskView className={simple ? 'my-0 border-none p-0 md:my-0 md:p-0' : undefined}>
                <FileUploadInput
                    title="Fotografías"
                    value={pictures}
                    onChange={(pics) => {
                        onChange([...pics, ...planes]);
                        setPictures(pics);
                    }}
                    propertyId={`${property?._id}`}
                    text="Clic aquí o arrastre las fotografías"
                    description="Asegúrate de darles un orden prioritario a las mejores fotografías presionando en ellas y arrastrándolas para lograr el orden deseado."
                    imgSrc="/icons/image-gallery.svg"
                    innerDescription={
                        <ul className="mx-auto mt-2 max-w-sm list-disc px-4 text-left text-primary-gray">
                            <li>12 imágenes para propiedades residenciales</li>
                            <li>5 para comerciales.</li>
                            <li>Las fotos deben estar a 72 DPI,</li>
                            <li>Tamaño mínimo de 1640x1080 píxeles</li>
                            <li>Formato JPG o PNG.</li>
                        </ul>
                    }
                    buttonText="Subir fotografías"
                />
            </TaskView>
            {simple ? <InputUrls simple={simple} input={input} setInput={setInput} /> : null}
            <TaskView
                className={simple ? 'mt-4 border-none p-0 md:mt-4 md:p-0' : undefined}
                classNameTitle={simple ? 'text-sm' : undefined}
                title={
                    simple ? (
                        'Planos'
                    ) : (
                        <>
                            <TableOutlined className="mr-4" />
                            Planos
                        </>
                    )
                }
            >
                <FileUploadInput
                    value={planes}
                    onChange={(pics) => {
                        onChange([...pictures, ...pics]);
                        setPlanes(pics);
                    }}
                    propertyId={`${property?._id}`}
                    isBlueprint
                    text="Clic aquí o arrastre los planos"
                    description="Sube los planos de la propiedad que poseas y asegúrate de que los mismos tenga una buena calidad y legibilidad."
                    imgSrc="/icons/blueprint.svg"
                    innerDescription={
                        <p className="mt-2 text-primary-gray">El tamaño del archivo no debe superar los 30MB.</p>
                    }
                    buttonText="Subir planos"
                />
            </TaskView>
        </>
    );
};
