import Button from '../Elements/Button';
import { LoadingButton } from '../Elements/ButtonLoading';

interface Props {
    onPrevious?: () => void;
    onFinish?: () => Promise<void> | void;
}

const ButtonBottomStep = ({ onPrevious, onFinish }: Props) => {
    return (
        <div className="flex w-full items-center gap-x-4 pt-2 lg:justify-end">
            {onPrevious ? (
                <Button className="w-full lg:w-auto" type="secondary" onClick={onPrevious}>
                    Volver un paso atrás
                </Button>
            ) : null}
            {onFinish ? (
                <LoadingButton onClick={onFinish} type="primary" className="h-10 w-full lg:w-auto">
                    Continuar
                </LoadingButton>
            ) : null}
        </div>
    );
};

export default ButtonBottomStep;
