import { IVisit } from '@pulppo/pulppo-models';
import dayjs from 'dayjs';
import { VisitCard } from '../../IntelliHome/Visits';
import { EmptyStateType } from '../EmptyState';

const VisitList = ({
    visits,
    refreshVisits,
    closeParent = () => {},
    loading = false
}: {
    visits: Array<IVisit>;
    refreshVisits: () => void;
    closeParent?: () => void;
    loading?: boolean;
}) => {
    return (
        <div className="px-4">
            {(!visits || visits?.length === 0) && !loading && <EmptyStateType type="visit" />}
            {visits?.length > 0 && (
                <div className="flex w-full flex-col items-center justify-center gap-2">
                    {visits
                        ?.filter((visit) => visit.status?.last === 'pending')
                        .sort((a, b) => dayjs(a.startTime)?.valueOf() - dayjs(b.startTime)?.valueOf())
                        .map((visit) => (
                            <VisitCard
                                closeParent={closeParent}
                                visit={visit}
                                key={visit?._id?.toString()}
                                onSave={() => refreshVisits()}
                                onCancel={() => refreshVisits()}
                            />
                        ))}
                    {visits
                        ?.filter(
                            (visit) => visit.status?.last === 'confirmed' && dayjs(visit?.startTime).isAfter(dayjs())
                        )
                        .sort((a, b) => dayjs(a.startTime)?.valueOf() - dayjs(b.startTime)?.valueOf())
                        .map((visit) => (
                            <VisitCard
                                visit={visit}
                                closeParent={closeParent}
                                key={visit?._id?.toString()}
                                onSave={() => refreshVisits()}
                                onCancel={() => refreshVisits()}
                            />
                        ))}
                    {visits
                        ?.filter(
                            (visit) =>
                                visit.status?.last === 'completed' ||
                                (visit.status?.last === 'confirmed' && dayjs(visit?.startTime).isBefore(dayjs()))
                        )
                        .sort((a, b) => dayjs(b.startTime)?.valueOf() - dayjs(a.startTime)?.valueOf())
                        .map((visit) => (
                            <VisitCard
                                visit={visit}
                                closeParent={closeParent}
                                key={visit?._id?.toString()}
                                onSave={() => refreshVisits()}
                                onCancel={() => refreshVisits()}
                            />
                        ))}
                    {visits
                        ?.filter((visit) => visit.status?.last === 'cancelled')
                        .sort((a, b) => dayjs(b.startTime)?.valueOf() - dayjs(a.startTime)?.valueOf())
                        .map((visit) => (
                            <VisitCard
                                visit={visit}
                                closeParent={closeParent}
                                key={visit?._id?.toString()}
                                onSave={() => refreshVisits()}
                                onCancel={() => refreshVisits()}
                            />
                        ))}
                </div>
            )}
        </div>
    );
};

export default VisitList;
