import Image from "next/image";
import { Tag } from 'antd';
import { IOperation, ISimpleProperty } from "@pulppo/pulppo-models";
import { useState } from "react";
import PropertyDetail from "../PropertyDetail/PropertyDetail";
import { useTranslate } from "@tolgee/react";

interface OperationCompletedPostProps {
    image?: string;
    property?: ISimpleProperty;
}

export const OperationCompletedPost: React.FC<OperationCompletedPostProps> = ({
  image,
  property: simpleProperty
}) => {
    const { t } = useTranslate();
    const [showPropertyModal, setShowPropertyModal] = useState<boolean>(false);

    console.log(simpleProperty.address);
  return (
    <div className="w-full max-w-3xl border-b border-gray-300 pb-3">
      <div className="px-4 pt-2">

        <div className="flex gap-4 items-start cursor-pointer border border-solid border-gray-300 rounded-md p-2" onClick={() => {
          setShowPropertyModal(true);
        }}>
          <div className="relative w-16 h-16 flex-shrink-0 rounded-md overflow-hidden">
            <Image
              src={image || "/placeholder.svg"}
              alt={simpleProperty?.address.name || ""}
              layout="fill"
              objectFit="cover"
            />
          </div>

          <div className="flex-1">
            <div className="text-sm font-medium text-black">{simpleProperty?.address.name}</div>
            <div className="text-xs text-gray-600">
              {simpleProperty?.address?.state?.name ? `${simpleProperty?.address?.state?.name}, ` : ""}
              {simpleProperty?.address?.neighborhood?.name ? `${simpleProperty?.address?.neighborhood?.name}` : ""}
            </div>
          </div>

          <div className="text-right flex flex-col items-end">
            <div className="text-md font-semibold text-black">{simpleProperty?.listing.price?.currency} {simpleProperty?.listing.price?.price}</div>
            <div className="bg-gray-900 text-white text-sm rounded-sm px-4 py-1 mt-2">
              {simpleProperty?.listing.operation === 'sale' ? 'Venta' : t('Renta')}
            </div>
          </div>
        </div>
      </div>
      { simpleProperty && <PropertyDetail property={simpleProperty} visible={showPropertyModal} onClose={() => setShowPropertyModal(false)} />}
    </div>
  );
};

