import useSWR from 'swr';
import { api_url, fetcher } from '../helpers/fetcher';
import { Currency } from '@pulppo/pulppo-models/build/@types/pulppo-models/enums/enums';

export const useCurrencyConverter = () => {
    const { data: converter } = useSWR<{ [key: string]: number }>(`${api_url}/currency`, fetcher, {
        fallbackData: { MXN: 1 / 20, USD: 1, COP: 0.00026 }
    });

    const currencyConverter = (from: Currency, to: Currency, amount: number): number => {
        if (!converter[from] || !converter[to]) return amount;
        return (amount * converter[from]) / converter[to];
    };

    return { currencyConverter };
};
