import { Orientations } from '@pulppo/pulppo-models/build/enums/Orientations';
import { PropertyConditions } from '@pulppo/pulppo-models/build/enums/PropertyConditions';
import { NewPropertyTypes } from '@pulppo/pulppo-models/build/enums/PropertyTypes';
import {
    Form,
    Row,
    Col,
    Select,
    Typography,
    Button as ButtonAnt,
    Avatar,
    Input,
    Divider,
    InputNumber as InputNumberAnt
} from 'antd';
import axios from '../../../utils/axios';
import { useState, useEffect, useContext, useRef } from 'react';
import { ExpandableCheckboxGroup } from '../../Elements/ExpandableCheckboxGroup';
import { ITaskViewProps, TaskView } from '../../Elements/TaskView';
import { PropertyLocation } from '../../PropertyDetail/PropertyLocation';
import { api_url } from '../../../helpers/fetcher';
import { Services } from '@pulppo/pulppo-models/build/enums/Services';
import { useRouter } from 'next/router';
import { ShowContactList } from '../../Contact/SimpleContactList';
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { AttributeMapper } from '../../../utils/AttributeMapper';
import { PulppoSelect } from '../../Elements/PulppoSelect';
import { NextImage } from '../../Elements/NextImage';
import { PropertyFormContext } from '../../../contexts/PropertyFormContext';
import DatePicker from '../../Elements/DatePicker';
import { useTranslate } from '@tolgee/react';
import { IProperty } from '@pulppo/pulppo-models';
import Button from '../../Elements/Button';
import { showSpaces } from '../../../utils/config';

const FormItem = ({ property_type, ...props }) => {
    const attribute = Array.isArray(props.name) ? props.name[props.name.length - 1] : props.name;
    if (AttributeMapper[attribute]?.[property_type]) {
        return (
            <Col {...props.wrapperCol}>
                <Form.Item
                    {...props}
                    wrapperCol={null}
                    rules={
                        AttributeMapper[attribute]?.[property_type] == 'required'
                            ? [
                                  {
                                      required: true,
                                      message: 'Falta ingresar ' + props.label
                                  }
                              ]
                            : []
                    }
                >
                    {props.children}
                </Form.Item>
            </Col>
        );
    }
    return null;
};

const InputNumber = (props) => {
    return (
        <InputNumberAnt
            value={
                props.value
                    ? typeof props.value == 'string'
                        ? +props.value.replace(/\D/gi, '').toLocaleString()
                        : props.value.toLocaleString()
                    : ''
            }
            onChange={(e) => props.onChange?.(+(e as string).replace(/\D/gi, ''))}
            inputMode="numeric"
            className="w-full rounded-none"
            controls={false}
            {...props}
        />
    );
};
export const advancedFields = [
    {
        type: 'input',
        id: 'catastral',
        label: 'Clave Catastral',
        path: ['catastral']
    },
    {
        type: 'select',
        id: 'ampliation',
        label: 'Tiene ampliación',
        options: [
            { label: 'Si', value: true },
            { label: 'No', value: false }
        ],
        path: ['attributes', 'ampliation']
    },
    {
        type: 'select',
        id: 'kitchen',
        label: 'Cocina',
        options: ['Sin Cocina', 'Integral', 'Cocineta', 'Solo Tarja'],
        condition: (p) => !p.type.includes('Terreno')
    },
    {
        type: 'number',
        id: 'sprinkler',
        label: 'Regaderas',
        condition: (p) => p.type != 'Departamento'
    },
    {
        type: 'select',
        id: 'terrainType',
        label: 'Tipo de terreno',
        options: ['Regular', 'Irregular'],
        condition: (p) => p.type != 'Departamento'
    },
    {
        type: 'number',
        id: 'frontMeters',
        label: 'Metros de frente'
    },
    {
        type: 'number',
        id: 'bottomMeters',
        label: 'Metros de fondo'
    },
    {
        type: 'input',
        id: 'floorUsage',
        label: 'Uso de suelo'
    },
    {
        type: 'input',
        id: 'buildingClasification',
        label: 'Clasificación del edificio',
        condition: (p) => p.type == 'Departamento'
    },
    {
        type: 'number',
        id: 'elevatorAmount',
        label: 'Cantidad de Elevadores',
        condition: (p) => p.type == 'Departamento'
    },
    {
        type: 'input',
        id: 'floorType',
        label: 'Tipo de piso',
        condition: (p) => p.type == 'Departamento'
    },
    {
        type: 'date',
        id: 'remodelationDate',
        label: 'Fecha de remodelación'
    },
    {
        type: 'number',
        id: 'floorsConstructed',
        label: 'Pisos construídos',
        condition: (p) => !p.type.includes('Terreno')
    },
    {
        type: 'number',
        id: 'houseAmount',
        label: 'Número de casas'
    },
    {
        type: 'select',
        id: 'constructionQuality',
        label: 'Calidad de construcción',
        options: ['Precaria', 'Baja', 'Económica', 'Media', 'Media Alta', 'Alta', 'Lujo', 'Inteligente']
    },
    {
        type: 'number',
        id: 'officeArea',
        label: 'Área de oficinas'
    },
    {
        type: 'number',
        id: 'officesAmount',
        label: 'Cantidad de locales/oficinas'
    },
    {
        type: 'input',
        id: 'distanceToFrontier',
        label: 'Distancia al cruce fronterizo'
    },
    {
        type: 'input',
        id: 'ocupationStatus',
        label: 'Estado de ocupación'
    },

    {
        type: 'input',
        id: 'productivePropertyType',
        label: 'Tipo de inmueble productivo'
    },
    {
        type: 'textarea',
        id: 'howToArrive',
        label: 'Cómo llegar'
    },
    {
        type: 'divider',
        id: 'construction_divider',
        label: 'Construcción'
    },

    {
        type: 'input',
        id: 'constructionStyle',
        label: 'Estilo de construcción'
    },
    {
        type: 'input',
        id: 'constructionStatus',
        label: 'Estado de construcción'
    },
    {
        type: 'number',
        id: 'rentConstructionArea',
        label: 'Área de construcción rentable'
    },

    {
        type: 'number',
        id: 'surfacePerFloor',
        label: 'Metros por piso'
    },
    {
        type: 'number',
        id: 'constructionYear',
        label: 'Año de construcción'
    },
    {
        type: 'number',
        id: 'remodelationYear',
        label: 'Año de remodelación'
    },
    {
        type: 'number',
        id: 'rentAmount',
        label: 'Cantidad de arrendatarios'
    },
    {
        type: 'select',
        id: 'class',
        label: 'Clase',
        options: ['A', 'B', 'C']
    },

    {
        type: 'number',
        id: 'alturaLosaALosa',
        label: 'Altura losa a losa'
    },

    {
        type: 'number',
        id: 'columnWidth',
        label: 'Ancho de columnas'
    },
    {
        type: 'number',
        id: 'columnDepth',
        label: 'Profundidad de columnas'
    },
    {
        type: 'number',
        id: 'widthBetweenColumns',
        label: 'Ancho entre columnas'
    },
    {
        type: 'number',
        id: 'depthBetweenColumns',
        label: 'Largo entre columnas'
    },
    {
        type: 'input',
        id: 'finishings',
        label: 'Acabados'
    },
    {
        type: 'input',
        id: 'windows',
        label: 'Ventanas'
    },
    {
        type: 'input',
        id: 'doorsAndClosets',
        label: 'Puertas y closets'
    },

    {
        type: 'input',
        id: 'buildingType',
        label: 'Tipo de edificio'
    },
    {
        type: 'number',
        id: 'minHeight',
        label: 'Altura mínima'
    },
    {
        type: 'number',
        id: 'maxHeight',
        label: 'Altura máxima'
    },
    {
        type: 'number',
        id: 'patioSurface',
        label: 'm2 de patio'
    },
    {
        type: 'number',
        id: 'privateSurface',
        label: 'm2 privados'
    },
    {
        type: 'number',
        id: 'officesSurface',
        label: 'm2 oficinas'
    },
    {
        type: 'number',
        id: 'manouverSurface',
        label: 'm2 Patio de maniobra'
    },
    {
        type: 'number',
        id: 'metersPerFloor',
        label: 'm2 por planta'
    },
    {
        type: 'number',
        id: 'commonBathrooms',
        label: 'Baños comunes'
    },
    {
        type: 'input',
        id: 'industrialParkName',
        label: 'Nombre de parque industrial'
    },

    {
        type: 'input',
        id: 'wallType',
        label: 'Tipo de muros'
    },
    {
        type: 'input',
        id: 'structureType',
        label: 'Tipo de estructura'
    },
    {
        type: 'input',
        id: 'coverType',
        label: 'Tipo de cubierta'
    },
    {
        type: 'input',
        id: 'cover',
        label: 'Cubierta'
    },
    {
        type: 'input',
        id: 'firme',
        label: 'Firme'
    },
    {
        type: 'input',
        id: 'lightningType',
        label: 'Tipo de iluminación'
    },
    {
        type: 'input',
        id: 'energyType',
        label: 'Tipo de energía'
    },
    {
        type: 'input',
        id: 'transformer',
        label: 'Transformador'
    },
    {
        type: 'input',
        id: 'electricSubstation',
        label: 'Subestación eléctrica'
    },
    {
        type: 'input',
        id: 'bardaPermietral',
        label: 'Barda perimetral'
    },
    {
        type: 'number',
        id: 'commonParkingLots',
        label: 'Estacionamientos comunes'
    },
    {
        type: 'number',
        id: 'privateParkingLots',
        label: 'Estacionamientos privados'
    },
    {
        type: 'number',
        id: 'trailerParkingLots',
        label: 'Estacionamientos para trailers'
    },
    {
        type: 'number',
        id: 'emergencyDoors',
        label: 'Puertas de emergencia'
    },
    {
        type: 'number',
        id: 'floorDepth',
        label: 'Espesor de piso'
    },
    // {
    //     type: "textarea",
    //     id: "rentRequirements",
    //     label: "Requisitos arrendamiento",
    // },

    // {
    //     type: "textarea",
    //     id: "exceptionalCircumstances",
    //     label: "Circunstancias excepcionales",
    // },

    {
        type: 'divider',
        id: 'other_data_divider',
        label: 'Otros datos'
    },
    {
        type: 'multiple',
        id: 'nearby',
        label: 'Ubicaciones cercanas',
        options: ['Esquina', 'Parques cercanos', 'Puerto', 'Playa', 'Ferrocarril']
    },
    {
        type: 'multiple',
        id: 'restrictions',
        label: 'Restricciones',
        options: ['No niños', 'Sólo familias', 'Para ejecutivos', 'Para estudiantes']
    }
];
const fieldMapper = {
    number: () => <InputNumber className="w-full" />,
    input: () => <Input />,
    multiple: (opts) => <Select mode="multiple" options={opts.map((opt) => ({ label: opt, value: opt }))}></Select>,
    select: (opts) => (
        <Select
            options={opts.map((opt) => ({
                label: typeof opt == 'object' ? opt?.label : opt,
                value: typeof opt == 'object' ? opt?.value : opt
            }))}
        />
    ),
    textarea: () => <Input.TextArea />,
    date: () => <DatePicker className="w-full" />
};

const OwnerInput = (props) => {
    const [contact, setContact] = useState(null);
    const onSelect = async (contact) => {
        props.onChange?.({
            _id: contact._id,
            firstName: contact?.firstName,
            lastName: contact?.lastName,
            phone: contact?.phones?.length > 0 ? contact.phones[0].phone : contact.phone || '',
            email: contact?.emails?.length > 0 ? contact?.emails[0].email : contact.email || '',
            emails: contact?.emails,
            phones: contact?.phones,
            profilePicture: contact?.profilePicture
        });
    };

    const chooseContact = () => {
        const { destroy } = ShowContactList({
            editContact: contact,
            onAdd: async (contact) => {
                onSelect(contact);
                destroy();
            },
            onSelect: async (contact) => {
                onSelect(contact);
                destroy();
            }
        });
    };

    useEffect(() => {
        if (props.contact) {
            axios.get(`${api_url}/contact/${props.contact}/simple`).then((r) => onSelect(r.data));
        }
    }, [props.contact]);
    useEffect(() => {
        setContact(props.value);
    }, [props.value]);

    return (
        <>
            {!contact && (
                <>
                    <Typography.Paragraph className="text-pulppo-status-dark-disabled">
                        Busca o agrega un contacto para asignarlo como propietario
                    </Typography.Paragraph>
                    <ButtonAnt className="w-full md:w-auto" type="primary" onClick={chooseContact}>
                        Seleccionar contacto
                    </ButtonAnt>
                </>
            )}
            {contact && (
                <div>
                    <div className="flex items-center">
                        <div className="flex items-center">
                            <Typography.Link href={`/contact/${contact._id}`}>
                                <Avatar
                                    size="large"
                                    src={
                                        <NextImage
                                            showPreview={false}
                                            alt={contact.profilePicture}
                                            src={
                                                contact.profilePicture ||
                                                `https://ui-avatars.com/api/?name=${contact?.firstName}%20${contact?.lastName}`
                                            }
                                            width={64}
                                            height={64}
                                        />
                                    }
                                />
                            </Typography.Link>
                            <div className="ml-4">
                                <Typography.Paragraph className="mb-0 text-lg">
                                    {contact.firstName} {contact.lastName}
                                </Typography.Paragraph>
                                <Typography.Paragraph
                                    className={`mb-0 ${contact.phones?.[0]?.phone ? 'text-pulppo-status-dark-disabled' : 'text-red-400'}`}
                                >
                                    {contact.phones?.[0]?.phone || 'No tiene teléfono'}
                                </Typography.Paragraph>
                                <Typography.Paragraph
                                    className={`mb-0 ${contact.emails?.[0]?.email ? 'text-pulppo-status-dark-disabled' : 'text-red-400'}`}
                                >
                                    {contact.emails?.[0]?.email || 'No tiene email'}
                                </Typography.Paragraph>
                                <Typography.Link onClick={chooseContact}>
                                    Editar <EditOutlined />
                                </Typography.Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

interface IPropsAttributes {
    property?: Partial<IProperty>;
    isDisabled?: boolean;
}

export const Attributes = ({ property, isDisabled }: IPropsAttributes) => {
    const { t } = useTranslate(['common']);
    const propertyForm = useContext(PropertyFormContext);

    const { formData } = property ? { formData: property } : propertyForm || {};

    const propertyType = formData?.type;

    return (
        <div className="@container">
            <Row gutter={10}>
                <Col xs={24}>
                    <Form.Item
                        className="form-item-one-column"
                        label="Tipo de propiedad"
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: 'Selecciona el tipo de propiedad'
                            }
                        ]}
                    >
                        <PulppoSelect
                            disabled={isDisabled}
                            className="py-2"
                            allowSearch={false}
                            onSelect={() => {}}
                            options={NewPropertyTypes.map((type) => ({
                                value: type,
                                key: type,
                                label: t(type, type, { ns: 'common' })
                            }))}
                        />
                    </Form.Item>
                </Col>
                <Form.Item className="hidden" wrapperCol={{ xs: 12, lg: 6 }} label="ID interno" name={['internalId']}>
                    <Input className="rounded-none" disabled={isDisabled} />
                </Form.Item>
                <FormItem
                    property_type={propertyType}
                    wrapperCol={{ xs: 12, lg: 6 }}
                    label={t('suites', 'Recámaras')}
                    name={['attributes', 'suites']}
                >
                    <InputNumber disabled={isDisabled} className="w-full rounded-none py-0.5" />
                </FormItem>
                {showSpaces && (
                    <FormItem
                        property_type={propertyType}
                        wrapperCol={{ xs: 12, lg: 6 }}
                        label={t('spaces', 'Ambientes')}
                        name={['attributes', 'spaces']}
                    >
                        <InputNumber disabled={isDisabled} className="w-full rounded-none py-0.5" />
                    </FormItem>
                )}
                <FormItem
                    property_type={propertyType}
                    wrapperCol={{ xs: 12, lg: 6 }}
                    label={t('bathrooms', 'Baños')}
                    name={['attributes', 'bathrooms']}
                >
                    <InputNumber disabled={isDisabled} className="w-full rounded-none py-0.5" />
                </FormItem>
                <FormItem
                    property_type={propertyType}
                    wrapperCol={{ xs: 12, lg: 6 }}
                    label={t('toilettes', 'Medios baños')}
                    name={['attributes', 'toilettes']}
                >
                    <InputNumber disabled={isDisabled} className="w-full rounded-none py-0.5" />
                </FormItem>
                <FormItem
                    property_type={propertyType}
                    wrapperCol={{ xs: 12, lg: 6 }}
                    label={t('parkings', 'Estacionamientos')}
                    name={['attributes', 'parkings']}
                >
                    <InputNumber disabled={isDisabled} className="w-full rounded-none py-0.5" />
                </FormItem>
                <FormItem
                    property_type={propertyType}
                    wrapperCol={{ xs: 12, lg: 8 }}
                    label={t('totalSurface', 'm2 Total')}
                    name={['attributes', 'totalSurface']}
                >
                    <InputNumber
                        disabled={isDisabled}
                        type="number"
                        inputMode="numeric"
                        className="w-full rounded-none py-0.5"
                    />
                </FormItem>
                <FormItem
                    property_type={propertyType}
                    wrapperCol={{ xs: 12, lg: 8 }}
                    label={t('roofedSurface', 'm2 Construidos')}
                    name={['attributes', 'roofedSurface']}
                >
                    <InputNumber
                        disabled={isDisabled}
                        className="w-full rounded-none py-0.5"
                        type="number"
                        inputMode="numeric"
                    />
                </FormItem>
                <FormItem
                    property_type={propertyType}
                    wrapperCol={{ xs: 12, lg: 8 }}
                    label={t('unroofedSurface', 'm2 Jardín')}
                    name={['attributes', 'unroofedSurface']}
                >
                    <InputNumber
                        disabled={isDisabled}
                        className="w-full rounded-none py-0.5"
                        type="number"
                        inputMode="numeric"
                    />
                </FormItem>
                <FormItem
                    className="form-item-one-column"
                    property_type={propertyType}
                    wrapperCol={{ xs: 12, lg: 24 }}
                    label="Año de construcción"
                    name={['attributes', 'yearBuild']}
                >
                    <PulppoSelect
                        options={[
                            {
                                label: 'En construcción',
                                key: '-1',
                                value: -1
                            },
                            {
                                label: 'A estrenar',
                                key: '0',
                                value: 0
                            },
                            ...new Array(100).fill(1).map((_, index) => {
                                const year = dayjs().tz().year() - index;
                                return {
                                    label: `${year}`,
                                    value: year,
                                    key: `${year}`
                                };
                            })
                        ]}
                        allowSearch={true}
                        onSelect={() => {}}
                        className="py-2"
                        icon={<EditOutlined className="text-lg" />}
                        disabled={isDisabled}
                    />
                </FormItem>
                {formData?.attributes?.yearBuild === -1 && (
                    <Col xs={24} lg={12}>
                        <FormItem
                            property_type={propertyType}
                            label="Mes entrega estimado"
                            name={['development', 'estimatedFinish', 'month']}
                        >
                            <PulppoSelect
                                disabled={isDisabled}
                                capitalizeOptions={true}
                                className="h-11"
                                options={new Array(12).fill(1).map((_, index) => {
                                    const value = dayjs().tz().month(index).format('MMMM');

                                    return {
                                        value: index + 1,
                                        key: `${index + 1}`,
                                        label: value
                                    };
                                })}
                            />
                        </FormItem>
                    </Col>
                )}
                {formData?.attributes?.yearBuild === -1 && (
                    <Col xs={24} lg={12}>
                        <FormItem
                            property_type={propertyType}
                            label="Año entrega estimado"
                            name={['development', 'estimatedFinish', 'year']}
                        >
                            <PulppoSelect
                                disabled={isDisabled}
                                capitalizeOptions={true}
                                className="h-11"
                                options={new Array(15).fill(1).map((_, index) => {
                                    const year = dayjs().tz().year() + index;
                                    return {
                                        label: `${year}`,
                                        value: year,
                                        key: `${year}`
                                    };
                                })}
                            />
                        </FormItem>
                    </Col>
                )}
                <div className="w-full @lg:w-1/2">
                    <FormItem
                        className="form-item-one-column"
                        property_type={propertyType}
                        label="Disposición"
                        name={['attributes', 'disposition']}
                    >
                        <DispositionSelect disabled={isDisabled} />
                    </FormItem>
                </div>
                <div className="w-full @lg:w-1/2">
                    <FormItem
                        className="form-item-one-column"
                        property_type={propertyType}
                        label="Orientación"
                        name={['attributes', 'orientation']}
                    >
                        <PulppoSelect
                            className="py-2"
                            allowSearch={false}
                            onSelect={() => {}}
                            options={Orientations.map((type) => ({
                                value: type,
                                key: type,
                                label: t(type)
                            }))}
                            disabled={isDisabled}
                        />
                    </FormItem>
                </div>
                <div className="w-full @lg:w-1/2">
                    <FormItem
                        className="form-item-one-column"
                        property_type={propertyType}
                        label="Estado del inmueble"
                        name={['attributes', 'condition']}
                    >
                        <PulppoSelect
                            className="py-2"
                            allowSearch={false}
                            onSelect={() => {}}
                            options={PropertyConditions.map((type) => ({
                                value: type,
                                key: type,
                                label: type
                            }))}
                            disabled={isDisabled}
                        />
                    </FormItem>
                </div>
                <div className="w-full @lg:w-1/2">
                    <FormItem
                        className="form-item-one-column"
                        property_type={propertyType}
                        label="Código interno"
                        name={['referenceCode']}
                    >
                        <Input
                            disabled={isDisabled}
                            className="w-full rounded-none py-2"
                            suffix={<EditOutlined className="text-lg" />}
                        />
                    </FormItem>
                </div>
            </Row>
            <Row gutter={10}>
                {advancedFields
                    .filter((field) => AttributeMapper[field.id]?.[propertyType] === 'required')
                    .map((field) =>
                        field.type == 'divider' ? (
                            <Col span={24} key={field.id}>
                                <Divider /> <Typography.Title level={4}>{field.label}</Typography.Title>
                            </Col>
                        ) : (
                            <FormItem
                                property_type={propertyType}
                                key={field.id}
                                wrapperCol={{
                                    xs: field.type == 'textarea' ? 24 : 12,
                                    md: field.type == 'textarea' ? 12 : 6
                                }}
                                name={field.path || ['additionalFields', field.id]}
                                label={field.label}
                            >
                                {fieldMapper[field.type](field.options)}
                            </FormItem>
                        )
                    )}
            </Row>
        </div>
    );
};

export const PropertyStep1 = () => {
    const router = useRouter();

    return (
        <div>
            <div className="bg-white md:bg-transparent">
                <Row gutter={20}>
                    <Col md={12} xs={24}>
                        <TaskView title="Propietario">
                            <Form.Item
                                name="contact"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Necesitas agregar al propietario para poder cargar la propiedad'
                                    }
                                ]}
                            >
                                <OwnerInput contact={router.query.contact} />
                            </Form.Item>
                        </TaskView>
                        <TaskView title="Ubicación" content={<PropertyLocation />} />
                    </Col>
                    <Col md={12} xs={24}>
                        <TaskView title="Características">
                            <Attributes />
                        </TaskView>
                        <AllServices />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export const AllServices = (props: ITaskViewProps) => {
    return <TaskView title="Servicios" content={<ServicesCheckbox />} {...props} />;
};

export const DispositionSelect = ({
    value,
    onChange,
    disabled
}: {
    value?: string;
    onChange?: (disposition: string) => void;
    disabled?: boolean;
}) => {
    const { t } = useTranslate(['common']);

    return (
        <>
            <Button
                onClick={() => {
                    onChange?.('front');
                }}
                className="w-1/2"
                type={value === 'front' ? 'black' : 'secondary'}
                disabled={disabled}
            >
                {t('front')}
            </Button>
            <Button
                onClick={() => {
                    onChange?.('back');
                }}
                className="w-1/2"
                type={value === 'back' ? 'black' : 'secondary'}
                disabled={disabled}
            >
                {t('back')}
            </Button>
        </>
    );
};

export const ServicesCheckbox = () => {
    const [search, setSearch] = useState('');

    return (
        <>
            <Input
                value={search}
                onChange={(e) => setSearch(e.currentTarget.value)}
                className="mb-2"
                prefix={<SearchOutlined />}
                placeholder="Buscar"
            />
            <Form.Item name="services">
                <ServicesInput search={search} />
            </Form.Item>
        </>
    );
};

const ServicesInput = ({ onChange, value: initialValue, search }: any) => {
    const initialized = useRef(false);
    const value = initialValue?.filter((el, i, arr) => el?.id && arr.findIndex((a) => a?.id === el?.id) === i);
    const [rooms, setRooms] = useState(value?.filter((svc) => svc.type == 2) || []);
    const [additionals, setAdditionals] = useState(value?.filter((svc) => svc.type == 3) || []);
    const [amenities, setAmenities] = useState(value?.filter((svc) => svc.type == 1) || []);
    const { t } = useTranslate('common');

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            return;
        }
        onChange([...amenities, ...rooms, ...additionals]);
    }, [amenities, rooms, additionals]);

    return (
        <Row justify="space-between">
            <Col xs={24} lg={8}>
                <ExpandableCheckboxGroup
                    value={amenities}
                    title={t('Amenidades')}
                    items={Services.filter(
                        (service) => service.type == 1 && service.name.toLowerCase().includes(search)
                    )}
                    onChange={setAmenities}
                />
            </Col>
            <Col xs={24} lg={8}>
                <ExpandableCheckboxGroup
                    value={rooms}
                    title={t('Espacios')}
                    items={Services.filter(
                        (service) => service.type == 2 && service.name.toLowerCase().includes(search)
                    )}
                    onChange={setRooms}
                />
            </Col>
            <Col xs={24} lg={8}>
                <ExpandableCheckboxGroup
                    value={additionals}
                    title={t('Adicionales')}
                    items={Services.filter(
                        (service) => service.type == 3 && service.name.toLowerCase().includes(search)
                    )}
                    onChange={setAdditionals}
                />
            </Col>
        </Row>
    );
};
