import { Form, Row, Col, InputNumber, Checkbox } from 'antd';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { PropertyFormContext } from '../../contexts/PropertyFormContext';
import { IVAInput } from './IVAInput';
import DatePicker from '../Elements/DatePicker';
import { isValidDate } from '../../utils/date';
import '../../utils/dateConfig';
import { PriceInput } from './PropertyListing';
import { IProperty } from '@pulppo/pulppo-models';
import { useTranslate } from '@tolgee/react';
import { isExclusive } from '../../utils/property';

export const CheckboxInput = (props) => {
    return (
        <Checkbox
            {...props}
            value={!!props.value}
            checked={!!props.value}
            onChange={(e) => props.onChange(e.target.checked)}
        >
            {props.children}
        </Checkbox>
    );
};

const ExclusiveInput = (props) => {
    return (
        <CheckboxInput
            disabled={props.disabled}
            value={isValidDate(props.value?.start)}
            onChange={(check: boolean) => {
                if (check) {
                    props.onChange({
                        start: new Date(),
                        durationMonths: 6
                    });
                } else {
                    props.onChange({
                        start: null,
                        durationMonths: null
                    });
                }
            }}
        >
            Es exclusiva
        </CheckboxInput>
    );
};
export const PropertyManagement = ({ property: initialProperty }: { property?: Partial<IProperty> }) => {
    const propertyForm = useContext(PropertyFormContext);
    const { formData } = initialProperty ? { formData: initialProperty } : propertyForm || {};

    const property = propertyForm?.formData || initialProperty;
    const { t } = useTranslate('common');

    const exclusive = isExclusive(property);

    return (
        <div>
            <Row align="middle" justify="space-between" gutter={20} className="mt-2">
                <Col>
                    <Form.Item label="Exclusividad" name={['contract', 'exclusive']}>
                        <ExclusiveInput />
                    </Form.Item>
                </Col>
            </Row>
            {exclusive ? (
                <div className={`${exclusive ? 'block' : 'hidden'}`}>
                    <div>
                        <Form.Item label="Inicio de exclusividad" name={['contract', 'exclusive', 'start']}>
                            <FormDatePicker />
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item
                            label="Duración de exclusividad"
                            name={['contract', 'exclusive', 'durationMonths']}
                            className="w-full"
                        >
                            <InputNumber className="h-8 w-full" addonAfter="meses" />
                        </Form.Item>
                    </div>
                </div>
            ) : null}
            <Row align="middle" justify="space-between" className="mt-6">
                <Col span={24}>
                    <Form.Item
                        className="w-full"
                        label="Comisión"
                        name={['contract', 'comission']}
                        rules={[
                            {
                                required: true,
                                message: 'Es necesario ingresar una comisión'
                            }
                        ]}
                    >
                        <IVAInput />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="space-between" gutter={10} align="middle" className="mt-2">
                <Col span={24}>
                    <Form.Item
                        label="Duración del acuerdo"
                        name={['contract', 'durationMonths']}
                        className="w-full"
                        rules={[
                            {
                                required: true,
                                message: 'Ingrese duración del acuerdo'
                            }
                        ]}
                    >
                        <CustomInputNumber addonAfter={'meses'} />
                    </Form.Item>
                </Col>
            </Row>
            {formData?.listing?.operation == 'rent' && (
                <Row align="middle" justify="space-between" className="mt-2">
                    <Col span={24}>
                        <Form.Item
                            label={`Políticas de ${t('rent', 'renta')}`}
                            tooltip={`En caso de ${t('rent', 'renta').toLowerCase()}, especificar requisitos de arrendamiento, ejemplo: acepta mascota, sólo familias, no negocia aval, etc.`}
                            name={['policy', 'rent']}
                        >
                            <TextArea placeholder="Acepta mascotas, sólo familias, no negocia aval"></TextArea>
                        </Form.Item>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export const CustomInputNumber = (props) => {
    return (
        <PriceInput
            className="w-full"
            disabled={props.disabled}
            status={props.status}
            stringMode={true}
            onChange={(val) => {
                props.onChange(+val);
            }}
            value={props.val}
            formatter={(val) => (val ? (+val)?.toLocaleString(process.env.NEXT_PUBLIC_LANGUAGE_CODE || 'es-MX') : '')}
            {...props}
            prefix={props.prefix || ''}
        />
    );
};

export const FormDatePicker = (props) => {
    const [value, setValue] = useState(props.value ? dayjs(props.value).tz() : null);

    useEffect(() => {
        setValue(props.value ? dayjs(props.value).tz() : null);
    }, [props.value]);

    return (
        <DatePicker
            onChange={(v) => {
                props.onChange(v?.toDate());
            }}
            value={value}
            className="w-full rounded-none"
        />
    );
};
