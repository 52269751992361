import { AimOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { useState } from 'react';
import { Image } from './IntelliHome';
import axios from '../../utils/axios';
import { api_url } from '../../helpers/fetcher';
import { ISearchProperty } from '@pulppo/pulppo-models';
import { Button } from 'antd';
import { useRouter } from 'next/router';
import { LoadingButton } from '../Elements/ButtonLoading';

export const OperationDetected = ({ show, onClose, search, audit, refresh }) => {
    const [property, setProperty] = useState<string>(null);
    const router = useRouter();

    return (
        <PulppoDrawer visible={show} onClose={onClose} title="Registro de oferta">
            <div className="flex h-full flex-col justify-between overflow-y-hidden">
                <div className="w-full flex-1 overflow-y-auto">
                    <div className="mb-6 flex cursor-pointer items-center gap-3 bg-pulppo-status-light-warning p-4 text-pulppo-status-dark-warning">
                        <ExclamationCircleFilled className="text-3xl" />
                        <div>
                            <p className="font-medium">
                                Detectamos una posible oferta con {search?.contact?.firstName}{' '}
                                {search?.contact?.lastName || ''}.
                            </p>
                            <p className="font-light">
                                Completa el formulario para registrar la operación en la plataforma
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div className="w-full">
                            <p className="mb-2 font-medium">Seleccione la propiedad de la operación</p>
                            <div className="flex flex-col gap-4">
                                {search?.properties
                                    ?.filter((prop: ISearchProperty) => prop.status.last !== 'deleted')
                                    ?.map((prop) => {
                                        const active = property === prop._id;
                                        return (
                                            <div
                                                key={prop?._id}
                                                onClick={() => {
                                                    setProperty((p) => (p === prop._id ? null : prop._id));
                                                }}
                                                className="flex w-full cursor-pointer items-center justify-between gap-2 pr-2 hover:bg-gray-100"
                                            >
                                                <div
                                                    className={`flex h-5 w-5 shrink-0 items-center justify-center rounded-full border-2 border-solid ${active ? 'border-black' : 'border-pulppo-status-dark-disabled'}`}
                                                >
                                                    {active && <div className="h-3 w-3 rounded-full bg-black" />}
                                                </div>
                                                <div className="flex w-full items-center gap-2">
                                                    <div className="shrink-0">
                                                        <Image
                                                            alt={prop?._id as string}
                                                            width={50}
                                                            height={50}
                                                            className="h-24 w-24"
                                                            src={prop?.pictures?.[0]?.url}
                                                        />
                                                    </div>

                                                    <div className="shrink-1 flex h-full flex-col justify-around">
                                                        <p className="">
                                                            {prop?.address?.street
                                                                ? `${prop?.address?.street?.slice(0, 30)}${
                                                                      prop?.address?.street?.length > 30 ? '...' : ''
                                                                  }`
                                                                : 'Sin dirección'}
                                                        </p>
                                                        <p className="text-ellipsis font-light text-pulppo-status-dark-disabled">{`${
                                                            prop?.address?.neighborhood?.name || ''
                                                        }, ${prop?.address?.city?.name || ''}`}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex gap-2">
                    <Button
                        onClick={() => {
                            router.push(`/new-searches/${search?._id}`);
                        }}
                        className="h-auto w-min min-w-[1px] py-2"
                    >
                        <AimOutlined />
                    </Button>
                    <LoadingButton
                        onClick={async () => {
                            await axios.patch(`${api_url}/audit/operation/${audit?._id}`, {
                                property
                            });
                            onClose();
                            refresh?.();
                        }}
                        type="primary"
                        className="h-auto flex-1 py-2 font-light"
                    >
                        {property ? `Registrar oferta` : `No hubo oferta`}
                    </LoadingButton>
                </div>
            </div>
        </PulppoDrawer>
    );
};
