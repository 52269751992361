import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { ReactNode, useState } from 'react';
import { PulppoDrawer } from './PulppoDrawer';
import { PulppoSelect } from './PulppoSelect';
import { Dayjs } from 'dayjs';
import Calendar, { CalendarDefault, rotateArray } from './Calendar';

export const PulppoDatePicker = ({
    value,
    disableOlderDates = false,
    format = (value: Dayjs) => value.tz().format('DD/MM/YYYY'),
    onChange = (value) => {},
    renderInput = null,
    placeholder = 'Seleccione una fecha en el calendario'
}: {
    value?: Dayjs;
    format?: (value: Dayjs) => string;
    onChange?: (value: Dayjs) => void;
    placeholder?: string;
    disableOlderDates?: boolean;
    renderInput?: ReactNode | Element;
}) => {
    const [showDrawer, setShowDrawer] = useState(false);
    const [month, setMonth] = useState(dayjs().tz().month() + 1);
    const [showOverlay, setShowOverlay] = useState(false);

    return (
        <>
            <div className="hidden w-full cursor-pointer lg:flex">
                <div className="relative flex-1">
                    <p
                        className="flex h-10 items-center border border-solid border-gray-300 px-4"
                        onClick={() => {
                            setShowOverlay((show) => !show);
                        }}
                    >
                        {!value ? placeholder : format(value)}
                    </p>
                    {showOverlay && (
                        <CalendarDefault
                            value={value}
                            onSelect={(date) => {
                                if (value.date() !== date.date()) {
                                    setShowOverlay(false);
                                }
                                onChange(date);
                            }}
                            disabledDate={(date) => disableOlderDates && date.isBefore(dayjs().tz().subtract(1, 'day'))}
                            className="w-min-96 absolute z-50 shadow-lg"
                        />
                    )}
                </div>
                <div
                    className="flex-0 flex h-10 w-10 items-center justify-center bg-gray-300"
                    onClick={() => {
                        setShowOverlay((show) => !show);
                    }}
                >
                    <EditOutlined />
                </div>
            </div>

            <div className="lg:hidden">
                <PulppoDrawer
                    title={value?.format('DD-MM-YYYY')}
                    extra={
                        <Button
                            className="text-lg"
                            type="primary"
                            onClick={() => {
                                setShowDrawer(false);
                            }}
                        >
                            Seleccionar
                        </Button>
                    }
                    visible={showDrawer}
                    onClose={() => {
                        setShowDrawer(false);
                    }}
                >
                    <Calendar
                        onSelect={onChange}
                        headerRender={(config) => (
                            <div className="mb-2">
                                <PulppoSelect
                                    className="h-12"
                                    value={`${month}`}
                                    onChange={(value) => {
                                        config.onChange(
                                            dayjs()
                                                .tz()
                                                .month(+value - 1)
                                                .isBefore(dayjs().tz().startOf('day'))
                                                ? dayjs()
                                                      .tz()
                                                      .month(+value - 1)
                                                      .add(1, 'year')
                                                : dayjs()
                                                      .tz()
                                                      .clone()
                                                      .month(+value - 1)
                                        );
                                        setMonth(+value);
                                    }}
                                    allowSearch={false}
                                    options={rotateArray(
                                        [
                                            {
                                                label: 'Enero',
                                                key: 'Enero',
                                                value: '1'
                                            },
                                            {
                                                label: 'Febrero',
                                                key: 'Febrero',
                                                value: '2'
                                            },
                                            {
                                                label: 'Marzo',
                                                key: 'Marzo',
                                                value: '3'
                                            },
                                            {
                                                label: 'Abril',
                                                key: 'Abril',
                                                value: '4'
                                            },
                                            {
                                                label: 'Mayo',
                                                key: 'Mayo',
                                                value: '5'
                                            },
                                            {
                                                label: 'Junio',
                                                key: 'Junio',
                                                value: '6'
                                            },
                                            {
                                                label: 'Julio',
                                                key: 'Julio',
                                                value: '7'
                                            },
                                            {
                                                label: 'Agosto',
                                                key: 'Agosto',
                                                value: '8'
                                            },
                                            {
                                                label: 'Septiembre',
                                                key: 'Septiembre',
                                                value: '9'
                                            },
                                            {
                                                label: 'Octubre',
                                                key: 'Octubre',
                                                value: '10'
                                            },
                                            {
                                                label: 'Noviembre',
                                                key: 'Noviembre',
                                                value: '11'
                                            },
                                            {
                                                label: 'Diciembre',
                                                key: 'Diciembre',
                                                value: '12'
                                            }
                                        ],
                                        dayjs().tz().month()
                                    )}
                                />
                            </div>
                        )}
                        disabledDate={(date) => date.isBefore(dayjs().tz().startOf('day'))}
                    />
                </PulppoDrawer>
                <div
                    className="cursor-pointer"
                    onClick={() => {
                        setShowDrawer(true);
                    }}
                >
                    {renderInput
                        ? (renderInput as ReactNode)
                        : ((
                              <div className="flex h-10 items-center rounded-md border border-solid border-gray-300">
                                  <p className="text-md flex-1 px-4">{value ? format(value) : 'Seleccionar'}</p>
                                  <div className="flex-0 flex h-10 w-10 items-center justify-center rounded-r-md bg-gray-300">
                                      <EditOutlined />
                                  </div>
                              </div>
                          ) as ReactNode)}
                </div>
            </div>
        </>
    );
};
