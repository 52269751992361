import { DeleteOutlined } from '@ant-design/icons';
import { INote } from '@pulppo/pulppo-models';
import { Comment, Form, Button, List, Input } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { NextImage } from './NextImage';
import { PulppoLoader } from './PulppoLoader';
import useUser from '../../hooks/useUser';

const { TextArea } = Input;

const CommentList = ({ comments, onDelete, setLoading, setComments }) => (
    <List
        dataSource={comments}
        itemLayout="horizontal"
        renderItem={(item: INote) => (
            <div className="relative">
                <Comment
                    content={item.content}
                    avatar={
                        <NextImage
                            showPreview={false}
                            src={
                                item?.creator?.profilePicture ||
                                `https://ui-avatars.com/api/?name=${item?.creator?.firstName}%20${item?.creator?.lastName}`
                            }
                            className="h-12 w-12 object-cover"
                            alt={item?.creator?.profilePicture}
                            width={64}
                            height={64}
                        />
                    }
                    author={`${item.creator?.firstName} ${item.creator?.lastName}`}
                    datetime={dayjs(item.createdAt)?.tz()?.fromNow()}
                />
                {onDelete && (
                    <div
                        className="absolute right-2 top-1/2"
                        onClick={async () => {
                            setLoading(true);
                            let notes = [...comments];
                            notes = notes.filter((n) => n._id !== (item as any)._id);
                            if (onDelete) await onDelete(notes);
                            setComments(notes);
                            setLoading(false);
                        }}
                    >
                        <DeleteOutlined className="text-xl text-pulppo-status-dark-disabled" />
                    </div>
                )}
            </div>
        )}
    />
);

const Editor = ({ onChange, onSubmit, submitting, value, addVisible }) => (
    <>
        <Form.Item>
            <TextArea
                className="rounded-none"
                placeholder="Escribe la nota aquí..."
                rows={3}
                onChange={onChange}
                value={value}
            />
        </Form.Item>
        {(value || addVisible) && (
            <Form.Item>
                <Button
                    className="flex h-10 items-center justify-center font-medium"
                    htmlType="submit"
                    loading={submitting}
                    onClick={(e) => {
                        e.preventDefault();
                        onSubmit();
                    }}
                    type="primary"
                >
                    Agregar Nota
                </Button>
            </Form.Item>
        )}
    </>
);

export const NoteEditor = ({
    notes,
    onNoteAdd,
    forcedUser = undefined,
    onNoteDelete = undefined,
    addVisible = false,
    isPublic = false,
    className
}: {
    notes?: Array<INote>;
    onNoteAdd?: (value) => void;
    onNoteDelete?: (value) => void;
    addVisible?: boolean;
    forcedUser?;
    isPublic?: boolean;
    className?: string;
}) => {
    const [comments, setComments] = useState(
        notes?.filter((note) => note && (isPublic || user?._id === note.creator?._id)) || []
    );
    const [submitting, setSubmitting] = useState(false);
    const [value, setValue] = useState('');
    const loggedUser = useUser((store) => store.user);

    useEffect(() => {
        setComments(notes?.filter((note) => note && (isPublic || user?._id === note.creator?._id)) || []);
    }, [notes]);

    const user = forcedUser || loggedUser;

    const handleSubmit = async () => {
        if (!value) {
            return;
        }

        setSubmitting(true);

        await onNoteAdd([
            ...comments,
            {
                creator: user,
                content: value,
                isPublic
            }
        ]);
        setSubmitting(false);
        setValue('');
        setComments([
            ...comments,
            {
                creator: user,
                content: value,
                isPublic
            }
        ]);
    };

    return (
        <>
            <Comment
                className={className}
                content={
                    <Editor
                        addVisible={addVisible}
                        onChange={(e) => setValue(e.target.value)}
                        onSubmit={handleSubmit}
                        submitting={submitting}
                        value={value}
                    />
                }
            />
            {comments.length > 0 && (
                <PulppoLoader loading={submitting}>
                    <CommentList
                        setComments={setComments}
                        setLoading={setSubmitting}
                        onDelete={onNoteDelete}
                        comments={comments}
                    />
                </PulppoLoader>
            )}
        </>
    );
};
