import useSWR from 'swr';
import { fetcher } from '../../helpers/fetcher';
import { Button, Drawer, Input, Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { ShowContactList } from '../Contact/SimpleContactList';
import { CloseOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../contexts/UserContext';
import { PropertyList } from '../Property/PropertyList';
import Markdown from 'react-markdown';
import axios from '../../utils/axios';
import { ISimpleProperty, ISimpleUser } from '@pulppo/pulppo-models';
import { provider_url } from '../Providers';
import useOperation from '../../hooks/useOperation';
import { LoadingButton } from './ButtonLoading';

export const IDToName = {
    credits: {
        name: 'Créditos hipotecarios',
        operation: 'sale'
    },
    rent_protection: {
        name: 'Protección de renta',
        operation: 'rent'
    },
    investigation: {
        name: 'Investigaciones',
        operation: ''
    },
    furniture: {
        name: 'Muebles y decoración',
        operation: ''
    },
    remodelation: {
        name: 'Remodelaciones',
        operation: ''
    },
    movings: {
        name: 'Mudanzas',
        operation: ''
    },
    taxes: {
        name: 'Reducción de impuestos para vendedor',
        operation: 'sale'
    },
    taxes_client: {
        name: 'Reducción de impuestos para comprador',
        operation: 'sale'
    },
    solar_panels: {
        name: 'Instalación de paneles solares',
        operation: ''
    }
};
const TypeStep = ({ onNext, setRequest }) => {
    const { data: providerCategories } = useSWR(`${provider_url}/providers`, fetcher, {
        fallbackData: []
    });
    return (
        <>
            <div className="flex h-full flex-col justify-between">
                <div className="mx-auto">
                    <p className="text-center font-light text-pulppo-status-dark-disabled">
                        Selecciona el servicio que quieres solicitar. <br />
                        Una vez seleccionado continuaremos con los datos de la solicitud que serán cargados
                        automáticamente.
                    </p>
                    <div className="mt-4">
                        {providerCategories.map((category) => {
                            const providers = category.providers;
                            return (
                                <div key={category._id} className="my-6">
                                    <h2 className="mb-4 text-lg font-medium">
                                        {IDToName[category._id]?.name}{' '}
                                        <span className="muted font-light text-pulppo-status-dark-disabled">
                                            ({providers.length} prestadores)
                                        </span>
                                    </h2>
                                    <div className="grid grid-cols-1 gap-2 p-1 lg:grid-cols-2">
                                        {providers.map((provider) => (
                                            <div
                                                key={provider._id}
                                                className="border-px group relative flex h-[400px] flex-col items-center gap-4 rounded border-solid border-gray-100 p-4 shadow"
                                            >
                                                <img
                                                    src={provider.logo}
                                                    className="aspect-video h-20 shrink-0 object-contain"
                                                />
                                                <div className="flex-1 overflow-y-scroll">
                                                    <div className="prose line-clamp-5 text-center">
                                                        <Markdown>
                                                            {provider.comission[category._id]?.fullDescription ||
                                                                provider.description}
                                                        </Markdown>
                                                    </div>
                                                </div>
                                                {provider.comission[category._id]?.description ? (
                                                    <div className="bg-gray-200 px-4 py-1">
                                                        {provider.comission[category._id]?.description}
                                                    </div>
                                                ) : (
                                                    <div className="bg-gray-200 px-4 py-1">
                                                        {(provider.comission[category._id]?.percent * 100).toFixed(1)}%
                                                        de comisión a tu favor
                                                    </div>
                                                )}
                                                <Button
                                                    onClick={() => {
                                                        setRequest((r) => ({
                                                            ...r,
                                                            provider,
                                                            type: category._id
                                                        }));
                                                        onNext();
                                                    }}
                                                    className="h-auto w-full bg-pulppo-primary-black py-2 text-white"
                                                >
                                                    Seleccionar
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

const DataStep = ({ onPrevious, onNext, request, setRequest }) => {
    const { user } = useContext(UserContext);
    const [showDrawer, setShowDrawer] = useState(false);
    const [filters, setFilters] = useState({
        tab: 'pulppo',
        operation: IDToName[request.type]?.operation
    });
    useEffect(() => {
        setFilters((f) => ({
            ...f,
            operation: IDToName[request.type]?.operation
        }));
    }, [request.type]);

    return (
        <div className="flex h-full w-full flex-col justify-between">
            <div className="">
                <p className="text-center font-light text-pulppo-status-dark-disabled">
                    Los datos han sido pre-cargados, comprueba que sean correctos o si deseas editar alguno de ellos.{' '}
                    <br />
                    Si todo está correcto haz clic en “Continuar”
                </p>
                <div>
                    <>
                        <div className="mt-8">
                            <p className="mb-4 font-medium">Cliente</p>
                            <Input
                                placeholder="Seleccionar..."
                                className="rounded-md py-2"
                                suffix={<EditOutlined className="text-gray-300" />}
                                value={[request?.client?.firstName, request?.client?.lastName]
                                    .filter(Boolean)
                                    .join(' ')}
                                onClick={() => {
                                    const { destroy } = ShowContactList({
                                        onAdd: () => {
                                            destroy();
                                        },
                                        onSelect: (contact) => {
                                            setRequest((r) => ({
                                                ...r,
                                                client: contact
                                            }));
                                            destroy();
                                        }
                                    });
                                }}
                            />
                        </div>
                        <div className="mt-8">
                            <p className="mb-4 font-medium">Propiedad</p>
                            <Input
                                onClick={async () => {
                                    setShowDrawer(true);
                                }}
                                value={[
                                    request?.property?.address?.street,
                                    request?.property?.address?.neighborhood?.name,
                                    request?.property?.address?.city?.name,
                                    request?.property?.address?.state?.name
                                ]
                                    .filter(Boolean)
                                    .join(', ')}
                                className="rounded-md py-2"
                                placeholder="Busca por dirección, colonia o identificador"
                                suffix={<SearchOutlined className="text-gray-200" />}
                            />
                        </div>
                    </>
                </div>
            </div>
            <div className="flex w-full items-center lg:justify-end">
                <div className="flex w-full items-center gap-4">
                    <Button onClick={onPrevious} className="h-auto flex-1 px-4 py-2 text-pulppo-primary-black">
                        Volver un paso atrás
                    </Button>
                    <Button
                        disabled={!request?.property?._id || !request?.client?._id}
                        onClick={onNext}
                        className="h-auto flex-1 bg-pulppo-primary-black px-4 py-2 text-white"
                    >
                        Continuar
                    </Button>
                </div>
            </div>
            <Drawer
                destroyOnClose
                closable={true}
                closeIcon={<CloseOutlined className="text-2xl" />}
                title=""
                bodyStyle={{
                    padding: '0px'
                }}
                zIndex={1000}
                height="100%"
                placement="bottom"
                onClose={() => setShowDrawer(false)}
                open={showDrawer}
            >
                <PropertyList
                    showTab={{ exclusive: false, mls: true, pulppo: true }}
                    showAddButton={false}
                    checkable={false}
                    propertyDetailActions={({ property }) => (
                        <Button
                            onClick={() => {
                                setShowDrawer(false);
                                setRequest((r) => ({
                                    ...r,
                                    property,
                                    owner: property?.contact
                                }));
                            }}
                            type="primary"
                            className="mt-2 w-full rounded-md"
                        >
                            Seleccionar
                        </Button>
                    )}
                    filters={filters}
                    setFilters={setFilters}
                />
            </Drawer>
        </div>
    );
};

export const ConfirmationStep = ({ onPrevious, onNext, request }) => {
    return (
        <div className="flex h-full w-full flex-col justify-between">
            <div className="w-full">
                {onNext && (
                    <p className="text-center font-light text-pulppo-status-dark-disabled">
                        Corrobora que todos los datos sean correctos y has clic en “Confirmar” para que envíemos la
                        solicitud
                    </p>
                )}
            </div>
            <div className="w-full flex-1 overflow-y-auto">
                <div className="mx-auto my-6 flex w-full flex-col gap-2 border border-solid border-gray-100 p-6">
                    <div className="flex w-full items-center justify-between border-b border-solid border-gray-100 py-5">
                        <p className="flex-1 text-lg font-bold text-pulppo-primary-black">Tipo de solicitud</p>
                        <p className="text-lg font-light text-pulppo-primary-black">{IDToName[request.type]?.name}</p>
                    </div>
                    <div className="flex w-full items-center justify-between border-b border-solid border-gray-100 py-5">
                        <p className="text-lg font-bold text-pulppo-primary-black">Proveedor</p>

                        <p className="text-lg font-light text-pulppo-primary-black">{request.provider?.name}</p>
                    </div>

                    {request?.client?._id && (
                        <div className="flex w-full items-end justify-between border-b border-solid border-gray-100 py-5">
                            <p className="text-lg font-bold text-pulppo-primary-black">Cliente</p>
                            <div className="flex items-center gap-2">
                                <img src={request.client?.profilePicture} className="h-10 w-10 rounded-full" />
                                <div>
                                    <p className="text-lg font-light text-pulppo-primary-black">
                                        {request.client?.firstName} {request.client?.lastName}
                                    </p>
                                    {[
                                        [
                                            request.client?.phones?.[0]?.phone,
                                            request.client?.emails?.[0]?.email,
                                            request.client?.phone,
                                            request.client?.email
                                        ].find(Boolean)
                                    ].map((el) => (
                                        <p key={el} className="text-sm font-thin text-pulppo-status-dark-disabled">
                                            {el}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                    {request?.property?._id && (
                        <div className="flex w-full items-center justify-between border-b border-solid border-gray-100 py-5">
                            <p className="text-lg font-bold text-pulppo-primary-black">Propiedad</p>
                            <div className="flex items-center gap-2">
                                <img src={request.property?.pictures?.[0]?.url} className="h-10 w-10" />
                                <div>
                                    <p className="text-lg font-light text-pulppo-primary-black">
                                        {request.property?.address.street}
                                    </p>

                                    <p className="max-w-[150px] truncate text-sm font-thin text-pulppo-status-dark-disabled">
                                        {[
                                            request?.property?.address?.neighborhood?.name,
                                            request?.property?.address?.city?.name,
                                            request?.property?.address?.state?.name
                                        ]
                                            .filter(Boolean)
                                            .join(', ')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                    {request.owner?._id && (
                        <div className="flex w-full items-center justify-between border-b border-solid border-gray-100 py-5">
                            <p className="text-lg font-bold text-pulppo-primary-black">Propietario</p>
                            <div className="flex items-center gap-2">
                                <img src={request.owner?.profilePicture} className="h-10 w-10 rounded-full" />
                                <div>
                                    <p className="text-lg font-light text-pulppo-primary-black">
                                        {request.owner?.firstName} {request.owner?.lastName}
                                    </p>
                                    {[
                                        [
                                            request.owner?.phones?.[0]?.phone,
                                            request.owner?.emails?.[0]?.email,
                                            request?.owner?.phone,
                                            request?.owner?.email
                                        ].find(Boolean)
                                    ].map((el) => (
                                        <p key={el} className="text-sm font-thin text-pulppo-status-dark-disabled">
                                            {el}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                    {request.broker?._id && (
                        <div className="flex w-full items-center justify-between border-solid border-gray-100 py-5">
                            <p className="text-lg font-bold text-pulppo-primary-black">Asesor</p>
                            <div className="flex items-center gap-2">
                                <img src={request.broker?.profilePicture} className="h-10 w-10 rounded-full" />
                                <div>
                                    <p className="text-lg font-light text-pulppo-primary-black">
                                        {request.broker?.firstName} {request.broker?.lastName}
                                    </p>
                                    {[[request?.broker?.phone, request?.broker?.email].find(Boolean)].map((el) => (
                                        <p key={el} className="text-sm font-thin text-pulppo-status-dark-disabled">
                                            {el}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex w-full items-center lg:justify-end">
                <div className="flex w-full items-center gap-4">
                    {onPrevious && (
                        <Button onClick={onPrevious} className="h-auto flex-1 px-4 py-2 text-pulppo-primary-black">
                            Volver un paso atrás
                        </Button>
                    )}
                    {onNext && (
                        <LoadingButton
                            onClick={onNext}
                            className="h-auto flex-1 bg-pulppo-primary-black px-4 py-2 text-white"
                        >
                            Confirmar
                        </LoadingButton>
                    )}
                </div>
            </div>
        </div>
    );
};
interface IRequest {
    broker: ISimpleUser;
    client: ISimpleUser;
    owner: ISimpleUser;
    property: ISimpleProperty;
    provider: {
        _id: string;
        name: string;
        logo: string;
        description: string;
        comission: any;
    };
    type: string;
}
export const CreateServiceRequest = ({ onClose, agent = null }) => {
    const [page, setPage] = useState('Tipo de solicitud');
    const [maxPage, setMaxPage] = useState(0);
    const { user } = useContext(UserContext);
    const operation = useOperation((o) => o.operation);
    const [request, setRequest] = useState({
        broker: agent || user
    } as Partial<IRequest>);

    useEffect(() => {
        if (!agent) return;
        setRequest((r) => ({ ...r, broker: agent }));
    }, [agent]);

    useEffect(() => {
        if (operation?.buyer?.contact?._id) setRequest((r) => ({ ...r, client: operation?.buyer?.contact }));
        if (operation?.property?._id) setRequest((r) => ({ ...r, property: operation?.property }));
    }, [operation?.buyer?.contact?._id, operation?.property?._id]);

    const steps = [
        {
            label: 'Tipo de solicitud',
            render: <TypeStep setRequest={setRequest} onNext={() => setPage(steps[pageIndex + 1].label)} />
        },
        {
            label: 'Datos de la solicitud',
            render: (
                <DataStep
                    request={request}
                    setRequest={setRequest}
                    onNext={() => setPage(steps[pageIndex + 1].label)}
                    onPrevious={() => setPage(steps[pageIndex - 1].label)}
                />
            )
        },
        {
            label: 'Confirmación',
            render: (
                <ConfirmationStep
                    onPrevious={() => setPage(steps[pageIndex - 1].label)}
                    onNext={async () => {
                        await axios.post(`${provider_url}/providers/${request.provider._id}/requests`, {
                            brokerId: request.broker._id,
                            clientId: request.client._id,
                            ownerId: request.owner._id,
                            propertyId: request.property._id,
                            type: request.type
                        });
                        setRequest({
                            broker: agent || user
                        });
                        onClose();
                    }}
                    request={request}
                />
            )
        }
    ];
    const pageIndex = steps.findIndex((step) => step.label == page);

    useEffect(() => {
        if (pageIndex > maxPage) {
            setMaxPage(pageIndex);
        }
    }, [pageIndex, maxPage]);

    return (
        <div className="flex h-full w-full flex-col p-2">
            <div>
                <Typography.Title level={4}>Nueva solicitud de servicio</Typography.Title>
            </div>
            <div className="relative mx-auto w-full lg:w-1/2">
                <div
                    className="absolute bg-gray-200"
                    style={{
                        width: `calc(70% - 1.5rem)`,
                        height: '2px',
                        top: '0.7rem',
                        left: 'calc(15% + 0.75rem)'
                    }}
                ></div>
                <div className="relative z-10 flex w-full items-center gap-2">
                    {steps.map((step, index) => (
                        <div
                            onClick={() => {
                                if (index > maxPage) {
                                    return;
                                }
                                setPage(step.label);
                            }}
                            key={step.label}
                            className="flex w-24 flex-1 cursor-pointer flex-col items-center justify-center"
                        >
                            <div
                                className={`flex h-6 w-6 shrink-0 items-center justify-center rounded-full transition-all ${
                                    pageIndex > index
                                        ? 'bg-primary-color'
                                        : pageIndex == index
                                          ? 'border-4 border-solid border-primary-color bg-white'
                                          : 'bg-gray-200'
                                }`}
                            ></div>
                            <div className={`line-clamp-1 ${pageIndex >= index ? 'text-black' : 'text-gray-200'}`}>
                                {step.label}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="relative mt-8 min-h-[60vh] w-full flex-1 overflow-x-hidden">
                {steps.map((step, index) => (
                    <div
                        className="absolute h-full w-full shrink-0 overflow-y-auto transition-all"
                        style={{
                            left: `${(index - pageIndex) * 100}%`
                        }}
                        key={step.label}
                    >
                        <div className="mx-auto h-full w-full max-w-4xl">{step.render}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};
