import React, { useEffect } from 'react';
import Glide from '@glidejs/glide';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { NextImage } from '../Elements/NextImage';

export const PhotoGallery = ({ idMenuReference, pictures, active, setPicModal, fullScreened = null }) => {
    const [pictureModal, setPictureModal] = React.useState(false);
    const [urlPicture, setUrlPicture] = React.useState('');
    const reference = React.useRef(null);
    const [zoom, setZoom] = React.useState(1.0);
    const zoomInRef = React.useRef(null);
    const glideAPI: any = React.useRef(null);
    const sliderConfiguration = React.useRef({
        type: 'carousel',
        startAt: 0,
        animationTimingFunc: 'ease-in-out',
        perView: 1,
        gap: 0
    });

    useEffect(() => {
        if (fullScreened?.url) {
            showFullScreenPicture(null, fullScreened.url, fullScreened.index);
        }
    }, [fullScreened?.url]);

    React.useEffect(() => {
        if (pictureModal) {
            setPicModal(pictureModal);
            glideAPI.current = new Glide(reference.current, sliderConfiguration.current as any);
            glideAPI.current instanceof Glide ? glideAPI.current.mount() : '';
            glideAPI.current.on('run', function () {
                setUrlPicture(pictures[glideAPI.current.index].url);
            });

            return () => {
                glideAPI.current instanceof Glide ? glideAPI.current.destroy() : '';
            };
        }
    }, [pictureModal, reference]);

    const handleZoomChange = (e, ObjZoom) => {
        if (!zoomInRef.current) {
            zoomInRef.current = e.target.value;
        } else {
            if (ObjZoom.activeC) {
                if (e.target.value > zoomInRef.current) ObjZoom.zoomIn();
                if (e.target.value < zoomInRef.current) ObjZoom.zoomOut();
                zoomInRef.current = e.target.value;
            }
        }

        if (e.type === 'change') {
            setZoom(e.target.value);
        } else {
            setZoom(0);
        }
    };

    const showFullScreenPicture = (e, url, index) => {
        e?.preventDefault?.();
        if (pictureModal) {
            setPictureModal(false);
            setPicModal(false);
        } else {
            sliderConfiguration.current.startAt = index;
            setPictureModal(true);
            setPicModal(true);
            setUrlPicture(url);
        }
    };

    return (
        <div
            className={`${
                idMenuReference === active ? 'component-active' : 'component'
            } ${pictureModal ? 'noScroll' : ''} `}
        >
            {!pictureModal ? (
                //WHEN pictureModal is false show miniature images gallery
                <div className="ImageGridGallery picture-modal">
                    {pictures.map((i, index) => (
                        <>
                            <div
                                key={i._id}
                                onClick={(e) => showFullScreenPicture(e, i.url, index)}
                                className="picture-grid relative"
                                // style={{ backgroundImage: `url(${i.url})` }}
                            >
                                <NextImage
                                    src={i.url}
                                    className="w-full"
                                    width={1920}
                                    height={1080}
                                    fit="inside"
                                    showPreview={false}
                                    alt={''}
                                />
                                <span className="icon-zoom-expand absolute" />
                            </div>
                        </>
                    ))}
                </div>
            ) : (
                //WHEN pictureModal is true show Glide
                <>
                    <div className="picture-details" style={{ padding: `${zoom > 1 ? '0' : ''}` }}>
                        {/* GLIDE SLIDE CAROUSEL */}
                        <div ref={reference} className="glide" style={{ display: `${zoom > 1 ? 'none' : ''}` }}>
                            <div className="glide__arrows" data-glide-el="controls">
                                <button className="glide__arrow glide__arrow--left" data-glide-dir="<">
                                    <span className="icon-arrow-back" />
                                </button>
                            </div>
                            <div className="glide__track" data-glide-el="track">
                                <ul className="glide__slides">
                                    {pictures.map((picture, index) => (
                                        <li
                                            key={index + picture.url}
                                            className="picture-fullscreen glide__slide"
                                            style={{
                                                backgroundImage: `url(${picture.url})`
                                            }}
                                        />
                                    ))}
                                </ul>
                                {window.innerWidth > 992 ? (
                                    <div style={{ height: '82vh' }} className="container">
                                        <div className="glide__bullets" data-glide-el="controls[nav]">
                                            {pictures.map((i, index) => (
                                                <button
                                                    key={i + i.url}
                                                    className="glide__bullet"
                                                    data-glide-dir={`=${index}`}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>

                            <div className="glide__arrows" data-glide-el="controls">
                                <button className="glide__arrow glide__arrow--right" data-glide-dir=">">
                                    <span className="icon-arrow-forward" />
                                </button>
                            </div>
                        </div>

                        {/* ZOOM IMG */}
                        <TransformWrapper
                            zoomAnimation={{ animationType: 'linear' }}
                            onPinching={(e) => setZoom(e.state.scale)}
                            onWheel={(e) => setZoom(e.state.scale)}
                        >
                            {({ zoomIn, zoomOut, resetTransform, centerView }) => (
                                <>
                                    <TransformComponent>
                                        <div
                                            style={{
                                                height: '92vh',
                                                width: '100vw',
                                                display: `${zoom > 1 ? '' : 'none'}`
                                            }}
                                        >
                                            <div
                                                className={'ImagePanZoom'}
                                                style={{
                                                    display: `${zoom > 1 ? '' : 'none'}`,
                                                    backgroundImage: `url(${urlPicture})`
                                                }}
                                            />
                                        </div>
                                    </TransformComponent>

                                    {/*ZOOM MENU FOOTER*/}
                                    <div
                                        className="content-menu"
                                        style={
                                            zoom > 1
                                                ? {
                                                      position: 'absolute',
                                                      bottom: '15px'
                                                  }
                                                : {}
                                        }
                                    >
                                        <p
                                            className="f-Mark-bold"
                                            onClick={(e) => showFullScreenPicture(e, null, null)}
                                            style={{
                                                visibility: `${
                                                    zoom > 1 && window.innerWidth > 992 ? 'hidden' : 'visible'
                                                }`
                                            }}
                                        >
                                            <span className="icon-view-thumbs" />
                                            Ver miniaturas
                                        </p>
                                        <div className="menu-range--wrapper">
                                            <div className="menu-range">
                                                <span
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => {
                                                        setZoom(zoom - 1.3);
                                                        zoomOut();
                                                    }}
                                                    className="icon-remove"
                                                />
                                                <input
                                                    onChange={(e) =>
                                                        handleZoomChange(e, {
                                                            zoomIn,
                                                            zoomOut,
                                                            centerView,
                                                            activeC: true
                                                        })
                                                    }
                                                    type="range"
                                                    min="1"
                                                    max="8"
                                                    value={zoom}
                                                    step="0.5"
                                                    name=""
                                                    id=""
                                                />
                                                <span
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => {
                                                        setZoom(zoom + 1.3);
                                                        zoomIn();
                                                    }}
                                                    className="icon-add"
                                                />
                                            </div>
                                            <div>
                                                {zoom > 1 ? (
                                                    <span
                                                        onClick={(e) => {
                                                            handleZoomChange(e, {
                                                                activeC: false
                                                            });
                                                            resetTransform();
                                                        }}
                                                        className="icon-zoom-collapse"
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </TransformWrapper>
                    </div>
                </>
            )}
        </div>
    );
};
