import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { CreatePropertyButton } from '../CreateProperty/CreateButton';
import { exclusiveMapper, Filters, publishMapper, qualityMapper, valuationMapper } from '../Property/Filters';
import { FilterTag } from './FilterTag';
import { Button, Tabs } from 'antd';
import { OperationAndPriceSelect } from './OperationAndPriceSelect';
import { PropertyTypeSelector } from './PropertyTypeSelector';
import { PropertySort } from './PropertySort';
import { DownOutlined, PlusCircleOutlined, ShareAltOutlined } from '@ant-design/icons';
import { TextSearch } from './TextSearch';
import { PropertyEditMultiple } from './PropertyEditMultiple';
import { PropertyStatusMapper } from '../../utils/status';
import { LoadingButton } from './ButtonLoading';
import { MapButton } from './MapButton';
import { SortArrows } from '../Icon/SortArrows';
import { PulppoMultiselect } from './PulppoMultiselect';
import { IconFilters } from '../Icon/IconFilters';
import { useTranslate } from '@tolgee/react';
export const PropertyListHeader = ({
    showCreateButton,
    setFilters,
    filters,
    user,
    setProperty,
    view,
    setView,
    showFilters,
    tabs,
    actions,
    selectedProperties,
    onShare,
    inCollection,
    setSelectedProperties,
    onSelectAll,
    isAllSelected,
    onTabChange,
    setIsAllSelected
}: {
    showCreateButton: boolean;
    setFilters: (filters: any) => void;
    filters: any;
    user: any;
    setProperty: (property: any) => void;
    view: string;
    setView: Dispatch<SetStateAction<string>>;
    showFilters: boolean;
    tabs: Array<{ label: string | ReactElement; value: string }>;
    actions: any;
    selectedProperties: Array<any>;
    onShare: () => void;
    inCollection: boolean;
    setSelectedProperties: (properties: Array<any>) => void;
    onSelectAll: () => void;
    isAllSelected: boolean;
    onTabChange: (tab: string) => Promise<void>;
    setIsAllSelected: (isAllSelected: boolean) => void;
}) => {
    const [openFilters, setOpenFilters] = useState(false);
    const [tags, setTags] = useState<Array<string>>([]);
    const [openEdit, setOpenEdit] = useState(false);
    const { t } = useTranslate('common');

    useEffect(() => {
        const aux: string[] = [];
        if (filters?.addresses?.length) {
            for (const address of filters?.addresses) {
                aux.push(address.name?.split(',')?.[0]);
            }
        }
        if (filters?.operation && filters?.operation !== 'all')
            aux.push(filters.operation === 'sale' ? 'Comprar' : t('to_rent', 'Rentar'));
        if (parseInt(filters?.price?.min) || parseInt(filters?.price?.max))
            aux.push(`
            ${filters?.price?.currency || process.env.NEXT_PUBLIC_PRIMARY_CURRENCY} 
                ${parseInt(filters.price?.min) ? `${parseInt(filters.price.min).toLocaleString()}` : ''} ${
                    parseInt(filters?.price?.min) && parseInt(filters?.price?.max) ? '-' : ''
                } ${parseInt(filters.price?.max) ? `${parseInt(filters.price.max).toLocaleString()}` : ''} `);
        if (filters?.status?.length) {
            (typeof filters?.status === 'string' ? filters?.status.split(',') : filters?.status).forEach((type) =>
                aux.push(`Estado: ${PropertyStatusMapper[type].label}`)
            );
        }
        if (filters?.sellers?.length) {
            (typeof filters?.sellers === 'string' ? filters?.sellers.split(',') : filters?.sellers).forEach((seller) =>
                aux.push(
                    `Vendedor: ${typeof seller === 'string' ? seller.split('_')[1] : `${seller.firstName} ${seller.lastName}`}`
                )
            );
        }
        if (filters?.producers?.length) {
            (typeof filters?.producers === 'string' ? filters?.producers.split(',') : filters?.producers).forEach(
                (seller) =>
                    aux.push(
                        `Productor: ${typeof seller === 'string' ? seller.split('_')[1] : `${seller.firstName} ${seller.lastName}`}`
                    )
            );
        }
        if (filters?.company?.length) {
            (typeof filters?.company === 'string' ? filters?.company.split(',') : filters?.company).forEach((seller) =>
                aux.push(`Inmobiliaria: ${seller}`)
            );
        }
        if (filters?.quality?.length) {
            (typeof filters?.quality === 'string' ? filters?.quality.split(',') : filters?.quality).forEach((seller) =>
                aux.push(`Calidad: ${qualityMapper[seller]}`)
            );
        }
        if (filters?.valuation?.length) {
            (typeof filters?.valuation === 'string' ? filters?.valuation.split(',') : filters?.valuation).forEach(
                (seller) => aux.push(`Valuación: ${valuationMapper[seller]}`)
            );
        }
        if (filters?.exclusive?.length) {
            (typeof filters?.exclusive === 'string' ? filters?.exclusive.split(',') : filters?.exclusive).forEach(
                (seller) => aux.push(exclusiveMapper[seller])
            );
        }
        if (filters?.published?.length) {
            (typeof filters?.published === 'string' ? filters?.published.split(',') : filters?.published).forEach(
                (seller) => aux.push(publishMapper[seller])
            );
        }
        if (filters?.types) {
            (typeof filters?.types === 'string' ? filters?.types.split(',') : filters?.types).forEach((type) =>
                aux.push(type)
            );
        }
        if (filters?.suites?.length) {
            aux.push(
                (typeof filters?.suites === 'string' ? filters?.suites?.split(',') : filters?.suites)?.reduce(
                    (str, elem, index, arr) => {
                        if (index === 0) {
                            if (arr.length === 1) str = `+${arr[0]} ${t('suites', 'recámaras')?.toLocaleLowerCase()}`;
                            else {
                                if (arr[0] === arr[1])
                                    str = `${arr[0]} ${t('suites', 'recámaras')?.toLocaleLowerCase()}`;
                                else str = `${arr[0]}-${arr[1]} ${t('suites', 'recámaras')?.toLocaleLowerCase()}`;
                            }
                        }
                        return str;
                    },
                    ''
                )
            );
        }
        if (filters?.bathrooms?.length) {
            aux.push(
                (typeof filters?.bathrooms === 'string' ? filters?.bathrooms?.split(',') : filters?.bathrooms)?.reduce(
                    (str, elem, index, arr) => {
                        if (index === 0) {
                            if (arr.length === 1) str = `+${arr[0]} baños`;
                            else {
                                if (arr[0] === arr[1]) str = `${arr[0]} baños`;
                                else str = `${arr[0]}-${arr[1]} baños`;
                            }
                        }
                        return str;
                    },
                    ''
                )
            );
        }
        if (filters?.parkings?.length) {
            aux.push(
                (typeof filters?.parkings === 'string' ? filters?.parkings?.split(',') : filters?.parkings)?.reduce(
                    (str, elem, index, arr) => {
                        if (index === 0) {
                            if (arr.length === 1) str = `+${arr[0]} estacionamientos`;
                            else {
                                if (arr[0] === arr[1]) str = `${arr[0]} estacionamientos`;
                                else str = `${arr[0]}-${arr[1]} estacionamientos`;
                            }
                        }
                        return str;
                    },
                    ''
                )
            );
        }
        if (filters?.roofedSurface) {
            aux.push(`
              ${parseInt(filters?.roofedSurface?.max) ? '' : 'desde'}
                    ${
                        parseInt(filters.roofedSurface?.min)
                            ? `${parseInt(filters.roofedSurface?.min).toLocaleString()}`
                            : ''
                    } ${parseInt(filters?.roofedSurface?.min) && parseInt(filters?.roofedSurface?.max) ? '-' : ''} 
            ${parseInt(filters?.roofedSurface?.min) ? '' : 'hasta'}
            
            ${parseInt(filters?.roofedSurface?.max) ? `${parseInt(filters.roofedSurface?.max).toLocaleString()}` : ''} 
                m2 construídos`);
        }
        if (filters?.totalSurface) {
            aux.push(`
              ${parseInt(filters?.totalSurface?.max) ? '' : 'desde'}
                    ${
                        parseInt(filters.totalSurface?.min)
                            ? `${parseInt(filters.totalSurface?.min).toLocaleString()}`
                            : ''
                    } ${parseInt(filters?.totalSurface?.min) && parseInt(filters?.totalSurface?.max) ? '-' : ''} 
            ${parseInt(filters?.totalSurface?.min) ? '' : 'hasta'}
            
            ${parseInt(filters?.totalSurface?.max) ? `${parseInt(filters.totalSurface?.max).toLocaleString()}` : ''} 
                m2 de terreno`);
        }
        if (filters && 'age' in filters) {
            if (filters.age === -1) aux.push('En construcción');
            else if (filters.age === 0) aux.push('A estrenar');
            else if (filters.age === 50) aux.push('Más de 50 años');
            else aux.push(`Hasta ${filters.age} años`);
        }
        if (filters?.amenities?.length) {
            for (const amenity of filters.amenities) {
                aux.push(amenity);
            }
        }
        setTags(aux);
    }, [filters]);

    const onChangeStateFilter = (value: string[]) => {
        setFilters((filters) => ({
            ...filters,
            status: value
        }));
    };

    return (
        <div className="flex-0 mx-4 hidden shrink-0 md:block">
            <div className="top-0 flex items-center justify-between pt-4">
                <p className="text-lg">Propiedades</p>
                {showCreateButton && <CreatePropertyButton className="shadow-none" />}
            </div>
            <Filters
                hide={{
                    sort: true,
                    location: true,
                    operation: true,
                    type: true,
                    search: true,
                    radius: true,
                    internal: !user
                }}
                setAppliedFilters={setFilters}
                visible={openFilters}
                setVisible={setOpenFilters}
                appliedFilters={filters}
            />
            <div className="pb-4">
                <div className="mb-3 mt-1 flex flex-wrap items-center justify-start gap-2 overflow-hidden">
                    {tags.map((tag, index) =>
                        index < 7 ? (
                            <div className="shrink-0" key={`tag_${tag}`}>
                                <FilterTag text={tag} />
                            </div>
                        ) : index === 7 ? (
                            <div className="shrink-0" key="tag_more">
                                <FilterTag text={`+${tags.length - 7} filtros`} />
                            </div>
                        ) : (
                            <span key={`tag_extra_${index}`}> </span>
                        )
                    )}

                    {tags.length ? (
                        <p
                            className="shrink-0 cursor-pointer font-medium underline underline-offset-4"
                            onClick={() =>
                                setFilters((filters) => ({
                                    tab: filters.tab
                                }))
                            }
                        >
                            Borrar filtros
                        </p>
                    ) : null}
                </div>
                <div className="hidden w-full items-center gap-2 overflow-x-hidden lg:flex">
                    <div className="flex-1">
                        <TextSearch
                            onEnter={(value) => {
                                if (value)
                                    setFilters((filters) => ({
                                        ...filters,
                                        search: value
                                    }));
                                else if (filters?.search) {
                                    setFilters((filters) => ({
                                        ...filters,
                                        search: ''
                                    }));
                                }
                            }}
                            selectedLocations={filters?.addresses || []}
                            onSelectLocation={(location) => {
                                const aux = [...(filters?.addresses || [])];
                                const index = aux.findIndex((elem) => elem.id === location.id);
                                if (index === -1) aux.push(location);
                                else aux.splice(index, 1);
                                setFilters((filters) => ({
                                    ...filters,
                                    addresses: aux
                                }));
                            }}
                            onSelectProperty={(property) => {
                                setProperty(property);
                            }}
                        />
                    </div>

                    <OperationAndPriceSelect
                        value={{
                            operation: filters?.operation || 'all',
                            price: {
                                min: filters?.price?.min || null,
                                max: filters?.price?.max || null,
                                currency: filters?.price?.currency || process.env.NEXT_PUBLIC_PRIMARY_CURRENCY
                            }
                        }}
                        apply={(value) => {
                            setFilters((filters) => ({
                                ...filters,
                                ...value
                            }));
                        }}
                    />
                    <PropertyTypeSelector
                        value={typeof filters?.types === 'string' ? filters?.types.split(',') : filters?.types}
                        apply={(value) =>
                            setFilters((filters) => ({
                                ...filters,
                                types: value
                            }))
                        }
                    />
                    {
                        // Aca utilizo el user para verificar si se muestra este internal, porque en filtros esta puesto como !hide.internal y el internal: !user
                        user && (
                            <PulppoMultiselect
                                allowSearch={false}
                                showButtons
                                className={{ container: 'fit-content' }}
                                options={Object.entries(PropertyStatusMapper).map(([key, { label }]) => ({
                                    key,
                                    label: t(label),
                                    value: key
                                }))}
                                placeholder="Seleccione opciones"
                                title="Estados"
                                onClear={() => setFilters((f) => ({ ...f, status: [] }))}
                                onApply={(values) => setFilters((f) => ({ ...f, status: values }))}
                                onChange={(values) => setFilters((f) => ({ ...f, status: values }))}
                                renderButton={() => (
                                    <Button className="flex h-10 items-center justify-center bg-white px-3 text-center">
                                        Estados {filters?.status?.length ? `(${filters.status.length})` : ''}
                                        <DownOutlined className="text-sm text-black" />
                                    </Button>
                                )}
                                value={filters?.status || []}
                            />
                        )
                    }
                    <div className="">
                        {showFilters && (
                            <Button
                                className="flex h-10 w-full items-center gap-2 bg-white px-3 text-center"
                                onClick={() => setOpenFilters(true)}
                            >
                                Más filtros <IconFilters className="text-sm" />
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            <Tabs
                activeKey={filters?.tab}
                className="no-padding mb-0"
                tabBarStyle={{ marginBottom: 0 }}
                tabBarGutter={15}
                renderTabBar={(props) => {
                    const isValuation = filters?.status?.includes('valuation');

                    return (
                        <div className="flex w-full items-center justify-between">
                            <div className="hidden w-full items-center justify-between pb-2 lg:flex">
                                <div className="flex-1">
                                    <div className="mx-0 flex w-full gap-8">
                                        {tabs.map((t) => (
                                            <button
                                                type="button"
                                                key={`tab-pulppo-${t.value}`}
                                                className="cursor-pointer pl-0"
                                                onClick={async (e) => {
                                                    await onTabChange(t.value);
                                                    props.onTabClick(t.value, e);
                                                }}
                                                disabled={isValuation && t.value !== 'user'}
                                            >
                                                <div
                                                    style={{
                                                        paddingBottom: '4px'
                                                    }}
                                                    className={`border-b-4 border-solid ${
                                                        filters?.tab === t.value
                                                            ? 'border-black font-semibold text-black'
                                                            : 'border-transparent text-pulppo-status-dark-disabled'
                                                    } ${isValuation && t.value !== 'user' ? 'opacity-25' : ''}`}
                                                >
                                                    {t.label}
                                                </div>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                                <div className="">
                                    {actions ||
                                        (selectedProperties.length !== 0 && (
                                            <>
                                                <Button
                                                    className="mr-2 h-11 font-medium"
                                                    onClick={() => {
                                                        setSelectedProperties([]);
                                                        setIsAllSelected(false);
                                                    }}
                                                >
                                                    Cancelar
                                                </Button>

                                                {(filters?.tab === 'user' ||
                                                    (filters?.tab === 'company' &&
                                                        user?.permissions?.properties?.edit)) &&
                                                    !isAllSelected && (
                                                        <LoadingButton
                                                            onClick={onSelectAll}
                                                            className="mr-2 h-11 font-medium"
                                                        >
                                                            Seleccionar Todo
                                                        </LoadingButton>
                                                    )}
                                                {selectedProperties.every(
                                                    (prop) => prop.company?._id == user?.company?._id
                                                ) &&
                                                    (user?.permissions?.properties?.edit ||
                                                        selectedProperties.every(
                                                            (prop) =>
                                                                prop.agent?.uid == user?.uid ||
                                                                prop.agentSeller?.uid == user?.uid
                                                        )) && (
                                                        <Button
                                                            onClick={() => setOpenEdit(true)}
                                                            className="mr-2 h-11 py-2 font-medium"
                                                        >
                                                            Editar ({selectedProperties?.length})
                                                        </Button>
                                                    )}

                                                <Button
                                                    type="primary"
                                                    className="h-11 font-medium"
                                                    onClick={async () => {
                                                        onShare();
                                                    }}
                                                >
                                                    {`${inCollection ? 'Agregar seleccionados' : 'Compartir'} (${
                                                        selectedProperties.length
                                                    })`}
                                                    <ShareAltOutlined />
                                                </Button>
                                            </>
                                        ))}
                                </div>
                            </div>
                            {selectedProperties?.length < 1 && (
                                <div className="hidden items-center gap-2 pb-2 lg:flex">
                                    <PropertySort
                                        filters={filters}
                                        setFilters={setFilters}
                                        render={() => (
                                            <Button className="flex h-8 items-center gap-1 border-none bg-transparent p-0 px-3">
                                                Ordenar
                                                <SortArrows />
                                            </Button>
                                        )}
                                    />
                                    <MapButton
                                        isMapView={view === 'map'}
                                        onToggleView={() => {
                                            setView((prev) => (prev === 'map' ? 'grid' : 'map'));
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    );
                }}
            />

            <PropertyEditMultiple
                open={openEdit && selectedProperties?.length}
                onClose={() => {
                    setOpenEdit(false);
                }}
                user={user}
                setFilters={setFilters}
                selectedProperties={selectedProperties}
                setSelectedProperties={setSelectedProperties}
                setIsAllSelected={setIsAllSelected}
            />
        </div>
    );
};
