import { twMerge } from 'tailwind-merge';
import { Badge } from '../Elements/Badge';
import { ReactNode } from 'react';
import AvatarProperty, { AvatarPropertyProps } from '../Property/AvatarProperty';
import AvatarUser, { AvatarUserProps } from '../Elements/AvatarUser';
import { ColorBadge } from '../../utils/color';

type General = {
    label: string;
    className?: {
        label?: string;
        value?: string;
    };
};

interface BadgeValue {
    color: ColorBadge;
    text: string | number;
}

export type RowListProps =
    | ({
          type?: 'text' | 'title';
          value?: string | number | ReactNode;
      } & General)
    | ({
          type: 'badge';
          value: BadgeValue;
      } & General)
    | ({
          type: 'property';
          value: AvatarPropertyProps;
      } & General)
    | ({
          type: 'user';
          value: AvatarUserProps;
      } & General);

const RowList = ({ label, value, type = 'text', className }: RowListProps) => {
    const valueClassName = twMerge(
        'w-52 text-right',
        type === 'user' && 'flex items-center',
        type === 'text' && 'font-semibold text-pulppo-primary-gray',
        className?.value
    );
    const labelClassName = twMerge(type === 'title' ? 'font-medium text-base' : 'font-light text-sm', className?.label);

    return (
        <div
            className={twMerge(
                'flex w-full items-center justify-between gap-2 border-solid border-gray-100',
                type === 'title' ? 'bg-gray-100 px-7 py-4' : 'border-b px-7 py-3'
            )}
            key={`row-${label}`}
        >
            {type === 'title' ? (
                <>
                    <h5 className={labelClassName}>{label}</h5>
                    {value ? <p className={valueClassName}>{value as string}</p> : null}
                </>
            ) : (
                <>
                    <p className={labelClassName}>{label}</p>
                    {type === 'badge' ? (
                        <Badge color={(value as BadgeValue).color} value={(value as BadgeValue).text} />
                    ) : type === 'property' ? (
                        <div className={valueClassName}>
                            <AvatarProperty {...(value as AvatarPropertyProps)} />
                        </div>
                    ) : type === 'user' ? (
                        <div className={valueClassName}>
                            <AvatarUser
                                {...(value as AvatarUserProps)}
                                isAgent={!!(value as AvatarUserProps).user?.uid}
                                disabled={!(value as AvatarUserProps).user?.uid}
                            />
                        </div>
                    ) : value ? (
                        <p className={valueClassName}>{value as string}</p>
                    ) : null}
                </>
            )}
        </div>
    );
};

export default RowList;
