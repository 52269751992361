import { useTranslate } from '@tolgee/react';
import { normalizeTextAttribute } from '../../utils/normalizeAttributes';
import Icon, { IconType } from '../Icon/Icon';
import { IAttributes } from '@pulppo/pulppo-models';
import { showSpaces } from '../../utils/config';

interface Props extends IAttributes {
    floor: string;
}

interface AttributeProps {
    icon: IconType;
    value: number | string;
    extra?: string;
}

const Attribute = ({ icon, value, extra }: AttributeProps) => {
    const { t } = useTranslate('common');
    return (
        <div className="flex items-center">
            <Icon width={18} height={18} name={icon} />
            <p className="ml-2">
                {normalizeTextAttribute(icon, value, t)}
                {extra ? ` ${extra}` : ''}
            </p>
        </div>
    );
};

export const PrimaryAttributes = ({
    suites,
    bathrooms,
    parkings,
    totalSurface,
    roofedSurface,
    condition,
    spaces,
    yearBuild,
    toilettes,
    floor
}: Props) => {
    return (
        <section className="flex flex-wrap gap-x-4 gap-y-1">
            {suites ? <Attribute icon={IconType.Bed} value={suites} /> : null}
            {spaces && showSpaces ? <Attribute icon={IconType.Door} value={spaces} /> : null}
            {bathrooms ? <Attribute icon={IconType.Bathroom} value={bathrooms} /> : null}
            {parkings ? <Attribute icon={IconType.Garage} value={parkings} /> : null}
            {totalSurface ? <Attribute icon={IconType.Mts} value={totalSurface} extra="Totales" /> : null}
            {roofedSurface ? <Attribute icon={IconType.Mts} value={roofedSurface} extra="Construidos" /> : null}
            {condition ? <Attribute icon={IconType.Upgrade} value={condition} /> : null}
            {yearBuild ? <Attribute icon={IconType.Hourglass} value={yearBuild} /> : null}
            {toilettes ? <Attribute icon={IconType.Toilet} value={toilettes} /> : null}
            {floor ? <Attribute icon={IconType.UpFloor} value={floor} /> : null}
        </section>
    );
};
