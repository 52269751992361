import React, { SVGProps } from 'react';

const ChangeUserSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M8.89132 9.08237C10.5813 9.08237 11.9513 7.71245 11.9513 6.02246C11.9513 4.33247 10.5813 2.9624 8.89132 2.9624C7.20133 2.9624 5.8313 4.33247 5.8313 6.02246C5.8313 7.71245 7.20133 9.08237 8.89132 9.08237Z"
            stroke="#212322"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M3.43127 15.7424C3.43127 12.8024 5.8313 10.4624 8.7113 10.4624C9.7913 10.4624 10.8113 10.7624 11.6513 11.3624"
            stroke="#212322"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M8.29132 13.5823H14.1713"
            stroke="#212322"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M8.29132 13.5823L9.4313 12.2024"
            stroke="#212322"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M14.2313 14.9624H8.29132"
            stroke="#212322"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M14.2313 14.9624L13.0913 16.4624"
            stroke="#212322"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);
export default ChangeUserSvg;
