import { IProperty } from '@pulppo/pulppo-models/build/@types/pulppo-models/schemas/Property';
import ComparableCard from './ComparableCard';

const ComparablesSection = ({
    comparables,
    tab,
    selectedTab,
    className = '',
    restoreComparable,
    removeComparable,
    disabled
}: {
    comparables: IProperty[];
    tab: 'low' | 'medium' | 'high' | null;
    selectedTab: 'low' | 'medium' | 'high' | null;
    className?: string;
    restoreComparable: (id: string) => void;
    removeComparable: (id: string) => void;
    disabled?: boolean;
}) => {
    return (
        <div className={className}>
            {tab === selectedTab && (
                <div className="mt-3 flex h-5/6 flex-1 flex-col gap-3 overflow-y-auto overflow-x-hidden">
                    {comparables
                        ?.filter((cmp) => cmp?.valuation?.result === tab)
                        .sort((a, b) => a.valuation?.value - b.valuation?.value)
                        .map((cmp) => (
                            <ComparableCard
                                key={cmp._id as any}
                                property={cmp}
                                showActions={true}
                                className=""
                                remove={() => {
                                    removeComparable(cmp._id?.toString());
                                }}
                                restore={() => {
                                    restoreComparable(cmp._id?.toString());
                                }}
                                disabled={disabled}
                            />
                        ))}
                </div>
            )}
        </div>
    );
};

export default ComparablesSection;
