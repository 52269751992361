import { IAgent, ICompany, ISimpleCompany } from '@pulppo/pulppo-models';
import { ReactNode, useEffect, useState } from 'react';
import useSWR from 'swr';
import { api_url, fetcher } from '../../helpers/fetcher';
import { NextImage } from './NextImage';
import { PulppoSelect } from './PulppoSelect';
import { CompanySelect } from './CompanySelect';
import { twMerge } from 'tailwind-merge';

const map = {
    properties: 'Propiedades',
    contacts: 'Contactos',
    company: 'Inmobiliaria',
    messages: 'Mensajes',
    searches: 'Búsquedas',
    stats: 'Estadísticas'
};

export const AgentSelectContacts = ({
    renderButton,
    allowAll,
    user,
    className,
    onChange,
    onChangeCompany
}: {
    renderButton: () => ReactNode;
    allowAll: boolean;
    user: IAgent;
    className: string;
    onChange: (val: IAgent) => void;
    onChangeCompany: (val: ISimpleCompany) => void;
}) => {
    const [selectedAgent, setSelectedAgent] = useState(user);
    const allCompanies = false;
    const [company, setCompany] = useState<ISimpleCompany>(user?.company);
    const { data: companies } = useSWR<{
        result: Array<ICompany>;
    }>(allCompanies && `${api_url}/company`, fetcher);
    const { data: agents, isLoading: isLoadingAgents } = useSWR<IAgent[]>(
        company?._id && `${api_url}/company/${company?._id}/agents`,
        fetcher
    );

    useEffect(() => {
        setSelectedAgent(null);
    }, []);

    useEffect(() => {
        setCompany(user?.company);
    }, [user?.company]);

    useEffect(() => {
        onChangeCompany?.(company);
    }, [company]);

    return user?.permissions?.contacts?.view && !isLoadingAgents ? (
        <div className={twMerge(['w-full md:w-auto', className])}>
            <PulppoSelect
                filter={companies && <CompanySelect onChange={(val) => setCompany(val)} value={company} />}
                value={selectedAgent?.uid}
                onChange={(val) => {
                    if (val === 'all') {
                        const agent = {
                            uid: 'all',
                            firstName: 'Todos',
                            lastName: 'los asesores',
                            company: company
                        };

                        setSelectedAgent(agent as IAgent);
                        onChange?.(agent as IAgent);
                        return;
                    }
                    const newAgents = agents.find((a) => a.uid == val);
                    setSelectedAgent(newAgents);
                    onChange?.(newAgents);
                }}
                options={[
                    ...[
                        {
                            value: user?.uid,
                            label: `Mis ${map['contacts']}`,
                            key: 'own'
                        },
                        undefined
                    ],
                    ...(allowAll
                        ? [
                              {
                                  value: 'all',
                                  label: 'Todos los asesores',
                                  key: 'all'
                              }
                          ]
                        : []),
                    ...(agents || [])
                        ?.filter((a) => a.uid != user?.uid)
                        .map((ag) => ({
                            value: ag.uid,
                            icon: (
                                <NextImage
                                    showPreview={false}
                                    src={
                                        ag?.profilePicture ||
                                        `https://ui-avatars.com/api/?name=${ag?.firstName}%20${ag?.lastName}`
                                    }
                                    alt={ag.profilePicture}
                                    width={64}
                                    height={64}
                                    className="h-6 w-6 rounded-full"
                                />
                            ),
                            label: `${ag.firstName} ${ag.lastName}`,
                            key: ag.uid
                        }))
                ].filter(Boolean)}
                renderButton={renderButton}
                className="w-full py-2"
            />
        </div>
    ) : (
        <div />
    );
};
