import axios from '../utils/axios';
import { wapp_url } from '../helpers/fetcher';
import { ISimpleUser } from '@pulppo/pulppo-models';
const THIRTY_SECONDS = 30e3;

export const sendMessageVCard = async ({
    user,
    toPhone,
    onPreSend,
    timeMS
}: {
    user: ISimpleUser;
    toPhone: string;
    onPreSend?: () => Promise<void>;
    timeMS?: number;
}) => {
    const vcard = `BEGIN:VCARD
VERSION:3.0
FN;CHARSET=UTF-8:${user?.firstName} ${user?.lastName}
N;CHARSET=UTF-8:${user?.lastName};${user?.firstName};;;
EMAIL;CHARSET=UTF-8;type=HOME,INTERNET:${user?.email}
TEL;type=CELL;type=VOICE;waid=${user.phone?.replace(/\D/gi, '')}:+${user.phone?.replace(/\D/gi, '')}
TITLE;CHARSET=UTF-8:Asesor inmobiliario
ORG;CHARSET=UTF-8:${user?.company?.name} by ${process.env.NEXT_PUBLIC_APP_NAME || 'Pulppo'}
URL;type=WORK;CHARSET=UTF-8:${process.env.NEXT_PUBLIC_PUBLIC_URL || 'https://pulppo.com'}
REV:2023-04-01T01:09:21.872Z
END:VCARD`;
    setTimeout(async () => {
        onPreSend && (await onPreSend());
        await axios
            .post(`${wapp_url}/message-attachment/${user?.uid}`, {
                to: toPhone?.replace(/\D/gi, ''),
                attachment: {
                    contacts: {
                        displayName: `${user.firstName} ${user.lastName}`,
                        contacts: [
                            {
                                vcard,
                                displayName: `${user.firstName} ${user.lastName}`
                            }
                        ]
                    }
                }
            })
            .catch((err) => {
                console.error(`No se pudo enviar el vcard: ${err}`);
            });
    }, timeMS ?? THIRTY_SECONDS);
};
