import axios from '../utils/axios';
import { api_url } from '../helpers/fetcher';
import { ID } from '../types';
import { IOperation } from '@pulppo/pulppo-models';

interface Price {
    price: number;
    currency: string;
}

interface Note {
    _id?: ID;
    content: string;
    files: Array<string>;
}

interface CreateOperationBuyer {
    contactId?: ID;
    companyId?: ID;
    brokerId: ID;
    sharePercentage?: number;
}

interface CreateOperationSeller {
    contactId: ID;
    companyId?: ID;
    producerId: ID;
    brokerId: ID;
    sharePercentage?: number;
    commissionRate?: number;
}

export const createOperation = async (operation: {
    propertyId: ID;
    side: string;
    searchId: ID;
    buyer: CreateOperationBuyer;
    seller: CreateOperationSeller;
    comission: Price & {
        differenceReason?: string;
        invoiceUrl?: string;
    };
    status: string;
    reserveValue: Price;
    closeValue: Price;
    expectedClosedAt: Date;
    payments: Array<number>;
    notes: Array<Note>;
}): Promise<IOperation | string | null> => {
    return axios
        .post(`${api_url}/operation`, operation)
        .then((r) => r.data.data)
        .catch((e) => {
            return e?.response?.data?.error || e?.response?.data?.message;
        });
};

export const updateOperation = async (
    operationId: ID,
    operation: {
        status?: string;
        notes?: Array<Note>;
        reserveValue?: Price;
        closeValue?: Price;
        comission?: Price & {
            differenceReason?: string;
            invoiceUrl?: string;
        };
        expectedClosedAt?: Date;
        payments?: Array<number>;
        side?: string;
        propertyId?: ID;
        buyer?: {
            contactId?: ID;
            brokerId?: ID;
            sharePercentage?: number;
        };
        seller?: {
            contactId?: ID;
            brokerId?: ID;
            producerId?: ID;
            commissionRate?: number;
            sharePercentage?: number;
        };
        cancelReason?: {
            id: string;
            description?: string;
        };
    }
): Promise<IOperation | string | null> => {
    return axios
        .patch(`${api_url}/operation/${operationId}`, operation)
        .then((r) => r.data.data)
        .catch((e) => {
            return e?.response?.data?.error || e?.response?.data?.message;
        });
};

export const closeOperation = async (operationId: ID, closeIdForce?: string): Promise<void> => {
    return axios
        .patch(`${api_url}/operation/${operationId}/close`, {
            closeIdForce
        })
        .then((r) => r.data.data);
};

export const removeLastPaymentOperation = async (operationId: ID): Promise<void> => {
    await axios.patch(`${api_url}/operation/${operationId}/removeLastPayment`);
};

export const validCreateOperationBuyer = async (props: {
    side: string;
    buyer: CreateOperationBuyer;
}): Promise<boolean | string> => {
    return axios
        .post(`${api_url}/operation/valid-create/buyer`, props)
        .then((r) => r.data.data)
        .catch((e) => {
            return e?.response?.data?.error || e?.response?.data?.message;
        });
};

export const validCreateOperationSeller = async (props: {
    propertyId: ID;
    side: string;
    seller: CreateOperationSeller;
}): Promise<boolean | string> => {
    return axios
        .post(`${api_url}/operation/valid-create/seller`, props)
        .then((r) => r.data)
        .catch((e) => {
            return e?.response?.data?.error || e?.response?.data?.message;
        });
};

export const operationAddPayments = async (operationId: ID, newPayments: Array<number>) => {
    await axios.patch(`${api_url}/operation/${operationId}/payment`, {
        payments: newPayments
    });
};
