import dayjs from 'dayjs';
import { PaperClipOutlined } from '@ant-design/icons';
import { INote } from '@pulppo/pulppo-models';

interface Props extends INote {}

const Note = ({ content, creator, createdAt, files }: Props) => {
    return (
        <div className="border border-solid border-pulppo-secondary-gray-disabled p-2">
            <div className="flex w-full gap-2">
                <div className="shrink-0">
                    <img
                        src={creator?.profilePicture}
                        alt={creator?.firstName}
                        className="h-5 w-5 rounded-full object-cover"
                    />
                </div>
                <div className="w-full overflow-x-hidden">
                    <div className="flex items-center justify-between gap-4">
                        <p className="text-sm font-medium">
                            {creator?.firstName} {creator?.lastName}
                        </p>
                        <p className="text-xs text-pulppo-primary-gray">{dayjs(createdAt).tz().fromNow()}</p>
                    </div>
                    <p className="text-xs text-pulppo-primary-gray">{content}</p>
                    {files?.length ? (
                        <div className="mt-4 flex w-full flex-wrap items-center gap-2">
                            {files.map((file) => (
                                <div
                                    key={file}
                                    onClick={() => {
                                        const a = document.createElement('a');
                                        a.href = file;
                                        a.download = decodeURIComponent(file).split('/').pop();
                                        a.target = '_blank';
                                        document.body.appendChild(a);
                                        a.click();
                                        document.body.removeChild(a);
                                    }}
                                    className="shrink-0 cursor-pointer rounded-full bg-gray-200 px-4 py-1"
                                >
                                    <PaperClipOutlined className="mr-2" />

                                    {decodeURIComponent(file).split('/').pop()}
                                </div>
                            ))}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default Note;
