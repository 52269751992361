import { useEffect, useRef } from 'react';
import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { useRouter } from 'next/router';
import { LoyaltyBadges } from './badges';
import { api_url, fetcher } from '../../helpers/fetcher';
import useUser from '../../hooks/useUser';
import { BadgesDrawer } from './BadgesDrawer';
import { EventCard } from './EventCard';
import { BadgeCard } from './BadgeCard';
import { EventsDrawer } from './EventDrawer';
import { LoyaltyProgress } from './LoyaltyProgress';
import { openBenefits } from '../../utils/openBenefits';
import { LoyaltyEmptyDrawer } from './LoyaltyEmptyDrawer';
import useSWR from 'swr';
import { IAgent, ISimpleUser } from '@pulppo/pulppo-models';
import { Skeleton } from 'antd';
import { LoadingButton } from '../Elements/ButtonLoading';

const EliteBadge = () => <img src="/icons/loyalty/elite_pulppo.png" className="h-16 w-16" />;

const ProBadge = () => <img src="/icons/loyalty/professional_pulppo.png" className="h-16 w-16" />;

const StdBadge = () => <img src="/icons/loyalty/standard_pulppo.png" className="h-16 w-16" />;
export interface LoyaltyEvent {
    _id: string;
    minLevel?: IAgent['level'];
    photo?: string;
    title: string;
    date: Date;
    description?: string;
    seats?: number;
    participants?: ISimpleUser[];
}
export const levels = [
    {
        id: 'standard',
        title: 'Estándar',
        image: <StdBadge />,
        features: [
            {
                text: 'Certificación Pulppo Academy'
            },
            {
                text: 'Miembro de la comunidad Pulppo'
            },
            {
                text: '1x puntos'
            },
            {
                text: 'Sin compensaciones por referidos',
                disabled: true
            },
            {
                text: 'Sin acceso a experiencias',
                disabled: true
            },
            {
                text: 'Sin impresión de un paquete de tarjetas de presentación o flyers',
                disabled: true
            }
        ]
    },
    {
        id: 'professional',
        title: 'Profesional',
        image: <ProBadge />,
        features: [
            {
                text: '$MIN de facturación en comisiones de los últimos 6 meses'
            },
            {
                text: 'Miembro de la comunidad Pulppo'
            },
            {
                text: '2x puntos'
            },
            {
                text: 'Compensaciones por referidos'
            },
            {
                text: 'Acceso a experiencias'
            },
            {
                text: 'Sin impresión de un paquete de tarjetas de presentación o flyers',
                disabled: true
            }
        ]
    },
    {
        id: 'elite',
        title: 'Elite',
        image: <EliteBadge />,
        features: [
            {
                text: '$MIN de facturación en comisiones de los últimos 6 meses'
            },
            {
                text: 'Embajador de la comunidad Pulppo'
            },
            {
                text: '3x puntos'
            },
            {
                text: 'Compensaciones por referidos'
            },
            {
                text: 'Acceso preferencial a experiencias'
            },

            {
                text: 'Impresión de un paquete de tarjetas de presentación o flyers'
            }
        ]
    }
];

export const getLevelTresholds = (currentLevel) => ({
    max: currentLevel === 'professional' || currentLevel === 'elite' ? 600e3 : 100e3,
    min: currentLevel === 'standard' ? 0 : currentLevel === 'professional' ? 100e3 : 600e3
});

export const LoyaltyDrawer = () => {
    const uid = useUser((u) => u.user?.uid);
    if (!uid) return <></>;

    return <LoyaltyDrawerInner />;
};
export const LoyaltyDrawerInner = () => {
    const uid = useUser((u) => u.user?.uid);
    const badges = useUser((u) => u.user?.badges);
    const currentLevel = useUser((u) => u.user?.level);
    const comission = useUser((u) => u.user?.comission);

    const {
        data: events,
        mutate: refreshEvents,
        isValidating: loadingEvents
        // isLoading: loadingEvents
    } = useSWR<LoyaltyEvent[]>(`${api_url}/loyalty/events`, fetcher);
    const router = useRouter();

    const ref = useRef<HTMLDivElement>();

    const OBJECTIVE_MAX = getLevelTresholds(currentLevel).max;
    const OBJECTIVE_MIN = getLevelTresholds(currentLevel).min;

    useEffect(() => {
        if (!ref.current) return;
        function scrollIntoViewHorizontally(container, child) {
            // Calculate the width and horizontal center of the container
            const containerWidth = container.offsetWidth;
            const containerHorizontalCenter = container.offsetLeft + containerWidth / 2;

            // Calculate the width and horizontal center of the child
            const childWidth = child.offsetWidth;
            const childHorizontalCenter = child.offsetLeft + childWidth / 2;

            // Calculate the distance to scroll
            const distanceToScroll = childHorizontalCenter - containerHorizontalCenter;

            // Scroll the container to center the child
            container.scrollLeft += distanceToScroll;
        }
        scrollIntoViewHorizontally(ref.current.parentElement, ref.current);
    }, [router.query.loyalty]);

    const text =
        currentLevel === 'standard'
            ? `Alcanza el próximo nivel superando los ${process.env.NEXT_PUBLIC_PRIMARY_CURRENCY || 'MXN'} $${OBJECTIVE_MAX.toLocaleString()} en comisiones acumuladas de los últimos 6 meses`
            : currentLevel === 'professional'
              ? `Asegura una facturación superior a los  ${process.env.NEXT_PUBLIC_PRIMARY_CURRENCY || 'MXN'} $${OBJECTIVE_MIN.toLocaleString()} para mantenerte o avanza al próximo nivel superando los MXN $${OBJECTIVE_MAX.toLocaleString()} en comisiones acumuladas de los últimos 6 meses`
              : `Mantén una facturación superior a los  ${process.env.NEXT_PUBLIC_PRIMARY_CURRENCY || 'MXN'} $${OBJECTIVE_MIN.toLocaleString()} para mantener tu nivel en comisiones acumuladas de los últimos 6 meses`;

    if (!currentLevel) return <LoyaltyEmptyDrawer />;
    return (
        <PulppoDrawer
            title={<img src="/icons/loyalty/pulppo-plus.svg" />}
            bodyStyle={{ padding: 0 }}
            visible={router.query.loyalty === 'true'}
            onClose={() => {
                delete router.query.loyalty;
                delete router.query.badges;
                delete router.query.events;
                router.push(router);
            }}
        >
            <div className="relative flex h-full flex-col overflow-y-hidden">
                <div className="shrink-1 flex flex-1 flex-col gap-5 overflow-y-scroll">
                    <div className="flex flex-col gap-5 bg-pulppo-primary-black p-5 text-white">
                        <div className="flex gap-5">
                            <div className="flex flex-1 flex-col items-center justify-center border-b border-solid border-pulppo-secondary-gray-disabled pb-2 pl-5">
                                {levels.find((l) => l.id === currentLevel).image}
                                <h5 className="text-white"> {levels.find((l) => l.id === currentLevel).title}</h5>
                            </div>
                            <div className="flex-1 pr-5">
                                <p className="text-white">{text}</p>
                            </div>
                        </div>
                        <LoyaltyProgress level={currentLevel} comission={comission} max={OBJECTIVE_MAX} />
                        {comission < OBJECTIVE_MIN ? (
                            <p className="px-4 text-sm">
                                Tu facturación ha bajado y no cumple con los requisitos del nivel{' '}
                                {levels?.find((l) => l.id === currentLevel)?.title}. El próximo mes lo verás reflejado
                            </p>
                        ) : comission > OBJECTIVE_MAX && currentLevel !== 'elite' ? (
                            <p className="px-4 text-sm">
                                Objetivo de facturación alcanzado. El próximo mes lo verás reflejado
                            </p>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="flex flex-col gap-5 pb-5 pl-5">
                        <div className="pb-4">
                            <div className="flex items-center justify-between pb-2 pr-5">
                                <h5 className="font-bold">Insignias</h5>
                                <a
                                    className="underline"
                                    onClick={() => {
                                        router.query.badges = 'true';
                                        router.push(router);
                                    }}
                                >
                                    ver más
                                </a>
                            </div>
                            <div className="no-wrap flex items-center gap-2 overflow-x-scroll">
                                {LoyaltyBadges.filter((b) => badges?.[b.id]).map((badge) => (
                                    <BadgeCard
                                        onClick={() => {
                                            router.query.badges = 'true';
                                            router.query.badge = badge.id;
                                            router.push(router);
                                        }}
                                        border={false}
                                        badge={badge}
                                        key={badge.title}
                                    />
                                ))}
                                {LoyaltyBadges.filter((b) => !badges?.[b.id]).map((badge) => (
                                    <BadgeCard
                                        onClick={() => {
                                            router.query.badges = 'true';
                                            router.query.badge = badge.id;
                                            router.push(router);
                                        }}
                                        border={false}
                                        badge={badge}
                                        key={badge.title}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className="pr-5">
                            <div className="h-px bg-pulppo-secondary-gray-disabled" />
                        </div>
                        {events?.length ? (
                            <>
                                <div className="pb-4">
                                    <div className="flex items-center justify-between pb-2 pr-5">
                                        <h5 className="font-bold">Experiencias</h5>
                                        <a
                                            className="underline"
                                            onClick={() => {
                                                router.query.events = 'true';
                                                router.push(router);
                                            }}
                                        >
                                            ver más
                                        </a>
                                    </div>
                                    {loadingEvents ? (
                                        <div className="no-wrap flex items-stretch gap-2 overflow-x-scroll">
                                            <Skeleton className="h-[50px] w-[350px]" />
                                        </div>
                                    ) : (
                                        <div className="no-wrap flex items-stretch gap-2 overflow-x-scroll">
                                            {events?.map((event) => (
                                                <EventCard
                                                    onEventChange={() => refreshEvents()}
                                                    onClick={() => {
                                                        router.query.events = 'true';
                                                        router.push(router);
                                                    }}
                                                    event={event}
                                                    key={event.title}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className="pr-5">
                                    <div className="h-px bg-pulppo-secondary-gray-disabled" />
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                        <div className="flex flex-1 flex-col">
                            <div className="flex items-center justify-between pb-2 pr-5">
                                <h5 className="font-bold">Niveles</h5>
                            </div>
                            <div className="no-wrap flex items-stretch gap-2 overflow-x-scroll py-5 pr-12">
                                {levels.map((level) => (
                                    <div
                                        key={level.id}
                                        ref={level.id === currentLevel ? ref : null}
                                        style={{
                                            width: '240px',
                                            marginLeft: level.id === currentLevel ? '20px' : '0',
                                            marginRight: level.id === currentLevel ? '20px' : '0',
                                            transform: level.id === currentLevel ? 'scale(1.05)' : ''
                                        }}
                                        className={`shrink-0 p-5 pt-10 transition-all duration-300 ${
                                            level.id === currentLevel
                                                ? 'bg-pulppo-primary-black text-white'
                                                : 'border border-solid border-pulppo-secondary-gray-disabled bg-white'
                                        }`}
                                    >
                                        <div className="flex w-full items-center justify-center">{level.image}</div>
                                        <h4
                                            className={`text-center ${
                                                level.id === currentLevel ? 'text-white' : 'text-black'
                                            } text-lg font-medium`}
                                        >
                                            {level.title}
                                        </h4>
                                        <div
                                            className={`my-2 h-px ${
                                                level.id === currentLevel
                                                    ? 'bg-white'
                                                    : 'bg-pulppo-secondary-gray-disabled'
                                            }`}
                                        />
                                        <div className="flex flex-col gap-2">
                                            {level.features.map((feature, i) => (
                                                <div
                                                    key={`${level.id}_feature_${i}`}
                                                    className={`flex items-center gap-2 ${
                                                        feature.disabled ? 'opacity-30' : ''
                                                    }`}
                                                >
                                                    <div>
                                                        {feature.disabled ? (
                                                            level.id === currentLevel ? (
                                                                <UncheckedIconWhite />
                                                            ) : (
                                                                <UncheckedIcon />
                                                            )
                                                        ) : level.id === currentLevel ? (
                                                            <CheckIconWhite />
                                                        ) : (
                                                            <CheckIconBlack />
                                                        )}
                                                    </div>
                                                    <p className="text-sm font-light">
                                                        {feature.text.replace(
                                                            'MIN',
                                                            getLevelTresholds(level.id).min.toLocaleString()
                                                        )}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {process.env.NEXT_PUBLIC_NO_LOYALTY_PROVIDER !== 'true' && (
                    <div className="p-5">
                        <LoadingButton
                            onClick={async () => {
                                await openBenefits(uid);
                            }}
                            className="h-auto w-full bg-pulppo-primary-black py-2 text-white"
                        >
                            {' '}
                            Canjear puntos
                        </LoadingButton>
                    </div>
                )}
            </div>
            <BadgesDrawer />
            <EventsDrawer events={events} onEventChange={() => refreshEvents()} />
        </PulppoDrawer>
    );
};

export const CheckIconBlack = () => {
    return (
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_175_2788)">
                <path
                    d="M6.00781 0.959961C3.10859 0.959961 0.757812 3.31074 0.757812 6.20996C0.757812 9.10918 3.10859 11.46 6.00781 11.46C8.90703 11.46 11.2578 9.10918 11.2578 6.20996C11.2578 3.31074 8.90703 0.959961 6.00781 0.959961ZM8.27539 4.49551L5.80742 7.91738C5.77293 7.96553 5.72746 8.00476 5.67477 8.03182C5.62209 8.05888 5.56372 8.073 5.50449 8.073C5.44527 8.073 5.38689 8.05888 5.33421 8.03182C5.28153 8.00476 5.23606 7.96553 5.20156 7.91738L3.74023 5.89238C3.6957 5.83027 3.74023 5.74356 3.81641 5.74356H4.36602C4.48555 5.74356 4.59922 5.80098 4.66953 5.89941L5.50391 7.05723L7.34609 4.50254C7.41641 4.40527 7.52891 4.34668 7.64961 4.34668H8.19922C8.27539 4.34668 8.31992 4.4334 8.27539 4.49551Z"
                    fill="#212322"
                />
            </g>
            <defs>
                <clipPath id="clip0_175_2788">
                    <rect width="12" height="12" fill="white" transform="translate(0.0078125 0.209961)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const CheckIconWhite = () => {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.3623 1.20984C3.46309 1.20984 1.1123 3.56062 1.1123 6.45984C1.1123 9.35906 3.46309 11.7098 6.3623 11.7098C9.26152 11.7098 11.6123 9.35906 11.6123 6.45984C11.6123 3.56062 9.26152 1.20984 6.3623 1.20984ZM8.62988 4.74539L6.16191 8.16726C6.12742 8.21541 6.08195 8.25463 6.02927 8.2817C5.97658 8.30876 5.91821 8.32288 5.85898 8.32288C5.79976 8.32288 5.74138 8.30876 5.6887 8.2817C5.63602 8.25463 5.59055 8.21541 5.55605 8.16726L4.09473 6.14226C4.0502 6.08015 4.09473 5.99343 4.1709 5.99343H4.72051C4.84004 5.99343 4.95371 6.05085 5.02402 6.14929L5.8584 7.3071L7.70059 4.75242C7.7709 4.65515 7.8834 4.59656 8.0041 4.59656H8.55371C8.62988 4.59656 8.67441 4.68328 8.62988 4.74539V4.74539Z"
                fill="white"
            />
        </svg>
    );
};

export const UncheckedIcon = () => {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_175_6204)">
                <path
                    d="M6.01135 1.20984C3.11213 1.20984 0.761353 3.56062 0.761353 6.45984C0.761353 9.35906 3.11213 11.7098 6.01135 11.7098C8.91057 11.7098 11.2614 9.35906 11.2614 6.45984C11.2614 3.56062 8.91057 1.20984 6.01135 1.20984ZM7.94963 8.45437L7.1762 8.45085L6.01135 7.06218L4.84768 8.44968L4.07307 8.4532C4.02151 8.4532 3.97932 8.41218 3.97932 8.35945C3.97932 8.33718 3.98752 8.31609 4.00159 8.29851L5.5262 6.4821L4.00159 4.66687C3.98743 4.64969 3.97957 4.62819 3.97932 4.60593C3.97932 4.55437 4.02151 4.51218 4.07307 4.51218L4.84768 4.5157L6.01135 5.90437L7.17502 4.51687L7.94846 4.51335C8.00002 4.51335 8.04221 4.55437 8.04221 4.6071C8.04221 4.62937 8.03401 4.65046 8.01995 4.66804L6.49768 6.48328L8.02112 8.29968C8.03518 8.31726 8.04338 8.33835 8.04338 8.36062C8.04338 8.41218 8.0012 8.45437 7.94963 8.45437Z"
                    fill="#212322"
                />
            </g>
            <defs>
                <clipPath id="clip0_175_6204">
                    <rect width="12" height="12" fill="white" transform="translate(0.0113525 0.459839)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const UncheckedIconWhite = () => {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_175_2764)">
                <path
                    d="M6.66455 0.959961C3.76533 0.959961 1.41455 3.31074 1.41455 6.20996C1.41455 9.10918 3.76533 11.46 6.66455 11.46C9.56377 11.46 11.9146 9.10918 11.9146 6.20996C11.9146 3.31074 9.56377 0.959961 6.66455 0.959961ZM8.60283 8.20449L7.82939 8.20098L6.66455 6.8123L5.50088 8.1998L4.72627 8.20332C4.67471 8.20332 4.63252 8.1623 4.63252 8.10957C4.63252 8.0873 4.64072 8.06621 4.65479 8.04863L6.17939 6.23223L4.65479 4.41699C4.64062 4.39982 4.63277 4.37831 4.63252 4.35605C4.63252 4.30449 4.67471 4.2623 4.72627 4.2623L5.50088 4.26582L6.66455 5.65449L7.82822 4.26699L8.60166 4.26348C8.65322 4.26348 8.69541 4.30449 8.69541 4.35723C8.69541 4.37949 8.68721 4.40059 8.67314 4.41816L7.15088 6.2334L8.67432 8.0498C8.68838 8.06738 8.69658 8.08848 8.69658 8.11074C8.69658 8.1623 8.65439 8.20449 8.60283 8.20449Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_175_2764">
                    <rect width="12" height="12" fill="white" transform="translate(0.664551 0.209961)" />
                </clipPath>
            </defs>
        </svg>
    );
};
