import { ReactNode } from 'react';
import Link from 'next/link';
import { RightOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import { twMerge } from 'tailwind-merge';

interface Props {
    level: number;
    children: ReactNode;
    icon?: ReactNode;
    href?: string;
    description?: ReactNode | string;
    style?: {
        mdDown?: boolean;
        containerClassName?: string;
        chevronRightVisible?: boolean;
    };
    rightElement?: ReactNode;
    isLoading?: boolean;
    className?: string;
}

const Title = ({ level = 1, children, icon, href, description, style, rightElement, isLoading, className }: Props) => {
    let title = <div />;
    if (level === 2) {
        title = <h2 className={twMerge('font-mark text-base font-bold', className)}>{children}</h2>;
    } else if (level === 3) {
        title = <h3 className={twMerge('font-mark text-base font-bold', className)}>{children}</h3>;
    }
    return (
        <div className={twMerge('relative', style?.containerClassName)}>
            <div className="flex items-center justify-between">
                <div className="flex flex-grow items-center gap-x-2">
                    {icon ? icon : null}
                    {icon && isLoading ? (
                        <Skeleton
                            title={{
                                width: '80%'
                            }}
                            paragraph={false}
                            active
                        />
                    ) : (
                        <div className={twMerge(style?.chevronRightVisible && 'pr-5', style?.mdDown && 'lg:hidden')}>
                            {title}
                            {description ? <p className="text-sm text-black">{description}</p> : null}
                        </div>
                    )}
                </div>
                {href && !rightElement ? (
                    <Link href={href}>
                        <a className="flex items-center">
                            <RightOutlined className="text-xl text-black" />
                        </a>
                    </Link>
                ) : null}
                {style?.chevronRightVisible ? (
                    <RightOutlined
                        className={twMerge('text-xl text-black', !icon && 'absolute right-0 top-1/2 -translate-y-1/2')}
                    />
                ) : null}
                {rightElement ? rightElement : null}
            </div>
            {style?.mdDown ? (
                <>
                    {isLoading ? (
                        <Skeleton
                            title={{
                                width: '80%'
                            }}
                            paragraph={false}
                            active
                        />
                    ) : (
                        <div
                            className={twMerge(
                                style?.chevronRightVisible && 'pr-5',
                                style.mdDown && 'mt-1 hidden lg:block'
                            )}
                        >
                            {title}
                            {description ? <p className="mt-0.5 text-sm text-black">{description}</p> : null}
                        </div>
                    )}
                </>
            ) : null}
        </div>
    );
};

export default Title;
