import { PulppoDrawer, PulppoDrawerProps } from '../Elements/PulppoDrawer';
import AgentsList, { BasicColor } from './AgentsList';
import useSWR from 'swr';
import { IAgent } from '@pulppo/pulppo-models';
import { api_url, fetcher } from '../../helpers/fetcher';
import { METRICS_DESCRIPTION } from '../../utils/constants';

interface Props extends PulppoDrawerProps {
    companyId: string;
}

const DrawerWhatsapp = ({ companyId, ...props }: Props) => {
    const { data: agents } = useSWR<Array<IAgent>>(`${api_url}/company/${companyId}/agents?filterBy=status`, fetcher);

    return (
        <PulppoDrawer className="text-base" title={METRICS_DESCRIPTION.whatsapp.title} {...props}>
            <AgentsList
                filter
                agents={agents}
                actions={{
                    sort: (x, y) => (x.whatsapp === y.whatsapp ? 0 : x.whatsapp ? 1 : -1),
                    tag: [
                        {
                            condition: (agent) => (agent.available ? BasicColor.Good : BasicColor.Wrong),
                            text: {
                                success: 'Disponible',
                                error: 'No disponible'
                            }
                        },
                        {
                            condition: (agent) => (agent.whatsapp ? BasicColor.Good : BasicColor.Wrong),
                            text: {
                                success: 'Vinculado',
                                error: 'No vinculado'
                            }
                        }
                    ]
                }}
            />
        </PulppoDrawer>
    );
};

export default DrawerWhatsapp;
