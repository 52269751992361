import { IProperty } from '@pulppo/pulppo-models';
import { Col, Row, Typography } from 'antd';
import { PulppoMap, PulppoMarker, PulppoZoom } from '../Elements/PulppoMap';
import { PropertyMedia } from '../PropertyDetailGallery/PropertyModal';
import { IconType } from '../../utils/constants';
import Icon from '../Icon/Icon';
import { PrimaryAttributes } from '../Property/PropertyAttributes';
import { ListServices } from './PropertyServices';
import { useRouter } from 'next/router';
import { twMerge } from 'tailwind-merge';
import { useTranslate } from '@tolgee/react';
import { showSpaces } from '../../utils/config';

export const PropertyDetailMain = ({ property }: { property: IProperty }) => {
    return (
        <div>
            <div>
                <div className="mb-5 md:mb-0">
                    <PropertyMedia
                        pictures={property?.pictures}
                        videos={property?.videos}
                        virtualTour={property?.virtualTour}
                        streetView={'true'}
                        address={property?.address}
                    ></PropertyMedia>
                </div>
                <div>
                    <PrimaryAttributes floor={property.address?.floor} {...property?.attributes} />
                    <div className="mt-6">
                        <Row className="">
                            <Col xs={24} md={24}>
                                {property.listing?.description?.split('\n').map((text, i) => (
                                    <p className="" key={i}>
                                        {text}
                                    </p>
                                )) || '-'}
                            </Col>
                        </Row>
                    </div>
                    <div className="mt-6 flex flex-col gap-4">
                        <ListServices type="amenities" services={property.services || []} />
                        <ListServices type="spaces" services={property.services || []} />
                        <ListServices type="additional" services={property.services || []} />
                    </div>
                </div>
            </div>
            <div className="mt-6">
                {property?.address?.location?.coordinates?.length === 2 && (
                    <div>
                        <p className="mb-3 font-medium uppercase">Ubicación</p>
                        <PropertyMap property={property} />
                    </div>
                )}
            </div>
        </div>
    );
};

interface AttributeProps {
    icon: IconType;
    value?: number | string;
    description?: string;
    hasBorder?: boolean;
    extra?: string;
    showEmpty?: boolean;
}

const Attribute = ({ icon, value, description, hasBorder = true, showEmpty = true }: AttributeProps) => {
    if (!value && !showEmpty) {
        return <></>;
    }
    const valueParsed = typeof value === 'number' ? Math.round(value) : value;
    return (
        <div className="flex items-center">
            <div className="flex items-center gap-1">
                <p className="text-center">{valueParsed || '-'}</p>
                <Icon width={20} height={20} name={icon} />
                {description ? <p className="text-lg font-semibold">{description}</p> : null}
            </div>
        </div>
    );
};

export const PropertyMainMetrics = ({
    property,
    className = '',
    size = 'large',
    showEmpty = true
}: {
    property: IProperty;
    className?: string;
    size?: 'large' | 'small';
    showEmpty?: boolean;
}) => {
    const isSmall = size === 'small';
    const type = property.type || 'Casa';
    const router = useRouter();
    const { t: translate } = useTranslate('common');
    const defaultTranslate = (val: string) => {
        const isEnglish = router.query.lang === 'en';
        if (val === 'Totales') {
            return isEnglish ? 'Total' : val;
        }
        if (val === 'Estado') {
            return isEnglish ? 'State' : val;
        }
        if (val === 'Terreno') {
            return isEnglish ? 'Terrain' : val;
        }
        if (val === 'Frente') {
            return isEnglish ? 'Front' : val;
        }
        if (val === 'Fondo') {
            return isEnglish ? 'Back' : val;
        }
        if (val === 'Recám.') {
            return isEnglish ? 'Rooms' : val;
        }
        if (val === 'Baños') {
            return isEnglish ? 'Bath.' : val;
        }
        if (val === 'Const.') {
            return isEnglish ? 'Roofed' : val;
        }
        if (val === 'Totales') {
            return isEnglish ? 'Total' : val;
        }
        if (val === 'Estac.') {
            return isEnglish ? 'Parkings' : val;
        }
        return val;
    };
    const t = (val: string) => translate(val, defaultTranslate(val));

    const getAttributes = () => {
        switch (type) {
            case 'Terreno residencial':
            case 'Terreno comercial':
            case 'Terreno industrial':
                return (
                    <>
                        <Attribute
                            showEmpty={showEmpty}
                            icon={IconType.Mts}
                            description={!isSmall ? t('Totales') : undefined}
                            value={property.attributes?.totalSurface}
                            extra="m²"
                            hasBorder={false}
                        />
                        <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />
                        <Attribute
                            showEmpty={showEmpty}
                            icon={IconType.DownFloor}
                            description={!isSmall ? t('Terreno') : undefined}
                            value={property.additionalFields?.terrainType}
                        />
                        {!isSmall ? (
                            <>
                                <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />
                                <Attribute
                                    showEmpty={showEmpty}
                                    icon={IconType.Measure}
                                    description={!isSmall ? t('Frente') : undefined}
                                    value={property.additionalFields?.frontMeters}
                                    extra="m²"
                                />
                                <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />
                                <Attribute
                                    showEmpty={showEmpty}
                                    icon={IconType.Measure}
                                    description={!isSmall ? t('Fondo') : undefined}
                                    value={property.additionalFields?.bottomMeters}
                                    extra="m²"
                                />
                            </>
                        ) : null}
                    </>
                );
            case 'Finca':
                return (
                    <>
                        <Attribute
                            showEmpty={showEmpty}
                            icon={IconType.Bed}
                            description={!isSmall ? t('Recám.') : undefined}
                            value={property.attributes?.suites}
                            hasBorder={false}
                        />
                        <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />
                        <Attribute
                            showEmpty={showEmpty}
                            icon={IconType.Bathroom}
                            description={!isSmall ? t('Baños') : undefined}
                            value={property.attributes?.bathrooms}
                        />
                        <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />
                        <Attribute
                            showEmpty={showEmpty}
                            icon={IconType.Mts}
                            description={!isSmall ? t('Const.') : undefined}
                            value={property.attributes?.roofedSurface}
                            extra="m²"
                        />
                        <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />
                        <Attribute
                            showEmpty={showEmpty}
                            icon={IconType.Mts}
                            description={!isSmall ? t('Totales') : undefined}
                            value={property.attributes?.totalSurface}
                            extra="m²"
                        />
                    </>
                );
            case 'Edificio':
                return (
                    <>
                        {!isSmall ? (
                            <>
                                <Attribute
                                    showEmpty={showEmpty}
                                    icon={IconType.Mts}
                                    description={!isSmall ? t('Const.') : undefined}
                                    value={property.attributes?.roofedSurface}
                                    extra="m²"
                                    hasBorder={false}
                                />
                                <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />
                            </>
                        ) : null}
                        <Attribute
                            showEmpty={showEmpty}
                            icon={IconType.Mts}
                            description={!isSmall ? t('Totales') : undefined}
                            value={property.attributes?.totalSurface}
                            extra="m²"
                            hasBorder={!isSmall}
                        />
                        <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />
                        <Attribute
                            showEmpty={showEmpty}
                            icon={IconType.UpFloor}
                            description={!isSmall ? t('Pisos') : undefined}
                            value={property.additionalFields?.floorsConstructed}
                        />
                    </>
                );
            case 'Oficina':
                return (
                    <>
                        <Attribute
                            showEmpty={showEmpty}
                            icon={IconType.Garage}
                            description={!isSmall ? t('Estac.') : undefined}
                            value={property.attributes?.parkings}
                            hasBorder={false}
                        />
                        {!isSmall ? (
                            <>
                                <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />
                                <Attribute
                                    showEmpty={showEmpty}
                                    icon={IconType.Mts}
                                    description={!isSmall ? t('Const.') : undefined}
                                    value={property.attributes?.roofedSurface}
                                    extra="m²"
                                />
                            </>
                        ) : null}
                        <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />
                        <Attribute
                            showEmpty={showEmpty}
                            icon={IconType.Mts}
                            description={!isSmall ? t('Totales') : undefined}
                            value={property.attributes?.totalSurface}
                            extra="m²"
                        />
                        {!isSmall ? (
                            <>
                                <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />
                                <Attribute
                                    showEmpty={showEmpty}
                                    icon={IconType.Price}
                                    description={!isSmall ? t('Manten.') : undefined}
                                    value={property.attributes?.expenses}
                                />
                            </>
                        ) : null}
                    </>
                );
            case 'Local':
            case 'Local en centro comercial':
                return (
                    <>
                        <Attribute
                            showEmpty={showEmpty}
                            icon={IconType.Garage}
                            description={!isSmall ? t('Estac.') : undefined}
                            value={property.attributes?.parkings}
                            hasBorder={false}
                        />
                        <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />
                        <Attribute
                            showEmpty={showEmpty}
                            icon={IconType.Mts}
                            description={!isSmall ? t('Totales') : undefined}
                            value={property.attributes?.totalSurface}
                            extra="m²"
                        />
                        {/* <Attribute
                            showEmpty={showEmpty}
                            icon={IconType.Upgrade}
                            description={!isSmall ? t('Estado') : undefined}
                            value={property.attributes?.condition}
                        /> */}
                        {!isSmall ? (
                            <>
                                <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />
                                <Attribute
                                    showEmpty={showEmpty}
                                    icon={IconType.Price}
                                    description={!isSmall ? t('Manten.') : undefined}
                                    value={property.attributes?.expenses}
                                />
                                <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />
                                <Attribute
                                    showEmpty={showEmpty}
                                    icon={IconType.Measure}
                                    description={!isSmall ? t('Frente') : undefined}
                                    value={property.additionalFields?.frontMeters}
                                    extra="m²"
                                />
                            </>
                        ) : null}
                    </>
                );
            case 'Casa uso de suelo':
                return (
                    <>
                        {!isSmall ? (
                            <>
                                <Attribute
                                    showEmpty={showEmpty}
                                    icon={IconType.Bed}
                                    description={!isSmall ? t('Recám.') : undefined}
                                    value={property.attributes?.suites}
                                    hasBorder={false}
                                />
                                <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />
                            </>
                        ) : null}
                        <Attribute
                            showEmpty={showEmpty}
                            icon={IconType.Garage}
                            description={!isSmall ? t('Estac.') : undefined}
                            value={property.attributes?.parkings}
                            hasBorder={!isSmall}
                        />
                        <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />
                        <Attribute
                            showEmpty={showEmpty}
                            icon={IconType.Mts}
                            description={!isSmall ? t('Totales') : undefined}
                            value={property.attributes?.totalSurface}
                            extra="m²"
                        />
                        <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />
                        <Attribute
                            showEmpty={showEmpty}
                            icon={IconType.Price}
                            description={!isSmall ? t('Manten.') : undefined}
                            value={property.attributes?.expenses}
                        />
                    </>
                );
            case 'Bodega comercial':
                return (
                    <>
                        {!isSmall ? (
                            <Attribute
                                showEmpty={showEmpty}
                                icon={IconType.Bathroom}
                                description={!isSmall ? t('Baños') : undefined}
                                value={property.attributes?.bathrooms}
                                hasBorder={false}
                            />
                        ) : null}
                        <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />
                        <Attribute
                            showEmpty={showEmpty}
                            icon={IconType.Mts}
                            description={!isSmall ? t('Const.') : undefined}
                            value={property.attributes?.roofedSurface}
                            extra="m²"
                            hasBorder={!isSmall}
                        />
                        <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />
                        <Attribute
                            showEmpty={showEmpty}
                            icon={IconType.Mts}
                            description={!isSmall ? t('Totales') : undefined}
                            value={property.attributes?.totalSurface}
                            extra="m²"
                        />
                        {!isSmall ? (
                            <>
                                <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />

                                <Attribute
                                    showEmpty={showEmpty}
                                    icon={IconType.Price}
                                    description={!isSmall ? t('Manten.') : undefined}
                                    value={property.attributes?.expenses}
                                />
                            </>
                        ) : null}
                    </>
                );
            case 'Nave industrial':
                return (
                    <>
                        <Attribute
                            showEmpty={showEmpty}
                            icon={IconType.Mts}
                            description={!isSmall ? t('Totales') : undefined}
                            value={property.attributes?.totalSurface}
                            extra="m²"
                            hasBorder={false}
                        />
                        {!isSmall ? (
                            <>
                                <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />
                                <Attribute
                                    showEmpty={showEmpty}
                                    icon={IconType.Mts}
                                    description={!isSmall ? t('Const.') : undefined}
                                    value={property.attributes?.roofedSurface}
                                    extra="m²"
                                />
                            </>
                        ) : null}
                        {!isSmall ? (
                            <>
                                <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />
                                <Attribute
                                    showEmpty={showEmpty}
                                    icon={IconType.Price}
                                    description={!isSmall ? t('Manten.') : undefined}
                                    value={property.attributes?.expenses}
                                />
                            </>
                        ) : null}
                    </>
                );
            case 'Casa':
            case 'Departamento':
            case 'Casa en condominio':
            default:
                return (
                    <>
                        <Attribute
                            showEmpty={showEmpty}
                            icon={IconType.Bed}
                            description={!isSmall ? t('Recám.') : undefined}
                            value={property.attributes?.suites}
                            hasBorder={false}
                        />
                        {showSpaces && (
                            <>
                                <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />
                                <Attribute
                                    showEmpty={showEmpty}
                                    icon={IconType.Door}
                                    description={!isSmall ? t('Amb.') : undefined}
                                    value={property.attributes?.spaces}
                                    hasBorder={false}
                                />
                            </>
                        )}
                        <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />
                        <Attribute
                            showEmpty={showEmpty}
                            icon={IconType.Bathroom}
                            description={!isSmall ? t('Baños') : undefined}
                            value={property.attributes?.bathrooms}
                        />
                        <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />
                        <Attribute
                            showEmpty={showEmpty}
                            icon={IconType.Garage}
                            description={!isSmall ? t('Estac.') : undefined}
                            value={property.attributes?.parkings}
                        />
                        {!isSmall ? (
                            <>
                                <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />
                                <Attribute
                                    showEmpty={showEmpty}
                                    icon={IconType.Mts}
                                    description={!isSmall ? t('Const.') : undefined}
                                    value={property.attributes?.roofedSurface}
                                    extra="m²"
                                />
                            </>
                        ) : null}
                        <div className="h-8 w-px bg-pulppo-secondary-gray-disabled opacity-40" />

                        <Attribute
                            showEmpty={showEmpty}
                            icon={IconType.Mts}
                            description={!isSmall ? t('Totales') : undefined}
                            value={property.attributes?.totalSurface}
                            extra="m²"
                        />
                    </>
                );
        }
    };

    return (
        <div>
            <div className={twMerge('flex justify-end gap-2 2xl:gap-4', className)}>{getAttributes()}</div>
        </div>
    );
};
export const PropertyCharacteristics = ({ property }: { property: IProperty }) => {
    const { t } = useTranslate('common');

    return (
        <>
            <div className="mt-20px pr-20px">
                {[
                    {
                        label: t('property_type', 'Tipo de propiedad'),
                        value: t(property.type)
                    },
                    {
                        label: t('year_build', 'Año de construcción'),
                        value: property.attributes?.yearBuild
                    },
                    {
                        label: t('parkings', 'Estacionamientos'),
                        value: property.attributes?.parkings
                    },
                    {
                        label: t('toilettes', 'Toilettes'),
                        value: property.attributes?.toilettes
                    },
                    {
                        label: t('disposition', 'Disposición'),
                        value: t(property.attributes?.disposition)
                    },
                    {
                        label: t('orientation', 'Orientación'),
                        value: t(property.attributes?.orientation)
                    },
                    {
                        label: t('expenses', 'Mantenimiento'),
                        value: property.attributes?.expenses
                    },
                    {
                        label: t('condition', 'Condición'),
                        value: t(property.attributes?.condition)
                    }
                ]
                    .filter(({ value }) => value)
                    .map(({ label, value }) => (
                        <div
                            key={label}
                            className="mt-14px flex flex-col items-start border-b border-solid lg:flex-row lg:items-center"
                            style={{
                                borderColor: '#00000033'
                            }}
                        >
                            <Typography.Paragraph className="mb-2 flex-1 text-gray-600">{label}</Typography.Paragraph>
                            <Typography.Paragraph className="mb-2 flex-1 font-bold">
                                {value || '-'}
                            </Typography.Paragraph>
                        </div>
                    ))}
            </div>
        </>
    );
};
export const PropertyMap = ({ property }: { property: IProperty }) => {
    return (
        <PulppoMap
            center={[property.address?.location?.coordinates[0] || 0, property.address?.location?.coordinates[1] || 0]}
            containerStyle={{
                height: '300px',
                width: '500px',
                maxWidth: '100%'
            }}
        >
            <PulppoMarker
                draggable={false}
                coordinates={[
                    property.address?.location?.coordinates[0] || 0,
                    property.address?.location?.coordinates[1] || 0
                ]}
            />
            <PulppoZoom />
        </PulppoMap>
    );
};
