import { FormatIcu } from '@tolgee/format-icu';
import { useRouter } from 'next/router';
import {
    NsFallback,
    Tolgee,
    TolgeeProvider,
    getFallbackArray,
    useTolgeeSSR,
    BackendFetch,
    DevTools
} from '@tolgee/react';
const apiKey = process.env.NEXT_PUBLIC_TOLGEE_API_KEY;
const apiUrl = process.env.NEXT_PUBLIC_TOLGEE_API_URL;

export const getServerLocales = async (locale: string | undefined, ns?: NsFallback) => {
    const namespaces = ['', ...getFallbackArray(ns)];
    const result: Record<string, any> = {};

    if (locale) {
        for (const namespace of namespaces) {
            if (namespace) {
                result[`${locale}:${namespace}`] = (await import(`../public/i18n/${namespace}/${locale}.json`)).default;
            } else {
                result[`${locale}`] = (await import(`../public/i18n/${locale}.json`)).default;
            }
        }
    }

    return result;
};

const tolgee = Tolgee()
    .use(FormatIcu())
    .use(BackendFetch())
    .use(process.env.NODE_ENV === 'production' ? BackendFetch() : DevTools())
    .init({
        availableLanguages: ['en', 'es-MX', 'es-CO', 'es-ES', 'es-AR'],
        defaultLanguage: process.env.NEXT_PUBLIC_LANGUAGE_CODE || 'es-MX',
        apiKey: apiKey,
        apiUrl: apiUrl
    });

export const TolgeeNextProvider = ({ children }) => {
    const router = useRouter();
    const tolgeeSSR = useTolgeeSSR(tolgee, router?.locale || process.env.NEXT_PUBLIC_LANGUAGE_CODE);

    return (
        <TolgeeProvider tolgee={tolgeeSSR} options={{ useSuspense: true }}>
            {children}
        </TolgeeProvider>
    );
};
