import { CalendarOutlined, CheckOutlined, MailOutlined, WarningFilled, WhatsAppOutlined } from '@ant-design/icons';
import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { ISimpleUser, IVisit } from '@pulppo/pulppo-models';
import { Input, notification } from 'antd';
import { useEffect, useState } from 'react';
import { LoadingButton } from '../Elements/ButtonLoading';
import { api_url, fetcher, instance, wapp_url } from '../../helpers/fetcher';
import dayjs from 'dayjs';
import useSWR from 'swr';
import useUser from '../../hooks/useUser';
import { twMerge } from 'tailwind-merge';

export const ShareDrawer = ({ visit, visible, onClose }: { visible: boolean; visit: IVisit; onClose: () => void }) => {
    const whatsapp = useUser((u) => u.user?.whatsapp);
    const [textInput, setTextInput] = useState(generateMessage(visit));
    const [medium, setMedium] = useState<string[]>([]);
    const { data: contact } = useSWR<ISimpleUser>(`${api_url}/contact/${visit?.contact?._id}/simple`, fetcher);

    useEffect(() => {
        const aux = [];
        if (contact?.phone && whatsapp) aux.push('phone');
        if (contact?.email) aux.push('calendar');
        if (!whatsapp && contact?.email) aux.push('email');
        setMedium(aux);
    }, [contact, visible, whatsapp]);

    const share = async (medium: string[], message) => {
        const url = await fetcher(`${api_url}/visit/${visit._id}/url`).catch(() => null);
        if (medium.includes('phone')) {
            url
                ? await instance
                      .post(`${wapp_url}/message-attachment/${visit.agent.uid}`, {
                          to: contact.phone?.replace?.(/\D/gi, ''),
                          content: message,
                          attachment: {
                              image: url
                          }
                      })

                      .then(() => {
                          onClose();
                          notification.success({
                              message: 'Se compartió el recorrido correctamente'
                          });
                      })
                      .catch(() => {
                          notification.error({
                              message: 'Hubo un problema al comprtir el recorrido'
                          });
                      })
                : await instance
                      .post(`${wapp_url}/message/${visit.agent.uid}`, {
                          to: contact.phone?.replace?.(/\D/gi, ''),
                          content: message
                      })

                      .then(() => {
                          onClose();
                          notification.success({
                              message: 'Se compartió el recorrido correctamente'
                          });
                      })
                      .catch(() => {
                          notification.error({
                              message: 'Hubo un problema al comprtir el recorrido'
                          });
                      });
        } else if (medium.includes('email')) {
            await instance
                .post(`https://api.pulppo.com/gmail/send/${visit.agent?.uid}`, {
                    html: true,
                    to: contact.email,
                    subject: `Recordatorio visita ${dayjs(visit.startTime).tz().format('DD/MM')}`,
                    message: `
  <img src=${url.url} alt="Recorrido" />
  <p> 
  ${message}
  <p> 
  `
                })
                .then(() => {
                    onClose();
                    notification.success({
                        message: 'Se compartió el recorrido correctamente'
                    });
                })
                .catch(() => {
                    notification.error({
                        message: 'Hubo un problema al comprtir el recorrido'
                    });
                });
        }
    };

    useEffect(() => {
        if (visit) setTextInput(generateMessage(visit));
    }, [visit]);

    return (
        <PulppoDrawer visible={visible} onClose={() => onClose()} title="Compartir recorrido">
            <div className="flex h-full flex-col">
                <div className="flex-1">
                    <p className="mb-1 mt-4 text-sm font-semibold">Mensaje</p>
                    <Input.TextArea
                        value={textInput}
                        className="rounded-none"
                        onInput={(ev) => {
                            setTextInput(ev.currentTarget.value);
                        }}
                        placeholder="Escriba un mensaje aqui..."
                        rows={14}
                    />
                    {contact?.phone && (
                        <div className="mt-4 border-b border-solid border-pulppo-secondary-gray-line py-3">
                            <div
                                className={twMerge(
                                    'flex w-full cursor-pointer items-center gap-4',
                                    whatsapp ? 'cursor-pointer' : 'cursor-not-allowed text-gray-300'
                                )}
                                onClick={
                                    whatsapp
                                        ? () =>
                                              setMedium((medium) => {
                                                  if (medium.includes('phone'))
                                                      return medium.filter((el) => el !== 'phone');
                                                  else return medium.concat('phone');
                                              })
                                        : null
                                }
                            >
                                <div className="flex items-center justify-center bg-pulppo-status-light-disabled p-1.5">
                                    <WhatsAppOutlined
                                        className={whatsapp ? 'text-black' : 'text-gray-300'}
                                        style={{ fontSize: '15px' }}
                                    />
                                </div>
                                <div className="flex-1">Whatsapp al {contact?.phone}</div>
                                <div
                                    className={twMerge(
                                        'flex h-5 w-5 items-center justify-center rounded-none border border-solid border-black bg-white',
                                        whatsapp ? 'border-black' : 'border-gray-300'
                                    )}
                                >
                                    {medium.includes('phone') && (
                                        <CheckOutlined
                                            className={twMerge(
                                                'p-1 text-xs font-bold',
                                                whatsapp ? 'text-black' : 'text-gray-300'
                                            )}
                                        />
                                    )}
                                </div>
                            </div>
                            {whatsapp ? null : (
                                <p className="mt-4 text-xs text-pulppo-status-dark-error">
                                    <span>
                                        <WarningFilled className="mr-2 text-pulppo-status-dark-error" />
                                    </span>
                                    No puedes realizar esta acción, debes tener vinculado tu whatsapp.
                                </p>
                            )}
                        </div>
                    )}
                    {contact?.email && (
                        <div
                            className="mt-4 flex w-full cursor-pointer items-center gap-4 border-b border-solid border-pulppo-secondary-gray-line py-3"
                            onClick={() =>
                                setMedium((medium) => {
                                    if (medium.includes('calendar')) return medium.filter((el) => el !== 'calendar');
                                    else return medium.concat('calendar');
                                })
                            }
                        >
                            <div className="flex shrink-0 items-center justify-center bg-pulppo-status-light-disabled p-1.5">
                                <CalendarOutlined className="text-black" style={{ fontSize: '15px' }} />
                            </div>
                            <div className="flex-1">Mandar invitación de calendar</div>
                            <div
                                className={`flex h-5 w-5 items-center justify-center rounded-none border border-solid border-black bg-white`}
                            >
                                {medium.includes('calendar') && (
                                    <CheckOutlined className="p-1 text-xs font-bold text-black" />
                                )}
                            </div>
                        </div>
                    )}
                    {contact?.email && (
                        <div
                            className="mt-4 flex w-full cursor-pointer items-center gap-4 border-b border-solid border-pulppo-secondary-gray-line py-3"
                            onClick={() =>
                                setMedium((medium) => {
                                    if (medium.includes('email')) return medium.filter((el) => el !== 'email');
                                    else return medium.concat('email');
                                })
                            }
                        >
                            <div className="flex shrink-0 items-center justify-center bg-pulppo-status-light-disabled p-1.5">
                                <MailOutlined className="text-black" style={{ fontSize: '15px' }} />
                            </div>
                            <div className="flex-1">Email a {contact?.email}</div>
                            <div
                                className={`flex h-5 w-5 items-center justify-center rounded-none border border-solid border-black bg-white`}
                            >
                                {medium.includes('email') && (
                                    <CheckOutlined className="p-1 text-xs font-bold text-black" />
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex w-full items-center justify-center gap-3">
                    <LoadingButton
                        className="h-10 flex-1"
                        type="default"
                        onClick={async () => {
                            const url = await fetcher(`${api_url}/visit/${visit._id}/url`);
                            if (url) {
                                const link = document.createElement('a');
                                link.href = url?.url;
                                link.target = '_blank';
                                link.rel = 'noreferrer';
                                link.download = 'tour.png';
                                link.click();
                            }
                        }}
                    >
                        Descargar
                    </LoadingButton>
                    <LoadingButton
                        type="primary"
                        className="h-10 flex-1"
                        disabled={!medium?.length}
                        onClick={async () => {
                            await share(medium, textInput);
                        }}
                    >
                        Compartir
                    </LoadingButton>
                </div>
            </div>
        </PulppoDrawer>
    );
};

const generateMessage = (visit: IVisit) => `Hola ${visit.contact?.firstName}
Te envío este mensaje para reconfirmar que tenemos una visita agendada el día ${dayjs(visit.startTime)
    .tz()
    .format('DD/MM')} a las ${dayjs(visit.startTime).tz().format('hh:mm a')} Hs.
En la misma visitaremos ${visit.steps?.length === 1 ? 'una propiedad' : `${visit.steps?.length} propiedades`}:
  ${visit.steps?.reduce((msg: string, step, index: number) => {
      return (
          msg +
          `${index + 1} - ${
              step.property.address?.street || `"${step.property?.listing?.title}"`
          } a las ${dayjs(step.date).tz().format('hh:mm a')} Hs
`
      );
  }, '')}
  Te envío adjunto una imagen con el recorrido a realizar. 
  En caso de haber algún inconveniente, por favor, házmelo saber para poder reagendar la visita. 
  Muchas gracias!
  `;
