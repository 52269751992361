import { PulppoRadio } from './PulppoRadio';

interface Props {
    filters: any;
    setFilters: (filters?: any) => void;
    render: (_?: any) => any;
    showButtons?: boolean;
}

export const PropertySort = ({ filters, setFilters, render, showButtons }: Props) => {
    return (
        <>
            <PulppoRadio
                value={sortOptions.find((elem) => elem.filter.sort === filters?.sort)?.key || null}
                onApply={(value) =>
                    setFilters((filters) => ({
                        ...filters,
                        ...sortOptions.find((opt) => opt.key === value).filter
                    }))
                }
                onClear={() => {
                    setFilters((filters) => ({
                        ...filters,
                        sort: null
                    }));
                }}
                options={sortOptions.map((opt) => ({
                    label: opt.label,
                    value: opt.key
                }))}
                defaultOptionValue="relevance"
                render={render}
                showButtons={showButtons}
                isSort
            />
        </>
    );
};

const sortOptions = [
    { key: 'relevance', label: 'Relevantes', filter: { sort: null } },
    {
        key: 'price_low',
        label: 'Menor precio',
        filter: { sort: 'listing.value,1' }
    },
    {
        key: 'price_high',
        label: 'Mayor precio',
        filter: { sort: 'listing.value,-1' }
    },
    {
        key: 'creation_recent',
        label: 'Fecha de creación mas reciente',
        filter: { sort: 'createdAt,-1' }
    },
    {
        key: 'creation_older',
        label: 'Fecha de creación mas antigua',
        filter: { sort: 'createdAt,1' }
    }
    // {
    //     key: 'price_m2_low',
    //     label: 'Menor precio por m2',
    //     filter: { sort: null }
    // },
    // {
    //     key: 'price_m2_high',
    //     label: 'Mayor precio por m2',
    //     filter: { sort: null }
    // },
    // {
    //     key: 'older',
    //     label: 'Menos antiguos',
    //     filter: { sort: 'attributes.yearBuild,-1' }
    // },
    // {
    //     key: 'newer',
    //     label: 'Mas antiguos',
    //     filter: { sort: 'attributes.yearBuild,1' }
    // },
    // {
    //     key: 'larger',
    //     label: 'Mayor superficie',
    //     filter: { sort: 'attributes.totalSurface,-1' }
    // },
    // {
    //     key: 'smaller',
    //     label: 'Menos superficie',
    //     filter: { sort: 'attributes.totalSurface,1' }
    // },
    // { key: 'closest', label: 'Cercanos a mi ubicación', filter: { sort: null } }
];

// export const PropertySort = ({ filters, setFilters, render }: Props) => {
//   const [open, setOpen] = useState(false);

  
//   const selectedKey =
//     sortOptions.find((elem) => elem.filter.sort === filters?.sort)?.key || 'relevance';

//   const triggerRef = useRef<HTMLDivElement | null>(null);
//   const modalRef = useRef<HTMLDivElement | null>(null);

//   const [modalPos, setModalPos] = useState({ top: 0, left: 0 });

//   useEffect(() => {
//     if (open && triggerRef.current) {
//       const rect = triggerRef.current.getBoundingClientRect();
//       setModalPos({
//         top: rect.bottom + 5 + window.scrollY,
//         left: rect.left + window.scrollX
//       });
//     }
//   }, [open]);

//   const closeModal = () => setOpen(false);

//   useEffect(() => {
//     function handleClickOutside(event: MouseEvent) {
//       if (
//         open &&
//         modalRef.current &&
//         !modalRef.current.contains(event.target as Node) &&
//         triggerRef.current &&
//         !triggerRef.current.contains(event.target as Node)
//       ) {
//         closeModal();
//       }
//     }
//     if (open) {
//       document.addEventListener('mousedown', handleClickOutside);
//     }
//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [open]);

//   const handleSelect = (key: string) => {
//     const option = sortOptions.find((opt) => opt.key === key);
//     if (!option) return;
//     setFilters((prev: any) => ({
//       ...prev,
//       ...option.filter
//     }));
//     closeModal();
//   };

//   return (
//     <>
//       <div ref={triggerRef} onClick={() => setOpen(true)} style={{ display: 'inline-block' }}>
//         {render(sortOptions.find((opt) => opt.key === selectedKey))}
//       </div>

//       {open && (
//         <div className="fixed top-0 left-0 z-50 h-full w-full">
//           <div className="absolute top-0 left-0 h-full w-full bg-black/30" />
          
//           <div
//             ref={modalRef}
//             className="absolute z-50 w-[220px] rounded-md bg-white p-2 shadow-lg"
//             style={{
//               top: modalPos.top,
//               left: modalPos.left
//             }}
//           >
//             {sortOptions.map((option) => (
//               <div
//                 key={option.key}
//                 onClick={() => handleSelect(option.key)}
//                 className={`cursor-pointer border-b border-gray-100 py-2 px-3 hover:underline last:border-none
//                   ${option.key === selectedKey ? 'font-semibold text-black' : 'text-black'}
//                 `}
//               >
//                 {option.label}
//               </div>
//             ))}
//           </div>
//         </div>
//       )}
//     </>
//   );
// };
