import { IAddress } from "@pulppo/pulppo-models";
import axios from "../utils/axios";
import { api_url } from "./fetcher";
import { notification } from "antd";

export const getLocationFromCoordinates = ({ lat, lng }) => {
  return axios
    .get(`${api_url}/location/coordinates?lat=${lat}&lng=${lng}`)
    .then((res) => res.data);
};

export const MapsLocationToAddress = async (
  pl: google.maps.places.PlaceResult & { street?: string }
): Promise<IAddress | null> => {
  try {
    const location = await getLocationFromCoordinates({
      lat: pl.geometry.location.lat(),
      lng: pl.geometry.location.lng(),
    });
    const { address_components, geometry } = pl;
    return {
      street:
        pl.street ||
        address_components?.[1]?.short_name +
          " " +
          address_components?.[0]?.short_name,
      zip: address_components?.find((addComp) =>
        addComp.types.includes("postal_code")
      )?.short_name,
      ...location,
      location: {
        type: "Point",
        coordinates: [geometry?.location?.lng(), geometry?.location?.lat()],
      },
    };
  } catch (err) {
    notification.error({
      message: `Hubo un error: ${err}`,
      description: "Prueba de vuelta en unos segundos",
    });
    return null;
  }
};
