import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import nookies, { parseCookies } from 'nookies';
import { api_url, fetcher, setNewToken, setToken, wapp_url } from '../helpers/fetcher';
import { useRouter } from 'next/router';
import { IAgent } from '@pulppo/pulppo-models';
import { analytics } from '../helpers/analytics';
import axios from '../utils/axios';
import * as Sentry from '@sentry/browser';
import useUser from '../hooks/useUser';
import useTimezone from '../hooks/useTimezone';
import useSWR from 'swr';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { auth } from '../services/authentication';
import { DEFAULT_COORDINATES } from '../utils/coordinates';
import { io } from 'socket.io-client';

export interface IUser extends IAgent {
    name: string;
    enableVisionTest?: boolean;
}

const UserContext = createContext<{
    user: IUser;
    setUser: any;
    adminOverriden: any;
    menuOption: number;
    setMenuOption: Function;
    chatContact?: any;
    setChatContact?: any;
    logout?: Function;
}>(null);

const UserContextProvider = ({ children }) => {
    const [user, setUser] = useState<IAgent & { name: string }>(null);
    const setUserFinal = useCallback((func) => {
        setUser((u) => {
            const newUser = typeof func === 'function' ? func(u) : func;
            if (newUser?.uid)
                nookies.set(undefined, 'currentUser', newUser?.uid, {
                    path: '/',
                    sameSite: 'None',
                    secure: true,
                    expires: new Date(2147483647000)
                });
            return newUser;
        });
    }, []);
    const [menuOption, setMenuOption] = useState(-1);
    const adminOverriden = useRef(false);
    const lastLogin = useRef(false);
    const router = useRouter();
    const routerRef = useRef(router?.pathname);
    const setUserStore = useUser((state) => state.setUser);

    const timestamp = useRef(Date.now());

    useEffect(() => {
        timestamp.current = Date.now();
    }, [user?.uid]);

    const { data: token } = useSWR(user?.uid && `${api_url}/login?t=${timestamp.current}`, fetcher, {
        refreshInterval: 10 * 60 * 1000,
        revalidateOnFocus: true,
        revalidateOnMount: true,
        revalidateOnReconnect: true
    });

    useEffect(() => {
        if (token?.firebaseToken) {
            signInWithCustomToken(auth, token?.firebaseToken)
                .then(() => {})
                .catch((err) => {
                    console.log('Error al iniciar sesión con token de firebase', err, token?.firebaseToken);
                    throw err;
                });
        }
        if (token?.token) {
            setNewToken(token?.token);
        }
    }, [token]);

    useTimezone({
        toRedirect: `/agent/${user?.uid}`,
        selectedTimezone: user?.timezone
    });

    useEffect(() => {
        routerRef.current = router?.pathname;
    }, [router?.pathname]);

    useEffect(() => {
        const cookies = nookies.get(undefined);
        const user =
            typeof localStorage != 'undefined' &&
            cookies['new-token'] &&
            cookies['currentUser'] &&
            localStorage.getItem('user') &&
            JSON.parse(localStorage.getItem('user'));
        if (user) setUser(user);

        const getLocation = () => {
            const onError = async () => {
                try {
                    const ipGeolocationResponse = await axios
                        .get(`https://api.ipgeolocation.io/ipgeo?apiKey=e6ef2ae197504a77bab30913e7b896f5`)
                        .catch(() => ({
                            data: {
                                longitude: DEFAULT_COORDINATES.lng,
                                latitude: DEFAULT_COORDINATES.lat
                            }
                        }));
                    localStorage.setItem(
                        'coordinates',
                        JSON.stringify([+ipGeolocationResponse.data.longitude, +ipGeolocationResponse.data.latitude])
                    );
                } catch (err) {
                    console.error(`Could not get geolocation from API`);
                }
            };
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(({ coords: { longitude, latitude } }) => {
                    localStorage.setItem('coordinates', JSON.stringify([longitude, latitude]));
                }, onError);
            } else {
                onError();
            }
        };
        if (user) getLocation();
    }, []);

    useEffect(() => {
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
            setUserStore(user);
            Sentry.setUser({ email: user?.email });

            analytics.identify(user._id as string, {
                ...user,
                company: {
                    ...user.company,
                    id: user.company?._id
                },
                name: `${user.firstName} ${user.lastName}`,
                avatar: user.profilePicture
            });
            if (!user.name) {
                setUser((user) => ({
                    ...user,
                    name: `${user.firstName} ${user.lastName}`
                }));
            }
        }
    }, [user]);

    const logOut = async (redirect = true) => {
        await fetcher(`${api_url}/agent/logout`).catch(console.error);
        setUser(null);
        setNewToken(null);
        setToken(null);
        nookies.destroy(undefined, 'currentUser');
        nookies.destroy(undefined, 'token');
        nookies.destroy(undefined, 'new-token');
        nookies.destroy(undefined, 'header');
        analytics.reset();
        localStorage.clear();
        Sentry.setUser(null);
        await getAuth().signOut();
        if (redirect) await router.push('/login');
    };

    useEffect(() => {
        const cookies = parseCookies();
        if (cookies?.currentUser)
            fetcher(`${api_url}/agent/${cookies.currentUser}`)
                .then((data) => {
                    if (data.status == 'inactive') {
                        return logOut();
                    }

                    setUser(data);
                })
                .catch(() => {});
    }, []);

    useEffect(() => {
        if (user?.uid && !lastLogin.current) {
            lastLogin.current = true;
            axios
                .patch(`${api_url}/agent/lastLogin`)
                .then()
                .catch((err) => console.log(err));
        }
    }, [user]);

    useEffect(() => {
        if (!user?.uid) return;
        if (!user?.features?.['whatsapp-disconnect']) return;

        console.log('Connecting to socket', wapp_url.replace('/whatsapp', ''));

        const socket = io(`${wapp_url.replace('/whatsapp', '')}`, {
            transports: ['websocket'],
            query: { uid: user?.uid }
        });
        socket.on('connect', () => {
            console.log('Connected to socket');
        });
    }, [user?.uid]);

    return (
        <UserContext.Provider
            value={{
                menuOption,
                setMenuOption,
                user,
                setUser: setUserFinal,
                adminOverriden,
                logout: logOut
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export { UserContextProvider, UserContext };
