import { Button } from 'antd';
import { PulppoPagination } from './PulppoPagination';
import { ShareAltOutlined } from '@ant-design/icons';
import { CreatePropertyButton } from '../CreateProperty/CreateButton';

const PAGE_SIZE = 52;

export const PropertyListFooter = ({
    filters,
    count,
    setFilters,
    selectedProperties,
    onShare,
    inCollection,
    setView,
    view
}) => {
    return (
        <div className="flex w-full flex-col gap-2 px-4 pb-5 pt-2 lg:pb-0 lg:pt-0">
            <PulppoPagination
                page={1 + +(filters?.offset || 0) / +(filters?.limit || PAGE_SIZE)}
                pageSize={+filters?.limit || PAGE_SIZE}
                count={count[filters?.tab]}
                onChange={(page, pageSize) => {
                    setFilters((f) => ({
                        ...f,
                        offset: (page - 1) * pageSize,
                        limit: pageSize
                    }));
                }}
            />
            <div className="flex w-full items-center gap-4 lg:hidden">
                <div className="shrink-0">
                    <Button
                        key="mapa"
                        className="flex h-11 w-12 items-center justify-center rounded-none bg-white"
                        onClick={() => setView((v) => (v == 'map' ? 'grid' : 'map'))}
                    >
                        <div className="flex items-center gap-2 font-medium">
                            {view === 'map' ? (
                                <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M16.367 5.46061H1.63357C1.26392 5.46061 0.964592 5.74789 0.964592 6.10347V15.4249C0.964592 15.7805 1.26392 16.0678 1.63357 16.0678H16.365C16.7347 16.0678 17.034 15.7805 17.034 15.4249V6.10347C17.036 5.74789 16.7367 5.46061 16.367 5.46061ZM5.94674 14.6213H2.41102V11.407H5.94674V14.6213ZM5.94674 10.1213H2.41102V6.90704H5.94674V10.1213ZM10.7682 14.6213H7.23245V11.407H10.7682V14.6213ZM10.7682 10.1213H7.23245V6.90704H10.7682V10.1213ZM15.5896 14.6213H12.0539V11.407H15.5896V14.6213ZM15.5896 10.1213H12.0539V6.90704H15.5896V10.1213ZM16.8753 1.9249H1.12531C1.03691 1.9249 0.964592 1.99722 0.964592 2.08561V3.69275C0.964592 3.78115 1.03691 3.85347 1.12531 3.85347H16.8753C16.9637 3.85347 17.036 3.78115 17.036 3.69275V2.08561C17.036 1.99722 16.9637 1.9249 16.8753 1.9249Z"
                                        fill="black"
                                        fillOpacity="0.85"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M13.6407 0.0211058C12.913 0.154699 12.6563 0.235559 12.2133 0.471106C11.0145 1.10743 10.227 2.33087 10.1391 3.69142C10.0969 4.31017 10.2516 4.89728 10.6911 5.78322C11.2114 6.84493 12.1571 8.19493 13.3278 9.56603C13.8059 10.125 13.9817 10.2164 14.2946 10.0688C14.5864 9.92814 16.1544 7.93478 16.861 6.80626C17.7926 5.31212 18.109 4.32071 17.9508 3.36798C17.6766 1.69454 16.4848 0.435949 14.8536 0.0879021C14.5829 0.0281363 13.82 -0.0105352 13.6407 0.0211058ZM14.8536 1.24806C15.859 1.54337 16.6008 2.36251 16.8188 3.4172C16.8505 3.56486 16.8751 3.77931 16.8751 3.89181C16.8751 4.59493 16.2141 5.87814 15.0188 7.48829C14.6391 8.00157 14.0907 8.68361 14.0626 8.68361C13.9993 8.68361 12.8989 7.23868 12.4559 6.57423C11.8969 5.73751 11.4434 4.85509 11.3169 4.35939C11.1376 3.66329 11.3801 2.77384 11.918 2.134C12.6352 1.2797 13.7848 0.931652 14.8536 1.24806Z"
                                        fill="black"
                                    />
                                    <path
                                        d="M13.6864 2.30273C12.8496 2.48555 12.2696 3.34336 12.4067 4.19414C12.5543 5.10469 13.4192 5.7375 14.3192 5.59336C15.2297 5.4457 15.8625 4.58086 15.7184 3.68086C15.6621 3.32578 15.5004 3.0164 15.2438 2.75976C14.9871 2.50312 14.7446 2.36953 14.4246 2.30273C14.1364 2.23945 13.9711 2.23945 13.6864 2.30273ZM14.2875 3.42422C14.3508 3.45234 14.4457 3.52617 14.4985 3.59297C14.6813 3.83555 14.6637 4.10976 14.4493 4.32422C14.2172 4.55625 13.9184 4.55976 13.6793 4.33125C13.2293 3.90234 13.711 3.18515 14.2875 3.42422Z"
                                        fill="black"
                                    />
                                    <path
                                        d="M3.1957 5.65313C1.6207 6.28594 0.284766 6.83086 0.232031 6.86602C0.179297 6.90117 0.105469 6.99609 0.0667969 7.07695C0.00351563 7.22109 0 7.39688 0 12.3926V17.5605L0.0808594 17.7012C0.165234 17.8559 0.404297 18 0.569531 18C0.622266 18 1.90898 17.5043 3.42773 16.8961L6.1875 15.7922L8.94727 16.8961C10.466 17.5043 11.7527 18 11.8125 18C11.9426 18 17.6238 15.7359 17.7785 15.6234C18.0141 15.4477 18.0035 15.6199 17.993 11.7352C17.9824 8.2582 17.9824 8.23008 17.9086 8.13516C17.7715 7.94883 17.659 7.89258 17.4375 7.89258C17.216 7.89258 17.1035 7.94883 16.9664 8.13516C16.8926 8.23008 16.8926 8.26875 16.875 11.5242L16.8574 14.8148L14.6777 15.6867C13.4789 16.1684 12.4699 16.5691 12.4383 16.5797C12.3785 16.5973 12.375 16.4215 12.368 13.5387C12.3574 10.5117 12.3574 10.4801 12.2836 10.3852C12.1465 10.1988 12.034 10.1426 11.8125 10.1426C11.591 10.1426 11.4785 10.1988 11.3414 10.3852C11.2676 10.4801 11.2676 10.5117 11.257 13.5387C11.25 16.4215 11.2465 16.5973 11.1867 16.5797C11.1551 16.5691 10.1461 16.1684 8.94727 15.6867L6.76758 14.8148L6.75703 10.3605C6.75352 7.91016 6.75703 5.90625 6.76758 5.90625C6.77813 5.90625 7.38281 6.14531 8.11055 6.43711C9.33047 6.92578 9.45352 6.96797 9.61875 6.95391C9.71719 6.94336 9.83672 6.9082 9.88945 6.86953C10.009 6.78516 10.125 6.55313 10.125 6.40195C10.125 6.25781 10.0125 6.03984 9.9 5.96602C9.72422 5.85 6.29297 4.5 6.17695 4.5C6.11367 4.50352 4.82344 4.99922 3.1957 5.65313ZM5.61797 10.3605L5.60742 14.8148L3.42773 15.6867C2.22891 16.1684 1.21992 16.5691 1.18477 16.5797C1.12852 16.5973 1.125 16.3758 1.13203 12.143L1.14258 7.68516L3.35742 6.7957C4.57734 6.31055 5.58281 5.90977 5.60039 5.90625C5.61445 5.90625 5.62148 7.91016 5.61797 10.3605Z"
                                        fill="black"
                                    />
                                </svg>
                            )}
                        </div>
                    </Button>
                </div>

                <div className="flex-1">
                    {selectedProperties.length !== 0 ? (
                        <Button
                            type="primary"
                            className="h-11 w-full rounded-none font-medium"
                            icon={<ShareAltOutlined />}
                            onClick={onShare}
                        >
                            {inCollection
                                ? `Agregar (${selectedProperties.length})`
                                : `Compartir (${selectedProperties.length})`}
                        </Button>
                    ) : CreatePropertyButton ? (
                        <CreatePropertyButton />
                    ) : null}
                </div>
            </div>
        </div>
    );
};
