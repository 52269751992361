import useSWRInfinite from 'swr/infinite';
import { api_url, fetcher } from '../helpers/fetcher';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ID } from '../types';
import { searchContact } from '../utils/Typesense';
import { IContact } from '@pulppo/pulppo-models';
import useUser from './useUser';

interface Props {
    query: string;
    userUid: string;
    company: ID;
    options?: {
        limit?: number;
        useTypesense?: boolean;
    };
    sort?: {
        createdAt: 'asc' | 'desc';
    };
}

const useContactSearch = ({
    query,
    userUid,
    company,
    options,
    sort
}: Props): {
    contacts: Array<IContact>;
    reload: () => void;
    setSize: Dispatch<SetStateAction<number>>;
    isValidating: boolean;
} => {
    const { user } = useUser();
    const [limit, setLimit] = useState(options?.limit || 20);
    const [isLoading, setIsLoading] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [size, setSize] = useState(0);
    const {
        data: allPages,
        mutate: updateContacts,
        setSize: setSizeSWR,
        isValidating
    } = useSWRInfinite((index) => {
        if (options?.useTypesense) return undefined;
        return (
            userUid &&
            `${api_url}/contact/search?search=${query}&agent=${userUid}&company=${company}&limit=${limit}&offset=${
                index * limit
            }`
        );
    }, fetcher);

    useEffect(() => {
        if (options?.useTypesense && userUid) {
            setIsLoading(true);
            const searchInCompany = user?.permissions?.contacts?.view && userUid === 'all';

            searchContact({
                query: !query ? '*' : query,
                filter: {
                    userUid: searchInCompany ? undefined : userUid === 'all' ? user?.uid : userUid,
                    companyId: searchInCompany
                        ? `${company}` || user?.company?._id
                            ? `${user?.company?._id}`
                            : undefined
                        : undefined
                },
                sort,
                options: {
                    limit: size * limit
                }
            }).then((res) => {
                setContacts(res?.hits.map((hit) => hit.document) || []);
                setIsLoading(false);
            });
        }
    }, [query, options?.useTypesense, userUid, limit, size, user, sort?.createdAt]);

    useEffect(() => {
        if (options?.limit) {
            setLimit(options.limit);
        }
    }, [options?.limit]);

    useEffect(() => {
        if (!options?.useTypesense) {
            setSizeSWR(size);
        }
    }, [size]);

    return {
        contacts: (options?.useTypesense ? contacts : allPages?.map((result) => result?.result).flat()) || [],
        reload: updateContacts,
        setSize,
        isValidating: isValidating || isLoading
    };
};

export default useContactSearch;
