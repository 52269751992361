import useSWR from 'swr';
import { fetcher, api_url, instance } from '../../helpers/fetcher';
import { PulppoSelect } from '../Elements/PulppoSelect';
import { useEffect, useState } from 'react';
import { IProperty } from '@pulppo/pulppo-models/@types/pulppo-models/schemas/Property';
import { LoadingButton } from '../Elements/ButtonLoading';

export const MercadolibreLocationEditor = ({ property: initialProperty }) => {
    const { data: property, mutate: refreshProperty } = useSWR<IProperty>(
        initialProperty?._id && `${api_url}/property/${initialProperty._id}`,
        fetcher
    );
    const [states, setStates] = useState<{ id: string; name: string }[]>([]);
    const [cities, setCities] = useState<{ id: string; name: string }[]>([]);
    const [neighborhoods, setNeighborhoods] = useState<{ id: string; name: string }[]>([]);
    const [state, setState] = useState<{ id: string; name: string }>(null);
    const [city, setCity] = useState<{ id: string; name: string }>(null);
    const [neighborhood, setNeighborhood] = useState<{ name: string; id: string }>(null);
    const getStates = async () => {
        console.log('Getting states');
        await fetcher(`${api_url}/mercadolibre/information/states`).then((res) => setStates(res.states));
    };
    const getCities = (state: string) => {
        console.log('Getting cities');
        fetcher(`${api_url}/mercadolibre/information/state/${state}`).then((res) => setCities(res.cities));
    };
    const getNeighborhoods = (city: string) => {
        fetcher(`${api_url}/mercadolibre/information/city/${city}`).then((res) => setNeighborhoods(res.neighborhoods));
    };
    const save = async (property, neighborhood, city, state) => {
        await instance
            .patch(`${api_url}/property/${property._id}`, {
                'address.portals.mercadolibre.id': neighborhood.id,
                'address.portals.mercadolibre.name': neighborhood.name,
                'address.portals.mercadolibre.city.id': city.id,
                'address.portals.mercadolibre.city.name': city.name,
                'address.portals.mercadolibre.state.id': state.id,
                'address.portals.mercadolibre.state.name': state.name
            })
            .catch((err) => console.log(err));
        await instance.put(`${api_url}/mercadolibre/listings/${property._id}`).catch((err) => console.log(err));
        refreshProperty();
    };

    useEffect(() => {
        console.log(state);
        if (state?.id) getCities(state.id);
    }, [state]);
    useEffect(() => {
        if (city?.id) getNeighborhoods(city.id);
    }, [city]);
    useEffect(() => {
        getStates().then(() => {
            if (property?.address?.portals?.mercadolibre?.id) {
                setNeighborhood({
                    id: property?.address?.portals?.mercadolibre?.id,
                    name: property?.address?.portals?.mercadolibre?.name
                });
                setCity({
                    id: property?.address?.portals?.mercadolibre?.city?.id,
                    name: property?.address?.portals?.mercadolibre?.city?.name
                });
                setState({
                    id: property?.address?.portals?.mercadolibre?.state?.id,
                    name: property?.address?.portals?.mercadolibre?.state?.name
                });
            }
        });
    }, [property]);

    return (
        <div className="flex h-full w-full flex-col justify-between">
            <div className="flex w-full flex-col gap-5">
                <PulppoSelect
                    options={states?.map((st) => ({
                        key: st.id,
                        label: st.name,
                        value: st.id
                    }))}
                    value={state?.id}
                    onSelect={(value) => {
                        setState({
                            id: value.value,
                            name: value.label
                        });
                    }}
                ></PulppoSelect>
                <PulppoSelect
                    options={cities?.map((st) => ({
                        key: st.id,
                        label: st.name,
                        value: st.id
                    }))}
                    value={city?.id}
                    onSelect={(value) => {
                        setCity({ id: value.value, name: value.label });
                    }}
                ></PulppoSelect>
                <PulppoSelect
                    options={neighborhoods?.map((st) => ({
                        key: st.id,
                        label: st.name,
                        value: st.id
                    }))}
                    value={neighborhood?.id}
                    onSelect={(value) => {
                        setNeighborhood({ id: value.value, name: value.label });
                    }}
                ></PulppoSelect>
            </div>
            <div className="w-full py-4">
                <LoadingButton
                    type="primary"
                    className="h-11 w-full"
                    disabled={!neighborhood}
                    onClick={async () => {
                        await save(property, neighborhood, city, state);
                    }}
                >
                    Guardar
                </LoadingButton>
            </div>
        </div>
    );
};
