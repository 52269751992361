import { Button, notification } from 'antd';
import { ShowPulppoDrawer } from '../Elements/showPulppoDrawer';
import { PauseOutlined, RightOutlined } from '@ant-design/icons';
import useUser from '../../hooks/useUser';
import { useRouter } from 'next/router';
import axios from '../../utils/axios';
import { api_url } from '../../helpers/fetcher';
import { twMerge } from 'tailwind-merge';
import { useShallow } from 'zustand/react/shallow';

const showAvailabilityDrawer = (router) => {
    const { destroy } = ShowPulppoDrawer({
        title: 'Estados de tu cuenta',
        onCancel: () => destroy(),
        children: (
            <DrawerAvailability
                router={router}
                onClose={() => {
                    destroy();
                }}
            />
        )
    });
};

const DrawerAvailability = ({ router, onClose }) => {
    const { user, setUser } = useUser();
    const disabled = !!user.suspensions?.length;
    const changeAvailability = async (available: boolean) => {
        setUser((u) => ({ ...u, available: available }));
        await axios
            .patch(`${api_url}/agent/${user?._id}`, {
                available
            })
            .then(() => {
                onClose();
            })
            .catch(() => {
                notification.error({
                    message: 'Ocurrió un error',
                    description: 'No pudimos modificar el estado de tu cuenta. Intenta nuevamente mas tarde'
                });
            });
    };
    return (
        <div className="flex h-full flex-col">
            <div className={disabled ? 'cursor-not-allowed opacity-30' : 'cursor-pointer'}>
                <div
                    className="border-b border-solid border-secondary-gray-line py-4"
                    onClick={async () => {
                        if (disabled) return;
                        await changeAvailability(true);
                    }}
                >
                    <div className="flex items-center gap-2">
                        <div className="h-4 w-4 rounded-full bg-status-dark-success"></div>
                        <p className="font-semibold">Disponible</p>
                    </div>
                    <p className="mt-2">
                        Estás activo para recibir nuevas consultas de clientes y oportunidades de negocio
                    </p>
                </div>
                <div
                    className="border-b border-solid border-secondary-gray-line py-4"
                    onClick={async () => {
                        if (disabled) return;
                        await changeAvailability(false);
                    }}
                >
                    <div className="flex items-center gap-2">
                        <div className="h-4 w-4 rounded-full bg-status-dark-disabled"></div>
                        <p className="font-semibold">No disponible</p>
                    </div>
                    <p className="mt-2">
                        No estarás recibiendo nuevas consultas, las cuales serán asignadas a otros asesores de{' '}
                        {user?.company?.name || 'tu inmobiliaria'}
                    </p>
                </div>
            </div>
            {disabled && (
                <>
                    <div className="flex-1 cursor-default border-b border-solid border-secondary-gray-line py-4">
                        <div className="flex items-center gap-2">
                            <div className="flex h-4 w-4 items-center justify-center rounded-full bg-status-dark-error">
                                <PauseOutlined className="text-2xs text-white" />
                            </div>
                            <p className="font-semibold">Pausa de consultas</p>
                        </div>
                        <p className="mt-2">
                            Este estado es automático y se activa cuando estás en un periodo de mejora de tus métricas
                            de productividad. Mientras dure esta fase, las nuevas consultas serán gestionadas por otros
                            asesores de {user?.company?.name || 'tu inmobiliaria'}
                        </p>
                    </div>
                    <Button
                        type="primary"
                        className="h-10 w-full"
                        onClick={() => {
                            if (router) {
                                router.query.suspension = user.uid;
                                router.push(router);
                            }
                        }}
                    >
                        Regularizar
                    </Button>
                </>
            )}
        </div>
    );
};

export const ButtonAvailability = () => {
    const router = useRouter();
    const user = useUser(useShallow((state) => state.user));
    const suspended = !!user?.suspensions?.length;
    return (
        <Button type="default" className="h-10" onClick={() => showAvailabilityDrawer(router)}>
            <div className="flex items-center justify-center">
                <div
                    className={twMerge(
                        'h-4 w-4 rounded-full',
                        suspended
                            ? 'bg-status-dark-error'
                            : user?.available
                              ? 'bg-status-dark-success'
                              : 'bg-status-dark-disabled'
                    )}
                ></div>
                <p className="ml-2 hidden lg:block">
                    {suspended ? 'Pausa de consultas' : user?.available ? 'Disponible' : 'No disponible'}
                </p>
                <RightOutlined className="text-pulppo-black mb-0 ml-2 lg:ml-10" />
            </div>
        </Button>
    );
};
