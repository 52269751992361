import { PulppoDrawer } from '../Elements/PulppoDrawer';
import AgentsList, { BasicColor } from './AgentsList';
import useSWR from 'swr';
import { api_url, fetcher } from '../../helpers/fetcher';
import { IAgent } from '@pulppo/pulppo-models';
import { setCourseToAgentOngoing } from '../../utils/courses';
import useCourse from '../../hooks/useCourse';
import { METRICS_DESCRIPTION } from '../../utils/constants';

interface Props {
    show: boolean;
    onClose: () => void;
    companyId: string;
}

const DrawerMetricCourse = ({ show, onClose, companyId }: Props) => {
    const { data: agents = [], isLoading } = useSWR<Array<IAgent>>(
        `${api_url}/company/${companyId}/agents?filterBy=status`,
        fetcher
    );
    const {
        state: { courses }
    } = useCourse({});

    return (
        <PulppoDrawer visible={show} onClose={onClose} title={METRICS_DESCRIPTION.brokersCertificate.title}>
            <AgentsList
                isLoading={isLoading}
                agents={agents.map((agent) => {
                    const coursesClean = setCourseToAgentOngoing(agent?.courses as any[], courses.total);
                    const coursePending = coursesClean.filter(
                        (course) => course.progress.status === 'notStarted' || course.progress.status === 'ongoing'
                    ).length;

                    return {
                        ...agent,
                        courseCompleted: coursesClean.length - coursePending
                    };
                })}
                actions={{
                    sort: (a, b) => a.courseCompleted - b.courseCompleted,
                    tag: {
                        condition: (agent) =>
                            (agent?.courseCompleted || 0) === courses.total.length ? BasicColor.Good : BasicColor.Wrong,
                        text: {
                            success: `Completo`,
                            error: `{courseCompleted} cursos de ${courses.total.length}`
                        }
                    }
                }}
            />
        </PulppoDrawer>
    );
};

export default DrawerMetricCourse;
