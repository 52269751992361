import { ISimpleUser, IVisit, ISimpleProperty } from '@pulppo/pulppo-models';
import { PulppoDrawer } from './PulppoDrawer';
import { ReactNode, useEffect, useState } from 'react';
import VisitList from './VisitDrawer/VisitList';
import { api_url, fetcher, instance } from '../../helpers/fetcher';
import useSWR from 'swr';
import Button from './Button';
import { ShowPortal } from '../../utils/ShowPortal';
import useUser from '../../hooks/useUser';
import { useVisible } from '../../hooks/useVisible';
import { PulppoRadio } from './PulppoRadio';
import { RightOutlined, SearchOutlined } from '@ant-design/icons';
import { AgentSelect } from './AgentSelect';
import { IAgent } from '@pulppo/pulppo-models/build/@types/pulppo-models/schemas/Agent';
import { Input } from 'antd';
import { ISearch } from '@pulppo/pulppo-models/build/@types/pulppo-models/schemas/Search';
import { SearchSelect } from './SearchSelect';
import { useDebounce } from '../../hooks/debounce';
import { PulppoLoader } from './PulppoLoader';

export interface VisitDrawerProps {
    show: boolean;
    onClose: () => void;
    searchId?: string;
    refreshSearch?: () => void;
    contact?: ISimpleUser;
    property?: ISimpleProperty;
    openForm?: boolean;
}

interface IFilters {
    agent: ISimpleUser;
    page: number;
    text: string;
    status: string;
    time: string;
}

const VisitDrawer = ({
    show,
    onClose,
    searchId = null,
    refreshSearch = () => {},
    property = null,
    openForm
}: VisitDrawerProps) => {
    const [visits, setVisits] = useState<IVisit[]>([]);
    const [filters, setFilters] = useState<{
        agent: ISimpleUser;
        page: number;
        text: string;
        status: string;
        time: string;
    }>();
    const [isLoading, setIsLoading] = useState(false);
    const { ref: endRef, visible } = useVisible();
    const [hasMore, setHasMore] = useState(true);
    const { user } = useUser();
    const getVisits = async () => {
        setIsLoading(true);
        if (searchId) {
            instance
                .get(`${api_url}/visit/search/${searchId}?cancelled=true`)
                .then((res) => {
                    setVisits(res.data);
                })
                .catch(() => setVisits([]))
                .finally(() => setIsLoading(false));
        } else {
            fetcher(
                filters?.agent?.uid &&
                    `${api_url}/visit/agent/${filters?.agent?.uid}/search?offset=${(filters.page - 1) * SIZE}&limit=${SIZE}&tab=all&sort=asc${filters?.text ? `&search=${filters?.text}` : ''}&status=${filters.status}&date=${filters.time}`
            )
                .then((r) => {
                    setHasMore(r.count !== 0)
                    setVisits((v) => (filters?.page === 1 ? r.visits : [...v, ...r.visits]));
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };
    const { data: search, mutate: setSearch } = useSWR<ISearch>(searchId && `${api_url}/search/${searchId}`, fetcher);
    const openVisitForm = (search: ISearch, property: ISimpleProperty = null) => {
        const { destroy } = ShowPortal({
            type: 'form-visit',
            config: {
                onClose: (closeParent) => {
                    destroy();
                    if (closeParent) {
                        onClose();
                    }
                },
                visit: {
                    search: search?._id,
                    contact: search.contact
                },
                property,
                onSave: async (shouldClose) => {
                    await getVisits();
                    refreshSearch();
                    if (shouldClose) destroy();
                }
            }
        });
    };
    useEffect(() => {
        getVisits();
    }, [filters]);

    useEffect(() => {
        if (visible && !isLoading && !searchId && hasMore) {
            setFilters((filters) => ({ ...filters, page: filters?.page + 1 }));
        }
    }, [visible, isLoading, searchId, hasMore]);
    useEffect(() => {
        if (show && search) {
            if (property) openVisitForm(search, property);
            else if (openForm) openVisitForm(search);
        }
    }, [property, show, openForm, search]);

    return (
        <>
            <PulppoDrawer
                visible={show}
                onClose={() => {
                    onClose();
                }}
                title="Visitas"
            >
                <div className="-mx-4 flex h-full flex-col items-center justify-between">
                    {!searchId && (
                        <div className="w-full px-2 py-2">
                            <Filters setFilters={(filters) => setFilters(filters)} />
                        </div>
                    )}
                    <PulppoLoader loading={isLoading} />
                    <div className="shrink-1 h-full w-full overflow-x-hidden">
                        <VisitList
                            refreshVisits={() => {
                                setFilters((filters) => ({ ...filters, page: 1 }));
                            }}
                            visits={visits}
                            closeParent={() => onClose()}
                            loading={isLoading}
                        />
                        {visits?.length > 0 && <div className="h-1 w-full" ref={endRef}></div>}
                    </div>
                    <div className="flex w-full shrink-0 items-center justify-center gap-3 px-2 py-2 lg:px-4">
                        {searchId ? (
                            !['cancelled', 'completed'].includes(search?.status?.last) && (
                                <Button
                                    disabled={search?.agent?.uid !== user?.uid}
                                    onClick={() => {
                                        openVisitForm(search);
                                    }}
                                    type="primary"
                                    className="flex h-10 w-full items-center justify-center font-medium"
                                >
                                    Agendar nueva visita
                                </Button>
                            )
                        ) : (
                            <div className="w-full flex-1">
                                <SearchSelect
                                    render={() => (
                                        <Button className="h-10 w-full rounded-none font-medium">Nueva visita</Button>
                                    )}
                                    onSelect={(search) => {
                                        openVisitForm(search);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </PulppoDrawer>
        </>
    );
};

const statusOptions = [
    { label: 'Todos', value: 'all' },
    { label: 'Pendientes', value: 'pending' },
    { label: 'Confirmadas', value: 'confirmed' },
    { label: 'Canceladas', value: 'cancelled' }
];
const timeOptions = [
    { label: 'Todas', value: 'all' },
    { label: 'Últimos 7 días', value: 'last_7_days' },
    { label: 'Últimos 15 días', value: 'last_15_days' },
    { label: 'Últimos 30 días', value: 'last_30_days' },
    { label: 'Últimos 60 días', value: 'last_60_days' },
    { label: 'Últimos 90 días', value: 'last_90_days' }
];
const SIZE = 10;

const Filters = ({ setFilters: setAppliedFilters }: { setFilters: (filters: IFilters) => void }) => {
    const user = useUser((u) => u.user);
    const [show, setShow] = useState(false);
    const [filters, setFilters] = useState<IFilters & { update: boolean }>({
        status: 'all',
        time: 'all',
        agent: user,
        page: 1,
        update: true,
        text: ''
    });
    const [search, setSearch] = useState('');
    useEffect(() => {
        if (filters.update) {
            setAppliedFilters({
                agent: filters.agent,
                status: filters.status,
                time: filters.time,
                text: filters?.text,
                page: filters?.page
            });
            setFilters((filters) => ({ ...filters, update: false }));
        }
    }, [user?.uid, filters]);

    useDebounce(
        () => {
            setFilters((filters) => ({ ...filters, text: search, update: true, page: 1 }));
        },
        300,
        [search]
    );
    return (
        <>
            <div className="flex items-center gap-2 px-2">
                <Input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Buscar..."
                    className="rounded-none py-2"
                    suffix={<SearchOutlined className="text-gray-300" />}
                />
                <Button onClick={() => setShow((show) => !show)} type="secondary">
                    Filtros
                </Button>
            </div>
            <PulppoDrawer
                title={'Seleccione las opciones deseadas'}
                visible={show}
                onClose={() => {
                    setShow(false);
                }}
                bodyStyle={{ height: '100%' }}
            >
                <div className="flex h-full flex-col justify-between">
                    <div className="flex flex-1 flex-col gap-4">
                        <div>
                            <p>Estado de la visita</p>
                            <PulppoRadio
                                value={filters.status}
                                title="Estado"
                                options={statusOptions}
                                onApply={function (value: string): void {
                                    setFilters((f) => ({ ...f, status: value }));
                                }}
                                onClear={() => {
                                    setFilters((f) => ({ ...f, status: 'all' }));
                                }}
                                render={({ label, value }) => (
                                    <div className="flex cursor-pointer items-center justify-between border border-solid border-gray-300 p-2">
                                        {statusOptions?.find((el) => el.value === filters.status)?.label}
                                        <RightOutlined className="text-gray-300" />
                                    </div>
                                )}
                            ></PulppoRadio>
                        </div>
                        <div>
                            <p>Fecha de la visita</p>
                            <PulppoRadio
                                value={filters.time}
                                title="Tiempo"
                                options={timeOptions}
                                onApply={function (value: string): void {
                                    setFilters((f) => ({ ...f, time: value }));
                                }}
                                onClear={() => {
                                    setFilters((f) => ({ ...f, time: 'all' }));
                                }}
                                render={({ label, value }) => (
                                    <div className="flex cursor-pointer items-center justify-between border border-solid border-gray-300 p-2">
                                        {timeOptions?.find((el) => el.value === filters.time)?.label}
                                        <RightOutlined className="text-gray-300" />
                                    </div>
                                )}
                            ></PulppoRadio>
                        </div>
                        {(user?.features?.viewAll || user?.permissions?.searches?.view) && (
                            <div>
                                <p>Asesor</p>
                                <AgentSelect
                                    className=""
                                    onChange={(agent: IAgent) => {
                                        setFilters((f) => ({ ...f, agent }) as any);
                                    }}
                                    allowAll={true}
                                    user={user}
                                    value={filters?.agent}
                                    renderButton={(item: { label: string | ReactNode }) => {
                                        return (
                                            <div className="flex cursor-pointer items-center justify-between border border-solid border-gray-300 p-2">
                                                {filters?.agent?.firstName} {filters?.agent?.lastName}
                                                <RightOutlined className="text-gray-300" />
                                            </div>
                                        );
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <Button
                        onClick={() => {
                            setShow(false);
                            setFilters((filters) => ({
                                ...filters,
                                page: 1,
                                update: true
                            }));
                        }}
                        type="primary"
                    >
                        Filtrar
                    </Button>
                </div>
            </PulppoDrawer>
        </>
    );
};

export default VisitDrawer;
