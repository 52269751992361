import { INote } from '@pulppo/pulppo-models';
import { Avatar, Button, Input, Modal } from 'antd';
import { PulppoDrawer } from './PulppoDrawer';
import * as ReactDOM from 'react-dom';
import { useState } from 'react';
import { PulppoLoader } from './PulppoLoader';
import dayjs from 'dayjs';
import { api_url, fetcher } from '../../helpers/fetcher';
import useSWR from 'swr';
import { CheckOutlined } from '@ant-design/icons';
import axios from '../../utils/axios';

const NoteList = ({ notes, isLoading }: { notes: INote[]; isLoading: boolean }) => {
    return (
        <PulppoLoader loading={isLoading}>
            {(!notes || notes?.length === 0) && (
                <div className="flex flex-col items-center py-10">
                    <img src="/icons/taskEmpty.svg" width={100} alt="task empty" />

                    <p className="text-center font-bold">No hay notas para mostrar</p>
                    <p className="text-center font-medium text-pulppo-status-dark-disabled">
                        No encontramos notas para mostrar en este contacto. Crea una nota privada o pública para dejar
                        comentarios o anotaciones respecto a este contacto
                    </p>
                </div>
            )}
            {notes?.length > 0 && (
                <div className="flex flex-col gap-4">
                    {notes.map((note) => (
                        <div key={`${note._id}`}>
                            <div className="mb-2 border border-solid border-gray-200 p-4">
                                <div className="flex items-start justify-between">
                                    <div className="flex gap-4">
                                        <Avatar src={note.creator?.profilePicture} className="h-5 w-5" />
                                        <p className="font-semibold">{`${note.creator?.firstName} ${note?.creator?.lastName}`}</p>
                                    </div>
                                    <div className="flex h-8 items-center justify-center border border-solid border-gray-300 px-4 text-pulppo-status-dark-disabled">
                                        {note?.isPublic ? 'Compartida' : 'Privada'}
                                    </div>
                                </div>

                                <p className="mt-4 text-pulppo-status-dark-disabled">{note.content}</p>
                            </div>
                            {(note as any)?.visit ? (
                                <p className="text-right text-xs text-pulppo-primary-gray">{`En la visita del ${dayjs((note as any)?.visit?.date).format('DD/MM')} a la propiedad ${(note as any)?.visit?.property}`}</p>
                            ) : (
                                <p className="w-full text-right text-xs text-pulppo-primary-gray">{`${dayjs(
                                    note.createdAt
                                )
                                    .tz()
                                    .format('DD/MM/YYYY')} a las ${dayjs(note.createdAt).tz().format('HH:mm')}Hs`}</p>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </PulppoLoader>
    );
};

const NewNote = ({ note, setNote }: { note: INote; setNote: (note) => void }) => {
    return (
        <div>
            <Input.TextArea
                rows={6}
                placeholder="Escribe una nueva nota aquí..."
                onChange={(ev) => {
                    setNote((note) => ({
                        ...note,
                        content: ev.currentTarget.value
                    }));
                }}
            />
            <div className="mt-6 flex items-center justify-between">
                <div>
                    <p className="font-medium">Visibilidad de la nota</p>
                    <p>Compartir esta nota con Pulppo</p>
                </div>
                <CustomSwitch
                    checked={note.isPublic}
                    onChange={() => {
                        setNote({ ...note, isPublic: !note.isPublic });
                    }}
                />
            </div>
        </div>
    );
};

const CustomSwitch = ({ checked, onChange }) => {
    return (
        <div
            className={`relative flex h-8 w-14 cursor-pointer items-center justify-center rounded-full shadow-md hover:shadow-gray-300 bg-${
                checked ? 'primary-color' : 'pulppo-status-dark-disabled'
            }`}
            onClick={() => {
                onChange((value) => !value);
            }}
        >
            <div
                className={`flex h-7 w-7 items-center justify-center ${
                    checked ? 'translate-x-3' : '-translate-x-3'
                } rounded-full bg-white transition`}
            >
                {checked && <CheckOutlined className="text-lg font-bold" />}
            </div>
        </div>
    );
};

const NoteDrawer = ({ show, setShow, onClose, search, user }) => {
    const [note, setNote] = useState<INote>(null);
    const {
        data: notes,
        mutate: refreshNotes,
        isLoading
    } = useSWR<INote[]>(`${api_url}/search/${search._id}/notes`, fetcher);
    return (
        <>
            <PulppoDrawer
                visible={show}
                onClose={() => {
                    setShow(false);
                    onClose();
                }}
                title={'Notas'}
            >
                <div className="-mx-4 flex h-full flex-col items-center justify-between px-4">
                    <div className="shrink-1 w-full overflow-x-hidden">
                        {!note && <NoteList notes={notes} isLoading={isLoading} />}
                        {note && <NewNote note={note} setNote={setNote} />}
                    </div>

                    <div className="flex w-full shrink-0 gap-3 px-4 py-2">
                        {!note && (
                            <Button
                                className="h-10 w-full font-medium"
                                type="primary"
                                onClick={() => {
                                    setNote({
                                        content: '',
                                        creator: user,
                                        createdAt: new Date(),
                                        isPublic: false
                                    });
                                }}
                            >
                                Añadir nueva nota
                            </Button>
                        )}
                        {note && (
                            <div className="flex w-full gap-2">
                                <Button className="flex-1 font-medium" onClick={() => setNote(null)}>
                                    Cancelar
                                </Button>
                                <Button
                                    onClick={() => {
                                        axios
                                            .post(`${api_url}/contact/${search.contact._id}/note`, note)
                                            .then(() => {
                                                refreshNotes();
                                                setNote(null);
                                            })
                                            .catch(() =>
                                                Modal.error({
                                                    title: 'Error al crear la nota',
                                                    content:
                                                        'Hubo un error al intentar crear la nota. Por favor intentalo nuevamente mas tarde'
                                                })
                                            );
                                    }}
                                    type="primary"
                                    className="flex-1 font-medium"
                                >
                                    Guardar nota
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </PulppoDrawer>
        </>
    );
};

export const ShowNoteDrawer = (config) => {
    const container = document.createDocumentFragment();
    let currentConfig = {
        ...config,
        close,
        show: true
    } as any;

    function destroy(...args: any[]) {
        ReactDOM.unmountComponentAtNode(container);
        const triggerCancel = args.some((param) => param && param.triggerCancel);
    }

    function render(props) {
        /**
         * https://github.com/ant-design/ant-design/issues/23623
         *
         * Sync render blocks React event. Let's make this async.
         */
        setTimeout(() => {
            ReactDOM.render(<NoteDrawer {...props} setShow={() => {}} />, container);
        });
    }

    function close(...args: any[]) {
        currentConfig = {
            ...currentConfig,
            show: false,
            afterClose: () => {
                destroy.apply(this, args);
            }
        };
        render(currentConfig);
    }

    function update(configUpdate) {
        if (typeof configUpdate === 'function') {
            currentConfig = configUpdate(currentConfig);
        } else {
            currentConfig = {
                ...currentConfig,
                ...configUpdate
            };
        }
        render(currentConfig);
    }

    render(currentConfig);

    return {
        destroy: close,
        update
    };
};

export default NoteDrawer;
