import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { useRouter } from 'next/router';
import { LoyaltyBadges } from './badges';
import { BadgeCard } from './BadgeCard';
import { getLevelTresholds, levels } from './LoyaltyDrawer';
import { NextLink } from '../Elements/NextLink';
import { LoadingButton } from '../Elements/ButtonLoading';

export const LoyaltyEmptyDrawer = () => {
    const router = useRouter();
    return (
        <PulppoDrawer
            title={<img src="/icons/loyalty/pulppo-plus.svg" alt="Pulppo Plus" />}
            bodyStyle={{ padding: 0 }}
            visible={router.query.loyalty === 'true'}
            onClose={() => {
                delete router.query.loyalty;
                delete router.query.badges;
                delete router.query.events;
                router.push(router);
            }}
        >
            <div className="relative flex h-full flex-col overflow-y-hidden">
                <div className="flex flex-col items-center justify-center gap-2 p-5 pt-8">
                    <h3 className="mb-0 text-center text-xl">Asesor no afiliado</h3>
                    <p className="text-muted text-center text-pulppo-status-dark-disabled">
                        Aún no estás afiliado a Pulppo Plus.
                        <br /> Para comenzar a formar parte, da el primer paso:
                    </p>
                </div>
                <div className="flex flex-1 flex-col overflow-y-scroll">
                    <div className="shrink-1 flex flex-1 flex-col gap-5 overflow-y-scroll p-5">
                        <div className="pb-4">
                            <BadgeCard
                                extra={
                                    <NextLink href="/academia">
                                        <LoadingButton className="mt-4 h-auto w-full py-2">Ir a Academia</LoadingButton>
                                    </NextLink>
                                }
                                showDetails={true}
                                badge={LoyaltyBadges.find((b) => b.id === 'academy')}
                            />
                        </div>

                        <div className="flex flex-1 flex-col">
                            <div className="flex items-center justify-between pb-2 pr-5">
                                <h5 className="font-bold">Niveles</h5>
                            </div>
                            <div className="no-wrap flex items-stretch gap-2 overflow-x-scroll py-5 pr-12">
                                {levels.map((level) => (
                                    <div
                                        key={level.id}
                                        style={{
                                            width: '240px',
                                            marginLeft: '0',
                                            marginRight: '0',
                                            transform: ''
                                        }}
                                        className="shrink-0 border border-solid border-pulppo-secondary-gray-disabled bg-white p-5 pt-10 transition-all duration-300"
                                    >
                                        <div className="flex w-full items-center justify-center">{level.image}</div>
                                        <h4 className="text-center text-lg font-medium text-black">{level.title}</h4>
                                        <div className="my-2 h-px bg-pulppo-secondary-gray-disabled" />
                                        <div className="flex flex-col gap-2">
                                            {level.features.map((feature, i) => (
                                                <div
                                                    key={`${level.id}_feature_${i}`}
                                                    className={`flex items-center gap-2 ${
                                                        feature.disabled ? 'opacity-30' : ''
                                                    }`}
                                                >
                                                    <div>
                                                        {feature.disabled ? <UncheckedIcon /> : <CheckIconBlack />}
                                                    </div>
                                                    <p className="text-sm font-light">
                                                        {feature.text.replace(
                                                            'MIN',
                                                            getLevelTresholds(level.id).min.toLocaleString()
                                                        )}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PulppoDrawer>
    );
};

export const CheckIconBlack = () => {
    return (
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_175_2788)">
                <path
                    d="M6.00781 0.959961C3.10859 0.959961 0.757812 3.31074 0.757812 6.20996C0.757812 9.10918 3.10859 11.46 6.00781 11.46C8.90703 11.46 11.2578 9.10918 11.2578 6.20996C11.2578 3.31074 8.90703 0.959961 6.00781 0.959961ZM8.27539 4.49551L5.80742 7.91738C5.77293 7.96553 5.72746 8.00476 5.67477 8.03182C5.62209 8.05888 5.56372 8.073 5.50449 8.073C5.44527 8.073 5.38689 8.05888 5.33421 8.03182C5.28153 8.00476 5.23606 7.96553 5.20156 7.91738L3.74023 5.89238C3.6957 5.83027 3.74023 5.74356 3.81641 5.74356H4.36602C4.48555 5.74356 4.59922 5.80098 4.66953 5.89941L5.50391 7.05723L7.34609 4.50254C7.41641 4.40527 7.52891 4.34668 7.64961 4.34668H8.19922C8.27539 4.34668 8.31992 4.4334 8.27539 4.49551Z"
                    fill="#212322"
                />
            </g>
            <defs>
                <clipPath id="clip0_175_2788">
                    <rect width="12" height="12" fill="white" transform="translate(0.0078125 0.209961)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const UncheckedIcon = () => {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_175_6204)">
                <path
                    d="M6.01135 1.20984C3.11213 1.20984 0.761353 3.56062 0.761353 6.45984C0.761353 9.35906 3.11213 11.7098 6.01135 11.7098C8.91057 11.7098 11.2614 9.35906 11.2614 6.45984C11.2614 3.56062 8.91057 1.20984 6.01135 1.20984ZM7.94963 8.45437L7.1762 8.45085L6.01135 7.06218L4.84768 8.44968L4.07307 8.4532C4.02151 8.4532 3.97932 8.41218 3.97932 8.35945C3.97932 8.33718 3.98752 8.31609 4.00159 8.29851L5.5262 6.4821L4.00159 4.66687C3.98743 4.64969 3.97957 4.62819 3.97932 4.60593C3.97932 4.55437 4.02151 4.51218 4.07307 4.51218L4.84768 4.5157L6.01135 5.90437L7.17502 4.51687L7.94846 4.51335C8.00002 4.51335 8.04221 4.55437 8.04221 4.6071C8.04221 4.62937 8.03401 4.65046 8.01995 4.66804L6.49768 6.48328L8.02112 8.29968C8.03518 8.31726 8.04338 8.33835 8.04338 8.36062C8.04338 8.41218 8.0012 8.45437 7.94963 8.45437Z"
                    fill="#212322"
                />
            </g>
            <defs>
                <clipPath id="clip0_175_6204">
                    <rect width="12" height="12" fill="white" transform="translate(0.0113525 0.459839)" />
                </clipPath>
            </defs>
        </svg>
    );
};
