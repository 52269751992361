import { Checkbox, Input, Skeleton } from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import Button from '../Elements/Button';
import { NextImage } from '../Elements/NextImage';
import { IAgent } from '@pulppo/pulppo-models';
import { ReactNode, useEffect, useState } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import Badge from '../Elements/Badge';
import { ColorBadge } from '../../utils/color';

type AgentMoreInfo<T> = IAgent & T;

export enum BasicColor {
    'Wrong',
    'Neutral',
    'Good'
}

interface Tag<T> {
    condition: (agent: AgentMoreInfo<T>) => BasicColor;
    replaceText?: string;
    text: {
        success: string | ((agent: AgentMoreInfo<T>) => string);
        error: string | ((agent: AgentMoreInfo<T>) => string);
    };
}

export interface PropsAgentListActions<T> {
    sort: (x: AgentMoreInfo<T>, y: AgentMoreInfo<T>) => number;
    onClick?: (agent: AgentMoreInfo<T>) => void;
    tag: Tag<T> | Array<Tag<T>>;
}

interface Props<T> {
    agents: Array<AgentMoreInfo<T>>;
    actions: PropsAgentListActions<T>;
    children?: (agent: AgentMoreInfo<T>) => ReactNode;
    needMetrics?: Array<string>;
    isLoading?: boolean;
    filter?: boolean;
}

Array.prototype.reverseIfTrue = function (isReverse) {
    return isReverse ? this.reverse() : this;
};

const AgentsList = <T,>({ agents, children, actions, isLoading }: Props<T>) => {
    const [agentsFiltered, setAgentsFiltered] = useState<Array<AgentMoreInfo<T>>>(agents || []);
    const [reverse, setReverse] = useState(false);
    const [agentSearch, setAgentSearch] = useState('');
    const [filterOnlyCommercialDirector, setFilterOnlyCommercialDirector] = useState(false);

    useEffect(() => {
        let newAgents = agents;
        if (filterOnlyCommercialDirector) {
            newAgents = newAgents.filter((agent) => agent?.subType === 'comercial' || agent?.subType === 'director');
        } else {
            newAgents = agents;
        }
        if (!agentSearch) {
            setAgentsFiltered(newAgents);
            return;
        }
        if (agents) {
            setAgentsFiltered(
                newAgents.filter(
                    (agent) =>
                        agent.firstName.toLowerCase().includes(agentSearch.toLowerCase()) ||
                        agent.lastName.toLowerCase().includes(agentSearch.toLowerCase())
                )
            );
        } else {
            setAgentsFiltered([]);
        }
    }, [agentSearch, agents, filterOnlyCommercialDirector]);

    const tags = Array.isArray(actions.tag) ? actions.tag : [actions.tag];

    return (
        <div>
            <p className="font-medium">Indicadores por asesor</p>
            <div className="mt-2 flex">
                <Input
                    placeholder="Buscar"
                    suffix={<SearchOutlined className="text-pulppo-primary-gray" />}
                    className="flex-grow rounded-none"
                    value={agentSearch}
                    onChange={(e) => setAgentSearch(e.target.value)}
                />
                <Button type="secondary" className="ml-2" onClick={() => setReverse(!reverse)}>
                    <img src="/icons/sort.svg" alt="sort" />
                </Button>
            </div>
            <Checkbox
                checked={filterOnlyCommercialDirector}
                onChange={(e) => setFilterOnlyCommercialDirector(e.target.checked)}
                className="mt-2"
            >
                Mostrar sólo comercial y directores
            </Checkbox>
            <div className="mt-4 space-y-6">
                {isLoading
                    ? [...Array(10).keys()].map((i) => (
                          <Skeleton
                              loading
                              active
                              key={`skeleton-agent-${i}`}
                              className="border-[0.5px] border-solid border-pulppo-secondary-gray-disabled p-2"
                              paragraph={false}
                              avatar={{ className: 'rounded-full h-12 w-12' }}
                              title={{
                                  className: 'w-64'
                              }}
                          />
                      ))
                    : agentsFiltered
                          ?.sort(actions.sort)
                          .reverseIfTrue(reverse)
                          .map((agent) => {
                              function replaceText(chain: string) {
                                  return chain.replace(/{([^}]+)}/g, function (match, key) {
                                      return `${agent[key.trim()]}` || match;
                                  });
                              }

                              const element = (
                                  <>
                                      <div className={`flex items-center`}>
                                          <NextImage
                                              width={128}
                                              height={128}
                                              className="inline-block h-12 w-12 min-w-12 rounded-full"
                                              src={agent?.profilePicture}
                                              alt={`${agent?.firstName} ${agent?.lastName}`}
                                              showPreview={false}
                                              fallback={`https://ui-avatars.com/api/?name=${agent?.firstName} ${agent?.lastName}`}
                                          />
                                          <div className="ml-2 truncate">
                                              <p className="truncate text-left text-sm text-gray-700 group-hover:text-gray-900">
                                                  {agent?.firstName || ''} {agent?.lastName || ''}
                                              </p>
                                              <p className="text-left text-xs capitalize text-pulppo-primary-gray">
                                                  {agent?.subType}
                                              </p>
                                          </div>
                                      </div>
                                      <div className="flex justify-end gap-x-2">
                                          {tags.map((tag) => {
                                              const basicColor = tag.condition(agent);
                                              const success = replaceText(
                                                  typeof tag.text.success === 'string'
                                                      ? tag.text.success
                                                      : tag.text.success(agent)
                                              );
                                              const error = replaceText(
                                                  typeof tag.text.error === 'string'
                                                      ? tag.text.error
                                                      : tag.text.error(agent)
                                              );

                                              return (
                                                  <Badge
                                                      key={`${agent?._id}-${basicColor}-${success}-${error}`}
                                                      className="h-min text-nowrap normal-case"
                                                      color={
                                                          basicColor === BasicColor.Good
                                                              ? ColorBadge.Green
                                                              : basicColor === BasicColor.Neutral
                                                                ? ColorBadge.Yellow
                                                                : ColorBadge.Red
                                                      }
                                                      value={basicColor === BasicColor.Good ? success : error}
                                                  />
                                              );
                                          })}
                                      </div>
                                  </>
                              );
                              if (children) {
                                  return (
                                      <Disclosure key={`resource-${agent._id}`}>
                                          <div className="border-[0.5px] border-solid border-pulppo-secondary-gray-disabled">
                                              <Disclosure.Button className="flex w-full items-center p-2">
                                                  <div className="grid flex-grow grid-cols-2 items-center gap-x-4">
                                                      {element}
                                                  </div>
                                                  <div className="ml-4 mr-2 flex items-center transition-transform duration-500 ui-open:-rotate-180 ui-open:transform">
                                                      <DownOutlined className="text-2xl" />
                                                  </div>
                                              </Disclosure.Button>
                                              <Transition
                                                  enter="transition-all duration-500 ease-in"
                                                  enterFrom="max-h-0 overflow-hidden"
                                                  enterTo="max-h-96 overflow-hidden"
                                                  leave="transition-all duration-300 ease-out"
                                                  leaveFrom="max-h-96"
                                                  leaveTo="max-h-0 overflow-hidden"
                                              >
                                                  <Disclosure.Panel className="p-2">
                                                      <section>{children(agent)}</section>
                                                  </Disclosure.Panel>
                                              </Transition>
                                          </div>
                                      </Disclosure>
                                  );
                              }
                              return (
                                  <div
                                      key={agent._id as string}
                                      className={`${actions.onClick ? 'cursor-pointer' : ''} grid grid-cols-2 items-center gap-x-4`}
                                      onClick={() => actions.onClick?.(agent)}
                                  >
                                      {element}
                                  </div>
                              );
                          })}
            </div>
        </div>
    );
};

export default AgentsList;
